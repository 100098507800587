import * as React from 'react';
import { Container, Row, Col } from 'reactstrap';
import NavMenu from './MenuNavigation/NavMenu';
import SideNavigation from './SideNavigation/SideNavigation';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>

        <Container fluid>
            <Row xs="12" className="row-fluid">
                <Col>
                    <NavMenu />
                </Col>
            </Row>
            <Row>
                <Col xs="1" sm="1">
                    <SideNavigation />
                </Col>
                <Col xs="10" sm="10" className="margenTop">
                    {props.children}
                </Col>
            </Row>
        </Container>
    </React.Fragment>
);
