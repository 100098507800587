import { useHistory } from "react-router-dom";
import "../../custom.css";
import GrillaPresupuestos from "./../GrillaDatos/GrillaPresupuesto";

import React, { useState, useEffect } from "react";
import "../Home/Home.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { fetchEngine } from "../../fetchData";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

const HomeSimulador = () => {
    let userName: any;
    if (authProvider.getAccountInfo() != null) {
        userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
    }

    const [dataPresupuesto, setDataPresupuesto] = useState([]);
    const [comboData2, setComboData2] = useState([] as any);
    const [pages2, setPages2] = useState(0);

    const [emailUsuarioActual, setEmailUsuarioActual] = useState<string>(userName.account.userName);
    const [contrato, setContrato] = useState(0);
    const [nombresim, setNombreSim] = useState("0");
    const [nombreopo, setNombreOpo] = useState("0");
    const [fechaAprobacion, setFechaAprobacion] = useState("12-12-2012");

    const [isAdmin, setEsAdmin] = useState(false);
    const [isGestion, setEsGestion] = useState(false);
    const [isUsuario, setEsUsuario] = useState(false);
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [tokenAPI, setTokenAPI] = useState<string | null>("");
    const [infoInicialCargada, setInfoInicialCargada] = useState<boolean>(false);

    const history = useHistory();
    const modalPropsStyles = { main: { maxWidth: 450 } };

    const cambioPage = (page: number) => {
        ////console.log(page);
    };

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                setTokenAPI(d);
            });
        }

        if (tokenAPI != "" && !infoInicialCargada) {
            setInfoInicialCargada(true);
            verificarAdmin();
            verificarUsuario();
            verificarGestion();
            verificarValidador();
        }
        if (tokenAPI != "" && infoInicialCargada) {
            obtenerPresupuestos(1);
        }
    }, [nombresim, nombreopo, contrato, fechaAprobacion, isAdmin, isUsuario, isGestion, esValidador, tokenAPI]);

    const verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                //this.setState({esAdmin:result.ok})
                setEsAdmin(result.ok);
            });
    };
    const verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                //this.setState({esUsuario:result.ok})
                setEsUsuario(result.ok);
            });
    };
    const verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                //this.setState({esGestion:result.ok})
                setEsGestion(result.ok);
            });
    };
    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }

    // Listado presupuesto
    const columnasPresupuesto = [
        {
            titulo: "ID",
            interno: "ID",
            posicion: 1,
            ordenable: true,
            filtrable: false,
        },
        {
            titulo: "Nombre simulador",
            interno: "NOMBRE",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text",
        },
        {
            titulo: "Nombre oportunidad",
            interno: "NOMBRE_PROYECTO",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text2",
        },
        {
            titulo: "Contrato Marco",
            interno: "CONTRATO_MARCO",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Fecha aprobación",
            interno: "FECHA_APROBACION",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "fechaAprobacion",
        },
    ];

    const accionesPresupuesto = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver presupuesto",
            title: "Ver presupuesto",
        },
    ];

    const accionPresupuesto = (accion: string, ID: number) => {
        switch (accion) {
            case "reload":
                obtenerPresupuestos(1);
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/simulador/${ID}`);
                break;
            case "paging":
                obtenerPresupuestos(ID);
                break;
            default:
                break;
        }
    };

    const callbackFilterPresupuesto = (id: string, value: string, value2: any) => {
        switch (id) {
            case "NOMBRE":
                setNombreSim(value != "" ? value : "0");
                break;
            case "NOMBRE_PROYECTO":
                setNombreOpo(value != "" ? value : "0");
                break;
            case "CONTRATO_MARCO":
                setContrato(parseInt(value));
                break;
            case "FECHA_APROBACION":
                if (value2 != undefined) {
                    value2 = value2.getFullYear() + "-" + (value2.getMonth() + 1) + "-" + value2.getDate();
                }
                setFechaAprobacion(value2);
                break;
        }
    };

    const obtenerPresupuestos = async (page: number) => {
        fetchEngine
            .getAPI(
                `/api/simulacion/getbyme/${emailUsuarioActual}/${nombresim != "" ? nombresim : "0"}/${
                    nombreopo != "" ? nombreopo : "0"
                }/${contrato}/${fechaAprobacion}/${page}`,
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenAPI}`,
                }
            )
            .then((result: any) => {
                setDataPresupuesto(result.data.listado);
                setComboData2([
                    {
                        id: "CONTRATO_MARCO",
                        value: result.data.contratos,
                    },
                ]);

                setPages2(result.contador / 10 + 1);
            });
    };

    if (isAdmin || isGestion || esValidador) {
        return (
            <React.Fragment>
                <BotonAyuda token={tokenAPI} />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                            {
                                <div className="iconoRuta">
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                    &nbsp;
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Home Simulador de Presupuestos</span>
                                </div>
                            }
                            {
                                <div className="titulosimulador">
                                    <Glyphicon glyph="briefcase" /> Módulo Simulador de Presupuestos
                                </div>
                            }
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                            <div className="fijado">
                                {/* <PrimaryButton text="Exportar detalle PDF"></PrimaryButton> */}
                                <PrimaryButton text="Agregar nuevo presupuesto" href="/simulador" />
                            </div>
                        </div>
                    </div>

                    {dataPresupuesto ? (
                        <GrillaPresupuestos
                            columnas={columnasPresupuesto}
                            listado={dataPresupuesto}
                            callbackPages={cambioPage}
                            callbackAcciones={accionPresupuesto}
                            pages2={pages2}
                            titulo={""}
                            acciones={accionesPresupuesto}
                            callbackFilterPresupuesto={callbackFilterPresupuesto}
                            comboData2={comboData2}
                        />
                    ) : (
                        "No hay datos cargados"
                    )}
                </div>
            </React.Fragment>
        );
    } else if (isUsuario) {
        return (
            <React.Fragment>
                <div className="mt-4 card sinAcceso text-primary">
                    <div className="card-header">Atención</div>
                    <div className="card-body">
                        <h6>
                            <Glyphicon glyph="info-sign" className="mr-1" /> Usted no posee los permisos para visualizar este formulario, si desea
                            obtener los permisos, contactese con el administrador del sistema.
                        </h6>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="mt-4 card sinAcceso text-primary"></div>
            </React.Fragment>
        );
    }
};

export default HomeSimulador;
