import React, { useState, useEffect } from "react";
import "react-autosuggest";
import "./grillaDatos.css";
import { ComboBox, DatePicker, DayOfWeek, Checkbox, IconButton, IDatePickerStrings, mergeStyleSets, TextField } from "@fluentui/react";
import { Categoria, Estado, RendicionDisplay, TipoRendicion } from "../../controles/entidades/Rendicion";
import { Panel } from "office-ui-fabric-react/lib/Panel";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { IFiltersString } from "../../controles/interfaces/filters";
import DatePicker2 from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface Props {
    listado: RendicionDisplay[];
    columnas: any[];
    titulo: string;
    style?: string;
    pages: number;
    callbackPages: any;
    callbackAcciones: any;
    callbackFilter: any;
    acciones: any[];
    listadoEstados: Estado[];
    listadoCategorias: Categoria[];
    listadoTipoRendiciones: TipoRendicion[];
    seccionRendicion: number;
    filters: IFiltersString;
    callbackLimpiarFiltros: () => void;
    paginaActual: number;
    gastosSeleccionados: number[];
}

const GrillaRendicion = ({
    listado,
    columnas,
    titulo,
    style,
    pages,
    acciones,
    callbackAcciones,
    callbackFilter,
    listadoEstados,
    listadoCategorias,
    listadoTipoRendiciones,
    seccionRendicion,
    filters,
    callbackLimpiarFiltros,
    paginaActual,
    gastosSeleccionados,
}: Props) => {
    const [activeKey, setActiveKey] = useState(0);
    const [currentPage, setcurrentPage] = useState(1);
    const [upperPageBound, setupperPageBound] = useState(5);
    const [lowerPageBound, setlowerPageBound] = useState(0);
    const [isPrevBtnActive, setisPrevBtnActive] = useState("disabled");
    const [isNextBtnActive, setisNextBtnActive] = useState("");
    const [pageBound, setpageBound] = useState(5);
    const [abrirFiltrosFechaCampos, setAbrirFiltrosFechaCampos] = useState<boolean>(false);

    const [fechaRendicionValue, setFechaRendicionValue] = useState(new Date())
    const [fechaGastoValue, setFechaGastoValue] = useState(new Date())
    useEffect(() => {
        setcurrentPage(paginaActual);
    }, [paginaActual]);

    const setPrevAndNextBtnClass = (listid: number) => {
        let totalPage: number = pages;
        setisNextBtnActive("disabled");
        setisPrevBtnActive("disabled");
        if (totalPage === listid && totalPage > 1) {
            setisNextBtnActive("disabled");
            setisPrevBtnActive("");
        } else if (listid === 1 && totalPage > 1) {
            setisNextBtnActive("");
        } else if (totalPage > 1) {
            setisNextBtnActive("");
            setisPrevBtnActive("");
        }
        if (listid === 1) {
            setisPrevBtnActive("disabled");
        }
    };

    const btnIncrementClick = () => {
        setupperPageBound(upperPageBound + pageBound);
        setlowerPageBound(lowerPageBound + pageBound);
        let listid: number = upperPageBound + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const btnDecrementClick = () => {
        setupperPageBound(upperPageBound - pageBound);
        setlowerPageBound(lowerPageBound - pageBound);
        let listid = upperPageBound - pageBound;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
    };

    const btnPrevClick = () => {
        if ((currentPage! - 1) % pageBound === 0) {
            setupperPageBound(upperPageBound - pageBound);
            setlowerPageBound(lowerPageBound - pageBound);
        }
        let listid = currentPage! - 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);

        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const btnNextClick = () => {
        if (currentPage! + 1 > upperPageBound) {
            setupperPageBound(upperPageBound + pageBound);
            setlowerPageBound(lowerPageBound + pageBound);
        }
        let listid = currentPage! + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const handleClick = (event: any) => {
        let listid = Number(event.target.id);
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const paginacion = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pages; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number: any) => {
            if (number === 1 && currentPage === 1) {
                return (
                    <li key={number} id={number} onClick={handleClick} className={"active" + " " + "page" + " " + number}>
                        <a id={number} onClick={handleClick}>
                            {number}
                        </a>
                    </li>
                );
            } else if (number < upperPageBound + 1 && number > lowerPageBound) {
                return (
                    <li
                        className={currentPage === number ? "active" : "" + " " + "page" + " " + number}
                        id={number}
                        onClick={handleClick}
                        key={number}>
                        <a id={number} onClick={handleClick}>
                            {number}
                        </a>
                    </li>
                );
            }
        });

        let pageIncrementBtn = <></>;
        if (pageNumbers.length > upperPageBound) {
            pageIncrementBtn = (
                <li onClick={btnIncrementClick} className={"pageitem"}>
                    <a onClick={btnIncrementClick}> &hellip; </a>
                </li>
            );
        }
        let pageDecrementBtn = <></>;
        if (lowerPageBound >= 1) {
            pageDecrementBtn = (
                <li onClick={btnDecrementClick} className={"pageitem"}>
                    <a onClick={btnDecrementClick}> &hellip; </a>
                </li>
            );
        }
        let renderPrevBtn = <></>;
        if (isPrevBtnActive === "disabled") {
            renderPrevBtn = (
                <li className={isPrevBtnActive + " " + "page"}>
                    <span id="btnPrev"></span>Ant.{" "}
                </li>
            );
        } else {
            renderPrevBtn = (
                <li onClick={btnPrevClick} className={isPrevBtnActive + " " + "page"}>
                    <a id="btnPrev" onClick={btnPrevClick}>
                        Ant.
                    </a>
                </li>
            );
        }
        let renderNextBtn = <></>;
        if (isNextBtnActive === "disabled") {
            renderNextBtn = (
                <li className={isNextBtnActive + " " + "page"}>
                    <span id="btnNext"> Sig.</span>
                </li>
            );
        } else {
            renderNextBtn = (
                <li onClick={btnNextClick} className={isNextBtnActive + " " + "page"}>
                    <a id="btnNext" onClick={btnNextClick}>
                        Sig.
                    </a>
                </li>
            );
        }

        return (
            <div style={{ display: "flex" }}>
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
                &nbsp;
            </div>
        );
    };

    const eventAcciones = (id: string, registro: number, idGasto?: number) => {
        if (id === "paging") {
            setActiveKey(registro);
        }
        callbackAcciones(id, registro, idGasto);
    };

    const limpiarFiltros = () => {
        callbackLimpiarFiltros();
        return true;
    };

    const DayPickerStrings: IDatePickerStrings = {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        shortDays: ["D", "L", "M", "M", "J", "V", "S"],
        goToToday: "Ir a hoy",
        prevMonthAriaLabel: "Ir al mes previo",
        nextMonthAriaLabel: "Ir al mes siguiente",
        prevYearAriaLabel: "Ir al año anterior",
        nextYearAriaLabel: "Ir al año siguiente",
        closeButtonAriaLabel: "Cerrar el selector de fechas",
    };

    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 15px 0",
            maxWidth: "300px",
        },
    });

    const onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return (date.getDate() + 1) + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    const onSelectDate = (date: Date | null | undefined, id: string): void => {
        if (date != null) {

            var newDateV = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

            switch (id) {
                case "FECHA_RENDICION":
                    filters.fechaRendicion = newDateV.toDateString();
                    setFechaRendicionValue(newDateV)
                    callbackFilter(id, "", date);
                    break;
                case "FECHA_GASTO":
                    filters.fechaGasto = newDateV.toDateString();
                    setFechaGastoValue(newDateV);
                    callbackFilter(id, "", date);
                    break;
                case "MES_ANIO_USO":
                    filters.mesAnioUso = date.toDateString();
                    callbackFilter(id, "", date);
                    break;
            }
        }
    };

    const onChangeText = (ev: any, id: string) => {
        let value = ev.target.value !== "" ? ev.target.value : "";
        let valueCallback = ev.target.value !== "" ? ev.target.value : "0";
        switch (id) {
            case "ID":
                filters.id = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "NUMERO_GASTO":
                filters.numGasto = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "MONTO":
                filters.monto = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "OT":
                filters.ot = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "NOMBRE_PROYECTO":
                filters.nombreProyecto = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "CREADO_POR_DISPLAY":
                filters.creador = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "PROVEEDOR":
                filters.proveedor = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "N_BOLETA_FACTURA":
                filters.numBoleta = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "USUARIO_APROBO_RECHAZO":
                filters.usuarioAproboRechazo = value;
                callbackFilter(id, valueCallback, []);
                break;
            case "ASIGNADO":
                filters.asignado = value;
                callbackFilter(id, valueCallback, []);
                break;
        }
    };

    const onChangeCheckbox = (ev: any, idGasto: number) => {
        let key = ev.target.id.split("-")[0];
        let isChecked = ev.target.checked;
        switch (key) {
            case "gasto":
                let gastos = Object.assign(gastosSeleccionados);
                if (isChecked) {
                    gastos.push(idGasto);
                    callbackFilter("GASTO", "", gastos);
                } else {
                    const gastosFiltrado = gastos.filter(function (gasto: number) {
                        return gasto !== idGasto;
                    });
                    callbackFilter("GASTO", "", gastosFiltrado);
                }
                break;
            case "activarID":
                if (isChecked !== undefined) {
                    columnas[1].disabled = !isChecked;
                }
                break;
            case "activarNUMERO_GASTO":
                if (isChecked !== undefined) {
                    columnas[2].disabled = !isChecked;
                }
                break;
            case "activarMONTO":
                if (isChecked !== undefined) {
                    columnas[3].disabled = !isChecked;
                }
                break;
            case "activarOT":
                if (isChecked !== undefined) {
                    columnas[4].disabled = !isChecked;
                }
                break;
            case "activarNOMBRE_PROYECTO":
                if (isChecked !== undefined) {
                    columnas[5].disabled = !isChecked;
                }
                break;
            case "activarCATEGORIA_RENDICION":
                if (isChecked !== undefined) {
                    columnas[6].disabled = !isChecked;
                }
                break;
            case "activarFECHA_RENDICION":
                if (isChecked !== undefined) {
                    columnas[7].disabled = !isChecked;
                }
                break;
            case "activarFECHA_GASTO":
                if (isChecked !== undefined) {
                    columnas[8].disabled = !isChecked;
                }
                break;
            case "activarCREADO_POR_DISPLAY":
                if (isChecked !== undefined) {
                    columnas[9].disabled = !isChecked;
                }
                break;
            case "activarMES_ANIO_USO":
                if (isChecked !== undefined) {
                    columnas[10].disabled = !isChecked;
                }
                break;
            case "activarTIPO_RENDICION":
                if (isChecked !== undefined) {
                    columnas[11].disabled = !isChecked;
                }
                break;
            case "activarPROVEEDOR":
                if (isChecked !== undefined) {
                    columnas[12].disabled = !isChecked;
                }
                break;
            case "activarN_BOLETA_FACTURA":
                if (isChecked !== undefined) {
                    columnas[13].disabled = !isChecked;
                }
                break;
            case "activarUSUARIO_APROBO_RECHAZO":
                if (isChecked !== undefined) {
                    columnas[14].disabled = !isChecked;
                }
                break;
            case "activarESTADO":
                if (isChecked !== undefined) {
                    columnas[15].disabled = !isChecked;
                }
                break;
        }
        callbackFilter("ACTIVAR", "", columnas);
    };

    const onChangeList = (e: any, item: any, id: string) => {
        if (item != null) {
            switch (id) {
                case "ESTADO":
                    filters.estadoRendicion = item.key;
                    callbackFilter(id, item.key, []);
                    callbackFilter("GASTO", "", []);
                    break;
                case "CATEGORIA_RENDICION":
                    filters.conceptoRendicion = item.key;
                    callbackFilter(id, item.key, []);
                    break;
                case "TIPO_RENDICION":
                    filters.tipoRendicion = item.key;
                    callbackFilter(id, item.key, []);
                    break;
            }
        }
    };

    const tipoFiltro = (tipo: string, id: string) => {
        let control = <></>;
        switch (tipo) {
            case "id":
                control = (
                    <TextField
                        id="ID"
                        key="text_ID"
                        className="id_ren"
                        type="number"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.id.trim() != "0" ? filters.id : ``}
                    />
                );
                break;
            case "numGasto":
                control = (
                    <TextField
                        id="NUMERO_GASTO"
                        key="text_NUMERO_GASTO"
                        className="id_ren"
                        type="number"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.numGasto.trim() != "0" ? filters.numGasto : ``}
                    />
                );
                break;
            case "monto":
                control = (
                    <TextField
                        id="MONTO"
                        key="text_MONTO"
                        className="monto_ren"
                        type="number"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.monto.trim() != "0" ? filters.monto : ``}
                    />
                );
                break;
            case "ot":
                control = (
                    <TextField
                        id="OT"
                        key="text_OT"
                        className="ot_ren"
                        type={"number"}
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.ot.trim() != "0" ? filters.ot : ``}
                    />
                );
                break;
            case "nombreProyecto":
                control = (
                    <TextField
                        id="NOMBRE_PROYECTO"
                        key="text_NOMBRE_PROYECTO"
                        className="nombre_ren"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.nombreProyecto.trim() != "0" ? filters.nombreProyecto : ``}
                    />
                );
                break;
            case "categoria":
                control = (
                    <ComboBox
                        id="CATEGORIA_RENDICION"
                        key="cmb_CONCEPTO"
                        onChange={(e, item) => {
                            onChangeList(e, item, id);
                        }}
                        autoComplete="on"
                        selectedKey={filters.conceptoRendicion.toString().trim() != "0" ? filters.conceptoRendicion : undefined}
                        options={listadoCategorias}
                    />
                );
                break;
            case "fechaRendicion":
                control = (
                    <DatePicker
                        id="FECHA_RENDICION"
                        key="date_FECHA_RENDICION"
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        placeholder="Seleccione una fecha..."
                        //value={filters.fechaRendicion.trim() != "0" ? new Date(filters.fechaRendicion) : undefined}
                        value={filters.fechaRendicion.trim() != "0" ? fechaRendicionValue : undefined}
                        formatDate={onFormatDate}
                        disabled={false}
                        onSelectDate={(e: Date | null | undefined) => {
                            onSelectDate(e, id);
                        }}
                    />
                );
                break;
            case "fechaGasto":
                control = (
                    <DatePicker
                        id="FECHA_GASTO"
                        key="date_FECHA_GASTO"
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        placeholder="Seleccione una fecha..."
                        value={filters.fechaGasto.trim() != "0" ? fechaGastoValue : undefined}
                        formatDate={onFormatDate}
                        disabled={false}
                        onSelectDate={(e: Date | null | undefined) => {
                            onSelectDate(e, id);
                        }}
                    />
                );
                break;
            case "creador":
                control = (
                    <TextField
                        id="CREADO_POR_DISPLAY"
                        key="text_CREADO_POR_DISPLAY"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.creador.trim() != "0" ? filters.creador : ``}
                    />
                );
                break;
            case "mesAnioUso":
                control = (
                    // <DatePicker
                    //     id="MES_ANIO_USO"
                    //     key="date_MES_ANIO_USO"
                    //     className={controlClass.control}
                    //     firstDayOfWeek={DayOfWeek.Monday}
                    //     strings={DayPickerStrings}
                    //     allowTextInput={true}
                    //     placeholder="Seleccione una fecha..."
                    //     value={filters.mesAnioUso.trim() != "0" ? new Date(filters.mesAnioUso) : undefined}
                    //     formatDate={onFormatDate}
                    //     disabled={false}
                    //     onSelectDate={(e: Date | null | undefined) => {
                    //         onSelectDate(e, id);
                    //     }}
                    // />
                    <DatePicker2
                        id="MES_ANIO_USO"
                        key="date_MES_ANIO_USO"
                        locale={"es"}
                        className="reactCalendar"
                        selected={filters.mesAnioUso.trim() != "0" ? new Date(filters.mesAnioUso) : undefined}
                        onChange={(e: Date | null | undefined) => {
                            onSelectDate(e, id);
                        }}
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                    />
                );
                break;
            case "tipoRendicion":
                control = (
                    <ComboBox
                        id="TIPO_RENDICION"
                        key="cmb_TIPO_RENDICION"
                        onChange={(e, item) => {
                            onChangeList(e, item, id);
                        }}
                        autoComplete="on"
                        selectedKey={filters.tipoRendicion.toString().trim() != "0" ? filters.tipoRendicion : ``}
                        options={listadoTipoRendiciones}
                    />
                );
                break;
            case "proveedor":
                control = (
                    <TextField
                        id="PROVEEDOR"
                        key="text_PROVEEDOR"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.proveedor.trim() != "0" ? filters.proveedor : ``}
                    />
                );
                break;
            case "numBoleta":
                control = (
                    <TextField
                        id="N_BOLETA_FACTURA"
                        key="text_N_BOLETA_FACTURA"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.numBoleta.trim() != "0" ? filters.numBoleta : ``}
                    />
                );
                break;
            case "aprobador":
                control = (
                    <TextField
                        id="USUARIO_APROBO_RECHAZO"
                        key="text_USUARIO_APROBO_RECHAZO"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.usuarioAproboRechazo.trim() != "0" ? filters.usuarioAproboRechazo : ``}
                    />
                );
                break;
            case "estado":
                control = (
                    <ComboBox
                        id="ESTADO"
                        key="cmb_ESTADO"
                        onChange={(e, item) => {
                            onChangeList(e, item, id);
                        }}
                        autoComplete="off"
                        selectedKey={filters.estadoRendicion.toString().trim() != "0" ? filters.estadoRendicion : undefined}
                        options={listadoEstados}
                        disabled={false}
                    />
                );
                break;
            case "asignado":
                control = (
                    <TextField
                        id="ASIGNADO"
                        key="text_ASIGNADO"
                        onChange={(e) => {
                            onChangeText(e, id);
                        }}
                        value={filters.asignado != undefined ? (filters.asignado.trim() != "0" ? filters.asignado : '') : ('')}
                    />
                );
                break;
        }
        return <div>{control}</div>;
    };

    return (
        <React.Fragment>
            <div>
                <div>
                    <h4 className="titGrilla">{titulo}</h4>
                    <div className="accionesGrilla">
                        <span className="botonRecargar">
                            <IconButton
                                key="recargarTabla"
                                iconProps={{
                                    iconName: "SyncStatusSolid",
                                }}
                                title="Recargar"
                                ariaLabel=""
                                onClick={() => {
                                    eventAcciones("reload", 0);
                                }}
                            />
                        </span>
                        <span className="botonLimpiar">
                            <IconButton
                                key="limpiarFiltros"
                                iconProps={{
                                    iconName: "ClearFilter",
                                }}
                                title="Limpiar filtros"
                                ariaLabel=""
                                onClick={() => {
                                    limpiarFiltros();
                                }}
                            />
                        </span>
                        <span className="botonLimpiar">
                            <IconButton
                                key="gestionarCampos"
                                iconProps={{
                                    iconName: "Settings",
                                }}
                                title="Gestionar campos"
                                ariaLabel=""
                                onClick={() => {
                                    setAbrirFiltrosFechaCampos(true);
                                }}
                            />
                        </span>
                    </div>

                    <div className="table-responsive">
                        <table key="tabla_datos" className="grilla table table-hover table-sm table-striped">
                            <thead>
                                <tr key={"tr_datos"}>
                                    {columnas.map((columna, key) => {
                                        if (!columna.disabled && columna.filterType !== "checkbox" && seccionRendicion !== 3) {
                                            return (
                                                <th>
                                                    {columna.titulo}
                                                    {columna.filtrable ? <span>{tipoFiltro(columna.filterType, columna.interno)}</span> : ""}
                                                </th>
                                            );
                                        } else if (!columna.disabled && seccionRendicion === 3) {
                                            return (
                                                <th key={columna.interno}>
                                                    {columna.titulo}
                                                    {columna.filtrable ? <span>{tipoFiltro(columna.filterType, columna.interno)}</span> : ""}
                                                </th>
                                            );
                                        }
                                    })}
                                    {acciones.length > 0 ? <th>Acciones</th> : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {listado.map((l: any, key) => {
                                    return (
                                        <tr key={key}>
                                            {columnas.map((c: any, key) => {
                                                if (c.filterType === "checkbox" && seccionRendicion === 3) {
                                                    const gastoFiltrado = gastosSeleccionados.filter(function (gasto: any) {
                                                        return parseInt(gasto) === l.ID_GASTO_RENDICION;
                                                    });
                                                    return (
                                                        <td key={key}>
                                                            <Checkbox
                                                                key={"gasto-" + l.ID_GASTO_RENDICION}
                                                                id={"gasto-" + l.ID_GASTO_RENDICION}
                                                                disabled={false}
                                                                onChange={(e) => {
                                                                    onChangeCheckbox(e, l.ID_GASTO_RENDICION);
                                                                }}
                                                                checked={gastoFiltrado.length > 0 ? true : false}
                                                            />
                                                        </td>
                                                    );
                                                }
                                                if (!c.disabled && c.filterType !== "checkbox" && seccionRendicion !== 3) {
                                                    return <td key={"d_" + key}>{l[c.interno]}</td>;
                                                } else if (!c.disabled && seccionRendicion === 3) {
                                                    return <td key={"d_" + key}>{l[c.interno]}</td>;
                                                }
                                            })}
                                            <td className="d-flex">
                                                {acciones.map((a, key) => {
                                                    return (
                                                        <IconButton
                                                            key={a.id + l.ID_GASTO_RENDICION}
                                                            iconProps={{ iconName: a.icon }}
                                                            title={a.title}
                                                            ariaLabel=""
                                                            onClick={() => {
                                                                eventAcciones(a.id, l["ID"], l["ID_GASTO_RENDICION"]);
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="paginar">{pages > 1 ? paginacion() : ""}</div>
                </div>
            </div>
            <Panel key="panel_columnas" isOpen={abrirFiltrosFechaCampos} hasCloseButton={false}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen  mt-4">
                        <h5>Visualización de columnas</h5>
                    </div>
                    {columnas.map((col, key) => {
                        if (col.filterType !== "checkbox") {
                            return (
                                <div key={key} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                                    <Checkbox
                                        id={"activar" + col.interno}
                                        label={col.titulo}
                                        disabled={false}
                                        checked={!col.disabled}
                                        onChange={(e) => {
                                            onChangeCheckbox(e, 0);
                                        }}
                                    />
                                </div>
                            );
                        }
                    })}
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                        <DefaultButton
                            text="Cerrar"
                            className="mr-2"
                            onClick={() => {
                                setAbrirFiltrosFechaCampos(false);
                            }}></DefaultButton>
                    </div>
                </div>
            </Panel>
        </React.Fragment>
    );
};

export default GrillaRendicion;
