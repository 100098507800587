import React from "react";
import "./CargaHoras.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { DefaultButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib-commonjs/DatePicker";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ComboBox } from "office-ui-fabric-react";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { basicReduxStore } from "../../reduxStore";
import { fetchEngine } from "../../fetchData";
import { AzureAD, AuthenticationState, IAzureADFunctionProps, MsalAuthProvider, LoginType, IAccountInfo } from "react-aad-msal";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { CargaHoras, Calendario, guardarNoche } from "../../controles/entidades/CargaHoras";
import NumberFormat from "react-number-format";
import moment from "moment";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

initializeIcons();

interface Props {
    provider: MsalAuthProvider;
}

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],

    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],

    shortDays: ["D", "L", "M", "M", "J", "V", "S"],

    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};

const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});
export class CargaProps {}

export class CargaState {
    car_info!: CargaHoras;
    opciones_usuarios: any[] = [];
    opciones_proyecto: any[] = [];
    opciones_tipo_tarea: any[] = [];
    opciones_rol: any[] = [];
    opciones_faena: any[] = [];
    opciones_frecuencia: any[] = [];
    opciones_tipo_calendario: any = [];
    contadorLeccion: number = 0;
    fecha_inicio?: Date;
    fecha_termino?: Date;
    fecha_carga?: Date;
    dialogoGuardar: boolean = false;
    dialogoError: boolean = false;
    estado_fechas?: boolean;
    fecha_inicio_mayor?: boolean;
    fecha_fin_menor?: boolean;

    correoUsuario: string = "";

    dialogoDiario: boolean = false;
    dialogoSemanal: boolean = false;
    dialogoMensual: boolean = false;

    dialogoBorrar: boolean = false;
    borrar: boolean = false;

    dialogoSalir: boolean = false;
    dialogoNotificar: boolean = false;

    esdia: boolean = true;

    fecha_semana_visualizar?: Date;
    fecha_semana_resta_inicio?: Date;
    fecha_semana_resta_fin?: Date;

    arrCalendarioLunes: any[] = [];
    arrCalendarioMartes: any[] = [];
    arrCalendarioMiercoles: any[] = [];
    arrCalendarioJueves: any[] = [];
    arrCalendarioViernes: any[] = [];
    arrCalendarioSabado: any[] = [];
    arrCalendarioDomingo: any[] = [];

    opcionesCalendario?: string;
    EliminarCarga: number = 0;
    CopiaSemanal: number = 0;

    cargaDiaDisabled?: boolean;

    nombre_usuario: string = "";

    formularioDisabled: boolean = false;

    opciones_proyecto_us: any[] = [];
    horas_noche: any[] = [];

    totalEnero: number = 0;
    totalFebrero: number = 0;
    totalMarzo: number = 0;
    totalAbril: number = 0;
    totalMayo: number = 0;
    totalJunio: number = 0;
    totalJulio: number = 0;
    totalAgosto: number = 0;
    totalSeptiembre: number = 0;
    totalOctubre: number = 0;
    totalNoviembre: number = 0;
    totalDiciembre: number = 0;

    cargar_noches: guardarNoche[] = [];

    correoaprobador: string = "";
    nombrecarga: string = "";
    agregarhora?: boolean;
    hora_dia: number = 0;
    hora_semana: number = 0;
    arrCargaInicio?: Date;
    arrCargaFin?: Date;
    testFeriado: any[] = [];
    arrFechaRango: any[] = [];
    test2: any[] = [];
    hasID?: boolean;
    opoID: number = 0;

    fechaprevia?: Date;
    fechanext?: Date;

    setSelectedDate?: Date;

    idcarga: number = 0;

    esAdmin?: boolean;
    esUsuario?: boolean;
    esGestion?: boolean;
    esValidador?: boolean;

    cargaInsertada: boolean = true;
    cargaNoInsertada: boolean = true;

    usuario_login: string = "";
    feriadosBD: any = [];
    tokenAPI: string = "";
}
export class Carga extends React.Component<CargaProps, CargaState> {
    constructor(props: any) {
        super(props);
        let pathName = window.location.pathname;
        let params = pathName.split("/");

        let hasID = params.length > 2;

        this.state = {
            car_info: {
                ID: 0,
                ID_PROYECTO: 0,
                ID_TIPO_TAREA: 0,
                ID_ROL: 0,
                ID_FAENA: 31,
                ID_FRECUENCIA: 0,
                ID_TIPO_CALENDARIO: 1,
                ID_USUARIO: 0,
                HH: 0,
                NOTA: "",
                FECHA_CARGA: undefined,
                TIPO_CARGA: "",
                ANIO: 0,
                MES: 0,
                SEMANA: 0,
                NOMBREUSUARIO: "",
                CORREOUSUARIO: "",
            },

            opciones_usuarios: [],
            opciones_proyecto: [],
            opciones_tipo_tarea: [],
            opciones_rol: [],
            opciones_faena: [],
            opciones_frecuencia: [],
            opciones_tipo_calendario: [],
            contadorLeccion: 250,
            dialogoGuardar: true,
            dialogoError: true,

            correoUsuario: "",

            dialogoDiario: true,
            dialogoSemanal: true,
            dialogoMensual: true,

            dialogoBorrar: true,
            borrar: true,

            dialogoSalir: true,
            dialogoNotificar: true,

            esdia: true,

            fecha_semana_visualizar: new Date(Date.now()),
            fecha_semana_resta_inicio: undefined,
            fecha_semana_resta_fin: undefined,

            arrCalendarioLunes: [],
            arrCalendarioMartes: [],
            arrCalendarioMiercoles: [],
            arrCalendarioJueves: [],
            arrCalendarioViernes: [],
            arrCalendarioSabado: [],
            arrCalendarioDomingo: [],

            opcionesCalendario: "",
            EliminarCarga: 0,
            CopiaSemanal: 0,

            cargaDiaDisabled: false,

            nombre_usuario: "",

            formularioDisabled: false,

            opciones_proyecto_us: [],
            horas_noche: [],

            totalEnero: 0,
            totalFebrero: 0,
            totalMarzo: 0,
            totalAbril: 0,
            totalMayo: 0,
            totalJunio: 0,
            totalJulio: 0,
            totalAgosto: 0,
            totalSeptiembre: 0,
            totalOctubre: 0,
            totalNoviembre: 0,
            totalDiciembre: 0,

            cargar_noches: [],
            correoaprobador: "",
            nombrecarga: "",
            agregarhora: true,
            hora_dia: 0,
            hora_semana: 0,
            arrCargaInicio: undefined,
            arrCargaFin: undefined,
            testFeriado: [],
            arrFechaRango: [],
            test2: [],
            hasID: hasID,
            opoID: hasID ? parseInt(params[2]) : 0,
            fechaprevia: undefined,
            fechanext: undefined,
            setSelectedDate: undefined,
            idcarga: 0,
            esAdmin: false,
            esUsuario: false,
            esGestion: false,
            esValidador: false,

            cargaInsertada: true,
            cargaNoInsertada: true,

            usuario_login: "",
            feriadosBD: [],
            tokenAPI: "",
        };

        this.eliminarCarga = this.eliminarCarga.bind(this);
        this._onFormatDate = this._onFormatDate.bind(this);
        this.textFieldOnChange = this.textFieldOnChange.bind(this);
        this.textFieldOnChangeHora = this.textFieldOnChangeHora.bind(this);
        this.rangoFechas = this.rangoFechas.bind(this);
    }

    componentDidMount() {
        let userName: any;
        let car = this.state.car_info;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        car.NOMBREUSUARIO = userName.account.name;
        car.CORREOUSUARIO = userName.account.userName;

        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                const tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                this.setState({ tokenAPI: d }, () => {
                    this.verificarAdmin();
                    this.verificarUsuario();
                    this.verificarGestion();
                    this.verificarValidador();
                    this.obtenerCargaNoche();
                    this.CargaNoche();
                    this.obtenerProyectos();
                    this.obtenerValor();
                    this.obtenerCarga();
                    this.apiFeriados();
                    if (this.state.fecha_semana_resta_inicio === undefined) {
                        let now = new Date(Date.now());
                        this.SeleccionFecha(now, true);
                    }
                });
            });
        }

        this.setState({ car_info: car, nombre_usuario: userName.account.name, usuario_login: userName.account.userName });
    }

    //CONSULTAS PERFILAMIENTO
    public verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esAdmin: result.ok });
            });
    };

    public verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esUsuario: result.ok });
            });
    };

    public verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esGestion: result.ok });
            });
    };

    public verificarValidador = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esValidador: result.ok });
            });
    };

    private _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    public async CargaNoche() {
        let enero: number = 0;
        let febrero: number = 0;
        let marzo: number = 0;
        let abril: number = 0;
        let mayo: number = 0;
        let junio: number = 0;
        let julio: number = 0;
        let agosto: number = 0;
        let septiembre: number = 0;
        let octubre: number = 0;
        let noviembre: number = 0;
        let diciembre: number = 0;

        this.state.horas_noche.map((a: any) => {
            this.state.opciones_proyecto.filter((e: any) => {
                if (a.ID_PROYECTO === e.key) {
                    e.ENERO = a.ENERO;
                    e.FEBRERO = a.FEBRERO;
                    e.MARZO = a.MARZO;
                    e.ABRIL = a.ABRIL;
                    e.MAYO = a.MAYO;
                    e.JUNIO = a.JUNIO;
                    e.JULIO = a.JULIO;
                    e.AGOSTO = a.AGOSTO;
                    e.SEPTIEMBRE = a.SEPTIEMBRE;
                    e.OCTUBRE = a.OCTUBRE;
                    e.NOVIEMBRE = a.NOVIEMBRE;
                    e.DICIEMBRE = a.DICIEMBRE;
                }
            });

            enero = enero + parseInt(a.ENERO);
            febrero = febrero + parseInt(a.FEBRERO);
            marzo = marzo + parseInt(a.MARZO);
            abril = abril + parseInt(a.ABRIL);
            mayo = mayo + parseInt(a.MAYO);
            junio = junio + parseInt(a.JUNIO);
            julio = julio + parseInt(a.JULIO);
            agosto = agosto + parseInt(a.AGOSTO);
            septiembre = septiembre + parseInt(a.SEPTIEMBRE);
            octubre = octubre + parseInt(a.OCTUBRE);
            noviembre = noviembre + parseInt(a.NOVIEMBRE);
            diciembre = diciembre + parseInt(a.DICIEMBRE);
        });
        this.setState({ totalEnero: enero });
        this.setState({ totalFebrero: febrero });
        this.setState({ totalMarzo: marzo });
        this.setState({ totalAbril: abril });
        this.setState({ totalMayo: mayo });
        this.setState({ totalJunio: junio });
        this.setState({ totalJulio: julio });
        this.setState({ totalAgosto: agosto });
        this.setState({ totalSeptiembre: septiembre });
        this.setState({ totalOctubre: octubre });
        this.setState({ totalNoviembre: noviembre });
        this.setState({ totalDiciembre: diciembre });
    }
    public async apiFeriados() {
        let ps = new Date();
        let year = ps.getFullYear();
        fetchEngine
            .getAPI(`https://apis.digital.gob.cl/fl/feriados/${year}`)
            .then((result: any) => {
                for (let index = 0; index < result.length; index++) {
                    const e = result[index];
                    let fecha = e.fecha;

                    this.state.testFeriado.push(fecha);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    public async obtenerCarga() {
        if (this.state.hasID) {
            let userName: any;
            let car = this.state.car_info;

            if (authProvider.getAccountInfo() != null) {
                userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
            }

            fetchEngine
                .getAPI(`/api/Carga/obtenerCarga/${this.state.opoID}`, {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.state.tokenAPI}`,
                })
                .then((result: any) => {
                    let resultado = result.data.carga[0];
                    car.ID_FAENA = resultado.ID_FAENA;
                    car.ID_PROYECTO = resultado.ID_PROYECTO;
                    car.ID_TIPO_TAREA = resultado.ID_TIPO_TAREA;
                    car.FECHA_CARGA = resultado.FECHA_CARGA != null ? new Date(resultado.FECHA_CARGA) : new Date();
                    car.HH = resultado.HH;
                    car.NOTA = resultado.NOTA;

                    this.setState({ car_info: car });
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }

    public notificarCarga() {
        let userName: any;
        let car = this.state.car_info;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let cargadehoras = {
            correo_aprobador: this.state.correoaprobador,
            nombre_carga: userName.account.name,
            proyecto: this.state.nombrecarga,
            hh: this.state.car_info.HH,
        };

        console.log(fetchEngine.urlFlujoCargaHoras);
        fetchEngine.postAPI(fetchEngine.urlFlujoCargaHoras ? fetchEngine.urlFlujoCargaHoras : "", JSON.stringify(cargadehoras), {
            Accept: "application/json",
            "Content-Type": "application/json",
        });
    }

    public async obtenerValor() {
        let userName: any;
        let car = this.state.car_info;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                const user: any[] = [];
                const ttar: any[] = [];
                const fae: any[] = [];
                const rol: any[] = [];
                const frec: any[] = [];
                const tcal: any[] = [];
                let idunidad = 0;
                let horadia = 0;
                if (result.data) {
                    {
                        result.data.consultores.map((a: any) => {
                            user.push({
                                key: a.ID,
                                text: a.CONSULTOR_DISPLAY + " - (" + a.CONSULTOR_LOGIN + ")",
                                correo: a.CONSULTOR_LOGIN,
                                nombre: a.CONSULTOR_DISPLAY,
                            });
                        });
                    }
                    {
                        result.data.tipo_tarea.map((a: any) => {
                            ttar.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                            });
                        });
                    }
                    {
                        result.data.faena.map((a: any) => {
                            fae.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                            });
                        });
                    }
                    {
                        result.data.cargos.map((a: any) => {
                            rol.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                            });
                        });
                    }
                    {
                        result.data.frecuencia.map((a: any) => {
                            frec.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                            });
                        });
                    }
                    {
                        result.data.tipo_calendario.map((a: any) => {
                            tcal.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                            });
                        });
                    }
                    result.data.consultores.map((e: any) => {
                        if (e.CONSULTOR_LOGIN === userName.account.userName.toLowerCase()) {
                            car.ID_USUARIO = e.ID;
                            car.ID_ROL = e.ID_CARGO;
                            idunidad = e.ID_UNIDAD_NEGOCIO;
                        }
                    });
                    result.data.unidades_negocios.map((e: any) => {
                        if (e.ID === idunidad) {
                            horadia = e.HORA_DIA;
                        }
                    });
                    this.setState({
                        opciones_usuarios: user,
                        opciones_tipo_tarea: ttar,
                        opciones_faena: fae,
                        opciones_rol: rol,
                        opciones_frecuencia: frec,
                        opciones_tipo_calendario: tcal,
                        car_info: car,
                        hora_dia: horadia,
                        feriadosBD: result.data.feriados,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    private async obtenerProyectos() {
        fetchEngine
            .getAPI(`/api/DatosFormulario/obtenerProyectos/${this.state.usuario_login}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                const pro: any[] = [];
                if (result.ok) {
                    result.data.proyectos.map((a: any) => {
                        if (a.ID_ESTADO === 13) {
                            pro.push({
                                key: a.ID,
                                text: a.ID + " - (" + a.NOMBRE + ")",
                                aprobador_horas: a.RESPONSABLE_HORAS_LOGIN,
                                ID_ESTADO: a.ID_ESTADO,
                            });
                        }
                    });

                    this.setState({ opciones_proyecto: pro });
                } else {
                    console.log(result.data);
                }
            });
    }

    public async guardarCarga() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        let car = this.state.car_info;
        if (this.state.esUsuario && this.state.esAdmin === false && this.state.esGestion === false && this.state.esValidador === false) {
            car.NOMBREUSUARIO = userName.account.name;
            car.CORREOUSUARIO = userName.account.userName;
            this.setState({ correoUsuario: userName.account.userName });
        }

        fetchEngine
            .postAPI("/api/Carga", car, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                const notificacionMasiva: any[] = [];
                if (resultado.ok) {
                    this.notificarCarga();
                    this.obtenerFechaSemana();
                    this.setState({ cargaInsertada: false });
                } else {
                    this.setState({ cargaNoInsertada: false });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public async obtenerFechaSemana() {
        const ini = this.state.fecha_semana_resta_inicio;
        const ini2 = this.state.fecha_semana_resta_inicio;
        const fin = this.state.fecha_semana_resta_fin;

        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let obj: any;
        if (this.state.esAdmin) {
            obj = {
                FECHA_DESDE: ini,
                FECHA_HASTA: fin,
                CREADO_POR_LOGIN: this.state.car_info.CORREOUSUARIO,
            };
        } else {
            obj = {
                FECHA_DESDE: ini,
                FECHA_HASTA: fin,
                CREADO_POR_LOGIN: userName.account.userName,
            };
        }

        fetchEngine
            .postAPI(`/api/Carga/GetCalendario`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    this.setState({
                        arrCalendarioLunes: resultado.data.lunes,
                        arrCalendarioMartes: resultado.data.martes,
                        arrCalendarioMiercoles: resultado.data.miercoles,
                        arrCalendarioJueves: resultado.data.jueves,
                        arrCalendarioViernes: resultado.data.viernes,
                        arrCalendarioSabado: resultado.data.sabado,
                        arrCalendarioDomingo: resultado.data.domingo,
                    });

                    let dlunes = resultado.data.lunes.reduce((a: any, b: any, i: any) => a + b.HH, 0);
                    let dmartes = resultado.data.martes.reduce((a: any, b: any, i: any) => a + b.HH, 0);
                    let dmiercoles = resultado.data.miercoles.reduce((a: any, b: any, i: any) => a + b.HH, 0);
                    let djueves = resultado.data.jueves.reduce((a: any, b: any, i: any) => a + b.HH, 0);
                    let dviernes = resultado.data.viernes.reduce((a: any, b: any, i: any) => a + b.HH, 0);
                    let dsabado = resultado.data.sabado.reduce((a: any, b: any, i: any) => a + b.HH, 0);
                    let ddomingo = resultado.data.domingo.reduce((a: any, b: any, i: any) => a + b.HH, 0);

                    let totaldias = dlunes + dmartes + dmiercoles + djueves + dviernes + dsabado + ddomingo;

                    this.setState({ hora_semana: totaldias });
                } else {
                    console.log(resultado.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });

        let dateArray = new Array();
        const currentDate2 = ini2;
        let currentDate = ini;
        if (currentDate != undefined) {
            if (fin != undefined) {
                while (currentDate <= fin) {
                    dateArray.push(
                        currentDate.getFullYear() +
                            "-" +
                            ("0" + (currentDate.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + currentDate.getDate()).slice(-2)
                    );
                    currentDate.setDate(currentDate.getDate() + 1);
                    currentDate = currentDate;
                }
            }

            currentDate.setDate(currentDate.getDate() - 7);
            currentDate = currentDate;
        }

        currentDate = currentDate2;

        this.setState({ arrFechaRango: [...dateArray] });
    }

    public async eliminarCarga(e: any) {
        let id = e.currentTarget === undefined ? e : e.currentTarget.getAttribute("data-index");

        fetchEngine
            .postAPI(`/api/Carga/EliminarCarga/${id}`, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    this.obtenerFechaSemana();
                    this.setState({ idcarga: 0 });
                } else {
                    console.log(result);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public async obtenerCopiaSemanal(e: any) {
        let id = e.currentTarget.getAttribute("data-index");

        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let obj = {
            ID: parseInt(id),
            FECHA_DESDE: this.state.fecha_semana_resta_inicio,
            FECHA_HASTA: this.state.fecha_semana_resta_fin,
            ID_TIPO_CALENDARIO: this.state.car_info.ID_TIPO_CALENDARIO,

            CREADO_POR_LOGIN: userName.account.userName,
        };

        fetchEngine
            .postAPI(`/api/Carga/CopiaSemanal`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    this.obtenerFechaSemana();
                } else {
                    console.log(result);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public async obtenerCargaNoche() {
        let car = this.state.car_info;

        fetchEngine
            .getAPI("/api/Carga/getNoche", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    resultado.data.carga.map((e: any) => {
                        this.state.horas_noche.push({
                            ID_PROYECTO: e.ID_PROYECTO,
                            ENERO: e.ENERO,
                            FEBRERO: e.FEBRERO,
                            MARZO: e.MARZO,
                            ABRIL: e.ABRIL,
                            MAYO: e.MAYO,
                            JUNIO: e.JUNIO,
                            JULIO: e.JULIO,
                            AGOSTO: e.AGOSTO,
                            SEPTIEMBRE: e.SEPTIEMBRE,
                            OCTUBRE: e.OCTUBRE,
                            NOVIEMBRE: e.NOVIEMBRE,
                            DICIEMBRE: e.DICIEMBRE,
                        });
                    });
                } else {
                    console.log(resultado.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public async insertarCargaNoche(e: any) {
        let userName: any;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        let proy = this.state.opciones_proyecto;
        let cargas = this.state.cargar_noches;
        let CARGA_NOCHE = {};

        proy.map((a: any) => {
            cargas.push({
                ID_PROYECTO: a.key,
                CONSULTOR_DISPLAY: userName.account.name,
                CONSULTOR_LOGIN: userName.account.userName,
                ENERO: a.ENERO,
                FEBRERO: a.FEBRERO,
                MARZO: a.MARZO,
                ABRIL: a.ABRIL,
                MAYO: a.MAYO,
                JUNIO: a.JUNIO,
                JULIO: a.JULIO,
                AGOSTO: a.AGOSTO,
                SEPTIEMBRE: a.SEPTIEMBRE,
                OCTUBRE: a.OCTUBRE,
                NOVIEMBRE: a.NOVIEMBRE,
                DICIEMBRE: a.DICIEMBRE,
            });
        });
        CARGA_NOCHE = {
            CARGA_NOCHE: cargas,
            CONSULTOR_DISPLAY: userName.account.name,
            CONSULTOR_LOGIN: userName.account.userName,
        };

        fetchEngine
            .postAPI("/api/Carga/carganoche", CARGA_NOCHE, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                } else {
                    console.log(result);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private _onChangeProyecto = (event: any, item: any): void => {
        let car = this.state.car_info;
        car.ID_PROYECTO = item.key;
        this.setState({ car_info: car, correoaprobador: item.aprobador_horas, nombrecarga: item.text });
    };
    private _onChangeTipoTarea = (event: any, item: any): void => {
        let car = this.state.car_info;
        car.ID_TIPO_TAREA = item.key;
        this.setState({ car_info: car });
    };
    private _onChangeFaena = (event: any, item: any): void => {
        let car = this.state.car_info;
        car.ID_FAENA = item.key;
        this.setState({ car_info: car });
    };
    private _onChangeTipoCalendario = (event: any, item: any): void => {
        let car = this.state.car_info;
        car.ID_TIPO_CALENDARIO = item.key;
        this.setState({ car_info: car });
    };
    private _onChangeUsuario = (event: any, item: any): void => {
        let car = this.state.car_info;
        car.ID_USUARIO = item.key;
        car.NOMBREUSUARIO = item.nombre;
        car.CORREOUSUARIO = item.correo;
        this.setState({ car_info: car, correoUsuario: item.correo, usuario_login: item.correo }, () => {
            this.obtenerProyectos();
            this.obtenerFechaSemana();
        });
    };

    public textFieldOnChange(ev: any) {
        let key = ev.target.id;
        let value = ev.target.value;
        let car = this.state.car_info;
        let val = 0;

        switch (key) {
            case "textHH":
                val = value === "" ? 0 : parseInt(value);
                car.HH = val < 0 ? 0 : val;
                car.HH = parseInt(value);
                break;
            case "textNota":
                if (value.length <= 250) {
                    car.NOTA = value;
                }
                break;
        }
        this.setState({ car_info: car });
    }

    public textFieldOnChangeHora(ev: any) {
        let valor = this.state.car_info;
        let value = parseFloat(ev.value);

        if (value >= 0) {
            valor.HH = parseFloat(value.toFixed(1));
        } else {
            valor.HH = 0;
        }

        this.setState({ car_info: valor });
    }

    public nocheOnChange(e: any) {
        let id = parseInt(e.target.getAttribute("data-index")); //id proyecto
        let key = e.target.id; // mes
        let value = parseInt(e.target.value); // valor noche text
        let horas = this.state.opciones_proyecto;

        switch (key) {
            case "Enero":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.ENERO = value;
                    }
                });
                break;
            case "Febrero":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.FEBRERO = value;
                    }
                });
                break;
            case "Marzo":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.MARZO = value;
                    }
                });
                break;
            case "Abril":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.ABRIL = value;
                    }
                });
                break;
            case "Mayo":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.MAYO = value;
                    }
                });
                break;
            case "Junio":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.JUNIO = value;
                    }
                });
                break;
            case "Julio":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.JULIO = value;
                    }
                });
                break;
            case "Agosto":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.AGOSTO = value;
                    }
                });
                break;
            case "Septiembre":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.SEPTIEMBRE = value;
                    }
                });
                break;
            case "Octubre":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.OCTUBRE = value;
                    }
                });
                break;
            case "Noviembre":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.NOVIEMBRE = value;
                    }
                });
                break;
            case "Diciembre":
                horas.map((e: any) => {
                    if (e.key === id) {
                        e.DICIEMBRE = value;
                    }
                });
                break;
        }

        this.setState({ opciones_proyecto: horas, horas_noche: horas });
        this.CargaNoche();
    }

    private _onSelectDateFechaCarga = (date: Date | null | undefined): void => {
        let car = this.state.car_info;
        this.setState({ estado_fechas: true });
        if (date != null) {
            if (car.FECHA_CARGA != undefined) {
                car.FECHA_CARGA = date;
            } else {
                car.FECHA_CARGA = date;
            }

            car.ANIO = date.getFullYear();
            car.MES = date.getMonth() + 1;
            car.SEMANA = moment(date).isoWeek();
        }

        this.setState({ car_info: car });
    };

    public contadorLeccion(ev: any) {
        let max = 250;
        let contadorTexto = this.state.contadorLeccion;
        let key = ev.target.value;
        let longitud = key.length;

        if (longitud <= max) {
            contadorTexto = max - longitud;
        }

        this.setState({ contadorLeccion: contadorTexto });
    }

    public validarAgregarCarga() {
        let car = this.state.car_info;
        if (
            car.ID_PROYECTO === undefined ||
            car.ID_PROYECTO === null ||
            car.ID_PROYECTO <= 0 ||
            car.ID_TIPO_TAREA === undefined ||
            car.ID_TIPO_TAREA === null ||
            car.ID_TIPO_TAREA <= 0 ||
            car.ID_FAENA === undefined ||
            car.ID_FAENA === null ||
            car.ID_FAENA <= 0 ||
            car.ID_USUARIO === undefined ||
            car.ID_USUARIO === null ||
            car.ID_USUARIO <= 0 ||
            car.HH <= 0 ||
            car.NOTA === null ||
            car.FECHA_CARGA === undefined
        ) {
            this.setState({ dialogoError: false });
        } else {
            this.setState({ car_info: car });
            this.guardarCarga();
        }
    }

    public dialogoInsertada() {
        return (
            <Dialog
                hidden={this.state.cargaInsertada}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Carga insertada",
                    subText: "La carga de hora se ha insertado correctamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ cargaInsertada: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoNoInsertada() {
        return (
            <Dialog
                hidden={this.state.cargaNoInsertada}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Carga no insertada",
                    subText: "A ocurrido un error al insertar la carga de hora",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ cargaNoInsertada: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoGuardar() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Insertar Carga",
                    subText: "¿Desea insertar la carga al calendario?",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.validarAgregarCarga();
                            this.setState({ dialogoGuardar: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoGuardar: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoNotificar() {
        return (
            <Dialog
                hidden={this.state.dialogoNotificar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Datos Notificados",
                    subText: "Datos Notificados exitosamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ dialogoNotificar: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoNotificar: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoSalir() {
        return (
            <Dialog
                hidden={this.state.dialogoSalir}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Salir",
                    subText: "¿Está seguro que desea salir? Los cambios efectuados no serán guardados",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Salir"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                            this.salirPT();
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cancelar"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public salirPT() {
        window.location.href = `/homeCarga`;
    }

    public dialogoError() {
        return (
            <Dialog
                hidden={this.state.dialogoError}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Campos incompletos",
                    subText: "Por favor complete todos los campos requeridos antes de guardar",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        {this.state.car_info.ID_PROYECTO === undefined ||
                        this.state.car_info.ID_PROYECTO === null ||
                        this.state.car_info.ID_PROYECTO <= 0 ? (
                            <div>Falta seleccionar proyecto</div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="ms-Grid-row">
                        {this.state.car_info.ID_TIPO_TAREA === undefined ||
                        this.state.car_info.ID_TIPO_TAREA === null ||
                        this.state.car_info.ID_TIPO_TAREA <= 0 ? (
                            <div>Falta seleccionar tipo tarea</div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="ms-Grid-row"></div>
                    <div className="ms-Grid-row">
                        {this.state.car_info.ID_FAENA === undefined || this.state.car_info.ID_FAENA === null || this.state.car_info.ID_FAENA <= 0 ? (
                            <div>Falta seleccionar faena</div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="ms-Grid-row">
                        {this.state.car_info.ID_USUARIO === undefined ||
                        this.state.car_info.ID_USUARIO === null ||
                        this.state.car_info.ID_USUARIO <= 0 ? (
                            <div>Falta seleccionar usuario</div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="ms-Grid-row">
                        {this.state.car_info.HH === undefined || this.state.car_info.HH === null || this.state.car_info.HH <= 0 ? (
                            <div>Falta seleccionar horas</div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="ms-Grid-row">
                        {this.state.car_info.FECHA_CARGA === undefined || this.state.car_info.FECHA_CARGA === null ? (
                            <div>Falta seleccionar fecha de carga</div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoError: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoEstInicioMayor() {
        return (
            <Dialog
                hidden={this.state.fecha_inicio_mayor}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Fecha incorrecta",
                    subText: "La fecha de inicio no puede ser superior a la de término",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ fecha_inicio_mayor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoEstFinMenor() {
        return (
            <Dialog
                hidden={this.state.fecha_fin_menor}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Frecuencia Incorrecta",
                    subText: "La fecha de termino no puede superar a la de inicio",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ fecha_fin_menor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoDiario() {
        return (
            <Dialog
                hidden={this.state.dialogoDiario}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Frecuencia Diaria",
                    subText: "La carga se realizara diariamente según la fecha seleccionada previamente",
                }}
                modalProps={{
                    styles: {
                        main: [
                            {
                                selectors: {
                                    ["@media (min-width: 480px)"]: {
                                        maxWidth: "400px",
                                        minWidth: "900px",
                                    },
                                },
                            },
                        ],
                    },
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DatePicker
                                id="fechaCargaInicio"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha de Inicio"
                                label="Fecha de Inicio"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DatePicker
                                id="fechaCargaTermino"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                label="Fecha de Termino"
                                placeholder="Fecha de Termino"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Lunes"} disabled={true} checked={true} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Miercoles"} disabled={true} checked={true} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Viernes"} disabled={true} checked={true} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Domingo"} disabled={true} checked={true} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Martes"} disabled={true} checked={true} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Jueves"} disabled={true} checked={true} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Sabado"} disabled={true} checked={true} />
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Guardar"
                        onClick={() => {
                            this.setState({ dialogoDiario: true });
                        }}></PrimaryButton>
                    <DefaultButton text="Cerrar"></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    public dialogoSemanal() {
        return (
            <Dialog
                hidden={this.state.dialogoSemanal}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Frecuencia Semanal",
                    subText: "Iniciar",
                }}
                modalProps={{
                    styles: {
                        main: [
                            {
                                selectors: {
                                    ["@media (min-width: 480px)"]: {
                                        maxWidth: "400px",
                                        minWidth: "900px",
                                    },
                                },
                            },
                        ],
                    },
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DatePicker
                                id="fechaCargaInicio"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha de Inicio"
                                label="Fecha de Inicio"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DatePicker
                                id="fechaCargaTermino"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                label="Fecha de Termino"
                                placeholder="Fecha de Termino"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                            />
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Lunes"} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Miercoles"} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Viernes"} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Domingo"} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Martes"} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Jueves"} />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                            <Checkbox id={"checkReem"} label={"Sabado"} />
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Guardar"
                        onClick={() => {
                            this.setState({ dialogoSemanal: true });
                        }}></PrimaryButton>
                    <DefaultButton text="Cerrar"></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    public dialogoMensual() {
        return (
            <Dialog
                hidden={this.state.dialogoMensual}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Frecuencia Mensual",
                    subText: "Iniciar",
                }}
                modalProps={{
                    styles: {
                        main: [
                            {
                                selectors: {
                                    ["@media (min-width: 480px)"]: {
                                        maxWidth: "400px",
                                        minWidth: "900px",
                                    },
                                },
                            },
                        ],
                    },
                }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DatePicker
                                id="fechaCargaInicio"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Fecha de Inicio"
                                label="Fecha de Inicio"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                            <DatePicker
                                id="fechaCargaTermino"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                label="Fecha de Termino"
                                placeholder="Fecha de Termino"
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                            />
                        </div>
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Guardar"
                        onClick={() => {
                            this.setState({ dialogoMensual: true });
                        }}></PrimaryButton>
                    <DefaultButton text="Cerrar"></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    public dialogoBorrar() {
        return (
            <Dialog
                hidden={this.state.dialogoBorrar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Eliminar Carga",
                    subText: "¿Desea eliminar esta carga?",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={(e) => {
                            this.eliminarCarga(this.state.idcarga);
                            this.setState({ dialogoBorrar: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoBorrar: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public convert(str: any) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [day, mnth, date.getFullYear()].join("-");
    }

    private SeleccionFecha = (fecha: Date | null | undefined, flag: boolean): void => {
        let fechaRango = [];
        let arrCargaInicio = undefined;
        let arrCargaFin = undefined;

        let fechaCarga_inicio;
        let fechaCarga_fin;

        let nombreDia = "";

        let fechaResta_inicio;
        let fechaResta_fin;

        if (fecha != null) {
            this.setState({ fecha_semana_visualizar: new Date(fecha) });
            fechaCarga_inicio = new Date(fecha);
            fechaCarga_fin = new Date(fecha);

            nombreDia = fecha.toLocaleDateString("es-ES", { weekday: "long" });

            fechaResta_inicio = new Date(fecha);
            fechaResta_fin = new Date(fecha);

            switch (nombreDia) {
                case "lunes":
                    fechaCarga_inicio = fechaResta_inicio;

                    fechaResta_fin.setDate(fechaResta_fin.getDate() + 6);
                    fechaCarga_fin = fechaResta_fin;
                    break;
                case "martes":
                    fechaResta_inicio.setDate(fechaResta_inicio.getDate() - 1);
                    fechaCarga_inicio = fechaResta_inicio;

                    fechaResta_fin.setDate(fechaResta_fin.getDate() + 5);
                    fechaCarga_fin = fechaResta_fin;
                    break;
                case "miércoles":
                    fechaResta_inicio.setDate(fechaResta_inicio.getDate() - 2);
                    fechaCarga_inicio = fechaResta_inicio;

                    fechaResta_fin.setDate(fechaResta_fin.getDate() + 4);
                    fechaCarga_fin = fechaResta_fin;
                    break;
                case "jueves":
                    fechaResta_inicio.setDate(fechaResta_inicio.getDate() - 3);
                    fechaCarga_inicio = fechaResta_inicio;

                    fechaResta_fin.setDate(fechaResta_fin.getDate() + 3);
                    fechaCarga_fin = fechaResta_fin;
                    break;
                case "viernes":
                    fechaResta_inicio.setDate(fechaResta_inicio.getDate() - 4);
                    fechaCarga_inicio = fechaResta_inicio;

                    fechaResta_fin.setDate(fechaResta_fin.getDate() + 2);
                    fechaCarga_fin = fechaResta_fin;

                    break;
                case "sábado":
                    fechaResta_inicio.setDate(fechaResta_inicio.getDate() - 5);
                    fechaCarga_inicio = fechaResta_inicio;

                    fechaResta_fin.setDate(fechaResta_fin.getDate() + 1);
                    fechaCarga_fin = fechaResta_fin;
                    break;
                case "domingo":
                    fechaResta_inicio.setDate(fechaResta_inicio.getDate() - 6);
                    fechaCarga_inicio = fechaResta_inicio;

                    fechaCarga_fin = fechaResta_fin;
                    break;
            }

            this.setState(
                {
                    fecha_semana_resta_inicio: fechaCarga_inicio,
                    fecha_semana_resta_fin: fechaCarga_fin,
                },
                () => {
                    if (flag === true) {
                        this.obtenerFechaSemana();
                    }
                }
            );
        }
    };

    public semana_anterior() {
        let anterior = this.state.fecha_semana_visualizar;
        let fecha_nueva;
        fecha_nueva = moment(moment(anterior).add(-7, "days")).toDate();

        this.SeleccionFecha(fecha_nueva, true);
    }

    public semana_siguiente() {
        let siguiente = this.state.fecha_semana_visualizar;
        let fecha_nueva;
        fecha_nueva = moment(moment(siguiente).add(7, "days")).toDate();

        this.SeleccionFecha(fecha_nueva, true);
    }

    public rangoFechas(startDate: Date | null | undefined, stopDate: Date | null | undefined) {
        var dateArray = new Array();
        var currentDate = startDate;
        if (currentDate != undefined) {
            if (stopDate != undefined) {
                while (currentDate <= stopDate) {
                    dateArray.push(
                        currentDate.getFullYear() +
                            "-" +
                            ("0" + (currentDate.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + currentDate.getDate()).slice(-2)
                    );
                    currentDate.setDate(currentDate.getDate() + 1);
                    currentDate = currentDate;
                }
            }
        }
        return dateArray;
    }

    public checkFeriado(fecha: any) {
        let feriados = this.state.testFeriado;
        for (let i = 0; i < feriados.length; i++) {
            const e = feriados[i];
            if (fecha == e) {
                return true;
            }
        }
        let feriadosManuales = this.state.feriadosBD;
        for (let i = 0; i < feriadosManuales.length; i++) {
            let fechaFeriado = feriadosManuales[i].FECHA.split("T")[0];
            if (fecha == fechaFeriado) {
                return true;
            }
        }

        return false;
    }

    public formatDate(fecha: string) {
        return fecha.split("-")[2] + "/" + fecha.split("-")[1] + "/" + fecha.split("-")[0];
    }

    render() {
        return (
            <React.Fragment>
                <BotonAyuda token={this.state.tokenAPI} />
                <div className="fuente">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                                <div className="iconoRuta">
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                    &nbsp;
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Carga de Horas</span>
                                </div>
                                <div className="titulosimulador">
                                    <Glyphicon glyph="time" /> Carga de Horas
                                </div>
                            </div>

                            <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                                <div className="fijado">
                                    <DefaultButton
                                        text="Salir"
                                        onClick={() => {
                                            this.setState({ dialogoSalir: false });
                                        }}></DefaultButton>
                                    <PrimaryButton
                                        text="Insertar"
                                        onClick={() => {
                                            this.validarAgregarCarga();
                                        }}
                                        disabled={this.state.formularioDisabled}></PrimaryButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pagina">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Usuario"
                                        autoComplete="on"
                                        allowFreeform={true}
                                        placeholder="Seleccionar..."
                                        options={this.state.opciones_usuarios}
                                        onChange={this._onChangeUsuario}
                                        selectedKey={this.state.car_info.ID_USUARIO}
                                        disabled={this.state.formularioDisabled ? true : this.state.esAdmin ? false : true}></ComboBox>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Proyecto"
                                        required={true}
                                        autoComplete="on"
                                        allowFreeform={true}
                                        placeholder="Seleccionar..."
                                        options={this.state.opciones_proyecto}
                                        onChange={this._onChangeProyecto}
                                        selectedKey={this.state.car_info.ID_PROYECTO}
                                        disabled={this.state.formularioDisabled}></ComboBox>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Tipo Tarea"
                                        required={true}
                                        autoComplete="on"
                                        allowFreeform={true}
                                        placeholder="Seleccionar..."
                                        options={this.state.opciones_tipo_tarea}
                                        onChange={this._onChangeTipoTarea}
                                        selectedKey={this.state.car_info.ID_TIPO_TAREA}
                                        disabled={this.state.formularioDisabled}></ComboBox>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Faena"
                                        required={true}
                                        autoComplete="on"
                                        allowFreeform={true}
                                        placeholder="Seleccionar..."
                                        options={this.state.opciones_faena}
                                        onChange={this._onChangeFaena}
                                        selectedKey={this.state.car_info.ID_FAENA}
                                        disabled={this.state.formularioDisabled}></ComboBox>
                                </div>

                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                                    <DatePicker
                                        id="fechaCarga"
                                        className={controlClass.control}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        strings={DayPickerStrings}
                                        placeholder="Seleccionar Fecha"
                                        label="Fecha Carga"
                                        ariaLabel="Seleccione una fecha"
                                        formatDate={this._onFormatDate}
                                        value={this.state.car_info.FECHA_CARGA}
                                        onSelectDate={this._onSelectDateFechaCarga}
                                        disabled={this.state.formularioDisabled}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg1">
                                    <span className={"formateo"}>
                                        <label>HH</label>
                                        <NumberFormat
                                            required={true}
                                            id={"textHH"}
                                            className={"numberinput"}
                                            title="HH"
                                            min={0}
                                            value={this.state.car_info.HH <= 0 ? "" : this.state.car_info.HH.toString().replace(".", ",")}
                                            disabled={this.state.formularioDisabled}
                                            decimalSeparator={","}
                                            thousandSeparator={"."}
                                            onValueChange={(value) => {
                                                this.textFieldOnChangeHora(value);
                                            }}
                                        />
                                    </span>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5">
                                    <TextField
                                        label="Nota"
                                        multiline
                                        autoAdjustHeight
                                        id={"textNota"}
                                        value={this.state.car_info.NOTA}
                                        onChange={(e) => {
                                            this.textFieldOnChange(e);
                                            this.contadorLeccion(e);
                                        }}
                                        disabled={this.state.formularioDisabled}></TextField>
                                    <label>Quedan {this.state.contadorLeccion} carácteres.</label>
                                </div>
                            </div>

                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 card mt-1 p-1 fondoCard">
                                    <div className="card-body p-0">
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                                            <DatePicker
                                                id="fechaSemanal"
                                                className={controlClass.control}
                                                firstDayOfWeek={DayOfWeek.Monday}
                                                strings={DayPickerStrings}
                                                placeholder="Seleccione una fecha"
                                                label="Semana a Visualizar"
                                                ariaLabel="Seleccione una fecha"
                                                formatDate={this._onFormatDate}
                                                onSelectDate={(e) => {
                                                    this.SeleccionFecha(e, true);
                                                }}
                                                value={this.state.fecha_semana_visualizar}
                                                disabled={this.state.formularioDisabled}
                                            />
                                            <DefaultButton
                                                className="border border-secondary align-top mr-2"
                                                onClick={() => {
                                                    this.semana_anterior();
                                                }}>
                                                <Glyphicon glyph="chevron-left" /> Anterior
                                            </DefaultButton>
                                            <DefaultButton
                                                className="border border-secondary"
                                                onClick={() => {
                                                    this.semana_siguiente();
                                                }}>
                                                Siguiente <Glyphicon glyph="chevron-right" />
                                            </DefaultButton>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3">
                                            <ComboBox
                                                label="Tipo Calendario"
                                                autoComplete="on"
                                                allowFreeform={true}
                                                placeholder="Seleccionar..."
                                                options={this.state.opciones_tipo_calendario}
                                                onChange={this._onChangeTipoCalendario}
                                                disabled={this.state.formularioDisabled}
                                                selectedKey={this.state.car_info.ID_TIPO_CALENDARIO}></ComboBox>
                                        </div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3"></div>
                                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg3 card mt-3 bg-light border-info">
                                            <div className="card-body p-1 m-0 totalSemanal">
                                                <label className="mr-2">Total Semanal</label>
                                                <TextField
                                                    value={this.state.hora_semana.toString()}
                                                    id=""
                                                    className="inputTotalSemanal mr-2"
                                                    disabled={true}></TextField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg5 mt-2">
                                    <ul className="nav">
                                        <li
                                            className="nav-item"
                                            onClick={(e) => {
                                                this.setState({ esdia: true, formularioDisabled: false });
                                            }}>
                                            <a className={this.state.esdia ? "nav-link active" : "nav-link"}>Declaración horas Día</a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg7 mt-2 d-flex justify-content-end">
                                    <div className="cajaInfo mr-4">
                                        <span className="bg-success rounded-circle infoDetalle">&nbsp;</span>Horas Cargadas
                                    </div>
                                    <div className="cajaInfo mr-4">
                                        <span className="bg-warning rounded-circle infoDetalle">&nbsp;</span>Exceso/Falta de horas
                                    </div>
                                    <div className="cajaInfo mr-4">
                                        <span className="bg-danger rounded-circle infoDetalle">&nbsp;</span>Sin Carga
                                    </div>
                                    <div className="cajaInfo mr-4 mt-2">
                                        <Glyphicon glyph="info-sign" className="text-secondary" /> Carga realizada en fin de semana/feriados
                                    </div>
                                </div>
                            </div>

                            {this.state.esdia ? (
                                <div className="ms-Grid-row">
                                    {
                                        /* CALENDARIO DIA */
                                        this.state.car_info.ID_TIPO_CALENDARIO === 1 ? (
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 p-0 mt-3">
                                                <div className="ms-Grid-col cajaDia">
                                                    {this.checkFeriado(this.state.arrFechaRango[0]) === true &&
                                                    this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Lunes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                            <Glyphicon glyph="info-sign" className="iconDia" />
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[0]) === true &&
                                                      this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Lunes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                      this.state.arrCalendarioLunes.length <= 0 ? (
                                                        <div className="card-header bg-danger text-white">
                                                            Lunes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                        </div>
                                                    ) : (this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                          this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                      (this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                          this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                        <div className="card-header bg-warning text-white">
                                                            Lunes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                        </div>
                                                    ) : (
                                                        <div className="card-header bg-success text-white">
                                                            Lunes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                        </div>
                                                    )}
                                                    <div className="card ModuloDia">
                                                        {this.state.arrCalendarioLunes.length <= 0 ? (
                                                            <div>
                                                                <div className="card-body">
                                                                    <h2 className="card-title">Sin carga</h2>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.arrCalendarioLunes.map((e: any) => {
                                                                return (
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <button
                                                                                    type="button"
                                                                                    className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                    title="Borrar"
                                                                                    key={e.ID}
                                                                                    data-index={e.ID}
                                                                                    onClick={(x) => {
                                                                                        this.setState({
                                                                                            dialogoBorrar: false,
                                                                                            idcarga: e.ID,
                                                                                        });
                                                                                    }}></button>{" "}
                                                                                OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                            </h5>
                                                                            <p className="card-text">{e.HH} HH</p>
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-copy"
                                                                                title="Copia Semanal"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-col cajaDia">
                                                    {this.checkFeriado(this.state.arrFechaRango[1]) === true &&
                                                    this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Martes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                            <Glyphicon glyph="info-sign" className="iconDia" />
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[1]) === true &&
                                                      this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Martes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                      this.state.arrCalendarioMartes.length <= 0 ? (
                                                        <div className="card-header bg-danger text-white">
                                                            Martes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                        </div>
                                                    ) : (this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                          this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                      (this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                          this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                        <div className="card-header bg-warning text-white">
                                                            Martes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                        </div>
                                                    ) : (
                                                        <div className="card-header bg-success text-white">
                                                            Martes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                        </div>
                                                    )}
                                                    <div className="card ModuloDia">
                                                        {this.state.arrCalendarioMartes.length <= 0 ? (
                                                            <div>
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Sin carga</h3>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.arrCalendarioMartes.map((e: any) => {
                                                                return (
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <button
                                                                                    type="button"
                                                                                    className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                    title="Borrar"
                                                                                    key={e.ID}
                                                                                    data-index={e.ID}
                                                                                    onClick={(x) => {
                                                                                        this.setState({
                                                                                            dialogoBorrar: false,
                                                                                            idcarga: e.ID,
                                                                                        });
                                                                                    }}></button>{" "}
                                                                                OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                            </h5>
                                                                            <p className="card-text">{e.HH} HH</p>
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-copy"
                                                                                title="Copia Semanal"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col cajaDia">
                                                    {this.checkFeriado(this.state.arrFechaRango[2]) === true &&
                                                    this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Miércoles{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                            <Glyphicon glyph="info-sign" className="iconDia" />
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[2]) === true &&
                                                      this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Miércoles{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                      this.state.arrCalendarioMiercoles.length <= 0 ? (
                                                        <div className="card-header bg-danger text-white">
                                                            Miércoles{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                        </div>
                                                    ) : (this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                          this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                      (this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                          this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) <
                                                              this.state.hora_dia) ? (
                                                        <div className="card-header bg-warning text-white">
                                                            Miércoles{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                        </div>
                                                    ) : (
                                                        <div className="card-header bg-success text-white">
                                                            Miércoles{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                        </div>
                                                    )}
                                                    <div className="card ModuloDia">
                                                        {this.state.arrCalendarioMiercoles.length <= 0 ? (
                                                            <div>
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Sin carga</h3>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.arrCalendarioMiercoles.map((e: any) => {
                                                                return (
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <button
                                                                                    type="button"
                                                                                    className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                    title="Borrar"
                                                                                    key={e.ID}
                                                                                    data-index={e.ID}
                                                                                    onClick={(x) => {
                                                                                        this.setState({
                                                                                            dialogoBorrar: false,
                                                                                            idcarga: e.ID,
                                                                                        });
                                                                                    }}></button>{" "}
                                                                                OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                            </h5>
                                                                            <p className="card-text">{e.HH} HH</p>
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-copy"
                                                                                title="Copia Semanal"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col cajaDia">
                                                    {this.checkFeriado(this.state.arrFechaRango[3]) === true &&
                                                    this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Jueves{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                            <Glyphicon glyph="info-sign" className="iconDia" />
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[3]) === true &&
                                                      this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Jueves{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                      this.state.arrCalendarioJueves.length <= 0 ? (
                                                        <div className="card-header bg-danger text-white">
                                                            Jueves{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                        </div>
                                                    ) : (this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                          this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                      (this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                          this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                        <div className="card-header bg-warning text-white">
                                                            Jueves{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                        </div>
                                                    ) : (
                                                        <div className="card-header bg-success text-white">
                                                            Jueves{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                        </div>
                                                    )}
                                                    <div className="card ModuloDia">
                                                        {this.state.arrCalendarioJueves.length <= 0 ? (
                                                            <div>
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Sin carga</h3>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.arrCalendarioJueves.map((e: any) => {
                                                                return (
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <button
                                                                                    type="button"
                                                                                    className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                    title="Borrar"
                                                                                    key={e.ID}
                                                                                    data-index={e.ID}
                                                                                    onClick={(x) => {
                                                                                        this.setState({
                                                                                            dialogoBorrar: false,
                                                                                            idcarga: e.ID,
                                                                                        });
                                                                                    }}></button>{" "}
                                                                                OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                            </h5>
                                                                            <p className="card-text">{e.HH} HH</p>
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-copy"
                                                                                title="Copia Semanal"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-col cajaDia">
                                                    {this.checkFeriado(this.state.arrFechaRango[4]) === true &&
                                                    this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Viernes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                            <Glyphicon glyph="info-sign" className="iconDia" />
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[4]) === true &&
                                                      this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Viernes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                        </div>
                                                    ) : this.checkFeriado(this.state.arrFechaRango[4]) === false &&
                                                      this.state.arrCalendarioViernes.length <= 0 ? (
                                                        <div className="card-header bg-danger text-white">
                                                            Viernes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                        </div>
                                                    ) : (this.checkFeriado(this.state.arrFechaRango[4]) === false &&
                                                          this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                      (this.checkFeriado(this.state.arrFechaRango[4]) === false &&
                                                          this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                        <div className="card-header bg-warning text-white">
                                                            Viernes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                        </div>
                                                    ) : (
                                                        <div className="card-header bg-success text-white">
                                                            Viernes{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                        </div>
                                                    )}
                                                    <div className="card ModuloDia">
                                                        {this.state.arrCalendarioViernes.length <= 0 ? (
                                                            <div>
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Sin carga</h3>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.arrCalendarioViernes.map((e: any) => {
                                                                return (
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <button
                                                                                    type="button"
                                                                                    className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                    title="Borrar"
                                                                                    key={e.ID}
                                                                                    data-index={e.ID}
                                                                                    onClick={(x) => {
                                                                                        this.setState({
                                                                                            dialogoBorrar: false,
                                                                                            idcarga: e.ID,
                                                                                        });
                                                                                    }}></button>{" "}
                                                                                OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                            </h5>
                                                                            <p className="card-text">{e.HH} HH</p>
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-copy"
                                                                                title="Copia Semanal"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-col cajaDia">
                                                    {(this.checkFeriado(this.state.arrFechaRango[5]) === true ||
                                                        this.checkFeriado(this.state.arrFechaRango[5]) === false) &&
                                                    this.state.arrCalendarioSabado.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Sábado{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                            <Glyphicon glyph="info-sign" className="iconDia" />
                                                        </div>
                                                    ) : (
                                                        <div className="card-header bg-secondary  text-white">
                                                            Sábado{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                        </div>
                                                    )}
                                                    <div className="card ModuloDia">
                                                        {this.state.arrCalendarioSabado.length <= 0 ? (
                                                            <div>
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Sin carga</h3>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.arrCalendarioSabado.map((e: any) => {
                                                                return (
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <button
                                                                                    type="button"
                                                                                    className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                    title="Borrar"
                                                                                    key={e.ID}
                                                                                    data-index={e.ID}
                                                                                    onClick={(x) => {
                                                                                        this.setState({
                                                                                            dialogoBorrar: false,
                                                                                            idcarga: e.ID,
                                                                                        });
                                                                                    }}></button>{" "}
                                                                                OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                            </h5>
                                                                            <p className="card-text">{e.HH} HH</p>
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-copy"
                                                                                title="Copia Semanal"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="ms-Grid-col cajaDia">
                                                    {(this.checkFeriado(this.state.arrFechaRango[6]) === true ||
                                                        this.checkFeriado(this.state.arrFechaRango[6]) === false) &&
                                                    this.state.arrCalendarioDomingo.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                        <div className="card-header bg-secondary text-white">
                                                            Domingo{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                            <Glyphicon glyph="info-sign" className="iconDia" />
                                                        </div>
                                                    ) : (
                                                        <div className="card-header bg-secondary  text-white">
                                                            Domingo{" "}
                                                            {this.state.arrFechaRango[0] === undefined
                                                                ? ""
                                                                : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                        </div>
                                                    )}

                                                    <div className="card ModuloDia">
                                                        {this.state.arrCalendarioDomingo.length <= 0 ? (
                                                            <div>
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Sin carga</h3>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            this.state.arrCalendarioDomingo.map((e: any) => {
                                                                return (
                                                                    <div>
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">
                                                                                <button
                                                                                    type="button"
                                                                                    className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                    title="Borrar"
                                                                                    key={e.ID}
                                                                                    data-index={e.ID}
                                                                                    onClick={(x) => {
                                                                                        this.setState({
                                                                                            dialogoBorrar: false,
                                                                                            idcarga: e.ID,
                                                                                        });
                                                                                    }}></button>{" "}
                                                                                OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                            </h5>
                                                                            <p className="card-text">{e.HH} HH</p>
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-copy"
                                                                                title="Copia Semanal"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )
                                    }
                                    {this.state.car_info.ID_TIPO_CALENDARIO === 2 ? (
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 p-0 mt-3">
                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[0]) === true &&
                                                this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[0]) === true &&
                                                  this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                  this.state.arrCalendarioLunes.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                      this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                      this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioLunes.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h2 className="card-title">Sin carga</h2>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioLunes.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[1]) === true &&
                                                this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[1]) === true &&
                                                  this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                  this.state.arrCalendarioMartes.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                      this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                      this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioMartes.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioMartes.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[2]) === true &&
                                                this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[2]) === true &&
                                                  this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                  this.state.arrCalendarioMiercoles.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                      this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                      this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioMiercoles.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioMiercoles.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[3]) === true &&
                                                this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[3]) === true &&
                                                  this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                  this.state.arrCalendarioJueves.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                      this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                      this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioJueves.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioJueves.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {(this.checkFeriado(this.state.arrFechaRango[4]) === true ||
                                                    this.checkFeriado(this.state.arrFechaRango[4]) === false) &&
                                                this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Viernes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-secondary  text-white">
                                                        Viernes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioViernes.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioViernes.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {(this.checkFeriado(this.state.arrFechaRango[5]) === true ||
                                                    this.checkFeriado(this.state.arrFechaRango[5]) === false) &&
                                                this.state.arrCalendarioSabado.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Sábado{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-secondary  text-white">
                                                        Sábado{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioSabado.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioSabado.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {(this.checkFeriado(this.state.arrFechaRango[6]) === true ||
                                                    this.checkFeriado(this.state.arrFechaRango[6]) === false) &&
                                                this.state.arrCalendarioDomingo.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Domingo{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-secondary  text-white">
                                                        Domingo{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioDomingo.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioDomingo.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.car_info.ID_TIPO_CALENDARIO === 3 ? (
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 p-0 mt-3">
                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[0]) === true &&
                                                this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[0]) === true &&
                                                  this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                  this.state.arrCalendarioLunes.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                      this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[0]) === false &&
                                                      this.state.arrCalendarioLunes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Lunes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[0].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioLunes.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h2 className="card-title">Sin carga</h2>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioLunes.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[1]) === true &&
                                                this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[1]) === true &&
                                                  this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                  this.state.arrCalendarioMartes.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                      this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[1]) === false &&
                                                      this.state.arrCalendarioMartes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Martes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[1].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioMartes.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioMartes.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[2]) === true &&
                                                this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[2]) === true &&
                                                  this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                  this.state.arrCalendarioMiercoles.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                      this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[2]) === false &&
                                                      this.state.arrCalendarioMiercoles.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Miércoles{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[2].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioMiercoles.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioMiercoles.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[3]) === true &&
                                                this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[3]) === true &&
                                                  this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                  this.state.arrCalendarioJueves.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                      this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[3]) === false &&
                                                      this.state.arrCalendarioJueves.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Jueves{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[3].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioJueves.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioJueves.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[4]) === true &&
                                                this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Viernes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[4]) === true &&
                                                  this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Viernes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[4]) === false &&
                                                  this.state.arrCalendarioViernes.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Viernes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[4]) === false &&
                                                      this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[4]) === false &&
                                                      this.state.arrCalendarioViernes.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Viernes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Viernes{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[4].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioViernes.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioViernes.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[5]) === true &&
                                                this.state.arrCalendarioSabado.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Sábado{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[5]) === true &&
                                                  this.state.arrCalendarioSabado.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Sábado{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[5]) === false &&
                                                  this.state.arrCalendarioSabado.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Sábado{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[5]) === false &&
                                                      this.state.arrCalendarioSabado.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[5]) === false &&
                                                      this.state.arrCalendarioSabado.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Sábado{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Sábado{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[5].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioSabado.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioSabado.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>

                                            <div className="ms-Grid-col cajaDia">
                                                {this.checkFeriado(this.state.arrFechaRango[6]) === true &&
                                                this.state.arrCalendarioDomingo.reduce((a, b, i) => a + b.HH, 0) > 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Domingo{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                        <Glyphicon glyph="info-sign" className="iconDia" />
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[6]) === true &&
                                                  this.state.arrCalendarioDomingo.reduce((a, b, i) => a + b.HH, 0) <= 0 ? (
                                                    <div className="card-header bg-secondary text-white">
                                                        Domingo{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                    </div>
                                                ) : this.checkFeriado(this.state.arrFechaRango[6]) === false &&
                                                  this.state.arrCalendarioDomingo.length <= 0 ? (
                                                    <div className="card-header bg-danger text-white">
                                                        Domingo{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                    </div>
                                                ) : (this.checkFeriado(this.state.arrFechaRango[6]) === false &&
                                                      this.state.arrCalendarioDomingo.reduce((a, b, i) => a + b.HH, 0) > this.state.hora_dia) ||
                                                  (this.checkFeriado(this.state.arrFechaRango[6]) === false &&
                                                      this.state.arrCalendarioDomingo.reduce((a, b, i) => a + b.HH, 0) < this.state.hora_dia) ? (
                                                    <div className="card-header bg-warning text-white">
                                                        Domingo{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                    </div>
                                                ) : (
                                                    <div className="card-header bg-success text-white">
                                                        Domingo{" "}
                                                        {this.state.arrFechaRango[0] === undefined
                                                            ? ""
                                                            : this.formatDate(this.state.arrFechaRango[6].toString())}
                                                    </div>
                                                )}
                                                <div className="card ModuloDia">
                                                    {this.state.arrCalendarioDomingo.length <= 0 ? (
                                                        <div>
                                                            <div className="card-body">
                                                                <h3 className="card-title">Sin carga</h3>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        this.state.arrCalendarioDomingo.map((e: any) => {
                                                            return (
                                                                <div>
                                                                    <div className="card-body">
                                                                        <h5 className="card-title">
                                                                            <button
                                                                                type="button"
                                                                                className="glyphicon glyphicon-remove btnBorrarDia"
                                                                                title="Borrar"
                                                                                key={e.ID}
                                                                                data-index={e.ID}
                                                                                onClick={(x) => {
                                                                                    this.setState({
                                                                                        dialogoBorrar: false,
                                                                                        idcarga: e.ID,
                                                                                    });
                                                                                }}></button>{" "}
                                                                            OT- {e.ID_PROYECTO} - {e.NOMBRE}{" "}
                                                                        </h5>
                                                                        <p className="card-text">{e.HH} HH</p>
                                                                        <button
                                                                            type="button"
                                                                            className="glyphicon glyphicon-copy"
                                                                            title="Copia Semanal"
                                                                            key={e.ID}
                                                                            data-index={e.ID}
                                                                            onClick={(e) => this.obtenerCopiaSemanal(e)}></button>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {/* fin calendario dia */}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                    {this.dialogoError()}
                    {this.dialogoGuardar()}
                    {this.dialogoNoInsertada()}
                    {this.dialogoInsertada()}
                    {this.dialogoNotificar()}
                    {this.dialogoEstInicioMayor()}
                    {this.dialogoEstFinMenor()}
                    {this.dialogoDiario()}
                    {this.dialogoSemanal()}
                    {this.dialogoMensual()}
                    {this.dialogoBorrar()}
                    {this.dialogoSalir()}
                </div>
            </React.Fragment>
        );
    }
}

export default Carga;
