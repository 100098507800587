import React, { useState, useEffect } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AzureAD, AuthenticationState, IAzureADFunctionProps, MsalAuthProvider, LoginType } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import { basicReduxStore } from "../../reduxStore";
import Autosuggest from "react-autosuggest";
import "react-autosuggest";
import "./Example.css";
import axios from "axios";
import { fetchEngine } from "../../fetchData";
interface Props {
    provider: MsalAuthProvider;
}

const Examples = () => {
    const theme = {
        foo: {
            color: "red",
            ":hover": {
                color: "green",
            },
        },
        bar: {
            color: "blue",
        },
    };

    // const theme = {
    //     container: 'react-autosuggest__container',
    //     containerOpen: 'react-autosuggest__container--open',
    //     input: 'react-autosuggest__input',
    //     inputOpen: 'react-autosuggest__input--open',
    //     inputFocused: 'react-autosuggest__input--focused',
    //     sugestionsContainer: 'react-autosuggest__suggestions-container',
    //     suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
    //     suggestionsList: 'react-autosuggest__suggestions-list',
    //     suggestion: 'react-autosuggest__suggestion',
    //     suggestionFirst: 'react-autosuggest__suggestion--first',
    //     suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
    //     sectionContainer: 'react-autosuggest__section-container',
    //     sectionContainerFirst: 'react-autosuggest__section-container--first',
    //     sectionTitle: 'react-autosuggest__section-title'
    // };
    const [isOpen, setToggle] = useState(false);
    const [alto, setAlto] = useState(0);
    const [userOpen, setuserOpen] = useState(false);
    const [value, setValue] = useState("");

    const [file, setFile] = useState({ name: "" });
    const [suggestions, setsuggestions] = useState([{ year: 0, name: "" }]);

    const graphEndPoint = fetchEngine.graphEndPoint;

    const toggle = () => {
        setToggle(!isOpen);
    };

    const toggleUser = () => {
        setuserOpen(!userOpen);
    };

    const [users, setUsers] = useState(undefined);

    useEffect(() => {
        // Actualiza el título del documento usando la API del navegador

        return () => {
            //console.log("Fin")
        };
    }, [authProvider]);

    const getUsers = (ev: any) => {
        //console.log();
        if (authProvider.authenticationState === AuthenticationState.Authenticated) {
            ////console.log("autenticado");
            let texto = ev.target != null ? ev.target.value : "";
            //let id = authProvider.acquireTokenSilent({ scopes: ['User.Read.All'] });
            let id = fetchEngine.acquireTokenAPIGraph(authProvider);

            if (id != null) {
                id.then((d) => {
                    //console.log(d.accessToken);
                    fetch(`${graphEndPoint}/users?$filter=startsWith(displayName,'${texto}')&$select=id,displayName,mail`, {
                        method: "GET",
                        mode: "cors",
                        cache: "no-cache",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + d.accessToken,
                        },
                    }).then((result) => {
                        //console.log(result);

                        result.json().then((r) => {
                            //console.log(r);
                        });
                    });
                });
            }
        }
    };

    const pruebaAPI = async () => {
        //let token = await authProvider.acquireTokenSilent({ scopes: ['3777bf5e-b7a5-4ad3-a07c-5b4dfbb7b6bc/Server.Read'] });

        let token = await fetchEngine.acquireTokenAPIGraph(authProvider);

        //let tokenSP = await authProvider.acquireTokenSilent({ scopes: ['https://imagen.sharepoint.com/AllSites.FullControl'] });
        let tokenSP = await fetchEngine.acquireTokenAPISharePoint(authProvider);

        let obj = {
            Token: tokenSP.accessToken,
            NombreOportunidad: "ddd",
        };

        fetchEngine
            .postAPI("/api/workspaces", obj, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`,
                },
            })
            .then((res) => {
                //console.log(res);
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const obtenerSitio = async () => {
        // let id = authProvider.acquireTokenSilent({ scopes: ['Sites.ReadWrite.All'] });

        //     let tenant = "imagen";
        //     let pathToSite = "/sites/pt";

        //     id.then(res => {

        //let tokent = res.accessToken;
        let token = await authProvider.acquireTokenSilent({ scopes: ["Sites.ReadWrite.All", "Files.ReadWrite.All"] });

        let tenant = "imagen";
        let pathToSite = "/sites/pt";

        var headers = new Headers();
        headers.append("Content-Type", "application/json");
        headers.append("Authorization", "Bearer ");
        axios
            .get(`${graphEndPoint}/sites/${tenant}.sharepoint.com:${pathToSite}:/drives`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.accessToken}`,
                },
            })
            .then((r) => {
                //console.log(r);
                let librerias = r.data.value;

                let documentos = librerias[2];

                let idLibreria = documentos.id;
                let carpeta = "vtz";
                let fileName = file.name;
                axios
                    .put(`${graphEndPoint}/drives/${idLibreria}/root:/${carpeta}/${fileName}:/content`, file, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token.accessToken}`,
                        },
                    })
                    .then((res) => {
                        //console.log(res);
                    })
                    .catch((err) => {
                        //console.log(err);
                    });
            });
        // }).catch(err => {
        //     //console.log(err);
        // })
    };

    const getSuggestions = async (value: any) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        let usuarios: any = [];
        await new Promise<any>((resolve, reject) => {
            let id = authProvider.acquireTokenSilent({ scopes: ["User.Read.All"] });
            //console.log(value);
            if (id != null) {
                id.then((d) => {
                    //console.log(d.accessToken);
                    fetch(
                        `${graphEndPoint}/users?$filter=startsWith(displayName,'${value}') 
                    or startsWith(mail,'${value}')&$select=id,displayName,mail`,
                        {
                            method: "GET",
                            mode: "cors",
                            cache: "no-cache",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + d.accessToken,
                            },
                        }
                    )
                        .then((result) => {
                            //console.log(result);

                            result.json().then((r) => {
                                //console.log(r);

                                r.value.forEach((user: any) => {
                                    usuarios.push({
                                        name: user.displayName,
                                        mail: user.mail,
                                    });
                                });
                                //resolve();
                            });
                        })
                        .catch((e) => {
                            reject();
                        });
                });
            }
        });

        return usuarios;
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = (suggestion: any) => suggestion.name;

    // Use your imagination to render suggestions.
    const renderSuggestion = (suggestion: any) => <div>{suggestion.name}</div>;

    const onChange = (event: any, { newValue }: any) => {
        setValue(newValue);
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({ value }: any) => {
        getSuggestions(value).then((r) => {
            setsuggestions(r);
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setsuggestions([]);
    };

    const getSectionSuggestions = (section: any) => {
        //console.log(section);
        return section.suggestions;
    };

    const handle = (e: any) => {
        console.log(e);

        setFile(e[0]);
    };

    const inputProps = {
        value: value,
        onChange: onChange,
    };

    return (
        <div>
            <div>
                <h3>Autocompletar</h3>
                <Autosuggest
                    //multiSection={true}
                    //theme={theme}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    //getSectionSuggestions={getSectionSuggestions}
                    inputProps={inputProps}
                />
            </div>

            <div>
                <Button onClick={obtenerSitio}>Click</Button>
            </div>

            <div>
                <input
                    type="file"
                    onChange={(e) => {
                        handle(e.target.files);
                    }}
                />
            </div>
        </div>
    );
};

export default Examples;
