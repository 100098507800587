import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { LogLevel, Logger } from "msal";
import axios from "axios";

interface Props {
    log?: boolean;
}

const c_apiURL: string = "";
const c_apiURLBUK: string = process.env.REACT_APP_APIURLBUK ? process.env.REACT_APP_APIURLBUK : "";
const c_tokenBUK: string = process.env.REACT_APP_TOKENBUK ? process.env.REACT_APP_TOKENBUK : "";
const c_graphEndPoint: string = process.env.REACT_APP_GRAPHENDPOINT ? process.env.REACT_APP_GRAPHENDPOINT : "";
const c_tenant: string = process.env.REACT_APP_TENANTNAME ? process.env.REACT_APP_TENANTNAME : "";
const c_pathToSite: string = process.env.REACT_APP_PATHTOSITE ? process.env.REACT_APP_PATHTOSITE : "";
const c_urlFlujoOportunidadGanada: string = process.env.REACT_APP_FLUJO_OPORTUNIDADGANADA ? process.env.REACT_APP_FLUJO_OPORTUNIDADGANADA : "";
const c_urlFlujoOportunidadResponsable: string = process.env.REACT_APP_RESPONSABLE ? process.env.REACT_APP_RESPONSABLE : "";
const c_urlFlujoRendicionEnviada = process.env.REACT_APP_FLUJORENDICIONENVIADA;
const c_urlFlujoRendicionRechazada = process.env.REACT_APP_FLUJORENDICIONRECHAZADA;
const c_urlFlujoCargaHoras = process.env.REACT_APP_FLUJOCARGAHORAS;
const c_scope_api: string = process.env.REACT_APP_SCOPE_API ? process.env.REACT_APP_SCOPE_API : "";
const c_scope_read_user_graph: string = process.env.REACT_APP_SCOPE_READ_USER_GRAPH ? process.env.REACT_APP_SCOPE_READ_USER_GRAPH : "";
const c_scope_sites_full_control_graph: string = process.env.REACT_APP_SCOPE_SITES_FULL_CONTROL_GRAPH
    ? process.env.REACT_APP_SCOPE_SITES_FULL_CONTROL_GRAPH
    : "";
const c_sharepoint_endpoint: string = process.env.REACT_APP_SHAREPOINTENDPOINT ? process.env.REACT_APP_SHAREPOINTENDPOINT : "";
const c_scope_sites_read_write_graph: string = process.env.REACT_APP_SCOPE_SITES_READ_WRITE_ALL_GRAPH
    ? process.env.REACT_APP_SCOPE_SITES_READ_WRITE_ALL_GRAPH
    : "";
const c_scope_files_read_write_graph: string = process.env.REACT_APP_SCOPE_FILES_READ_WRITE_ALL_GRAPH
    ? process.env.REACT_APP_SCOPE_FILES_READ_WRITE_ALL_GRAPH
    : "";
const c_spTenant: string = process.env.REACT_APP_SPTENANT ? process.env.REACT_APP_SPTENANT : "";
const c_library: string = process.env.REACT_APP_LIBRARY ? process.env.REACT_APP_LIBRARY : "";
class Engine {
    constructor() {}

    apiURLBUK = c_apiURLBUK;
    tokenBUK = c_tokenBUK;

    // LOCALHOST
    graphEndPoint = c_graphEndPoint;
    tenant = c_tenant;
    pathToSite = c_pathToSite;
    urlFlujoRendicionEnviada = c_urlFlujoRendicionEnviada;
    urlFlujoRendicionRechazada = c_urlFlujoRendicionRechazada;
    urlFlujoOportunidadGanada = c_urlFlujoOportunidadGanada;
    urlFlujoOportunidadResponsable = c_urlFlujoOportunidadResponsable;
    urlFlujoCargaHoras = c_urlFlujoCargaHoras;
    spTenant = c_spTenant;
    library = c_library;
    public acquireTokenAPI = async (authProvider: any) => {
        return authProvider.acquireTokenSilent({ scopes: [c_scope_api] });
    };

    public acquireTokenAPIGraph = async (authProvider: any) => {
        return authProvider.acquireTokenSilent({ scopes: [c_scope_read_user_graph] });
    };

    public acquireTokenAPISharePoint = async (authProvider: any) => {
        return authProvider.acquireTokenSilent({ scopes: [`${c_sharepoint_endpoint}${c_scope_sites_full_control_graph}`] });
    };

    public acquireTokenAPIGraphSites = async (authProvider: any) => {
        return authProvider.acquireTokenSilent({ scopes: [c_scope_sites_read_write_graph] });
    };

    public acquireTokenAPIGraphDocuments = async (authProvider: any) => {
        return authProvider.acquireTokenSilent({ scopes: [c_scope_sites_read_write_graph, c_scope_files_read_write_graph] });
    };

    public postAPI = async (apiEndPoint: string, data?: any, headers?: any) => {
        let result = await axios.post(`${c_apiURL}${apiEndPoint}`, data, { headers: headers });
        let resultado = this.procesarResultado(result);

        return resultado;
    };

    public patchAPI = async (apiEndPoint: string, data?: any, headers?: any) => {
        let result = await axios.patch(`${c_apiURL}${apiEndPoint}`, data, { headers: headers });
        let resultado = this.procesarResultado(result);

        return resultado;
    };

    public putAPI = async (apiEndPoint: string, data?: any, headers?: any) => {
        let result = await axios.put(`${c_apiURL}${apiEndPoint}`, data, { headers: headers });
        let resultado = this.procesarResultado(result);

        return resultado;
    };

    public getAPI = async (apiEndPoint: string, headers?: any) => {
        let result = await axios.get(`${c_apiURL}${apiEndPoint}`, { headers: headers });
        let resultado = this.procesarResultado(result);

        return resultado;
    };

    public getAPIBUK = async (apiURLBUK: string, apiEndPoint: string, headers?: any) => {
        let result = await axios.get(`${c_apiURLBUK}${apiEndPoint}`, { headers: headers });
        let resultado = this.procesarResultado(result);

        return resultado;
    };

    public getAPIBlob = async (apiEndPoint: string, headers?: any) => {
        let result = await axios.get(`${c_apiURL}${apiEndPoint}`, { headers: headers, responseType: "arraybuffer" });
        let resultado = this.procesarResultado(result);

        return resultado;
    };

    public postAPIBlob = async (apiEndPoint: string, data?: any, headers?: any) => {
        let result = await axios.post(`${c_apiURL}${apiEndPoint}`, data, { headers: headers, responseType: "arraybuffer" });
        let resultado = this.procesarResultado(result);

        return resultado;
    };

    public setStorageValue = async (authProvider: any) => {
        let date = new Date(),
            expires = new Date(date);
        expires.setMinutes(date.getMinutes() + 20);

        let tokenAPI = await this.acquireTokenAPI(authProvider);
        let tokenAPIGraph = await this.acquireTokenAPIGraph(authProvider);
        let tokenAPISharePoint = await this.acquireTokenAPISharePoint(authProvider);
        let tokenAPIGraphSites = await this.acquireTokenAPIGraphSites(authProvider);
        let tokenAPIGraphDocuments = await this.acquireTokenAPIGraphDocuments(authProvider);

        let sessionObject = {
            expiresAt: expires,
            tokenAPI: tokenAPI.accessToken,
            tokenAPIGraph: tokenAPIGraph.accessToken,
            tokenAPISharePoint: tokenAPISharePoint.accessToken,
            tokenAPIGraphSites: tokenAPIGraphSites.accessToken,
            tokenAPIGraphDocuments: tokenAPIGraphDocuments.accessToken,
        };
        localStorage.setItem("sessionVantazToken", JSON.stringify(sessionObject));

        return sessionObject;
    };

    public getStorageValue = async (authProvider: any) => {
        let storageItem = localStorage.getItem("sessionVantazToken");
        let currentDate = new Date();
        let valueStorage = null;

        if (storageItem != null) {
            let obj = JSON.parse(storageItem);
            let expiration = obj.expiresAt;

            if (currentDate > new Date(expiration)) {
                valueStorage = await this.setStorageValue(authProvider);
            } else {
                valueStorage = obj;
            }
        } else {
            valueStorage = await this.setStorageValue(authProvider);
        }

        return valueStorage == undefined ? "" : valueStorage;
    };

    private procesarResultado = (result: any) => {
        let statusCode = result.status;
        let resultado = result.data;
        let procesado = {};

        if (statusCode === 200) {
            if (resultado.ok) {
                procesado = resultado;
            } else {
                //Error controlado
                procesado = resultado;
            }
        } else if (statusCode === 400) {
            //Bad Request
        } else if (statusCode === 401) {
            //Authorization
        } else if (statusCode === 500) {
            //Internal Server Error
        } else {
            //Otro
        }

        return procesado;
    };
}

export const fetchEngine = new Engine();
