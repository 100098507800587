import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AzureAD, AuthenticationState, IAzureADFunctionProps, IAccountInfo } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import { fetchEngine } from "../../fetchData";
import { basicReduxStore } from "../../reduxStore";
import { IconButton, IIconProps } from "office-ui-fabric-react";
import "./Workspace.css";
import axios from "axios";

const emojiIcon: IIconProps = { iconName: "Completed" };
const library: IIconProps = { iconName: "OneDriveFolder16" };
const warning: IIconProps = { iconName: "Warning" };

interface Props {
    id: number;
    nombre: string;
    callback: any;
    colaboradores?: any;
}

const Workspace = ({ id, nombre, callback, colaboradores }: Props) => {
    const [creando, setCreando] = useState(false);
    const [creado, setcreado] = useState(false);
    const [errorE, setError] = useState(false);
    const [url, setUrl] = useState("");
    const [mensaje, setMensaje] = useState("");
    const [tokenSP, setTokenSP] = useState<string | null>("");
    const [tokenAPI, setTokenAPI] = useState<string | null>("");

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                setTokenAPI(d.tokenAPI);
                setTokenSP(d.tokenAPISharePoint);
            });
        }
        if (tokenAPI != "") {
            repositorio();
        }
    }, [id, tokenAPI, tokenSP]);

    const crearRepositorio = async () => {
        setCreando(true);

        let obj = {
            NombreOportunidad: nombre,
            Token: tokenSP,
            Colaboradores: colaboradores,
            ID_OBJETO: id,
        };

        fetchEngine
            .postAPI("/api/workspaces/", obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    if (resultado.data) {
                        let oportunidad = resultado.data;
                        let userName: any;
                        if (authProvider.getAccountInfo() != null) {
                            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
                        }

                        if (oportunidad.titulo) {
                            let objRegistro = {
                                ID_OBJETO: id,
                                TIPO_OBJETO: 1,
                                NOMBRE: nombre,
                                URL_RELATIVA: oportunidad.relativeUrl,
                                URL: oportunidad.url,
                                CREADO_POR: authProvider.getAccountInfo() != null ? userName.account.userName : "",
                                MODIFICADO_POR: authProvider.getAccountInfo() != null ? userName.account.userName : "",
                            };

                            fetchEngine
                                .putAPI(`/api/workspaces/${id}`, objRegistro, {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${tokenAPI}`,
                                })
                                .then((resultado: any) => {
                                    if (resultado.ok) {
                                        setcreado(true);
                                        setCreando(false);
                                        setUrl(oportunidad.url);
                                        repositorio();
                                    } else {
                                        setcreado(false);
                                        setCreando(false);
                                        setError(true);
                                        setMensaje(`${resultado.mensaje} - ${resultado.data.message}`);
                                    }
                                })
                                .catch((err) => {
                                    setcreado(false);
                                    setCreando(false);
                                    setError(true);
                                    setMensaje(err.message);
                                });
                        }
                    }
                } else {
                    setcreado(false);
                    setCreando(false);
                    setError(true);
                    setMensaje(resultado.mensaje);
                }
            })
            .catch((err) => {
                setMensaje(err.response);
            });
    };

    const repositorio = async () => {
        fetchEngine
            .getAPI(`/api/workspaces/${id}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    if (resultado.data) {
                        let oportunidad = resultado.data[0];
                        if (resultado.data.length > 0) {
                            setcreado(true);
                            setUrl(oportunidad.URL);
                            callback(resultado.data);
                        }
                    }
                } else {
                    setcreado(false);
                    setMensaje(resultado.mensaje);
                }
            })
            .catch((err) => {
                setMensaje(err.response);
            });
    };

    return (
        <AzureAD provider={authProvider} reduxStore={basicReduxStore}>
            {({ accountInfo, authenticationState, error }: IAzureADFunctionProps) => {
                return (
                    <React.Fragment>
                        {creado && !creando ? (
                            ""
                        ) : !creado && creando ? (
                            <div className="alert alert-warning mensajeAlerta mt-2 p-1 pl-3 border border-warning">
                                <div>Espera mientras se crea el espacio de trabajo</div>
                                <div>Puedes seguir revisando el formulario</div>
                            </div>
                        ) : (
                            <div className="alert alert-warning mensajeAlerta mt-2 p-1 pl-3 border border-warning">
                                <h5 className="tit">Hola {accountInfo != null ? accountInfo.account.name : "invitado"}!</h5>
                                <div className="txt">El espacio de trabajo en SharePoint </div>
                                <span>
                                    {!errorE ? (
                                        <p className="mensaje">
                                            <strong>no ha sido creado</strong>, ¿desea crearlo ahora?{" "}
                                        </p>
                                    ) : (
                                        <p className="mensaje">
                                            <IconButton iconProps={warning} title={mensaje} />
                                            <strong>No pudo ser creado</strong>, reintentalo!{" "}
                                        </p>
                                    )}
                                    <IconButton
                                        className="btnCrear btn btn-info"
                                        iconProps={emojiIcon}
                                        title={"Crear repositorio"}
                                        onClick={crearRepositorio}
                                    />
                                </span>
                            </div>
                        )}
                    </React.Fragment>
                );
            }}
        </AzureAD>
    );
};

export default Workspace;
