import React, { useState, useEffect } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
    AzureAD,
    AuthenticationState,
    IAzureADFunctionProps,
    MsalAuthProvider,
    LoginType,
} from "react-aad-msal";
import "react-autosuggest";
import axios from "axios";
import {
    ComboBox,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IComboBox,
    IComboBoxOption,
    IconButton,
    PrimaryButton,
    TextField,
} from "@fluentui/react";
import { Accordion } from "../../../controles/Accordion/Accordion";
import "../PlanificacionProyecto.css";
import { faIgloo } from "@fortawesome/free-solid-svg-icons";
import { render } from "react-dom";
import NumberFormat from "react-number-format";

interface Props {
    actividadesList?: Ingreso[];
    ingresoP?: IngresoData[];
    callback: any;
    listadoOpciones?: any[];
    horasDia?: number;
    roles?: any[];
    moneda?: string;
    simID?: number;
    callbackActividades: any;
    moneda_ctto: string;
}

interface Ingreso {
    ID_MODULO: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    INDEX: number;
    NOMBRE: string;
    TOTAL_HORAS: number;
    TARIFA_HH: number;
    COSTO_HH: number;
    TOTAL_INGRESOS: number;
    TOTAL_COSTO: number;
    TOTAL_RENTABILIDAD: number;
}

interface IngresoData {
    INDEX: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    ID_CARGO: number; // rol
    TIEMPO_INVERTIR: number;
    SEMANAS: number;
    HORAS: number;
    TARIFA_HH: number;
    COSTO_HH: number;
    TOTAL_INGRESOS: number;
    TOTAL_COSTO: number;
    MARGEN: number;
}

let time: any;

const addRol = (e: any, actividadID: number, index: number) => {};

const IngresosDirectosVenta = ({
    actividadesList,
    ingresoP,
    callback,
    listadoOpciones,
    horasDia,
    roles,
    moneda,
    simID,
    callbackActividades,
    moneda_ctto,
}: Props) => {
    ////console.log(roles)
    ////console.log(actividadesList);
    ////console.log(ingresoP);

    const [horas_dia, setHorasDia] = useState(horasDia);
    const [contador, setContador] = useState(0);
    const [ingresos, setIngreso] = useState(ingresoP);
    const [actividades, setActividades] = useState(actividadesList);
    const [nombreAcividad, setNombreActividad] = useState("");

    const [horas, setHoras] = useState(0);
    const [tarfiaHH, setTarifaHH] = useState(0);
    const [costoHH, setCostoHH] = useState(0);
    const [totalIngresos, setTotalIngresos] = useState(0);
    const [totalCostos, setTotalCostos] = useState(0);
    const [totalRentabilidad, setTotalRentabilidad] = useState(0);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogSubText, setDialogSubText] = useState("");

    const [indexActividad, setIndexActividad] = useState(-1);
    const [indexIngreso, setIndexIngreso] = useState(-1);

    const [activeKey, setActiveKey] = useState(0);
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
    };

    const addActividad = () => {
        let actividad = actividades;
        if (nombreAcividad != "") {
            if (actividad != undefined) {
                actividad.push({
                    ID_MODULO: 1,
                    ID_SIMULACION: 0,
                    ID_ACTIVIDAD: actividad.length,
                    INDEX: actividad.length,
                    NOMBRE: nombreAcividad,
                    COSTO_HH: 0,
                    TOTAL_COSTO: 0,
                    TARIFA_HH: 0,
                    TOTAL_HORAS: 0,
                    TOTAL_INGRESOS: 0,
                    TOTAL_RENTABILIDAD: 0,
                });
                ////console.log(actividad);
                setActividades([...actividad]);
                callbackActividades(actividades);
                ////console.log(actividades)
            }
        } else {
            //errorActividad
        }

        setNombreActividad("");
    };

    const addRol = (actividadID: number) => {
        let ingreso = ingresos;
        //////console.log(ingreso);
        if (ingreso != undefined) {
            ingreso.push({
                ID_ACTIVIDAD: actividadID,
                COSTO_HH: 0,
                HORAS: 0,
                ID_CARGO: 0,
                ID_SIMULACION: 0,
                MARGEN: 0,
                SEMANAS: 0,
                TARIFA_HH: 0,
                TIEMPO_INVERTIR: 0,
                TOTAL_COSTO: 0,
                TOTAL_INGRESOS: 0,
                INDEX: ingreso.reduce(
                    (a: number, b: IngresoData) => 1 + b.INDEX,
                    0
                ),
            });
            ////console.log(ingreso);
            setIngreso([...ingreso]);
            callback(ingreso);
        }
    };

    const deleteRol = (indexI: number) => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            let row = ingreso.find(
                (ing: IngresoData, i: number) => i === indexI
            );
            //////console.log(row)
            let sumaRows = 0;
            if (row != undefined) {
                sumaRows =
                    row.TARIFA_HH +
                    row.COSTO_HH +
                    row.SEMANAS +
                    row.TIEMPO_INVERTIR;
            }

            if (sumaRows === 0) {
                ingreso.splice(indexI, 1);
                setIngreso([...ingreso]);
                ////console.log(ingreso);
                callback(ingreso);
            } else {
                // alert("esta fila tiene datos!");
                setDialogTitle("Ingreso con datos!");
                setDialogSubText(
                    "Esta fila contiene datos ingresados ¿Está seguro que desea eliminar?"
                );
                setIndexIngreso(indexI);
                setDialog(false);
            }
        }
    };

    const deleteRolIndex = () => {
        let ingreso = ingresos;
        //////console.log(indexIngreso)
        if (ingreso != undefined) {
            ingreso.splice(indexIngreso, 1);
            setIngreso([...ingreso]);
            Actividades();
            ////console.log(ingreso);
            setIndexIngreso(-1);
            setIndexActividad(-1);
            setDialog(true);
            callback(ingreso);
        }
    };

    const deleteActividad = (actividadID: number, indexA: number) => {
        let ingreso = ingresos;
        let actividad = actividades;
        let tieneIngresos = false;

        if (ingreso != undefined) {
            tieneIngresos = ingreso.some(
                (i: IngresoData) => i.ID_ACTIVIDAD === actividadID
            );
        }

        if (tieneIngresos) {
            // alert("No puede eliminar porque tiene items adentro!");
            setIndexActividad(indexA);
            setDialogTitle("Actividad con roles!");
            setDialogSubText("¿Está seguro que desea eliminar la actividad?");
            setDialog(false);
        } else {
            if (actividad != undefined) {
                actividad.splice(indexA, 1);
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        }
    };

    const deleteActividadRoles = () => {
        let ingreso = ingresos;
        let actividad = actividades;
        let actividadEliminar: Ingreso | undefined;

        if (actividad != undefined) {
            actividadEliminar = actividad.find((a, i) => i === indexActividad);

            if (ingreso != undefined) {
                let ingEliminar: number[];
                if (actividadEliminar != undefined) {
                    let idActividad = actividadEliminar.ID_ACTIVIDAD;
                    ingreso.forEach((ing: IngresoData, i: number) => {
                        if (ing.ID_ACTIVIDAD === idActividad) {
                            if (ingreso != undefined) {
                                ingreso.splice(i, 1);
                            }
                        }
                    });
                }

                actividad.splice(indexActividad, 1);
                setIndexActividad(-1);
                setActividades([...actividad]);
                callback(actividades);
                setIngreso([...ingreso]);
                callback(ingreso);
                setDialog(true);
            }
        }
    };

    const onValueChanged = (index: number, ev: any, indexA: number) => {
        let key = ev.target.id;
        let value = ev.target.value;
        ////console.log(index, indexA, value, key);
        let val = 0;

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    switch (key) {
                        case "costoHHDirecto":
                            if (parseFloat(value) < 0) {
                                i.COSTO_HH = 0;
                            } else {
                                i.COSTO_HH = parseFloat(value);
                            }

                            break;
                        case "tiempoInvertirDirecto":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val <= 100) {
                                i.TIEMPO_INVERTIR = val;
                            }
                            //i.TIEMPO_INVERTIR = parseFloat(value);
                            //Calcular
                            break;
                        case "semanasDirecto":
                            i.SEMANAS = parseFloat(value);
                            //Calcular
                            break;
                        case "horasDirecto":
                            //i.HORAS = parseFloat(value);
                            break;
                        case "tarifaHHDirecto":
                            if (parseFloat(value) < 0) {
                                i.TARIFA_HH = 0;
                            } else {
                                i.TARIFA_HH = parseFloat(value);
                            }
                            //Calcular
                            break;
                        case "totalIngresosDirecto":
                            i.TOTAL_INGRESOS = parseFloat(value);
                            break;
                        case "totalCostoDirecto":
                            i.TOTAL_COSTO = parseFloat(value);
                            //Calcular
                            break;
                        case "margenDirecto":
                            i.MARGEN = parseFloat(value);
                            break;
                    }
                    let horasSemana = horasDia === undefined ? 0 : horasDia * 5;
                    ////console.log(horasSemana)

                    i.HORAS = parseFloat(
                        (
                            i.SEMANAS *
                            horasSemana *
                            (i.TIEMPO_INVERTIR / 100)
                        ).toFixed(2)
                    );
                    i.TOTAL_INGRESOS = parseFloat(
                        (i.TARIFA_HH * i.HORAS).toFixed(2)
                    );
                    i.TOTAL_COSTO = parseFloat(
                        (i.COSTO_HH * i.HORAS).toFixed(2)
                    );
                    i.MARGEN = parseFloat(
                        (
                            ((i.TOTAL_INGRESOS - i.TOTAL_COSTO) /
                                i.TOTAL_INGRESOS) *
                            100
                        ).toFixed(2)
                    );
                    if (i.COSTO_HH === 0) {
                        i.MARGEN = 0;
                    }
                }
            });

            //Total Actividad
            calcularTotalActividad(data, indexA);

            setIngreso([...data]);
            callback(data);
        }
    };

    const calcularTotalActividad = (
        ingresos: IngresoData[],
        actividadID: number
    ) => {
        if (ingresos != undefined) {
            let ingresosAct = ingresos.filter((i) => {
                return i.ID_ACTIVIDAD === actividadID;
            });
            let actividadesCopy = actividades;
            let total_ingresos = 0;
            let total_costo = 0;
            if (actividadesCopy != undefined) {
                //let act = actividadesCopy.find(a => a.actividadID === actividadID);
                actividadesCopy.forEach((a) => {
                    if (a.ID_ACTIVIDAD === actividadID) {
                        a.COSTO_HH = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.COSTO_HH, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_INGRESOS = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.TOTAL_INGRESOS, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_COSTO = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.TOTAL_COSTO, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_HORAS = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.HORAS, 0)
                                .toFixed(2)
                        );
                        a.TARIFA_HH = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.TARIFA_HH, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_RENTABILIDAD = parseFloat(
                            (
                                ((a.TOTAL_INGRESOS - a.TOTAL_COSTO) /
                                    a.TOTAL_INGRESOS) *
                                100
                            ).toFixed(2)
                        );
                    }
                });

                ////console.log(actividadesCopy)

                //actualizar totales
                total_ingresos = actividadesCopy.reduce(
                    (a, b) => a + b.TOTAL_INGRESOS,
                    0
                );
                total_costo = actividadesCopy.reduce(
                    (a, b) => a + b.TOTAL_COSTO,
                    0
                );

                setCostoHH(actividadesCopy.reduce((a, b) => a + b.COSTO_HH, 0));
                setTotalIngresos(
                    actividadesCopy.reduce((a, b) => a + b.TOTAL_INGRESOS, 0)
                );
                setTotalCostos(
                    actividadesCopy.reduce((a, b) => a + b.TOTAL_COSTO, 0)
                );
                setHoras(
                    actividadesCopy.reduce((a, b) => a + b.TOTAL_HORAS, 0)
                );
                setTarifaHH(
                    actividadesCopy.reduce((a, b) => a + b.TARIFA_HH, 0)
                );
                setTotalRentabilidad(
                    parseFloat(
                        (
                            ((total_ingresos - total_costo) / total_ingresos) *
                            100
                        ).toFixed(2)
                    )
                );

                setActividades([...actividadesCopy]);
            }
        }
    };

    document.onclick = () => {
        /* let data = ingresos;
        data!.map((a:any) => {
        ////console.log(a);
        calcularTotalActividad(data!, a.ID_ACTIVIDAD);
        }) */
    };

    useEffect(() => {
        let data = ingresos;
        data!.map((a: any) => {
            ////console.log(a);
            calcularTotalActividad(data!, a.ID_ACTIVIDAD);
        });
    }, []);

    const _onChangeRol = (index: number, item: any, a: Ingreso): void => {
        let data = ingresos;
        let key = item.key;

        if (data !== undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    if (roles !== undefined) {
                        roles.map((a: any) => {
                            if (a.key === key) {
                                i.TARIFA_HH = a.tarifa;
                                i.COSTO_HH = a.costo;
                                i.ID_CARGO = key;
                            }
                        });
                    }
                }
            });
            setIngreso([...data]);
        }
    };

    const FilasIngresos = (a: Ingreso) => {
        return (
            <div>
                {ingresos != undefined
                    ? ingresos.map((i: IngresoData, indexI: number) => {
                          //////console.log(i);
                          return (
                              <div>
                                  {i.ID_ACTIVIDAD === a.ID_ACTIVIDAD ? (
                                      <div>
                                          <div key={indexI}>
                                              <div
                                                  className="ms-Grid-row bloqueActividad"
                                                  id="bloqueActividad"
                                              >
                                                  <div
                                                      className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 txtSmall"
                                                      id="txtven"
                                                  >
                                                      <ComboBox
                                                          label="Rol"
                                                          placeholder="Consultor"
                                                          id={
                                                              "consultorDirecto"
                                                          }
                                                          defaultSelectedKey={
                                                              i.ID_CARGO
                                                          }
                                                          onChange={(
                                                              e,
                                                              selectedOption
                                                          ) => {
                                                              _onChangeRol(
                                                                  indexI,
                                                                  selectedOption,
                                                                  a
                                                              );
                                                          }}
                                                          options={roles}
                                                          disabled={true}
                                                          allowFreeform={true}
                                                          autoComplete="on"
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Tiempo invertir(%)"
                                                          id={
                                                              "tiempoInvertirDirecto"
                                                          }
                                                          defaultValue={
                                                              i.TIEMPO_INVERTIR ===
                                                              0
                                                                  ? ""
                                                                  : i.TIEMPO_INVERTIR.toString().replace(
                                                                        ".",
                                                                        ","
                                                                    ) + "%"
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          disabled={true}
                                                          placeholder={"%"}
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Semanas"
                                                          id={"semanasDirecto"}
                                                          defaultValue={
                                                              i.SEMANAS === 0
                                                                  ? ""
                                                                  : new Intl.NumberFormat(
                                                                        "de-DE"
                                                                    )
                                                                        .format(
                                                                            i.SEMANAS
                                                                        )
                                                                        .toString()
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          disabled={true}
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Horas"
                                                          id={"horasDirecto"}
                                                          value={
                                                              i.HORAS === 0
                                                                  ? ""
                                                                  : new Intl.NumberFormat(
                                                                        "de-DE"
                                                                    ).format(
                                                                        i.HORAS
                                                                    )
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          disabled={true}
                                                          placeholder={"..."}
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Tarifa x HH"
                                                          id={"tarifaHHDirecto"}
                                                          value={
                                                              i.TARIFA_HH === 0
                                                                  ? ""
                                                                  : new Intl.NumberFormat(
                                                                        "de-DE"
                                                                    )
                                                                        .format(
                                                                            i.TARIFA_HH
                                                                        )
                                                                        .toString()
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          placeholder={"..."}
                                                          disabled={true}
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Costo x HH"
                                                          //disabled={true}
                                                          placeholder={"..."}
                                                          id={"costoHHDirecto"}
                                                          value={
                                                              i.COSTO_HH === 0
                                                                  ? ""
                                                                  : new Intl.NumberFormat(
                                                                        "de-DE"
                                                                    )
                                                                        .format(
                                                                            i.COSTO_HH
                                                                        )
                                                                        .toString()
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          disabled={true}
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Total ingresos"
                                                          id={
                                                              "totalIngresosDirecto"
                                                          }
                                                          value={
                                                              i.TOTAL_INGRESOS ===
                                                              0
                                                                  ? ""
                                                                  : new Intl.NumberFormat(
                                                                        "de-DE"
                                                                    ).format(
                                                                        i.TOTAL_INGRESOS
                                                                    )
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          placeholder={"..."}
                                                          //type={"number"}
                                                          disabled={true}
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Total costo"
                                                          id={
                                                              "totalCostoDirecto"
                                                          }
                                                          value={
                                                              i.TOTAL_COSTO ===
                                                              0
                                                                  ? ""
                                                                  : new Intl.NumberFormat(
                                                                        "de-DE"
                                                                    ).format(
                                                                        i.TOTAL_COSTO
                                                                    )
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          disabled={true}
                                                          placeholder={"..."}
                                                          //type={"number"}
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm4 ms-md1 ms-lg1 txtSmall">
                                                      <TextField
                                                          label="Rentabilidad (%)"
                                                          id={"margenDirecto"}
                                                          value={
                                                              i.MARGEN === 0
                                                                  ? ""
                                                                  : i.MARGEN.toString().replace(
                                                                        ".",
                                                                        ","
                                                                    ) + "%"
                                                          }
                                                          onChange={(e) => {
                                                              onValueChanged(
                                                                  indexI,
                                                                  e,
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                          placeholder={"..."}
                                                          disabled={true}
                                                      />
                                                  </div>
                                                  {/* <div className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 mt-4" id="divEliminarRol">
                                                        <button id="btnAgregarRol" onClick={(e) => { deleteRol(indexI) }}><i className="ms-Icon ms-Icon--Delete" aria-hidden="true" id="btnEliminarRol"></i></button>
                                                    </div> */}
                                              </div>
                                          </div>
                                      </div>
                                  ) : (
                                      ""
                                  )}
                              </div>
                          );
                      })
                    : ""}

                <div
                    className="ms-Grid-row bloqueActividad"
                    id="bloqueActividad"
                >
                    {/* <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" id="divAgregarActividad">
                        <button id="btnAgregarRol" onClick={(e) => { addRol(a.ID_ACTIVIDAD) }}><i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarRol"><span className="txtAgregar">&nbsp; Agregar rol</span></i></button>
                    </div> */}
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                    <div
                        className="ms-Grid-col ms-sm4 ms-md4 ms-lg5"
                        id="divTotal"
                    >
                        Total
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 pb-1">
                        <TextField
                            id={"totalActividadDirectoHoras"}
                            disabled={true}
                            placeholder={"..."}
                            //type={"number"}
                            value={
                                a.TOTAL_HORAS != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TOTAL_HORAS
                                      )
                                    : ""
                            }
                            //new Intl.NumberFormat('de-DE').format(this.state.plan_costo_d)}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoTarifaHH"}
                            disabled={true}
                            placeholder={"..."}
                            //type={"number"}
                            value={
                                a.TARIFA_HH != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TARIFA_HH
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoCostoHH"}
                            disabled={true}
                            placeholder={"..."}
                            //type={"number"}
                            value={
                                a.COSTO_HH != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.COSTO_HH
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoTotalFees"}
                            disabled={true}
                            placeholder={"..."}
                            //type={"number"}
                            value={
                                a.TOTAL_INGRESOS != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TOTAL_INGRESOS
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoTotalCosto"}
                            disabled={true}
                            placeholder={"..."}
                            //type={"number"}
                            value={
                                a.TOTAL_COSTO != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TOTAL_COSTO
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoRentabilidad"}
                            disabled={true}
                            placeholder={"..."}
                            //type={"number"}
                            value={
                                a.TOTAL_RENTABILIDAD != undefined
                                    ? new Intl.NumberFormat("de-DE")
                                          .format(a.TOTAL_RENTABILIDAD)
                                          .replace(".", ",") + "%"
                                    : ""
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    const Resumen = () => {
        return (
            <div className="ms-Grid-row divTotalBloque" id="divTotalBloque">
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg5" id="divTotal">
                    Total ingresos y costos directos
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoHoras"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            horas != undefined
                                ? new Intl.NumberFormat("de-DE").format(horas)
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoTarifaHH"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            tarfiaHH != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      tarfiaHH
                                  )
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoCostoHH"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            costoHH != undefined
                                ? new Intl.NumberFormat("de-DE").format(costoHH)
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoTotalFees"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            totalIngresos != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      totalIngresos
                                  )
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoTotalCosto"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            totalCostos != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      totalCostos
                                  )
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoRentabilidad"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            totalRentabilidad != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      totalRentabilidad
                                  ) + "%"
                                : ""
                        }
                    />
                </div>
            </div>
        );
    };

    const Actividades = () => {
        return actividades != undefined
            ? actividades.map((a: Ingreso, indexA: number) => {
                  //////console.log(a);
                  return (
                      <div>
                          <div>
                              <div className="acordeonActividad">
                                  <Accordion
                                      title={a.NOMBRE}
                                      defaultCollapsed={false}
                                      inicio={true}
                                      expandAll={false}
                                      className="fondoTitAcordionActividad"
                                      key={1}
                                      classFondo="fondoSeccionActividad"
                                  >
                                      <div className="itemContent">
                                          {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="divBloque">
                                            <button id="btnAgregarRol" className="cierre" onClick={(e) => { deleteActividad(a.ID_ACTIVIDAD, indexA) }}><i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" id="btnEliminarActividad"></i></button>
                                        </div> */}
                                      </div>
                                      {FilasIngresos(a)}
                                  </Accordion>
                              </div>
                          </div>
                      </div>
                  );
              })
            : "";
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            indexIngreso > -1
                                ? deleteRolIndex()
                                : deleteActividadRoles();
                        }}
                        text="Sí, Eliminar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    {/* <Accordion title="Ingresos y costos directos"
                        defaultCollapsed={true} inicio={true} expandAll={false} className="fondoTitAcordion" key={1} classFondo="fondoSeccion"> */}
                    <div className="itemContent">
                        <div className="ms-Grid-row">
                            {/* <div className="ms-Grid-col ms-sm8 ms-md4 ms-lg4 mt-2 mb-2">
                                <TextField
                                    placeholder="Nombre Actividad"
                                    id={"nombreActividadDirecto"}
                                    //value={this.state.nombreActividad}
                                    onChange={(e: any) => { setNombreActividad(e.target.value) }}
                                />
                                {nombreAcividad === "" ? <label className="txterror">Debe ingresar un nombre para la nueva actividad</label> : ""}
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md4 ms-lg3 mt-2 mb-2" id="divAgregarActividad">
                                <button id="btnAgregarActividad" className="btnAgregarActividad" onClick={(e) => { addActividad() }}>
                                    <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarActividad">&nbsp;
                                        <span className="txtAgregar">Agregar Actividad</span>
                                    </i>
                                </button>
                            </div> */}
                            <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8"></div>
                            <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 d-flex justify-content-end mt-3">
                                <label>
                                    Los valores reflejados están en la moneda
                                    del Contrato Marco:{" "}
                                    <span>{moneda_ctto}</span>
                                </label>
                            </div>
                        </div>
                        {Actividades()}
                        {dialog()}
                        {Resumen()}
                    </div>
                    {/* </Accordion> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default IngresosDirectosVenta;
