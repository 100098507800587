import React, { useState, useEffect } from "react";
import "react-autosuggest";
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";
import { Accordion } from "../../../controles/Accordion/Accordion";
import "../PlanificacionProyecto.css";
import NumberFormat from "react-number-format";

interface Props {
    actividadesList?: IngresoTerceros[];
    ingresoP?: IngresoData[];
    callback: any;
    moneda?: string;
    estado_modulo: number;
    callbackActividades: any;
}

interface IngresoTerceros {
    ID_SIMULACION: number;
    ID_MODULO: number;
    ID_ACTIVIDAD: number;
    index: number;
    NOMBRE: string;
    TOTAL_COSTO: number;
    TOTAL_INGRESO: number;
    TOTAL_RENTABILIDAD: number;
}

interface IngresoData {
    INDEX: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    ITEM: string;
    UNIDAD: string;
    COSTO_UNITARIO: number;
    CANTIDAD: number;
    COSTO_TOTAL: number;
    MARGEN: number;
    INGRESO: number;
    RENTABILIDAD: number;
}

const IngresosTercerosPlanificacion = ({ actividadesList, ingresoP, callback, moneda, estado_modulo, callbackActividades }: Props) => {
    const [ingresos, setIngreso] = useState(ingresoP);
    const [actividades, setActividades] = useState(actividadesList);
    const [nombreAcividad, setNombreActividad] = useState("");

    const [costoTotal, setCostoTotal] = useState(0);
    const [ingresoTotal, setingresoTotal] = useState(0);
    const [rentabilidadTotal, setRentabilidadTotal] = useState(0);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogSubText, setDialogSubText] = useState("");

    const [indexActividad, setIndexActividad] = useState(-1);
    const [indexIngreso, setIndexIngreso] = useState(-1);

    const [activeKey, setActiveKey] = useState(0);
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
    };

    const addActividad = () => {
        let actividad = actividades;
        let nuevoID = 0;

        if (actividad != undefined) {
            actividad.map((a: any) => {
                nuevoID = a.ID_ACTIVIDAD;
            });
        }

        if (nombreAcividad != "") {
            if (actividad != undefined) {
                actividad.push({
                    ID_SIMULACION: 0,
                    ID_MODULO: 2,
                    ID_ACTIVIDAD: nuevoID + 1,
                    index: actividad.length,
                    NOMBRE: nombreAcividad,
                    TOTAL_COSTO: 0,
                    TOTAL_INGRESO: 0,
                    TOTAL_RENTABILIDAD: 0,
                });
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        } else {
            //errorActividad
        }

        setNombreActividad("");
    };

    const addRol = (actividadID: number) => {
        //console.log(ingresos);
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.push({
                ID_ACTIVIDAD: actividadID,
                ID_SIMULACION: 0,
                ITEM: "",
                UNIDAD: "",
                COSTO_UNITARIO: 0,
                CANTIDAD: 0,
                COSTO_TOTAL: 0,
                MARGEN: 0,
                INGRESO: 0,
                RENTABILIDAD: 0,
                INDEX: ingreso.reduce((a: number, b: IngresoData) => 1 + b.INDEX, 0),
            });
            setIngreso([...ingreso]);
            callback(ingreso);
        }
    };

    const deleteRol = (indexI: number) => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            let row = ingreso.find((ing: IngresoData, i: number) => i === indexI);
            let sumaRows = 0;
            if (row != undefined) {
                sumaRows = row.MARGEN + row.CANTIDAD + row.COSTO_UNITARIO;
            }

            if (sumaRows === 0) {
                ingreso.splice(indexI, 1);
                setIngreso([...ingreso]);
                //console.log(ingreso);
                callback(ingreso);
            } else {
                setDialogTitle("Ingreso con datos!");
                setDialogSubText("Esta fila contiene datos ingresados ¿Está seguro que desea eliminar?");
                setIndexIngreso(indexI);
                setDialog(false);
            }
        }
    };

    const deleteRolIndex = () => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.splice(indexIngreso, 1);
            setIngreso([...ingreso]);
            //console.log(ingreso);
            setIndexIngreso(-1);
            setIndexActividad(-1);
            setDialog(true);
            callback(ingreso);
            calcularTotalActividad(ingreso, indexActividad);
            setIndexActividad(-1);
        }
    };

    const deleteActividad = (actividadID: number, indexA: number) => {
        let ingreso = ingresos;
        let actividad = actividades;
        let tieneIngresos = false;

        if (ingreso != undefined) {
            tieneIngresos = ingreso.some((i: IngresoData) => i.ID_ACTIVIDAD === actividadID);
        }

        if (tieneIngresos) {
            setIndexActividad(indexA);
            setDialogTitle("Actividad con roles!");
            setDialogSubText("¿Está seguro que desea eliminar la actividad?");
            setDialog(false);
        } else {
            if (actividad != undefined) {
                actividad.splice(indexA, 1);
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        }
    };

    const deleteActividadRoles = () => {
        let ingreso = ingresos;
        let ingreso2: any = [];
        let actividad = actividades;
        let actividad2: any = [];
        let actividadEliminar: IngresoTerceros | undefined;

        if (actividad != undefined) {
            actividadEliminar = actividad.find((a, i) => i === indexActividad);

            if (ingreso != undefined) {
                if (actividadEliminar != undefined) {
                    let idActividad = actividadEliminar.ID_ACTIVIDAD;

                    ingreso.map((ing: any) => {
                        if (ing.ID_ACTIVIDAD != idActividad) {
                            ingreso2.push({
                                CANTIDAD: ing.CANTIDAD,
                                COSTO_TOTAL: ing.COSTO_TOTAL,
                                COSTO_UNITARIO: ing.COSTO_UNITARIO,
                                ID: ing.ID,
                                ID_ACTIVIDAD: ing.ID_ACTIVIDAD,
                                ID_SIMULACION: ing.ID_SIMULACION,
                                ID__FILA_ITEM: ing.ID__FILA_ITEM,
                                INGRESO: ing.INGRESO,
                                ITEM: ing.ITEM,
                                MARGEN: ing.MARGEN,
                                RENTABILIDAD: ing.RENTABILIDAD,
                                UNIDAD: ing.UNIDAD,
                            });
                        }
                    });

                    actividad.map((a: any) => {
                        if (a.ID_ACTIVIDAD != idActividad) {
                            actividad2.push({
                                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                ID_MODULO: a.ID_MODULO,
                                ID_SIMULACION: a.ID_SIMULACION,
                                NOMBRE: a.NOMBRE,
                                TOTAL_COSTO: a.TOTAL_COSTO,
                                TOTAL_INGRESO: a.TOTAL_INGRESO,
                                TOTAL_RENTABILIDAD: a.TOTAL_RENTABILIDAD,
                            });
                        }
                    });
                }

                setActividades([...actividad2]);
                setDialog(true);
                callback(ingreso2);
                callbackActividades(actividad2);
                let total_ingresos: 0;
                let total_costo: 0;
                total_ingresos = actividad2.reduce((a: any, b: any) => a + b.TOTAL_INGRESO, 0);
                total_costo = actividad2.reduce((a: any, b: any) => a + b.TOTAL_COSTO, 0);
                setCostoTotal(actividad2.reduce((a: any, b: any) => a + b.TOTAL_COSTO, 0));
                setingresoTotal(actividad2.reduce((a: any, b: any) => a + b.TOTAL_INGRESO, 0));
                setRentabilidadTotal(total_ingresos != 0 ? parseFloat((((total_ingresos - total_costo) / total_ingresos) * 100).toFixed(2)) : 0);
            }
        }
    };
    const _onCheckChange = (ev?: any, isChecked?: boolean) => {};

    const onValueChanged = (index: number, ev: any, indexA: number, e?: string) => {
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.floatValue : ev.target.value;
        //console.log(index, indexA, value, key);
        let val = 0;

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    switch (key) {
                        case "unidadTerceros_Plan":
                            i.UNIDAD = value;
                            break;
                        case "costoUnitarioTerceros_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.COSTO_UNITARIO = val;
                            } else {
                                i.COSTO_UNITARIO = 0;
                            }
                            //Calcular
                            break;
                        case "cantidadTerceros_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.CANTIDAD = val;
                            } else {
                                i.CANTIDAD = 0;
                            }
                            //Calcular
                            break;
                        case "costoTotalTerceros_Plan":
                            i.COSTO_TOTAL = parseFloat(value);
                            break;
                        case "margenTerceros_Plan":
                            val = value === "" || value === undefined ? 0 : parseFloat(value);
                            if (val <= 100 && val >= 0) {
                                i.MARGEN = val;
                            } else if (val < 0) {
                                i.MARGEN = 0;
                            }
                            //Calcular
                            break;
                        case "ingresoTerceros_Plan":
                            i.INGRESO = parseFloat(value);
                            break;
                        case "itemTerceros_Plan":
                            i.ITEM = value;
                            break;
                        case "rentabilidadTerceros_Plan":
                            i.RENTABILIDAD = parseFloat(value);
                            //Calcular
                            break;
                    }
                    //console.log(i.INGRESO, i.COSTO_TOTAL);

                    i.COSTO_TOTAL = parseFloat((i.CANTIDAD * i.COSTO_UNITARIO).toFixed(2));
                    i.INGRESO = parseFloat((i.COSTO_TOTAL * (1 + i.MARGEN / 100)).toFixed(2));
                    i.RENTABILIDAD = i.INGRESO != 0 ? parseFloat((((i.INGRESO - i.COSTO_TOTAL) / i.INGRESO) * 100).toFixed(2)) : 0;
                }
            });

            //Total Actividad
            calcularTotalActividad(data, indexA);

            setIngreso([...data]);
            callback(data);
        }
    };

    const calcularTotalActividad = (ingresos: IngresoData[], actividadID: number) => {
        if (ingresos !== undefined && actividades !== undefined) {
            let total_costo_act: number = 0;
            let total_ingreso_act: number = 0;
            actividades
                .filter((actividad: IngresoTerceros) => actividad.ID_ACTIVIDAD == actividadID && actividad.ID_MODULO == 2)
                .forEach((actividad: IngresoTerceros) => {
                    actividad.TOTAL_COSTO = 0;
                    actividad.TOTAL_INGRESO = 0;
                    ingresos
                        .filter((ingreso: IngresoData) => ingreso.ID_ACTIVIDAD === actividadID)
                        .forEach((ingreso: IngresoData) => {
                            ingreso.COSTO_TOTAL = ingreso.CANTIDAD * ingreso.COSTO_UNITARIO;
                            ingreso.INGRESO = ingreso.COSTO_TOTAL * (1 + (ingreso.MARGEN / 100));
                            ingreso.RENTABILIDAD = ingreso.INGRESO == 0 ? 0 : (((ingreso.INGRESO - ingreso.COSTO_TOTAL) / ingreso.INGRESO) * 100);
                            actividad.TOTAL_COSTO += ingreso.COSTO_TOTAL;
                            actividad.TOTAL_INGRESO += ingreso.INGRESO;
                        });
                    actividad.TOTAL_RENTABILIDAD = actividad.TOTAL_INGRESO == 0 ? 0 : (((actividad.TOTAL_INGRESO - actividad.TOTAL_COSTO) / actividad.TOTAL_INGRESO) * 100);
                });
            actividades
                .filter((actividad: IngresoTerceros) => actividad.ID_MODULO == 2)
                .forEach((actividad: IngresoTerceros) => {
                    total_costo_act += actividad.TOTAL_COSTO;
                    total_ingreso_act += actividad.TOTAL_INGRESO;
                });
            setCostoTotal(total_costo_act);
            setingresoTotal(total_ingreso_act);
            setRentabilidadTotal(total_ingreso_act == 0 ? 0 : (((total_ingreso_act - total_costo_act) / total_ingreso_act) * 100));
        }
    };

    useEffect(() => {
        let data = ingresos;
        data!.map((a: any) => {
            //console.log(a);
            calcularTotalActividad(data!, a.ID_ACTIVIDAD);
        });
    }, []);

    const FilasIngresos = (a: IngresoTerceros) => {
        return (
            <div>
                {ingresos != undefined
                    ? ingresos.map((i: IngresoData, indexI: number) => {
                          //console.log(indexI);
                          return (
                              <div>
                                  {i.ID_ACTIVIDAD === a.ID_ACTIVIDAD ? (
                                      <div>
                                          <div key={indexI}>
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 txtSmall separadorBloques">
                                                  <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                                      <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 txtSmall">
                                                          <TextField
                                                              label="Ítem"
                                                              id={"itemTerceros_Plan"}
                                                              defaultValue={i.ITEM}
                                                              placeholder="Nombre ítem"
                                                              onChange={(e) => {
                                                                  onValueChanged(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 txtSmall">
                                                          <TextField
                                                              label="Unidad"
                                                              id={"unidadTerceros_Plan"}
                                                              defaultValue={i.UNIDAD}
                                                              placeholder={"..."}
                                                              onChange={(e) => {
                                                                  onValueChanged(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Costo unitario</label>
                                                              <NumberFormat
                                                                  id={"costoUnitarioTerceros_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "costoUnitarioTerceros_Plan");
                                                                  }}
                                                                  defaultValue={
                                                                      i.COSTO_UNITARIO <= 0 ? "" : i.COSTO_UNITARIO.toString().replace(".", ",")
                                                                  }
                                                                  value={
                                                                      i.COSTO_UNITARIO === 0 || i.COSTO_UNITARIO <= 0
                                                                          ? ""
                                                                          : i.COSTO_UNITARIO.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Cantidad</label>
                                                              <NumberFormat
                                                                  id={"cantidadTerceros_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "cantidadTerceros_Plan");
                                                                  }}
                                                                  defaultValue={i.CANTIDAD <= 0 ? "" : i.CANTIDAD.toString().replace(".", ",")}
                                                                  value={
                                                                      i.CANTIDAD === 0 || i.CANTIDAD <= 0
                                                                          ? ""
                                                                          : i.CANTIDAD.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Costo total</label>
                                                              <NumberFormat
                                                                  id={"costoTotalTerceros_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "costoTotalTerceros_Plan");
                                                                  }}
                                                                  value={
                                                                      i.COSTO_TOTAL === 0 ? "" : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(i.COSTO_TOTAL)
                                                                  }
                                                                  disabled={true}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>% Sobre Costos</label>
                                                              <NumberFormat
                                                                  id={"margenTerceros_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "margenTerceros_Plan");
                                                                  }}
                                                                  defaultValue={
                                                                      i.MARGEN <= 0
                                                                          ? ""
                                                                          : i.MARGEN > 100
                                                                          ? "100"
                                                                          : i.MARGEN.toString().replace(".", ",")
                                                                  }
                                                                  value={
                                                                      i.MARGEN <= 0
                                                                          ? ""
                                                                          : i.MARGEN > 100
                                                                          ? "100"
                                                                          : i.MARGEN.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Rentabilidad (%)</label>
                                                              <NumberFormat
                                                                  id={"rentabilidadTerceros_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "rentabilidadTerceros_Plan");
                                                                  }}
                                                                  value={
                                                                      i.RENTABILIDAD === 0
                                                                          ? ""
                                                                          : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(i.RENTABILIDAD).replace(".", ",")
                                                                  }
                                                                  disabled={true}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                                                          ""
                                                      ) : (
                                                          <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 mt-4" id="divEliminarRol">
                                                              <button
                                                                  id="btnAgregarRol"
                                                                  onClick={(e) => {
                                                                      deleteRol(indexI);
                                                                      setIndexActividad(a.ID_ACTIVIDAD);
                                                                  }}>
                                                                  <i className="ms-Icon ms-Icon--Delete" aria-hidden="true" id="btnEliminarRol"></i>
                                                              </button>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  ) : (
                                      ""
                                  )}
                              </div>
                          );
                      })
                    : ""}

                <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                    {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                        ""
                    ) : (
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" id="divAgregarActividad">
                            <button
                                id="btnAgregarRol"
                                onClick={(e) => {
                                    addRol(a.ID_ACTIVIDAD);
                                }}>
                                <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarRol">
                                    <span className="txtAgregar">&nbsp; Agregar ítem</span>
                                </i>
                            </button>
                        </div>
                    )}
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg7" id="divTotal">
                        Total
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 pb-1">
                        <TextField
                            id={"totalActividadTerceroCosto_Plan"}
                            disabled={true}
                            placeholder={"..."}
                            value={a.TOTAL_COSTO != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.TOTAL_COSTO) : ""}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadTercerosRentabilidad_Plan"}
                            disabled={true}
                            placeholder={"..."}
                            value={a.TOTAL_RENTABILIDAD != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.TOTAL_RENTABILIDAD) + "%" : ""}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const Resumen = () => {
        return (
            <div className="ms-Grid-row divTotalBloque" id="divTotalBloque">
                <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg7" id="divTotal">
                    Total servicios terceros
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueTerceroCostoTotal_Plan"}
                        disabled={true}
                        placeholder={"..."}
                        value={costoTotal != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(costoTotal) : ""}
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueTerceroRentabilidad_Plan"}
                        disabled={true}
                        placeholder={"..."}
                        value={rentabilidadTotal != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(rentabilidadTotal) + "%" : ""}
                    />
                </div>
            </div>
        );
    };

    const Actividades = () => {
        return actividades != undefined
            ? actividades.map((a: IngresoTerceros, indexA: number) => {
                  return (
                      <div>
                          <div>
                              <div className="acordeonActividad">
                                  <Accordion
                                      title={a.NOMBRE}
                                      defaultCollapsed={false}
                                      inicio={true}
                                      expandAll={false}
                                      className="fondoTitAcordionActividad"
                                      key={indexA}
                                      classFondo="fondoSeccionActividad">
                                      <div className="itemContent">
                                          {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                                              ""
                                          ) : (
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="divBloque">
                                                  <button
                                                      id="btnAgregarRol"
                                                      className="cierre"
                                                      onClick={(e) => {
                                                          deleteActividad(a.ID_ACTIVIDAD, indexA);
                                                      }}>
                                                      <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" id="btnEliminarActividad"></i>
                                                  </button>
                                              </div>
                                          )}
                                      </div>
                                      {FilasIngresos(a)}
                                  </Accordion>
                              </div>
                          </div>
                      </div>
                  );
              })
            : "";
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            indexIngreso > -1 ? deleteRolIndex() : deleteActividadRoles();
                        }}
                        text="Sí, Eliminar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    <div className="itemContent">
                        {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm1 ms-md8 ms-lg7"></div>
                                <div className="ms-Grid-col ms-sm11 ms-md4 ms-lg5 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm8 ms-md4 ms-lg4 mt-2 mb-2">
                                    <TextField
                                        placeholder="Nombre Actividad"
                                        id={"nombreActividadDirecto"}
                                        value={nombreAcividad}
                                        onChange={(e: any) => {
                                            setNombreActividad(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm1 ms-md3 ms-lg3 mt-2 mb-2" id="divAgregarActividad">
                                    <button
                                        id="btnAgregarActividad"
                                        className="btnAgregarActividad"
                                        onClick={(e) => {
                                            addActividad();
                                        }}>
                                        <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarActividad">
                                            &nbsp;
                                            <span className="txtAgregar">Agregar Actividad</span>
                                        </i>
                                    </button>
                                </div>
                                <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        {Actividades()}
                        {dialog()}
                        {Resumen()}
                    </div>
                    {/* </Accordion> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default IngresosTercerosPlanificacion;
