import { useHistory } from "react-router-dom";
import "../../custom.css";
import GrillaCargaHoras from "./../GrillaDatos/GrillaCargaHoras";
import "./CargaHoras.css";

import React, { useState, useEffect } from "react";
import "../Home/Home.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { fetchEngine } from "../../fetchData";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

const Homecarga = () => {
    const [dataCarga, setDataCarga] = useState([]);
    const [comboData, setComboData] = useState([] as any);
    const [pagesCarga, setPagesCarga] = useState(0);
    const [responsable, setResponsable] = useState("0");
    const [estado, setEstadoCarga] = useState(0);
    const [id, setId] = useState(0);
    const [ot, setOt] = useState(0);
    const [nombreProyecto, setNombreProyecto] = useState("0");
    const [hh, setHH] = useState(0);
    const [fechaCarga, setFechaCarga] = useState("12-12-2012");
    const [nombreUsuario1, setNombreUsuario1] = useState("0");

    const [tipoTab, settipoTab] = useState("Mis Registros");
    const [currentPage, setCurrentPage] = useState(tipoTab);
    const [postsPerPage, setPostsPerPage] = useState(10);

    const history = useHistory();
    const modalPropsStyles = { main: { maxWidth: 450 } };

    // CALENDARIO NOCHE

    const [nombreUsuario, setNombreUsuario] = useState("");
    const [horasNoche, setHorasNoche] = useState([{}]);
    const [totalEnero, setTotalEnero] = useState(0);
    const [totalFebrero, setTotalFebrero] = useState(0);
    const [totalMarzo, setTotalMarzo] = useState(0);
    const [totalAbril, setTotalAbril] = useState(0);
    const [totalMayo, setTotalMayo] = useState(0);
    const [totalJunio, setTotalJunio] = useState(0);
    const [totalJulio, setTotalJulio] = useState(0);
    const [totalAgosto, setTotalAgosto] = useState(0);
    const [totalSeptiembre, setTotalSeptiembre] = useState(0);
    const [totalOctubre, setTotalOctubre] = useState(0);
    const [totalNoviembre, setTotalNoviembre] = useState(0);
    const [totalDiciembre, setTotalDiciembre] = useState(0);
    const [dialogoGuardar, setDialogoGuardar] = useState(true);
    const [proyectos, setProyectos] = useState([{}]);
    const [listado, setListado] = useState();

    const [isAdmin, setEsAdmin] = useState(false);
    const [isGestion, setEsGestion] = useState(false);
    const [isUsuario, setEsUsuario] = useState(false);
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [tokenAPI, setTokenAPI] = useState<string | null>("");
    const [proyectoCargado, setProyectoCargado] = useState<boolean>(false);

    useEffect(() => {
        if (authProvider != undefined) {
            obtenerCarga(1);
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                setTokenAPI(d);
            });
        }
        if (tokenAPI != "") {
            verificarAdmin();
            verificarUsuario();
            verificarGestion();
            verificarValidador();
            if (!proyectoCargado) {
                obtenerProyecto();
            }
        }
    }, [id, ot, nombreProyecto, hh, fechaCarga, estado, responsable, horasNoche, isAdmin, isUsuario, esValidador, isGestion, tokenAPI]);

    const cambioPage = (page: number) => {};
    const verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsAdmin(result.ok);
            });
    };

    const verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsUsuario(result.ok);
            });
    };

    const verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsGestion(result.ok);
            });
    };

    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }

    // Listado rendicion
    const columnasCargas = [
        {
            titulo: "ID",
            interno: "ID",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "id",
        },
        {
            titulo: "OT",
            interno: "OT",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "ot",
        },
        {
            titulo: "Nombre Proyecto",
            interno: "NOMBRE_PROYECTO",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text",
        },
        {
            titulo: "HH",
            interno: "HH",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "hh",
        },
        {
            titulo: "Fecha carga",
            interno: "FECHA_CARGA",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "dateCarga",
        },
        {
            titulo: "Nombre Usuario",
            interno: "NOMBRE_USUARIO",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "ComboBox",
        },
    ];

    const accionesCarga = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver carga",
            title: "Ver carga",
        },
    ];

    const accionCarga = (accion: string, ID: number) => {
        switch (accion) {
            case "reload":
                obtenerCarga(1);
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/CargaHoras/${ID}`);
                break;
            case "paging":
                obtenerCarga(ID);
                break;
            default:
                break;
        }
    };

    const callbackFilterCarga = (id: string, value: string, value2: any) => {
        switch (id) {
            case "ID":
                setId(parseInt(value != "" ? value : "0"));
                break;
            case "OT":
                setOt(parseInt(value != "" ? value : "0"));
                break;
            case "NOMBRE_PROYECTO":
                setNombreProyecto(value != "" ? value : "0");
                break;
            case "HH":
                setHH(parseInt(value != "" ? value : "0"));
                break;
            case "FECHA_CARGA":
                if (value2 != undefined) {
                    value2 = value2.getFullYear() + "-" + (value2.getMonth() + 1) + "-" + value2.getDate();
                }
                setFechaCarga(value2);
                break;

            case "ESTADO":
                setEstadoCarga(parseInt(value));
                break;
            case "CREADO_POR_LOGIN":
                setResponsable(value2[0].mail);
                break;
            case "NOMBRE_USUARIO":
                setResponsable(value);

                break;
        }
    };

    const obtenerCarga = async (page: number) => {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        setNombreUsuario1(userName.account.name);

        if (isAdmin) {
            fetchEngine
                .getAPI(
                    `/api/Carga/GetByMe/admin/${id}/${ot}/${nombreProyecto}/${hh}/${fechaCarga}/${estado}/${
                        responsable != "" ? responsable : "0"
                    }/${page}`,
                    {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${tokenAPI}`,
                    }
                )
                .then((result: any) => {
                    if (result.ok) {
                        setDataCarga(result.data.listado);
                        setComboData([
                            {
                                id: "ESTADO",
                                value: result.data.estados,
                            },
                        ]);

                        setPagesCarga(result.contador);
                    } else {
                        console.log(result);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (isUsuario || isGestion) {
            fetchEngine
                .getAPI(
                    `/api/Carga/GetByMe/${userName.account.userName}/${id}/${ot}/${nombreProyecto}/${hh}/${fechaCarga}/${estado}/${userName.account.name}/${page}`,
                    {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${tokenAPI}`,
                    }
                )
                .then((result: any) => {
                    if (result.ok) {
                        setDataCarga(result.data.listado);
                        setComboData([
                            {
                                id: "ESTADO",
                                value: result.data.estados,
                            },
                        ]);

                        setPagesCarga(result.contador);
                    } else {
                        console.log(result);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const obtenerProyecto = async () => {
        let userName: any;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPI(`/api/Carga/getNoche/${userName.account.userName}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                const list: any[] = [];
                if (result.data) {
                    result.data.carga.map((a: any) => {
                        list.push({
                            key: a.key,
                            text: a.text,
                            aprobador_horas: a.RESPONSABLE_HORAS_LOGIN,
                            ID_ESTADO: a.ID_ESTADO,
                            ENERO: a.ENERO,
                            FEBRERO: a.FEBRERO,
                            MARZO: a.MARZO,
                            ABRIL: a.ABRIL,
                            MAYO: a.MAYO,
                            JUNIO: a.JUNIO,
                            JULIO: a.JULIO,
                            AGOSTO: a.AGOSTO,
                            SEPTIEMBRE: a.SEPTIEMBRE,
                            OCTUBRE: a.OCTUBRE,
                            NOVIEMBRE: a.NOVIEMBRE,
                            DICIEMBRE: a.DICIEMBRE,
                        });
                    });

                    let enero: number = 0;
                    let febrero: number = 0;
                    let marzo: number = 0;
                    let abril: number = 0;
                    let mayo: number = 0;
                    let junio: number = 0;
                    let julio: number = 0;
                    let agosto: number = 0;
                    let septiembre: number = 0;
                    let octubre: number = 0;
                    let noviembre: number = 0;
                    let diciembre: number = 0;

                    list.map((a: any) => {
                        enero = a.ENERO !== undefined ? enero + parseInt(a.ENERO) : 0;
                        febrero = a.FEBRERO !== undefined ? febrero + parseInt(a.FEBRERO) : 0;
                        marzo = a.MARZO !== undefined ? marzo + parseInt(a.MARZO) : 0;
                        abril = a.ABRIL !== undefined ? abril + parseInt(a.ABRIL) : 0;
                        mayo = a.MAYO !== undefined ? mayo + parseInt(a.MAYO) : 0;
                        junio = a.JUNIO !== undefined ? junio + parseInt(a.JUNIO) : 0;
                        julio = a.JULIO !== undefined ? julio + parseInt(a.JULIO) : 0;
                        agosto = a.AGOSTO !== undefined ? agosto + parseInt(a.AGOSTO) : 0;
                        septiembre = a.SEPTIEMBRE !== undefined ? septiembre + parseInt(a.SEPTIEMBRE) : 0;
                        octubre = a.OCTUBRE !== undefined ? octubre + parseInt(a.OCTUBRE) : 0;
                        noviembre = a.NOVIEMBRE !== undefined ? noviembre + parseInt(a.NOVIEMBRE) : 0;
                        diciembre = a.DICIEMBRE !== undefined ? diciembre + parseInt(a.DICIEMBRE) : 0;
                    });

                    setTotalEnero(enero);
                    setTotalFebrero(febrero);
                    setTotalMarzo(marzo);
                    setTotalAbril(abril);
                    setTotalMayo(mayo);
                    setTotalJunio(junio);
                    setTotalJulio(julio);
                    setTotalAgosto(agosto);
                    setTotalSeptiembre(septiembre);
                    setTotalOctubre(octubre);
                    setTotalNoviembre(noviembre);
                    setTotalDiciembre(diciembre);
                    setProyectos(list);
                    setProyectoCargado(true);
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const nocheOnChange = (e: any) => {
        let id = parseInt(e.target.getAttribute("data-index")); //id proyecto
        let key = e.target.id; // mes
        let value = parseInt(e.target.value); // valor noche text
        let horas = proyectos;
        let val = 0;

        if (value < 0) {
            value = 0;
        }

        switch (key) {
            case "Enero":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.ENERO = val;
                        } else {
                            e.ENERO = 0;
                        }
                    }
                });
                break;
            case "Febrero":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.FEBRERO = val;
                        } else {
                            e.FEBRERO = 0;
                        }
                    }
                });
                break;
            case "Marzo":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.MARZO = val;
                        } else {
                            e.MARZO = 0;
                        }
                    }
                });
                break;
            case "Abril":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.ABRIL = val;
                        } else {
                            e.ABRIL = 0;
                        }
                    }
                });
                break;
            case "Mayo":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.MAYO = val;
                        } else {
                            e.MAYO = 0;
                        }
                    }
                });
                break;
            case "Junio":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.JUNIO = val;
                        } else {
                            e.JUNIO = 0;
                        }
                    }
                });
                break;
            case "Julio":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.JULIO = val;
                        } else {
                            e.JULIO = 0;
                        }
                    }
                });
                break;
            case "Agosto":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.AGOSTO = val;
                        } else {
                            e.AGOSTO = 0;
                        }
                    }
                });
                break;
            case "Septiembre":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.SEPTIEMBRE = val;
                        } else {
                            e.SEPTIEMBRE = 0;
                        }
                    }
                });
                break;
            case "Octubre":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.OCTUBRE = val;
                        } else {
                            e.OCTUBRE = 0;
                        }
                    }
                });
                break;
            case "Noviembre":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.NOVIEMBRE = val;
                        } else {
                            e.NOVIEMBRE = 0;
                        }
                    }
                });
                break;
            case "Diciembre":
                val = value.toString() === "" ? 0 : value;
                horas.map((e: any) => {
                    if (e.key === id) {
                        if (val >= 0) {
                            e.DICIEMBRE = val;
                        } else {
                            e.DICIEMBRE = 0;
                        }
                    }
                });
                break;
        }

        setHorasNoche([...horas]);
        CargaNoche();

        ////console.log(horasNoche)
    };

    const CargaNoche = () => {
        let enero: number = 0;
        let febrero: number = 0;
        let marzo: number = 0;
        let abril: number = 0;
        let mayo: number = 0;
        let junio: number = 0;
        let julio: number = 0;
        let agosto: number = 0;
        let septiembre: number = 0;
        let octubre: number = 0;
        let noviembre: number = 0;
        let diciembre: number = 0;

        proyectos.map((a: any) => {
            enero = a.ENERO !== undefined ? enero + parseInt(a.ENERO) : 0;
            febrero = a.FEBRERO !== undefined ? febrero + parseInt(a.FEBRERO) : 0;
            marzo = a.MARZO !== undefined ? marzo + parseInt(a.MARZO) : 0;
            abril = a.ABRIL !== undefined ? abril + parseInt(a.ABRIL) : 0;
            mayo = a.MAYO !== undefined ? mayo + parseInt(a.MAYO) : 0;
            junio = a.JUNIO !== undefined ? junio + parseInt(a.JUNIO) : 0;
            julio = a.JULIO !== undefined ? julio + parseInt(a.JULIO) : 0;
            agosto = a.AGOSTO !== undefined ? agosto + parseInt(a.AGOSTO) : 0;
            septiembre = a.SEPTIEMBRE !== undefined ? septiembre + parseInt(a.SEPTIEMBRE) : 0;
            octubre = a.OCTUBRE !== undefined ? octubre + parseInt(a.OCTUBRE) : 0;
            noviembre = a.NOVIEMBRE !== undefined ? noviembre + parseInt(a.NOVIEMBRE) : 0;
            diciembre = a.DICIEMBRE !== undefined ? diciembre + parseInt(a.DICIEMBRE) : 0;
        });
        setTotalEnero(enero);
        setTotalFebrero(febrero);
        setTotalMarzo(marzo);
        setTotalAbril(abril);
        setTotalMayo(mayo);
        setTotalJunio(junio);
        setTotalJulio(julio);
        setTotalAgosto(agosto);
        setTotalSeptiembre(septiembre);
        setTotalOctubre(octubre);
        setTotalNoviembre(noviembre);
        setTotalDiciembre(diciembre);
    };

    const insertarCargaNoche = async (e: any) => {
        let userName: any;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        let proy = proyectos;
        let CARGA_NOCHE = {};

        CARGA_NOCHE = {
            CARGA_NOCHE: proy,
            CONSULTOR_DISPLAY: userName.account.name,
            CONSULTOR_LOGIN: userName.account.userName,
        };

        fetchEngine
            .postAPI("/api/Carga/carganoche", CARGA_NOCHE, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    obtenerProyecto();
                } else {
                    console.log(result);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const dialogoGuardarNoche = () => {
        return (
            <Dialog
                hidden={dialogoGuardar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Datos guardados",
                    subText: "Datos guardados éxitosamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            setDialogoGuardar(true);
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <BotonAyuda token={tokenAPI} />
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                        <div className="iconoRuta">
                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                            &nbsp;
                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Home Carga de Horas</span>
                        </div>
                        <div className="titulosimulador">
                            <Glyphicon glyph="time" /> Módulo Carga de Horas
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                        <div className="fijado">
                            <PrimaryButton
                                text="Guardar Noches"
                                onClick={(e) => {
                                    insertarCargaNoche(e);
                                    setDialogoGuardar(false);
                                }}
                            />
                            <PrimaryButton text="Agregar Carga Horas" href="/CargaHoras" />
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className="seleccion">
                            <ul className="nav"></ul>
                        </div>
                    </div>
                </div>

                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                <div className="tab-content">
                    <div id="tres" className={tipoTab === "Mis Cargas" ? "tab-pane fade" : "tab-pane active"}>
                        {dataCarga ? (
                            <GrillaCargaHoras
                                columnas={columnasCargas}
                                listado={dataCarga}
                                callbackPages={cambioPage}
                                callbackAcciones={accionCarga}
                                pages={pagesCarga}
                                titulo={""}
                                acciones={accionesCarga}
                                callbackFilter={callbackFilterCarga}
                                comboData={comboData}
                                setDataCarga={setDataCarga}
                                tokenAPI={tokenAPI}
                            />
                        ) : (
                            "No hay datos cargados"
                        )}
                    </div>
                </div>

                <div id="prueba">
                    <div className="ms-Grid-row">
                        {/*  calendario noche */}
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2 text-primary">
                                <label>{nombreUsuario1}</label>
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Ene</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Feb</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Mar</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Abr</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">May</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Jun</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Jul</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Ago</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Sep</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Oct</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Nov</label>
                            </div>
                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1 mt-2 labelMesNoche">
                                <label className="text-info">Dic</label>
                            </div>
                        </div>
                    </div>

                    {proyectos.map((e: any) => {
                        /* //console.log(e) */
                        return (
                            <div className="ms-Grid-row mb-2">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2">
                                        <label>{e.text} </label>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Enero"}
                                            defaultValue={e.ENERO <= 0 ? 0 : e.ENERO}
                                            value={e.ENERO <= 0 ? 0 : e.ENERO}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Febrero"}
                                            defaultValue={e.FEBRERO <= 0 ? 0 : e.FEBRERO}
                                            value={e.FEBRERO <= 0 ? 0 : e.FEBRERO}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Marzo"}
                                            defaultValue={e.MARZO <= 0 ? 0 : e.MARZO}
                                            value={e.MARZO <= 0 ? 0 : e.MARZO}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Abril"}
                                            defaultValue={e.ABRIL <= 0 ? 0 : e.ABRIL}
                                            value={e.ABRIL <= 0 ? 0 : e.ABRIL}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Mayo"}
                                            defaultValue={e.MAYO <= 0 ? 0 : e.MAYO}
                                            value={e.MAYO <= 0 ? 0 : e.MAYO}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Junio"}
                                            defaultValue={e.JUNIO <= 0 ? 0 : e.JUNIO}
                                            value={e.JUNIO <= 0 ? 0 : e.JUNIO}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Julio"}
                                            defaultValue={e.JULIO <= 0 ? 0 : e.JULIO}
                                            value={e.JULIO <= 0 ? 0 : e.JULIO}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Agosto"}
                                            defaultValue={e.AGOSTO <= 0 ? 0 : e.AGOSTO}
                                            value={e.AGOSTO <= 0 ? 0 : e.AGOSTO}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Septiembre"}
                                            defaultValue={e.SEPTIEMBRE <= 0 ? 0 : e.SEPTIEMBRE}
                                            value={e.SEPTIEMBRE <= 0 ? 0 : e.SEPTIEMBRE}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Octubre"}
                                            defaultValue={e.OCTUBRE <= 0 ? 0 : e.OCTUBRE}
                                            value={e.OCTUBRE <= 0 ? 0 : e.OCTUBRE}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Noviembre"}
                                            defaultValue={e.NOVIEMBRE <= 0 ? 0 : e.NOVIEMBRE}
                                            value={e.NOVIEMBRE <= 0 ? 0 : e.NOVIEMBRE}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                    <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                                        <TextField
                                            id={"Diciembre"}
                                            defaultValue={e.DICIEMBRE <= 0 ? 0 : e.DICIEMBRE}
                                            value={e.DICIEMBRE <= 0 ? 0 : e.DICIMEBRE}
                                            key={e.key}
                                            data-index={e.key}
                                            onChange={(e) => {
                                                nocheOnChange(e);
                                            }}
                                            type="number"></TextField>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="ms-Grid-row mb-2 ">
                    <hr className="border-info"></hr>
                </div>
                <div className="ms-Grid-row mb-2">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2 text-secondary">
                            <label>Total noches por mes</label>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalEnero.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalFebrero.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalMarzo.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalAbril.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalMayo.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalJunio.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalJulio.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalAgosto.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalSeptiembre.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalOctubre.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalNoviembre.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg1">
                            <TextField disabled value={totalDiciembre.toString()}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2 text-secondary"></div>
                        </div>
                    </div>
                </div>
                {dialogoGuardarNoche()}
            </div>
        </React.Fragment>
    );
};

export default Homecarga;
