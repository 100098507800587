import React, { useState, useEffect } from "react";
import { authProvider } from "../../authProvider";
import Dropzone from "react-dropzone";
import {
    Callout,
    Dialog,
    DialogFooter,
    DialogType,
    FontWeights,
    getTheme,
    IconButton,
    IIconProps,
    mergeStyleSets,
    PrimaryButton,
    ProgressIndicator,
    MessageBar,
    MessageBarType,
} from "office-ui-fabric-react";
import axios from "axios";
import { fetchEngine } from "../../fetchData";

interface Props {
    id: number;
    spTenant: string;
    spUrl: string;
    librarySpanish: string;
    librarySP_EN: string;
    carpeta: string;
    disabled: boolean;
    idArchivo?: number;
    numGasto?: number;
    emailUsuario: string;
    esAdminProp: boolean;
    esUsuarioProp: boolean;
    esGestionProp: boolean;
    esValidadorProp: boolean;
    esContableProp: boolean;
    callbackPropuesta?: any;
}

interface Biblioteca {
    id: string;
    nombre: string;
    url: string;
    displayName: string;
}

interface Documentos {
    name: string;
    id: string;
    url: string;
}

const emojiIcon: IIconProps = { iconName: "RecycleBin" };
const download: IIconProps = { iconName: "CloudDownload" };
const izquierda: IIconProps = { iconName: "DecreaseIndentArrowMirrored" };
const derecha: IIconProps = { iconName: "DecreaseIndentArrow" };
const graphEndPoint = fetchEngine.graphEndPoint;

const DragNDropFile = ({
    id,
    spTenant,
    librarySP_EN,
    carpeta,
    spUrl,
    disabled,
    librarySpanish,
    idArchivo,
    numGasto,
    emailUsuario,
    esAdminProp,
    esUsuarioProp,
    esGestionProp,
    esValidadorProp,
    esContableProp,
    callbackPropuesta,
}: Props) => {
    const docs: Documentos[] = [];
    const lib = {} as Biblioteca;
    const [adjuntos, setAdjuntos] = useState([] as Documentos[]);
    const [libreria, setLibreria] = useState("");
    const [cargando, setCargando] = useState(false);
    const [error, setError] = useState(false);
    const [isDialogHidden, setDialogHidden] = useState(true);
    const [documentoId, setDocumentoId] = useState("");
    const [documentoNombre, setDocumentoNombre] = useState("");
    const theme = getTheme();
    const [tokenGraph, setTokenGraph] = useState<string | null>("");

    const styles = mergeStyleSets({
        buttonArea: {
            verticalAlign: "top",
            display: "inline-block",
            textAlign: "center",
            margin: "0 100px",
            minWidth: 130,
            height: 32,
        },
        callout: {
            maxWidth: 300,
        },
        header: {
            padding: "18px 24px 12px",
        },
        title: [
            theme.fonts.xLarge,
            {
                margin: 0,
                fontWeight: FontWeights.semilight,
            },
        ],
        inner: {
            height: "100%",
            padding: "0 24px 20px",
        },
        actions: {
            position: "relative",
            marginTop: 20,
            width: "100%",
            whiteSpace: "nowrap",
        },
        subtext: [
            theme.fonts.small,
            {
                margin: 0,
                fontWeight: FontWeights.semilight,
            },
        ],
        link: [
            theme.fonts.medium,
            {
                color: theme.palette.neutralPrimary,
            },
        ],
    });

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPIGraph);
            }).then((d) => {
                setTokenGraph(d);
            });
        }
        if (tokenGraph != "") {
            obtenerBibliotecaInfo();
        }
    }, [id, esAdminProp, esUsuarioProp, esGestionProp, esValidadorProp, spUrl, numGasto, tokenGraph]);

    const obtenerBibliotecaInfo = async () => {
        axios
            .get(`${graphEndPoint}/sites/${spTenant}:${spUrl}:/lists`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenGraph}`,
                },
            })
            .then((l) => {
                let librerias = l.data.value;
                let documento = librerias.filter((libreria: any) => {
                    return libreria.displayName === librarySP_EN;
                })[0];

                setLibreria(documento.id);
                obtenerDocumentos();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const obtenerDocumentos = async () => {
        setCargando(true);
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        axios
            .get(`${graphEndPoint}/sites/${spTenant}:${spUrl}:/drives`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenGraph}`,
                },
            })
            .then((r) => {
                let librerias = r.data.value;

                let documentos = librerias.filter((l: any) => {
                    return l.name === librarySpanish;
                })[0];

                let idLibreria = documentos.id;
                let filter = "";
                if (idArchivo && numGasto) {
                    if (idArchivo < 3427) {
                        filter = "?$filter=startsWith(name,'" + idArchivo + "_')";
                    } else {
                        filter = "?$filter=startsWith(name,'" + idArchivo + "_" + numGasto + "_')";
                    }
                }
                axios
                    .get(`${graphEndPoint}/drives/${idLibreria}/root:/${carpeta}:/children${filter}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${tokenGraph}`,
                        },
                    })
                    .then((d) => {
                        let documentos = d.data.value;
                        let docInfo: Documentos[] = [];
                        documentos.forEach((d: any) => {
                            if (esAdminProp || esValidadorProp || esContableProp) {
                                docInfo.push({
                                    id: d.id,
                                    name: d.name,
                                    url: d.webUrl,
                                });
                            } else if ((esGestionProp || esUsuarioProp) && d.createdBy.user.email === emailUsuario) {
                                docInfo.push({
                                    id: d.id,
                                    name: d.name,
                                    url: d.webUrl,
                                });
                            } else if (emailUsuario === "propuesta") {
                                docInfo.push({
                                    id: d.id,
                                    name: d.name,
                                    url: d.webUrl,
                                });
                            }
                        });

                        setAdjuntos(docInfo);
                        callbackPropuesta(docInfo.length);
                        setCargando(false);
                    })
                    .catch((err) => {
                        setCargando(false);
                    });
            })
            .catch((err) => {
                setCargando(false);
            });
    };

    const manejarDocumentos = async (files: any[]) => {
        try {

            setCargando(true);
            setError(false);
            //Cargar documentos a través de la API de MSGraph
            files.forEach((f) => {
                axios
                    .get(`${graphEndPoint}/sites/${spTenant}:${spUrl}:/drives`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${tokenGraph}`,
                        },
                    })
                    .then((r) => {
                        console.log('r')
                        console.log(r)
                        let librerias = r.data.value;

                        let documentos = librerias.filter((l: any) => {
                            return l.name === librarySpanish;
                        })[0];

                        let idLibreria = documentos.id;
                        let fileName = "";
                        let isSameFileName = false;
                        let nombreConGuionesBajos = false;
                        if (f.name.search("_") > 0) {
                            nombreConGuionesBajos = true;
                        }
                        if (idArchivo !== undefined && numGasto !== undefined) {
                            fileName = idArchivo + "_" + numGasto + "_" + new Date().getTime() + "_" + f.name;
                            adjuntos.map((ads: any) => {
                                if (ads.name.split("_")[3] === f.name) {
                                    isSameFileName = true;
                                }
                            });
                        } else {
                            fileName = new Date().getTime() + "_" + f.name;
                        }
                        if (!isSameFileName && !nombreConGuionesBajos) {
                            axios
                                .put(`${graphEndPoint}/drives/${idLibreria}/root:/${carpeta}/${fileName}:/content`, f, {
                                    headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${tokenGraph}`,
                                    },
                                })
                                .then((res) => {
                                    obtenerDocumentos();
                                })
                                .catch((err) => {
                                    setCargando(false);
                                });
                        } else {
                            setError(true);
                            setCargando(false);
                        }
                    })
                    .catch((err) => {
                        console.log('err')
                        console.log(err)
                        setCargando(false);
                    });
            });

        } catch (error) {
            console.log(error)
        }
    };

    const callout = (nombre: string, id: string) => {
        setDialogHidden(false);
        setDocumentoNombre(nombre);
        setDocumentoId(id);
    };

    const eliminarArchivo = async () => {
        setCargando(true);
        setDialogHidden(true);
        axios
            .get(`${graphEndPoint}/sites/${spTenant}:${spUrl}:/drives`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenGraph}`,
                },
            })
            .then((r) => {
                let librerias = r.data.value;

                let documentos = librerias.filter((l: any) => {
                    return l.name === librarySpanish;
                })[0];

                let idLibreria = documentos.id;
                axios
                    .delete(`${graphEndPoint}/drives/${idLibreria}/items/${documentoId}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${tokenGraph}`,
                        },
                    })
                    .then((d) => {
                        obtenerDocumentos();

                        setCargando(false);
                    })
                    .catch((err) => {
                        setCargando(false);
                    });
            })
            .catch((err) => {
                setCargando(false);
            });
    };

    const dialogoEliminarDocumento = () => {
        return (
            <div>
                <Dialog
                    hidden={isDialogHidden}
                    onDismiss={() => setDialogHidden(true)}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Eliminar documento",
                        subText: `¿Está seguro que desea eliminar el documento ${documentoNombre}?`,
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } },
                    }}>
                    <DialogFooter>
                        <PrimaryButton onClick={eliminarArchivo}>Eliminar</PrimaryButton>
                        <PrimaryButton onClick={() => setDialogHidden(true)}>Cerrar</PrimaryButton>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    };

    return (
        <React.Fragment>
            <Dropzone
                onDrop={(acceptedFiles) => {
                    console.log('acceptedFiles')
                    console.log(acceptedFiles)
                    manejarDocumentos(acceptedFiles);
                }}>
                {({ getRootProps, getInputProps }) => (
                    <section onClick={() => {
                        console.log('disabled')
                        console.log(disabled)
                    }}>
                        {disabled ? (
                            ""
                        ) : (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p
                                    style={{
                                        cursor: "pointer",
                                        textAlign: "center",
                                        border: "1px dotted #0078d4",
                                        background: "whitesmoke",
                                        margin: "0 auto",
                                        width: "90%",
                                        paddingBottom: "7px",
                                    }}>
                                    <IconButton iconProps={izquierda} />
                                    Arrastre o seleccione archivos haciendo clic aquí
                                    <IconButton iconProps={derecha} />
                                </p>
                            </div>
                        )}
                        {cargando && <ProgressIndicator label="Procesando documento" description="Espere mientras se procesa el documento" />}
                        {error && (
                            <MessageBar messageBarType={MessageBarType.blocked} isMultiline={false} className="mt-2">
                                REVISE su archivo, tiene el mismo nombre que otro que ya esta subido o tiene guiones bajos.
                            </MessageBar>
                        )}
                        <div>
                            <table className={"table"}>
                                <thead>
                                    <tr>
                                        <th>Nombre</th>
                                        <th>Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {adjuntos
                                        ? adjuntos.map((a, i) => {
                                            return (
                                                <tr key={"tr_" + i}>
                                                    <td key={"n_" + i}>{a.name}</td>
                                                    <td key={"a_" + i}>
                                                        <a href={a.url} target="_blank">
                                                            <IconButton iconProps={download} title="Descargar documento" ariaLabel="" />
                                                        </a>
                                                        {disabled ? (
                                                            ""
                                                        ) : (
                                                            <IconButton
                                                                iconProps={emojiIcon}
                                                                title="Eliminar documento"
                                                                onClick={() => {
                                                                    callout(a.name, a.id);
                                                                }}
                                                                ariaLabel="Eliminar documento"
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                        : ""}
                                </tbody>
                            </table>
                            {dialogoEliminarDocumento()}
                        </div>
                    </section>
                )}
            </Dropzone>
        </React.Fragment>
    );
};

export default DragNDropFile;
