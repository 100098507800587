import { useHistory } from "react-router-dom";
import GrillaRendiciones from "./../GrillaDatos/GrillaRendiciones";

import React, { useState, useEffect } from "react";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { fetchEngine } from "../../fetchData";
import { Categoria, Estado, GastoMasivo, GastoRendicion, RendicionDisplay, TipoRendicion } from "../../controles/entidades/Rendicion";
import { DatePicker, DayOfWeek, IDatePickerStrings, mergeStyleSets } from "@fluentui/react";
import "../../custom.css";
import "./Rendicion.css";
import "../Home/Home.css";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import DialogosEstadoRendiciones from "../Rendicion/DialogosEstadoRendiciones";
import { getFiltersOfStorage, saveFiltersInStorage } from "../../store/filters";
import { defaultFilters, IFiltersString } from "../../controles/interfaces/filters";
import BotonAyuda from "../BotonAyuda/BotonAyuda"

const HomeRendicion = () => {
    let userName: any;
    const history = useHistory();
    if (authProvider.getAccountInfo() != null) {
        userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
    }
    const [dataRendicion, setDataRendicion] = useState<RendicionDisplay[]>([]);
    let gastoMasivo = {
        GASTOS_SELECCIONADOS: [],
        ESTADO_GASTO: 0,
        USUARIO_APROBO_RECHAZO: "",
        FECHA_APROBO_RECHAZO: new Date(),
        MOTIVO_RECHAZO: "",
    };
    const [gastosMasivos, setGastosMasivos] = useState<GastoMasivo>(gastoMasivo);
    const [pagesRendicion, setPagesRendicion] = useState(0);
    const [usuario, setUsuario] = useState<string>(userName.account.userName);
    const storageFilters = getFiltersOfStorage("filtersRendiciones");
    const storageColumnas = getFiltersOfStorage("columnasRendiciones");
    const [filtros, setFiltros] = useState(storageFilters || defaultFilters);

    const [listadoEstados, setListadoEstados] = useState<Estado[]>([]);
    const [listadoCategorias, setListadoCategorias] = useState<Categoria[]>([]);
    const [listadoTipoRendiciones, setListadoTipoRendiciones] = useState<TipoRendicion[]>([]);

    const [fechaDesde, setFechaDesde] = useState<Date | undefined>(undefined);
    const [fechaHasta, setFechaHasta] = useState<Date | undefined>(undefined);

    const [esAdmin, setEsAdmin] = useState<boolean>(false);
    const [esGestion, setEsGestion] = useState<boolean>(false);
    const [esUsuario, setEsUsuario] = useState<boolean>(false);
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [esContable, setEsContable] = useState<boolean>(false);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [gastosSeleccionados, setGastosSeleccionados] = useState<number[]>([]);

    const [dialogoAprobar, setDialogoAprobar] = useState<boolean>(true);
    const [dialogoRechazar, setDialogoRechazar] = useState<boolean>(true);
    const [dialogoMotivoRechazo, setDialogoMotivoRechazo] = useState<boolean>(true);
    const [dialogoContabilizar, setDialogoContabilizar] = useState<boolean>(true);
    const [dialogoValidarGasto, setDialogoValidarGasto] = useState<boolean>(true);
    const [motivoRechazo, setMotivoRechazo] = useState<string>("");
    const [estadoGasto, setEstadoGasto] = useState<number>(0);

    const columnasRendicionInicial = [
        {
            titulo: "",
            interno: "",
            posicion: 1,
            ordenable: false,
            filtrable: false,
            filterType: "checkbox",
            disabled: false,
        },
        {
            titulo: "ID",
            interno: "ID",
            posicion: 2,
            ordenable: true,
            filtrable: true,
            filterType: "id",
            disabled: false,
        },
        {
            titulo: "N° Gasto",
            interno: "NUMERO_GASTO",
            posicion: 3,
            ordenable: true,
            filtrable: true,
            filterType: "numGasto",
            disabled: false,
        },
        {
            titulo: "Monto",
            interno: "MONTO",
            posicion: 4,
            ordenable: false,
            filtrable: true,
            filterType: "monto",
            disabled: false,
        },
        {
            titulo: "OT",
            interno: "OT",
            posicion: 5,
            ordenable: false,
            filtrable: true,
            filterType: "ot",
            disabled: false,
        },
        {
            titulo: "Nombre Proyecto",
            interno: "NOMBRE_PROYECTO",
            posicion: 6,
            ordenable: false,
            filtrable: true,
            filterType: "nombreProyecto",
            disabled: false,
        },
        {
            titulo: "Concepto",
            interno: "CATEGORIA_RENDICION",
            posicion: 7,
            ordenable: true,
            filtrable: true,
            filterType: "categoria",
            disabled: false,
        },
        {
            titulo: "Fecha rendición",
            interno: "FECHA_RENDICION",
            posicion: 8,
            ordenable: false,
            filtrable: true,
            filterType: "fechaRendicion",
            disabled: false,
        },
        {
            titulo: "Fecha gasto",
            interno: "FECHA_GASTO",
            posicion: 9,
            ordenable: false,
            filtrable: true,
            filterType: "fechaGasto",
            disabled: false,
        },
        {
            titulo: "Creador",
            interno: "CREADO_POR_DISPLAY",
            posicion: 10,
            ordenable: false,
            filtrable: true,
            filterType: "creador",
            disabled: false,
        },
        {
            titulo: "Mes/Año Uso",
            interno: "MES_ANIO_USO",
            posicion: 11,
            ordenable: false,
            filtrable: true,
            filterType: "mesAnioUso",
            disabled: true,
        },
        {
            titulo: "Tipo Rendición",
            interno: "TIPO_RENDICION",
            posicion: 12,
            ordenable: false,
            filtrable: true,
            filterType: "tipoRendicion",
            disabled: true,
        },
        {
            titulo: "Proveedor",
            interno: "PROVEEDOR",
            posicion: 13,
            ordenable: false,
            filtrable: true,
            filterType: "proveedor",
            disabled: true,
        },
        {
            titulo: "N° Boleta/Factura",
            interno: "N_BOLETA_FACTURA",
            posicion: 14,
            ordenable: false,
            filtrable: true,
            filterType: "numBoleta",
            disabled: true,
        },
        {
            titulo: "Aprobador",
            interno: "USUARIO_APROBO_RECHAZO",
            posicion: 15,
            ordenable: false,
            filtrable: true,
            filterType: "aprobador",
            disabled: true,
        },
        {
            titulo: "Estado",
            interno: "ESTADO",
            posicion: 16,
            ordenable: true,
            filtrable: true,
            filterType: "estado",
            disabled: false,
        },
        {
            titulo: "Usuario",
            interno: "ASIGNADO",
            posicion: 17,
            ordenable: false,
            filtrable: true,
            filterType: "asignado",
            disabled: false,
        },
    ];

    const [columnasRendicion, setColumnasRendicion] = useState<any[]>(storageColumnas || columnasRendicionInicial);
    const [tokenAPI, setTokenAPI] = useState<string>("");

    const cambioPage = (page: number) => {
    };

    const DayPickerStrings: IDatePickerStrings = {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        shortDays: ["D", "L", "M", "M", "J", "V", "S"],
        goToToday: "Ir a hoy",
        prevMonthAriaLabel: "Ir al mes previo",
        nextMonthAriaLabel: "Ir al mes siguiente",
        prevYearAriaLabel: "Ir al año anterior",
        nextYearAriaLabel: "Ir al año siguiente",
        closeButtonAriaLabel: "Cerrar el selector de fechas",
    };

    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 15px 0",
            maxWidth: "300px",
        },
    });

    const onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    const onSelectDate = (date: Date | null | undefined, id: string): void => {
        if (date != null) {
            switch (id) {
                case "FECHA_DESDE":
                    setFechaDesde(date);
                    break;
                case "FECHA_HASTA":
                    setFechaHasta(date);
                    break;
            }
        }
    };

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                setTokenAPI(d);
            });
        }
        if (tokenAPI != "") {
            verificarAdmin();
            verificarUsuario();
            verificarGestion();
            verificarValidador();
            verificarContable();
            obtenerRendiciones();
        }
    }, [filtros, esAdmin, esUsuario, esGestion, tokenAPI]);

    const accionesRendicion = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver rendición",
            title: "Ver rendición",
        },
        {
            id: "pdf",
            icon: "PDF",
            tooltip: "Generar PDF",
            title: "Generar PDF",
        },
    ];

    const accionRendicion = (accion: string, ID: number, idGasto: number) => {
        switch (accion) {
            case "reload":
                obtenerRendiciones();
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/ver-rendicion/${ID}/${idGasto}`);
                break;
            case "paging":
                const copyFilters: IFiltersString = { ...filtros };
                copyFilters.pagina = ID;
                setFiltros(copyFilters);
                obtenerRendiciones();
                saveFiltersInStorage("filtersRendiciones", copyFilters);
                break;
            case "pdf":
                generarPDF(ID);
                break;
            default:
                break;
        }
    };

    async function verificarAdmin() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsAdmin(result.ok);
            });
    }
    async function verificarUsuario() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsUsuario(result.ok);
            });
    }

    async function verificarGestion() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsGestion(result.ok);
            });
    }
    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }
    async function verificarContable() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetContable`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsContable(result.ok);
            });
    }

    const callbackFilterRendicion = (id: string, value: string, value2: any) => {
        const copyFilters: IFiltersString = { ...filtros };

        switch (id) {
            case "ID":
                copyFilters.id = value;
                // setId(parseInt(value));
                break;
            case "NUMERO_GASTO":
                copyFilters.numGasto = value;
                // setNumGasto(parseInt(value));
                break;
            case "MONTO":
                copyFilters.monto = value;
                // setMonto(parseInt(value));
                break;
            case "OT":
                copyFilters.ot = value;
                // setOt(parseInt(value));
                break;
            case "NOMBRE_PROYECTO":
                copyFilters.nombreProyecto = value;
                // setNombreProyecto(value);
                break;
            case "CATEGORIA_RENDICION":
                copyFilters.conceptoRendicion = value;
                // setCategoriaRendicion(parseInt(value));
                break;
            case "FECHA_RENDICION":
                if (value2 !== undefined && value2 !== "0") {
                    value2 = value2.getFullYear() + "-" + (value2.getMonth() + 1) + "-" + value2.getDate();
                }
                copyFilters.fechaRendicion = value2;
                // setFechaRendicion(value2);
                break;
            case "FECHA_GASTO":
                if (value2 !== undefined && value2 !== "0") {
                    value2 = value2.getFullYear() + "-" + (value2.getMonth() + 1) + "-" + value2.getDate();
                }
                copyFilters.fechaGasto = value2;
                // setFechaGasto(value2);
                break;
            case "CREADO_POR_DISPLAY":
                copyFilters.creador = value;
                // setCreador(value);
                break;
            case "MES_ANIO_USO":
                if (value2 !== undefined && value2 !== "0") {
                    value2 = value2.getFullYear() + "-" + (value2.getMonth() + 1) + "-" + value2.getDate();
                }
                copyFilters.mesAnioUso = value2;
                break;
            case "TIPO_RENDICION":
                copyFilters.tipoRendicion = value;
                break;
            case "PROVEEDOR":
                copyFilters.proveedor = value;
                break;
            case "N_BOLETA_FACTURA":
                copyFilters.numBoleta = value;
                break;
            case "USUARIO_APROBO_RECHAZO":
                copyFilters.usuarioAproboRechazo = value;
                break;
            case "ESTADO":
                copyFilters.estadoRendicion = value;
                break;
            case "GASTO":
                setGastosSeleccionados(value2);
                break;
            case "ACTIVAR":
                setColumnasRendicion(value2);
                saveFiltersInStorage("columnasRendiciones", value2);
                break;
        }
        copyFilters.pagina = 1;
        setFiltros(copyFilters);
        saveFiltersInStorage("filtersRendiciones", copyFilters);
    };

    const formatNumber = (valor: string) => {
        let value = "";
        if (valor.includes(".")) {
            value = valor.replace(".", ",");
        } else {
            value = valor;
        }

        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return value;
    };

    const obtenerRendiciones = async () => {
        const {
            id,
            numGasto,
            monto,
            ot,
            nombreProyecto,
            conceptoRendicion,
            fechaRendicion,
            fechaGasto,
            creador,
            tipoRendicion,
            mesAnioUso,
            proveedor,
            numBoleta,
            usuarioAproboRechazo,
            estadoRendicion,
            fechaDesde,
            fechaHasta,
            seccionRendicion,
            pagina,
            asignado,
        } = filtros;

        const urlParams = `${usuario}/${id}/${numGasto}/${monto}/${ot}/${nombreProyecto}/${conceptoRendicion}/${fechaRendicion}/${fechaGasto}/${creador}/${tipoRendicion}/${mesAnioUso}/${proveedor}/${numBoleta}/${usuarioAproboRechazo}/${estadoRendicion}/${seccionRendicion}/${fechaDesde}/${fechaHasta}/${pagina}/${asignado}`;
        fetchEngine
            .getAPI(`/api/rendicion/ObtenerMisRendiciones/${urlParams}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                const estados: Estado[] = [];
                const categorias: Categoria[] = [];
                const tiposRendiciones: TipoRendicion[] = [];
                if (result.ok) {
                    result.data.rendiciones.forEach((e: any) => {
                        e.MONTO = formatNumber("$" + e.MONTO.toString());
                    });

                    setDataRendicion(result.data.rendiciones);

                    result.data.estados.map((estado: any) => {
                        estados.push({
                            key: estado.ID,
                            text: estado.TITULO,
                            disabled: !estado.ACTIVO,
                        });
                    });

                    result.data.categorias.map((categoria: any) => {
                        categorias.push({
                            key: categoria.ID,
                            text: categoria.TITULO,
                            disabled: !categoria.ACTIVO,
                        });
                    });

                    result.data.tipos.map((tipo: any) => {
                        tiposRendiciones.push({
                            key: tipo.ID,
                            text: tipo.TITULO,
                            disabled: !tipo.ACTIVO,
                        });
                    });

                    setListadoEstados(estados);
                    setListadoCategorias(categorias);
                    setListadoTipoRendiciones(tiposRendiciones);

                    if (result.contador <= 10) {
                        setPagesRendicion(result.contador / 10);
                    } else {
                        setPagesRendicion(result.contador / 10 + 1);
                    }
                } else {
                    console.log(result);
                }
            });
    };

    const laudus = async () => {
        fetchEngine
            .getAPIBlob(`/api/excel/obtenerExcel/10`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                var file = new Blob([result]);
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.download = "Reporte.xlsx";
                fileLink.click();
            });
    };

    const generarPDF = async (idRendicion: number) => {
        setLoading(false);
        fetchEngine
            .getAPIBlob(`/api/rendicion/GenerarPDF/${idRendicion}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                const file = new Blob([result]);
                const fileURL = URL.createObjectURL(file);
                const fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.download = `PDF_Rendicion_${idRendicion}_${new Date().getTime()}.pdf`;
                fileLink.click();
                setLoading(true);
            });
    };

    const limpiarFiltros = () => {
        const copyFilters = { ...filtros };
        copyFilters.id = "0";
        copyFilters.numGasto = "0";
        copyFilters.monto = "0";
        copyFilters.ot = "0";
        copyFilters.nombreProyecto = "0";
        copyFilters.conceptoRendicion = "0";
        copyFilters.fechaRendicion = "0";
        copyFilters.fechaGasto = "0";
        copyFilters.creador = "0";
        copyFilters.tipoRendicion = "0";
        copyFilters.mesAnioUso = "0";
        copyFilters.proveedor = "0";
        copyFilters.numBoleta = "0";
        copyFilters.usuarioAproboRechazo = "0";
        copyFilters.estadoRendicion = "0";
        copyFilters.fechaDesde = "0";
        copyFilters.fechaHasta = "0";
        copyFilters.asignado= "0";
        setFechaDesde(undefined);
        setFechaHasta(undefined);

        setFiltros(copyFilters);
        saveFiltersInStorage("filtersRendiciones", copyFilters);
    };

    async function modificarEstadoGasto() {
        let fechaAproboRechazo = new Date();
        let gastoMasivo = Object.assign(gastosMasivos);

        gastoMasivo.GASTOS_SELECCIONADOS = gastosSeleccionados;
        gastoMasivo.ESTADO_GASTO = estadoGasto;
        gastoMasivo.FECHA_APROBO_RECHAZO = fechaAproboRechazo;
        gastoMasivo.USUARIO_APROBO_RECHAZO = usuario;
        gastoMasivo.MOTIVO_RECHAZO = motivoRechazo;

        setGastosMasivos(gastoMasivo);

        fetchEngine
            .postAPI(`/api/rendicion/ModificarEstadoGasto`, gastoMasivo, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result) {
                    if (gastoMasivo.ESTADO_GASTO === 18) {
                        setDialogoAprobar(true);
                    } else if (gastoMasivo.ESTADO_GASTO === 19) {
                        setDialogoMotivoRechazo(true);
                    } else if (gastoMasivo.ESTADO_GASTO === 26) {
                        setDialogoValidarGasto(true);
                    } else if (gastoMasivo.ESTADO_GASTO === 27) {
                        setDialogoContabilizar(true);
                    } else if (gastoMasivo.ESTADO_GASTO === 29) {
                        setDialogoMotivoRechazo(true);
                    }
                    setGastosSeleccionados([]);
                    obtenerRendiciones();
                } else {
                    console.log(result);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function filtrarPorRangoCampos() {
        let copyFilters = { ...filtros };
        copyFilters.fechaDesde = "0";
        copyFilters.fechaHasta = "0";

        let fechaDesdeString = "";
        let fechaHastaString = "";
        if (fechaDesde && fechaHasta) {
            fechaDesdeString = fechaDesde.toLocaleDateString("ko-KR").replace(/\. /g, "-").replace(".", ""); //ko-KR es el formato de hora coreana, se utiliza para mostrar la fecha en el formato de la BD
            fechaHastaString = fechaHasta.toLocaleDateString("ko-KR").replace(/\. /g, "-").replace(".", ""); //ko-KR es el formato de hora coreana, se utiliza para mostrar la fecha en el formato de la BD
        }
        copyFilters.fechaDesde = fechaDesdeString;
        copyFilters.fechaHasta = fechaHastaString;
        setFiltros(copyFilters);
        saveFiltersInStorage("filtersRendiciones", copyFilters);
    }

    function textFieldOnChange(ev: any, e?: string) {
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.value : ev.target.value;

        switch (key) {
            case "motivoRechazo":
                setMotivoRechazo(value);
                break;
        }
    }

    function callbackDialogos(key: string) {
        console.log(key);
        switch (key) {
            case "aprobar":
                modificarEstadoGasto();
                break;
            case "siRechazar":
                setDialogoRechazar(true);
                setDialogoMotivoRechazo(false);
                break;
            case "cerrar":
                setDialogoAprobar(true);
                setDialogoRechazar(true);
                setDialogoMotivoRechazo(true);
                setDialogoContabilizar(true);
                setDialogoValidarGasto(true);
                break;
            case "rechazarGastoAprobador":
                modificarEstadoGasto();
                break;
            case "rechazarGastoValidador":
                modificarEstadoGasto();
                break;
            case "validar":
                modificarEstadoGasto();
                break;
            case "contabilizar":
                modificarEstadoGasto();
                break;
        }
    }

    function callbackSeccion(seccion: number) {
        const copyFilters = { ...filtros };
        copyFilters.seccionRendicion = seccion;
        copyFilters.pagina = 1;
        setFiltros(copyFilters);
        saveFiltersInStorage("filtersRendiciones", copyFilters);
    }

    return (
        <React.Fragment>
            <BotonAyuda token={tokenAPI} />
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                        <div className="iconoRuta">
                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                            &nbsp;
                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Home Rendición de Gastos</span>
                        </div>
                        <div className="titulosimulador">
                            <Glyphicon glyph="usd" /> Módulo Rendición de Gastos
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                        <div className="fijado">
                            <PrimaryButton text="Agregar nueva rendición" href="/crear-rendicion" />
                            {filtros.seccionRendicion === 3 && Number(filtros.estadoRendicion) === 17 && (esAdmin || esGestion) && (
                                <PrimaryButton
                                    text="Aprobar Gasto"
                                    onClick={() => {
                                        setDialogoAprobar(false);
                                        setEstadoGasto(18);
                                    }}
                                    className="ml-2"
                                />
                            )}
                            {filtros.seccionRendicion === 3 && Number(filtros.estadoRendicion) === 18 && (esAdmin || esValidador) && (
                                <PrimaryButton
                                    text="Validar Gasto"
                                    onClick={() => {
                                        setDialogoValidarGasto(false);
                                        setEstadoGasto(26);
                                    }}
                                    className="ml-2"
                                />
                            )}
                            {filtros.seccionRendicion === 3 &&
                                (Number(filtros.estadoRendicion) === 17 || Number(filtros.estadoRendicion) === 18) &&
                                (esAdmin || esValidador || esGestion) && (
                                    <PrimaryButton
                                        text="Rechazar Gasto"
                                        onClick={() => {
                                            setDialogoRechazar(false);
                                            if (Number(filtros.estadoRendicion) === 17) {
                                                setEstadoGasto(19);
                                            } else if (Number(filtros.estadoRendicion) === 18) {
                                                setEstadoGasto(29);
                                            }
                                        }}
                                        className="ml-1"
                                    />
                                )}
                            {filtros.seccionRendicion === 3 && Number(filtros.estadoRendicion) === 26 && (esAdmin || esContable) && (
                                <PrimaryButton
                                    text="Contabilizar Gasto"
                                    onClick={() => {
                                        setDialogoContabilizar(false);
                                        setEstadoGasto(27);
                                    }}
                                    className="ml-2"
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row d-flex">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg7 align-self-end">
                        <div className="seleccion">
                            <ul className="nav">
                                <li
                                    onClick={() => {
                                        callbackSeccion(1);
                                    }}
                                    className={filtros.seccionRendicion === 1 ? "active" : ""}>
                                    <a data-toggle="tab">Mis Rendiciones de Proyectos</a>
                                </li>
                                <li
                                    onClick={() => {
                                        callbackSeccion(2);
                                    }}
                                    className={filtros.seccionRendicion === 2 ? "active" : ""}>
                                    <a data-toggle="tab">Mis Rendiciones Administrativas</a>
                                </li>
                                {!esUsuario && (
                                    <li
                                        onClick={() => {
                                            callbackSeccion(3);
                                        }}
                                        className={filtros.seccionRendicion === 3 ? "active" : ""}>
                                        <a data-toggle="tab">Mis Rendiciones Asignadas</a>
                                    </li>
                                )}
                                <li
                                    onClick={() => {
                                        callbackSeccion(4);
                                    }}
                                    className={filtros.seccionRendicion === 4 ? "active" : ""}>
                                    <a data-toggle="tab">Historial de Rendiciones</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <DatePicker
                            id="FECHA_DESDE"
                            label="Fecha Desde"
                            className={controlClass.control}
                            firstDayOfWeek={DayOfWeek.Monday}
                            strings={DayPickerStrings}
                            allowTextInput={true}
                            placeholder="Seleccione una fecha..."
                            value={fechaDesde}
                            formatDate={onFormatDate}
                            disabled={false}
                            onSelectDate={(e: Date | null | undefined) => {
                                onSelectDate(e, "FECHA_DESDE");
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                        <DatePicker
                            id="FECHA_HASTA"
                            label="Fecha Hasta"
                            className={controlClass.control}
                            firstDayOfWeek={DayOfWeek.Monday}
                            strings={DayPickerStrings}
                            allowTextInput={true}
                            placeholder="Seleccione una fecha..."
                            value={fechaHasta}
                            formatDate={onFormatDate}
                            disabled={false}
                            onSelectDate={(e: Date | null | undefined) => {
                                onSelectDate(e, "FECHA_HASTA");
                            }}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg1 align-self-center">
                        <PrimaryButton
                            disabled={fechaDesde === undefined || fechaHasta === undefined ? true : false}
                            text="Buscar"
                            className="btnBuscar"
                            onClick={() => {
                                filtrarPorRangoCampos();
                            }}></PrimaryButton>
                    </div>
                </div>

                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                <div className="tab-content">
                    {dataRendicion ? (
                        <GrillaRendiciones
                            columnas={columnasRendicion}
                            listado={dataRendicion}
                            callbackPages={cambioPage}
                            callbackAcciones={accionRendicion}
                            pages={pagesRendicion}
                            titulo={""}
                            acciones={accionesRendicion}
                            callbackFilter={callbackFilterRendicion}
                            listadoEstados={listadoEstados}
                            listadoCategorias={listadoCategorias}
                            listadoTipoRendiciones={listadoTipoRendiciones}
                            seccionRendicion={filtros.seccionRendicion}
                            filters={filtros}
                            callbackLimpiarFiltros={limpiarFiltros}
                            paginaActual={filtros.pagina}
                            gastosSeleccionados={gastosSeleccionados}
                        />
                    ) : (
                        "No hay datos cargados"
                    )}
                </div>
            </div>
            <DialogosEstadoRendiciones
                dialogoAprobar={dialogoAprobar}
                dialogoRechazar={dialogoRechazar}
                dialogoMotivoRechazo={dialogoMotivoRechazo}
                motivoRechazo={motivoRechazo}
                callbackDialogos={callbackDialogos}
                textOnChange={textFieldOnChange}
                dialogoContabilizar={dialogoContabilizar}
                dialogoValidarGasto={dialogoValidarGasto}
                isLoading={isLoading}
                estadoGasto={Number(filtros.estadoRendicion)}
            />
        </React.Fragment>
    );
};

export default HomeRendicion;
