import * as React from "react";
import "../../custom.css";
import logoVantaz2021 from "../../img/logoVTZ2021.svg";
import "./Mantencion.css";

const Mantencion = () => {
    
    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row mt-5">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 mt-4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 mt-4">
                        <h5 className="titSistemaVTZ">SITIO EN MANTENCIÓN</h5>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-1 text-center">
                        <img src={logoVantaz2021} alt="" className="logoVTZ2021" />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 mt-4"></div>
                </div>

                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                        <h1 className="titHome">Sistema Integrado Gestión de Proyectos</h1>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Mantencion;
