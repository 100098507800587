import * as React from "react";
import { useState, useEffect } from "react";
import "./SideNavigation.css";
//import styles from './SideNavigation.css';
import { fetchEngine } from "../../fetchData";
import { authProvider } from "../../authProvider";
import { Glyphicon, Nav, Navbar, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NavbarToggler, NavbarBrand } from "reactstrap";

const SideNavigation = () => {
    let pathName = window.location.pathname;
    let params = pathName.split("/");
    let params2 = params[1] != "" ? params[1] : "home";
    let params3 =
        params2 === "oportunidad"
            ? "homeoportunidad"
            : params2 === "gestionproyecto"
                ? "homegestionproyecto"
                : params2 === "simulador"
                    ? "homesimulador"
                    : params2 === "CargaHoras"
                        ? "homecarga"
                        : params2;
    const [marginLeft, setMargin] = useState(-15);
    const [width, setWidth] = useState("4.5%");
    const [url, setUrl] = useState(params3);

    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isAdmin, setEsAdmin] = useState(false);
    const [isGestion, setEsGestion] = useState(false);
    const [isUsuario, setEsUsuario] = useState(false);
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [esContable, setEsContable] = useState<boolean>(false);
    const [enMantencion, setEnMantencion] = useState<boolean>(true);

    const [tokenAPI, setTokenAPI] = useState<string | null>("");

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                setTokenAPI(d);
            });
        }


        if (tokenAPI != "") {


            verificarAdmin();
            verificarUsuario();
            verificarGestion();
            verificarValidador();
            verificarContable();
            verificarSitioMantencion();
        }
    }, [tokenAPI]);

    useEffect(() => {

        ObtenerURl();
        setUrl(
            url == "oportunidad"
                ? "homeoportunidad"
                : url == "simulador"
                    ? "homesimulador"
                    : url == "gestionproyecto"
                        ? "homegestionproyecto"
                        : url == "rendicion"
                            ? "homerendicion"
                            : url == "CargaHoras"
                                ? "homecarga"
                                : url
        );

    }, [isAdmin, isAdmin, isUsuario, enMantencion, esValidador, esContable]);


    const MenuOpen = () => {
        setMenuOpen(!isMenuOpen);

        if (isMenuOpen) {
            setMargin(-15);
            setWidth("4.5%");
        } else {
            setMargin(-15);
            setWidth("9%");
        }
    };

    const verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsAdmin(result.ok);
            });
    };

    const verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsUsuario(result.ok);
            });
    };

    const verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsGestion(result.ok);
            });
    };

    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }
    async function verificarContable() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetContable`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsContable(result.ok);
            });
    }

    async function verificarSitioMantencion() {
        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    let m = result.data.parametros[0];
                    if (!m.ACTIVO) {
                        setEnMantencion(m.ACTIVO);
                    }
                    
                }
            });
    }

    const ObtenerURl = async () => {
        let pathName = window.location.pathname;
        let params = pathName.split("/");


        if (params.length > 1) {
            if (params[1] == "oportunidad") {
                setUrl("homeoportunidad");
            } else if (params[1] == "gestionproyecto") {
                setUrl("homegestionproyecto");
            } else if (params[1] == "simulador") {
                setUrl("homesimulador");
            } else if (params[1] == "" || params[1] == "") {
                setUrl("home");
            }
            setUrl(params[1]);
        } else {
            setUrl("home");
        }


    };

    return (
        <React.Fragment>
            {


                (!enMantencion && (isAdmin || isGestion || isUsuario || esValidador || esContable)) && (
                    <div id="sidebar-menu" className="sideBarMenuContainer">
                        <Navbar style={{ width: width, marginLeft: marginLeft, position: "fixed" }} fluid className="sidebar">
                            <div className="marginMenu">
                                <NavbarToggler id="slider-toggler" onClick={MenuOpen}>
                                    <Navbar.Text className="userMenu">
                                        {isMenuOpen ? (
                                            <Glyphicon glyph="remove" title="Cerrar" />
                                        ) : (
                                            <Glyphicon glyph="menu-hamburger" title="Abrir menú" />
                                        )}
                                    </Navbar.Text>
                                </NavbarToggler>
                                <Navbar.Collapse id="coll">
                                    <Navbar.Text className={"userMenu"} onClick={ObtenerURl}>
                                        <NavbarBrand className={url === "home" || url === "" ? "seleccionado" : ""} tag={Link} to={"/"}>
                                            <Glyphicon glyph="home" />
                                            <span>{isMenuOpen ? "Inicio" : ""}</span>{" "}
                                        </NavbarBrand>
                                    </Navbar.Text>
                                    {(isAdmin || isGestion || esValidador) && (
                                        <React.Fragment>
                                            <Navbar.Text className={"userMenu"} onClick={ObtenerURl}>
                                                <NavbarBrand
                                                    className={url === "homeoportunidad" ? "seleccionado" : ""}
                                                    tag={Link}
                                                    to={"/homeoportunidad"}>
                                                    <Glyphicon glyph="pushpin" />
                                                    <span>{isMenuOpen ? "Oportunidad" : ""}</span>
                                                </NavbarBrand>
                                            </Navbar.Text>
                                            <Navbar.Text className="userMenu" onClick={ObtenerURl}>
                                                <NavbarBrand
                                                    className={url === "homegestionproyecto" ? "seleccionado" : ""}
                                                    tag={Link}
                                                    to={"/homegestionproyecto"}>
                                                    <Glyphicon glyph="ok" />
                                                    <span>{isMenuOpen ? "Gestión Proyecto" : ""}</span>
                                                </NavbarBrand>
                                            </Navbar.Text>
                                            <Navbar.Text className="userMenu" onClick={ObtenerURl}>
                                                <NavbarBrand
                                                    className={url === "planificacionproyecto" ? "seleccionado" : ""}
                                                    tag={Link}
                                                    to={"/planificacionproyecto"}>
                                                    <Glyphicon glyph="calendar" />
                                                    <span>{isMenuOpen ? "Planificación Proyecto" : ""}</span>
                                                </NavbarBrand>
                                            </Navbar.Text>
                                            <Navbar.Text className="userMenu" onClick={ObtenerURl}>
                                                <NavbarBrand className={url === "homesimulador" ? "seleccionado" : ""} tag={Link} to={"/homesimulador"}>
                                                    <Glyphicon glyph="briefcase" />
                                                    <span>{isMenuOpen ? "Simulador" : ""}</span>
                                                </NavbarBrand>
                                            </Navbar.Text>
                                            <Navbar.Text className="userMenu" onClick={ObtenerURl}>
                                                <NavbarBrand className={url === "staffing" ? "seleccionado" : ""} tag={Link} to={"/staffing"}>
                                                    <Glyphicon glyph="user" />
                                                    <span>{isMenuOpen ? "Staffing" : ""}</span>
                                                </NavbarBrand>
                                            </Navbar.Text>
                                        </React.Fragment>
                                    )}
                                    {(isAdmin || isGestion || isUsuario || esContable || esValidador) && (
                                        <Navbar.Text className="userMenu" onClick={ObtenerURl}>
                                            <NavbarBrand className={url === "homerendicion" ? "seleccionado" : ""} tag={Link} to={"/homerendicion"}>
                                                <Glyphicon glyph="usd" />
                                                <span>{isMenuOpen ? "Rendición" : ""}</span>
                                            </NavbarBrand>
                                        </Navbar.Text>
                                    )}
                                    {(isAdmin || isGestion || isUsuario || esValidador) && (
                                        <Navbar.Text className="userMenu" onClick={ObtenerURl}>
                                            <NavbarBrand className={url === "homecarga" ? "seleccionado" : ""} tag={Link} to={"/homecarga"}>
                                                <Glyphicon glyph="time" />
                                                <span>{isMenuOpen ? "Carga Horas" : ""}</span>
                                            </NavbarBrand>
                                        </Navbar.Text>
                                    )}
                                    {(isAdmin || esValidador) && (
                                        <Navbar.Text className="userMenu" onClick={ObtenerURl}>
                                            <NavbarBrand className={url === "mantenedor" ? "seleccionado" : ""} tag={Link} to={"/mantenedor"}>
                                                <Glyphicon glyph="cog" />
                                                <span>{isMenuOpen ? "Administración" : ""}</span>
                                            </NavbarBrand>
                                        </Navbar.Text>
                                    )}
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </div>
                )}
        </React.Fragment>
    );
};

export default SideNavigation;
