import React, { useState } from "react";
import "../../custom.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import "./Calidad.css";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib-commonjs/DatePicker";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { TextField, MaskedTextField, ITextFieldStyles } from "office-ui-fabric-react/lib/TextField";
import { Accordion } from "../../controles/Accordion/Accordion";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ComboBox } from "office-ui-fabric-react";
import { IIconProps } from "office-ui-fabric-react";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import axios from "axios";
import { basicReduxStore } from "../../reduxStore";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { fetchEngine } from "../../fetchData";
initializeIcons();

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],

    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],

    shortDays: ["D", "L", "M", "M", "J", "V", "S"],

    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};
const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});
const moneda: IIconProps = { iconName: "AllCurrency" };
export class CalidadProps {}
export class CalidadState {}

export class Calidad extends React.Component<CalidadProps, CalidadState> {
    constructor(props: any) {
        super(props);
        let pathName = window.location.pathname;

        let params = pathName.split("/");

        let hasID = params.length > 2;

        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className="fuente">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                                {
                                    <div className="iconoRuta">
                                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                        &nbsp;
                                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Forecast</span>
                                    </div>
                                }
                                {
                                    <div className="titulosimulador">
                                        <Glyphicon glyph="flag" /> Calidad y relacionamiento con clientes
                                        {/* <img className="izquierda" src={punteroTitulo} />Gestión de Oportunidad */}
                                    </div>
                                }
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                                {/*<div className="fijado">
                                                    <DefaultButton text="Salir"></DefaultButton>
                                                    <PrimaryButton text="Guardar"></PrimaryButton>
                                        </div>*/}
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4 mb-4">
                                <div className="table-responsive-lg shadow-sm">
                                    <table className="table tablaforecast border-bottom">
                                        <thead>
                                            <tr>
                                                <th>Etapa</th>
                                                <th>Más Temprano</th>
                                                <th>
                                                    <span className="badge badge-primary">2020</span>Diciembre
                                                </th>
                                                <th>
                                                    <span className="badge badge-primary">2021</span>Enero
                                                </th>
                                                <th>
                                                    <span className="badge badge-primary">2021</span>Febrero
                                                </th>
                                                <th>Luego</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-bordered">
                                            <tr className="bordes">
                                                <td className="colTotal">
                                                    <strong>Total</strong>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>30.492.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>30.492.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>30.492.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>30.492.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>30.492.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>30.492.000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeGris">
                                                    Invitación <label className="numeroFase">0%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>5.952.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">29.760.000</span>5.952.000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeNaranjo">
                                                    Análisis Cualitativo <label className="numeroFase">10%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">23.000.000</span>11.500.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">23.000.000</span>11.500.000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeAmarillo">
                                                    Estudio de la Oferta <label className="numeroFase">20%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">23.000.000</span>11.500.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">23.000.000</span>11.500.000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeCeleste">
                                                    Propuesta presentada <label className="numeroFase">30%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">23.000.000</span>11.500.000
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">23.000.000</span>11.500.000
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeAzul">
                                                    Negociación <label className="numeroFase">0%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeVerde">
                                                    Adjudicada <label className="numeroFase">0%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeRojo">
                                                    Perdida <label className="numeroFase">0%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bordeOscuro">
                                                    Cancelada <label className="numeroFase">0%</label>
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                                <td className="text-right">
                                                    <span className="numeroChico">0</span>0
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Calidad;
