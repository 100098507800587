export const saveFiltersInStorage = (key: string, filters: any) => {
    localStorage.setItem(key, JSON.stringify(filters));
};

export const getFiltersOfStorage = (key: string) => {
    const stringFilters = localStorage.getItem(key);
    if (stringFilters != null) {
        const filters = JSON.parse(stringFilters);
        return filters;
    } else {
        return null;
    }
};
