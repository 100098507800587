import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { LogLevel, Logger } from "msal";

const logger = new Logger(
    (logLevel, message, containsPii) => {
        ////console.log("[MSAL]", message);
    },
    {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false
    }
);

const env = process.env;

// localhost
const tenantID = process.env.REACT_APP_TENANTID;
const instanceID = process.env.REACT_APP_INSTANCE;
const clientID = process.env.REACT_APP_CLIENTIDFRONT;

// console.log(process.env);

// ambiente QA imagen
/* const tenantID = "fc9f30fb-f690-4c42-bbc6-d99cb95218fa";
const instanceID = "https://login.microsoftonline.com/";
const client_id = "c4b3d926-095f-49b5-b5ca-159607039438"; */

// ambiente QA vantaz
/* const tenantID = '3788df5b-a33b-4f80-bdcd-a5dfb897f0da';
const instanceID = 'https://login.microsoftonline.com/';
const clientID = '8fc43374-cffa-4b58-81cb-add0caac9c08'; */



// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
    {
        auth: {
            //authority: "https://login.microsoftonline.com/3788df5b-a33b-4f80-bdcd-a5dfb897f0da",
            authority: `${instanceID}${tenantID}`,
            // ambiente qa imagen
            /* clientId: client_id != undefined ? client_id : "", */
            // localhost y ambiente qa vtz
            clientId: clientID != undefined ? clientID : "",
            postLogoutRedirectUri: window.location.origin,
            redirectUri: window.location.origin,
            validateAuthority: true,

            // After being redirected to the "redirectUri" page, should user
            // be redirected back to the Url where their login originated from?
            navigateToLoginRequestUrl: true
        },
        // Enable logging of MSAL events for easier troubleshooting.
        // This should be disabled in production builds.
        system: {
            logger: logger as any
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    },
    {
        scopes: ["openid"]
    },
    {
        loginType: LoginType.Redirect,
        // When a token is refreshed it will be done by loading a page in an iframe.
        // Rather than reloading the same page, we can point to an empty html file which will prevent
        // site resources from being loaded twice.
        tokenRefreshUri: window.location.origin + "/auth.html"
    }
);