import { IconButton } from "office-ui-fabric-react/lib-commonjs/Button";
import React, { useState, useEffect } from "react";
import { GastoRendicionGrilla } from "../../controles/entidades/Rendicion";

interface Props {
    listaGastos: GastoRendicionGrilla[];
    acciones: Function;
    formatNumber: Function;
    valorTotal: number;
    montoFondo: number;
    idTipoRendicion: number;
    estadoRendicion: number;
    esAdminProp: boolean;
    esGestionProp: boolean;
    esValidadorProp: boolean;
    esContableProp: boolean;
    mismoUsuario: boolean;
}

const ListaGastos = ({
    listaGastos,
    acciones,
    formatNumber,
    valorTotal,
    montoFondo,
    idTipoRendicion,
    estadoRendicion,
    esAdminProp,
    esGestionProp,
    esValidadorProp,
    esContableProp,
    mismoUsuario,
}: Props) => {
    const [valorTotalFondo, setValorTotalFondo] = useState<number>(0);

    useEffect(() => {
        let totalFondo = montoFondo - valorTotal;
        setValorTotalFondo(totalFondo);
    }, [montoFondo, valorTotal, idTipoRendicion]);

    return (
        <div className="ms-Grid-row mb-4">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                <h6 className="border-bottom border-warning text-warning">Lista de Gastos</h6>
            </div>
            <div className="itemContent">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 border-bottom border-dark text-dark">
                        <span className="font-weight-bold text-14">N° Gasto</span>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 border-bottom border-dark text-dark">
                        <span className="font-weight-bold text-14">Fecha Gasto</span>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg4 border-bottom border-dark text-dark">
                        <span className="font-weight-bold text-14">Categoria</span>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 border-bottom border-dark text-dark">
                        <span className="font-weight-bold text-14">Monto</span>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 border-bottom border-dark text-dark">
                        <span className="font-weight-bold text-14">Estado</span>
                    </div>
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 border-bottom border-dark text-dark">
                        <span className="font-weight-bold text-14">Acciones</span>
                    </div>
                </div>
                {listaGastos.map((gasto) => {
                    return (
                        <div key={gasto.ID} className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1">
                                <span className="text-14">{gasto.ID_RENDICION + "-" + gasto.NUMERO_GASTO}</span>
                            </div>
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                <span className="text-14">{gasto.FECHA_GASTO_FORMATEADA}</span>
                            </div>
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg4">
                                <span className="text-14">{gasto.CATEGORIA}</span>
                            </div>
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                <span className="text-14">{"$" + formatNumber(gasto.MONTO.toString())}</span>
                            </div>
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                <span className="text-14">{gasto.ESTADO}</span>
                            </div>
                            <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1">
                                {gasto.ID_ESTADO === 17 && (estadoRendicion === 17 || estadoRendicion === 19) && !mismoUsuario && (
                                    <IconButton
                                        iconProps={{
                                            iconName: "CheckMark",
                                        }}
                                        title="Aprobar Gasto"
                                        ariaLabel=""
                                        onClick={() => {
                                            acciones("aprobar", gasto.ID);
                                        }}
                                    />
                                )}
                                {gasto.ID_ESTADO === 18 && estadoRendicion === 18 && (esValidadorProp || esAdminProp) && (
                                    <IconButton
                                        iconProps={{
                                            iconName: "EntitlementRedemption",
                                        }}
                                        title="Validar Gasto"
                                        ariaLabel=""
                                        onClick={() => {
                                            acciones("validar", gasto.ID);
                                        }}
                                    />
                                )}
                                {((gasto.ID_ESTADO === 17 && (estadoRendicion === 17 || estadoRendicion === 19) && !mismoUsuario) ||
                                    (gasto.ID_ESTADO === 18 &&
                                        (estadoRendicion === 18 || estadoRendicion === 29) &&
                                        (esAdminProp || esValidadorProp))) && (
                                    <IconButton
                                        iconProps={{
                                            iconName: "Cancel",
                                        }}
                                        title="Rechazar Gasto"
                                        ariaLabel=""
                                        onClick={() => {
                                            acciones("rechazar", gasto.ID);
                                        }}
                                    />
                                )}

                                {gasto.ID_ESTADO === 26  && (esAdminProp || esContableProp) && (
                                    <IconButton
                                        iconProps={{
                                            iconName: "MoveToFolder",
                                        }}
                                        title="Contabilizar Gasto"
                                        ariaLabel=""
                                        onClick={() => {
                                            acciones("contabilizar", gasto.ID);
                                        }}
                                    />
                                )}
                                <IconButton
                                    iconProps={{
                                        iconName: "RedEye",
                                    }}
                                    title="Ver Gasto"
                                    ariaLabel=""
                                    onClick={() => {
                                        acciones("ver", gasto.ID);
                                    }}
                                />
                                {((gasto.ID_ESTADO === 16 && estadoRendicion === 16) || (gasto.ID_ESTADO === 19 && estadoRendicion === 19)) &&
                                    (esAdminProp || mismoUsuario) && (
                                        <IconButton
                                            iconProps={{
                                                iconName: "Delete",
                                            }}
                                            title="Eliminar Gasto"
                                            ariaLabel=""
                                            onClick={() => {
                                                acciones("eliminar", gasto.ID);
                                            }}
                                        />
                                    )}
                            </div>
                        </div>
                    );
                })}
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 border-top border-dark text-dark text-right">
                        <span className="font-weight-bold text-14">Total Gastos</span>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 border-top border-dark text-dark">
                        <span className="font-weight-bold text-14">{"$" + formatNumber(valorTotal.toString())}</span>
                    </div>
                </div>
                {idTipoRendicion === 3 && (
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 text-dark text-right">
                            <span className="font-weight-bold text-14">Fondo Restante</span>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6 text-dark">
                            <span className="font-weight-bold text-14">{"$" + formatNumber(valorTotalFondo.toString())}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ListaGastos;
