import React, { useState } from "react";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Home from "./components/Home/Home";
import Counter from "./components/Counter";
import FetchData from "./components/FetchData";
import LoginLaunch from "./auth/LoginLaunch";
import LoginOnLaunch from "./auth/LoginOnLaunch";
import Examples from "./components/examples/Examples";
import GestionProyecto from "./components/NavGestionProyecto/GestionProyecto";
import Oportunidad from "./components/NavOportunidad/Oportunidad";
import Simulador from "./components/Simulador/Simulador";
import Staffing from "./components/Staffing/Staffing";
import Mantenedor from "./components/Mantenedor/Mantenedor";
import PlanificacionProyecto from "./components/PlanificacionProyecto/PlanificacionProyecto";
import Calidad from "./components/Calidad/Calidad";
import HomeCarga from "./components/CargaHoras/HomeCarga";
import CargaHoras from "./components/CargaHoras/CargaHoras";
import Rendicion from "./components/Rendicion/Rendicion";
import HomeOportunidad from "./components/NavOportunidad/HomeOportunidad";
import HomeGestionProyecto from "./components/NavGestionProyecto/HomeGestionProyecto";
import HomeSimulador from "./components/Simulador/HomeSimulador";
import HomeRendicion from "./components/Rendicion/HomeRendicion";
import Mantencion from "./components/Mantencion/Mantencion";

import { AzureAD, AuthenticationState, IAzureADFunctionProps, MsalAuthProvider, LoginType } from "react-aad-msal";
import { basicReduxStore } from "./reduxStore";
import { authProvider } from "./authProvider";

// let token = localStorage.getItem("msal.c4b3d926-095f-49b5-b5ca-159607039438.idtoken");
// console.log(token);

export default () => (
    /* token ? (
        <Layout>
            <Route exact path="/" component={Home} />
            <Route path="/counter" component={Counter} />
            <Route path="/fetch-data/:startDateIndex?" component={FetchData} />
            <Route path="/login" component={LoginLaunch} />
            <Route path="/login-redirect" component={LoginOnLaunch} />
            <Route path="/examples" component={Examples} />
            <Route path="/homeoportunidad" component={HomeOportunidad} />
            <Route path="/oportunidad" component={Oportunidad} />
            <Route path="/homesimulador" component={HomeSimulador} />
            <Route path="/simulador" component={Simulador} />
            <Route path="/staffing" component={Staffing} />
            <Route path="/homegestionproyecto" component={HomeGestionProyecto} />
            <Route path="/gestionproyecto" component={GestionProyecto} />
            <Route path="/mantenedor" component={Mantenedor} />
            <Route path="/planificacionproyecto" component={PlanificacionProyecto} />
            <Route path="/Calidad" component={Calidad} />
            <Route path="/homecarga" component={HomeCarga} />
            <Route path="/CargaHoras" component={CargaHoras} />
            <Route path="/homerendicion" component={HomeRendicion} />
            <Route path="/rendicion" component={Rendicion} />
        </Layout>
    ) : ( */
    <AzureAD provider={authProvider} reduxStore={basicReduxStore}>
        {({ accountInfo, authenticationState, error }: IAzureADFunctionProps) => {
            return (
                <Layout>
                    <Route exact path="/" component={Home} />
                    <Route path="/counter" component={Counter} />
                    <Route path="/fetch-data/:startDateIndex?" component={FetchData} />
                    <Route path="/login" component={LoginLaunch} />
                    <Route path="/login-redirect" component={LoginOnLaunch} />
                    <Route path="/examples" component={Examples} />
                    <Route path="/homeoportunidad" component={HomeOportunidad} />
                    <Route path="/oportunidad" component={Oportunidad} />
                    <Route path="/homesimulador" component={HomeSimulador} />
                    <Route path="/simulador" component={Simulador} />
                    <Route path="/staffing" component={Staffing} />
                    <Route path="/homegestionproyecto" component={HomeGestionProyecto} />
                    <Route path="/gestionproyecto" component={GestionProyecto} />
                    <Route path="/mantenedor" component={Mantenedor} />
                    <Route path="/planificacionproyecto" component={PlanificacionProyecto} />
                    <Route path="/Calidad" component={Calidad} />
                    <Route path="/homecarga" component={HomeCarga} />
                    <Route path="/CargaHoras" component={CargaHoras} />
                    <Route path="/homerendicion" component={HomeRendicion} />
                    <Route path="/crear-rendicion" component={Rendicion} />
                    <Route path="/Sitio-Mantencion" component={Mantencion} />
                    <Route path="/ver-rendicion/:idRendicionParam/:idGastoParam" component={Rendicion} />
                </Layout>
            );
        }}
    </AzureAD>
);
// );
