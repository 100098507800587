// @ts-ignore
import BUKloader from "./BUKloader.gif";
import React, { useState } from "react";
import { validate, clean, format, getCheckDigit } from "rut.js";
import "office-ui-fabric-react/dist/css/fabric.css";
import { Label } from "office-ui-fabric-react";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { IconButton } from "@fluentui/react/lib/Button";
import { Glyphicon } from "react-bootstrap";
import "./Mantenedor.css";
import { IIconProps } from "office-ui-fabric-react";
import { ComboBox, KTP_FULL_PREFIX } from "office-ui-fabric-react";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib/DatePicker";

import { fetchEngine } from "../../fetchData";
import { authProvider } from "../../authProvider";
import "../../custom.css";
import AutoComplete from "../../controles/AutoComplete/AutoComplete";
import { MantenedorEntidad } from "../../controles/entidades/MantenedorEntidad";
import { UserMantenedores } from "../../controles/entidades/UserMantenedores";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from "@fluentui/react/lib/DetailsList";
import { MessageBar, MessageBarType } from "@fluentui/react";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

initializeIcons();
const editarMantenedor: IIconProps = { iconName: "SaveAs" };
const deleteMantenedor: IIconProps = { iconName: "Delete" };
const MantenedorIcon: IIconProps = { iconName: "AdminMLogoInverse32" };
const RolIcon: IIconProps = { iconName: "CRMServices" };
const ParamIcon: IIconProps = { iconName: "PageList" };

const columns: IColumn[] = [
    {
        key: "column1",
        name: "Tipo rendición",
        fieldName: "TIPO_RENDICION", // identificador para array con objetos
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
    },
    {
        key: "column2",
        name: "Moneda",
        fieldName: "MONEDA",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
    },
    {
        key: "column3",
        name: "Fecha rendición",
        fieldName: "FECHA_RENDICION",
        minWidth: 140,
        maxWidth: 140,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column4",
        name: "Correo usuario",
        fieldName: "CORREO_USUARIO",
        minWidth: 140,
        maxWidth: 140,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
    },
    {
        key: "column5",
        name: "Monto fondo",
        fieldName: "MONTO_FONDO",
        minWidth: 50,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column6",
        name: "Estado rendición",
        fieldName: "ESTADO_RENDICION",
        minWidth: 140,
        maxWidth: 140,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
    },
    {
        key: "column7",
        name: "Categoría",
        fieldName: "CATEGORIA",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
    },
    {
        key: "column8",
        name: "OT",
        fieldName: "OT",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column9",
        name: "Fecha gasto",
        fieldName: "FECHA_GASTO",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column10",
        name: "Monto",
        fieldName: "MONTO",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column11",
        name: "N° Boleta o Factura",
        fieldName: "N_BOLETA_FACTURA",
        minWidth: 165,
        maxWidth: 165,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column12",
        name: "Descripción",
        fieldName: "DESCRIPCION",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column13",
        name: "Proveedor",
        fieldName: "PROVEEDOR",
        minWidth: 70,
        maxWidth: 70,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column14",
        name: "Cobrable al cliente",
        fieldName: "REEMBOLSABLE",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column15",
        name: "Mes año uso",
        fieldName: "MES_ANIO_USO",
        minWidth: 110,
        maxWidth: 110,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column16",
        name: "Motivo rechazo",
        fieldName: "MOTIVO_RECHAZO",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column17",
        name: "Estado gasto",
        fieldName: "ESTADO_GASTO",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
];

const columnsCargaHoras: IColumn[] = [
    {
        key: "column1",
        name: "OT",
        fieldName: "ID_PROYECTO", // identificador para array con objetos
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        data: "number",
        isPadded: true,
    },
    {
        key: "column2",
        name: "Tipo Tarea",
        fieldName: "ID_TIPO_TAREA",
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: "string",
        isPadded: true,
    },
    {
        key: "column4",
        name: "Faena",
        fieldName: "ID_FAENA",
        minWidth: 80,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column57",
        name: "Usuario",
        fieldName: "ID_USUARIO",
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column9",
        name: "HH",
        fieldName: "HH",
        minWidth: 30,
        maxWidth: 30,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column10",
        name: "Nota",
        fieldName: "NOTA",
        minWidth: 165,
        maxWidth: 165,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
    {
        key: "column11",
        name: "Fecha Carga",
        fieldName: "FECHA_CARGA",
        minWidth: 100,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "string",
    },
];

const columnsIngresos: IColumn[] = [
    {
        key: "column1",
        name: "OT",
        fieldName: "OT",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column2",
        name: "Fecha ingreso",
        fieldName: "FECHA_INGRESO",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column2",
        name: "Monto",
        fieldName: "MONTO",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
];

const columnsEmpleados: IColumn[] = [
    {
        key: "column1",
        name: "RUT",
        fieldName: "RUT",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column2",
        name: "Correo empleado",
        fieldName: "CONSULTOR_LOGIN",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column3",
        name: "Nombre empleado",
        fieldName: "CONSULTOR_DISPLAY",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column4",
        name: "Rol",
        fieldName: "ID_CARGO",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column5",
        name: "Tipo contrato",
        fieldName: "ID_TIPO_CONTRATO",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column6",
        name: "Unidad negocio",
        fieldName: "ID_UNIDAD_NEGOCIO",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column7",
        name: "Fecha Inicio Contrato",
        fieldName: "FECHA_DESDE",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column8",
        name: "Fecha Fin Contrato",
        fieldName: "FECHA_HASTA",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
];

const columnsVacaciones: IColumn[] = [
    {
        key: "column1",
        name: "RUT",
        fieldName: "RUT",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column2",
        name: "Nombre empleado",
        fieldName: "CONSULTOR_DISPLAY",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column3",
        name: "Fecha Inicio",
        fieldName: "FECHA_DESDE",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column4",
        name: "Fecha Fin",
        fieldName: "FECHA_HASTA",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
];

const columnsLicencias: IColumn[] = [
    {
        key: "column1",
        name: "RUT",
        fieldName: "RUT",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column2",
        name: "Nombre empleado",
        fieldName: "CONSULTOR_DISPLAY",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column3",
        name: "Fecha Inicio",
        fieldName: "FECHA_DESDE",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
    {
        key: "column4",
        name: "Fecha Fin",
        fieldName: "FECHA_HASTA",
        minWidth: 135,
        maxWidth: 135,
        isRowHeader: true,
        isResizable: true,
        isPadded: true,
        data: "number",
    },
];

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],

    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],

    shortDays: ["D", "L", "M", "M", "J", "V", "S"],

    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};

const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});

export class MantenedorProps {
    titulo?: string;
}

export class MantenedorState {
    mantenedor!: MantenedorEntidad;
    Users!: UserMantenedores;
    items!: any[];
    estadodelformulario?: boolean;
    dialogoGuardar?: boolean;
    dialogo_error?: boolean;
    dialogo_update?: boolean;
    dialogo_delete?: boolean;
    tipoMantenedor?: string;
    paginas!: number;
    total!: number;
    edit?: boolean;
    id_edit?: number;
    currentPage: number = 1;
    todosPerPage!: number;
    upperPageBound!: number;
    lowerPageBound!: number;
    isPrevBtnActive!: string;
    isNextBtnActive!: string;
    pageBound!: number;
    linea!: number;
    tarifa!: string;
    valor1!: string;
    valor2!: string;
    valor3!: string;
    rut!: string;
    rutValido?: boolean;
    costo!: string;
    nivel!: number;
    contrato_marco!: number;
    grupo_clientes!: number;
    moneda!: number;
    listado_linea!: any[];
    listado_monedas!: any[];
    listado_unidadNegocio!: any[];
    listado_unidadRendicion!: any[];
    listado_contrato_marco!: any[];
    listado_grupo_clientes!: any[];
    listado_cargoPlan!: any[];
    listado_contratos!: any[];
    subtext_error!: string;
    tipoAdmin?: string;
    tipoUser?: string;
    esAdmin?: boolean;
    esValidador?: boolean;
    administradores?: any;

    panelMantendores!: any;
    panelroles!: any;
    fecha_desde!: any;
    fecha_hasta!: any;

    /* Importar rendiciones */

    dialogo_error_laudus: boolean = true;
    isDisabledCargar: boolean = true;
    isDisabledConfirmar: boolean = true;
    file_excel!: any;
    items_laudus!: any;
    isLoaded: boolean = true;
    dialogoCargaExitosa: boolean = true;
    cantidad_total: number = 0;
    cantidad_aceptada: number = 0;

    /* Fin Importar rendiciones */

    /* Importar gastos */

    isDisabledCargar_gastos: boolean = true;
    isDisabledConfirmar_gastos: boolean = true;
    file_excel_gastos!: any;
    items_laudus_gastos!: any;
    isLoaded_gastos: boolean = true;
    cantidad_total_gastos: number = 0;
    cantidad_aceptada_gastos: number = 0;

    esUsuario?: boolean;
    esGestion?: boolean;

    /* Fin Importar gastos */

    /* Importar carga de horas*/

    isDisabledCargar_cargaHora: boolean = true;
    isDisabledConfirmar_cargaHora: boolean = true;
    file_excel_cargaHora!: any;
    items_cargaHora!: any;
    isLoaded_cargaHora: boolean = true;
    cantidad_total_cargaHora: number = 0;
    cantidad_aceptada_cargaHora: number = 0;
    dialogoCargaExitosaCargaHora: boolean = true;

    /*Fin importar carga de horas */

    apiURLBUK: string = "";
    tokenBUK: string = "";

    items_empleados!: any;
    isLoaded_empleados: boolean = true;
    isLoading_empleados: boolean = true;
    cantidad_total_empleados: number = 0;
    cantidad_aceptada_empleados: number = 0;
    isDisabledEmpleados: boolean = true;

    items_vacaciones!: any;
    isLoaded_vacaciones: boolean = true;
    isLoading_vacaciones: boolean = true;
    cantidad_total_vacaciones: number = 0;
    cantidad_aceptada_vacaciones: number = 0;
    isDisabledVacaciones: boolean = true;

    items_licencias!: any;
    isLoaded_licencias: boolean = true;
    isLoading_licencias: boolean = true;
    cantidad_total_licencias: number = 0;
    cantidad_aceptada_licencias: number = 0;
    isDisabledLicencias: boolean = true;

    dialogoCargado: boolean = true;
    dialogoNoCargado: boolean = true;

    //datatable reutilizado
    openModalCargaMasivaDatosPendientes: boolean = false;
    CamposDocumentosRestantes: any;

    aprobDisplay1: string = "";
    aprobMail1: string = "";
    aprobDisplay2: string = "";
    aprobMail2: string = "";
    aprobDisplay3: string = "";
    aprobMail3: string = "";
    esadministrativo: boolean = false;
    opcionesSiNo: any = [];
    tokenAPI: string = "";
    tokenAPIGraph: string = "";
}
export class Mantenedor extends React.Component<MantenedorProps, MantenedorState> {
    private configDataTable = {
        rowsPerPageText: "Resultados por Página:",
        rangeSeparatorText: "de",
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: "Todos",
    };

    constructor(props: any) {
        super(props);

        let pathName = window.location.pathname;

        let params = pathName.split("/");
        ////console.log(params);

        let hasID = params.length > 2;

        this.state = {
            mantenedor: {
                ID: 0,
                TITULO: "",
                DESCRIPCION: "",
                ACTIVO: true,
                URL_WORKSPACE: "",
            },
            Users: {
                ID: 0,
                TEXT: "",
                LOGINNAME: "",
            },
            paginas: 0,
            total: 0,
            items: [],
            estadodelformulario: false,
            dialogoGuardar: true,
            dialogo_error: true,
            dialogo_update: true,
            dialogo_delete: true,
            tipoMantenedor: "Estado",
            edit: false,
            id_edit: 0,
            currentPage: 1,
            todosPerPage: 3,
            upperPageBound: 5,
            lowerPageBound: 0,
            isPrevBtnActive: "disabled",
            isNextBtnActive: "",
            pageBound: 5,
            linea: 0,
            contrato_marco: 0,
            grupo_clientes: 0,
            moneda: 0,
            listado_linea: [],
            listado_monedas: [],
            listado_unidadNegocio: [],
            listado_unidadRendicion: [],
            listado_contrato_marco: [],
            listado_grupo_clientes: [],
            listado_cargoPlan: [],
            listado_contratos: [],
            tarifa: "",
            valor1: "",
            valor2: "",
            valor3: "",
            costo: "",
            rut: "",
            nivel: 0,
            rutValido: true,
            subtext_error: "",
            tipoAdmin: "Mantenedor",
            tipoUser: "",
            esAdmin: false,
            esValidador: false,
            administradores: [],
            panelMantendores: false,
            panelroles: false,
            fecha_desde: null,
            fecha_hasta: null,

            dialogo_error_laudus: true,
            dialogoCargaExitosa: true,
            /* Importar rendiciones */

            isDisabledCargar: true,
            isDisabledConfirmar: true,
            file_excel: undefined,
            items_laudus: undefined,
            isLoaded: false,
            cantidad_total: 0,
            cantidad_aceptada: 0,

            /* Fin Importar rendiciones */

            /* Importar gastos */

            isDisabledCargar_gastos: true,
            isDisabledConfirmar_gastos: true,
            file_excel_gastos: undefined,
            items_laudus_gastos: undefined,
            isLoaded_gastos: false,
            cantidad_total_gastos: 0,
            cantidad_aceptada_gastos: 0,

            esUsuario: false,
            esGestion: false,

            /* Fin Importar gastos */

            /* Importar carga masiva */

            isDisabledCargar_cargaHora: true,
            isDisabledConfirmar_cargaHora: true,
            file_excel_cargaHora: undefined,
            items_cargaHora: undefined,
            isLoaded_cargaHora: false,
            cantidad_total_cargaHora: 0,
            cantidad_aceptada_cargaHora: 0,
            dialogoCargaExitosaCargaHora: true,

            /*Fin importar carga masiva */

            apiURLBUK: fetchEngine.apiURLBUK,
            tokenBUK: fetchEngine.tokenBUK,

            items_empleados: undefined,
            isLoaded_empleados: false,
            isLoading_empleados: false,
            cantidad_total_empleados: 0,
            cantidad_aceptada_empleados: 0,
            isDisabledEmpleados: true,

            items_vacaciones: undefined,
            isLoaded_vacaciones: false,
            isLoading_vacaciones: false,
            cantidad_total_vacaciones: 0,
            cantidad_aceptada_vacaciones: 0,
            isDisabledVacaciones: true,

            items_licencias: undefined,
            isLoaded_licencias: false,
            isLoading_licencias: false,
            cantidad_total_licencias: 0,
            cantidad_aceptada_licencias: 0,
            isDisabledLicencias: true,

            dialogoCargado: true,
            dialogoNoCargado: true,

            //datatable reutilizado
            openModalCargaMasivaDatosPendientes: false,
            CamposDocumentosRestantes: [],

            aprobDisplay1: "",
            aprobMail1: "",
            aprobDisplay2: "",
            aprobMail2: "",
            aprobDisplay3: "",
            aprobMail3: "",
            esadministrativo: false,
            opcionesSiNo: [
                {
                    key: 1,
                    text: "Sí",
                },
                {
                    key: 0,
                    text: "No",
                },
            ],
            tokenAPI: "",
            tokenAPIGraph: "",
        };

        this.limpiar = this.limpiar.bind(this);
        this.limpiarUser = this.limpiarUser.bind(this);
        this.userHandler = this.userHandler.bind(this);
        this.selectStaffing = this.selectStaffing.bind(this);
        this.selectUnRen1 = this.selectUnRen1.bind(this);
        this.selectUnRen2 = this.selectUnRen2.bind(this);
        this.selectUnRen3 = this.selectUnRen3.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.btnDecrementClick = this.btnDecrementClick.bind(this);
        this.btnIncrementClick = this.btnIncrementClick.bind(this);
        this.btnNextClick = this.btnNextClick.bind(this);
        this.btnPrevClick = this.btnPrevClick.bind(this);
        this.validarExtension = this.validarExtension.bind(this);
        this.validarExtensionCargaMasiva = this.validarExtensionCargaMasiva.bind(this);
        this.importarLaudus = this.importarLaudus.bind(this);
        this.importarLaudusConfirmar = this.importarLaudusConfirmar.bind(this);
        this.laudusPlantillaIngreso = this.laudusPlantillaIngreso.bind(this);
        this.laudusPlantilla = this.laudusPlantilla.bind(this);
        this.validarExtensionIngreso = this.validarExtensionIngreso.bind(this);
        this.importarLaudusIngresos = this.importarLaudusIngresos.bind(this);
        this.importarLaudusConfirmarIngresos = this.importarLaudusConfirmarIngresos.bind(this);
        this.conexionBUK = this.conexionBUK.bind(this);
        this.guardarEmpleados = this.guardarEmpleados.bind(this);
        this.conexionBUKVacaciones = this.conexionBUKVacaciones.bind(this);
        this.guardarVacaciones = this.guardarVacaciones.bind(this);
        this.conexionBUKLicencias = this.conexionBUKLicencias.bind(this);
        this.guardarLicencias = this.guardarLicencias.bind(this);
        this.importarCargaHorasConfirmar = this.importarCargaHorasConfirmar.bind(this);
        this.cargaHoraPlantilla = this.cargaHoraPlantilla.bind(this);
        this.importarCargaHorasMasiva = this.importarCargaHorasMasiva.bind(this);
    }

    componentDidMount() {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                const tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                this.setState({ tokenAPI: d.tokenAPI, tokenAPIGraph: d.tokenAPIGraph }, () => {
                    this.verificarAdmin();
                    this.verificarUsuario();
                    this.verificarGestion();
                    this.verificarValidador();
                    this.obtenerLinea();
                    this.obtenerMantenedor("Estado");
                });
            });
        }
    }

    public async itemsTable(currentPage: number) {
        this.setState({ currentPage: currentPage });

        fetchEngine
            .getAPI("/api/Usuarios?typeuser=" + this.state.tipoUser + "&page=" + currentPage + "&pageSize=10", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    let listado_obtenido = result.data.datos;
                    let total = result.data.total;
                    let paginas = result.data.paginas;

                    this.setState({
                        items: listado_obtenido,
                        total: total,
                        paginas: paginas,
                    });
                } else {
                    ////console.log(result);
                }
            });
    }

    public async itemsTableMantenedor(currentPage: number) {
        this.setState({ currentPage: currentPage });

        fetchEngine
            .getAPI("/api/Mantenedor?Mantenedor=" + this.state.tipoMantenedor + "&page=" + currentPage + "&pageSize=10", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    let listado_obtenido = result.data.datos;
                    let total = result.data.total;
                    let paginas = result.data.paginas;
                    //console.log("fetch mantenedor", listado_obtenido);
                    this.setState({
                        items: listado_obtenido,
                        total: total,
                        paginas: paginas,
                    });
                } else {
                    ////console.log(result);
                }
            });
    }

    public verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                ////console.log(result.ok)
                this.setState({ esAdmin: result.ok });
            });
    };

    public verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                ////console.log(result.ok)
                this.setState({ esUsuario: result.ok });
            });
    };

    public verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                ////console.log(result.ok)
                this.setState({ esGestion: result.ok });
            });
    };

    public verificarValidador = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esValidador: result.ok });
            });
    };

    private _onSelectDateDesde = (date: Date | null | undefined): void => {
        this.setState({ estadodelformulario: true, fecha_desde: date });
    };

    private _onSelectDateHasta = (date: Date | null | undefined): void => {
        this.setState({ estadodelformulario: true, fecha_hasta: date! });
    };

    private async obtenerLinea() {
        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    let listado_obtenido = result.data.lineas;
                    let listado_cto = result.data.contratos_marco;
                    let listado_grupo_clientes = result.data.grupo_clientes;
                    let listado_cargoPlan = result.data.cargos_plan;
                    let listado_contratos = result.data.contrato_consultor;
                    let listado_admins = result.data.administradores;
                    let listado_monedas = result.data.monedas;
                    let listado_unidadNegocio = result.data.unidades_negocios;
                    let listado_unidadRendicion = result.data.unidad_rendicion;
                    const adm: any[] = [];
                    let options_Linea = listado_obtenido.map((e: any) => {
                        return { key: e.ID, text: e.TITULO };
                    });
                    let options_contrato_marco = listado_cto.map((e: any) => {
                        return { key: e.ID, text: e.TITULO };
                    });
                    let options_unidadNegocio = listado_unidadNegocio.map((e: any) => {
                        return { key: e.ID, text: e.TITULO };
                    });
                    let options_unidadRendicion = listado_unidadRendicion.map((e: any) => {
                        return { key: e.ID, text: e.DESCRIPCION };
                    });
                    let options_grupo_clientes = listado_grupo_clientes.map((e: any) => {
                        return { key: e.ID, text: e.TITULO };
                    });
                    let options_cargos_plan = listado_cargoPlan.map((e: any) => {
                        return { key: e.ID, text: e.TITULO };
                    });
                    let options_contrato_consultor = listado_contratos.map((e: any) => {
                        return { key: e.ID, text: e.SIGLA_CONTRATO };
                    });
                    let options_monedas = listado_monedas.map((e: any) => {
                        return { key: e.ID, text: e.TITULO };
                    });
                    listado_admins.map((a: any) => {
                        if (a.ACTIVO) {
                            adm.push({
                                key: a.ID,
                                text: a.MAIL,
                            });
                        }
                    });
                    options_Linea.unshift({ key: 0, text: "Seleccionar..." });
                    options_contrato_marco.unshift({ key: 0, text: "Seleccionar..." });

                    this.setState({
                        listado_contratos: options_contrato_consultor,
                        listado_cargoPlan: options_cargos_plan,
                        listado_linea: options_Linea,
                        listado_unidadNegocio: options_unidadNegocio,
                        listado_unidadRendicion: options_unidadRendicion,
                        listado_monedas: options_monedas,
                        listado_contrato_marco: options_contrato_marco,
                        listado_grupo_clientes: options_grupo_clientes,
                        administradores: adm,
                    });
                } else {
                    console.log(result);
                }
            });
    }

    private async obtenerMantenedor(mantenedor: string) {
        this.limpiar();

        fetchEngine
            .getAPI("/api/Mantenedor?mantenedor=" + mantenedor + "&page=1&pageSize=10", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    let listado_obtenido = result.data.datos;
                    let total = result.data.total;
                    let paginas = result.data.paginas;
                    this.setState({
                        items: listado_obtenido,
                        total: total,
                        paginas: paginas,
                    });
                } else {
                    console.log(result);
                }
            });
    }

    private async obtenerLaudus() { }

    private async obtenerBUK() { }

    private async cargaMasiva() { }

    private async laudus() {
        let userName: any;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPIBlob(`/api/excel/obtenerExcel/10`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                var file = new Blob([result]);
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.download = "Rendiciones_exportado_SGP.xlsx";
                fileLink.click();
            });
    }

    private async laudusPlantilla() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPIBlob(`/api/excel/obtenerExcelPlantilla`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                ////console.log(result);

                ////console.log("Resultado");
                var file = new Blob([result]);
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.download = "Plantilla_importación_rendiciones.xlsx";
                fileLink.click();
            });
    }

    private async cargaHoraPlantilla() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPIBlob(`/api/excel/obtenerExcelPlantilla_cargaHoras`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                ////console.log(result);

                ////console.log("Resultado");
                var file = new Blob([result]);
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.download = "Plantilla_importación_cargaHoras.xlsx";
                fileLink.click();
            });
    }

    private async laudusPlantillaIngreso() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPIBlob(`/api/excel/obtenerExcelPlantillaIngreso`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                ////console.log(result);

                ////console.log("Resultado");
                var file = new Blob([result]);
                var fileURL = URL.createObjectURL(file);
                var fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.download = "Plantilla_importación_ingresos.xlsx";
                fileLink.click();
            });
    }

    private async importarLaudus() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let formData = new FormData();
        formData.append("Archivo", this.state.file_excel);
        console.log('formData')
        console.log(formData)

        fetchEngine
            .postAPI(`/api/excel/importarExcel`, formData, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                const infoExcel = result.data;
                infoExcel.forEach((row: any) => {
                    console.log(row)
                    let splited = row.MES_ANIO_USO.split("-");
                    row.MES_ANIO_USO = splited[1] + "-" + splited[2];
                    console.log(splited[1] + "-" + splited[2])
                });
                this.setState({
                    items_laudus: result.data,
                    isLoaded: true,
                    isDisabledConfirmar: false,
                    cantidad_total: result.cantidad_total,
                    cantidad_aceptada: result.cantidad_aceptado,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private async importarCargaHorasMasiva() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let formData = new FormData();
        formData.append("Archivo", this.state.file_excel_cargaHora);

        fetchEngine
            .postAPI(`/api/excel/importarExcel_CargaHoras`, formData, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({
                    items_cargaHora: result.data,
                    isLoaded_cargaHora: true,
                    isDisabledConfirmar_cargaHora: false,
                    cantidad_total_cargaHora: result.cantidad_total,
                    cantidad_aceptada_cargaHora: result.cantidad_aceptado,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private async importarLaudusIngresos() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let formData = new FormData();
        formData.append("Archivo", this.state.file_excel_gastos);

        fetchEngine
            .postAPI(`/api/excel/importarExcelIngresos`, formData, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({
                    items_laudus_gastos: result.data,
                    isLoaded_gastos: true,
                    isDisabledConfirmar_gastos: false,
                    cantidad_total_gastos: result.cantidad_total,
                    cantidad_aceptada_gastos: result.cantidad_aceptado,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private async importarLaudusConfirmar() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }


        /* let newLaudus = this.state.items_laudus.map((item) => {

            return {
                Archivo: item.Archivo,
                TIPO_RENDICION: item.TIPO_RENDICION,
                MONEDA: item.MONEDA,
                FECHA_RENDICION: item.FECHA_RENDICION,
                CORREO_USUARIO: item.CORREO_USUARIO,
                MONTO_FONDO: item.MONTO_FONDO,
                ESTADO_RENDICION: item.ESTADO_RENDICION,
                CATEGORIA: item.CATEGORIA,
                OT: item.OT,
                FECHA_GASTO: item.FECHA_GASTO,
                MONTO: item.MONTO,
                RECUPERABLE: item.RECUPERABLE,
                N_BOLETA_FACTURA: item.N_BOLETA_FACTURA,
                DESCRIPCION: item.DESCRIPCION,
                PROVEEDOR: item.PROVEEDOR,
                REEMBOLSABLE: item.REEMBOLSABLE,
                MES_ANIO_USO: item.MES_ANIO_USO,
                MOTIVO_RECHAZO: item.MOTIVO_RECHAZO,
                ESTADO_GASTO: item.ESTADO_GASTO
            }
        }) */

        let obj = {
            LAUDUS: this.state.items_laudus,
            CREADO_POR_LOGIN: userName.account.userName,
            CREADO_POR_DISPLAY: userName.account.name,
        };

        fetchEngine
            .postAPI(`/api/excel/importarExcelConfirmar`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                console.log('result: importarExcelConfirmar')
                console.log(result)
                if (result) {
                    this.setState({
                        items_laudus: undefined,
                        isLoaded: false,
                        isDisabledConfirmar: true,
                        dialogoCargaExitosa: false,
                        isDisabledCargar: true,
                    });

                    let fileInput = document.getElementById("file") as HTMLInputElement;
                    fileInput.value = "";
                }
            })
            .catch((err) => {
                console.log('error: importarExcelConfirmar.');
                console.log(err);
            });
    }

    private async importarCargaHorasConfirmar() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let obj = {
            CARGA_HORAS: this.state.items_cargaHora,
            CREADO_POR_LOGIN: userName.account.userName,
            CREADO_POR_DISPLAY: userName.account.name,
        };

        fetchEngine
            .postAPI(`/api/excel/importarExcelCargaHorasConfirmar`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok === true && this.state.cantidad_total_cargaHora > 0) {
                    this.setState({
                        items_cargaHora: undefined,
                        isLoaded_cargaHora: false,
                        isDisabledConfirmar_cargaHora: true,
                        dialogoCargado: false,
                        isDisabledCargar_cargaHora: true,
                    });

                    let fileInput = document.getElementById("fileCarga") as HTMLInputElement;

                    fileInput.value = "";
                } else {
                    this.setState({ dialogoNoCargado: false });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private async importarLaudusConfirmarIngresos() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let obj = {
            LAUDUS: this.state.items_laudus_gastos,
        };

        fetchEngine
            .postAPI(`/api/excel/importarExcelConfirmarIngresos`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({
                    items_laudus_gastos: undefined,
                    isLoaded_gastos: false,
                    isDisabledConfirmar_gastos: true,
                    dialogoCargaExitosa: false,
                    isDisabledCargar_gastos: true,
                });

                let fileInput = document.getElementById("fileIngreso") as HTMLInputElement;

                fileInput.value = "";
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private async obtenerUser(typeuser: string, page: number) {
        fetchEngine
            .getAPI("/api/Usuarios?typeuser=" + typeuser + "&page=" + page + "&pageSize=10", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    let listado_obtenido = result.data.datos;
                    let total = result.data.total;
                    let paginas = result.data.paginas;

                    this.setState({
                        items: listado_obtenido,
                        total: total,
                        paginas: paginas,
                    });
                } else {
                    console.log(result);
                }
            });
    }

    private async conexionBUK() {
        this.setState({ isLoading_empleados: true, items_empleados: undefined, isLoaded_empleados: false });

        fetchEngine
            .getAPI(`/api/Buk`, {
                Accept: "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                console.log(result);
                this.setState({
                    items_empleados: result.data.listado,
                    isLoaded_empleados: true,
                    isLoading_empleados: false,
                    cantidad_aceptada_empleados: result.cantidad_aceptado,
                    cantidad_total_empleados: result.cantidad_total,
                    isDisabledEmpleados: false,
                });
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    private async guardarEmpleados() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let obj = {
            EMPLOYEES: this.state.items_empleados,
        };

        fetchEngine
            .postAPI(`/api/Buk/importarEmpleadosConfirmar`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ isDisabledEmpleados: true, items_empleados: undefined, isLoaded_empleados: false, dialogoCargaExitosa: false });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private async conexionBUKVacaciones() {
        this.setState({ isLoading_vacaciones: true, items_vacaciones: undefined, isLoaded_vacaciones: false });

        fetchEngine
            .getAPI(`/api/Buk/obtenerVacaciones`, {
                Accept: "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({
                    items_vacaciones: result.data.listado,
                    isLoaded_vacaciones: true,
                    isLoading_vacaciones: false,
                    cantidad_aceptada_vacaciones: result.cantidad_aceptado,
                    cantidad_total_vacaciones: result.cantidad_total,
                    isDisabledVacaciones: false,
                });
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    private async guardarVacaciones() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let obj = {
            VACACIONES: this.state.items_vacaciones,
        };

        fetchEngine
            .postAPI(`/api/Buk/importarVacacionesConfirmar`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ isDisabledVacaciones: true, items_vacaciones: undefined, isLoaded_vacaciones: false, dialogoCargaExitosa: false });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private async conexionBUKLicencias() {
        this.setState({ isLoading_licencias: true, items_licencias: undefined, isLoaded_licencias: false });

        fetchEngine
            .getAPI(`/api/Buk/obtenerLicencias`, {
                Accept: "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({
                    items_licencias: result.data.listado,
                    isLoaded_licencias: true,
                    isLoading_licencias: false,
                    cantidad_aceptada_licencias: result.cantidad_aceptado,
                    cantidad_total_licencias: result.cantidad_total,
                    isDisabledLicencias: false,
                });
            })
            .catch((e: any) => {
                console.log(e);
            });
    }

    private async guardarLicencias() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let obj = {
            LICENCIAS: this.state.items_licencias,
        };

        fetchEngine
            .postAPI(`/api/Buk/importarLicenciasConfirmar`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ isDisabledLicencias: true, items_licencias: undefined, isLoaded_licencias: false, dialogoCargaExitosa: false });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    setPrevAndNextBtnClass(listid: number) {
        let totalPage: number = this.state.paginas;
        this.setState({ isNextBtnActive: "disabled", isPrevBtnActive: "disabled" });
        if (totalPage === listid && totalPage > 1) {
            this.setState({ isPrevBtnActive: "", isNextBtnActive: "disabled" });
        } else if (listid === 1 && totalPage > 1) {
            this.setState({ isNextBtnActive: "" });
        } else if (totalPage > 1) {
            this.setState({ isNextBtnActive: "" });
            this.setState({ isPrevBtnActive: "" });
        }
        if (listid === 1) {
            this.setState({ isPrevBtnActive: "disabled" });
        }
    }

    btnIncrementClick() {
        this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        let listid: number = this.state.upperPageBound + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
        if (this.state.paginas > 1) {
            if (this.state.tipoAdmin === "Mantenedor") {
                this.itemsTableMantenedor(listid);
            } else {
                this.itemsTable(listid);
            }
        }
    }

    btnDecrementClick() {
        this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        let listid = this.state.upperPageBound - this.state.pageBound;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
    }

    btnPrevClick() {
        if ((this.state.currentPage! - 1) % this.state.pageBound === 0) {
            this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        }
        let listid = this.state.currentPage! - 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
        if (this.state.paginas > 1) {
            if (this.state.tipoAdmin === "Mantenedor") {
                this.itemsTableMantenedor(listid);
            } else {
                this.itemsTable(listid);
            }
        }
    }

    btnNextClick() {
        if (this.state.currentPage! + 1 > this.state.upperPageBound) {
            this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        }
        let listid = this.state.currentPage! + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
        if (this.state.paginas > 1) {
            if (this.state.tipoAdmin === "Mantenedor") {
                this.itemsTableMantenedor(listid);
            } else {
                this.itemsTable(listid);
            }
        }
    }

    handleClick(event: any) {
        let listid = Number(event.target.id);
        this.setState({
            currentPage: listid,
        });
        this.setPrevAndNextBtnClass(listid);
        if (this.state.paginas > 1) {
            if (this.state.tipoAdmin === "Mantenedor") {
                this.itemsTableMantenedor(listid);
            } else {
                this.itemsTable(listid);
            }
        }
    }
    public paginacion() {
        const pageNumbers: Number[] = [];
        const { currentPage, todosPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;

        for (let i = 1; i <= this.state.paginas; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number: any) => {
            if (number === 1 && currentPage === 1) {
                return (
                    <li key={number} id={number} onClick={this.handleClick} className={"active" + " " + "page" + " " + number}>
                        <a id={number} onClick={this.handleClick}>
                            {number}
                        </a>
                    </li>
                );
            } else if (number < upperPageBound + 1 && number > lowerPageBound) {
                return (
                    <li
                        className={currentPage === number ? "active" : "" + " " + "page" + " " + number}
                        id={number}
                        onClick={this.handleClick}
                        key={number}>
                        <a id={number} onClick={this.handleClick}>
                            {number}
                        </a>
                    </li>
                );
            }
        });

        let pageIncrementBtn = <></>;
        if (pageNumbers.length > upperPageBound) {
            pageIncrementBtn = (
                <li onClick={this.btnIncrementClick} className={"pageitem"}>
                    <a onClick={this.btnIncrementClick}> &hellip; </a>
                </li>
            );
        }
        let pageDecrementBtn = <></>;
        if (lowerPageBound >= 1) {
            pageDecrementBtn = (
                <li onClick={this.btnDecrementClick} className={"pageitem"}>
                    <a onClick={this.btnDecrementClick}> &hellip; </a>
                </li>
            );
        }
        let renderPrevBtn = <></>;
        if (isPrevBtnActive === "disabled") {
            renderPrevBtn = (
                <li className={isPrevBtnActive + " " + "page"}>
                    <span id="btnPrev"></span>Ant.{" "}
                </li>
            );
        } else {
            renderPrevBtn = (
                <li onClick={this.btnPrevClick} className={isPrevBtnActive + " " + "page"}>
                    <a id="btnPrev" onClick={this.btnPrevClick}>
                        Ant.
                    </a>
                </li>
            );
        }
        let renderNextBtn = <></>;
        if (isNextBtnActive === "disabled") {
            renderNextBtn = (
                <li className={isNextBtnActive + " " + "page"}>
                    <span id="btnNext"> Sig.</span>
                </li>
            );
        } else {
            renderNextBtn = (
                <li onClick={this.btnNextClick} className={isNextBtnActive + " " + "page"}>
                    <a id="btnNext" onClick={this.btnNextClick}>
                        Sig.
                    </a>
                </li>
            );
        }

        return (
            <div style={{ display: "flex" }}>
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
                &nbsp;
            </div>
        );
    }
    public limpiar() {
        let mnt = this.state.mantenedor;

        mnt.TITULO = "";
        mnt.DESCRIPCION = "";
        mnt.URL_WORKSPACE = "";

        this.setState({
            mantenedor: mnt,
            linea: 0,
            fecha_desde: null,
            fecha_hasta: null,
            contrato_marco: 0,
            grupo_clientes: 0,
            rut: "",
            tarifa: "",
            costo: "",
            nivel: 0,
            valor1: "",
            valor2: "",
            valor3: "",
            aprobDisplay1: "",
            aprobDisplay2: "",
            aprobDisplay3: "",
            aprobMail1: "",
            aprobMail2: "",
            aprobMail3: "",
        });
    }

    private _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "";
        }
    };
    public limpiarUser() {
        let mnt = this.state.Users;

        mnt.LOGINNAME = "";
        mnt.TEXT = "";

        this.setState({ Users: mnt, linea: 0 });
    }
    public async insertMantenedor() {
        let fecha = "1/1/2001";
        if (this.state.fecha_desde != null && this.state.fecha_desde != "1/1/2001") {
            fecha = this.state.fecha_desde.getFullYear() + "-" + (this.state.fecha_desde.getMonth() + 1) + "-" + this.state.fecha_desde.getDate();
        }

        let fecha2 = "1/1/2001";
        if (this.state.fecha_hasta != null && this.state.fecha_hasta != "1/1/2001") {
            fecha2 = this.state.fecha_hasta.getFullYear() + "-" + (this.state.fecha_hasta.getMonth() + 1) + "-" + this.state.fecha_hasta.getDate();
        }
        this.setState({ fecha_desde: fecha, fecha_hasta: fecha2 }, () => {
            if (this.state.tipoMantenedor === "Sub-Linea" && this.state.linea === 0) {
                this.setState({ dialogo_error: false, subtext_error: "Debe seleccionar Linea" });
            } else {
                if (this.state.mantenedor.TITULO!.length > 0) {
                    fetchEngine
                        .postAPI(
                            "/api/Mantenedor/InsertMantenedor?mantenedor=" +
                            this.state.tipoMantenedor +
                            "&titulo=" +
                            this.state.mantenedor.TITULO +
                            "&descripcion=" +
                            this.state.mantenedor.DESCRIPCION +
                            "&linea=" +
                            this.state.linea +
                            "&tarifa=" +
                            this.state.tarifa +
                            "&contrato_marco=" +
                            this.state.contrato_marco +
                            "&costo=" +
                            this.state.costo +
                            "&nivel=" +
                            this.state.nivel +
                            "&grupo_clientes=" +
                            this.state.grupo_clientes +
                            "&fecha_desde=" +
                            this.state.fecha_desde +
                            "&fecha_hasta=" +
                            this.state.fecha_hasta +
                            "&rut=" +
                            this.state.rut +
                            "&aprobDisplay1=" +
                            this.state.aprobDisplay1 +
                            "&aprobDisplay2=" +
                            this.state.aprobDisplay2 +
                            "&aprobDisplay3=" +
                            this.state.aprobDisplay3 +
                            "&aprobMail1=" +
                            this.state.aprobMail1 +
                            "&aprobMail2=" +
                            this.state.aprobMail2 +
                            "&aprobMail3=" +
                            this.state.aprobMail3 +
                            "&urlWorkspace=" +
                            this.state.mantenedor.URL_WORKSPACE,
                            "",
                            {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${this.state.tokenAPI}`,
                            }
                        )
                        .then((result: any) => {
                            if (result.ok) {
                                this.setState({ dialogoGuardar: false, panelMantendores: false, panelroles: false });
                                this.limpiar();
                                this.obtenerMantenedor(this.state.tipoMantenedor!);
                            } else {
                                console.log(result);
                            }
                        });
                } else {
                    this.setState({ dialogo_error: false, subtext_error: "El campo Titulo no puede ser vacio." });
                }
            }
        });
    }

    public recuperarDatos(titulo: string, descripcion: string, urlWorkspace: string) {
        let mnt = this.state.mantenedor;

        mnt.TITULO = titulo;
        mnt.DESCRIPCION = descripcion;
        mnt.URL_WORKSPACE = urlWorkspace != null ? urlWorkspace : "";

        this.setState({ mantenedor: mnt });
    }

    public async insertUsers() {
        if (this.state.Users.LOGINNAME === "") {
            this.setState({ dialogo_error: false, subtext_error: "Debe seleccionar un usuario" });
        } else {
            fetchEngine
                .postAPI(
                    "/api/Usuarios/InsertarUsuarios?typeuser=" +
                    this.state.tipoUser +
                    "&displayname=" +
                    this.state.Users.TEXT +
                    "&loginname=" +
                    this.state.Users.LOGINNAME,
                    "",
                    {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.state.tokenAPI}`,
                    }
                )
                .then((result: any) => {
                    if (result.ok) {
                        this.setState({ dialogoGuardar: false });
                        this.limpiarUser();
                        this.obtenerUser(this.state.tipoUser!, 1);
                    } else {
                        console.log(result);
                    }
                });
        }
    }
    public async updateMantenedor(id: Number) {
        let fecha = "1/1/2001";
        if (this.state.fecha_desde != null && this.state.fecha_desde != "1/1/2001") {
            fecha = this.state.fecha_desde.getFullYear() + "-" + (this.state.fecha_desde.getMonth() + 1) + "-" + this.state.fecha_desde.getDate();
        }

        let fecha2 = "1/1/2001";
        if (this.state.fecha_hasta != null && this.state.fecha_hasta != "1/1/2001") {
            fecha2 = this.state.fecha_hasta.getFullYear() + "-" + (this.state.fecha_hasta.getMonth() + 1) + "-" + this.state.fecha_hasta.getDate();
        }
        this.setState({ fecha_desde: fecha, fecha_hasta: fecha2 }, () => {
            fetchEngine
                .postAPI(
                    "/api/Mantenedor/UpdateMantenedor?mantenedor=" +
                    this.state.tipoMantenedor +
                    "&id=" +
                    id +
                    "&activo=" +
                    this.state.mantenedor.ACTIVO +
                    "&titulo=" +
                    this.state.mantenedor.TITULO +
                    "&descripcion=" +
                    this.state.mantenedor.DESCRIPCION +
                    "&linea=" +
                    this.state.linea +
                    "&tarifa=" +
                    this.state.tarifa +
                    "&valor1=" +
                    this.state.valor1 +
                    "&valor2=" +
                    this.state.valor2 +
                    "&valor3=" +
                    this.state.valor3 +
                    "&contrato_marco=" +
                    this.state.contrato_marco +
                    "&costo=" +
                    this.state.costo +
                    "&nivel=" +
                    this.state.nivel +
                    "&grupo_clientes=" +
                    this.state.grupo_clientes +
                    "&fecha_desde=" +
                    this.state.fecha_desde +
                    "&fecha_hasta=" +
                    this.state.fecha_hasta +
                    "&aprobDisplay1=" +
                    this.state.aprobDisplay1 +
                    "&aprobDisplay2=" +
                    this.state.aprobDisplay2 +
                    "&aprobDisplay3=" +
                    this.state.aprobDisplay3 +
                    "&aprobMail1=" +
                    this.state.aprobMail1 +
                    "&aprobMail2=" +
                    this.state.aprobMail2 +
                    "&aprobMail3=" +
                    this.state.aprobMail3 +
                    "&esadmin=" +
                    this.state.esadministrativo +
                    "&urlWorkspace=" +
                    this.state.mantenedor.URL_WORKSPACE,
                    "",
                    {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.state.tokenAPI}`,
                    }
                )
                .then((result: any) => {
                    if (result.ok) {
                        this.setState({ edit: false, dialogo_update: false });
                        this.limpiar();
                        this.obtenerMantenedor(this.state.tipoMantenedor!);
                    } else {
                        console.log(result);
                    }
                });
        });
    }
    public async updateUsers(id: Number) {
        fetchEngine
            .postAPI("/api/Usuarios/UpdateUser?id=" + id + "&typeuser=" + this.state.tipoUser + "&activo=" + this.state.Users.ACTIVO, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    this.setState({ edit: false, dialogo_update: false });
                    this.obtenerUser(this.state.tipoUser!, 1);
                } else {
                    console.log(result);
                }
            });
    }
    public async deleteMantenedor(id: Number, titulo: string) {
        fetchEngine
            .postAPI("/api/Mantenedor/DeleteMantenedor?mantenedor=" + this.state.tipoMantenedor + "&id=" + id + "&titulo=" + titulo, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    this.setState({ dialogo_delete: false });
                    this.obtenerMantenedor(this.state.tipoMantenedor!);
                } else {
                    ////console.log(result);
                }
            });
    }
    private _onChangeLinea = (event: any, item: any): void => {
        this.setState({ linea: item.key });
    };
    private _onChangeContratoMarco = (event: any, item: any): void => {
        this.setState({ contrato_marco: item.key });
    };
    private _onChangeGrupoClientes = (event: any, item: any): void => {
        this.setState({ grupo_clientes: item.key });
    };

    handletable(event: any) {
        let mnt = this.state.mantenedor;
        mnt.ACTIVO = event.target.value;
        this.setState({ mantenedor: mnt });
    }
    handletable2(event: any) {
        this.setState({ esadministrativo: event.target.value });
    }
    handletableUser(event: any) {
        let mnt = this.state.Users;
        mnt.ACTIVO = event.target.value;
        this.setState({ Users: mnt });
    }
    public salirMantenedor() {
        window.location.href = `/`;
    }
    private cambiarestadoformulario() {
        this.setState({ estadodelformulario: true });
    }

    public textFieldOnChange(ev: any) {
        let key = ev.target.id;
        let value = ev.target.value;
        let mn = this.state.mantenedor;
        let val = 0;
        switch (key) {
            case "tituloMantenedor":
                mn.TITULO = value;
                break;
            case "descripcionMantenedor":
                mn.DESCRIPCION = value;
                break;
            case "tarifa":
                this.setState({ tarifa: value });
                break;
            case "valor1":
                this.setState({ valor1: value });
                break;
            case "valor2":
                this.setState({ valor2: value });
                break;
            case "valor3":
                this.setState({ valor3: value });
                break;
            case "costo":
                this.setState({ costo: value });
                break;
            case "rut":
                let rut_verificado = format(value);
                let validarRut = validate(rut_verificado);
                this.setState({ rut: rut_verificado, rutValido: validarRut });
                break;
            case "nivel":
                if (value > 0) {
                    this.setState({ nivel: value });
                }
                break;
            case "urlWorkspace":
                mn.URL_WORKSPACE = value.replace(/\/+$/g, ""); //Expresion regular que elimina el ultimo slash de la url
                break;
        }
        this.setState({ mantenedor: mn });
        //}
    }

    public ChangeProbabilidad(value: any) {
        let mn = this.state.mantenedor;
        mn.ACTIVO = value;
        this.setState({ mantenedor: mn });
    }

    public monedaAnterior(monedaAnt: string) {
        let moneda: number = 0;
        ////console.log(this.state.listado_monedas);
        ////console.log(moneda);

        this.state.listado_monedas.map((a) => {
            if (a.text === monedaAnt) {
                moneda = a.key;
            }
        });
        if (this.state.contrato_marco === 0) {
            this.setState({ contrato_marco: moneda });
        }

        return moneda;
    }

    public divMantenedor() {
        let contador = 0;
        //console.log("Items: ", this.state.items);
        //console.log("State: ",this.state);
        ////console.log(this.state.fecha_desde);
        ////console.log(this.state.fecha_hasta);
        let itemsTable =
            this.state.items != null
                ? this.state.items.map((a) => {
                    let espar = contador % 2 ? true : false;
                    contador++;
                    return (
                        <tr>
                            <td>{a.ID}</td>
                            {this.state.tipoMantenedor === "Categoria rendicion" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <ComboBox
                                            placeholder="Seleccionar..."
                                            //allowFreeform
                                            disabled={false}
                                            autoComplete="on"
                                            //defaultSelectedKey={this.monedaAnterior(a.DESCRIPCION)}
                                            selectedKey={this.state.contrato_marco}
                                            onChange={this._onChangeContratoMarco}
                                            options={this.state.listado_unidadRendicion}></ComboBox>
                                    </td>
                                ) : (
                                    <td>{a.DESCRIPCION}</td>
                                )
                            ) : (
                                ""
                            )}
                            {this.state.tipoMantenedor === "Rol simulador" ? (
                                <td>{a.CONTRATO_MARCO}</td>
                            ) : this.state.tipoMantenedor === "Cliente" ? (
                                <td>{a.GRUPO_CLIENTES}</td>
                            ) : this.state.tipoMantenedor === "Contratos marco" || this.state.tipoMantenedor === "Cargos plan" ? (
                                <td>{a.DESCRIPCION}</td>
                            ) : (
                                ""
                            )}
                            {/* <td>{a.TITULO}</td> */}
                            {this.state.tipoMantenedor === "Sub-Linea" ? <td>{a.LINEA}</td> : ""}
                            {this.state.tipoMantenedor === "Staffing" ? <td>{a.RUT}</td> : ""}

                            {this.state.tipoMantenedor === "Feriados" ||
                                this.state.tipoMantenedor === "Cargos plan" ||
                                this.state.tipoMantenedor === "Contratos marco" ||
                                this.state.tipoMantenedor === "Unidades negocio" ||
                                this.state.tipoMantenedor === "Tipo contratos" ||
                                this.state.tipoMantenedor === "Tipo tarea" ||
                                this.state.tipoMantenedor === "Tipo rendición" ||
                                this.state.tipoMantenedor === "Principales elementos del servicio" ||
                                this.state.tipoMantenedor === "Tipo contrato consultor" ||
                                this.state.tipoMantenedor === "Competidores" ||
                                this.state.tipoMantenedor === "Cliente" ||
                                this.state.tipoMantenedor === "Grupo clientes" ||
                                this.state.tipoMantenedor === "Categoria rendicion" ||
                                this.state.tipoMantenedor === "Paises" ||
                                this.state.tipoMantenedor === "Oficinas" ||
                                this.state.tipoMantenedor === "Linea" ||
                                this.state.tipoMantenedor === "Sub-Linea" ||
                                this.state.tipoMantenedor === "Rol simulador" ||
                                this.state.tipoMantenedor === "Motivos" ||
                                this.state.tipoMantenedor === "Faena" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <TextField
                                                id={"tituloMantenedor"}
                                                value={this.state.mantenedor.TITULO}
                                                onChange={(e) => {
                                                    this.textFieldOnChange(e);
                                                }}></TextField>
                                        </div>
                                    </td>
                                ) : (
                                    <td>{a.TITULO}</td>
                                )
                            ) : (
                                <td>{a.TITULO}</td>
                            )}

                            {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                     <TextField id={"tituloMantenedor"} label="Titulo" value={this.state.mantenedor.TITULO} onChange={(e) => { this.textFieldOnChange(e) }}  ></TextField>
                   </div> */}
                            {this.state.tipoMantenedor === "Staffing" ? <td>{a.DESCRIPCION}</td> : ""}

                            {this.state.tipoMantenedor === "Contratos marco" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <ComboBox
                                            placeholder="Seleccionar..."
                                            //allowFreeform
                                            disabled={false}
                                            autoComplete="on"
                                            defaultSelectedKey={this.monedaAnterior(a.LINEA)}
                                            selectedKey={this.state.contrato_marco}
                                            onChange={this._onChangeContratoMarco}
                                            options={this.state.listado_monedas}></ComboBox>
                                    </td>
                                ) : (
                                    <td>{a.LINEA}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Staffing" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <ComboBox
                                            placeholder="Seleccionar..."
                                            //allowFreeform
                                            disabled={false}
                                            autoComplete="on"
                                            selectedKey={this.state.grupo_clientes}
                                            //value={this.state.contrato_marco}
                                            //defaultValue={this.state.jefefaena}
                                            onChange={this._onChangeGrupoClientes}
                                            options={this.state.listado_cargoPlan}></ComboBox>
                                    </td>
                                ) : (
                                    <td>{a.LINEA}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Staffing" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <ComboBox
                                            placeholder="Seleccionar..."
                                            //allowFreeform
                                            disabled={false}
                                            autoComplete="on"
                                            selectedKey={this.state.contrato_marco}
                                            //value={this.state.contrato_marco}
                                            //defaultValue={this.state.jefefaena}
                                            onChange={this._onChangeContratoMarco}
                                            options={this.state.listado_contratos}></ComboBox>
                                    </td>
                                ) : (
                                    <td>{a.CONTRATO_MARCO}</td>
                                )
                            ) : (
                                ""
                            )}
                            {this.state.tipoMantenedor === "Categoria rendicion" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"tarifa"}
                                            defaultValue={a.TARIFA.toString()}
                                            value={this.state.tarifa}
                                            type={"number"}
                                            //placeholder={a.TARIFA}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.TARIFA}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Categoria rendicion" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        {a.BOOL ? (
                                            <TextField
                                                id={"urlWorkspace"}
                                                defaultValue={a.URL_WORKSPACE}
                                                value={this.state.mantenedor.URL_WORKSPACE}
                                                type={"text"}
                                                //placeholder={a.TARIFA}
                                                onChange={(e) => {
                                                    this.textFieldOnChange(e);
                                                }}></TextField>
                                        ) : (
                                            ""
                                        )}
                                    </td>
                                ) : (
                                    <td>
                                        {a.BOOL ? (
                                            a.URL_WORKSPACE != null ? (
                                                <a href={a.URL_WORKSPACE} target="_blank" rel="noreferrer">
                                                    {a.URL_WORKSPACE}
                                                </a>
                                            ) : (
                                                "-"
                                            )
                                        ) : (
                                            "N/A"
                                        )}
                                    </td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Staffing" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <ComboBox
                                            placeholder="Seleccionar..."
                                            //allowFreeform
                                            disabled={false}
                                            autoComplete="on"
                                            selectedKey={this.state.linea}
                                            //value={this.state.contrato_marco}
                                            //defaultValue={this.state.jefefaena}
                                            onChange={this._onChangeLinea}
                                            options={this.state.listado_unidadNegocio}></ComboBox>
                                    </td>
                                ) : (
                                    <td>{a.GRUPO_CLIENTES}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Staffing" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <DatePicker
                                            onChange={this.cambiarestadoformulario}
                                            id="fechacierre"
                                            className={controlClass.control}
                                            firstDayOfWeek={DayOfWeek.Monday}
                                            strings={DayPickerStrings}
                                            allowTextInput={true}
                                            placeholder={
                                                this.state.fecha_desde != null
                                                    ? this._onFormatDate(new Date(this.state.fecha_desde))
                                                    : "Fecha desde"
                                            }
                                            //placeholder="Fecha desde"
                                            //isRequired={true}
                                            //value={this.state.fecha_desde != null ? this.state.fecha_desde : ""}
                                            ariaLabel="Seleccione una fecha"
                                            formatDate={this._onFormatDate}
                                            onSelectDate={this._onSelectDateDesde}
                                        />
                                    </td>
                                ) : (
                                    <td>
                                        {this._onFormatDate(new Date(a.FECHA_DESDE)) != "1/1/1" &&
                                            this._onFormatDate(new Date(a.FECHA_DESDE)) != "1/1/2001"
                                            ? this._onFormatDate(new Date(a.FECHA_DESDE))
                                            : ""}
                                    </td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Staffing" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <DatePicker
                                            onChange={this.cambiarestadoformulario}
                                            id="fechacierre"
                                            className={controlClass.control}
                                            firstDayOfWeek={DayOfWeek.Monday}
                                            strings={DayPickerStrings}
                                            allowTextInput={true}
                                            placeholder={
                                                this.state.fecha_hasta != null
                                                    ? this._onFormatDate(new Date(this.state.fecha_hasta))
                                                    : "Fecha hasta"
                                            }
                                            //placeholder="Fecha hasta"
                                            //isRequired={true}
                                            //value={this.state.fecha_hasta != null ? this.state.fecha_hasta : ""}
                                            ariaLabel="Seleccione una fecha"
                                            formatDate={this._onFormatDate}
                                            onSelectDate={this._onSelectDateHasta}
                                        />
                                    </td>
                                ) : (
                                    <td>
                                        {this._onFormatDate(new Date(a.FECHA_HASTA)) != "1/1/1" &&
                                            this._onFormatDate(new Date(a.FECHA_HASTA)) != "1/1/2001"
                                            ? this._onFormatDate(new Date(a.FECHA_HASTA))
                                            : ""}
                                    </td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Unidades negocio" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <ComboBox
                                            placeholder="Seleccionar..."
                                            //allowFreeform
                                            disabled={false}
                                            autoComplete="on"
                                            selectedKey={this.state.grupo_clientes}
                                            //value={this.state.contrato_marco}
                                            //defaultValue={this.state.jefefaena}
                                            onChange={this._onChangeGrupoClientes}
                                            options={this.state.listado_monedas}></ComboBox>
                                    </td>
                                ) : (
                                    <td>{a.DESCRIPCION}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Unidades negocio" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <TextField
                                                id={"descripcionMantenedor"}
                                                value={this.state.mantenedor.DESCRIPCION}
                                                type={"number"}
                                                onChange={(e) => {
                                                    this.textFieldOnChange(e);
                                                }}></TextField>
                                        </div>
                                    </td>
                                ) : (
                                    <td>{a.ID_MODULO}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Tipo contrato consultor" || this.state.tipoMantenedor === "Unidad rendición" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <TextField
                                                id={"descripcionMantenedor"}
                                                value={this.state.mantenedor.DESCRIPCION}
                                                onChange={(e) => {
                                                    this.textFieldOnChange(e);
                                                }}></TextField>
                                        </div>
                                    </td>
                                ) : (
                                    <td>{a.DESCRIPCION}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Unidad rendición" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <AutoComplete
                                                id="aprobador"
                                                callback={this.selectUnRen1}
                                                multi={false}
                                                disabled={false}
                                                defaultValue={{
                                                    name: this.state.aprobDisplay1,
                                                    mail: this.state.aprobMail1,
                                                }}
                                                tokenAPIGraph={this.state.tokenAPIGraph}
                                            />
                                        </div>
                                    </td>
                                ) : (
                                    <td>{a.LINEA}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Unidad rendición" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <AutoComplete
                                                id="backup"
                                                callback={this.selectUnRen2}
                                                multi={false}
                                                disabled={false}
                                                defaultValue={{
                                                    name: this.state.aprobDisplay2,
                                                    mail: this.state.aprobMail2,
                                                }}
                                                tokenAPIGraph={this.state.tokenAPIGraph}
                                            />
                                        </div>
                                    </td>
                                ) : (
                                    <td>{a.RUT}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Unidad rendición" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <AutoComplete
                                                id="supervisor"
                                                callback={this.selectUnRen3}
                                                multi={false}
                                                disabled={false}
                                                defaultValue={{
                                                    name: this.state.aprobDisplay3,
                                                    mail: this.state.aprobMail3,
                                                }}
                                                tokenAPIGraph={this.state.tokenAPIGraph}
                                            />
                                        </div>
                                    </td>
                                ) : (
                                    <td>{a.CONTRATO_MARCO}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Feriados" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <DatePicker
                                            onChange={this.cambiarestadoformulario}
                                            id="fechacierre"
                                            className={controlClass.control}
                                            firstDayOfWeek={DayOfWeek.Monday}
                                            strings={DayPickerStrings}
                                            allowTextInput={true}
                                            placeholder={
                                                this.state.fecha_desde != null ? this._onFormatDate(new Date(this.state.fecha_desde)) : "Fecha"
                                            }
                                            //placeholder="Fecha"
                                            //value={this.state.fecha_desde}
                                            ariaLabel="Seleccione una fecha"
                                            formatDate={this._onFormatDate}
                                            onSelectDate={this._onSelectDateDesde}
                                        />
                                    </td>
                                ) : (
                                    <td>{this._onFormatDate(new Date(a.FECHA_DESDE))}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Rol simulador" || this.state.tipoMantenedor === "Contratos marco" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"tarifa"}
                                            defaultValue={a.TARIFA.toString()}
                                            value={this.state.tarifa}
                                            type={"number"}
                                            //placeholder={a.TARIFA}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.TARIFA}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Cargos plan" || this.state.tipoMantenedor === "Rol simulador" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"costo"}
                                            value={this.state.costo}
                                            type={"number"}
                                            placeholder={a.COSTO}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.COSTO}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Cargos plan" || this.state.tipoMantenedor === "Rol simulador" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"nivel"}
                                            value={this.state.nivel > 0 ? this.state.nivel.toString() : ""}
                                            type={"number"}
                                            placeholder={a.NIVEL}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.NIVEL}</td>
                                )
                            ) : (
                                ""
                            )}
                            {this.state.tipoMantenedor === "Moneda" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"tarifa"}
                                            value={this.state.tarifa}
                                            type={"number"}
                                            placeholder={a.VALOR_CLP}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.VALOR_CLP}</td>
                                )
                            ) : (
                                ""
                            )}
                            {this.state.tipoMantenedor === "Moneda" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"costo"}
                                            value={this.state.costo}
                                            type={"number"}
                                            placeholder={a.VALOR_UF}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.VALOR_UF}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Moneda" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"valor1"}
                                            value={this.state.valor1}
                                            type={"number"}
                                            placeholder={a.VALOR_SOLES}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.VALOR_SOLES}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Moneda" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"valor2"}
                                            value={this.state.valor2}
                                            type={"number"}
                                            placeholder={a.VALOR_USD}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.VALOR_USD}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Moneda" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <TextField
                                            id={"valor3"}
                                            value={this.state.valor3}
                                            type={"number"}
                                            placeholder={a.VALOR_AUD}
                                            onChange={(e) => {
                                                this.textFieldOnChange(e);
                                            }}></TextField>
                                    </td>
                                ) : (
                                    <td>{a.VALOR_AUD}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Unidad rendición" ? (
                                this.state.edit != false && this.state.id_edit == a.ID ? (
                                    <td>
                                        <select
                                            name="tableActivo"
                                            onChange={(e) => {
                                                this.handletable2(e);
                                            }}>
                                            <option value={a.BOOL == true ? "true" : "false"} selected>
                                                {a.BOOL == true ? "Sí" : "No"}
                                            </option>
                                            <option value={a.BOOL == true ? "false" : "true"}>{a.BOOL == true ? "No" : "Sí"}</option>
                                        </select>
                                    </td>
                                ) : (
                                    <td>{a.BOOL == true ? "Sí" : "No"}</td>
                                )
                            ) : (
                                ""
                            )}

                            {this.state.tipoMantenedor === "Estado" ? (
                                a.ID_MODULO === 1 ? (
                                    <td>Oportunidades</td>
                                ) : a.ID_MODULO === 2 ? (
                                    <td>Simulación</td>
                                ) : a.ID_MODULO === 3 ? (
                                    <td>Planificaciones</td>
                                ) : a.ID_MODULO === 4 ? (
                                    <td>Gestión de Proyecto</td>
                                ) : a.ID_MODULO === 5 ? (
                                    <td>Carga de horas</td>
                                ) : (
                                    <td>Rendición</td>
                                )
                            ) : (
                                ""
                            )}
                            {/* <td>{this.state.tipoMantenedor === "Estado" ? (a.ID_MODULO === 1 ? "Oportunidades" : a.ID_MODULO === 2 ? "Simulación" : a.ID_MODULO === 3 ? "Planificaciones" : a.ID_MODULO === 4 ? "Gestión de Proyecto" : a.ID_MODULO === 5 ? "Carga de horas" : "Rendición") : this.state.tipoMantenedor === "Rol simulador" ? a.NIVEL :  this.state.tipoMantenedor === "Contratos marco" ? a.TARIFA: this.state.tipoMantenedor === "Staffing" ? this._onFormatDate(new Date(a.FECHA_HASTA)): ""}</td> */}
                            {this.state.tipoMantenedor === "Estado" ? (
                                ""
                            ) : this.state.edit != false && this.state.id_edit == a.ID ? (
                                <td>
                                    <select
                                        name="tableActivo"
                                        onChange={(e) => {
                                            this.handletable(e);
                                        }}>
                                        <option value={a.ACTIVO == true ? "true" : "false"} selected>
                                            {a.ACTIVO == true ? "Activo" : "Inactivo"}
                                        </option>
                                        <option value={a.ACTIVO == true ? "false" : "true"}>{a.ACTIVO == true ? "Inactivo" : "Activo"}</option>
                                    </select>
                                </td>
                            ) : (
                                <td>{a.ACTIVO == true ? "Activo" : "Inactivo"}</td>
                            )}

                            {this.state.tipoMantenedor === "Estado" ? (
                                ""
                            ) : this.state.edit != false && this.state.id_edit == a.ID ? (
                                <td>
                                    <IconButton
                                        iconProps={editarMantenedor}
                                        title="Editar"
                                        ariaLabel="Edit"
                                        onClick={() => {
                                            console.log("edit");
                                            this.updateMantenedor(a.ID);
                                        }}
                                    />
                                </td>
                            ) : this.state.edit != false && this.state.id_edit != a.ID ? (
                                <td></td>
                            ) : (
                                <td>
                                    <IconButton
                                        iconProps={editarMantenedor}
                                        title="Editar"
                                        ariaLabel="Edit"
                                        onClick={() => {
                                            this.setState({
                                                edit: true,
                                                id_edit: a.ID,
                                                aprobDisplay1: a.LINEA,
                                                aprobMail1: a.APROBADOR_LOGIN,
                                                aprobDisplay2: a.RUT,
                                                aprobMail2: a.BACKUP_LOGIN,
                                                aprobDisplay3: a.CONTRATO_MARCO,
                                                aprobMail3: a.SUPERVISOR_LOGIN,
                                            });
                                            this.recuperarDatos(a.TITULO, a.DESCRIPCION, a.URL_WORKSPACE);
                                        }}
                                    />
                                </td>
                            )}
                        </tr>
                    );
                })
                : "";

        return (
            <div>
                {this.divOpciones()}

                <br></br>
                <div className="ms-Grid-row text-center" style={{ display: "flex", justifyContent: "center" }}>
                    <Pivot aria-label="Vistas de datos" onLinkClick={this.pivotClickedMantenedor} className="btnTipoTab">
                        <PivotItem
                            //itemIcon={'delete'}
                            headerText="Estados"
                            headerButtonProps={{
                                "data-order": 1,
                                "data-title": "Mantenedor",
                            }}>
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Áreas de negocios">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Líneas">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Monedas">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Rol simulador">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Faenas">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Motivos">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Oficinas">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Países">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Categoría rendición">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Grupo clientes">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Clientes">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Competidores">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Tipo calendario">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Tipo contrato consultor">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Principales elementos del servicio">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Tipo rendición">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Tipo tarea">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Tipo contratos">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Unidades negocio">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Contratos marco">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Staffing">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Cargos plan">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Feriados">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Unidad rendición">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Checklist Cierre">
                            <div></div>
                        </PivotItem>
                    </Pivot>
                </div>
                <br></br>
                {this.state.tipoMantenedor != "" ? (
                    <div>
                        <div>
                            <h4 className="titGrilla">
                                {this.state.tipoMantenedor === "Estado"
                                    ? "Estados"
                                    : this.state.tipoMantenedor === "Linea"
                                        ? "Áreas de negocios"
                                        : this.state.tipoMantenedor === "Sub-Linea"
                                            ? "Líneas"
                                            : this.state.tipoMantenedor === "Moneda"
                                                ? "Monedas"
                                                : this.state.tipoMantenedor === "Faena"
                                                    ? "Faenas"
                                                    : this.state.tipoMantenedor === "Paises"
                                                        ? "Países"
                                                        : this.state.tipoMantenedor === "Cliente"
                                                            ? "Clientes"
                                                            : this.state.tipoMantenedor === "Categoria rendicion"
                                                                ? "Categoría rendición"
                                                                : this.state.tipoMantenedor}
                            </h4>
                            <div className={"buttonDerecha"}>
                                {this.state.tipoMantenedor === "Estado" ||
                                    this.state.tipoMantenedor === "Moneda" ||
                                    this.state.tipoMantenedor === "Tipo calendario" ? (
                                    ""
                                ) : (
                                    <PrimaryButton
                                        text="Agregar"
                                        onClick={() => {
                                            this.setState({ panelMantendores: true });
                                        }}></PrimaryButton>
                                )}
                            </div>
                        </div>
                        <div>
                            <br></br>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 card mb-3">
                                <div>
                                    <table className="grilla table table-hover table-striped table-responsive-sm table-responsive-md table-responsive-lg">
                                        <thead>
                                            <th>ID</th>
                                            {this.state.tipoMantenedor === "Categoria rendicion" ? <th>CATEGORIA</th> : ""}
                                            {this.state.tipoMantenedor === "Staffing" ? <th>RUT</th> : ""}
                                            {this.state.tipoMantenedor === "Rol simulador" ? (
                                                <th>CONTRATO MARCO</th>
                                            ) : this.state.tipoMantenedor === "Cliente" ? (
                                                <th>GRUPO CLIENTE</th>
                                            ) : this.state.tipoMantenedor === "Contratos marco" || this.state.tipoMantenedor === "Cargos plan" ? (
                                                <th>UNIDAD NEGOCIO</th>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.tipoMantenedor === "Tipo contrato consultor" ? (
                                                <th>SIGLA CONTRATO</th>
                                            ) : this.state.tipoMantenedor === "Sub-Linea" ? (
                                                <th>LINEA</th>
                                            ) : this.state.tipoMantenedor === "Staffing" ? (
                                                <th>CORREO</th>
                                            ) : this.state.tipoMantenedor === "Feriados" ? (
                                                <th>DESCRIPCIÓN</th>
                                            ) : (
                                                <th>TÍTULO</th>
                                            )}
                                            {this.state.tipoMantenedor === "Tipo contrato consultor" ? (
                                                <th>TIPO CONTRATO</th>
                                            ) : this.state.tipoMantenedor === "Unidades negocio" ? (
                                                <th>MONEDA</th>
                                            ) : this.state.tipoMantenedor === "Contratos marco" ? (
                                                <th>MONEDA</th>
                                            ) : this.state.tipoMantenedor === "Staffing" ? (
                                                <th>NOMBRE</th>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.tipoMantenedor === "Moneda" ? (
                                                <th>VALOR CLP</th>
                                            ) : this.state.tipoMantenedor === "Rol simulador" ? (
                                                <th>TARIFA</th>
                                            ) : this.state.tipoMantenedor === "Staffing" ? (
                                                <th>CARGO</th>
                                            ) : this.state.tipoMantenedor === "Feriados" ? (
                                                <th>FECHA</th>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.tipoMantenedor === "Moneda" ? (
                                                <th>VALOR UF</th>
                                            ) : this.state.tipoMantenedor === "Rol simulador" || this.state.tipoMantenedor === "Cargos plan" ? (
                                                <th>COSTO</th>
                                            ) : this.state.tipoMantenedor === "Staffing" ? (
                                                <th>TIPO CONTRATO</th>
                                            ) : this.state.tipoMantenedor === "Unidades negocio" ? (
                                                <th>HORAS</th>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.tipoMantenedor != "Moneda" ? "" : <th>VALOR SOLES</th>}
                                            {this.state.tipoMantenedor === "Unidad rendición" ? <th>DESCRIPCIÓN</th> : ""}
                                            {this.state.tipoMantenedor === "Categoria rendicion" ? <th>CUENTA CONTABLE</th> : ""}
                                            {this.state.tipoMantenedor === "Categoria rendicion" ? <th>URL WORKSPACE</th> : ""}
                                            {this.state.tipoMantenedor === "Unidad rendición" ? <th>APROBADOR</th> : ""}
                                            {this.state.tipoMantenedor === "Unidad rendición" ? <th>BACK UP</th> : ""}
                                            {this.state.tipoMantenedor === "Unidad rendición" ? <th>SUPERVISOR</th> : ""}
                                            {this.state.tipoMantenedor != "Moneda" ? "" : <th>VALOR USD</th>}
                                            {this.state.tipoMantenedor != "Moneda" ? "" : <th>VALOR AUD</th>}
                                            {this.state.tipoMantenedor === "Sub-Linea" ? <th>SUB-LINEA</th> : ""}
                                            {this.state.tipoMantenedor === "Estado" ? (
                                                <th>MÓDULO</th>
                                            ) : this.state.tipoMantenedor === "Rol simulador" ? (
                                                <th>NIVEL</th>
                                            ) : this.state.tipoMantenedor === "Contratos marco" ? (
                                                <th>HORAS DÍA</th>
                                            ) : this.state.tipoMantenedor === "Staffing" ? (
                                                <th>UNIDAD NEGOCIO</th>
                                            ) : this.state.tipoMantenedor === "Cargos plan" ? (
                                                <th>NIVEL</th>
                                            ) : (
                                                ""
                                            )}
                                            {this.state.tipoMantenedor === "Staffing" ? <th>FECHA DESDE</th> : ""}
                                            {this.state.tipoMantenedor === "Staffing" ? <th>FECHA HASTA</th> : ""}
                                            {this.state.tipoMantenedor === "Unidad rendición" ? <th>ADMINISTRATIVO</th> : ""}
                                            {this.state.tipoMantenedor === "Estado" ? "" : <th>ACTIVO</th>}
                                            {this.state.tipoMantenedor === "Estado" ? "" : <th>ACCIONES</th>}
                                        </thead>
                                        <tbody>{itemsTable}</tbody>
                                    </table>
                                    <br></br>
                                    {this.state.items != null && this.state.items != undefined ? (
                                        <ul id="page-numbers" className={"pagination"}>
                                            {this.state.items.length > 0 ? this.paginacion() : ""}
                                        </ul>
                                    ) : (
                                        ""
                                    )}
                                    {/*this.state.items.length > 0  ? this.paginacion() : ""*/}
                                </div>
                            </div>
                        </div>
                        <br></br>
                    </div>
                ) : (
                    ""
                )}
                <br></br>
                {this.PanelMantenedores()}
                {this.dialogoError()}
                {this.dialogoDelete()}
                {this.dialogoInsert()}
                {this.dialogoUpdate()}
            </div>
        );
    }

    public PanelMantenedores() {
        return (
            <Panel isOpen={this.state.panelMantendores} type={PanelType.medium} hasCloseButton={false}>
                {this.state.tipoMantenedor === "Sub-Linea" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo/a {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <ComboBox
                                label="Linea"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.linea}
                                //value={this.state.op_info.NOMBRE}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeLinea}
                                options={this.state.listado_linea}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <TextField id={"descripcionMantenedor"} label="Descripción" value={this.state.mantenedor.DESCRIPCION} onChange={(e) => { this.textFieldOnChange(e) }}  ></TextField>
                  </div> */}
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={this.state.linea === 0 || this.state.mantenedor.TITULO!.length === 0 ? true : false}
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Rol simulador" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo/a {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <ComboBox
                                label="Contrato Marco"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.contrato_marco}
                                //value={this.state.contrato_marco}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeContratoMarco}
                                options={this.state.listado_contrato_marco}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tarifa"}
                                label="Tarifa"
                                value={this.state.tarifa}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"costo"}
                                label="Costo"
                                value={this.state.costo}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"nivel"}
                                label="Nivel"
                                value={this.state.nivel > 0 ? this.state.nivel.toString() : ""}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={
                                    this.state.contrato_marco === 0 || this.state.nivel === 0 || this.state.mantenedor.TITULO!.length === 0
                                        ? true
                                        : false
                                }
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Cliente" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo/a {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <ComboBox
                                label="Grupo clientes"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.grupo_clientes}
                                //value={this.state.contrato_marco}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeGrupoClientes}
                                options={this.state.listado_grupo_clientes}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={this.state.grupo_clientes === 0 || this.state.mantenedor.TITULO!.length === 0 ? true : false}
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Tipo contrato consultor" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo/a {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Sigla contrato"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"descripcionMantenedor"}
                                label="Tipo contrato"
                                value={this.state.mantenedor.DESCRIPCION}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={
                                    this.state.mantenedor.DESCRIPCION!.length === 0 || this.state.mantenedor.TITULO!.length === 0 ? true : false
                                }
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Unidades negocio" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo/a {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <ComboBox
                            label="Moneda"
                            placeholder="Seleccionar..."
                            //allowFreeform
                            disabled={false}
                            autoComplete="on"
                            selectedKey={this.state.grupo_clientes}
                            //value={this.state.contrato_marco}
                            //defaultValue={this.state.jefefaena}
                            onChange={this._onChangeGrupoClientes}
                            options={this.state.listado_monedas}></ComboBox>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"nivel"}
                                label="Hora día"
                                value={this.state.nivel > 0 ? this.state.nivel.toString() : ""}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={
                                    this.state.grupo_clientes === 0 || this.state.nivel === 0 || this.state.mantenedor.TITULO!.length === 0
                                        ? true
                                        : false
                                }
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Cargos plan" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo cargo</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <ComboBox
                                label="Unidad negocio"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.linea}
                                //value={this.state.contrato_marco}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeLinea}
                                options={this.state.listado_unidadNegocio}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"costo"}
                                label="Costo"
                                value={this.state.costo}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"nivel"}
                                label="Nivel"
                                value={this.state.nivel > 0 ? this.state.nivel.toString() : ""}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={
                                    this.state.linea === 0 || this.state.nivel === 0 || this.state.mantenedor.TITULO!.length === 0 ? true : false
                                }
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Feriados" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo feriado</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DatePicker
                                onChange={this.cambiarestadoformulario}
                                id="fechacierre"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                allowTextInput={true}
                                placeholder={this.state.fecha_desde != null ? this._onFormatDate(new Date(this.state.fecha_desde)) : "Fecha"}
                                //placeholder="Fecha"
                                label="Fecha"
                                //isRequired={true}
                                //value={this.state.fecha_desde}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                onSelectDate={this._onSelectDateDesde}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={this.state.fecha_desde === null || this.state.mantenedor.TITULO!.length === 0 ? true : false}
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Staffing" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <Label>Usuario</Label>
                            <AutoComplete
                                id="Admin"
                                callback={this.selectStaffing}
                                multi={false}
                                disabled={false}
                                defaultValue={{
                                    name: this.state.mantenedor.DESCRIPCION,
                                    mail: this.state.mantenedor.TITULO,
                                }}
                                tokenAPIGraph={this.state.tokenAPIGraph}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"rut"}
                                label="Rut"
                                value={this.state.rut}
                                errorMessage={this.state.rutValido === false ? "Formato de rut incorrecto" : ""}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="Cargo"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.grupo_clientes}
                                //value={this.state.contrato_marco}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeGrupoClientes}
                                options={this.state.listado_cargoPlan}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="Tipo contrato"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.contrato_marco}
                                //value={this.state.contrato_marco}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeContratoMarco}
                                options={this.state.listado_contratos}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="Unidad negocio"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.linea}
                                //value={this.state.contrato_marco}
                                //defaultValue={this.state.jefefaena}
                                onChange={this._onChangeLinea}
                                options={this.state.listado_unidadNegocio}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DatePicker
                                onChange={this.cambiarestadoformulario}
                                id="fechacierre"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                allowTextInput={true}
                                placeholder={this.state.fecha_desde != null ? this._onFormatDate(new Date(this.state.fecha_desde)) : "Fecha desde"}
                                //placeholder="Fecha desde"
                                label="Fecha desde"
                                //isRequired={true}
                                //value={this.state.fecha_desde}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                onSelectDate={this._onSelectDateDesde}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <DatePicker
                                onChange={this.cambiarestadoformulario}
                                id="fechacierre"
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                allowTextInput={true}
                                placeholder={this.state.fecha_hasta != null ? this._onFormatDate(new Date(this.state.fecha_hasta)) : "Fecha desde"}
                                //placeholder="Fecha hasta"
                                label="Fecha hasta"
                                //isRequired={true}
                                //value={this.state.fecha_hasta}
                                ariaLabel="Seleccione una fecha"
                                formatDate={this._onFormatDate}
                                onSelectDate={this._onSelectDateHasta}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={
                                    this.state.rutValido === false ||
                                        this.state.fecha_hasta === null ||
                                        this.state.grupo_clientes === 0 ||
                                        this.state.contrato_marco === 0 ||
                                        this.state.grupo_clientes === 0 ||
                                        this.state.linea === 0 ||
                                        this.state.mantenedor.DESCRIPCION!.length === 0 ||
                                        this.state.mantenedor.TITULO!.length === 0
                                        ? true
                                        : false
                                }
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Contratos marco" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo/a {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <ComboBox
                                label="Unidad negocio"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.grupo_clientes}
                                onChange={this._onChangeGrupoClientes}
                                options={this.state.listado_unidadNegocio}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="Moneda"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.contrato_marco}
                                onChange={this._onChangeContratoMarco}
                                options={this.state.listado_monedas}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"nivel"}
                                label="Hora día"
                                value={this.state.nivel > 0 ? this.state.nivel.toString() : ""}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={
                                    this.state.contrato_marco === 0 ||
                                        this.state.grupo_clientes === 0 ||
                                        this.state.nivel === 0 ||
                                        this.state.mantenedor.TITULO!.length === 0
                                        ? true
                                        : false
                                }
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Unidad rendición" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nueva {this.state.tipoMantenedor}</h5>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Título"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"descripcionMantenedor"}
                                label="Descripción"
                                value={this.state.mantenedor.DESCRIPCION}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <h6>Aprobador</h6>
                            <AutoComplete
                                id="Admin"
                                callback={this.selectUnRen1}
                                multi={false}
                                disabled={false}
                                defaultValue={
                                    {
                                        /* name: a.RUT,
                              mail: this.state.mantenedor.TITULO*/
                                    }
                                }
                                tokenAPIGraph={this.state.tokenAPIGraph}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <h6>Back Up</h6>
                            <AutoComplete
                                id="Admin"
                                callback={this.selectUnRen2}
                                multi={false}
                                disabled={false}
                                defaultValue={
                                    {
                                        /* name: a.RUT,
                              mail: this.state.mantenedor.TITULO*/
                                    }
                                }
                                tokenAPIGraph={this.state.tokenAPIGraph}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <h6>Supervisor</h6>
                            <AutoComplete
                                id="Admin"
                                callback={this.selectUnRen3}
                                multi={false}
                                disabled={false}
                                defaultValue={
                                    {
                                        /* name: a.RUT,
                              mail: this.state.mantenedor.TITULO*/
                                    }
                                }
                                tokenAPIGraph={this.state.tokenAPIGraph}
                            />
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="Administrativo"
                                placeholder="Seleccionar..."
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.contrato_marco}
                                onChange={this._onChangeContratoMarco}
                                options={this.state.opcionesSiNo}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={this.state.mantenedor.TITULO === "" || this.state.mantenedor.DESCRIPCION === "" ? true : false}
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                    this.setState({ panelMantendores: false });
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : this.state.tipoMantenedor === "Categoria rendicion" ? (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nueva {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <ComboBox
                                label="Unidad rendición"
                                placeholder="Seleccionar..."
                                //allowFreeform
                                disabled={false}
                                autoComplete="on"
                                selectedKey={this.state.grupo_clientes}
                                onChange={this._onChangeGrupoClientes}
                                options={this.state.listado_unidadRendicion}></ComboBox>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"nivel"}
                                label="Cuenta Contable"
                                value={this.state.nivel > 0 ? this.state.nivel.toString() : ""}
                                type={"number"}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        {this.state.grupo_clientes != 1 ? (
                            <>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <TextField
                                        id={"urlWorkspace"}
                                        label="Url Workspace"
                                        value={this.state.mantenedor.URL_WORKSPACE}
                                        type={"text"}
                                        onChange={(e) => {
                                            this.textFieldOnChange(e);
                                        }}></TextField>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <label>
                                        Para obtener el Url Workspace debe ingresar al sitio SGP en su ambiente de SharePoint (
                                        <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_SUBSITEURLEXAMPLE}>
                                            {process.env.REACT_APP_SUBSITEURLEXAMPLE}
                                        </a>
                                        ), ingresar al subsitio que requiera y copiar la url que aparece en la parte superior del navegador.
                                        <br />
                                        Ejemplo: {`${process.env.REACT_APP_SUBSITEURLEXAMPLE}/adm`}
                                    </label>
                                </div>
                            </>
                        ) : (
                            ""
                        )}

                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={
                                    this.state.grupo_clientes === 0 || this.state.nivel === 0 || this.state.mantenedor.TITULO!.length === 0
                                        ? true
                                        : false
                                }
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                }}></PrimaryButton>
                        </div>
                    </div>
                ) : (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                            <h5>Agregar nuevo/a {this.state.tipoMantenedor}</h5>
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <TextField
                                id={"tituloMantenedor"}
                                label="Titulo"
                                value={this.state.mantenedor.TITULO}
                                onChange={(e) => {
                                    this.textFieldOnChange(e);
                                }}></TextField>
                        </div>
                        {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <TextField id={"descripcionMantenedor"} label="Descripción" value={this.state.mantenedor.DESCRIPCION} onChange={(e) => { this.textFieldOnChange(e) }}  ></TextField>
                  </div> */}
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                            <DefaultButton
                                text="Cerrar"
                                onClick={() => {
                                    this.setState({ panelMantendores: false });
                                }}></DefaultButton>
                            <PrimaryButton
                                disabled={this.state.mantenedor.TITULO!.length === 0 ? true : false}
                                text="Agregar"
                                onClick={() => {
                                    this.insertMantenedor();
                                    this.setState({ panelMantendores: false });
                                }}></PrimaryButton>
                        </div>
                    </div>
                )}
            </Panel>
        );
    }
    public PanelRoles() {
        return (
            <Panel isOpen={this.state.panelroles} type={PanelType.medium} hasCloseButton={false}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 panelmargen">
                        <h5>Agregar nuevo/a {this.state.tipoUser}</h5>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                        <Label>Usuario</Label>

                        <AutoComplete
                            id="Admin"
                            callback={this.userHandler}
                            multi={false}
                            disabled={false}
                            defaultValue={{
                                name: this.state.Users.TEXT,
                                mail: this.state.Users.LOGINNAME,
                            }}
                            tokenAPIGraph={this.state.tokenAPIGraph}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ panelroles: false });
                            }}></DefaultButton>
                        <PrimaryButton
                            onClick={() => {
                                this.insertUsers();
                                this.setState({ panelroles: false });
                            }}
                            text={"Agregar"}></PrimaryButton>
                    </div>
                </div>
            </Panel>
        );
    }
    public userHandler(tipo: string, value: any, display: string) {
        ////console.log(tipo, value, display);

        let admin = this.state.Users;

        if (tipo === "Admin") {
            admin.TEXT = value[0].name;
            admin.LOGINNAME = value[0].mail;
        } else if (tipo === "Colab") {
            admin.TEXT = value[0].name;
            admin.LOGINNAME = value[0].mail;
        }

        this.setState({ Users: admin });
    }

    public selectStaffing(tipo: string, value: any, display: string) {
        let mant = this.state.mantenedor;

        mant.DESCRIPCION = value[0].name;
        mant.TITULO = value[0].mail;

        this.setState({ mantenedor: mant });
    }

    public selectUnRen1(tipo: string, value: any, display: string) {
        if (value[0] === undefined || value[0] === null) {
            this.setState({ aprobDisplay1: "", aprobMail1: "" });
        } else {
            this.setState({ aprobDisplay1: value[0].name, aprobMail1: value[0].mail });
        }
    }

    public selectUnRen2(tipo: string, value: any, display: string) {
        if (value[0] === undefined || value[0] === null) {
            this.setState({ aprobDisplay2: "", aprobMail2: "" });
        } else {
            this.setState({ aprobDisplay2: value[0].name, aprobMail2: value[0].mail });
        }
    }

    public selectUnRen3(tipo: string, value: any, display: string) {
        if (value[0] === undefined || value[0] === null) {
            this.setState({ aprobDisplay3: "", aprobMail3: "" });
        } else {
            this.setState({ aprobDisplay3: value[0].name, aprobMail3: value[0].mail });
        }
    }

    public divRoles() {
        let contador = 0;
        ////console.log(this.state.items)
        let itemsTable =
            this.state.items != null
                ? this.state.items.map((a) => {
                    let espar = contador % 2 ? true : false;
                    contador++;
                    return (
                        <tr>
                            <td>{a.ID}</td>
                            <td>{a.DISPLAYNAME}</td>
                            <td>{a.MAIL}</td>
                            {this.state.edit != false && this.state.id_edit == a.ID ? (
                                <td>
                                    <select
                                        name="tableActivo"
                                        onChange={(e) => {
                                            this.handletableUser(e);
                                        }}>
                                        <option value={a.ACTIVO == true ? "true" : "false"} selected>
                                            {a.ACTIVO == true ? "Activo" : "Inactivo"}
                                        </option>
                                        <option value={a.ACTIVO == true ? "false" : "true"}>{a.ACTIVO == true ? "Inactivo" : "Activo"}</option>
                                    </select>
                                </td>
                            ) : (
                                <td>{a.ACTIVO == true ? "Activo" : "Inactivo"}</td>
                            )}
                            {this.state.edit != false && this.state.id_edit == a.ID ? (
                                <td>
                                    <IconButton
                                        iconProps={editarMantenedor}
                                        title="Editar"
                                        ariaLabel="Edit"
                                        onClick={() => {
                                            this.updateUsers(a.ID);
                                        }}
                                    />
                                </td>
                            ) : this.state.edit != false && this.state.id_edit != a.ID ? (
                                <td></td>
                            ) : (
                                <td>
                                    <IconButton
                                        iconProps={editarMantenedor}
                                        title="Editar"
                                        ariaLabel="Edit"
                                        onClick={() => {
                                            this.setState({ edit: true, id_edit: a.ID });
                                        }}
                                    />
                                </td>
                            )}
                        </tr>
                    );
                })
                : "";
        return (
            <div>
                {this.divOpciones()}

                <br></br>
                <div className="ms-Grid-row" style={{ display: "flex", justifyContent: "center" }}>
                    <Pivot aria-label="Vistas de datos" onLinkClick={this.pivotClickedRoles} className="btnTipoTab">
                        <PivotItem headerText="Administradores">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Gestión">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Usuarios">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Validadores">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Contables">
                            <div></div>
                        </PivotItem>
                    </Pivot>
                </div>
                <br></br>
                {this.state.tipoUser != "" ? (
                    <div>
                        <div>
                            <h4 className="titGrilla">{this.state.tipoUser}</h4>
                            <div className={"buttonDerecha"}>
                                <PrimaryButton
                                    text="Agregar"
                                    onClick={() => {
                                        this.setState({ panelroles: true });
                                    }}></PrimaryButton>
                            </div>
                        </div>

                        <div className="ms-Grid" dir="ltr">
                            <br></br>
                            <div className="ms-Grid-row">
                                <br></br>
                                <br></br>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 card mb-3">
                                    <div>
                                        <table className="grilla table table-hover table-striped table-responsive-sm table-responsive-md table-responsive-lg">
                                            <thead>
                                                <th>ID</th>
                                                <th>NOMBRE USUARIO</th>
                                                <th>CORREO USUARIO</th>
                                                <th>ACTIVO</th>
                                                <th>ACCIONES</th>
                                            </thead>
                                            <tbody>{itemsTable}</tbody>
                                        </table>
                                        <br></br>
                                        {this.state.items != null ? (
                                            <ul id="page-numbers" className={"pagination"}>
                                                {this.state.items.length > 0 ? this.paginacion() : ""}
                                            </ul>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.PanelRoles()}
                        {this.dialogoError()}
                        {this.dialogoDelete()}
                        {this.dialogoInsert()}
                        {this.dialogoUpdate()}
                    </div>
                ) : (
                    ""
                )}
            </div>
        );
    }

    public divImportarRen() {
        return (
            <div>
                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <h6 className="tituloLaudus">Importar Rendiciones</h6>
                        <div className="panel">
                            <div className="moduloPasos">
                                <div className="contenedorPasos">
                                    <div className="numero">1</div>
                                    <div className="modulo">
                                        <p>
                                            En este paso (opcional) se puede usar la plantilla Excel que se debe subir para la inserción de los datos.
                                        </p>
                                        <DefaultButton onClick={this.laudusPlantilla} title="Obtenga la plantilla Excel para importar datos">
                                            Obtener plantilla Excel
                                        </DefaultButton>
                                        <p className="txtSmall">Luego de realizar este paso opcional continue con el número 2</p>
                                    </div>
                                </div>
                                <div className="contenedorPasos">
                                    <div className="numero">2</div>
                                    <div className="modulo">
                                        <p>En este paso debe seleccionar el archivo Excel con la información solicitada del paso anterior.</p>
                                        <input
                                            type="file"
                                            onChange={this.validarExtension}
                                            id="file"
                                            name="file"
                                            placeholder="Archivo de Excel"></input>
                                        <p className="txtSmall">Luego de realizar este paso continue con el número 3</p>
                                    </div>
                                </div>
                                <div className="contenedorPasos">
                                    <div className="numero">3</div>
                                    <div className="modulo">
                                        <p>En este último paso debe confirmar la importación de datos una vez visualizado y revisado el listado.</p>
                                        <DefaultButton
                                            onClick={this.importarLaudusConfirmar}
                                            title="Confirme la importación."
                                            disabled={this.state.isDisabledConfirmar}>
                                            Confirmar
                                        </DefaultButton>
                                        <p className="txtSmall">
                                            Luego de confirmar, se subirá la información que se muestra en el listado a la base de datos del sistema
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoaded === true ? (
                                <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                    <MessageBar
                                        messageBarType={
                                            this.state.cantidad_aceptada < this.state.cantidad_total ? MessageBarType.warning : MessageBarType.success
                                        }
                                        isMultiline={false}
                                        dismissButtonAriaLabel="Close">
                                        {this.state.cantidad_aceptada < this.state.cantidad_total
                                            ? `Cantidad original de datos: ${this.state.cantidad_total}. Solo se subirán ${this.state.cantidad_aceptada} datos. Favor revisar`
                                            : `Se han cargado todos los datos importados (${this.state.cantidad_total})`}
                                    </MessageBar>
                                </div>
                            ) : (
                                ""
                            )}
                            {this.state.isLoaded === true ? (
                                <DetailsList
                                    items={this.state.items_laudus}
                                    compact={true}
                                    columns={columns}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    checkButtonAriaLabel="select row"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public divImportarIng() {
        return (
            <div>
                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <h6 className="tituloLaudus">Importar Ingresos</h6>
                        <div className="panel">
                            <div className="moduloPasos">
                                <div className="contenedorPasos">
                                    <div className="numero">1</div>
                                    <div className="modulo">
                                        <p>
                                            En este paso (opcional) se puede usar la plantilla Excel que se debe subir para la inserción de los datos.
                                        </p>
                                        <DefaultButton onClick={this.laudusPlantillaIngreso} title="Obtenga la plantilla Excel para importar datos">
                                            Obtener plantilla Excel
                                        </DefaultButton>
                                        <p className="txtSmall">Luego de realizar este paso opcional continue con el número 2</p>
                                    </div>
                                </div>
                                <div className="contenedorPasos">
                                    <div className="numero">2</div>
                                    <div className="modulo">
                                        <p>En este paso debe seleccionar el archivo Excel con la información solicitada del paso anterior.</p>
                                        <input
                                            type="file"
                                            onChange={this.validarExtensionIngreso}
                                            id="fileIngreso"
                                            name="fileIngreso"
                                            placeholder="Archivo de Excel"></input>
                                        <p className="txtSmall">Luego de realizar este paso continue con el número 3</p>
                                    </div>
                                </div>
                                <div className="contenedorPasos">
                                    <div className="numero">3</div>
                                    <div className="modulo">
                                        <p>En este último paso debe confirmar la importación de datos una vez visualizado y revisado el listado.</p>
                                        <DefaultButton
                                            onClick={this.importarLaudusConfirmarIngresos}
                                            title="Confirme la importación."
                                            disabled={this.state.isDisabledConfirmar_gastos}>
                                            Confirmar
                                        </DefaultButton>
                                        <p className="txtSmall">
                                            Luego de confirmar, se subirá la información que se muestra en el listado a la base de datos del sistema
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {this.state.isLoaded_gastos === true ? (
                                <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                    <MessageBar
                                        messageBarType={
                                            this.state.cantidad_aceptada_gastos < this.state.cantidad_total_gastos
                                                ? MessageBarType.warning
                                                : MessageBarType.success
                                        }
                                        isMultiline={false}
                                        dismissButtonAriaLabel="Close">
                                        {this.state.cantidad_aceptada_gastos < this.state.cantidad_total_gastos
                                            ? `Cantidad original de datos: ${this.state.cantidad_total_gastos}. Solo se subirán ${this.state.cantidad_aceptada_gastos} datos. Favor revisar`
                                            : `Se han cargado todos los datos importados (${this.state.cantidad_total_gastos})`}
                                    </MessageBar>
                                </div>
                            ) : (
                                ""
                            )}
                            {this.state.isLoaded_gastos === true ? (
                                <DetailsList
                                    items={this.state.items_laudus_gastos}
                                    compact={true}
                                    columns={columnsIngresos}
                                    selectionMode={SelectionMode.none}
                                    /* getKey={this._getKey} */
                                    /* setKey="multiple" */
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    /* selection={(e) => this.itemSeleccionado(e)} */
                                    /* selectionPreservedOnEmptyClick={true} */
                                    /* onItemInvoked={this._onItemInvoked} */
                                    /* enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items" */
                                    checkButtonAriaLabel="select row"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public divExportarRen() {
        return (
            <div>
                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                    <h6 className="tituloLaudus">Exportar Rendiciones</h6>
                    <div className="panel">
                        <div className="moduloPasos">
                            <div className="contenedorPasos"></div>
                            <div className="contenedorPasos">
                                <div className="numero">1</div>
                                <div className="modulo">
                                    <p>Puede obtener un listado de todas las Rendiciones en el siguiente botón.</p>
                                    <DefaultButton onClick={this.laudus} title="Obtenga Excel con datos del sistema">
                                        Obtener Excel
                                    </DefaultButton>
                                    <p className="txtSmall">Se descargará un archivo Excel con los datos solicitados</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public divLaudus2() {
        return (
            <div>
                {this.divOpciones()}

                <br></br>
                <div className="ms-Grid-row" style={{ display: "flex", justifyContent: "center" }}>
                    <Pivot aria-label="Vistas de datos" onLinkClick={this.pivotClickedLaudus} className="btnTipoTab">
                        <PivotItem headerText="Importar Rendiciones">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Importar Ingresos">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Exportar Rendiciones">
                            <div></div>
                        </PivotItem>
                    </Pivot>
                </div>
                <br></br>
                {this.state.tipoUser === "ImportarRen"
                    ? this.divImportarRen()
                    : this.state.tipoUser === "ExportarRen"
                        ? this.divExportarRen()
                        : this.state.tipoUser === "ImportarIng"
                            ? this.divImportarIng()
                            : this.divImportarRen()}

                {this.dialogoErrorLaudus()}
                {this.dialogoCargaExitosa()}
            </div>
        );
    }

    public divLaudus() {
        return <div>{this.divOpciones()}</div>;
    }

    public itemSeleccionado(e: any) {
        //console.log(e);
    }

    private validarExtension(event: any) {
        let fileInput = document.getElementById("file") as HTMLInputElement;

        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
            return;
        }

        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        if (ext !== "xlsx") {
            this.setState({
                dialogo_error_laudus: false,
                subtext_error: "El archivo cargado debe ser un Excel (extensión .xlsx)",
                isDisabledCargar: true,
                items_laudus: undefined,
                isLoaded: false,
                isDisabledConfirmar: true,
            });
            fileInput.value = "";
        } else {
            this.setState({ isDisabledCargar: false, file_excel: event.target.files[0] }, () => {
                this.importarLaudus();
            });
        }
    }

    private validarExtensionIngreso(event: any) {
        let fileInput = document.getElementById("fileIngreso") as HTMLInputElement;

        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
            return;
        }

        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        if (ext !== "xlsx") {
            this.setState({
                dialogo_error_laudus: false,
                subtext_error: "El archivo cargado debe ser un Excel (extensión .xlsx)",
                isDisabledCargar_gastos: true,
                items_laudus_gastos: undefined,
                isLoaded_gastos: false,
                isDisabledConfirmar_gastos: true,
            });
            fileInput.value = "";
        } else {
            this.setState({ isDisabledCargar_gastos: false, file_excel_gastos: event.target.files[0] }, () => { this.importarLaudusIngresos(); });

        }
    }

    private validarExtensionCargaMasiva(event: any) {
        let fileInput = document.getElementById("fileCarga") as HTMLInputElement;

        if (!event || !event.target || !event.target.files || event.target.files.length === 0) {
            return;
        }

        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf(".");

        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1);

        if (ext !== "xlsx") {
            this.setState({
                dialogo_error_laudus: false,
                subtext_error: "El archivo cargado debe ser un Excel (extensión .xlsx)",
                isDisabledCargar_cargaHora: true,
                items_cargaHora: undefined,
                isLoaded_cargaHora: false,
                isDisabledConfirmar_cargaHora: true,
            });
            fileInput.value = "";
        } else {
            this.setState({ isDisabledCargar_cargaHora: false, file_excel_cargaHora: event.target.files[0] }, () => { this.importarCargaHorasMasiva(); });

        }
    }

    // INICIO DIV PARA CARGA MASIVA DE HORAS
    public divBUK3() {
        return (
            <div>
                {/* LISTADO DE PIVOT MENU GENERAL */}
                {this.divOpciones()}

                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <h6 className="tituloLaudus">Carga masiva de Horas</h6>
                        <div className="panel">
                            <div className="moduloPasos">
                                <div className="contenedorPasos">
                                    <div className="numero">1</div>
                                    <div className="modulo">
                                        <p>
                                            En este paso (opcional) se puede usar la plantilla Excel que se debe subir para la inserción de los datos.
                                        </p>
                                        <DefaultButton onClick={this.cargaHoraPlantilla} title="Obtenga la plantilla Excel para importar datos">
                                            Obtener plantilla Excel
                                        </DefaultButton>
                                        <p className="txtSmall">Luego de realizar este paso opcional continue con el número 2</p>
                                    </div>
                                </div>
                                <div className="contenedorPasos">
                                    <div className="numero">2</div>
                                    <div className="modulo">
                                        <p>En este paso debe seleccionar el archivo Excel con la información solicitada del paso anterior.</p>
                                        <input
                                            type="file"
                                            onChange={this.validarExtensionCargaMasiva}
                                            id="fileCarga"
                                            name="fileCarga"
                                            placeholder="Archivo de Excel"></input>
                                        <p className="txtSmall">Luego de realizar este paso continue con el número 3</p>
                                    </div>
                                </div>
                                <div className="contenedorPasos">
                                    <div className="numero">3</div>
                                    <div className="modulo">
                                        <p>En este último paso debe confirmar la importación de datos una vez visualizado y revisado el listado.</p>
                                        <DefaultButton
                                            onClick={this.importarCargaHorasConfirmar}
                                            title="Confirme la importación."
                                            disabled={this.state.isDisabledConfirmar_cargaHora}>
                                            Confirmar
                                        </DefaultButton>
                                        <p className="txtSmall">
                                            Luego de confirmar, se subirá la información que se muestra en el listado a la base de datos del sistema
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {this.state.isLoaded_cargaHora === true ? (
                                <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                    <MessageBar
                                        messageBarType={
                                            this.state.cantidad_aceptada_cargaHora < this.state.cantidad_total_cargaHora
                                                ? MessageBarType.warning
                                                : MessageBarType.success
                                        }
                                        isMultiline={false}
                                        dismissButtonAriaLabel="Close">
                                        {this.state.cantidad_aceptada_cargaHora < this.state.cantidad_total_cargaHora
                                            ? `Cantidad original de datos: ${this.state.cantidad_total_cargaHora}. Solo se subirán ${this.state.cantidad_aceptada_cargaHora} datos. Favor revisar`
                                            : `Se han cargado todos los datos importados (${this.state.cantidad_total_cargaHora})`}
                                    </MessageBar>
                                </div>
                            ) : (
                                ""
                            )}
                            {this.state.isLoaded_cargaHora === true ? (
                                <DetailsList
                                    items={this.state.items_cargaHora}
                                    compact={true}
                                    columns={columnsCargaHoras}
                                    selectionMode={SelectionMode.none}
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    checkButtonAriaLabel="select row"
                                />
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>

                {/*PANELES */}
                {this.dialogoErrorLaudus()}
                {this.dialogoCargado()}
                {this.dialogoNoCargado()}
            </div>
        );
    }
    // FIN DIV PARA CARGA MASIVA DE HORAS

    public divBUK2() {
        return (
            <div>
                {this.divOpciones()}

                <br></br>
                <div className="ms-Grid-row" style={{ display: "flex", justifyContent: "center" }}>
                    <Pivot aria-label="Vistas de datos" onLinkClick={this.pivotClickedBUK} className="btnTipoTab">
                        <PivotItem headerText="Importar Empleados">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Importar Vacaciones">
                            <div></div>
                        </PivotItem>
                        <PivotItem headerText="Importar Licencias">
                            <div></div>
                        </PivotItem>
                    </Pivot>
                </div>
                <br></br>
                {this.state.tipoUser === "ImportarEmp"
                    ? this.divImportarEmp()
                    : this.state.tipoUser === "ImportarVac"
                        ? this.divExportarVac()
                        : this.state.tipoUser === "ImportarLic"
                            ? this.divImportarLic()
                            : this.divImportarEmp()}

                {this.dialogoCargaExitosa()}
            </div>
        );
    }

    public divImportarEmp() {
        return (
            <div>
                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <h6 className="tituloLaudus">Importar Empleados BUK</h6>
                        <div className="panel">
                            <div className="moduloPasos">
                                <div>
                                    <div className="contenedorPasos">
                                        <div className="numero">1</div>
                                        <div className="modulo">
                                            <p>
                                                Obtiene a los empleados de BUK. Posteriormente mostará en un listado a los empleados que se podrán
                                                insertar (se modifican los que ya existen).
                                            </p>
                                            <DefaultButton onClick={this.conexionBUK} title="Obtenga a través de BUK la información">
                                                Obtener información
                                            </DefaultButton>
                                            <p className="txtSmall">Una vez realizado, puede seguir con el paso 2</p>
                                        </div>
                                    </div>
                                    <div className="contenedorPasos">
                                        <div className="numero">2</div>
                                        <div className="modulo">
                                            <p>Guardar datos en el sistema SGP.</p>
                                            <DefaultButton
                                                onClick={this.guardarEmpleados}
                                                disabled={this.state.isDisabledEmpleados}
                                                title="Guardar información">
                                                Guardar
                                            </DefaultButton>
                                            <p className="txtSmall">Este paso final insertará los datos mostrados en el sistema SGP</p>
                                        </div>
                                    </div>
                                </div>

                                {this.state.isLoaded_empleados === true ? (
                                    <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                        <MessageBar
                                            messageBarType={
                                                this.state.cantidad_aceptada_empleados < this.state.cantidad_total_empleados
                                                    ? MessageBarType.warning
                                                    : MessageBarType.success
                                            }
                                            isMultiline={false}
                                            dismissButtonAriaLabel="Close">
                                            {this.state.cantidad_aceptada_empleados < this.state.cantidad_total_empleados
                                                ? `Cantidad original en BUK: ${this.state.cantidad_total_empleados}. Solo se subirán ${this.state.cantidad_aceptada_empleados} datos.`
                                                : `Se han cargado todos los datos importados (${this.state.cantidad_total_empleados})`}
                                        </MessageBar>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {this.state.isLoaded_empleados === true ? (
                                    <div className="listadoBUK">
                                        <DetailsList
                                            items={this.state.items_empleados}
                                            compact={true}
                                            columns={columnsEmpleados}
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            checkButtonAriaLabel="select row"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {this.state.isLoading_empleados === true ? (
                                <div>
                                    {" "}
                                    <img src={BUKloader} className="loaderBUK" />{" "}
                                    {/* <h6 className="loaderBUK">La operación tomará unos segundos..</h6> */}{" "}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public divExportarVac() {
        return (
            <div>
                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <h6 className="tituloLaudus">Importar Vacaciones BUK</h6>
                        <div className="panel">
                            <div className="moduloPasos">
                                <div>
                                    <div className="contenedorPasos">
                                        <div className="numero">1</div>
                                        <div className="modulo">
                                            <p>
                                                Obtiene las vacaciones del año actual. Posteriormente mostará en un listado las vacaciones que se
                                                podrán insertar (se modifican los que ya existen).
                                            </p>
                                            <DefaultButton onClick={this.conexionBUKVacaciones} title="Obtenga a través de BUK la información">
                                                Obtener información
                                            </DefaultButton>
                                            <p className="txtSmall">Una vez realizado, puede seguir con el paso 2</p>
                                        </div>
                                    </div>
                                    <div className="contenedorPasos">
                                        <div className="numero">2</div>
                                        <div className="modulo">
                                            <p>Guardar datos en el sistema SGP.</p>
                                            <DefaultButton
                                                onClick={this.guardarVacaciones}
                                                disabled={this.state.isDisabledVacaciones}
                                                title="Guardar información">
                                                Guardar
                                            </DefaultButton>
                                            <p className="txtSmall">Este paso final insertará los datos mostrados en el sistema SGP</p>
                                        </div>
                                    </div>
                                </div>

                                {this.state.isLoaded_vacaciones === true ? (
                                    <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                        <MessageBar
                                            messageBarType={
                                                this.state.cantidad_aceptada_vacaciones < this.state.cantidad_total_vacaciones
                                                    ? MessageBarType.warning
                                                    : MessageBarType.success
                                            }
                                            isMultiline={false}
                                            dismissButtonAriaLabel="Close">
                                            {this.state.cantidad_aceptada_vacaciones < this.state.cantidad_total_vacaciones
                                                ? `Cantidad original en BUK: ${this.state.cantidad_total_vacaciones}. Solo se subirán ${this.state.cantidad_aceptada_vacaciones} datos.`
                                                : `Se han cargado todos los datos importados (${this.state.cantidad_total_vacaciones})`}
                                        </MessageBar>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {this.state.isLoaded_vacaciones === true ? (
                                    <div className="listadoBUK">
                                        <DetailsList
                                            items={this.state.items_vacaciones}
                                            compact={true}
                                            columns={columnsVacaciones}
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            checkButtonAriaLabel="select row"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {this.state.isLoading_vacaciones === true ? (
                                <div>
                                    {" "}
                                    <img src={BUKloader} className="loaderBUK" />{" "}
                                    {/* <h6 className="loaderBUK">La operación tomará unos segundos..</h6> */}{" "}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public divImportarLic() {
        return (
            <div>
                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <h6 className="tituloLaudus">Importar Licencias BUK</h6>
                        <div className="panel">
                            <div className="moduloPasos">
                                <div>
                                    <div className="contenedorPasos">
                                        <div className="numero">1</div>
                                        <div className="modulo">
                                            <p>
                                                Obtiene las licencias del año actual. Posteriormente mostará en un listado las licencias que se podrán
                                                insertar (se modifican los que ya existen).
                                            </p>
                                            <DefaultButton onClick={this.conexionBUKLicencias} title="Obtenga a través de BUK la información">
                                                Obtener información
                                            </DefaultButton>
                                            <p className="txtSmall">Una vez realizado, puede seguir con el paso 2</p>
                                        </div>
                                    </div>
                                    <div className="contenedorPasos">
                                        <div className="numero">2</div>
                                        <div className="modulo">
                                            <p>Guardar datos en el sistema SGP.</p>
                                            <DefaultButton
                                                onClick={this.guardarLicencias}
                                                disabled={this.state.isDisabledLicencias}
                                                title="Guardar información">
                                                Guardar
                                            </DefaultButton>
                                            <p className="txtSmall">Este paso final insertará los datos mostrados en el sistema SGP</p>
                                        </div>
                                    </div>
                                </div>

                                {this.state.isLoaded_licencias === true ? (
                                    <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                        <MessageBar
                                            messageBarType={
                                                this.state.cantidad_aceptada_licencias < this.state.cantidad_total_licencias
                                                    ? MessageBarType.warning
                                                    : MessageBarType.success
                                            }
                                            isMultiline={false}
                                            dismissButtonAriaLabel="Close">
                                            {this.state.cantidad_aceptada_licencias < this.state.cantidad_total_licencias
                                                ? `Cantidad original en BUK: ${this.state.cantidad_total_licencias}. Solo se subirán ${this.state.cantidad_aceptada_licencias} datos.`
                                                : `Se han cargado todos los datos importados (${this.state.cantidad_total_licencias})`}
                                        </MessageBar>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {this.state.isLoaded_licencias === true ? (
                                    <div className="listadoBUK">
                                        <DetailsList
                                            items={this.state.items_licencias}
                                            compact={true}
                                            columns={columnsLicencias}
                                            selectionMode={SelectionMode.none}
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            checkButtonAriaLabel="select row"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {this.state.isLoading_licencias === true ? (
                                <div>
                                    {" "}
                                    <img src={BUKloader} className="loaderBUK" />{" "}
                                    {/* <h6 className="loaderBUK">La operación tomará unos segundos..</h6> */}{" "}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    public divOpciones() {
        return (
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                    <div className="iconoRuta">
                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                        &nbsp;
                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Administración</span>
                    </div>
                    <div className="titulosimulador">
                        <Glyphicon glyph="cog" className="text-warning" /> Administración
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-3">
                    <div>
                        {this.state.tipoAdmin === "Mantenedor" ? (
                            <PrimaryButton
                                iconProps={MantenedorIcon}
                                text="Mantenedor"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Mantenedor", tipoMantenedor: "Estado" });
                                    this.obtenerMantenedor("Estado");
                                }}
                                className={"mr-2"}
                            />
                        ) : (
                            <DefaultButton
                                iconProps={MantenedorIcon}
                                text="Mantenedor"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Mantenedor", tipoMantenedor: "Estado" });
                                    this.obtenerMantenedor("Estado");
                                }}
                                className={"mr-2"}
                            />
                        )}
                        {this.state.tipoAdmin === "Roles" ? (
                            <PrimaryButton
                                iconProps={RolIcon}
                                text="Roles"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Roles", tipoUser: "Administradores" });
                                    this.obtenerUser("Administradores", 1);
                                }}
                                className={"mr-2"}
                            />
                        ) : (
                            <DefaultButton
                                iconProps={RolIcon}
                                text="Roles"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Roles", tipoUser: "Administradores" });
                                    this.obtenerUser("Administradores", 1);
                                }}
                                className={"mr-2"}
                            />
                        )}
                        {this.state.tipoAdmin === "Laudus" ? (
                            <PrimaryButton
                                iconProps={ParamIcon}
                                text="Laudus"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Laudus" });
                                }}
                                className={"mr-2"}
                            />
                        ) : (
                            <DefaultButton
                                iconProps={ParamIcon}
                                text="Laudus"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Laudus" });
                                }}
                                className={"mr-2"}
                            />
                        )}
                        {this.state.tipoAdmin === "BUK" ? (
                            <PrimaryButton
                                iconProps={ParamIcon}
                                text="BUK"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "BUK" });
                                }}
                                className={"mr-2"}
                            />
                        ) : (
                            <DefaultButton
                                iconProps={ParamIcon}
                                text="BUK"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "BUK" });
                                }}
                                className={"mr-2"}
                            />
                        )}
                        {this.state.tipoAdmin === "Carga masiva de horas" ? (
                            <PrimaryButton
                                iconProps={ParamIcon}
                                text="Carga masiva de horas"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Carga masiva de horas" });
                                }}
                                className={"mr-2"}
                            />
                        ) : (
                            <DefaultButton
                                iconProps={ParamIcon}
                                text="Carga masiva de Horas"
                                onClick={() => {
                                    this.setState({ tipoAdmin: "Carga masiva de horas" });
                                }}
                                className={"mr-2"}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    public pivotClicked = (item: any) => {
        ////console.log(item.key);
        this.setState({ edit: false });

        if (item.key === ".0") {
            this.setState({ tipoAdmin: "Mantenedor", tipoUser: "", items: [], tipoMantenedor: "Estado", currentPage: 1 });
            this.obtenerMantenedor("Estado");
            this.setState({ tipoMantenedor: "Estado" });
        } else if (item.key === ".1") {
            this.setState({ tipoAdmin: "Roles", tipoUser: "Administradores", items: [], tipoMantenedor: "", currentPage: 1 });
            this.obtenerUser("Administradores", 1);
        } else if (item.key === ".2") {
            this.setState({ tipoAdmin: "Laudus", tipoUser: "", items: [], tipoMantenedor: "", currentPage: 1 });
            this.obtenerLaudus();
        } else if (item.key === ".3") {
            this.setState({ tipoAdmin: "BUK", tipoUser: "", items: [], tipoMantenedor: "", currentPage: 1 });
            this.obtenerBUK();
        } else if (item.key === ".3") {
            this.setState({ tipoAdmin: "Carga masiva de horas", tipoUser: "", items: [], tipoMantenedor: "", currentPage: 1 });
            this.cargaMasiva();
        }

        this.limpiar();
        this.limpiarUser();
    };

    public pivotClickedRoles = (item: any) => {
        ////console.log(item.key);
        this.setState({ edit: false });
        this.cambiarestadoformulario();

        if (item.key === ".0") {
            this.obtenerUser("Administradores", 1);
            this.setState({ tipoUser: "Administradores" });
        } else if (item.key === ".1") {
            this.obtenerUser("Gestión", 1);
            this.setState({ tipoUser: "Gestión" });
        } else if (item.key === ".2") {
            this.obtenerUser("Usuarios", 1);
            this.setState({ tipoUser: "Usuarios" });
        } else if (item.key === ".3") {
            this.obtenerUser("Validadores", 1);
            this.setState({ tipoUser: "Validadores" });
        } else if (item.key === ".4") {
            this.obtenerUser("Contables", 1);
            this.setState({ tipoUser: "Contables" });
        }
        this.limpiar();
        this.limpiarUser();
        this.limpiarPaginacion();
    };

    public pivotClickedLaudus = (item: any) => {
        ////console.log(item.key);
        this.setState({ edit: false });
        this.cambiarestadoformulario();

        if (item.key === ".0") {
            this.obtenerUser("ImportarRen", 1);
            this.setState({ tipoUser: "ImportarRen" });
        } else if (item.key === ".1") {
            this.obtenerUser("ImportarIng", 1);
            this.setState({ tipoUser: "ImportarIng" });
        } else if (item.key === ".2") {
            this.obtenerUser("ExportarRen", 1);
            this.setState({ tipoUser: "ExportarRen" });
        }
        this.limpiar();
        this.limpiarUser();
        this.limpiarPaginacion();
    };

    public pivotClickedBUK = (item: any) => {
        ////console.log(item.key);
        this.setState({ edit: false });
        this.cambiarestadoformulario();

        if (item.key === ".0") {
            this.obtenerUser("ImportarEmp", 1);
            this.setState({ tipoUser: "ImportarEmp" });
        } else if (item.key === ".1") {
            this.obtenerUser("ImportarVac", 1);
            this.setState({ tipoUser: "ImportarVac" });
        } else if (item.key === ".2") {
            this.obtenerUser("ImportarLic", 1);
            this.setState({ tipoUser: "ImportarLic" });
        }
        this.limpiar();
        this.limpiarUser();
        this.limpiarPaginacion();
    };

    public pivotClickedMantenedor = (item: any) => {
        this.cambiarestadoformulario();
        this.setState({ edit: false });
        console.log(item.key);

        if (item.key === ".0") {
            this.obtenerMantenedor("Estado");
            this.setState({ tipoMantenedor: "Estado" });
        } else if (item.key === ".1") {
            this.obtenerMantenedor("Linea");
            this.setState({ tipoMantenedor: "Linea" });
        } else if (item.key === ".2") {
            this.obtenerMantenedor("Sub-Linea");
            this.obtenerLinea();
            this.setState({ tipoMantenedor: "Sub-Linea" });
        } else if (item.key === ".3") {
            this.obtenerMantenedor("Moneda");
            this.setState({ tipoMantenedor: "Moneda" });
        } else if (item.key === ".4") {
            this.obtenerMantenedor("Rol simulador");
            this.setState({ tipoMantenedor: "Rol simulador" });
        } else if (item.key === ".5") {
            this.obtenerMantenedor("Faena");
            this.setState({ tipoMantenedor: "Faena" });
        } else if (item.key === ".6") {
            this.obtenerMantenedor("Motivos");
            this.setState({ tipoMantenedor: "Motivos" });
        } else if (item.key === ".7") {
            this.obtenerMantenedor("Oficinas");
            this.setState({ tipoMantenedor: "Oficinas" });
        } else if (item.key === ".8") {
            this.obtenerMantenedor("Paises");
            this.setState({ tipoMantenedor: "Paises" });
        } else if (item.key === ".9") {
            this.obtenerMantenedor("Categoria rendicion");
            this.setState({ tipoMantenedor: "Categoria rendicion" });
        } else if (item.key === ".a") {
            this.obtenerMantenedor("Grupo clientes");
            this.setState({ tipoMantenedor: "Grupo clientes" });
        } else if (item.key === ".b") {
            this.obtenerMantenedor("Cliente");
            this.setState({ tipoMantenedor: "Cliente" });
        } else if (item.key === ".c") {
            this.obtenerMantenedor("Competidores");
            this.setState({ tipoMantenedor: "Competidores" });
        } else if (item.key === ".d") {
            this.obtenerMantenedor("Tipo calendario");
            this.setState({ tipoMantenedor: "Tipo calendario" });
        } else if (item.key === ".e") {
            this.obtenerMantenedor("Tipo contrato consultor");
            this.setState({ tipoMantenedor: "Tipo contrato consultor" });
        } else if (item.key === ".f") {
            this.obtenerMantenedor("Principales elementos del servicio");
            this.setState({ tipoMantenedor: "Principales elementos del servicio" });
        } else if (item.key === ".g") {
            this.obtenerMantenedor("Tipo rendición");
            this.setState({ tipoMantenedor: "Tipo rendición" });
        } else if (item.key === ".h") {
            this.obtenerMantenedor("Tipo tarea");
            this.setState({ tipoMantenedor: "Tipo tarea" });
        } else if (item.key === ".i") {
            this.obtenerMantenedor("Tipo contratos");
            this.setState({ tipoMantenedor: "Tipo contratos" });
        } else if (item.key === ".j") {
            this.obtenerMantenedor("Unidades negocio");
            this.setState({ tipoMantenedor: "Unidades negocio" });
        } else if (item.key === ".k") {
            this.obtenerMantenedor("Contratos marco");
            this.setState({ tipoMantenedor: "Contratos marco" });
        } else if (item.key === ".l") {
            this.obtenerMantenedor("Staffing");
            this.setState({ tipoMantenedor: "Staffing" });
        } else if (item.key === ".m") {
            this.obtenerMantenedor("Cargos plan");
            this.setState({ tipoMantenedor: "Cargos plan" });
        } else if (item.key === ".n") {
            this.obtenerMantenedor("Feriados");
            this.setState({ tipoMantenedor: "Feriados" });
        } else if (item.key === ".o") {
            this.obtenerMantenedor("Unidad rendición");
            this.setState({ tipoMantenedor: "Unidad rendición" });
        } else if (item.key === ".p") {
            this.obtenerMantenedor("Checklist cierre");
            this.setState({ tipoMantenedor: "Checklist cierre" });
        }
        this.limpiar();
        this.limpiarUser();
        this.limpiarPaginacion();
    };
    public limpiarPaginacion() {
        this.setState({
            currentPage: 1,
            todosPerPage: 3,
            upperPageBound: 5,
            lowerPageBound: 0,
            isPrevBtnActive: "disabled",
            isNextBtnActive: "",
            pageBound: 5,
        });
    }

    render() {
        let urlSitio = window.location.protocol + "//" + window.location.host;

        if (this.state.esAdmin || this.state.esValidador) {
            return (
                <React.Fragment>
                    <BotonAyuda token={this.state.tokenAPI} />
                    <div>
                        <div>
                            {this.state.tipoAdmin === ""
                                ? this.divOpciones()
                                : this.state.tipoAdmin === "Laudus"
                                    ? this.divLaudus2()
                                    : this.state.tipoAdmin === "BUK"
                                        ? this.divBUK2()
                                        : this.state.tipoAdmin === "Carga masiva de horas"
                                            ? this.divBUK3()
                                            : this.state.tipoAdmin === "Mantenedor"
                                                ? this.divMantenedor()
                                                : this.divRoles()}
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (this.state.esGestion || this.state.esUsuario) {
            return (
                <React.Fragment>
                    <div>
                        <div className="mt-4 card sinAcceso text-primary">
                            <div className="card-header">Atención</div>
                            <div className="card-body">
                                <h6>
                                    <Glyphicon glyph="info-sign" className="mr-1" /> Actualmente no posee el rol asignado para acceder a la
                                    administración, en caso de error favor contactarse con el administrador del sistema.
                                </h6>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div></div>
                </React.Fragment>
            );
        }
    }

    //DIALOGOS

    public dialogoError() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogo_error: true })}
                hidden={this.state.dialogo_error}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Problema al agregar`,
                    subText: this.state.subtext_error,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogo_error: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }
    public dialogoInsert() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogoGuardar: true })}
                hidden={this.state.dialogoGuardar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Agregado con éxito`,
                    subText: "Se ha agregado el nuevo campo éxitosamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogoGuardar: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }
    public dialogoUpdate() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogo_update: true })}
                hidden={this.state.dialogo_update}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Modificado con éxito`,
                    subText: "Modificación realizada éxitosamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogo_update: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }
    public dialogoDelete() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogo_delete: true })}
                hidden={this.state.dialogo_delete}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Eliminado con éxito`,
                    subText: "Campo eliminado éxitosamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogo_delete: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }
    public dialogoErrorLaudus() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogo_error_laudus: true })}
                hidden={this.state.dialogo_error_laudus}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Problema al cargar archivo`,
                    subText: this.state.subtext_error,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogo_error_laudus: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }
    public dialogoCargaExitosa() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogoCargaExitosa: true })}
                hidden={this.state.dialogoCargaExitosa}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Carga con éxito`,
                    subText: "Se han cargado los datos importados éxitosamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogoCargaExitosa: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }

    public dialogoCargado() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogoCargado: true })}
                hidden={this.state.dialogoCargado}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Carga masiva con éxito`,
                    subText: "Se han cargado los datos importados de la carga masiva éxitosamente",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogoCargado: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }

    public dialogoNoCargado() {
        return (
            <Dialog
                onDismiss={() => this.setState({ dialogoNoCargado: true })}
                hidden={this.state.dialogoNoCargado}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: `Error al cargar`,
                    subText: `No se han podido cargar los datos de la carga masiva
                  `,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                this.setState({ dialogoNoCargado: true });
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    }
}

export default Mantenedor;