import { useHistory } from "react-router-dom";
import "../../custom.css";
import GrillaDatos from "./../GrillaDatos/GrillaDatos";

import React, { useState, useEffect } from "react";
import "../Home/Home.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { fetchEngine } from "../../fetchData";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

const HomeOportunidad = () => {
    let userName: any;
    if (authProvider.getAccountInfo() != null) {
        userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
    }

    const [data, setData] = useState([]);
    const [comboData, setComboData] = useState([] as any);
    const [pages, setPages] = useState(0);
    const [elementID, setElementId] = useState(0);

    const [contrato, setContrato] = useState(0);

    const [emailUsuarioActual, setEmailUsuarioActual] = useState<string>(userName.account.userName);
    const [nombre, setNombre] = useState("0");
    const [fecha, setFecha] = useState("12-12-2012");
    const [id, setId] = useState(0);
    const [unidad, setUnidad] = useState(0);
    const [estado, setEstado] = useState(0);
    const [responsable, setResponsable] = useState("0");

    const [cliente, setCliente] = useState(0);

    const [isAdmin, setEsAdmin] = useState(false);
    const [isGestion, setEsGestion] = useState(false);
    const [isUsuario, setEsUsuario] = useState(false);
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [tokenAPI, setTokenAPI] = useState<string | null>("");
    const [tokenAPIGraph, setTokenAPIGraph] = useState<string | null>("");
    const [infoInicialCargada, setInfoInicialCargada] = useState<boolean>(false);

    const history = useHistory();
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: "Advertencia",
        subText: "¿Está seguro que quiere copiar esta oportunidad?",
    };

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                setTokenAPI(d.tokenAPI);
                setTokenAPIGraph(d.tokenAPIGraph);
            });
        }

        if (tokenAPI !== "" && !infoInicialCargada) {
            setInfoInicialCargada(true);
            verificarAdmin();
            verificarGestion();
            verificarUsuario();
            verificarValidador();
        }
        if (tokenAPI != "" && infoInicialCargada) {
            obtenerOportunidades(1);
        }
    }, [nombre, cliente, fecha, unidad, estado, id, responsable, isAdmin, isUsuario, isGestion, esValidador, tokenAPI, tokenAPIGraph]);

    const verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsAdmin(result.ok);
            });
    };

    const verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsUsuario(result.ok);
            });
    };

    const verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsGestion(result.ok);
            });
    };

    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }

    const cambioPage = (page: number) => {
        ////console.log(page);
    };

    const columnas = [
        {
            titulo: "ID",
            interno: "ID",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "id",
        },
        {
            titulo: "Nombre",
            interno: "NOMBRE",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text",
        },
        {
            titulo: "Cliente",
            interno: "CLIENTE_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Unidad de negocio",
            interno: "UNIDAD_NEGOCIO_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Fecha apertura",
            interno: "FECHA_CREACION",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "date",
        },
        {
            titulo: "Estado",
            interno: "ESTADO_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Responsable",
            interno: "RESPONSABLE_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "autocomplete",
            placeholder: "Nombre de Responsable",
        },
    ];

    const acciones = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver oportunidad",
            title: "Ver oportunidad",
        },
        {
            id: "copiar",
            icon: "Copy",
            tooltip: "Copiar oportunidad",
            title: "Copiar oportunidad",
        },
    ];

    const accion = (accion: string, ID: number) => {
        switch (accion) {
            case "reload":
                obtenerOportunidades(1);
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/oportunidad/${ID}`);
                break;
            case "paging":
                obtenerOportunidades(ID);
                break;
            case "copiar":
                setElementId(ID);
                setDialog(false);
                break;
            default:
                break;
        }
    };

    const obtenerOportunidades = async (page: number) => {
        fetchEngine
            .getAPI(
                `/api/oportunidad/getbyme/${emailUsuarioActual}/${nombre != "" ? nombre : "0"}/${cliente}/${fecha}/${unidad}/${estado}/${isNaN(id) ? 0 : id}/${
                    responsable != "" ? responsable : "0"
                }/${page}`,
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenAPI}`,
                }
            )
            .then((result: any) => {
                setData(result.data.listado);
                setComboData([
                    {
                        id: "ESTADO_DISPLAY",
                        value: result.data.estados,
                    },
                    {
                        id: "UNIDAD_NEGOCIO_DISPLAY",
                        value: result.data.unidades,
                    },
                    {
                        id: "CLIENTE_DISPLAY",
                        value: result.data.clientes,
                    },
                ]);

                setPages(result.contador);
            });
    };

    const copiarOportunidad = async (ID: number) => {
        fetchEngine
            .putAPI(`/api/oportunidad/${ID}`, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                let copyID = result.id;
                history.push(`/oportunidad/${copyID}`);
            });
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            copiarOportunidad(elementID);
                            setDialog(true);
                        }}
                        text="Sí, copiar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };

    const callbackFilter = (id: string, value: string, value2: any) => {
        switch (id) {
            case "ID":
                setId(parseInt(value));
                break;
            case "FECHA_CREACION":
                let dateAux: string = "12-12-2012";
                try {
                    dateAux = value2.getFullYear() + "-" + (value2.getMonth() + 1) + "-" + value2.getDate();
                } catch (error){
                    // VOID
                }
                setFecha(dateAux);
                break;
            case "NOMBRE":
                setNombre(value != "" ? value : "0");
                break;
            case "CLIENTE_DISPLAY":
                setCliente(parseInt(value));
                break;
            case "UNIDAD_NEGOCIO_DISPLAY":
                setUnidad(parseInt(value));
                break;
            case "ESTADO_DISPLAY":
                setEstado(parseInt(value));
                break;
            case "RESPONSABLE_DISPLAY":
                setResponsable(
                    value2[0] !== undefined ?
                        value2[0].mail !== undefined ? value2[0].mail
                        : "0"
                    : "0"
                );
                break;
            case "CONTRATO_MARCO":
                setContrato(parseInt(value));
                break;
        }
    };

    if (isAdmin || isGestion || esValidador) {
        return (
            <React.Fragment>
                <BotonAyuda token={tokenAPI} />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                            {
                                <div className="iconoRuta">
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                    &nbsp;
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Home Gestión de Oportunidades</span>
                                </div>
                            }
                            {
                                <div className="titulosimulador">
                                    <Glyphicon glyph="pushpin" /> Módulo Gestión de Oportunidades
                                </div>
                            }
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                            <div className="fijado">
                                <PrimaryButton text="Agregar nueva oportunidad" href="/oportunidad" />
                            </div>
                        </div>
                    </div>

                    {data ? (
                        <GrillaDatos
                            columnas={columnas}
                            listado={data}
                            callbackPages={cambioPage}
                            callbackAcciones={accion}
                            pages={pages}
                            titulo={""}
                            acciones={acciones}
                            callbackFilter={callbackFilter}
                            comboData={comboData}
                            tokenAPIGraph={tokenAPIGraph}
                        />
                    ) : (
                        "No hay datos cargados"
                    )}

                    {dialog()}
                </div>
            </React.Fragment>
        );
    } else if (isUsuario) {
        return (
            <React.Fragment>
                <div className="mt-4 card sinAcceso text-primary">
                    <div className="card-header">Atención</div>
                    <div className="card-body">
                        <h6>
                            <Glyphicon glyph="info-sign" className="mr-1" /> Usted no posee los permisos para visualizar este formulario, si desea
                            obtener los permisos, contactese con el administrador del sistema.
                        </h6>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="mt-4 card sinAcceso text-primary"></div>
            </React.Fragment>
        );
    }
};

export default HomeOportunidad;
