import { useHistory, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "./Rendicion.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib-commonjs/DatePicker";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ComboBox } from "office-ui-fabric-react";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { fetchEngine } from "../../fetchData";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { GastoRendicion, GastoRendicionGrilla, Participante, Rendicion } from "../../controles/entidades/Rendicion";
import AutoComplete from "../../controles/AutoComplete/AutoComplete";
import DragNDropFile from "../../controles/DragNDrop/DragNDropFile";
import Workspace from "../../controles/Workspace/Workspace";
import NumberFormat from "react-number-format";
import DialogosRendiciones from "../Rendicion/DialogosRendiciones";
import DialogosEstadoRendiciones from "../Rendicion/DialogosEstadoRendiciones";
import ListaGastos from "../Rendicion/ListaGastos";
import NewDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

initializeIcons();

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    shortDays: ["D", "L", "M", "M", "J", "V", "S"],
    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};

const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});

export interface ITextFieldCustomRenderExampleState {
    isCalloutVisible: boolean;
}

type QueryParams = {
    idRendicionParam: string;
    idGastoParam: string;
};

const AgregarVerRendicion = () => {
    const { idRendicionParam, idGastoParam } = useParams<QueryParams>();
    let userName: any;

    if (authProvider.getAccountInfo() != null) {
        userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
    }

    const ren_info = {
        ID: 0,
        ID_TIPO_RENDICION: 0,
        ID_MONEDA: 1,
        ID_USUARIO: 0,
        ID_ESTADO: 0,
        FECHA_RENDICION: new Date(Date.now()),
        MONTO_FONDO: 0,
        CREADO_POR_LOGIN: userName.account.userName,
        CREADO_POR_DISPLAY: userName.account.name,
        VALOR_TOTAL: 0,
    };
    const history = useHistory();
    const [renInfo, setRenInfo] = useState<Rendicion>(ren_info);
    const [renID, setRenID] = useState<number>(Number(idRendicionParam));
    const [gastosRendicion, setGastosRendicion] = useState<GastoRendicionGrilla[]>([]);
    const [gastoID, setGastoID] = useState<number>(0);
    const gastoLimpio = {
        ID: 0,
        NUMERO_GASTO: gastosRendicion.length + 1,
        ID_RENDICION: renID,
        ID_CATEGORIA_RENDICION: 0,
        ID_PROYECTO: 0,
        ID_ESTADO: 0,
        FECHA_GASTO: undefined,
        MONTO: 0,
        N_BOLETA_FACTURA: "",
        DESCRIPCION: "",
        PROVEEDOR: "",
        REEMBOLSABLE: false,
        ANIO: 0,
        MES: 0,
        RECUPERABLE: 0,
        USUARIO_APROBO_RECHAZO: "",
        FECHA_APROBO_RECHAZO: undefined,
        MOTIVO_RECHAZO: "",
        MES_ANIO_USO: undefined,
        PARTICIPANTES: [],
    };
    const [gastoSeleccionado, setGastoSeleccionado] = useState<GastoRendicion>(gastoLimpio);
    const [participantesRendicion, setParticipantesRendicion] = useState<Participante[]>([]);
    const [spRelativeSite, setSpRelativeSite] = useState<string>("");
    const [spTenant, setSpTenant] = useState<string>(fetchEngine.spTenant);
    const [opcionesProyectos, setOpcionesProyectos] = useState<any>([]);
    const [opcionesUsuario, setOpcionesUsuario] = useState<any>([]);
    const [opcionesMoneda, setOpcionesMoneda] = useState<any>([]);
    const [opcionesTipo, setOpcionesTipo] = useState<any>([]);
    const [opcionesCategoria, setOpcionesCategoria] = useState<any>([]);
    const [opcionesEstado, setOpcionesEstado] = useState<any>([]);
    const [contador, setContador] = useState<number>(0);

    const [dialogoEliminar, setDialogoEliminar] = useState<boolean>(true);
    const [dialogoEliminarConfirmardo, setDialogoEliminarConfirmardo] = useState<boolean>(true);
    const [dialogoEliminarError, setDialogoEliminarError] = useState<boolean>(true);
    const [dialogoGuardar, setDialogoGuardar] = useState<boolean>(true);
    const [dialogoEnviar, setDialogoEnviar] = useState<boolean>(true);
    const [dialogoValidar, setDialogoValidar] = useState<boolean>(true);
    const [dialogoAprobar, setDialogoAprobar] = useState<boolean>(true);
    const [dialogoRechazar, setDialogoRechazar] = useState<boolean>(true);
    const [dialogoMotivoRechazo, setDialogoMotivoRechazo] = useState<boolean>(true);
    const [dialogoConfirmaGuardar, setDialogoConfirmaGuardar] = useState<boolean>(true);
    const [montoFormateado, setMontoFormateado] = useState<number>(0);
    const [bloqueados, setBloqueados] = useState<boolean>(false);
    const [motivoRechazo, setMotivoRechazo] = useState<string>("");
    const [responsable, setResponsable] = useState<string>("");
    const [nombreProyecto, setNombreProyecto] = useState<string>("");
    const [enviarRen, setEnviarRen] = useState<boolean>(false);
    const [responsableNombre, setResponsableNombre] = useState<string>("");
    const [idOportunidad, setIdOportunidad] = useState<number>(0);

    const [nombreOportunidad, setNombreOportunidad] = useState<string>("");
    const [esAdmin, setEsAdmin] = useState<boolean>(false);
    const [esUsuario, setEsUsuario] = useState<boolean>(false);
    const [esGestion, setEsGestion] = useState<boolean>(false);
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [esContable, setEsContable] = useState<boolean>(false);
    const [usuarioActual, setUsuarioActual] = useState<string>(userName.account.name);
    const [usuarioActualEmail, setUsuarioActualEmail] = useState<string>(userName.account.userName);
    const [idUsuario, setIdUsuario] = useState<number>(0);
    const [esAdministrativo, setEsAdministrativo] = useState<boolean>(false);
    const [library, setLibrary] = useState<string>(process.env.REACT_APP_LIBRARY ? process.env.REACT_APP_LIBRARY : "");
    const [totalAdjuntos, setTotalAdjuntos] = useState<number>(0);
    const [valorTotalGastos, setValorTotalGastos] = useState<number>(0);
    const [dialogoEliminarGasto, setDialogoEliminarGasto] = useState<boolean>(true);
    const [dialogoEliminarConfirmarGasto, setDialogoEliminarConfirmarGasto] = useState<boolean>(true);
    const [dialogoEliminarErrorGasto, setDialogoEliminarErrorGasto] = useState<boolean>(true);
    const [dialogoContabilizar, setDialogoContabilizar] = useState<boolean>(true);
    const [dialogoValidarGasto, setDialogoValidarGasto] = useState<boolean>(true);
    const [tokenAPI, setTokenAPI] = useState<string | null>("");
    const [tokenAPIGraph, setTokenAPIGraph] = useState<string | null>("");
    const [mismoUsuario, setMismoUsuario] = useState<boolean>(false);
    const [requiereMesAnioUso, setRequiereMesAnioUso] = useState<boolean>(false);
    const [gastosSinRechazar, setGastosSinRechazar] = useState<boolean>(false);

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                setTokenAPI(d.tokenAPI);
                setTokenAPIGraph(d.tokenAPIGraph);
            });
        }

        if (tokenAPI != "") {
            verificarAdmin();
            verificarUsuario();
            verificarGestion();
            verificarValidador();
            verificarContable();
            obtenerProyectos();
            obtenerValoresCbx();
        }
    }, [esAdmin, esContable, esUsuario, esGestion, esValidador, tokenAPI, tokenAPIGraph, enviarRen]);

    //CONSULTAS PERFILAMIENTO
    async function verificarAdmin() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsAdmin(result.ok);
            });
    }

    async function verificarUsuario() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsUsuario(result.ok);
            });
    }

    async function verificarGestion() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsGestion(result.ok);
            });
    }
    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }
    async function verificarContable() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetContable`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsContable(result.ok);
            });
    }

    const _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    const _onFormatDateMesAnioUso = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getMonth() + 1 + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    function userHandler(tipo: string, value: any, display: string) {
        let gasto = Object.assign(gastoSeleccionado);

        if (tipo === "participantes") {
            gasto.PARTICIPANTES = value.map((p: any) => {
                return { MAIL: p.mail, DISPLAYNAME: p.name, ACTIVO: true };
            });
        }

        setGastoSeleccionado(gasto);
    }

    async function obtenerValoresCbx() {
        let userName: any;
        let ren = renInfo;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                const pro: any[] = [];
                const mo: any[] = [];
                const tp: any[] = [];
                const cat: any[] = [];
                const us: any[] = [];
                const es: any[] = [];
                const ctto: any[] = [];
                if (result.ok) {
                    result.data.proyectos.map((a: any) => {
                        if (a.ID_ESTADO === 13) {
                            pro.push({
                                key: a.ID,
                                text: a.ID + " - (" + a.NOMBRE + ")",
                                responsable: a.RESPONSABLE_GASTOS_LOGIN,
                                responsableNombre: a.RESPONSABLE_GASTOS_DISPLAY,
                                id_oportunidad: a.ID_OPORTUNIDAD,
                            });
                        }
                    });

                    result.data.monedas.map((m: any) => {
                        mo.push({
                            key: m.ID,
                            text: m.TITULO,
                            disabled: !m.ACTIVO,
                        });
                    });

                    result.data.tipo_rendicion.map((t: any) => {
                        tp.push({
                            key: t.ID,
                            text: t.TITULO,
                            disabled: !t.ACTIVO,
                        });
                    });

                    result.data.categoria_rendicion.map((c: any) => {
                        cat.push({
                            key: c.ID,
                            text: c.TITULO,
                            disabled: !c.ACTIVO,
                            esAdministrativo: c.ES_ADMINISTRATIVO,
                            idUnidadRendicion: c.ID_UNIDAD_RENDICION,
                            urlWorkspace: c.URL_WORKSPACE,
                        });
                    });

                    result.data.consultores.map((a: any) => {
                        us.push({
                            key: a.ID,
                            text: a.CONSULTOR_DISPLAY + " - (" + a.CONSULTOR_LOGIN + ")",
                            nombre: a.CONSULTOR_DISPLAY,
                            correo: a.CONSULTOR_LOGIN,
                        });
                    });

                    result.data.consultores.map((e: any) => {
                        if (e.CONSULTOR_LOGIN === userName.account.userName.toLowerCase()) {
                            ren.ID_USUARIO = e.ID;
                            setIdUsuario(e.ID);
                        }
                    });

                    result.data.estados.map((f: any) => {
                        if (f.ID_MODULO === 6) {
                            es.push({
                                key: f.ID,
                                text: f.TITULO,
                            });
                        }
                    });

                    setOpcionesMoneda(mo);
                    setOpcionesTipo(tp);
                    setOpcionesCategoria(cat);
                    setOpcionesUsuario(us);
                    setOpcionesEstado(es);

                    if (esAdmin || esContable || esValidador) {
                        setOpcionesProyectos(pro);
                    }
                    if (renID > 0) {
                        obtenerREN(cat);
                    }
                } else {
                    console.log(result);
                }
            });
    }

    async function obtenerProyectos() {
        let userName: any;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .getAPI(`/api/DatosFormulario/obtenerProyectos/${usuarioActualEmail}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                const pro: any[] = [];
                if (result.ok) {
                    result.data.proyectos.map((a: any) => {
                        if (a.ID_ESTADO === 13) {
                            pro.push({
                                key: a.ID,
                                text: a.ID + " - (" + a.NOMBRE + ")",
                                responsable: a.RESPONSABLE_GASTOS_LOGIN,
                                responsableNombre: a.RESPONSABLE_GASTOS_DISPLAY,
                                id_oportunidad: a.ID_OPORTUNIDAD,
                            });
                        }
                    });
                    setOpcionesProyectos(pro);
                } else {
                    console.log(result);
                }
            });
    }

    function callbackWorkspace(props: any) {
        let worskspace = props[0];
        console.log(worskspace);
        setSpRelativeSite(worskspace.URL_RELATIVA);
    }

    function contar(ev: any) {
        let max = 250;
        let contadorTexto = contador;
        let key = ev.target.value;
        let longitud = key.length;

        if (longitud <= max) {
            contadorTexto = max - longitud;
        }

        setContador(contadorTexto);
    }

    function textFieldOnChange(ev: any, e?: string) {
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.value : ev.target.value;
        let gasto = { ...gastoSeleccionado };
        let rendicion = { ...renInfo };

        switch (key) {
            case "textMonto":
                value = value === "" ? 0 : value;
                gasto.MONTO = parseFloat(value);
                setGastoSeleccionado(gasto);
                break;
            case "textBoleta":
                gasto.N_BOLETA_FACTURA = value;
                setGastoSeleccionado(gasto);
                break;
            case "textDesc":
                if (value.length <= 250) {
                    gasto.DESCRIPCION = value;
                }
                setGastoSeleccionado(gasto);
                break;
            case "textProv":
                gasto.PROVEEDOR = value;
                setGastoSeleccionado(gasto);
                break;
            case "motivoRechazo":
                gasto.MOTIVO_RECHAZO = value;
                setGastoSeleccionado(gasto);
                break;
            case "montoFondo":
                value = value === "" ? 0 : value;
                rendicion.MONTO_FONDO = parseFloat(value);
                setRenInfo(rendicion);
                break;
        }
    }

    function formatNumber(valor: string) {
        let value = "";

        if (valor.includes(".")) {
            value = valor.replace(".", ",");
        } else {
            value = valor;
        }

        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return value;
    }

    const _onChangeUsuario = (event: any, item: any): void => {
        let ren = { ...renInfo };
        ren.ID_USUARIO = item.key;
        ren.CREADO_POR_LOGIN = item.correo;
        ren.CREADO_POR_DISPLAY = item.nombre;
        setRenInfo(ren);

        obtenerProyectos();
    };
    const _onChangeEstado = (event: any, item: any): void => {
        let ren = { ...renInfo };
        ren.ID_ESTADO = item.key;
        setRenInfo(ren);
    };
    const _onChangeTipo = (event: any, item: any): void => {
        let ren = { ...renInfo };
        ren.ID_TIPO_RENDICION = item.key;
        setRenInfo(ren);
    };
    const _onChangeMoneda = (event: any, item: any): void => {
        let ren = { ...renInfo };
        ren.ID_MONEDA = item.key;
        setRenInfo(ren);
    };
    const _onChangeProyecto = (event: any, item: any): void => {
        console.log('item')
        console.log(item)
        let gasto = { ...gastoSeleccionado };

        if (item != undefined) {
            gasto.ID_PROYECTO = item.key;
            setGastoSeleccionado(gasto);
            setResponsable(item.responsable);
            setNombreProyecto(item.text);
            setIdOportunidad(item.id_oportunidad);
            setNombreOportunidad(item.text);
        }
    };
    const _onChangeCategoria = (event: any, item: any): void => {
        let gasto = { ...gastoSeleccionado };
        gasto.ID_CATEGORIA_RENDICION = item.key;
        gasto.ID_PROYECTO = item.esAdministrativo ? 0 : gasto.ID_PROYECTO;
        setGastoSeleccionado(gasto);
        setEsAdministrativo(item.esAdministrativo);
        if (item.key === 32 || item.key === 37 || item.key === 11 || item.key === 14) {
            setRequiereMesAnioUso(true);
        } else {
            setRequiereMesAnioUso(false);
        }
        if (!item.text.startsWith("PRY") && item.urlWorkspace != null) {
            const relativeUrlWorkspace = item.urlWorkspace.substr(29); //29 es la longitud de la cadena con el dominio de SharePoint.
            setSpRelativeSite(relativeUrlWorkspace);
        } else {
            setSpRelativeSite("");
        }
    };
    const _onSelectDate = (date: Date | null | undefined, key: string): void => {
        let ren = { ...renInfo };
        let gasto = { ...gastoSeleccionado };
        if (date != null) {
            switch (key) {
                case "fechaRendicion":
                    ren.FECHA_RENDICION = date;
                    setRenInfo(ren);
                    break;
                case "fechaGasto":
                    gasto.FECHA_GASTO = date;
                    gasto.ANIO = date.getFullYear();
                    gasto.MES = date.getMonth() + 1;
                    setGastoSeleccionado(gasto);
                    break;
                case "mesAnioUso":
                    gasto.MES_ANIO_USO = date;
                    setGastoSeleccionado(gasto);
                    break;
            }
        }
    };
    function _onCheckChange(ev?: any, isChecked?: boolean) {
        let id = ev.target.id;
        let gasto = { ...gastoSeleccionado };
        switch (id) {
            case "checkReem":
                gasto.REEMBOLSABLE = isChecked;
                break;
        }
        setGastoSeleccionado(gasto);
    }
    async function obtenerREN(cat?: any[], guardandoREN?: boolean) {
        setGastosSinRechazar(false);
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPI(`/api/rendicion/ObtenerRendicion/${renID}/${usuarioActualEmail}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    let rendicion = resultado.data.rendicion[0];
                    let gastos = resultado.data.gastos;
                    let totalMontoGastos = 0;
                    gastos.map((gasto: GastoRendicionGrilla) => {
                        if (gasto.ID_ESTADO === 19) {
                            setGastosSinRechazar(true);
                        }
                        totalMontoGastos += gasto.MONTO;
                    });
                    if (gastos.length > 0) {
                        setValorTotalGastos(totalMontoGastos);
                        rendicion.VALOR_TOTAL = totalMontoGastos;
                    } else {
                        setValorTotalGastos(0);
                        rendicion.VALOR_TOTAL = 0;
                    }
                    if (rendicion.CREADO_POR_LOGIN === usuarioActualEmail) {
                        setMismoUsuario(true);
                    }
                    rendicion.FECHA_RENDICION = rendicion.FECHA_RENDICION != null ? new Date(rendicion.FECHA_RENDICION) : new Date();
                    setRenInfo(rendicion);
                    setGastosRendicion(gastos);
                    setParticipantesRendicion(resultado.data.participantes);
                    const participantes = resultado.data.participantes.filter(function (participante: any) {
                        return participante.ID_GASTO_RENDICION === Number(idGastoParam);
                    });

                    if (Number(idGastoParam) !== 0 && !guardandoREN) {
                        seleccionarGasto(Number(idGastoParam), false, gastos, participantes, cat);
                    } else {
                        let totalGastos = gastos.length + 1;
                        gastoLimpio.NUMERO_GASTO = totalGastos;
                        setGastoSeleccionado(gastoLimpio);
                        setIdOportunidad(0);
                    }
                } else {
                    console.log(resultado);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    async function eliminarRendicion(e: number) {
        fetchEngine
            .postAPI(`/api/rendicion/EliminarRendicion/${e}`, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    setDialogoEliminarConfirmardo(false);
                } else {
                    setDialogoEliminarError(false);
                    console.log(result);
                }
            })
            .catch((err) => {
                setDialogoEliminarError(false);
                console.log(err);
            });
    }

    async function guardarREN(estadoSeleccionado: number) {
        let ren = Object.assign(renInfo);
        let urlSitio = window.location.protocol + "//" + window.location.host;
        let userName: any;

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        if (renID === 0 && esUsuario && esAdmin === false && esGestion === false) {
            ren.CREADO_POR_LOGIN = userName.account.userName;
            ren.CREADO_POR_DISPLAY = userName.account.name;
        }
        ren.ID_ESTADO = estadoSeleccionado;
        fetchEngine
            .postAPI("/api/rendicion", ren, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    if (renID > 0) {
                        obtenerREN([], true);
                        if (estadoSeleccionado == 17) {
                            setDialogoEnviar(false);
                        } else {
                            setDialogoGuardar(true);
                        }
                    } else {
                        history.push(`/ver-rendicion/${resultado.id}/0`);
                    }
                    if (estadoSeleccionado == 17 /* enviarRen === true */) {

                        let notificacionRendicionEnviada = {
                            CORREO_APROBADOR: responsable,
                            CREADO_POR: ren.CREADO_POR_DISPLAY,
                            ENLACE_RENDICION: `${urlSitio}/ver-rendicion/${resultado.id}/0`,
                            NOMBRE_PROYECTO: nombreProyecto,
                        };

                        fetchEngine.postAPI(
                            fetchEngine.urlFlujoRendicionEnviada ? fetchEngine.urlFlujoRendicionEnviada : "",
                            notificacionRendicionEnviada,
                            {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            }
                        );
                    } else {
                        setDialogoGuardar(false);
                    }
                } else {
                    console.log(resultado);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function guardarGastoRendicion(estadoSeleccionado: number) {
        let gasto = Object.assign(gastoSeleccionado);

        if (gasto.MONTO <= 0) {
            gasto.MONTO = 0;
        }
        gasto.ID_ESTADO = estadoSeleccionado;

        fetchEngine
            .postAPI("/api/rendicion/InsertarGasto", gasto, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    if (estadoSeleccionado === 18) {
                        setDialogoAprobar(true);
                        guardarHistorialAccionGasto(gasto.ID, 18);
                    } else if (estadoSeleccionado === 19) {
                        guardarHistorialAccionGasto(gasto.ID, 19);
                        let urlSitio = window.location.protocol + "//" + window.location.host;

                        let notificacionRendicionRechazada = {
                            CREADO_POR: renInfo.CREADO_POR_LOGIN,
                            ENLACE_RENDICION: `${urlSitio}/ver-rendicion/${renID}/${gasto.ID}`,
                            MOTIVO_RECHAZO: gasto.MOTIVO_RECHAZO,
                            NOMBRE_APROBADOR: usuarioActual,
                            ID_RENDICION: renID,
                            NUMERO_GASTO: gasto.NUMERO_GASTO,
                        };

                        fetchEngine.postAPI(
                            fetchEngine.urlFlujoRendicionRechazada ? fetchEngine.urlFlujoRendicionRechazada : "",
                            notificacionRendicionRechazada,
                            {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                            }
                        );
                        setDialogoMotivoRechazo(true);
                    } else if (estadoSeleccionado === 26) {
                        setDialogoValidarGasto(true);
                        guardarHistorialAccionGasto(gasto.ID, 26);
                    } else if (estadoSeleccionado === 27) {
                        setDialogoContabilizar(true);
                        guardarHistorialAccionGasto(gasto.ID, 27);
                    } else if (estadoSeleccionado === 29) {
                        guardarHistorialAccionGasto(gasto.ID, 29);
                        setDialogoMotivoRechazo(true);
                    } else {
                        setDialogoGuardar(false);
                    }
                    setSpRelativeSite("");
                    obtenerREN([], true);
                } else {
                    console.log(resultado);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function guardarHistorialAccionGasto(idGasto: number, estadoGasto: number) {
        const accion = {
            ID: 0,
            ID_GASTO_RENDICION: idGasto,
            USUARIO: usuarioActual,
            EMAIL_USUARIO: usuarioActualEmail,
            FECHA_ACCION: new Date(),
            ESTADO_ACCION: estadoGasto,
        };
        fetchEngine
            .postAPI(`/api/rendicion/InsertarHistorialAccionesGasto`, accion, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                console.log(result);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function eliminarGasto(idRendicion: number, idGasto: number) {
        fetchEngine
            .postAPI(`/api/rendicion/EliminarGasto/${idRendicion}/${idGasto}`, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    setDialogoEliminarConfirmarGasto(false);
                    obtenerREN([], true);
                } else {
                    setDialogoEliminarErrorGasto(false);
                }
            })
            .catch((err) => {
                setDialogoEliminarErrorGasto(false);
            });
    }
    function validarGastoEnProceso() {
        let flag = false;
        if (
            gastoSeleccionado.ID_CATEGORIA_RENDICION != 0 ||
            gastoSeleccionado.MONTO != 0 ||
            gastoSeleccionado.N_BOLETA_FACTURA != "" ||
            gastoSeleccionado.PROVEEDOR != ""
        ) {
            flag = false;
        } else {
            flag = true;
        }
        return flag;
    }

    function validarCamposRendicion(estado: number) {
        if (
            renInfo.ID_TIPO_RENDICION === undefined ||
            renInfo.ID_TIPO_RENDICION === null ||
            renInfo.ID_TIPO_RENDICION <= 0 ||
            renInfo.ID_MONEDA === undefined ||
            renInfo.ID_MONEDA === null ||
            renInfo.ID_MONEDA <= 0 ||
            renInfo.ID_USUARIO === undefined ||
            renInfo.ID_USUARIO === null ||
            renInfo.ID_USUARIO <= 0 ||
            renInfo.FECHA_RENDICION === undefined ||
            renInfo.FECHA_RENDICION === null ||
            (renInfo.ID_TIPO_RENDICION === 3 && (renInfo.MONTO_FONDO === undefined || renInfo.MONTO_FONDO === null || renInfo.MONTO_FONDO <= 0))
        ) {
            setDialogoValidar(false);
            //setEnviarRen(false);
        } else {
            //Validar si esta llenando un gasto
            console.log(renInfo);
            console.log(gastoSeleccionado);
            if (estado === 16 && renInfo.ID != 0) {
                if (validarGastoEnProceso()) {
                    setEnviarRen(true);
                    guardarREN(estado);
                } else {
                    //lert("Esta llenando un gasto");
                    setDialogoConfirmaGuardar(false);
                }
            } else {
                setEnviarRen(true);
                guardarREN(estado);
            }

        }
    }
    function validarCamposGasto() {
        let gasto = Object.assign(gastoSeleccionado);
        let tieneProyecto = true;
        if (!esAdministrativo) {
            if (gasto.ID_PROYECTO === undefined || gasto.ID_PROYECTO === null || gasto.ID_PROYECTO <= 0) {
                tieneProyecto = false;
            }
        }
        if (
            gasto.ID_CATEGORIA_RENDICION === undefined ||
            gasto.ID_CATEGORIA_RENDICION === null ||
            gasto.ID_CATEGORIA_RENDICION <= 0 ||
            gasto.FECHA_GASTO === undefined ||
            gasto.FECHA_GASTO === null ||
            gasto.PROVEEDOR === undefined ||
            gasto.PROVEEDOR === "" ||
            gasto.MONTO === undefined ||
            gasto.MONTO === null ||
            gasto.MONTO < 0 ||
            gasto.N_BOLETA_FACTURA === undefined ||
            gasto.N_BOLETA_FACTURA === null ||
            gasto.N_BOLETA_FACTURA === "" ||
            totalAdjuntos === 0 ||
            totalAdjuntos === null ||
            totalAdjuntos === undefined ||
            (!esAdministrativo && !tieneProyecto) ||
            (requiereMesAnioUso && (gastoSeleccionado.MES_ANIO_USO === undefined || gasto.MES_ANIO_USO === null || gasto.MES_ANIO_USO === undefined))
        ) {
            setDialogoValidar(false);
        } else {
            guardarGastoRendicion(16);
        }
    }

    function callbackDialogos(key: string) {
        switch (key) {
            case "eliminar":
                setDialogoEliminar(true);
                eliminarRendicion(renID);
                break;
            case "renEliminada":
                setDialogoEliminarConfirmardo(true);
                window.location.href = `/homerendicion`;
                break;
            case "errorEliminar":
                setDialogoEliminarError(true);
                break;
            case "enviar":
                setDialogoEnviar(true);
                history.push(`/homerendicion`);
                break;
            case "guardar":
                setDialogoGuardar(true);
                break;
            case "aprobar":
                guardarGastoRendicion(18);
                break;
            case "siRechazar":
                setDialogoRechazar(true);
                setDialogoMotivoRechazo(false);
                break;
            case "cerrar":
                setDialogoAprobar(true);
                setDialogoRechazar(true);
                setDialogoMotivoRechazo(true);
                setDialogoEliminar(true);
                setDialogoValidar(true);
                setDialogoEliminarGasto(true);
                setDialogoContabilizar(true);
                setDialogoValidarGasto(true);
                break;
            case "rechazarGastoAprobador":
                guardarGastoRendicion(19);
                break;
            case "eliminarGasto":
                eliminarGasto(renID, gastoID);
                setDialogoEliminarGasto(true);
                break;
            case "gastoEliminado":
                setDialogoEliminarConfirmarGasto(true);
                break;
            case "errorEliminarGasto":
                setDialogoEliminarErrorGasto(true);
                break;
            case "validar":
                guardarGastoRendicion(26);
                break;
            case "contabilizar":
                guardarGastoRendicion(27);
                break;
            case "rechazarGastoValidador":
                guardarGastoRendicion(29);
                break;
            case "confirmaGuardarSi":
                setDialogoConfirmaGuardar(true);
                guardarREN(16);
                break;
            case "confirmaGuardarNo":
                setDialogoConfirmaGuardar(true);
                break;
        }
    }

    function formatStringDate(date: Date | undefined) {
        let dateString = date !== undefined ? date.toString().split("T")[0] : "";
        let formatDate;
        if (dateString !== "" && dateString !== "0001-01-01") {
            formatDate = new Date(parseInt(dateString.split("-")[0]), parseInt(dateString.split("-")[1]) - 1, parseInt(dateString.split("-")[2]));
        }
        return formatDate;
    }

    function seleccionarGasto(
        idGasto: number,
        asginarUsuarioApruebaRechaza: boolean,
        listaGastos?: GastoRendicionGrilla[],
        listaParticipantes?: Participante[],
        listaCategorias?: any[]
    ) {
        let copiaGastoRendicion = JSON.parse(JSON.stringify(listaGastos || gastosRendicion));

        const gastoFiltrado = copiaGastoRendicion.find(function (gasto: any) {
            return gasto.ID === idGasto;
        });

        const participantes = participantesRendicion.filter(function (participante) {
            return participante.ID_GASTO_RENDICION === idGasto;
        });

        gastoFiltrado.FECHA_GASTO = gastoFiltrado.FECHA_GASTO != null ? formatStringDate(gastoFiltrado.FECHA_GASTO) : undefined;
        gastoFiltrado.FECHA_APROBO_RECHAZO =
            gastoFiltrado.FECHA_APROBO_RECHAZO != null ? formatStringDate(gastoFiltrado.FECHA_APROBO_RECHAZO) : undefined;
        gastoFiltrado.MES_ANIO_USO = gastoFiltrado.MES_ANIO_USO != null ? formatStringDate(gastoFiltrado.MES_ANIO_USO) : undefined;

        const categorias = listaCategorias || opcionesCategoria;
        if (gastoFiltrado.ID_PROYECTO === 0) {
            setEsAdministrativo(true);
            categorias.map((e: any) => {
                if (e.key === gastoFiltrado.ID_CATEGORIA_RENDICION) {
                    if (e.urlWorkspace != null) {
                        const relativeUrlWorkspace = e.urlWorkspace.substr(29);
                        setSpRelativeSite(relativeUrlWorkspace);
                    }
                }
            });
            setIdOportunidad(0);
            setNombreOportunidad("");
        } else {
            setIdOportunidad(gastoFiltrado.ID_OPORTUNIDAD);
            setNombreOportunidad(gastoFiltrado.NOMBRE_OPORTUNIDAD);
        }
        if (
            gastoFiltrado.ID_CATEGORIA_RENDICION === 32 ||
            gastoFiltrado.ID_CATEGORIA_RENDICION === 37 ||
            gastoFiltrado.ID_CATEGORIA_RENDICION === 11 ||
            gastoFiltrado.ID_CATEGORIA_RENDICION === 14
        ) {
            setRequiereMesAnioUso(true);
        } else {
            setRequiereMesAnioUso(false);
        }
        gastoFiltrado.PARTICIPANTES = listaParticipantes || participantes;
        delete gastoFiltrado.CATEGORIA;
        delete gastoFiltrado.FECHA_GASTO_FORMATEADA;
        if (asginarUsuarioApruebaRechaza) {
            gastoFiltrado.FECHA_APROBO_RECHAZO = new Date();
            gastoFiltrado.USUARIO_APROBO_RECHAZO = usuarioActual;
        }
        setGastoSeleccionado(gastoFiltrado);
    }

    function callbackAcciones(key: string, idGasto: number) {
        switch (key) {
            case "ver":
                seleccionarGasto(idGasto, false);
                setGastoID(idGasto);
                break;
            case "eliminar":
                setGastoID(idGasto);
                setDialogoEliminarGasto(false);
                break;
            case "aprobar":
                seleccionarGasto(idGasto, true);
                setGastoID(idGasto);
                setDialogoAprobar(false);
                break;
            case "rechazar":
                seleccionarGasto(idGasto, true);
                setGastoID(idGasto);
                setDialogoRechazar(false);
                break;
            case "validar":
                seleccionarGasto(idGasto, false);
                setGastoID(idGasto);
                setDialogoValidarGasto(false);
                break;
            case "contabilizar":
                seleccionarGasto(idGasto, false);
                setGastoID(idGasto);
                setDialogoContabilizar(false);
                break;
        }
    }

    function obtenerTotalPropuesta(value: number) {
        setTotalAdjuntos(value);
    }

    function nuevaREN() {
        window.location.href = `/crear-rendicion`;
    }

    return (
        <React.Fragment>
            <BotonAyuda token={tokenAPI} />
            <div className="fuente">
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                            <div className="iconoRuta">
                                <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                &nbsp;
                                <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Rendición de gastos</span>
                            </div>
                            <div className="titulosimulador">
                                <Glyphicon glyph="usd" /> Rendición de Gastos
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                            <div className="fijado">
                                <PrimaryButton text="Volver" onClick={() => history.push(`/homerendicion`)} />
                                {renInfo.CREADO_POR_LOGIN == usuarioActualEmail && renInfo.ID_ESTADO === 16 && (
                                    <PrimaryButton text="Eliminar Rendición" onClick={() => setDialogoEliminar(false)} />
                                )}

                                {renInfo.ID_ESTADO > 16 && <PrimaryButton text="Nueva rendición" onClick={() => nuevaREN()} />}

                                {(renInfo.ID === 0 || renInfo.ID_ESTADO === 16) && (
                                    <PrimaryButton text="Guardar Rendición" onClick={() => validarCamposRendicion(16)} />
                                )}
                                {renInfo.ID_ESTADO === 19 && <PrimaryButton text="Guardar Rendición" onClick={() => validarCamposRendicion(19)} />}
                                {(renInfo.ID_ESTADO === 16 || renInfo.ID_ESTADO === 19) && (
                                    <PrimaryButton
                                        text={renInfo.ID_ESTADO === 19 ? "Reenviar Rendición" : "Enviar Rendición"}
                                        onClick={() => {
                                            setEnviarRen(true);
                                            validarCamposRendicion(17);
                                        }}
                                        disabled={renInfo.ID === 0 || gastosRendicion.length === 0 || gastosSinRechazar ? true : false}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pagina">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10"></div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                <span className="formateo">
                                    <label>ID</label>
                                    <NumberFormat id="idRendicion" value={renInfo.ID} disabled={true} />
                                </span>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-12" onClick={() => {
                                console.log('renInfo')
                                console.log(renInfo)
                                console.log('gastoSeleccionado')
                                console.log(gastoSeleccionado)
                                console.log('spRelativeSite')
                                console.log(spRelativeSite)

                                var res = spRelativeSite === "" ? ("Debe seleccionar una categoria para adjuntar documentos") : renInfo.ID === 0 ? ("Debe guardar la rendicion antes de subir archivos") : "mostrar zona archivo"

                                console.log('res')
                                console.log(res)

                            }}>
                                <h6 className="border-bottom border-warning text-warning">Datos Generales</h6>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                <DatePicker
                                    id="fechaRendicion"
                                    className={controlClass.control}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    strings={DayPickerStrings}
                                    placeholder={"Seleccionar..."}
                                    label="Fecha rendición"
                                    formatDate={_onFormatDate}
                                    disabled={true}
                                    value={renInfo.FECHA_RENDICION}
                                    onSelectDate={(e) => {
                                        _onSelectDate(e, "fechaRendicion");
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                <ComboBox
                                    label={"Tipo"}
                                    placeholder={"Seleccionar..."}
                                    options={opcionesTipo}
                                    selectedKey={renInfo.ID_TIPO_RENDICION}
                                    onChange={_onChangeTipo}
                                    required={true}
                                    disabled={renInfo.ID_ESTADO > 16 && renInfo.ID_ESTADO != 19 ? true : false}
                                    allowFreeform={true}
                                    autoComplete="on"
                                />
                            </div>
                            {renInfo.ID_TIPO_RENDICION === 3 && (
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                    <span className="formateo">
                                        <label>
                                            Monto Fondo <span>*</span>
                                        </label>
                                        <NumberFormat
                                            id="montoFondo"
                                            onValueChange={(e) => {
                                                textFieldOnChange(e, "montoFondo");
                                            }}
                                            value={renInfo.MONTO_FONDO === 0 ? "" : formatNumber(renInfo.MONTO_FONDO.toString())}
                                            disabled={renInfo.ID_ESTADO > 16 && renInfo.ID_ESTADO != 19 ? true : false}
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                            prefix={"$"}
                                        />
                                    </span>
                                </div>
                            )}
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                                    <ComboBox
                                        label="Usuario"
                                        autoComplete="on"
                                        allowFreeform={true}
                                        placeholder={""}
                                        options={opcionesUsuario}
                                        onChange={_onChangeUsuario}
                                        selectedKey={renInfo.ID_USUARIO}
                                        disabled={renInfo.ID_ESTADO > 16 && renInfo.ID_ESTADO != 19 ? true : esAdmin ? false : true}></ComboBox>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                                    <ComboBox
                                        label={"Moneda rendición"}
                                        placeholder={"Seleccionar..."}
                                        options={opcionesMoneda}
                                        selectedKey={renInfo.ID_MONEDA}
                                        onChange={_onChangeMoneda}
                                        required={true}
                                        disabled={renInfo.ID_ESTADO > 16 && renInfo.ID_ESTADO != 19 ? true : false}
                                        allowFreeform={true}
                                        autoComplete="on"
                                    />
                                </div>
                            </div>
                        </div>
                        {renID > 0 ? (
                            <React.Fragment>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                                        <h6 className="border-bottom border-warning text-warning">Gasto</h6>
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                <ComboBox
                                                    label="Categoría/Tipo Gasto"
                                                    placeholder="Seleccionar..."
                                                    options={opcionesCategoria}
                                                    selectedKey={gastoSeleccionado.ID_CATEGORIA_RENDICION}
                                                    onChange={_onChangeCategoria}
                                                    required={true}
                                                    disabled={gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false}
                                                    allowFreeform={true}
                                                    autoComplete="on"
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                <span className="formateo">
                                                    <label>
                                                        Monto <span>*</span>
                                                    </label>
                                                    <NumberFormat
                                                        id="textMonto"
                                                        onValueChange={(e) => {
                                                            textFieldOnChange(e, "textMonto");
                                                        }}
                                                        value={gastoSeleccionado.MONTO === 0 ? "" : formatNumber(gastoSeleccionado.MONTO.toString())}
                                                        disabled={
                                                            gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false
                                                        }
                                                        thousandSeparator={"."}
                                                        decimalSeparator={","}
                                                        prefix={"$"}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                <DatePicker
                                                    id="fechaGasto"
                                                    className={controlClass.control}
                                                    firstDayOfWeek={DayOfWeek.Monday}
                                                    strings={DayPickerStrings}
                                                    placeholder={""}
                                                    label="Fecha gasto"
                                                    formatDate={_onFormatDate}
                                                    value={gastoSeleccionado.FECHA_GASTO}
                                                    onSelectDate={(e) => {
                                                        _onSelectDate(e, "fechaGasto");
                                                    }}
                                                    isRequired={true}
                                                    disabled={gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                <TextField
                                                    label="Proveedor"
                                                    id={"textProv"}
                                                    onChange={(e) => {
                                                        textFieldOnChange(e);
                                                    }}
                                                    value={gastoSeleccionado.PROVEEDOR}
                                                    required={true}
                                                    disabled={gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ComboBox
                                                    label="Proyecto"
                                                    placeholder={
                                                        esAdministrativo == true ? "Deshabilitado por rendición administrativa." : "Seleccionar..."
                                                    }
                                                    options={opcionesProyectos}
                                                    selectedKey={gastoSeleccionado.ID_PROYECTO}
                                                    onChange={_onChangeProyecto}
                                                    required={true}
                                                    disabled={
                                                        gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19
                                                            ? true
                                                            : esAdministrativo
                                                                ? true
                                                                : false
                                                    }
                                                    allowFreeform={true}
                                                    autoComplete="on"
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                <TextField
                                                    label="N° Boleta o Factura"
                                                    id={"textBoleta"}
                                                    onChange={(e) => {
                                                        textFieldOnChange(e);
                                                    }}
                                                    value={gastoSeleccionado.N_BOLETA_FACTURA}
                                                    required={true}
                                                    disabled={gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false}
                                                />
                                                <span>Debe ingresar N° referencia de boleta o factura</span>
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 reembolsable">
                                                <Checkbox
                                                    id="checkReem"
                                                    label="Cobrable al cliente"
                                                    checked={gastoSeleccionado.REEMBOLSABLE}
                                                    onChange={_onCheckChange}
                                                    disabled={gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className="ms-Grid-row">
                                            {gastoSeleccionado.ID_ESTADO === 19 ? (
                                                <React.Fragment>
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                        <TextField
                                                            id="textDesc"
                                                            label="Descripción"
                                                            value={gastoSeleccionado.DESCRIPCION}
                                                            multiline
                                                            autoAdjustHeight
                                                            onChange={(e) => {
                                                                textFieldOnChange(e);
                                                                contar(e);
                                                            }}
                                                            disabled={
                                                                gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false
                                                            }
                                                        />
                                                        <label>Quedan {contador} carácteres.</label>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                        <TextField
                                                            id="motivorechazo"
                                                            label="Motivo de rechazo"
                                                            value={gastoSeleccionado.MOTIVO_RECHAZO}
                                                            defaultValue={gastoSeleccionado.MOTIVO_RECHAZO}
                                                            multiline
                                                            autoAdjustHeight
                                                            onChange={(e) => {
                                                                textFieldOnChange(e);
                                                            }}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <TextField
                                                        id="textDesc"
                                                        label="Descripción"
                                                        value={gastoSeleccionado.DESCRIPCION}
                                                        multiline
                                                        autoAdjustHeight
                                                        onChange={(e) => {
                                                            textFieldOnChange(e);
                                                            contar(e);
                                                        }}
                                                        disabled={
                                                            gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false
                                                        }
                                                    />
                                                    <label>Quedan {contador} carácteres.</label>
                                                </div>
                                            )}
                                        </div>
                                        <div className="ms-Grid-row">
                                            {esAdministrativo ? (
                                                <React.Fragment>
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                        <label className="participantes">Participantes</label>
                                                        <div className="mt10">
                                                            <AutoComplete
                                                                key="participantes"
                                                                id="participantes"
                                                                callback={userHandler}
                                                                multi={true}
                                                                disabled={
                                                                    gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19
                                                                        ? true
                                                                        : false
                                                                }
                                                                defaultValue={
                                                                    gastoSeleccionado.PARTICIPANTES != null
                                                                        ? gastoSeleccionado.PARTICIPANTES.map((u: any) => {
                                                                            return {
                                                                                name: u.DISPLAYNAME,
                                                                                mail: u.MAIL,
                                                                            };
                                                                        })
                                                                        : ""
                                                                }
                                                                tokenAPIGraph={tokenAPIGraph}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                                        {/* {requiereMesAnioUso ? <label style={{ marginBottom: "18px" }}>Mes/Año Uso <p style={{ color: "#a4262c" }}>*</p> </label> : <label>Mes/Año Uso</label>} */}
                                                        {requiereMesAnioUso ? <label style={{ marginBottom: "10px" }}>Mes/Año Uso <label style={{ color: "#a4262c" }}>*</label> </label> : <label>Mes/Año Uso</label>}
                                                        
                                                            <NewDatePicker
                                                                id="mesAnioUso"
                                                                locale={"es"}
                                                                className="reactCalendar"
                                                                selected={gastoSeleccionado.MES_ANIO_USO}
                                                                onChange={(e) => {
                                                                    _onSelectDate(e, "mesAnioUso");
                                                                }}
                                                                showMonthYearPicker
                                                                dateFormat="MM/yyyy"
                                                                disabled={
                                                                    gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false
                                                                }
                                                            />
                                                        
                                                    </div>
                                                </React.Fragment>
                                            ) : (
                                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                    <label className="participantes">Participantes</label>
                                                    <div className="mt10">
                                                        <AutoComplete
                                                            key={"participantes"}
                                                            id="participantes"
                                                            callback={userHandler}
                                                            multi={true}
                                                            disabled={
                                                                gastoSeleccionado.ID_ESTADO > 16 && gastoSeleccionado.ID_ESTADO != 19 ? true : false
                                                            }
                                                            defaultValue={
                                                                gastoSeleccionado.PARTICIPANTES != null
                                                                    ? gastoSeleccionado.PARTICIPANTES.map((u: any) => {
                                                                        return {
                                                                            name: u.DISPLAYNAME,
                                                                            mail: u.MAIL,
                                                                        };
                                                                    })
                                                                    : ""
                                                            }
                                                            tokenAPIGraph={tokenAPIGraph}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            {idOportunidad && idOportunidad > 0 ? (
                                                <Workspace
                                                    callback={callbackWorkspace}
                                                    nombre={nombreOportunidad ? nombreOportunidad : ""}
                                                    id={idOportunidad!}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2">
                                            <label>Adjuntar boletas</label>
                                            <div>
                                                {spRelativeSite === "" ? <p>Debe seleccionar una categoria para adjuntar documentos</p> :
                                                    renInfo.ID === 0 ? <p>Debe guardar la rendicion antes de subir archivos</p> :
                                                        <DragNDropFile
                                                            disabled={gastoSeleccionado.ID_ESTADO >= 17 && gastoSeleccionado.ID_ESTADO != 19 ? true : false}
                                                            id={idOportunidad}
                                                            spTenant={spTenant}
                                                            librarySP_EN={library}
                                                            librarySpanish={"Documentos"}
                                                            carpeta={"Rendiciones"}
                                                            spUrl={spRelativeSite}
                                                            idArchivo={renID}
                                                            numGasto={gastoSeleccionado.NUMERO_GASTO}
                                                            emailUsuario={renInfo.CREADO_POR_LOGIN}
                                                            esAdminProp={esAdmin}
                                                            esGestionProp={esGestion}
                                                            esUsuarioProp={esUsuario}
                                                            esValidadorProp={esValidador}
                                                            esContableProp={esContable}
                                                            callbackPropuesta={obtenerTotalPropuesta}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(renInfo.ID_ESTADO === 16 || gastoSeleccionado.ID_ESTADO === 19) && (esAdmin || mismoUsuario) && (
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2 text-right">
                                            <PrimaryButton
                                                text={gastoSeleccionado.ID > 0 ? "Modificar Gasto" : "Agregar Gasto"}
                                                onClick={() => validarCamposGasto()}
                                                className="mr-2"
                                            />
                                            <PrimaryButton
                                                text="Limpiar"
                                                onClick={() => {
                                                    setGastoSeleccionado(gastoLimpio);
                                                    setIdOportunidad(0);
                                                    setSpRelativeSite("");
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                                <ListaGastos
                                    listaGastos={gastosRendicion}
                                    acciones={callbackAcciones}
                                    formatNumber={formatNumber}
                                    valorTotal={valorTotalGastos}
                                    montoFondo={renInfo.MONTO_FONDO}
                                    idTipoRendicion={renInfo.ID_TIPO_RENDICION}
                                    estadoRendicion={renInfo.ID_ESTADO}
                                    esAdminProp={esAdmin}
                                    esGestionProp={esGestion}
                                    esValidadorProp={esValidador}
                                    esContableProp={esContable}
                                    mismoUsuario={mismoUsuario}
                                />
                            </React.Fragment>
                        ) : (
                            <div className="mt-4 card sinAcceso text-primary">
                                <div className="card-header">Atención</div>
                                <div className="card-body">
                                    <h6>
                                        <Glyphicon glyph="info-sign" className="mr-1" /> Debe guardar la rendicion para poder agregar gastos y
                                        boletas.
                                    </h6>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <DialogosRendiciones
                    dialogoEliminar={dialogoEliminar}
                    dialogoEliminarConfirmardo={dialogoEliminarConfirmardo}
                    dialogoEliminarError={dialogoEliminarError}
                    dialogoEnviar={dialogoEnviar}
                    dialogoGuardar={dialogoGuardar}
                    dialogoValidar={dialogoValidar}
                    dialogoConfirmaGuardar={dialogoConfirmaGuardar}
                    rendicion={renInfo}
                    gasto={gastoSeleccionado}
                    totalAdjuntos={totalAdjuntos}
                    esAdministrativo={esAdministrativo}
                    callbackDialogos={callbackDialogos}
                    dialogoEliminarGasto={dialogoEliminarGasto}
                    dialogoEliminarConfirmarGasto={dialogoEliminarConfirmarGasto}
                    dialogoEliminarErrorGasto={dialogoEliminarErrorGasto}
                    requiereMesAnioUso={requiereMesAnioUso}
                />
                <DialogosEstadoRendiciones
                    dialogoAprobar={dialogoAprobar}
                    dialogoRechazar={dialogoRechazar}
                    dialogoMotivoRechazo={dialogoMotivoRechazo}
                    motivoRechazo={gastoSeleccionado.MOTIVO_RECHAZO}
                    callbackDialogos={callbackDialogos}
                    textOnChange={textFieldOnChange}
                    dialogoContabilizar={dialogoContabilizar}
                    dialogoValidarGasto={dialogoValidarGasto}
                    estadoGasto={gastoSeleccionado.ID_ESTADO}
                />
            </div>
        </React.Fragment>
    );
};

export default AgregarVerRendicion;
