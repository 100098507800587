import React, { useState } from "react";
import "../../custom.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import "./PlanificacionProyecto.css";
import { GestionProyectoEntity } from "../../controles/entidades/GestionProyecto";
import { GestionOportunidad } from "../../controles/entidades/GestionOportunidad";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib-commonjs/DatePicker";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Accordion } from "../../controles/Accordion/Accordion";
import Spinner from "../../controles/Spinner/Spinner";
import { ComboBox, Stack, Checkbox } from "office-ui-fabric-react";
import { IIconProps } from "office-ui-fabric-react";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { Ingreso } from "../../controles/entidades/SimuladorPresupuesto";
import { SimuladorPresupuesto, IngresoData, IngresoTercerosData, IngresoGastosData } from "../../controles/entidades/SimuladorPresupuesto";
import {
    IngresoDirectoData_Plan,
    IngresoTercerosData_Plan,
    IngresoGastosData_Plan,
    IngresoDirectoTotal_Plan,
    IngresoTercerosTotal_Plan,
    IngresoGastosTotal_Plan,
    IngresoDirectoData_Fore,
    IngresoTercerosData_Fore,
    IngresoGastosData_Fore,
    IngresoDirectoTotal_Fore,
    IngresoTercerosTotal_Fore,
    IngresoGastosTotal_Fore,
    Venta,
    Planificacion,
    Forecast,
} from "../../controles/entidades/Planificacion";
import {
    Reserva,
    ArrayReserva,
    Consultores,
    Plan,
    Roles,
    ConsultoresList,
    ArrayGastos,
    ArrayIngresos_Plan,
    ArrayRecuperables,
    ChecklistCierre,
} from "../../controles/entidades/Planificacion";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { fetchEngine } from "../../fetchData";
import IngresosDirectosVenta from "./Venta/IngresosDirectosVenta";
import IngresosTercerosVenta from "./Venta/IngresosTercerosVenta";
import IngresosGastosVenta from "./Venta/GastosVenta";
import IngresosDirectosPlanificacion from "./Planificado/IngresosDirectosPlanificacion";
import IngresosTercerosPlanificacion from "./Planificado/IngresosTercerosPlanificacion";
import IngresosGastosPlanificacion from "./Planificado/GastosPlanificacion";
import IngresosDirectosForecast from "./Forecast/IngresosDirectosForecast";
import IngresosTercerosForecast from "./Forecast/IngresosTercerosForecast";
import IngresosGastosForecast from "./Forecast/GastosForecast";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Staffing from "../../components/Staffing/Staffing";
import { MessageBar, MessageBarType } from "@fluentui/react";
import NumberFormat from "react-number-format";
import moment from "moment";
import BotonAyuda from "../BotonAyuda/BotonAyuda";
initializeIcons();

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],

    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],

    shortDays: ["D", "L", "M", "M", "J", "V", "S"],

    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};
const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});
const moneda: IIconProps = { iconName: "AllCurrency" };
const porcentaje: IIconProps = { iconName: "CalculatorPercentage" };
export class PlanificacionProyectoProps { }
export class PlanificacionProyectoState {
    tipoModulo?: string;
    esExtension?: boolean;
    id_extension?: string;
    id_simulacion?: number;
    contador?: number;
    pry_info!: GestionProyectoEntity;
    op_info!: GestionOportunidad;
    sim_info!: SimuladorPresupuesto;

    venta!: Venta;
        ingresosDirectosVenta: IngresoData[] = [];
    ingresosTercerosVenta: IngresoTercerosData[] = [];
    ingresosGastosVenta: IngresoGastosData[] = [];
    actividadRolesVenta: Ingreso[] = [];

    actividadTercerosVenta: any[] = [];
    actividadGastosVenta: any[] = [];
    opciones_unidad_aux?: any[];
    opciones_roles_aux?: any[];
    opciones_roles_plan: any[] = [];
    opciones_roles?: any[];
    opciones_item?: any[];
    opciones_monedas?: any[];
    opciones_cttomarco_aux?: any[];
    opciones_cargo_directos: any[] = [];
    opciones_categoria?: any[];
    horasxdia!: number;

    planificacion!: Planificacion;
        
    ingresosDirectosPlanificacion: IngresoDirectoData_Plan[] = [];
    arrayReserva: ArrayReserva[] = [];
    ingresosTercerosPlanificacion: IngresoTercerosData_Plan[] = [];
    ingresosGastosPlanificacion: IngresoGastosData_Plan[] = [];
    arrayGastos: ArrayGastos[] = [];
    arrayIngresos_Plan: ArrayIngresos_Plan[] = [];

    actividadRolesPlanificacion: IngresoDirectoTotal_Plan[] = [];
    actividadTercerosPlanificacion: IngresoTercerosTotal_Plan[] = [];
    actividadGastosPlanificacion: IngresoGastosTotal_Plan[] = [];

    forecast!: Forecast;
        
    ingresosDirectosForecast: IngresoDirectoData_Fore[] = [];
    arrayReserva_Fore: ArrayReserva[] = [];
    arrayReserva_Fore_Cerrados: ArrayReserva[] = [];
    ingresosTercerosForecast: IngresoTercerosData_Fore[] = [];
    ingresosGastosForecast: IngresoGastosData_Fore[] = [];
    arrayGastos_Fore: ArrayGastos[] = [];
    arrayRecuperables_Fore: ArrayRecuperables[] = [];
    arrayIngresos_Fore: ArrayIngresos_Plan[] = [];

    actividadRolesForecast: IngresoDirectoTotal_Fore[] = [];
    actividadTercerosForecast: IngresoTercerosTotal_Fore[] = [];
    actividadGastosForecast: IngresoGastosTotal_Fore[] = [];

    estadodelformulario?: boolean;
    opciones_simulacion?: any[];
    opciones_proyectos?: any[];
    opciones_cliente?: any[];
    opciones_estados?: any[];
    hasID?: boolean;
    plnID: number = 0;
    esNewProyect?: boolean;
    disabledInicial?: boolean;
    moneda_cto?: string;
    abrirPanel: boolean = false;
    ocultarFijado: boolean = false;
    dialogoSalir?: boolean;

    first_year: number = 0;
    last_year: number = 0;
    total_year: any[] = [];

    plan_info!: Plan;
    ingreso_directo: IngresoDirectoData_Plan[] = [];

    consultoresList: Consultores[] = [];
    checklistCierre: ChecklistCierre[] = [];
    seleccionChecklistCierre: number[] = [];
    validacionProyecto: boolean = true;
    cargarGuardado: boolean = true;
    guardadoOK: boolean = true;
    guardadoNoOK: boolean = true;
    errorMessage: string = "";

    clientID: string = "";

    listadoRoles_itfc: Roles[] = [];
    listadoConsultores_itfc: ConsultoresList[] = [];

    cantidad_semanas: number | undefined;
    cantidad_meses: number | undefined;

    cantidad_semanas_fr: number | undefined;
    cantidad_meses_fr: number | undefined;

    fecha_inicio_mayor?: boolean;
    fecha_fin_menor?: boolean;

    dialogoCerrarMes: boolean = true;
    dialogoCerrarEjecucion: boolean = true;
    dialogoVolverEjecucion: boolean = true;
    dialogoCerrarAdmin: boolean = true;
    dialogoLiberarSellos: boolean = true;

    ingresos_terceros_total: number = 0;

    esAdmin?: boolean;
    esUsuario?: boolean;
    esGestion?: boolean;
    esValidador?: boolean;

    guardarDisabled: boolean = false;
    sellarDisabled: boolean = false;
    cerrarMesDisabled: boolean = false;
    updateCalendario: boolean = false;

    totalNeto: number = 0;
    entroForecast: boolean = false;
    todosMesesCerrados: boolean = true;
    tokenAPI: string = "";
    
    /*xventa_ingreso: number = 0;
    xventa_costo: number = 0;
    xplanificacion_ingreso: number = 0;
    xplanificacion_costo: number = 0;
    xforecast_ingreso: number = 0;
    xforecast_costo: number = 0;*/
}

export class PlanificacionProyecto extends React.Component<PlanificacionProyectoProps, PlanificacionProyectoState> {
    constructor(props: any) {
        super(props);
        let pathName = window.location.pathname;
        let params = pathName.split("/");
        let hasID = params.length > 2;

        this.state = {
            /*xventa_ingreso: 0,
            xventa_costo: 0,
            xplanificacion_ingreso: 0,
            xplanificacion_costo: 0,
            xforecast_ingreso: 0,
            xforecast_costo: 0,*/
            tipoModulo: "Planificado",
            esExtension: false,
            id_extension: "",
            id_simulacion: 0,
            contador: 0,
            pry_info: {
                NOMBRE: "",
                DESCRIPCION: "",
                ID: 0,
                ID_ESTADO: 1,
                ID_ESTADO_PROY: 0,
                ID_UNIDAD: 0,
                RESPONSABLE_LOGIN: "",
                RESPONSABLE_DISPLAY: "",
                JEFE_PROYECTO_DISPLAY: "",
                JEFE_PROYECTO_LOGIN: "",
                RESPONSABLE_GASTOS_DISPLAY: "",
                RESPONSABLE_GASTOS_LOGIN: "",
                RESPONSABLE_HORAS_DISPLAY: "",
                RESPONSABLE_HORAS_LOGIN: "",
                ASISTENTE_DISPLAY: "",
                ASISTENTE_LOGIN: "",
                ID_LINEA: 0,
                ID_SUBLINEA: 0,
                PROBABILIDAD: 0,
                FECHA_APERTURA: undefined,
                FECHA_ENVIO_PROPUESTA: undefined,
                ID_CLIENTE: 0,
                COMPRADORES: "",
                AREA_CLIENTE: "",
                ID_PAIS: 0,
                ID_OFICINA: 0,
                NOMBRE_CONTACTO: "",
                MONTO_ESTIMADO: 0,
                ID_CONTRATO_MARCO: 0,
                RENTABILIDAD_ESPERADA: 0,
                ID_VALORIZACION: 0,
                MONTO_ORDEN_COMPRA: 0,
                RESPONSABLE_PROYECTO_LOGIN: "",
                RESPONSABLE_PROYECTO_DISPLAY: "",
                LICITACION: false,
                REQUIERE_ACREDITACION: false,
                FECHA_ESTIMADA_INICIO: undefined,
                FECHA_ESTIMADA_FIN: undefined,
                ID_MONEDA: 0,
                LECCION_APRENDIDA: "",
                COMENTARIOS: "",
                ID_MOTIVOS: 0,
                PRINCIPALES_ELEMENTOS: [],
                MOTIVOS: [],
                COMPETIDORES: [],
                COLABORADORES: [],
                MAIL: "",
                TELEFONO: 0,
                FECHA_INICIO: undefined,
                FECHA_TERMINO: undefined,
                INGRESO_VENTA: 0,
                COSTO_VENTA: 0,
                RESULTADO: 0,
                RENTABILIDAD: 0,
                ID_ORIGINAL: 0,
                ID_EXTENSION: 0,
                EXTENSION: false,
                OPORTUNIDAD_NEGOCIO: "",
                OBJETIVO_PROYECTO: "",
                ENTREGABLES: "",
                ALCANCE: "",
                SOLUCION: "",
                BENEFICIOS: "",
                token: "",
            },
            op_info: {
                NOMBRE: "",
                DESCRIPCION: "",
                ID: 0,
                ID_ESTADO: 1,
                ID_ESTADO_PROY: 0,
                ID_UNIDAD: 0,
                RESPONSABLE_LOGIN: "",
                RESPONSABLE_DISPLAY: "",
                ID_LINEA: 0,
                ID_SUBLINEA: 0,
                PROBABILIDAD: 0,
                FECHA_CREACION: undefined,
                FECHA_MODIFICACION: undefined,
                FECHA_APERTURA: undefined,
                FECHA_ENVIO_PROPUESTA: undefined,
                ID_CLIENTE: 0,
                COMPRADORES: "",
                AREA_CLIENTE: "",
                ID_PAIS: 0,
                ID_OFICINA: 0,
                NOMBRE_CONTACTO: "",
                MONTO_ESTIMADO: 0,
                ID_CONTRATO_MARCO: 0,
                RENTABILIDAD_ESPERADA: 0,
                ID_VALORIZACION: 0,
                MONTO_ORDEN_COMPRA: 0,
                RESPONSABLE_PROYECTO_LOGIN: "",
                RESPONSABLE_PROYECTO_DISPLAY: "",
                LICITACION: false,
                REQUIERE_ACREDITACION: false,
                FECHA_ESTIMADA_INICIO: undefined,
                FECHA_ESTIMADA_FIN: undefined,
                ID_MONEDA: 0,
                LECCION_APRENDIDA: "",
                COMENTARIOS: "",
                ID_MOTIVOS: 0,
                PRINCIPALES_ELEMENTOS: [],
                MOTIVOS: [],
                COMPETIDORES: [],
                COLABORADORES: [],
                MAIL: "",
                TELEFONO: 0,
                FECHA_INICIO: undefined,
                FECHA_TERMINO: undefined,
                INGRESO_VENTA: 0,
                COSTO_VENTA: 0,
                RESULTADO: 0,
                RENTABILIDAD: 0,
                ID_ORIGINAL: 0,
                ID_EXTENSION: 0,
                EXTENSION: false,
                token: "",
            },
            sim_info: {
                ID: 0,
                ID_OPORTUNIDAD: 0,
                NOMBRE_PROYECTO: "",
                FECHA_DESDE: undefined,
                FECHA_HASTA: undefined,
                UF: 0,
                PESOS: 0,
                USD: 0,
                AUD: 0,
                SOLES: 0,
                HORAS_DIA: 0,
                ID_TIPO_CONTRATO: 0,
                ID_UNIDAD: 0,
                ID_CONTRATO_MARCO: 0,
                INGRESO_VENTA: 0,
                INGRESO_VENTA_DCTO: 0,
                COSTO_VENTA: 0,
                RESULTADO: 0,
                RESULTADO_DCTO: 0,
                RENTABILIDAD: 0,
                DESCUENTO_VOLUMEN: 0,
                FINDER_FEE: 0,
                TOTAL_HORAS: 0,
                TARIFA_PROMEDIO: 0,
                ID_MONEDA: 0,
                INGRESOS_DIRECTOS: [],
                INGRESOS_TERCEROS: [],
                GASTOS: [],
                CREADO_POR: "",
                MODIFICADO_POR: "",
                NOMBRE: "",
                ID_ESTADO: 0,
                MONEDA: "",
                INGRESO_VENTA_CONVERSION: 0,
                COSTO_VENTA_CONVERSION: 0,
                RESULTADO_CONVERSION: 0,
                RENTABILIDAD_CONVERSION: 0,
                TARIFA_PROMEDIO_CONVERSION: 0,
                INGRESO_VENTA_DCTO_CONVERSION: 0,
            },

            ingresosDirectosVenta: [],
            ingresosTercerosVenta: [],
            ingresosGastosVenta: [],
            actividadRolesVenta: [],
            actividadTercerosVenta: [],
            actividadGastosVenta: [],
            opciones_roles_aux: [],
            opciones_roles_plan: [],
            opciones_cargo_directos: [],
            opciones_unidad_aux: [],
            opciones_roles: [],
            opciones_item: [],
            opciones_monedas: [],
            opciones_cttomarco_aux: [],
            opciones_categoria: [],
            horasxdia: 0,

            ingresosDirectosPlanificacion: [],
            arrayReserva: [],
            ingresosTercerosPlanificacion: [],
            ingresosGastosPlanificacion: [],
            arrayGastos: [],
            arrayIngresos_Plan: [],
            actividadRolesPlanificacion: [],
            actividadTercerosPlanificacion: [],
            actividadGastosPlanificacion: [],

            ingresosDirectosForecast: [],
            arrayReserva_Fore: [],
            arrayReserva_Fore_Cerrados: [],
            ingresosTercerosForecast: [],
            ingresosGastosForecast: [],
            arrayGastos_Fore: [],
            arrayRecuperables_Fore: [],
            arrayIngresos_Fore: [],
            actividadRolesForecast: [],
            actividadTercerosForecast: [],
            actividadGastosForecast: [],

            opciones_simulacion: [],
            opciones_proyectos: [],
            opciones_cliente: [],
            hasID: hasID,
            plnID: hasID ? parseInt(params[2]) : 0,
            esNewProyect: false,
            disabledInicial: true,
            moneda_cto: "CLP",
            abrirPanel: false,
            ocultarFijado: false,
            dialogoSalir: true,

            first_year: 0,
            last_year: 0,
            total_year: [],

            plan_info: {
                ID: 0,
                ID_PROYECTO: 0,
                ID_CLIENTE: 0,
                TIPO_MODULO: "Planificado",
                CREADO_POR: "",
                MODIFICADO_POR: "",
                ID_OPORTUNIDAD: 0,
                ID_ESTADO: 0,
                ID_UNIDAD: 0,
                MONEDA_CTTO: "",
            },

            venta: {
                ID: 0,
                ID_PLAN: 0,
                FECHA_INICIO: undefined,
                FECHA_TERMINO: undefined,
                INGRESO_VENTA_VENTA: 0,
                COSTO_VENTA_VENTA: 0,
                RESULTADO_VENTA: 0,
                RENTABILIDAD_VENTA: 0,
            },
            
            planificacion: {
                ID: 0,
                ID_PLAN: 0,
                FECHA_INICIO: undefined,
                FECHA_TERMINO: undefined,
                INGRESO_VENTA_PLAN: 0,
                COSTO_VENTA_PLAN: 0,
                RESULTADO_PLAN: 0,
                RENTABILIDAD_PLAN: 0,
                INGRESOS_DIRECTOS: [],
                INGRESOS_TERCEROS: [],
                GASTOS: [],
                ACTIVIDADES: [],
                ARRAY_RESERVA: [],
                ID_ESTADO: 0,
                MODIFICADO_POR: "",
                ID_PROYECTO: 0,
                TOTAL_INGRESOS_DIST: 0,
            },
                        
            forecast: {
                ID: 0,
                ID_PLAN: 0,
                FECHA_INICIO: undefined,
                FECHA_TERMINO: undefined,
                INGRESO_VENTA_FORECAST: 0,
                COSTO_VENTA_FORECAST: 0,
                RESULTADO_FORECAST: 0,
                RENTABILIDAD_FORECAST: 0,
                INGRESOS_DIRECTOS: [],
                INGRESOS_TERCEROS: [],
                GASTOS: [],
                ACTIVIDADES: [],
                ID_ESTADO: 0,
                MODIFICADO_POR: "",
                ID_PROYECTO: 0,
                SELLADO: false,
                TOTAL_INGRESOS_DIST: 0,
            },
                        
            ingreso_directo: [],
            consultoresList: [],
            checklistCierre: [],
            seleccionChecklistCierre: [],
            validacionProyecto: true,
            cargarGuardado: true,
            guardadoOK: true,
            guardadoNoOK: true,
            errorMessage: "",

            clientID: process.env.REACT_APP_SCOPE_API ? process.env.REACT_APP_SCOPE_API : "",

            listadoRoles_itfc: [],
            listadoConsultores_itfc: [],

            cantidad_semanas: 0,
            cantidad_meses: 0,

            cantidad_semanas_fr: 0,
            cantidad_meses_fr: 0,

            fecha_inicio_mayor: true,
            fecha_fin_menor: true,

            dialogoCerrarMes: true,
            dialogoCerrarEjecucion: true,
            dialogoVolverEjecucion: true,
            dialogoCerrarAdmin: true,
            dialogoLiberarSellos: true,

            ingresos_terceros_total: 0,
            esAdmin: false,
            esUsuario: false,
            esGestion: false,
            esValidador: false,

            guardarDisabled: false,
            sellarDisabled: false,
            cerrarMesDisabled: false,
            updateCalendario: false,

            totalNeto: 0,
            entroForecast: false,
            todosMesesCerrados: true,
            tokenAPI: "",
        };
        if (this.state.plnID > 0) {
            let op_info = this.state.pry_info;
            let pln = this.state.plan_info;

            op_info.ID = this.state.plnID;
            pln.ID_PROYECTO = this.state.plnID;

            this.setState({
                pry_info: op_info,
                hasID: true,
                esNewProyect: true,
                plan_info: pln,
            });
        }

        this.redirigirGestion = this.redirigirGestion.bind(this);
        this.callbackFijado = this.callbackFijado.bind(this);
        this._onFormatDate = this._onFormatDate.bind(this);
        this._onChangeProyecto = this._onChangeProyecto.bind(this);

        this.callbackIngresos = this.callbackIngresos.bind(this);
        this.callbackReserva = this.callbackReserva.bind(this);
        this.callbackArrayGastos = this.callbackArrayGastos.bind(this);
        this.callbackIngresosTerceros = this.callbackIngresosTerceros.bind(this);
        this.callbackIngresosGastos = this.callbackIngresosGastos.bind(this);

        this.callbackIngresosForecast = this.callbackIngresosForecast.bind(this);
        this.callbackReservaForecast = this.callbackReservaForecast.bind(this);
        this.callbackArrayGastosForecast = this.callbackArrayGastosForecast.bind(this);
        this.callbackArrayRecuperablesForecast = this.callbackArrayRecuperablesForecast.bind(this);
        this.callbackIngresosTercerosForecast = this.callbackIngresosTercerosForecast.bind(this);
        this.callbackIngresosGastosForecast = this.callbackIngresosGastosForecast.bind(this);

        this.guardarPlan = this.guardarPlan.bind(this);
        this.guardarForecast = this.guardarForecast.bind(this);
        this.callbackReserva = this.callbackReserva.bind(this);
        this.cargandoGuardado = this.cargandoGuardado.bind(this);
    }

    componentDidMount() {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                const tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                this.setState({ tokenAPI: d }, () => {
                    this.verificarAdmin();
                    this.verificarUsuario();
                    this.verificarGestion();
                    this.verificarValidador();
                    this.obtenerOportunidades();
                    this.obtenerValores();
                    this.obtenerPRY();
                    this.obtenerSimulaciones();
                    //this.CalcularPlanificacion();
                    //this.CalcularForecast();
                });
            });
        }
    }

    //CONSULTAS PERFILAMIENTO
    public verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esAdmin: result.ok });
            });
    };

    public verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esUsuario: result.ok });
            });
    };

    public verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esGestion: result.ok });
            });
    };

    public verificarValidador = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esValidador: result.ok });
            });
    };

    public async liberarSellos() {
        let fecha_actual = undefined;

        fetchEngine
            .putAPI(`/api/planificacion/${this.state.forecast.ID}`, fecha_actual, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                this.setState({ cargarGuardado: true });
                if (resultado.ok) {
                    this.setState({ cargarGuardado: false, guardadoOK: true });

                    setTimeout(() => {
                        this.setState({
                            cargarGuardado: true,
                            guardadoOK: false,
                        });
                        setTimeout(() => {
                            this.setState({
                                cargarGuardado: true,
                                guardadoOK: true,
                            });
                        }, 1000);
                    }, 1000);

                    window.location.reload();
                } else {
                    this.setState({ cargarGuardado: true, guardadoNoOK: false });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // PLANIFICACION
    public callbackIngresos = (ingresos: any) => {
        this.setState({ ingresosDirectosPlanificacion: ingresos }, () => {
            this.CalcularPlanificacion();
        });
    };

    public callbackReserva(reserva: any) {
        this.setState({ arrayReserva: [...reserva] });
    }

    public callbackActividades = (actividades: any) => {
        this.setState({ actividadRolesPlanificacion: [...actividades] }, () => {
            this.CalcularPlanificacion();
        });
    };

    public callbackIngresosTerceros = (ingresos: any) => {
        this.setState({ ingresosTercerosPlanificacion: [...ingresos] }, () => {
            this.CalcularPlanificacion();
        });
    };

    public callbackActividadesTerceros = (actividades: any) => {
        this.setState({ actividadTercerosPlanificacion: [...actividades] }, () => {
            this.CalcularPlanificacion();
        });
    };

    public callbackArrayGastos(reserva: any) {
        this.setState({ arrayGastos: [...reserva] });
    }

    public callbackIngresosGastos = (ingresos: any) => {
        this.setState({ ingresosGastosPlanificacion: [...ingresos] }, () => {
            this.CalcularPlanificacion();
        });
    };

    public callbackActividadesGastos = (actividades: any) => {
        this.setState({ actividadGastosPlanificacion: [...actividades] }, () => {
            this.CalcularPlanificacion();
        });
    };

    private CalcularVenta(){
        //this.IngresoVenta();
        //this.CostoVenta();
        //this.ResultadoVenta();
        //this.RentabilidadVenta();
    }

    public IngresoVenta() {
        let venta = this.state.venta;
        let totalIngresoDistribuido = venta.INGRESO_VENTA_VENTA;
        let totalIngresosDirecto = (this.state.ingresosDirectosVenta || []).reduce((acumulado, ingreso) => acumulado + (ingreso.TARIFA_HH * ingreso.HORAS), 0);
        let totalIngresoTercero = (this.state.ingresosTercerosVenta || []).reduce((acumulado, ingreso) => acumulado + (ingreso.COSTO_UNITARIO * ingreso.CANTIDAD * (1 + (ingreso.RENTABILIDAD / 100))), 0);
        let totalGastoRecuperable = (this.state.ingresosGastosVenta || []).filter((ingreso: any) => ingreso.REEMBOLSABLE === 1).reduce((acumulado, ingreso) => acumulado + (ingreso.VALOR_RECUPERAR), 0);
        let total = totalIngresosDirecto;
        venta.INGRESO_VENTA_VENTA = parseFloat(total.toFixed(2));

        this.setState({
            venta: venta,
        });
    }

    public CostoVenta() {
        let venta = this.state.venta;
        let totalCostoDirecto = (this.state.ingresosDirectosVenta || []).reduce((acumulado, costo) => acumulado + (costo.COSTO_HH * costo.HORAS), 0);
        let totalCostoTercero = (this.state.ingresosTercerosVenta || []).reduce((acumulado, costo) => acumulado + (costo.COSTO_UNITARIO * costo.CANTIDAD), 0);
        let totalGasto = (this.state.ingresosGastosVenta || []).filter((ingreso: any) => ingreso.REEMBOLSABLE === 0).reduce((acumulado, ingreso) => acumulado + (ingreso.COSTO_UNITARIO * ingreso.CANTIDAD), 0);
        let total = totalCostoDirecto + totalCostoTercero + totalGasto;
        venta.COSTO_VENTA_VENTA = parseFloat(total.toFixed(2));

        this.setState({ venta: venta });
    }

    public ResultadoVenta() {
        let venta = this.state.venta;
        let totalIngreso = (venta.INGRESO_VENTA_VENTA || 0);
        let totalCosto = (venta.COSTO_VENTA_VENTA || 0);
        let total = totalIngreso - totalCosto;
        venta.RESULTADO_VENTA = parseFloat(total.toFixed(2));
        
        this.setState({ venta: venta });
    }

    public RentabilidadVenta() {
        let venta = this.state.venta;
        let totalIngreso = (venta.INGRESO_VENTA_VENTA || 0);
        let totalResultado = (venta.RESULTADO_VENTA || 0);
        let total = totalIngreso !== 0 ? ((totalResultado / totalIngreso) * 100) : 0;
        venta.RENTABILIDAD_VENTA = parseFloat(total.toFixed(2));

                this.setState({ venta: venta });
    }

    private CalcularPlanificacion(){
        this.IngresoPlanificacion();
        this.CostoPlanificacion();
        this.ResultadoPlanificacion();
        this.RentabilidadPlanificacion();
    }

    public IngresoPlanificacion() {
        let plan = this.state.planificacion;
        let totalIngresoDistribuido = plan.TOTAL_INGRESOS_DIST;
        let totalIngresosDirecto = 0;
        let totalIngresoTercero = 0;
        let totalGastoRecuperable = 0;

        this.state.ingresosDirectosPlanificacion.forEach((ingreso: IngresoDirectoData_Plan) => { totalIngresosDirecto += ingreso.TOTAL_INGRESOS });
        this.state.ingresosTercerosPlanificacion.forEach((ingreso: IngresoTercerosData_Plan) => { totalIngresoTercero += ingreso.INGRESO });
        this.state.actividadGastosPlanificacion.forEach((ingreso: IngresoGastosTotal_Plan) => { totalGastoRecuperable += ingreso.VALOR_RECUPERAR });

        let total = totalIngresoDistribuido;
        plan.INGRESO_VENTA_PLAN = total;
        
        this.setState({
            planificacion: plan,
        });
    }

    public CostoPlanificacion() {
        let plan = this.state.planificacion;
        let totalCostoDirecto = 0;
        let totalCostoTercero = 0;
        let totalGasto = 0;

        this.state.ingresosDirectosPlanificacion.forEach((costo: IngresoDirectoData_Plan) => { totalCostoDirecto += costo.COSTO_HH * costo.TOTAL_HORAS });
        this.state.ingresosTercerosPlanificacion.forEach((costo: IngresoTercerosData_Plan) => { totalCostoTercero += costo.COSTO_UNITARIO * costo.CANTIDAD });
        this.state.ingresosGastosPlanificacion.forEach((costo: IngresoGastosData_Plan) => { totalGasto += costo.COSTO_UNITARIO * costo.CANTIDAD });

        let total = totalCostoDirecto + totalCostoTercero + totalGasto;
        plan.COSTO_VENTA_PLAN = total;

        this.setState({ planificacion: plan });
    }

    public ResultadoPlanificacion() {
        let plan = this.state.planificacion;
        let totalIngreso = (plan.INGRESO_VENTA_PLAN || 0);
        let totalCosto = (plan.COSTO_VENTA_PLAN || 0);
        let total = totalIngreso - totalCosto;
        plan.RESULTADO_PLAN = parseFloat(total.toFixed(2));
                
        this.setState({ planificacion: plan });
    }

    public RentabilidadPlanificacion() {
        let plan = this.state.planificacion;
        let totalIngreso = (plan.INGRESO_VENTA_PLAN || 0);
        let totalResultado = (plan.RESULTADO_PLAN || 0);
        let total = totalIngreso !== 0 ? ((totalResultado / totalIngreso) * 100) : 0;
        plan.RENTABILIDAD_PLAN = parseFloat(total.toFixed(2));
        
        this.setState({ planificacion: plan });
    }

    // FORECAST
    public callbackIngresosForecast = (ingresos: any) => {
        this.setState({ ingresosDirectosForecast: [...ingresos] }, () => {
            this.CalcularForecast();
        });
    };

    public callbackReservaForecast(reserva: any) {
        this.setState({ arrayReserva_Fore: [...reserva] }, () => {
            this.CalcularForecast();
        });
    }

    public callbackActividadesForecast = (actividades: any) => {
        this.setState({ actividadRolesForecast: [...actividades] }, () => {
            this.CalcularForecast();
        });
    };

    public callbackIngresosTercerosForecast = (ingresos: any, adicional?: number) => {
        this.setState({ ingresosTercerosForecast: [...ingresos] }, () => {
            this.CalcularForecast();
        });
    };

    public callbackActividadesTercerosForecast = (actividades: any) => {
        this.setState({ actividadTercerosForecast: [...actividades] }, () => {
            this.CalcularForecast();
        });
    };

    public callbackArrayGastosForecast(reserva: ArrayGastos[]) {
        this.setState({ 
            arrayGastos_Fore: [...reserva] 
        }, () => {
            this.CalcularForecast();
        });
    }

    public callbackArrayRecuperablesForecast(reserva: any) {
        this.setState({ arrayRecuperables_Fore: [...reserva] }, () => {
            this.CalcularForecast();
        });
    }

    public callbackIngresosGastosForecast = (ingresos: any) => {
        this.setState({ ingresosGastosForecast: [...ingresos] }, () => {
            this.CalcularForecast();
        });
    };

    public callbackActividadesGastosForecast = (actividades: any) => {
        let total_neto = 0;
        total_neto = actividades !== undefined ? actividades.reduce((a: any, b: any) => a + b.TOTAL_NETO, 0) : 0;

        this.setState(
            {
                actividadGastosForecast: [...actividades],
                totalNeto: total_neto,
            },
            () => {
                this.CalcularForecast();
            }
        );
    };
    
    private CalcularForecast(no_adicional?: number){
        this.IngresoVentaForecast(no_adicional);
        this.CostoVentaForecast();
        this.ResultadoForecast();
        this.RentabilidadForecast();
    }

    public IngresoVentaForecast(no_adicional?: number) {
        let adicional = (no_adicional || 0) > 0 ? true : false;
        let fore = this.state.forecast;
        let totalIngresoDistribuido = fore.TOTAL_INGRESOS_DIST;
        let totalIngresoDirecto = 0;
        let totalIngresoTercero = 0;
        let totalIngresoAdicional = 0;
        let totalGastoRecuperable = 0;

        this.state.ingresosDirectosForecast.forEach((ingreso: IngresoDirectoData_Fore) => { totalIngresoDirecto += ingreso.TOTAL_INGRESOS; });
        this.state.ingresosTercerosForecast.forEach((ingreso: IngresoTercerosData_Fore) => { totalIngresoTercero += ingreso.INGRESO });
        this.state.ingresosTercerosForecast.forEach((ingreso: IngresoTercerosData_Fore) => { totalIngresoTercero += ingreso.NO_ADICIONAL === true ? 0 : (ingreso.COSTO_UNITARIO * ingreso.CANTIDAD * (1 + (ingreso.MARGEN / 100))) });
        this.state.actividadGastosForecast.forEach((ingreso: IngresoGastosTotal_Fore) => { totalGastoRecuperable += ingreso.RECUPERABLE_DISTRIBUIDO });

        let total = totalIngresoDistribuido + totalGastoRecuperable;
        fore.INGRESO_VENTA_FORECAST = total;
        
        this.setState({
            forecast: fore,
            ingresos_terceros_total: adicional ? totalIngresoAdicional : 0,
        });
    }

    public CostoVentaForecast() {
        let fore = this.state.forecast;
        let totalCostoDirecto: number = 0;
        let totalCostoTercero = 0;
        let totalGasto = 0;
        
        this.state.ingresosDirectosForecast.forEach((costo: IngresoDirectoData_Fore) => { totalCostoDirecto += (costo.COSTO_HH * costo.TOTAL_HORAS) });
        this.state.ingresosTercerosForecast.forEach((costo: IngresoTercerosData_Fore) => { totalCostoTercero += costo.CANTIDAD * costo.COSTO_UNITARIO});
        //this.state.actividadGastosForecast.forEach((costo: IngresoGastosTotal_Fore) => { totalGasto += costo.GASTO_DISTRIBUIDO });
        this.state.arrayGastos_Fore.forEach((costo: ArrayGastos) => { totalGasto += costo.VALOR });

        let total = totalCostoDirecto + totalCostoTercero + totalGasto;
        //console.log("totalCostoDirecto", totalCostoDirecto);
        //console.log("totalCostoTercero", totalCostoTercero);
        //console.log("totalGasto", totalGasto);

        fore.COSTO_VENTA_FORECAST = total;
        
        this.setState({ forecast: fore });
    }

    public ResultadoForecast() {
        let fore = this.state.forecast;
        let totalIngreso = (fore.INGRESO_VENTA_FORECAST || 0);
        let totalCosto = (fore.COSTO_VENTA_FORECAST || 0);
        let total = totalIngreso - totalCosto;
        fore.RESULTADO_FORECAST = parseFloat(total.toFixed(2));
        
        this.setState({ forecast: fore });
    }

    public RentabilidadForecast() {
        let fore = this.state.forecast;
        let totalIngreso = (fore.INGRESO_VENTA_FORECAST || 0);
        let totalResultado = (fore.RESULTADO_FORECAST || 0);
        let total = totalIngreso !== 0 ? ((totalResultado / totalIngreso) * 100) : 0;
        fore.RENTABILIDAD_FORECAST = parseFloat(total.toFixed(2));
        
        this.setState({ forecast: fore });
    }

    public redirigirGestion() {
        window.location.href = `/gestionproyecto/${this.state.plnID}`;
    }

    public salir() {
        window.location.href = `/`;
    }

    private _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    private async obtenerValores() {
        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    const clt: any[] = [];
                    const checklist: any[] = [];
                    if (result.data) {
                        result.data.cliente.map((a: any) => {
                            clt.push({
                                key: a.ID,
                                text: decodeURIComponent(a.TITULO),
                            });
                        });

                        result.data.checklist_cierre.map((cc: any) => {
                            if (cc.ACTIVO === true) {
                                checklist.push({
                                    key: cc.ID,
                                    text: cc.TITULO,
                                });
                            }
                        });

                        this.setState({
                            opciones_cliente: clt,
                            consultoresList: result.data.consultores,
                            checklistCierre: checklist,
                        });
                    }
                } else {
                    console.log(result.data);
                }
            });
    }
    
    public async obtenerSimulaciones() {
        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    const sim: any[] = [];
                    const car: any[] = [];
                    const car_plan: any[] = [];
                    const item: any[] = [];
                    const ctm: any[] = [];
                    const mnd: any[] = [];
                    const unnaux: any[] = [];
                    const est: any[] = [];
                    const con: any[] = [];
                    const categorias: any[] = [];
                    if (result.data) {
                        {
                            result.data.simulaciones.map((a: any) => {
                                sim.push({
                                    ID_SIMULACION: a.ID,
                                    ID_OPORTUNIDAD: a.ID_OPORTUNIDAD,
                                });
                            });
                        }
                        {
                            result.data.cargos.map((a: any) => {
                                car.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    id_contrato_marco: a.ID_CONTRATO_MARCO,
                                    id_unidad: a.ID_UNIDAD,
                                    tarifa: a.TARIFA,
                                    costo: a.COSTO,
                                });
                            });
                        }
                        {
                            result.data.cargos_plan.map((a: any) => {
                                car_plan.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    disabled: !a.ACTIVO,
                                    id_contrato_marco: a.ID_CONTRATO_MARCO,
                                    id_unidad: a.ID_UNIDAD,
                                    tarifa: a.TARIFA,
                                    costo: a.COSTO,
                                });
                            });
                        }
                        {
                            result.data.items.map((a: any) => {
                                item.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                });
                            });
                        }
                        {
                            result.data.contratos_marco.map((a: any) => {
                                ctm.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    horas: a.HORA_DIA,
                                    id_unidad: a.ID_UNIDAD_NEGOCIO,
                                    id_moneda: a.ID_MONEDA,
                                });
                            });
                        }
                        {
                            result.data.unidades_negocios.map((a: any) => {
                                unnaux.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    disabled: !a.ACTIVO,
                                    hora_dia: a.HORA_DIA,
                                });
                            });
                        }
                        {
                            result.data.monedas.map((a: any) => {
                                mnd.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                });
                            });
                        }
                        {
                            result.data.estados.map((a: any) => {
                                if (a.ID_MODULO === 3 && a.ACTIVO) {
                                    est.push({
                                        key: a.ID,
                                        text: a.TITULO,
                                        id_modulo: a.ID_MODULO,
                                        estado: a.ACTIVO,
                                    });
                                }
                            });
                        }
                        {
                            result.data.consultores.map((a: any) => {
                                con.push({
                                    key: a.ID,
                                    text: a.CONSULTOR_DISPLAY,
                                    id_unidad: a.ID_UNIDAD_NEGOCIO,
                                    id_cargo: a.ID_CARGO,
                                    id_cargo_plan: a.ID_CARGO,
                                });
                            });
                        }
                        {
                            result.data.categoria_rendicion.map((a: any) => {
                                if (a.ES_ADMINISTRATIVO === false) {
                                    categorias.push({
                                        key: a.ID,
                                        text: a.TITULO,
                                    });
                                }
                            });
                        }
                        this.setState(
                            {
                                opciones_monedas: mnd,
                                opciones_cttomarco_aux: ctm,
                                opciones_roles_aux: car,
                                opciones_roles_plan: car_plan,
                                opciones_unidad_aux: unnaux,
                                opciones_item: item,
                                opciones_simulacion: sim,
                                opciones_estados: est,
                                opciones_cargo_directos: con,
                                opciones_categoria: categorias,
                                listadoConsultores_itfc: con,
                            },
                            () => {
                                this.cargarHerencia();
                            }
                        );
                        if (this.state.opciones_cttomarco_aux != undefined) {
                            this.state.opciones_cttomarco_aux.map((a: any) => {
                                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                                    this.setState({ horasxdia: a.horas });
                                }
                            });
                        }

                        if (this.state.opciones_cttomarco_aux != undefined) {
                            this.state.opciones_cttomarco_aux.map((a: any) => {
                                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                                    this.setState({ horasxdia: a.horas });
                                    this.state.opciones_monedas!.map((b: any) => {
                                        if (b.key === a.id_moneda) {
                                            this.setState({
                                                moneda_cto: b.text,
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    }
                } else {
                    console.log(result.data);
                }
            });
    }
    
    public cargarHerencia() {
        if (this.state.sim_info.ID_UNIDAD != undefined && this.state.sim_info.ID_UNIDAD > 0 && this.state.opciones_roles_aux != undefined) {
            let opciones_roles2: any[] = [];
            this.state.opciones_roles_aux.map((a: any) => {
                if (a.id_unidad === this.state.sim_info.ID_UNIDAD) {
                    opciones_roles2.push({
                        key: a.key,
                        text: a.text,
                    });
                }
            });
            this.setState({ opciones_roles: opciones_roles2 });
        }

        if (this.state.sim_info.ID_UNIDAD != undefined && this.state.sim_info.ID_UNIDAD > 0 && this.state.opciones_roles_aux != undefined) {
            let opciones_cto2: any[] = [];
            this.state.opciones_roles_aux.map((a: any) => {
                if (a.id_unidad === this.state.sim_info.ID_UNIDAD) {
                    opciones_cto2.push({
                        key: a.key,
                        text: a.text,
                    });
                }
            });
            this.setState({ opciones_cttomarco_aux: opciones_cto2 });
        }
    }
    
    public moduloVenta() {
        let pry = this.state.pry_info;
        this.state.opciones_simulacion!.map((e: any) => {
            if (e.ID_OPORTUNIDAD === pry.ID_OPORTUNIDAD) {
                this.setState({ id_simulacion: e.ID_SIMULACION });
            }
        });
    }
    
    public async obtenerPRY() {
        let plan = this.state.plan_info;
        let venta = this.state.venta;
        let pla = this.state.planificacion;
        let fore = this.state.forecast;

        fetchEngine
            .getAPI(`/api/Planificacion/ObtenerPlanificacion/${this.state.plan_info.ID_PROYECTO}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    this.setState({ disabledInicial: false });

                    /*this.setState({
                        xventa_ingreso: resultado.data.venta_ingreso,
                        xventa_costo: resultado.data.venta_costo,
                        xplanificacion_ingreso: resultado.data.planificacion_ingreso,
                        xplanificacion_costo: resultado.data.planificacion_costo,
                        xforecast_ingreso: resultado.data.forecast_ingreso,
                        xforecast_costo: resultado.data.forecast_costo,
                    });*/

                    let pry = resultado.data.plan[0];
                    let vta = resultado.data.venta[0];
                    let pln = resultado.data.planificacion[0];
                    let fst = resultado.data.forecast[0];
                    let pry_data = resultado.data;
                    
                    plan.ID = pry.ID;
                    plan.ID_PROYECTO = pry.ID_PROYECTO;
                    plan.ID_CLIENTE = pry.ID_CLIENTE;
                    plan.CREADO_POR = pry.CREADO_POR;
                    plan.MODIFICADO_POR = pry.MODIFICADO_POR;
                    plan.ID_OPORTUNIDAD = pry.ID_OPORTUNIDAD;
                    plan.ID_ESTADO = pry.ID_ESTADO;
                    plan.ID_UNIDAD = pry.ID_UNIDAD;
                    plan.MONEDA_CTTO = pry.MONEDA_CTTO;

                    if (venta !== undefined) {
                        venta.ID = vta.ID;
                        venta.ID_PLAN = vta.ID_PLAN;
                        venta.FECHA_INICIO = vta.FECHA_INICIO != null ? new Date(vta.FECHA_INICIO) : new Date();
                        venta.FECHA_TERMINO = vta.FECHA_TERMINO != null ? new Date(vta.FECHA_TERMINO) : new Date();
                        venta.INGRESO_VENTA_VENTA = vta.INGRESO_VENTA_VENTA;
                        venta.COSTO_VENTA_VENTA = vta.COSTO_VENTA_VENTA;
                        venta.RESULTADO_VENTA = vta.RESULTADO_VENTA;
                        venta.RENTABILIDAD_VENTA = vta.RENTABILIDAD_VENTA;
                    }

                    if (pla !== undefined) {
                        pla.ID = pln.ID;
                        pla.ID_PLAN = pln.ID_PLAN;
                        pla.FECHA_INICIO = pln.FECHA_INICIO != null ? new Date(pln.FECHA_INICIO) : new Date();
                        pla.FECHA_TERMINO = pln.FECHA_TERMINO != null ? new Date(pln.FECHA_TERMINO) : new Date();
                        this.calendarioReserva();
                        pla.INGRESO_VENTA_PLAN = pln.INGRESO_VENTA_PLAN;
                        pla.COSTO_VENTA_PLAN = pln.COSTO_VENTA_PLAN;
                        pla.RESULTADO_PLAN = pln.RESULTADO_PLAN;
                        pla.RENTABILIDAD_PLAN = pln.RENTABILIDAD_PLAN;
                        pla.TOTAL_INGRESOS_DIST = pln.TOTAL_INGRESOS_DIST;
                    }

                    if (fst !== undefined) {
                        fore.ID = fst.ID;
                        fore.ID_PLAN = fst.ID_PLAN;
                        fore.FECHA_INICIO = fst.FECHA_INICIO != null ? new Date(fst.FECHA_INICIO) : new Date();
                        fore.FECHA_TERMINO = fst.FECHA_TERMINO != null ? new Date(fst.FECHA_TERMINO) : new Date();
                        fore.INGRESO_VENTA_FORECAST = fst.TOTAL_INGRESOS_DIST;
                        fore.COSTO_VENTA_FORECAST = pry_data.costo_total_fin;
                        fore.RESULTADO_FORECAST = pry_data.resultado_total_fin;
                        fore.RENTABILIDAD_FORECAST = pry_data.rentabilidad_fin;
                        fore.TOTAL_INGRESOS_DIST = fst.TOTAL_INGRESOS_DIST;
                        fore.FECHA_CIERRE_MES = fst.FECHA_CIERRE_MES;
                    }
                    
                    let i = 1;
                    pry_data.ingresos_directos_planificacion.forEach((e: any) => {
                        e.ID_TEMPORAL = i;

                        i = i + 1;
                    });

                    let i_g = 1;
                    pry_data.gastos_planificacion.forEach((e: any) => {
                        e.ID_TEMPORAL = i_g;

                        i_g = i_g + 1;
                    });

                    let i_f = 1;
                    pry_data.ingresos_directos_forecast.forEach((e: any) => {
                        e.ID_TEMPORAL = i_f;

                        i_f = i_f + 1;
                    });

                    let i_g_f = 1;
                    pry_data.gastos_forecast.forEach((e: any) => {
                        e.ID_TEMPORAL = i_g_f;

                        i_g_f = i_g_f + 1;
                    });

                    let ingresosD_Plan = pry_data.ingresos_directos_planificacion === undefined ? [] : pry_data.ingresos_directos_planificacion;
                    let ingresosT_Plan = pry_data.ingresos_terceros_planificacion === undefined ? [] : pry_data.ingresos_terceros_planificacion;
                    let gastos_Plan = pry_data.gastos_planificacion === undefined ? [] : pry_data.gastos_planificacion;

                    let idirecto_Plan: any = [];
                    let itercero_Plan: any = [];
                    let igastos_Plan: any = [];

                    let ingresosD_Venta = pry_data.ingresos_directos_venta === undefined ? [] : pry_data.ingresos_directos_venta;
                    let ingresosT_Venta = pry_data.ingresos_terceros_venta === undefined ? [] : pry_data.ingresos_terceros_venta;
                    let gastos_Venta = pry_data.gastos_venta === undefined ? [] : pry_data.gastos_venta;

                    let idirecto_Venta: any = [];
                    let itercero_Venta: any = [];
                    let igastos_Venta: any = [];

                    let ingresosD_Fore = pry_data.ingresos_directos_forecast === undefined ? [] : pry_data.ingresos_directos_forecast;
                    let ingresosT_Fore = pry_data.ingresos_terceros_forecast === undefined ? [] : pry_data.ingresos_terceros_forecast;
                    let gastos_Fore = pry_data.gastos_forecast === undefined ? [] : pry_data.gastos_forecast;

                    let idirecto_Fore: any = [];
                    let itercero_Fore: any = [];
                    let igastos_Fore: any = [];

                    let idirecto_Fore_r: any = [];
                    let itercero_Fore_r: any = [];
                    let igastos_Fore_r: any = [];

                    this.setState({
                        ingresosDirectosPlanificacion: ingresosD_Plan,
                        ingresosTercerosPlanificacion: ingresosT_Plan,
                        ingresosGastosPlanificacion: gastos_Plan,
                    });
                    this.setState({
                        ingresosDirectosVenta: ingresosD_Venta,
                        ingresosTercerosVenta: ingresosT_Venta,
                        ingresosGastosVenta: gastos_Venta,
                    });
                    this.setState({
                        ingresosDirectosForecast: ingresosD_Fore,
                        ingresosTercerosForecast: ingresosT_Fore,
                        ingresosGastosForecast: gastos_Fore,
                        totalNeto: pry_data.total_neto_final,
                    });

                    let IT_total = 0;
                    IT_total = ingresosT_Fore.reduce((a: any, b: any) => a + b.INGRESO, 0);

                    if (pry_data.actividades_planificacion.length > 0) {
                        pry_data.actividades_planificacion.map((a: any) => {
                            if (a.ID_MODULO === 1) {
                                idirecto_Plan.push({
                                    INDEX: 0,
                                    ID_MODULO: 1,
                                    ID_PLANIFICACION: 0,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    NOMBRE: a.NOMBRE,
                                    TOTAL_HORAS: 0,
                                    TARIFA_HH: 0,
                                    COSTO_HH: 0,
                                    TOTAL_INGRESOS: 0,
                                    TOTAL_COSTO: 0,
                                });
                            } else if (a.ID_MODULO === 2) {
                                itercero_Plan.push({
                                    ID_MODULO: 2,
                                    ID_SIMULACION: 0,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    index: 0,
                                    NOMBRE: a.NOMBRE,
                                    TOTAL_COSTO: 0,
                                    TOTAL_INGRESO: 0,
                                });
                            } else if (a.ID_MODULO === 3) {
                                igastos_Plan.push({
                                    ID: a.ID,
                                    ID_SIMULACION: 0,
                                    ID_MODULO: 3,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    index: 0,
                                    NOMBRE: a.NOMBRE,
                                    GASTO_TOTAL: a.GASTO_TOTAL,
                                    VALOR_RECUPERAR: 0,
                                    GASTO_DISTRIBUIDO: a.GASTO_DISTRIBUIDO,
                                });
                            }
                        });
                    }

                    if (pry_data.actividades_venta.length > 0) {
                        pry_data.actividades_venta.map((a: any) => {
                            if (a.ID_MODULO === 1) {
                                idirecto_Venta.push({
                                    INDEX: 0,
                                    ID_MODULO: 1,
                                    ID_PLANIFICACION: 0,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    NOMBRE: a.NOMBRE,
                                    TOTAL_HORAS: 0,
                                    TARIFA_HH: 0,
                                    COSTO_HH: 0,
                                    TOTAL_INGRESOS: 0,
                                    TOTAL_COSTO: 0,
                                });
                            } else if (a.ID_MODULO === 2) {
                                itercero_Venta.push({
                                    ID_MODULO: 2,
                                    ID_SIMULACION: 0,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    index: 0,
                                    NOMBRE: a.NOMBRE,
                                    TOTAL_COSTO: 0,
                                    TOTAL_INGRESO: 0,
                                });
                            } else if (a.ID_MODULO === 3) {
                                igastos_Venta.push({
                                    ID_SIMULACION: 0,
                                    ID_MODULO: 3,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    index: 0,
                                    NOMBRE: a.NOMBRE,
                                    GASTO_TOTAL: 0,
                                    VALOR_RECUPERAR: 0,
                                });
                            }
                        });
                    }

                    if (pry_data.actividades_forecast.length > 0) {
                        pry_data.actividades_forecast.map((a: any) => {
                            if (a.ID_MODULO === 1) {
                                idirecto_Fore.push({
                                    ID: a.ID,
                                    INDEX: 0,
                                    ID_MODULO: 1,
                                    ID_PLANIFICACION: 0,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    NOMBRE: a.NOMBRE,
                                    TOTAL_HORAS: 0,
                                    TARIFA_HH: 0,
                                    COSTO_HH: 0,
                                    TOTAL_INGRESOS: 0,
                                    TOTAL_COSTO: 0,
                                });
                            } else if (a.ID_MODULO === 2) {
                                itercero_Fore.push({
                                    ID: a.ID,
                                    ID_MODULO: 2,
                                    ID_SIMULACION: 0,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    index: 0,
                                    NOMBRE: a.NOMBRE,
                                    TOTAL_COSTO: 0,
                                    TOTAL_INGRESO: 0,
                                });
                            } else if (a.ID_MODULO === 3) {
                                igastos_Fore.push({
                                    ID: a.ID,
                                    ID_SIMULACION: 0,
                                    ID_MODULO: 3,
                                    ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                    index: 0,
                                    NOMBRE: a.NOMBRE,
                                    GASTO_TOTAL: a.GASTO_TOTAL,
                                    VALOR_RECUPERAR: 0,
                                    GASTO_DISTRIBUIDO: a.GASTO_DISTRIBUIDO,
                                    RECUPERABLE_DISTRIBUIDO: a.RECUPERABLE_DISTRIBUIDO,
                                    TOTAL_NETO: a.TOTAL_NETO,
                                });
                            }
                        });
                    }
                    
                    if (pry_data.actividades_forecast_recuperable.length > 0) {
                        pry_data.actividades_forecast_recuperable.filter((a: any) => a.ID_MODULO === 3).map((a: any) => {
                            igastos_Fore.filter((e: any) => e.ID === a.ID).map((e: any) => {
                                e.VALOR_RECUPERAR = a.RECUPERABLE_DISTRIBUIDO;
                                e.RECUPERABLE_DISTRIBUIDO = a.RECUPERABLE_DISTRIBUIDO;
                                e.TOTAL_NETO = a.RECUPERABLE_DISTRIBUIDO - e.GASTO_DISTRIBUIDO;
                            });
                        });
                    }
                    
                    let total_neto = igastos_Fore.filter((e: any) => e.ID_MODULO === 3).reduce((a: any, b: any) => a + b.TOTAL_NETO, 0);
                    fore.RESULTADO_FORECAST = fore.INGRESO_VENTA_FORECAST - fore.COSTO_VENTA_FORECAST + total_neto;
                    let distribucion = pry_data.distribucion === undefined ? [] : pry_data.distribucion;

                    if (distribucion !== undefined) {
                        if (ingresosD_Plan !== undefined) {
                            distribucion.map((r: ArrayReserva) => {
                                ingresosD_Plan.map((i: any) => {
                                    if (r.ID_INGRESOS_DIRECTOS === i.ID) {
                                        r.ID_TEMPORAL = i.ID_TEMPORAL;
                                    }
                                    r.FECHA_RESERVA = new Date(r.ANIO, r.ID_MES - 1, 1);
                                });
                            });
                        }
                    }

                    if (pry_data.distribucion.length > 0) {
                        this.setState({ arrayReserva: distribucion });
                    }

                    let distribucion_fore = pry_data.distribucion_forecast === undefined ? [] : pry_data.distribucion_forecast;

                    if (distribucion_fore !== undefined) {
                        if (ingresosD_Fore !== undefined) {
                            distribucion_fore.map((r: ArrayReserva) => {
                                ingresosD_Fore.map((i: any) => {
                                    if (r.ID_INGRESOS_DIRECTOS === i.ID) {
                                        r.ID_TEMPORAL = i.ID_TEMPORAL;
                                    }
                                    r.FECHA_RESERVA = new Date(r.ANIO, r.ID_MES - 1, 1);
                                });
                            });
                        }
                    }

                    if (pry_data.distribucion_forecast.length > 0) {
                        this.setState({
                            arrayReserva_Fore: distribucion_fore,
                            arrayReserva_Fore_Cerrados: distribucion_fore,
                        });
                    }

                    let distribucion_ingresos =
                        pry_data.distribucion_ingresos_planificacion === undefined ? [] : pry_data.distribucion_ingresos_planificacion;
                    if (distribucion_ingresos.length > 0) {
                        this.setState({
                            arrayIngresos_Plan: distribucion_ingresos,
                        });
                    }

                    let distribucion_ingresos_fore =
                        pry_data.distribucion_ingresos_forecast === undefined ? [] : pry_data.distribucion_ingresos_forecast;
                    let estaCerrado = true;

                    if (distribucion_ingresos_fore.length > 0) {
                        this.setState({
                            arrayIngresos_Fore: distribucion_ingresos_fore,
                        });
                    }

                    /* Actividades gastos y array gastos */
                    let i_act_gasto_plan = 1;
                    igastos_Plan.forEach((e: any) => {
                        e.ID_TEMPORAL = i_act_gasto_plan;

                        i_act_gasto_plan = i_act_gasto_plan + 1;
                    });

                    let distribucion_gastos = pry_data.distribucion_gastos === undefined ? [] : pry_data.distribucion_gastos;

                    if (distribucion_gastos !== undefined) {
                        if (igastos_Plan !== undefined) {
                            distribucion_gastos.map((r: ArrayGastos) => {
                                igastos_Plan.map((i: any) => {
                                    if (r.ID_GASTOS === i.ID) {
                                        r.ID_TEMPORAL = i.ID_TEMPORAL;
                                    }
                                });
                            });
                        }
                    }

                    if (pry_data.distribucion_gastos.length > 0) {
                        this.setState({ arrayGastos: distribucion_gastos });
                    }

                    let i_act_gasto_fore = 1;
                    igastos_Fore.forEach((e: any) => {
                        e.ID_TEMPORAL = i_act_gasto_fore;
                        
                        i_act_gasto_fore = i_act_gasto_fore + 1;
                    });

                    let distribucion_gastos_fore = pry_data.distribucion_gastos_forecast === undefined ? [] : pry_data.distribucion_gastos_forecast;

                    if (distribucion_gastos_fore !== undefined) {
                        if (gastos_Fore !== undefined) {
                            distribucion_gastos_fore.map((r: ArrayGastos) => {
                                igastos_Fore.map((i: any) => {
                                    if (r.ID_GASTOS === i.ID) {
                                        r.ID_TEMPORAL = i.ID_TEMPORAL;
                                    }
                                });
                            });
                        }
                    }

                    if (pry_data.distribucion_gastos_forecast.length > 0) {
                        this.setState({
                            arrayGastos_Fore: distribucion_gastos_fore,
                        });
                    }
                    let distribucion_recuperables_fore =
                        pry_data.distribucion_recuperables_forecast === undefined ? [] : pry_data.distribucion_recuperables_forecast;

                    if (distribucion_recuperables_fore !== undefined) {
                        if (gastos_Fore !== undefined) {
                            distribucion_recuperables_fore.map((r: ArrayRecuperables) => {
                                igastos_Fore.map((i: any) => {
                                    if (r.ID_GASTOS === i.ID) {
                                        r.ID_TEMPORAL = i.ID_TEMPORAL;
                                    }
                                });
                            });
                        }
                    }

                    if (pry_data.distribucion_recuperables_forecast.length > 0) {
                        this.setState({
                            arrayRecuperables_Fore: distribucion_recuperables_fore,
                        });
                    }
                    /* Fin Actividades gastos y array gastos */

                    this.setState({
                        actividadRolesPlanificacion: idirecto_Plan,
                        actividadTercerosPlanificacion: itercero_Plan,
                        actividadGastosPlanificacion: igastos_Plan,
                        actividadRolesVenta: idirecto_Venta,
                        actividadTercerosVenta: itercero_Venta,
                        actividadGastosVenta: igastos_Venta,
                        actividadRolesForecast: idirecto_Fore,
                        actividadTercerosForecast: itercero_Fore,
                        actividadGastosForecast: igastos_Fore,
                    });
                    this.CalcularForecast(0);
                    this.setState(
                        {
                            pry_info: pry,
                            plan_info: plan,
                            venta: venta,
                            planificacion: pla,
                            totalNeto: total_neto,
                        },
                        () => {
                            this.moduloVenta();
                            this.verificarProyectoCerrado();
                            this.calendarioReserva();
                            this.CalcularVenta();
                            this.CalcularPlanificacion();
                            this.CalcularForecast();
                        }
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    private _onSelectDateInicio = (date: Date | null | undefined): void => {
        let pry = this.state.pry_info;
        let pl = this.state.planificacion;
        let arrReserva = this.state.arrayReserva;
        let arrReservaCleaned: ArrayReserva[] = [];
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (pl.FECHA_TERMINO != undefined) {
                if (date > pl.FECHA_TERMINO) {
                    this.setState({ fecha_inicio_mayor: false });
                } else {
                    pl.FECHA_INICIO = date;
                }
            } else {
                pl.FECHA_INICIO = date;
            }
            this.calendarioReserva();

            let dateFechaReserva = new Date(date.getFullYear(), date.getMonth(), 1);
            arrReservaCleaned = arrReserva.filter((x) => {
                return x.FECHA_RESERVA === undefined ? new Date() : x.FECHA_RESERVA >= dateFechaReserva;
            });
        }
        this.setState({
            planificacion: pl,
            updateCalendario: true,
            arrayReserva: arrReservaCleaned,
        });
    };

    private _onSelectDateFin = (date: Date | null | undefined): void => {
        let pry = this.state.pry_info;
        let pl = this.state.planificacion;
        let arrReserva = this.state.arrayReserva;
        let arrReservaCleaned: ArrayReserva[] = [];
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (pl.FECHA_INICIO != undefined) {
                if (date < pl.FECHA_INICIO) {
                    this.setState({ fecha_fin_menor: false });
                } else {
                    pl.FECHA_TERMINO = date;
                }
            } else {
                pl.FECHA_TERMINO = date;
            }
            this.calendarioReserva();

            let dateFechaReserva = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            arrReservaCleaned = arrReserva.filter((x) => {
                return x.FECHA_RESERVA === undefined ? new Date() : x.FECHA_RESERVA <= dateFechaReserva;
            });
        }
        this.setState({
            planificacion: pl,
            updateCalendario: true,
            arrayReserva: arrReservaCleaned,
        });
    };

    public _onSelectDateInicioForecast = (date: Date | null | undefined): void => {
        let fore = this.state.forecast;
        let arrReserva = this.state.arrayReserva_Fore;
        let arrReservaCleaned: ArrayReserva[] = [];
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (fore.FECHA_TERMINO != undefined) {
                if (date > fore.FECHA_TERMINO) {
                    this.setState({ fecha_inicio_mayor: false });
                } else {
                    fore.FECHA_INICIO = date;
                }
            } else {
                fore.FECHA_INICIO = date;
            }
            this.calendarioReserva();

            let dateFechaReserva = new Date(date.getFullYear(), date.getMonth(), 1);
            arrReservaCleaned = arrReserva.filter((x) => {
                return x.FECHA_RESERVA === undefined ? new Date() : x.FECHA_RESERVA >= dateFechaReserva;
            });
        }

        this.setState({ forecast: fore, arrayReserva_Fore: arrReservaCleaned }, () => {
            this.CalcularForecast();
        });
    };

    private _onSelectDateFinForecast = (date: Date | null | undefined): void => {
        let fore = this.state.forecast;
        let arrReserva = this.state.arrayReserva_Fore;
        let arrReservaCleaned: ArrayReserva[] = [];
        this.setState({ estadodelformulario: true });
        if (date != null) {
            if (fore.FECHA_INICIO != undefined) {
                if (date < fore.FECHA_INICIO) {
                    this.setState({ fecha_fin_menor: false });
                } else {
                    fore.FECHA_TERMINO = date;
                }
            } else {
                fore.FECHA_TERMINO = date;
            }
            this.calendarioReserva();

            let dateFechaReserva = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            arrReservaCleaned = arrReserva.filter((x) => {
                return x.FECHA_RESERVA === undefined ? new Date() : x.FECHA_RESERVA <= dateFechaReserva;
            });
        }
        this.setState({ forecast: fore, arrayReserva_Fore: arrReservaCleaned }, () => {
            this.CalcularForecast();
            this.verificarProyectoCerrado();
        });
    };
    
    private _onChangeProyecto = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let pry = this.state.pry_info;
        let plan = this.state.plan_info;
        let res = this.state.arrayReserva;
        pry.ID = item.key;
        plan.ID_PROYECTO = item.key;
        this.setState({ pry_info: pry, hasID: true, esNewProyect: true, plan_info: plan });
    };
    
    private _onChangeEstado = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let pry = this.state.pry_info;
        let plan = this.state.plan_info;
        let res = this.state.arrayReserva;
        pry.ID_ESTADO = item.key;
        plan.ID_ESTADO = item.key;
        this.setState({ pry_info: pry, hasID: true, esNewProyect: true, plan_info: plan });
    };
    
    private _onChangeCliente = (event: any, item: any): void => {
        this.setState({ estadodelformulario: true });
        let pry = this.state.pry_info;
        let plan = this.state.plan_info;
        pry.ID_CLIENTE = item.key;
        plan.ID_CLIENTE = item.key;
        this.setState({ pry_info: pry, plan_info: plan });
    };
    
    private _onChangeChecklistCierre = (event: any, item: any): void => {
        let checklistCierre = this.state.seleccionChecklistCierre;
        let existeElemento = checklistCierre.includes(parseInt(event.currentTarget.title));

        if (existeElemento) {
            checklistCierre.map((key, index) => {
                if (parseInt(event.currentTarget.title) === key) {
                    checklistCierre.splice(index, 1);
                }
            });
        } else {
            checklistCierre.push(parseInt(event.currentTarget.title));
        }
        this.setState({ seleccionChecklistCierre: checklistCierre });
    };
    
    public todosCheckboxChecklistSeleccionados() {
        let totalChecklist = this.state.checklistCierre.length;
        let totalSeleccionChecklist = this.state.seleccionChecklistCierre.length;

        if (totalChecklist === totalSeleccionChecklist) {
            return true;
        }
        return false;
    }
    
    public async obtenerOportunidades() {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPI(`/api/GestionProyecto/GetByMeProyectos/${userName.account.userName}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                const list: any[] = [];
                if (result.data) {
                    {
                        result.data.listado.map((a: any) => {
                            list.push({
                                key: a.ID,
                                text: "OT " + a.ID + " - " + a.NOMBRE,
                            });
                        });
                    }
                }

                this.setState({
                    opciones_proyectos: list,
                });
            });
    }

    public callbackFijado(esOculto: any) {
        this.setState({ ocultarFijado: esOculto });
    }

    public calendarioReserva() {
        let nuevoArray: any = [];
        let nuevoArray2: any = [];
        let nuevoArray_sems: any = [];
        let nuevoArray_sems2: any = [];
        let pl = this.state.planificacion;
        let fr = this.state.forecast;
        let cant_sems: number | undefined;
        let cant_meses: number | undefined;
        let cant_sems_fr: number | undefined;
        let cant_meses_fr: number | undefined;

        let yearinicio = 0;
        let yearfin = 0;

        let iniciomes = 0;
        let finmes = 0;

        if (this.state.tipoModulo === "Planificado") {
            yearinicio = new Date(pl.FECHA_INICIO !== undefined ? pl.FECHA_INICIO : "").getFullYear();
            yearfin = new Date(pl.FECHA_TERMINO !== undefined ? pl.FECHA_TERMINO : "").getFullYear();

            iniciomes = new Date(pl.FECHA_INICIO !== undefined ? pl.FECHA_INICIO : "").getMonth() + 1;
            finmes = new Date(pl.FECHA_TERMINO !== undefined ? pl.FECHA_TERMINO : "").getMonth() + 1;
        } else if (this.state.tipoModulo === "Forecast") {
            yearinicio = new Date(fr.FECHA_INICIO !== undefined ? fr.FECHA_INICIO : "").getFullYear();
            yearfin = new Date(fr.FECHA_TERMINO !== undefined ? fr.FECHA_TERMINO : "").getFullYear();

            iniciomes = new Date(fr.FECHA_INICIO !== undefined ? fr.FECHA_INICIO : "").getMonth() + 1;
            finmes = new Date(fr.FECHA_TERMINO !== undefined ? fr.FECHA_TERMINO : "").getMonth() + 1;
        }

        let totalyear = yearfin - yearinicio + 1;
        let years = yearinicio;
        let arrayyear = [];

        for (let i = 0; i < totalyear; i++) {
            arrayyear.push({
                years: years + i,
                inicio_year: yearinicio,
                fin_year: yearfin,
                inicio_mes: iniciomes,
                fin_mes: finmes,
            });
        }

        if (pl.FECHA_INICIO !== undefined) {
            if (pl.FECHA_TERMINO !== undefined) {
                var fechaInicio_original = new Date(pl.FECHA_INICIO.getFullYear(), pl.FECHA_INICIO.getMonth(), 1);
                while (
                    fechaInicio_original.toLocaleDateString("es-ES", {
                        weekday: "long",
                    }) !== "lunes"
                ) {
                    fechaInicio_original.setDate(fechaInicio_original.getDate() + 1);
                }

                var fechaInicio = moment(
                    new Date(fechaInicio_original.getFullYear(), fechaInicio_original.getMonth(), fechaInicio_original.getDate()),
                    "YYYY-MM-DD"
                );
                var fechaFin = moment(new Date(pl.FECHA_TERMINO.getFullYear(), pl.FECHA_TERMINO.getMonth() + 1, 0), "YYYY-MM-DD");
                cant_sems = fechaFin.diff(fechaInicio, "week") + 1;

                var anioInicio = moment(new Date(pl.FECHA_INICIO.getFullYear(), pl.FECHA_INICIO.getMonth() - 1, 1), "YYYY-MM-DD");
                var anioFin = moment(new Date(pl.FECHA_TERMINO.getFullYear(), pl.FECHA_TERMINO.getMonth() + 1, 0), "YYYY-MM-DD");

                cant_meses = anioFin.diff(anioInicio, "month");

                this.state.arrayIngresos_Plan.map((a) => {
                    if (
                        (pl.FECHA_INICIO!.getFullYear() < a.ANIO ||
                            (pl.FECHA_INICIO!.getFullYear() === a.ANIO && pl.FECHA_INICIO!.getMonth() < a.ID_MES)) &&
                        (pl.FECHA_TERMINO!.getFullYear() > a.ANIO ||
                            (pl.FECHA_TERMINO!.getFullYear() === a.ANIO && pl.FECHA_TERMINO!.getMonth() + 2 > a.ID_MES))
                    ) {
                        nuevoArray.push({
                            ANIO: a.ANIO,
                            ID: a.ID,
                            ID_MES: a.ID_MES,
                            ID_PLANIFICACION: a.ID_PLANIFICACION,
                            VALOR: a.VALOR,
                        });
                    }
                });

                this.state.arrayReserva.map((a) => {
                    if (
                        (pl.FECHA_INICIO!.getFullYear() < a.ANIO ||
                            (pl.FECHA_INICIO!.getFullYear() === a.ANIO && pl.FECHA_INICIO!.getMonth() < a.ID_MES)) &&
                        (pl.FECHA_TERMINO!.getFullYear() > a.ANIO ||
                            (pl.FECHA_TERMINO!.getFullYear() === a.ANIO && pl.FECHA_TERMINO!.getMonth() + 2 > a.ID_MES))
                    ) {
                        nuevoArray_sems.push({
                            ANIO: a.ANIO,
                            HORAS: a.HORAS,
                            ID: a.ID,
                            ID_INGRESOS_DIRECTOS: a.ID_INGRESOS_DIRECTOS,
                            ID_MES: a.ID_MES,
                            ID_TEMPORAL: a.ID_TEMPORAL,
                            PORCENTAJE: a.PORCENTAJE > 0 ? a.PORCENTAJE : 0,
                            SEMANA: a.SEMANA,
                        });
                    }
                });

                let plan = this.state.planificacion;

                plan.TOTAL_INGRESOS_DIST = parseFloat(nuevoArray.reduce((a: any, b: any) => a + b.VALOR, 0).toFixed(2));

                this.setState({ planificacion: plan });
            }
        }

        if (fr.FECHA_INICIO !== undefined) {
            if (fr.FECHA_TERMINO !== undefined) {
                var fechaInicio_original_fore = new Date(fr.FECHA_INICIO.getFullYear(), fr.FECHA_INICIO.getMonth(), 1);

                while (
                    fechaInicio_original_fore.toLocaleDateString("es-ES", {
                        weekday: "long",
                    }) !== "lunes"
                ) {
                    fechaInicio_original_fore.setDate(fechaInicio_original_fore.getDate() + 1);
                }

                var fechaInicio_fore = moment(
                    new Date(fechaInicio_original_fore.getFullYear(), fechaInicio_original_fore.getMonth(), fechaInicio_original_fore.getDate()),
                    "YYYY-MM-DD"
                );
                var fechaFin_fore = moment(new Date(fr.FECHA_TERMINO.getFullYear(), fr.FECHA_TERMINO.getMonth() + 1, 0), "YYYY-MM-DD");

                cant_sems_fr = fechaFin_fore.diff(fechaInicio_fore, "week") + 1;
                cant_meses_fr = this.monthDiff(fr.FECHA_INICIO, fr.FECHA_TERMINO);

                this.state.arrayIngresos_Fore.map((a) => {
                    if (
                        (fr.FECHA_INICIO!.getFullYear() < a.ANIO ||
                            (fr.FECHA_INICIO!.getFullYear() === a.ANIO && fr.FECHA_INICIO!.getMonth() < a.ID_MES)) &&
                        (fr.FECHA_TERMINO!.getFullYear() > a.ANIO ||
                            (fr.FECHA_TERMINO!.getFullYear() === a.ANIO && fr.FECHA_TERMINO!.getMonth() + 2 > a.ID_MES))
                    ) {
                        nuevoArray2.push({
                            ANIO: a.ANIO,
                            ID: a.ID,
                            ID_MES: a.ID_MES,
                            ID_PLANIFICACION: a.ID_PLANIFICACION,
                            VALOR: a.VALOR,
                        });
                    }
                });

                this.state.arrayReserva_Fore.map((a) => {
                    if (
                        (fr.FECHA_INICIO!.getFullYear() < a.ANIO ||
                            (fr.FECHA_INICIO!.getFullYear() === a.ANIO && fr.FECHA_INICIO!.getMonth() < a.ID_MES)) &&
                        (fr.FECHA_TERMINO!.getFullYear() > a.ANIO ||
                            (fr.FECHA_TERMINO!.getFullYear() === a.ANIO && fr.FECHA_TERMINO!.getMonth() + 2 > a.ID_MES))
                    ) {
                        nuevoArray_sems2.push({
                            ANIO: a.ANIO,
                            HORAS: a.HORAS,
                            ID: a.ID,
                            ID_INGRESOS_DIRECTOS: a.ID_INGRESOS_DIRECTOS,
                            ID_MES: a.ID_MES,
                            ID_TEMPORAL: a.ID_TEMPORAL,
                            PORCENTAJE: a.PORCENTAJE > 0 ? a.PORCENTAJE : 0,
                            SEMANA: a.SEMANA,
                        });
                    }
                });

                let fore = this.state.forecast;

                fore.TOTAL_INGRESOS_DIST = parseFloat(nuevoArray2.reduce((a: any, b: any) => a + b.VALOR, 0).toFixed(2));

                this.setState({ forecast: fore });
            }
        }

        this.setState({
            first_year: yearinicio,
            last_year: yearfin,
            arrayIngresos_Plan: nuevoArray,
            arrayIngresos_Fore: nuevoArray2,
            arrayReserva: nuevoArray_sems,
            arrayReserva_Fore: nuevoArray_sems2,
            total_year: arrayyear,
            cantidad_semanas: cant_sems,
            cantidad_meses: cant_meses,
            cantidad_meses_fr: cant_meses_fr,
            cantidad_semanas_fr: cant_sems_fr,
        });
    }

    public yearsDiff(d1: any, d2: any) {
        let date1 = new Date(d1);
        let date2 = new Date(d2);
        let yearsDiff = date2.getFullYear() - date1.getFullYear();
        return yearsDiff;
    }

    public monthDiff(d1: any, d2: any) {
        let date1 = new Date(d1);
        let date2 = new Date(d2);
        let years = this.yearsDiff(d1, d2);
        let months = years * 12 + (date2.getMonth() - date1.getMonth());
        return months + 1;
    }

    public weeksBetween(d1: any, d2: any) {
        if (d1 !== undefined) {
            if (d2 !== undefined) {
                return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000)) + 1;
            }
        }
    }

    public async guardarPlan(sellado: boolean, showMessage: boolean = true) {
        let plan = this.state.planificacion;
        let pln = this.state.plan_info;
        let userName: any;
        let modulos: any = [];

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        plan.MODIFICADO_POR = userName.account.userName;

        if (pln.ID_ESTADO == 10) {
            plan.ID_ESTADO = 42; //Luego el procedimiento toma este numero para colocar estado 10 en el PLAN
        } else {
            plan.ID_ESTADO = 9;
        }

        plan.ID_PROYECTO = pln.ID_PROYECTO;

        if (sellado === true && this.state.tipoModulo === "Planificado") {
            let pln = this.state.plan_info;
            plan.ID_ESTADO = 10;
            pln.ID_ESTADO = 10;
            this.setState({ plan_info: pln });
        }

        plan.ARRAY_RESERVA = this.state.arrayReserva;
        plan.INGRESOS_DIRECTOS = this.state.ingresosDirectosPlanificacion;
        plan.INGRESOS_TERCEROS = this.state.ingresosTercerosPlanificacion;
        plan.GASTOS = this.state.ingresosGastosPlanificacion;
        plan.ARRAY_GASTOS = this.state.arrayGastos;
        plan.ARRAY_INGRESOS = this.state.arrayIngresos_Plan;

        this.state.actividadRolesPlanificacion.map((a: any) => {
            modulos.push({
                ID_PLANIFICACION: this.state.plan_info.ID,
                ID_MODULO: 1,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
                ID_TEMPORAL: 0,
            });
        });
        this.state.actividadTercerosPlanificacion.map((a: any) => {
            modulos.push({
                ID_PLANIFICACION: this.state.plan_info.ID,
                ID_MODULO: 2,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
                ID_TEMPORAL: 0,
            });
        });
        this.state.actividadGastosPlanificacion.map((a: any) => {
            modulos.push({
                ID_PLANIFICACION: this.state.plan_info.ID,
                ID_MODULO: 3,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
                ID_TEMPORAL: a.ID_TEMPORAL,
                GASTO_TOTAL: a.GASTO_TOTAL,
                GASTO_DISTRIBUIDO: a.GASTO_DISTRIBUIDO,
            });
        });

        plan.ACTIVIDADES = modulos;

        this.setState({ planificacion: plan });

        fetchEngine
            .postAPI("/api/planificacion/InsertarPlanificacion", this.state.planificacion, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (showMessage) {
                    if (resultado.ok) {
                        this.setState({ guardadoOK: true });

                        setTimeout(() => {
                            this.setState({
                                cargarGuardado: true,
                                guardadoOK: false,
                            });
                            setTimeout(() => {
                                this.setState({
                                    cargarGuardado: true,
                                    guardadoOK: true,
                                    guardarDisabled: false,
                                });
                            }, 1000);
                        }, 1000);

                        if (sellado) {
                            window.location.reload();
                        }
                    } else {
                        this.setState({ 
                            cargarGuardado: true, 
                            guardadoNoOK: false, 
                            errorMessage: resultado.data.number !== undefined ? resultado.data.number == 50000 ? resultado.data.message : "" : ""
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public guardarForecast(sellado: boolean, cerradoEjecucion: boolean, cerradoAdm: boolean, cerrarMes: boolean = false) {
        let fore = this.state.forecast;
        let pln = this.state.plan_info;
        let userName: any;
        let modulos: any = [];

        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fore.MODIFICADO_POR = userName.account.userName;
        fore.ID_PROYECTO = pln.ID_PROYECTO;

        if (cerradoEjecucion) {
            fore.ID_ESTADO = 24;
            pln.ID_ESTADO = 24;
        } else if (cerradoAdm) {
            fore.ID_ESTADO = 25;
            pln.ID_ESTADO = 25;
        } else {
            fore.ID_ESTADO = 10;
            pln.ID_ESTADO = 10;
        }

        if (sellado === true && this.state.tipoModulo === "Forecast") {
            fore.SELLADO = true;
        } else {
            fore.SELLADO = false;
        }

        fore.INGRESOS_DIRECTOS = this.state.ingresosDirectosForecast;
        fore.INGRESOS_TERCEROS = this.state.ingresosTercerosForecast;
        fore.GASTOS = this.state.ingresosGastosForecast;

        fore.ARRAY_RESERVA = this.state.arrayReserva_Fore;
        fore.ARRAY_GASTOS = this.state.arrayGastos_Fore;
        fore.ARRAY_INGRESOS = this.state.arrayIngresos_Fore;
        fore.ARRAY_RECUPERABLES = this.state.arrayRecuperables_Fore;

        this.state.actividadRolesForecast.map((a: any) => {
            modulos.push({
                ID: a.ID,
                ID_PLANIFICACION: this.state.plan_info.ID,
                ID_MODULO: 1,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
                ID_TEMPORAL: a.ID_TEMPORAL,
            });
        });
        this.state.actividadTercerosForecast.map((a: any) => {
            modulos.push({
                ID: a.ID,
                ID_PLANIFICACION: this.state.plan_info.ID,
                ID_MODULO: 2,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
                ID_TEMPORAL: a.ID_TEMPORAL,
            });
        });
        this.state.actividadGastosForecast.map((a: any) => {
            modulos.push({
                ID: a.ID,
                ID_PLANIFICACION: this.state.plan_info.ID,
                ID_MODULO: 3,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
                ID_TEMPORAL: a.ID_TEMPORAL,
                GASTO_TOTAL: a.GASTO_TOTAL,
                GASTO_DISTRIBUIDO: a.GASTO_DISTRIBUIDO,
                RECUPERABLE_DISTRIBUIDO: a.RECUPERABLE_DISTRIBUIDO,
                TOTAL_NETO: a.TOTAL_NETO,
            });
        });

        fore.ACTIVIDADES = modulos;
        this.setState({ forecast: fore, plan_info: pln });

        fetchEngine
            .postAPI("/api/planificacion/InsertarForecast", this.state.forecast, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    console.log("cerradoEjecucion", cerradoEjecucion);
                    if (cerradoEjecucion) {
                        this.guardarChecklistCierre();
                    } else {
                        this.setState({
                            cargarGuardado: true,
                            guardadoOK: false,
                            guardarDisabled: false,
                        });

                        if (cerrarMes) {
                            this.setState({
                                dialogoCerrarMes: true,
                                cerrarMesDisabled: false,
                            })
                            this.guardarFechaCierreMes();
                        }
                    }
                } else {
                    this.setState({ 
                        guardarDisabled: false, 
                        guardadoNoOK: false, 
                        cargarGuardado: true, 
                        errorMessage: resultado.data.number !== undefined ? resultado.data.number == 50000 ? resultado.data.message : "" : ""
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public validarProyecto(sellado: boolean) {
        let plan = this.state.plan_info;
        let ingresosDirPlan = this.state.ingresosDirectosPlanificacion;
        let totalIngresosDist = this.state.planificacion.TOTAL_INGRESOS_DIST;
        let existePersonalAsignado = true;

        ingresosDirPlan.map((act: any) => {
            if (act.ID_UNIDAD === 0 || act.ID_CARGO_PLAN === 0 || act.TOTAL_HORAS === 0) {
                existePersonalAsignado = false;
            }
        });

        if (plan.ID_PROYECTO === undefined || plan.ID_PROYECTO === null || plan.ID_PROYECTO <= 0) {
            this.setState({ validacionProyecto: false, cargarGuardado: true });
        } else if (
            sellado === true &&
            (totalIngresosDist === undefined || totalIngresosDist === null || totalIngresosDist <= 0 || !existePersonalAsignado)
        ) {
            this.setState({ validacionProyecto: false, cargarGuardado: true });
        } else {
            this.setState({ validacionProyecto: true });
            if (this.state.tipoModulo === "Planificado") {
                this.guardarPlan(sellado);
            } else if (this.state.tipoModulo === "Forecast") {
                this.guardarForecast(sellado, false, false);
            }
        }
    }

    public selectedModulo(modulo: string) {
        let plan = this.state.plan_info;
        plan.TIPO_MODULO = modulo;

        this.setState({ plan_info: plan });
    }

    public trunc(x: number, posiciones: number) {
        var s = x.toString();
        var l = s.length;
        var decimalLength = s.indexOf(".") + 1;

        if (l - decimalLength <= posiciones) {
            return x;
        }
        // Parte decimal del número
        var isNeg = x < 0;
        var decimal = x % 1;
        var entera = isNeg ? Math.ceil(x) : Math.floor(x);
        var decimalFormated = Math.floor(Math.abs(decimal) * Math.pow(10, posiciones));
        // Sustraemos del número original la parte decimal
        // y le sumamos la parte decimal que hemos formateado
        var finalNum = entera + (decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1);

        return finalNum;
    }

    public divPlanificadoFecha() {
        return (
            <div className="card-body">
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <DatePicker
                        id="fechainicio"
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        placeholder="Fecha de inicio"
                        label="Fecha de inicio"
                        ariaLabel="Seleccione una fecha"
                        formatDate={this._onFormatDate}
                        value={this.state.planificacion.FECHA_INICIO}
                        disabled={
                            this.state.plan_info.ID_ESTADO === 24 || this.state.plan_info.ID_ESTADO === 25
                                ? true
                                : this.state.esAdmin
                                    ? false
                                    : this.state.plan_info.ID_ESTADO === 10 && this.state.tipoModulo == "Planificado"
                                        ? true
                                        : false
                        }
                        onSelectDate={this._onSelectDateInicio}
                    />
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <DatePicker
                        id="fechafin"
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        placeholder="Fecha de término"
                        label="Fecha de término"
                        ariaLabel="Seleccione una fecha"
                        formatDate={this._onFormatDate}
                        value={this.state.planificacion.FECHA_TERMINO}
                        disabled={
                            this.state.plan_info.ID_ESTADO === 24 || this.state.plan_info.ID_ESTADO === 25
                                ? true
                                : this.state.esAdmin
                                    ? false
                                    : this.state.plan_info.ID_ESTADO === 10 && this.state.tipoModulo == "Planificado"
                                        ? true
                                        : false
                        }
                        onSelectDate={this._onSelectDateFin}
                    />
                </div>
            </div>
        );
    }
    
    public divPlanificado() {
        if (this.state.contador === 0) {
            this.setState({ contador: 1 });
        }
        return (
            <div style={{ marginBottom: 100 }}>
                <div className="acordeon">
                    <Accordion
                        title="Costos directos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={1}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosDirectosPlanificacion
                                actividadesList={this.state.actividadRolesPlanificacion}
                                listadoOpciones={this.state.opciones_roles_aux}
                                listadoRoles_Plan={this.state.opciones_roles_plan}
                                listadoOpcionesUnidad={this.state.opciones_unidad_aux}
                                moneda={this.state.moneda_cto}
                                ingresoP={this.state.ingresosDirectosPlanificacion}
                                callback={this.callbackIngresos}
                                callbackActividades={this.callbackActividades}
                                total_year={this.state.total_year}
                                arrayReserva={this.state.arrayReserva}
                                consultoresList={this.state.consultoresList}
                                callbackReserva={this.callbackReserva}
                                estado_modulo={
                                    this.state.plan_info.ID_ESTADO === 24
                                        ? 24
                                        : this.state.plan_info.ID_ESTADO === 25
                                            ? 25
                                            : this.state.esAdmin
                                                ? 9
                                                : this.state.plan_info.ID_ESTADO
                                }
                                listadoCargos={this.state.opciones_cargo_directos}
                                id_unidad={this.state.plan_info.ID_UNIDAD}
                                id_planificacion={this.state.planificacion.ID}
                                listadoConsultores_itfc={this.state.listadoConsultores_itfc}
                                cantidad_semanas={this.state.cantidad_semanas}
                                callback_update_calendario={this.state.updateCalendario}
                            />
                        </div>
                    </Accordion>
                </div>
                <div className="acordeon">
                    <Accordion
                        title="Ingresos y costos terceros"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={2}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosTercerosPlanificacion
                                actividadesList={this.state.actividadTercerosPlanificacion}
                                moneda={this.state.moneda_cto}
                                ingresoP={this.state.ingresosTercerosPlanificacion}
                                callback={this.callbackIngresosTerceros}
                                estado_modulo={
                                    this.state.plan_info.ID_ESTADO === 24
                                        ? 24
                                        : this.state.plan_info.ID_ESTADO === 25
                                            ? 25
                                            : this.state.esAdmin
                                                ? 9
                                                : this.state.plan_info.ID_ESTADO
                                }
                                callbackActividades={this.callbackActividadesTerceros}
                            />
                        </div>
                    </Accordion>
                </div>
                <div className="acordeon">
                    <Accordion
                        title="Gastos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={3}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosGastosPlanificacion
                                actividadesList={this.state.actividadGastosPlanificacion}
                                listadoOpciones={this.state.opciones_roles_aux}
                                moneda={this.state.moneda_cto}
                                callback={this.callbackIngresosGastos}
                                callbackActividades={this.callbackActividadesGastos}
                                ingresoP={this.state.ingresosGastosPlanificacion}
                                estado_modulo={
                                    this.state.plan_info.ID_ESTADO === 24
                                        ? 24
                                        : this.state.plan_info.ID_ESTADO === 25
                                            ? 25
                                            : this.state.esAdmin
                                                ? 9
                                                : this.state.plan_info.ID_ESTADO
                                }
                                lista_categorias={this.state.opciones_categoria}
                                total_year={this.state.total_year}
                                arrayGastos={this.state.arrayGastos}
                                callbackArrayGastos={this.callbackArrayGastos}
                                id_planificacion={this.state.planificacion.ID}
                                cantidad_meses={this.state.cantidad_meses}
                            />
                        </div>
                    </Accordion>
                </div>
            </div>
        );
    }

    public divForecast() {
        return (
            <div style={{ marginBottom: 100 }}>
                <div className="acordeon">
                    <Accordion
                        title="Costos directos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={1}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosDirectosForecast
                                actividadesList={this.state.actividadRolesForecast}
                                listadoOpciones={this.state.opciones_roles_aux}
                                listadoOpcionesUnidad={this.state.opciones_unidad_aux}
                                listadoOpcionesCtoMarco={this.state.opciones_cttomarco_aux}
                                moneda={this.state.moneda_cto}
                                ingresoP={this.state.ingresosDirectosForecast}
                                callback={this.callbackIngresosForecast}
                                callbackActividades={this.callbackActividadesForecast}
                                total_year={this.state.total_year}
                                arrayReserva={this.state.arrayReserva_Fore} // cambiar
                                arrayReserva_Cerrados={this.state.arrayReserva_Fore_Cerrados}
                                plan_info={this.state.plan_info}
                                consultoresList={this.state.consultoresList}
                                proyectoID={this.state.pry_info.ID}
                                callbackReserva={this.callbackReservaForecast}
                                estado_modulo={this.state.plan_info.ID_ESTADO}
                                listadoCargos={this.state.opciones_cargo_directos}
                                id_unidad={this.state.plan_info.ID_UNIDAD}
                                id_planificacion={this.state.forecast.ID}
                                listadoRoles_itfc={this.state.listadoRoles_itfc}
                                listadoConsultores_itfc={this.state.listadoConsultores_itfc}
                                listadoRoles_Plan={this.state.opciones_roles_plan}
                                fecha_cierre_mes={this.state.forecast.FECHA_CIERRE_MES}
                                cantidad_semanas={this.state.cantidad_semanas_fr}
                            />
                        </div>
                    </Accordion>
                </div>
                <div className="acordeon">
                    <Accordion
                        title="Ingresos y costos terceros"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={1}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosTercerosForecast
                                actividadesList={this.state.actividadTercerosForecast}
                                moneda={this.state.moneda_cto}
                                ingresoP={this.state.ingresosTercerosForecast}
                                callback={this.callbackIngresosTercerosForecast}
                                estado_modulo={this.state.plan_info.ID_ESTADO}
                                callbackActividades={this.callbackActividadesTercerosForecast}
                            />
                        </div>
                    </Accordion>
                </div>
                <div className="acordeon">
                    <Accordion
                        title="Gastos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={1}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosGastosForecast
                                actividadesList={this.state.actividadGastosForecast}
                                listadoOpciones={this.state.opciones_roles_aux}
                                moneda={this.state.moneda_cto}
                                callback={this.callbackIngresosGastosForecast}
                                callbackActividades={this.callbackActividadesGastosForecast}
                                ingresoP={this.state.ingresosGastosForecast}
                                estado_modulo={this.state.plan_info.ID_ESTADO}
                                lista_categorias={this.state.opciones_categoria}
                                total_year={this.state.total_year}
                                arrayGastos={this.state.arrayGastos_Fore} // cambiar
                                arrayRecuperables={this.state.arrayRecuperables_Fore}
                                callbackArrayGastos={this.callbackArrayGastosForecast}
                                callbackArrayRecuperables={this.callbackArrayRecuperablesForecast}
                                id_planificacion={this.state.planificacion.ID}
                                cantidad_meses={this.state.cantidad_meses_fr}
                                fecha_cierre_mes={this.state.forecast.FECHA_CIERRE_MES}
                            />
                        </div>
                    </Accordion>
                </div>
            </div>
        );
    }
    
    public divVentaFecha() {
        return (
            <div className="card-body">
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <DatePicker
                        id=""
                        disabled={true}
                        placeholder="Seleccione"
                        label="Fecha de inicio"
                        ariaLabel="Seleccione una fecha"
                        formatDate={this._onFormatDate}
                        value={this.state.venta.FECHA_INICIO}
                    />
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <DatePicker
                        id=""
                        disabled={true}
                        placeholder="Seleccione"
                        label="Fecha de término"
                        ariaLabel="Seleccione una fecha"
                        formatDate={this._onFormatDate}
                        value={this.state.venta.FECHA_TERMINO}
                    />
                </div>
            </div>
        );
    }
    
    public divVenta() {
        return (
            <div style={{ marginBottom: 100 }}>
                <div className="acordeon">
                    <Accordion
                        title="Ingresos y costos directos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={1}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosDirectosVenta
                                actividadesList={this.state.actividadRolesVenta}
                                listadoOpciones={this.state.opciones_roles}
                                ingresoP={this.state.ingresosDirectosVenta}
                                moneda={this.state.moneda_cto}
                                callback={this.callbackIngresos}
                                horasDia={this.state.horasxdia}
                                roles={this.state.opciones_roles_aux}
                                simID={1}
                                callbackActividades={""}
                                moneda_ctto={this.state.plan_info.MONEDA_CTTO}
                            />
                        </div>
                    </Accordion>
                </div>
                <div className="acordeon">
                    <Accordion
                        title="Ingresos y costos terceros"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={1}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosTercerosVenta
                                actividadesList={this.state.actividadTercerosVenta}
                                ingresoP={this.state.ingresosTercerosVenta}
                                moneda={this.state.moneda_cto}
                                callback={this.callbackIngresosTerceros}
                                callbackActividades={""}
                                moneda_ctto={this.state.plan_info.MONEDA_CTTO}
                            />
                        </div>
                    </Accordion>
                </div>
                <div className="acordeon">
                    <Accordion
                        title="Gastos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        className="fondoTitAcordion"
                        key={1}
                        classFondo="fondoSeccion">
                        <div className="itemContent">
                            <IngresosGastosVenta
                                actividadesList={this.state.actividadGastosVenta}
                                listadoOpciones={this.state.opciones_item}
                                ingresoP={this.state.ingresosGastosVenta}
                                moneda={this.state.moneda_cto}
                                callback={this.callbackIngresosGastos}
                                callbackActividades={""}
                                moneda_ctto={this.state.plan_info.MONEDA_CTTO}
                            />
                        </div>
                    </Accordion>
                </div>
            </div>
        );
    }

    public divForecastFecha() {
        return (
            <div className="card-body">
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <DatePicker
                        id="fechainicio"
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        placeholder="Fecha de inicio"
                        label="Fecha de inicio"
                        //isRequired={true}
                        ariaLabel="Seleccione una fecha"
                        formatDate={this._onFormatDate}
                        value={this.state.forecast.FECHA_INICIO}
                        disabled={this.state.plan_info.ID_ESTADO === 24 || this.state.plan_info.ID_ESTADO === 25 ? true : false}
                        onSelectDate={this._onSelectDateInicioForecast}
                    />
                </div>
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                    <DatePicker
                        id="fechafin"
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        placeholder="Fecha de término"
                        label="Fecha de término"
                        //isRequired={true}
                        ariaLabel="Seleccione una fecha"
                        formatDate={this._onFormatDate}
                        value={this.state.forecast.FECHA_TERMINO}
                        disabled={this.state.plan_info.ID_ESTADO === 24 || this.state.plan_info.ID_ESTADO === 25 ? true : false}
                        onSelectDate={this._onSelectDateFinForecast}
                    />
                </div>
            </div>
        );
    }

    public panelStaffing() {
        return (
            <div>
                <SlidingPane
                    className="some-custom-class"
                    overlayClassName="some-custom-overlay-class"
                    isOpen={this.state.abrirPanel}
                    title="Panel Staffing"
                    subtitle="Planifica y administra a la misma vez"
                    onRequestClose={() => {
                        this.setState({ abrirPanel: false });
                        this.setState({ ocultarFijado: false });
                    }}
                    width="50%">
                    <Staffing />
                </SlidingPane>
            </div>
        );
    }

    public dialogoSalir() {
        return (
            <Dialog
                hidden={this.state.dialogoSalir}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Salir",
                    subText: "¿Está seguro que desea salir? Los cambios efectuados no serán guardados",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Salir"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                            this.salir();
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cancelar"
                        onClick={() => {
                            this.setState({ dialogoSalir: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public formatNumber(valor: string) {
        let value = "";

        if (valor.includes(".")) {
            value = valor.replace(".", ",");
        } else {
            value = valor;
        }

        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return value;
    }

    public onReservaChange(anio: number, mes: number, element: any) {
        let valor = parseFloat(element.value); // valor del input

        if (isNaN(valor)) {
            valor = 0;
        } else if (valor >= 0) {
            valor = valor;
        } else {
            valor = 0;
        }

        switch (mes) {
            case 1:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 2:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 3:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 4:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 5:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 6:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 7:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 8:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 9:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 10:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 11:
                this.agregarReservaArray(anio, mes, valor);
                break;
            case 12:
                this.agregarReservaArray(anio, mes, valor);
                break;
        }

        if (this.state.tipoModulo === "Forecast" && this.state.entroForecast) {
            this.CalcularForecast();
        } else if (this.state.tipoModulo === "Forecast" && !this.state.entroForecast) {
            this.setState({ entroForecast: true });
        }

        if (this.state.tipoModulo === "Planificado") {
            this.CalcularForecast();
            this.setState({ entroForecast: false });
        }
    }

    public agregarReservaArray(anio: number, mes: number, valor: any) {
        let arrayIngresos = this.state.arrayIngresos_Plan;
        let arrayIngresos_Fore = this.state.arrayIngresos_Fore;
        let data = this.state.planificacion;
        let data_Fore = this.state.forecast;

        let flag = false;

        if (this.state.tipoModulo === "Planificado") {
            if (arrayIngresos !== undefined) {
                if (arrayIngresos.length <= 0) {
                    arrayIngresos.push({
                        ID: 0,
                        ID_PLANIFICACION: this.state.planificacion.ID,
                        ID_MES: mes,
                        VALOR: valor,
                        ANIO: anio,
                    });
                } else {
                    //modifica
                    arrayIngresos.forEach((e: any) => {
                        if (e.ANIO === anio && e.ID_MES === mes) {
                            e.VALOR = valor;
                        }
                    });

                    //agrega
                    for (let index = 0; index < arrayIngresos.length; index++) {
                        const e = arrayIngresos[index];
                        if (e.ANIO === anio && e.ID_MES === mes) {
                            flag = false;
                            break;
                        } else {
                            flag = true;
                        }
                    }
                }
                if (flag === true) {
                    arrayIngresos.push({
                        ID: 0,
                        ID_PLANIFICACION: this.state.planificacion.ID,
                        ID_MES: mes,
                        VALOR: valor,
                        ANIO: anio,
                    });
                }

                data.TOTAL_INGRESOS_DIST = parseFloat(this.state.arrayIngresos_Plan.reduce((a, b) => a + b.VALOR, 0).toFixed(2));
            }
        } else if (this.state.tipoModulo === "Forecast") {
            if (arrayIngresos_Fore !== undefined) {
                if (arrayIngresos_Fore.length <= 0) {
                    arrayIngresos_Fore.push({
                        ID: 0,
                        ID_PLANIFICACION: this.state.forecast.ID,
                        ID_MES: mes,
                        VALOR: valor,
                        ANIO: anio,
                    });
                } else {
                    //modifica
                    arrayIngresos_Fore.forEach((e: any) => {
                        if (e.ANIO === anio && e.ID_MES === mes) {
                            e.VALOR = valor;
                        }
                    });

                    //agrega
                    for (let index = 0; index < arrayIngresos_Fore.length; index++) {
                        const e = arrayIngresos_Fore[index];
                        if (e.ANIO === anio && e.ID_MES === mes) {
                            flag = false;
                            break;
                        } else {
                            flag = true;
                        }
                    }
                }
                if (flag === true) {
                    arrayIngresos_Fore.push({
                        ID: 0,
                        ID_PLANIFICACION: this.state.forecast.ID,
                        ID_MES: mes,
                        VALOR: valor,
                        ANIO: anio,
                    });
                }

                data_Fore.TOTAL_INGRESOS_DIST = parseFloat(this.state.arrayIngresos_Fore.reduce((a, b) => a + b.VALOR, 0).toFixed(2));
            }
        }

        this.setState({
            arrayIngresos_Plan: [...arrayIngresos],
            planificacion: data,
            arrayIngresos_Fore: [...arrayIngresos_Fore],
            forecast: data_Fore,
        });
    }

    public valueMonth(year: number, month_number: number, modulo: string) {
        let value = -1;
        let arrayIngresos = this.state.arrayIngresos_Plan;
        let arrayIngresos_Fore = this.state.arrayIngresos_Fore;

        if (modulo === "Planificado") {
            if (arrayIngresos !== undefined) {
                arrayIngresos.forEach((e: ArrayIngresos_Plan) => {
                    if (e.ANIO === year && e.ID_MES === month_number) {
                        value = e.VALOR;
                    }
                });
            }
        } else if (modulo === "Forecast") {
            if (arrayIngresos_Fore !== undefined) {
                arrayIngresos_Fore.forEach((e: ArrayIngresos_Plan) => {
                    if (e.ANIO === year && e.ID_MES === month_number) {
                        value = e.VALOR;
                    }
                });
            }
        }

        return value.toString();
    }

    public calcularDistribucion() {
        let ingreso_distribuido = 0;
        let arrayIngresos = this.state.arrayIngresos_Plan;
        let arrayIngresos_Fore = this.state.arrayIngresos_Fore;
        let cantidad_meses = this.state.cantidad_meses;
        let cantidad_meses_fr = this.state.cantidad_meses_fr;
        let nuevoTotal = this.state.planificacion.INGRESO_VENTA_PLAN;
        let nuevoTotal_fr = this.state.planificacion.INGRESO_VENTA_PLAN + this.state.ingresos_terceros_total;

        //PLAN
        if (arrayIngresos !== undefined) {
            arrayIngresos.map((e: ArrayIngresos_Plan) => {
                if (this.isClosedMonth(e.ID_MES - 1, e.ANIO) === true) {
                    nuevoTotal = nuevoTotal - e.VALOR;
                    cantidad_meses = cantidad_meses != undefined ? cantidad_meses - 1 : 0;
                }
            });
        }

        if (cantidad_meses !== undefined) {
            ingreso_distribuido = nuevoTotal / cantidad_meses;
        }

        if (arrayIngresos !== undefined) {
            arrayIngresos.map((e: ArrayIngresos_Plan) => {
                if (this.isClosedMonth(e.ID_MES - 1, e.ANIO) === false) {
                    e.VALOR = parseFloat(ingreso_distribuido.toFixed(2));
                }
            });
        }

        //FORECAST
        if (arrayIngresos_Fore !== undefined) {
            arrayIngresos_Fore.map((e: ArrayIngresos_Plan) => {
                if (this.isClosedMonth(e.ID_MES - 1, e.ANIO) === true) {
                    nuevoTotal_fr = nuevoTotal_fr - e.VALOR;
                    cantidad_meses_fr = cantidad_meses_fr != undefined ? cantidad_meses_fr - 1 : 0;
                }
            });
        }

        if (cantidad_meses_fr !== undefined) {
            ingreso_distribuido = nuevoTotal_fr / cantidad_meses_fr;
        }

        if (arrayIngresos_Fore !== undefined) {
            arrayIngresos_Fore.map((e: ArrayIngresos_Plan) => {
                if (this.isClosedMonth(e.ID_MES - 1, e.ANIO) === false) {
                    e.VALOR = parseFloat(ingreso_distribuido.toFixed(2));
                }
            });
        }

        this.setState({
            arrayIngresos_Plan: arrayIngresos,
            arrayIngresos_Fore: arrayIngresos_Fore,
        });
    }

    public verificarProyectoCerrado() {
        let fecha_termino = this.state.forecast.FECHA_TERMINO !== undefined ? this.state.forecast.FECHA_TERMINO : new Date();
        let fecha_cierre_mes = this.state.forecast.FECHA_CIERRE_MES !== undefined ? this.state.forecast.FECHA_CIERRE_MES : new Date();
        let parse_fecha_cierre_string = "";
        if (fecha_termino && fecha_cierre_mes) {
            parse_fecha_cierre_string = fecha_cierre_mes.toString().split("T")[0];

            fecha_cierre_mes = new Date(
                parseInt(parse_fecha_cierre_string.split("-")[0]),
                parseInt(parse_fecha_cierre_string.split("-")[1]) - 1,
                parseInt(parse_fecha_cierre_string.split("-")[2])
            );

            if (fecha_termino.getMonth() === fecha_cierre_mes.getMonth() && fecha_termino.getFullYear() === fecha_cierre_mes.getFullYear()) {
                this.setState({ todosMesesCerrados: false });
            } else if (fecha_termino < fecha_cierre_mes) {
                this.setState({ todosMesesCerrados: true });
            } else {
                this.setState({ todosMesesCerrados: false });
            }
        }
    }
    
    /**
     * Se encarga de revisar los meses cerrados, comparando la ultima fecha vez de cierre con la fecha de cada campo
     *
     * @param {Number} anio - full year (YYYY)
     * @param {Number} mes - month_number is in the range 0..11
     * @returns {Boolean} En caso de ser true, deshabilita el campo, si no, lo deja habilitado
     */
    public isClosedMonth(mes: number, anio: number) {
        let fecha_campo = new Date(anio, mes, 28);
        let fecha_cierre_mes = this.state.forecast.FECHA_CIERRE_MES !== undefined ? this.state.forecast.FECHA_CIERRE_MES : new Date();
        let parse_fecha_string = "";
        parse_fecha_string = fecha_cierre_mes.toString().split("T")[0];

        fecha_cierre_mes = new Date(
            parseInt(parse_fecha_string.split("-")[0]),
            parseInt(parse_fecha_string.split("-")[1]),
            parseInt(parse_fecha_string.split("-")[2])
        );

        if (fecha_campo.getMonth() === fecha_cierre_mes.getMonth() && fecha_campo.getFullYear() === fecha_cierre_mes.getFullYear()) {
            return false;
        } else if (fecha_campo < fecha_cierre_mes) {
            return true;
        } else {
            return false;
        }
    }

    public guardarFechaCierreMes() {
        let fecha_actual = new Date();

        fetchEngine
            .putAPI(`/api/planificacion/${this.state.forecast.ID}`, fecha_actual, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    window.location.reload();
                } else {
                    this.setState({  cerrarMesDisabled: false, guardadoNoOK: false,cargarGuardado: true });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
    
    public async guardarChecklistCierre() {
        this.state.seleccionChecklistCierre.map((idChecklist: any) => {
            fetchEngine
                .postAPI(
                    "/api/planificacion/InsertarChecklistCierre?id_checklist=" +
                    idChecklist +
                    "&id_gestion_proyecto=" +
                    this.state.plan_info.ID_PROYECTO,
                    "",
                    {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.state.tokenAPI}`,
                    }
                )
                .then((resultado: any) => {
                    this.setState({ cargarGuardado: true });
                    if (resultado.ok) {
                        this.setState({ cargarGuardado: false, guardadoOK: true });

                        setTimeout(() => {
                            this.setState({
                                cargarGuardado: true,
                                guardadoOK: false,
                            });
                            setTimeout(() => {
                                this.setState({
                                    cargarGuardado: true,
                                    guardadoOK: true,
                                });
                            }, 1000);
                        }, 1000);
                    } else {
                        this.setState({ guardadoNoOK: false, cargarGuardado: true});
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }

    render() { 
        if (this.state.esAdmin || this.state.esGestion || this.state.esValidador) {
            return (
                <React.Fragment>
                    <BotonAyuda token={this.state.tokenAPI} />
                    <div className="fuente">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                                    {
                                        <div className="iconoRuta">
                                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                            &nbsp;
                                            <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Planificación del Proyecto</span>
                                        </div>
                                    }
                                    {
                                        <div className="titulosimulador">
                                            <Glyphicon glyph="calendar" /> Planificación del Proyecto
                                        </div>
                                    }
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                                    {this.state.ocultarFijado === false ? (
                                        <div className="fijado">
                                            {this.state.plnID > 0 ? (
                                                <DefaultButton text="Volver" onClick={this.redirigirGestion}></DefaultButton>
                                            ) : (
                                                ""
                                            )}
                                            <DefaultButton
                                                text="Salir"
                                                onClick={() => {
                                                    this.setState({
                                                        dialogoSalir: false,
                                                    });
                                                }}
                                            />
                                            <PrimaryButton
                                                text="Staffing"
                                                title="Abre panel Staffing"
                                                onClick={() => {
                                                    this.setState({
                                                        abrirPanel: true,
                                                    });
                                                    this.setState({
                                                        ocultarFijado: true,
                                                    });
                                                }}
                                            />
                                            {this.state.tipoModulo !== "Venta" &&
                                                this.state.plan_info.ID_ESTADO !== 24 &&
                                                this.state.plan_info.ID_ESTADO !== 25 && (
                                                    <React.Fragment>
                                                        <PrimaryButton
                                                            text="Guardar"
                                                            onClick={() => {
                                                                this.setState({ guardarDisabled: true, cargarGuardado: false })
                                                                this.validarProyecto(false);
                                                            }}
                                                            disabled={
                                                                this.state.guardarDisabled
                                                                    ? true
                                                                    : this.state.esAdmin
                                                                        ? false
                                                                        : this.state.tipoModulo === "Planificado" && this.state.plan_info.ID_ESTADO === 10
                                                                            ? true
                                                                            : false
                                                            }
                                                        />
                                                        {this.state.tipoModulo == "Planificado" && (
                                                            <PrimaryButton
                                                                text="Sellar"
                                                                onClick={() => {
                                                                    this.setState({ sellarDisabled: true })
                                                                    this.validarProyecto(true);
                                                                }}
                                                                disabled={this.state.sellarDisabled ? true : this.state.plan_info.ID_ESTADO === 10 ? true : false}
                                                            />
                                                        )}
                                                        {this.state.tipoModulo == "Forecast" && !this.state.todosMesesCerrados && (
                                                            <PrimaryButton
                                                                text="Cerrar mes"
                                                                onClick={() => {
                                                                    this.setState({
                                                                        dialogoCerrarMes: false,
                                                                        cerrarMesDisabled: true,
                                                                    });
                                                                }}
                                                                disabled={this.state.cerrarMesDisabled}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            {this.state.plan_info.ID_ESTADO === 10 &&
                                                this.state.tipoModulo == "Forecast" &&
                                                this.state.todosMesesCerrados && (
                                                    <PrimaryButton
                                                        text="Cierre Ejecución"
                                                        onClick={() => {
                                                            this.setState({
                                                                dialogoCerrarEjecucion: false,
                                                            });
                                                        }}
                                                    />
                                                )}
                                            {this.state.plan_info.ID_ESTADO === 24 && this.state.tipoModulo == "Forecast" && (
                                                <PrimaryButton
                                                    text="Cierre Administrativo"
                                                    onClick={() => {
                                                        this.setState({
                                                            dialogoCerrarAdmin: false,
                                                        });
                                                    }}
                                                />
                                            )}
                                            {/*<a href={"/planificacionproyecto/" + (this.state.plan_info.ID_PROYECTO - 1)}>Anterior</a>*/}
                                            {/*<a href={"/planificacionproyecto/" + (this.state.plan_info.ID_PROYECTO + 1)}>Siguiente</a>*/}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                                    <ComboBox
                                        label="Proyecto"
                                        allowFreeform={true}
                                        autoComplete="on"
                                        placeholder="Seleccionar..."
                                        selectedKey={this.state.plnID > 0 ? this.state.plnID : this.state.plan_info.ID_PROYECTO}
                                        onChange={this._onChangeProyecto}
                                        options={this.state.opciones_proyectos}
                                        disabled={true}></ComboBox>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg4">
                                    <ComboBox
                                        label="Cliente"
                                        allowFreeform={true}
                                        autoComplete="on"
                                        placeholder="Seleccionar..."
                                        disabled={true}
                                        selectedKey={this.state.plan_info.ID_CLIENTE}
                                        onChange={this._onChangeCliente}
                                        options={this.state.opciones_cliente}></ComboBox>
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2"></div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                                    <ComboBox
                                        label="Estado"
                                        allowFreeform={true}
                                        autoComplete="on"
                                        placeholder="Seleccionar..."
                                        disabled={true}
                                        selectedKey={this.state.plan_info.ID_ESTADO}
                                        onChange={this._onChangeEstado}
                                        options={this.state.opciones_estados}></ComboBox>
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4 mt-2">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ">
                                        <nav className="nav nav-pills nav-fill tipoTabs">
                                            <DefaultButton
                                                className={this.state.tipoModulo === "Venta" ? "activo" : "buttonlong"}
                                                onClick={() => {
                                                    this.state.plan_info.ID_ESTADO === 9
                                                        ? this.guardarPlan(false, false)
                                                        : this.setState({
                                                            tipoModulo: "Venta",
                                                        });
                                                    this.moduloVenta();
                                                    this.setState({
                                                        tipoModulo: "Venta",
                                                    });
                                                    this.selectedModulo("Venta");
                                                }}
                                                text="Venta"></DefaultButton>
                                            <DefaultButton
                                                className={this.state.tipoModulo === "Planificado" ? "activo" : "buttonlong"}
                                                onClick={() => {
                                                    this.moduloVenta();
                                                    this.setState({
                                                        tipoModulo: "Planificado",
                                                    });
                                                    this.selectedModulo("Planificado");
                                                    this.obtenerPRY();
                                                }}
                                                text="Planificación"></DefaultButton>
                                            <DefaultButton
                                                className={this.state.tipoModulo === "Forecast" ? "activo" : "buttonlong"}
                                                onClick={() => {
                                                    this.setState({
                                                        tipoModulo: "Forecast",
                                                    });
                                                    this.selectedModulo("Forecast");
                                                    this.obtenerPRY();
                                                }}
                                                text="Ejecución"
                                                disabled={this.state.plan_info.ID_ESTADO === 9 ? true : false}></DefaultButton>
                                        </nav>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 card fondoCard">
                                        {this.state.tipoModulo === "Planificado"
                                            ? this.divPlanificadoFecha()
                                            : this.state.tipoModulo === "Venta"
                                                ? this.divVentaFecha()
                                                : this.state.tipoModulo === "Forecast"
                                                    ? this.divForecastFecha()
                                                    : ""}
                                    </div>
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8 mt-3">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <div className="ms-Grid-col ms-sm0 ms-md0 ms-lg2"></div>
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10">
                                            <label>Cuadros Resumen</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg8">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 text-right mt-2">
                                        <span className="textIndicadorResumen">
                                            Venta 
                                            <Glyphicon glyph="glyphicon glyphicon-play" className="ml-1" />
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 card fondoCard">
                                        <div className="card-body">
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    value={this.formatNumber(parseFloat(this.state.venta.INGRESO_VENTA_VENTA.toFixed(2)).toString())}
                                                    label="Ingreso"></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    value={this.formatNumber(parseFloat(this.state.venta.COSTO_VENTA_VENTA.toFixed(2)).toString())}
                                                    label="Costos"></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    value={this.formatNumber(parseFloat(this.state.venta.RESULTADO_VENTA.toFixed(2)).toString())}
                                                    label="Resultado"></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={porcentaje}
                                                    value={this.formatNumber(
                                                        this.state.venta.RENTABILIDAD_VENTA.toFixed(2).toString().replace(".", ",") + "%"
                                                    )}
                                                    label="Rentabilidad(%)"></TextField>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 text-right mt-3">
                                        <span className="textIndicadorResumen">
                                            Plan {
                                                /*Math.abs(this.state.planificacion.INGRESO_VENTA_PLAN - this.state.xplanificacion_ingreso) > 1 ? "(diferencia ingreso)"
                                                : Math.abs(this.state.planificacion.COSTO_VENTA_PLAN - this.state.xplanificacion_costo) > 1 ? "(diferencia costo)"
                                                : ""*/
                                            }
                                            <Glyphicon glyph="glyphicon glyphicon-play" className="ml-1" />
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 mt-2 card fondoCard">
                                        <div className="card-body">
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    value={this.formatNumber(
                                                        parseFloat(this.state.planificacion.INGRESO_VENTA_PLAN.toFixed(2)).toString()
                                                    ) /*+ (Math.abs(this.state.planificacion.INGRESO_VENTA_PLAN - this.state.xplanificacion_ingreso) > 1 ? "("+this.state.xplanificacion_ingreso+")" : "")*/}
                                                    label="Ingreso"></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    label="Costos"
                                                    value={this.formatNumber(
                                                        parseFloat(this.state.planificacion.COSTO_VENTA_PLAN.toFixed(2)).toString()
                                                    ) /*+ (Math.abs(this.state.planificacion.COSTO_VENTA_PLAN - this.state.xplanificacion_costo) > 1 ? "("+this.state.xplanificacion_costo+")" : "")*/}></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    label="Resultado"
                                                    value={this.formatNumber(
                                                        parseFloat(this.state.planificacion.RESULTADO_PLAN.toFixed(2)).toString()
                                                    )}></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={porcentaje}
                                                    label="Rentabilidad(%)"
                                                    value={this.formatNumber(
                                                        this.state.planificacion.RENTABILIDAD_PLAN.toFixed(2).toString().replace(".", ",") + "%"
                                                    )}></TextField>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 text-right mt-3">
                                        <span className="textIndicadorResumen">
                                            Forecast {
                                                /*Math.abs(this.state.forecast.INGRESO_VENTA_FORECAST - this.state.xforecast_ingreso) > 1 ? "(diferencia ingreso)"
                                                : Math.abs(this.state.forecast.COSTO_VENTA_FORECAST - this.state.xforecast_costo) > 1 ? "(diferencia costo)"
                                                : ""*/
                                            }
                                            <Glyphicon glyph="glyphicon glyphicon-play" className="ml-1" />
                                        </span>
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 card fondoCard mt-2">
                                        <div className="card-body">
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    value={this.formatNumber(
                                                        parseFloat(this.state.forecast.INGRESO_VENTA_FORECAST.toFixed()).toString()
                                                    ) /*+ (Math.abs(this.state.forecast.INGRESO_VENTA_FORECAST - this.state.xforecast_ingreso) > 1 ? "("+this.state.xforecast_ingreso+")" : "")*/}
                                                    label="Ingreso"></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    label="Costos"
                                                    value={this.formatNumber(
                                                        parseFloat(this.state.forecast.COSTO_VENTA_FORECAST.toFixed()).toString()
                                                    ) /*+ (Math.abs(this.state.forecast.COSTO_VENTA_FORECAST - this.state.xforecast_costo) > 1 ? "("+this.state.xforecast_costo+")" : "")*/}></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={moneda}
                                                    label="Resultado"
                                                    value={this.formatNumber(
                                                        parseFloat(this.state.forecast.RESULTADO_FORECAST.toFixed()).toString()
                                                    )}></TextField>
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                                <TextField
                                                    disabled={true}
                                                    iconProps={porcentaje}
                                                    label="Rentabilidad(%)"
                                                    value={this.formatNumber(
                                                        this.state.forecast.RENTABILIDAD_FORECAST.toFixed(2).toString().replace(".", ",") + "%"
                                                    )}></TextField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.tipoModulo === "Venta" ? (
                                ""
                            ) : (
                                <div>
                                    <div className="tituloDistribucionIngresos">
                                        {this.state.tipoModulo === "Planificado"
                                            ? "Distribución Ingresos Planificación"
                                            : "Distribución Ingresos Forecast"}
                                    </div>
                                    <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                        <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg2 txtSmall">
                                            <TextField
                                                label="Total Ingresos Distribuidos"
                                                id={"totalIngresos"}
                                                value={
                                                    this.state.tipoModulo === "Planificado"
                                                        ? this.state.planificacion.TOTAL_INGRESOS_DIST === 0
                                                            ? ""
                                                            : this.formatNumber(
                                                                parseFloat(this.state.planificacion.TOTAL_INGRESOS_DIST.toFixed()).toString()
                                                            )
                                                        : this.state.forecast.TOTAL_INGRESOS_DIST === 0
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(this.state.forecast.TOTAL_INGRESOS_DIST).toString()
                                                }
                                                defaultValue={
                                                    this.state.tipoModulo === "Planificado"
                                                        ? this.state.planificacion.TOTAL_INGRESOS_DIST === 0
                                                            ? ""
                                                            : this.formatNumber(
                                                                parseFloat(this.state.planificacion.TOTAL_INGRESOS_DIST.toFixed()).toString()
                                                            )
                                                        : this.state.forecast.TOTAL_INGRESOS_DIST === 0
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(this.state.forecast.TOTAL_INGRESOS_DIST).toString()
                                                }
                                                disabled={true}
                                                placeholder={"..."}
                                            />
                                        </div>
                                        {this.state.tipoModulo === "Planificado" ? (
                                            ""
                                        ) : (
                                            <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg2 txtSmall">
                                                <TextField
                                                    label="Ingresos Terceros Adicionales"
                                                    id={"totalIngresosAdicionales"}
                                                    value={
                                                        this.state.ingresos_terceros_total === 0
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(this.state.ingresos_terceros_total).toString()
                                                    }
                                                    defaultValue={
                                                        this.state.ingresos_terceros_total === 0
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(this.state.ingresos_terceros_total).toString()
                                                    }
                                                    disabled={true}
                                                    placeholder={"..."}
                                                />
                                            </div>
                                        )}
                                        <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                            <MessageBar
                                                messageBarType={
                                                    this.state.tipoModulo === "Planificado"
                                                        ? this.state.planificacion.TOTAL_INGRESOS_DIST > this.state.planificacion.INGRESO_VENTA_PLAN
                                                            ? MessageBarType.warning
                                                            : this.state.planificacion.TOTAL_INGRESOS_DIST <
                                                                this.state.planificacion.INGRESO_VENTA_PLAN
                                                                ? MessageBarType.warning
                                                                : MessageBarType.success
                                                        : this.state.forecast.TOTAL_INGRESOS_DIST >
                                                            this.state.planificacion.INGRESO_VENTA_PLAN + this.state.ingresos_terceros_total
                                                            ? MessageBarType.warning
                                                            : this.state.forecast.TOTAL_INGRESOS_DIST <
                                                                this.state.planificacion.INGRESO_VENTA_PLAN + this.state.ingresos_terceros_total
                                                                ? MessageBarType.warning
                                                                : MessageBarType.success
                                                }
                                                isMultiline={false}
                                                dismissButtonAriaLabel="Close">
                                                {this.state.tipoModulo === "Planificado"
                                                    ? this.state.planificacion.TOTAL_INGRESOS_DIST > this.state.planificacion.INGRESO_VENTA_PLAN
                                                        ? `Sobran ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat(
                                                                (
                                                                    this.state.planificacion.TOTAL_INGRESOS_DIST -
                                                                    this.state.planificacion.INGRESO_VENTA_PLAN
                                                                ).toFixed(2)
                                                            )
                                                        )} ingresos distribuidos.`
                                                        : this.state.planificacion.TOTAL_INGRESOS_DIST < this.state.planificacion.INGRESO_VENTA_PLAN
                                                            ? `Faltan ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                                parseFloat(
                                                                    (
                                                                        this.state.planificacion.INGRESO_VENTA_PLAN -
                                                                        this.state.planificacion.TOTAL_INGRESOS_DIST
                                                                    ).toFixed(2)
                                                                )
                                                            )} ingresos distribuidos.`
                                                            : "Los ingresos están correctamente distribuidos."
                                                    : this.state.forecast.INGRESO_VENTA_FORECAST >
                                                        this.state.planificacion.INGRESO_VENTA_PLAN + this.state.ingresos_terceros_total
                                                        ? `Sobran ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat(
                                                                (
                                                                    this.state.forecast.INGRESO_VENTA_FORECAST -
                                                                    (this.state.planificacion.INGRESO_VENTA_PLAN + this.state.ingresos_terceros_total)
                                                                ).toFixed(2)
                                                            )
                                                        )} ingresos distribuidos. El valor de referencia es: ${this.formatNumber(
                                                            (
                                                                parseFloat(this.state.planificacion.INGRESO_VENTA_PLAN.toFixed(2)) +
                                                                parseFloat(this.state.ingresos_terceros_total.toFixed(2))
                                                            ).toString()
                                                        )}`
                                                        : this.state.forecast.INGRESO_VENTA_FORECAST <
                                                            this.state.planificacion.INGRESO_VENTA_PLAN + this.state.ingresos_terceros_total
                                                            ? `Faltan ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                                parseFloat(
                                                                    (
                                                                        this.state.planificacion.INGRESO_VENTA_PLAN +
                                                                        this.state.ingresos_terceros_total -
                                                                        this.state.forecast.INGRESO_VENTA_FORECAST
                                                                    ).toFixed(2)
                                                                )
                                                            )} ingresos distribuidos. El valor de referencia es: ${this.formatNumber(
                                                                (
                                                                    parseFloat(this.state.planificacion.INGRESO_VENTA_PLAN.toFixed(2)) +
                                                                    parseFloat(this.state.ingresos_terceros_total.toFixed(2))
                                                                ).toString()
                                                            )}`
                                                            : `Los ingresos están correctamente distribuidos. El valor de referencia es: ${this.formatNumber(
                                                                (
                                                                    parseFloat(this.state.planificacion.INGRESO_VENTA_PLAN.toFixed(2)) +
                                                                    parseFloat(this.state.ingresos_terceros_total.toFixed(2))
                                                                ).toString()
                                                            )}`}
                                            </MessageBar>
                                        </div>
                                        {this.state.tipoModulo === "Forecast" ? (
                                            ""
                                        ) : (
                                            <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg2 btnDistribucion">
                                                <PrimaryButton
                                                    text="Distribución automática Plan"
                                                    onClick={() => this.calcularDistribucion()}
                                                    disabled={
                                                        this.state.plan_info.ID_ESTADO === 24 || this.state.plan_info.ID_ESTADO === 25
                                                            ? true
                                                            : this.state.esAdmin
                                                                ? false
                                                                : this.state.tipoModulo === "Planificado" && this.state.plan_info.ID_ESTADO === 10
                                                                    ? true
                                                                    : false
                                                    }
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {/* calendario semanas */}
                                    {this.state.total_year !== undefined
                                        ? this.state.total_year.map((a: any) => {
                                            let arr_months2 = [];
                                            let arr_months: any[] = [];
                                            for (let indexA = 1; indexA <= 12; indexA++) {
                                                arr_months.push({
                                                    mes: indexA,
                                                    value: this.valueMonth(
                                                        a.years,
                                                        indexA,
                                                        this.state.tipoModulo !== undefined ? this.state.tipoModulo : ""
                                                    ),
                                                });
                                                arr_months2 = [];
                                            }

                                            return (
                                                <div>
                                                    <div className="ms-Grid-row bloqueActividad mt-3" id="bloqueActividad">
                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2">
                                                            <div className="table-responsive fondoTabla">
                                                                <table className="table table-sm tablaGastos">
                                                                    <thead>
                                                                        <tr>
                                                                            {a.years === a.inicio_year && a.inicio_mes > 1 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">Enero {a.years}</span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">
                                                                                            Febrero {a.years}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">Marzo {a.years}</span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">Abril {a.years}</span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">Mayo {a.years}</span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">Junio {a.years}</span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">Julio {a.years}</span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">
                                                                                            Agosto {a.years}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">
                                                                                            Septiembre {a.years}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">
                                                                                            Octubre {a.years}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">
                                                                                            Noviembre {a.years}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                ""
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td colSpan={1}>
                                                                                        <span className="badge badge-primary">
                                                                                            Diciembre {a.years}
                                                                                        </span>
                                                                                    </td>
                                                                                    <td></td>
                                                                                </React.Fragment>
                                                                            )}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            {(a.years === a.inicio_year && a.inicio_mes > 1) === false ? (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[0].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 1, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"ENE-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 1, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[0].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[0].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[0].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[0].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(1, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            ) : arr_months[0].value > 0 ? (
                                                                                this.agregarReservaArray(a.years, 1, 0)
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 1 ? "" : <td></td>}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                arr_months[1].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 2, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[1].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 2, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"FEB-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 2, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[1].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[1].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[1].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[1].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(2, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                arr_months[2].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 3, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[2].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 3, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"MAR-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 3, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[2].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[2].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[2].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[2].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(3, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                arr_months[3].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 4, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[3].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 4, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"ABR-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 4, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[3].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[3].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[3].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[3].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(4, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                arr_months[4].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 5, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[4].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 5, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"MAY-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 5, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[4].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[4].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[4].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[4].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(5, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                arr_months[5].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 6, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[5].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 6, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"JUN-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 6, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[5].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[5].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[5].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[5].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(6, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                arr_months[6].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 7, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[6].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 7, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"JUL-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 7, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[6].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[6].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[6].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[6].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(7, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                arr_months[7].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 8, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[7].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 8, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"AGO-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 8, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[7].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[7].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[7].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[7].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(8, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                arr_months[8].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 9, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[8].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 9, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"SEP-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 9, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[8].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[8].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[8].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[8].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(9, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                arr_months[9].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 10, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[9].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 10, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"OCT-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 10, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[9].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[9].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[9].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[9].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(10, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                arr_months[10].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 11, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[10].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 11, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"NOV-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 11, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[10].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[10].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[10].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[10].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(11, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}

                                                                            {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                arr_months[11].value > 0 ? (
                                                                                    this.agregarReservaArray(a.years, 12, 0)
                                                                                ) : (
                                                                                    ""
                                                                                )
                                                                            ) : (
                                                                                <React.Fragment>
                                                                                    <td>
                                                                                        {arr_months[11].value < 0
                                                                                            ? this.agregarReservaArray(a.years, 12, 0)
                                                                                            : ""}
                                                                                        <span className={"formateoDistribucion"}>
                                                                                            <NumberFormat
                                                                                                id={"DIC-" + a.years}
                                                                                                onValueChange={(element) => {
                                                                                                    this.onReservaChange(a.years, 12, element);
                                                                                                }}
                                                                                                defaultValue={
                                                                                                    arr_months[11].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[11].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                value={
                                                                                                    arr_months[11].value <= 0
                                                                                                        ? ""
                                                                                                        : arr_months[11].value
                                                                                                            .toString()
                                                                                                            .replace(".", ",")
                                                                                                }
                                                                                                thousandSeparator={"."}
                                                                                                decimalSeparator={","}
                                                                                                placeholder={"..."}
                                                                                                disabled={
                                                                                                    this.isClosedMonth(12, a.years) === true
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    </td>
                                                                                </React.Fragment>
                                                                            )}
                                                                            {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                ""
                                                                            ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                ""
                                                                            ) : (
                                                                                <td></td>
                                                                            )}
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                        : ""}
                                    {/* fin calendario semanas */}
                                </div>
                            )}

                            <br></br>
                            {this.state.tipoModulo === "Planificado"
                                ? this.divPlanificado()
                                : this.state.tipoModulo === "Venta"
                                    ? this.divVenta()
                                    : this.state.tipoModulo === "Forecast"
                                        ? this.divForecast()
                                        : ""}

                            {this.panelStaffing()}
                            {this.dialogoSalir()}
                            {this.validacionProyecto()}
                            {this.cargandoGuardado()}
                            {this.guardadoOk()}
                            {this.guardadoNoOk()}
                            {this.dialogoFechaInicioMayor()}
                            {this.dialogoFechaFinMenor()}
                            {this.dialogCerrarMes()}
                            {this.dialogCerrarEjecucion()}
                            {this.dialogCerrarAdmin()}
                            {this.dialogLiberarSellos()}
                        </div>
                    </div>
                </React.Fragment>
            );
        } else if (this.state.esUsuario) {
            return (
                <React.Fragment>
                    <div className="mt-4 card sinAcceso text-primary">
                        <div className="card-header">Atención</div>
                        <div className="card-body">
                            <h6>
                                <Glyphicon glyph="info-sign" className="mr-1" /> Usted no posee los permisos para visualizar esta información, si
                                desea obtener los permisos, contactese con el administrador del sistema.
                            </h6>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="mt-4 card sinAcceso text-primary"></div>
                </React.Fragment>
            );
        }
    }

    public validacionProyecto() {
        return (
            <Dialog
                hidden={this.state.validacionProyecto}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Error al sellar el proyecto",
                    subText: "Debe distribuir los ingresos del proyecto o las HH de los roles.",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ validacionProyecto: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public cargandoGuardado() {
        return (
            <Dialog
                hidden={this.state.cargarGuardado}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Guardando",
                    subText: "Favor espere...",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <div className="spinner">
                    <Spinner mnj="" />
                </div>
            </Dialog>
        );
    }

    public guardadoOk() {
        return (
            <Dialog
                hidden={this.state.guardadoOK}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Se ha guardado éxitosamente",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { textAlign: "center" } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ guardadoOK: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public guardadoNoOk() {
        return (
            <Dialog
                hidden={this.state.guardadoNoOK}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: this.state.errorMessage.length > 0 ? this.state.errorMessage : "Ocurrió un error al guardar, favor contacte a su administrador.",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { textAlign: "center" } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ errorMessage: "", guardadoNoOK: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoFechaInicioMayor() {
        return (
            <Dialog
                hidden={this.state.fecha_inicio_mayor}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Fecha incorrecta",
                    subText: "La fecha de inicio no puede ser superior a la de término.",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ fecha_inicio_mayor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoFechaFinMenor() {
        return (
            <Dialog
                hidden={this.state.fecha_fin_menor}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Fecha incorrecta",
                    subText: "La fecha de fin no puede ser inferior a la de inicio.",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Aceptar"
                        onClick={() => {
                            this.setState({ fecha_fin_menor: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogCerrarMes() {
        return (
            <Dialog
                hidden={this.state.dialogoCerrarMes}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "¿Está seguro de cerrar mes?",
                    subText: "Se guardará toda la información y se actualizarán los valores pronosticados por valores reales.",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Confirmar"
                        onClick={() => {
                            this.guardarForecast(false, false, false, true);
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoCerrarMes: true, cerrarMesDisabled: false });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    
    public dialogCerrarAdmin() {
        return (
            <Dialog
                hidden={this.state.dialogoCerrarAdmin}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "¿Está seguro de cerrar administrativamente el proyecto?",
                    subText: "NO podrá realizar cambios en el proyecto.",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Confirmar"
                        onClick={() => {
                            this.guardarForecast(false, false, true);
                            this.setState({ dialogoCerrarAdmin: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoCerrarAdmin: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    
    public dialogCerrarEjecucion() {
        const stackTokens = { childrenGap: 10 };
        return (
            <Dialog
                hidden={this.state.dialogoCerrarEjecucion}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "¿Está seguro de cerrar la ejecución del proyecto?",
                    subText: "No podrá cargar horas o asignar roles al proyecto. REVISE con detalle el siguiente checklist:",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { minWidth: 450 } },
                }}>
                {/* <ChoiceGroup options={this.state.checklistCierre} /> */}
                <Stack tokens={stackTokens}>
                    {this.state.checklistCierre.map((checkBoxItem) => {
                        return <Checkbox label={checkBoxItem.text} title={checkBoxItem.key.toString()} onChange={this._onChangeChecklistCierre} />;
                    })}
                </Stack>
                <DialogFooter>
                    <PrimaryButton
                        text="Confirmar"
                        onClick={() => {
                            this.guardarForecast(true, true, false, true);
                            this.setState({ dialogoCerrarEjecucion: true });
                        }}
                        disabled={this.todosCheckboxChecklistSeleccionados() === true ? false : true}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.todosCheckboxChecklistSeleccionados();
                            this.setState({ dialogoCerrarEjecucion: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    
    public dialogVolverEjecucion() {
        return (
            <Dialog
                hidden={this.state.dialogoVolverEjecucion}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "¿Está seguro de que el proyecto vuelva al estado Ejecución?",
                    subText: "Se le permitirá modificar el proyecto.",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Confirmar"
                        onClick={() => {
                            this.guardarForecast(false, false, false);
                            this.setState({ dialogoCerrarEjecucion: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoCerrarEjecucion: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
    
    public dialogLiberarSellos() {
        return (
            <Dialog
                hidden={this.state.dialogoLiberarSellos}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "¿Está seguro de liberar los sellos?",
                    subText: "Se liberaran los sellos para su edición",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Confirmar"
                        onClick={() => {
                            this.liberarSellos();
                            this.setState({ dialogoLiberarSellos: true });
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoLiberarSellos: true });
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    }
}

export default PlanificacionProyecto;
