import React, { useState } from "react";
import "../../custom.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import "./Simulador.css";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib-commonjs/DatePicker";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { TextField, MaskedTextField, ITextFieldStyles } from "office-ui-fabric-react/lib/TextField";
import { Accordion } from "../../controles/Accordion/Accordion";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ComboBox } from "office-ui-fabric-react";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import axios from "axios";
import punteroTitulo from "../../img/iconCursor.svg";
import { Ingreso } from "../../controles/entidades/SimuladorPresupuesto";
import { SimuladorPresupuesto, IngresoData, IngresoTercerosData, IngresoGastosData } from "../../controles/entidades/SimuladorPresupuesto";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import IngresosDirectos from "./IngresosDirectos";
import IngresosTerceros from "./IngresosTerceros";
import { fetchEngine } from "../../fetchData";
import IngresosGastos from "./Gastos";
import NumberFormat from "react-number-format";
import BotonAyuda from "../BotonAyuda/BotonAyuda";
initializeIcons();

const DayPickerStrings: IDatePickerStrings = {
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    shortDays: ["D", "L", "M", "M", "J", "V", "S"],
    goToToday: "Ir a hoy",
    prevMonthAriaLabel: "Ir al mes previo",
    nextMonthAriaLabel: "Ir al mes siguiente",
    prevYearAriaLabel: "Ir al año anterior",
    nextYearAriaLabel: "Ir al año siguiente",
    closeButtonAriaLabel: "Cerrar el selector de fechas",
};
const controlClass = mergeStyleSets({
    control: {
        margin: "0 0 15px 0",
        maxWidth: "300px",
    },
});

export class SimuladorProps {}
export class SimuladorState {
    sim_info!: SimuladorPresupuesto;
    collapsed?: boolean;
    dialogoGuardar?: boolean;
    dialogoErrorNombre?: boolean;
    dialogoGuardar2?: boolean;
    dialogoGuardarError?: boolean;
    dialogoAprobar: boolean = true;
    estadoFormulario?: boolean;
    dialogoIngresar?: boolean;
    roles: any[] = [];

    actividadRoles: Ingreso[] = [];
    actividadTerceros: any[] = [];
    actividadGastos: any[] = [];

    ingresosDirectos: IngresoData[] = [];
    ingresosTerceros: IngresoTercerosData[] = [];
    ingresosGastos: IngresoGastosData[] = [];

    nombreActividad!: string;
    nombreActividadTerceros!: string;
    nombreActividadGastos!: string;

    opoID: number = 0;
    hasID?: boolean;
    simID: number = 0;

    esAdmin?: boolean;
    esUsuario?: boolean;
    esGestion?: boolean;
    esValidador?: boolean;

    opciones_unidad_negocio?: any[];
    opciones_monedas?: any[];
    opciones_cttomarco?: any[] = [];
    opciones_cttomarco_aux?: any[];
    opciones_tipoctto?: any[];
    opciones_roles_aux?: any[];
    opciones_roles?: any[];
    opciones_item?: any[];
    opciones_proyecto?: any[];
    opciones_estado?: any[];
    opciones_categoria?: any[];

    plan_horas_d: number = 0;
    plan_ingresos_d: number = 0;
    plan_costo_d: number = 0;

    rentabilidad_plan_t: number = 0;
    plan_ingresos_t: number = 0;
    plan_costo_t: number = 0;

    plan_gasto_gr: number = 0;
    plan_recuperable_gr: number = 0;
    plan_diferencia_gr: number = 0;

    plan_gasto_gp: number = 0;

    largo_ingresos: number = 0;

    descuento_volumen_dcto: number = 0;
    resultado_dcto: number = 0;
    rentabilidad_dcto: number = 0;
    tarifa_promedio_dcto: number = 0;

    yearprimero_directo!: number;
    yearultimo_directo!: number;
    mesprimero_directo!: number;
    mesulimo_directo!: number;
    yeartotal_directo!: any[];
    horasxdia!: number;
    moneda_cto!: string;
    tipo_contrato_select!: string;

    clientID: string = "";

    moneda_ctto: string = "";
    tokenAPI: string = "";
}

export class Simulador extends React.Component<SimuladorProps, SimuladorState> {
    constructor(props: any) {
        super(props);
        let pathName = window.location.pathname;

        let params = pathName.split("/");

        let hasID = params.length > 2;

        this.state = {
            sim_info: {
                ID: hasID ? parseInt(params[2]) : 0,
                ID_OPORTUNIDAD: 0,
                NOMBRE_PROYECTO: "",
                FECHA_DESDE: undefined,
                FECHA_HASTA: undefined,
                FECHA_APROBACION: undefined,
                UF: 0,
                PESOS: 0,
                USD: 0,
                AUD: 0,
                SOLES: 0,
                HORAS_DIA: 0,
                ID_TIPO_CONTRATO: 0,
                ID_UNIDAD: 0,
                ID_CONTRATO_MARCO: 0,
                INGRESO_VENTA: 0,
                INGRESO_VENTA_DCTO: 0,
                COSTO_VENTA: 0,
                RESULTADO: 0,
                RESULTADO_DCTO: 0,
                RENTABILIDAD: 0,
                DESCUENTO_VOLUMEN: 0,
                FINDER_FEE: 0,
                TOTAL_HORAS: 0,
                TARIFA_PROMEDIO: 0,
                ID_MONEDA: 0,
                INGRESOS_DIRECTOS: [],
                INGRESOS_TERCEROS: [],
                GASTOS: [],
                CREADO_POR: "",
                MODIFICADO_POR: "",
                NOMBRE: "",
                ID_ESTADO: 7,
                MONEDA: "",
                INGRESO_VENTA_CONVERSION: 0,
                COSTO_VENTA_CONVERSION: 0,
                RESULTADO_CONVERSION: 0,
                RENTABILIDAD_CONVERSION: 0,
                TARIFA_PROMEDIO_CONVERSION: 0,
                INGRESO_VENTA_DCTO_CONVERSION: 0,
            },
            dialogoGuardar: true,
            dialogoGuardar2: true,
            dialogoErrorNombre: true,
            dialogoGuardarError: true,
            dialogoAprobar: true,
            estadoFormulario: false,
            dialogoIngresar: true,
            roles: [],

            actividadRoles: [],
            actividadTerceros: [],
            actividadGastos: [],

            ingresosDirectos: [],
            ingresosTerceros: [],
            ingresosGastos: [],

            nombreActividad: "",
            nombreActividadTerceros: "",
            nombreActividadGastos: "",

            hasID: hasID,
            simID: hasID ? parseInt(params[2]) : 0,
            opoID: hasID ? parseInt(params[3]) : 0,
            opciones_unidad_negocio: [],
            opciones_monedas: [],
            opciones_tipoctto: [],
            opciones_cttomarco: [],
            opciones_cttomarco_aux: [],
            opciones_roles_aux: [],
            opciones_item: [],
            opciones_proyecto: [],
            opciones_estado: [],
            opciones_categoria: [],

            plan_horas_d: 0,
            plan_ingresos_d: 0,
            plan_costo_d: 0,

            rentabilidad_plan_t: 0,
            plan_ingresos_t: 0,
            plan_costo_t: 0,

            plan_gasto_gr: 0,
            plan_recuperable_gr: 0,
            plan_diferencia_gr: 0,

            plan_gasto_gp: 0,

            largo_ingresos: 0,

            descuento_volumen_dcto: 0,
            resultado_dcto: 0,
            rentabilidad_dcto: 0,
            tarifa_promedio_dcto: 0,

            yearprimero_directo: 0,
            yearultimo_directo: 0,
            yeartotal_directo: [],
            mesprimero_directo: 0,
            mesulimo_directo: 0,
            horasxdia: 0,
            moneda_cto: "",
            tipo_contrato_select: "",

            clientID: process.env.REACT_APP_SCOPE_API ? process.env.REACT_APP_SCOPE_API : "",
            moneda_ctto: "",
            esAdmin: false,
            esUsuario: false,
            esGestion: false,
            esValidador: false,
            tokenAPI: "",
        };
        this.procesar();
        this.obtenerMeses();
        this._onFormatDate = this._onFormatDate.bind(this);
        this.guardarSIM = this.guardarSIM.bind(this);
        this.callbackIngresos = this.callbackIngresos.bind(this);
        this.callbackActividades = this.callbackActividades.bind(this);
        this.callbackIngresosTerceros = this.callbackIngresosTerceros.bind(this);
        this.callbackIngresosGastos = this.callbackIngresosGastos.bind(this);
        this.IngresoVenta = this.IngresoVenta.bind(this);
        this.DatosPlan = this.DatosPlan.bind(this);
    }

    componentDidMount() {
        if (authProvider != undefined) {
            let simuladorID = this.state.simID != null ? this.state.simID : 0;
            new Promise<any>(async (resolve, reject) => {
                const tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                this.setState({ tokenAPI: d }, () => {
                    this.verificarAdmin();
                    this.verificarUsuario();
                    this.verificarGestion();
                    this.verificarValidador();
                    this.obtenerValores();
                    if (simuladorID) {
                        this.obtenerSIM();
                    }
                    if (this.state.opoID > 0) {
                        this.obtenerOP();
                    }
                });
            });
        }
    }

    //CONSULTAS PERFILAMIENTO
    public verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esAdmin: result.ok });
            });
    };

    public verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esUsuario: result.ok });
            });
    };

    public verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esGestion: result.ok });
            });
    };

    public verificarValidador = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((result: any) => {
                this.setState({ esValidador: result.ok });
            });
    };

    private _onFormatDate = (date: Date | undefined): string => {
        //////console.log(date);
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    private async obtenerValores() {
        let sim = this.state.sim_info;
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.state.tokenAPI}`,
            })
            .then((resultado: any) => {
                ////console.log(resultado);
                //let resultado = result.data;
                if (resultado.ok) {
                    // console.log(resultado.data);
                    const ctm: any[] = [];
                    const mnd: any[] = [];
                    const unn: any[] = [];
                    const tcn: any[] = [];
                    const item: any[] = [];
                    const car: any[] = [];
                    const pro: any[] = [];
                    const est: any[] = [];
                    const categorias: any[] = [];
                    if (resultado.data) {
                        {
                            resultado.data.contratos_marco.map((a: any) => {
                                ctm.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    activo: a.ACTIVO,
                                    horas: a.HORA_DIA,
                                    id_unidad: a.ID_UNIDAD_NEGOCIO,
                                    id_moneda: a.ID_MONEDA,
                                });
                            });
                        }
                        {
                            resultado.data.monedas.map((a: any) => {
                                mnd.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    VALOR_AUD: a.VALOR_AUD,
                                    VALOR_CLP: a.VALOR_CLP,
                                    VALOR_SOLES: a.VALOR_SOLES,
                                    VALOR_UF: a.VALOR_UF,
                                    VALOR_USD: a.VALOR_USD,
                                });
                            });
                        }
                        {
                            resultado.data.unidades_negocios.map((a: any) => {
                                unn.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                });
                            });
                        }
                        {
                            resultado.data.tipo_contrato.map((a: any) => {
                                tcn.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    disabled: !a.ACTIVO,
                                });
                            });
                        }
                        {
                            resultado.data.items.map((a: any) => {
                                item.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                });
                            });
                        }
                        {
                            resultado.data.cargos.map((a: any) => {
                                car.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    id_contrato_marco: a.ID_CONTRATO_MARCO,
                                    id_unidad: a.ID_UNIDAD,
                                    tarifa: a.TARIFA,
                                    costo: a.COSTO,
                                });
                            });
                        }
                        {
                            resultado.data.oportunidades.map((a: any) => {
                                //if(a.CREADO_POR == userName.account.userName && a.NOMBRE != ""){
                                pro.push({
                                    key: a.ID,
                                    text: a.NOMBRE,
                                    unidad: a.ID_UNIDAD,
                                    contrato_marco: a.ID_CONTRATO_MARCO,
                                    f_inicio: a.FECHA_INICIO,
                                    f_fin: a.FECHA_TERMINO,
                                    moneda: a.MONEDA,
                                    clp: a.PESOS,
                                    uf: a.UF,
                                    soles: a.SOLES,
                                    usd: a.USD,
                                    aud: a.AUD,
                                    moneda_ctto: a.MONEDA_CTTO,
                                });
                                // }
                            });
                        }
                        {
                            resultado.data.estados.map((a: any) => {
                                if (a.ID_MODULO === 2) {
                                    est.push({
                                        key: a.ID,
                                        text: a.TITULO,
                                    });
                                }
                            });
                        }
                        {
                            resultado.data.categoria_rendicion.map((a: any) => {
                                if (a.ES_ADMINISTRATIVO === false) {
                                    categorias.push({
                                        key: a.ID,
                                        text: a.TITULO,
                                    });
                                }
                            });
                        }

                        ////console.log(mnd);

                        this.setState({
                            opciones_cttomarco_aux: ctm,
                            opciones_monedas: mnd,
                            opciones_unidad_negocio: unn,
                            opciones_item: item,
                            opciones_roles_aux: car,
                            opciones_tipoctto: tcn,
                            opciones_proyecto: pro,
                            opciones_estado: est,
                            opciones_categoria: categorias,
                        });
                        ////console.log(this.state.opciones_estado);

                        if (this.state.opciones_cttomarco_aux != undefined) {
                            this.state.opciones_cttomarco_aux.map((a: any) => {
                                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                                    this.setState({ horasxdia: a.horas });
                                }
                            });
                        }

                        if (this.state.opciones_cttomarco_aux != undefined) {
                            this.state.opciones_cttomarco_aux.map((a: any) => {
                                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                                    this.setState({ horasxdia: a.horas });
                                    this.state.opciones_monedas!.map((b: any) => {
                                        if (b.key === a.id_moneda) {
                                            this.setState({
                                                moneda_cto: b.text,
                                            });
                                            ////console.log(this.state.moneda_cto);
                                        }
                                    });
                                }
                            });
                        }

                        this.cargarHerencia();

                        /* ////console.log(this.state.opciones_monedas)
                        ////console.log(sim.MONEDA)
                        if(this.state.opciones_monedas !== undefined){
                            this.state.opciones_monedas.map((e:any) => {
                                if(sim.MONEDA == e.TITULO){
                                    //console.log(e)
                                    sim.AUD = e.VALOR_AUD;
                                    sim.PESOS = e.VALOR_CLP;
                                    sim.SOLES = e.VALOR_SOLES;
                                    sim.UF = e.VALOR_UF;
                                    sim.USD = e.VALOR_USD;
                                };
                            });
                        }

                         this.setState({sim_info:sim}) */
                    }
                } else {
                    ////console.log(resultado.data);
                }
            })
            .catch((err) => {
                ////console.log(err);
            });
    }

    public procesar() {
        let sim = this.state.sim_info;
    }

    public cargarHerencia() {
        if (this.state.sim_info.ID_UNIDAD != undefined && this.state.sim_info.ID_UNIDAD > 0 && this.state.opciones_cttomarco_aux != undefined) {
            let opciones_ctto_marco2: any[] = [];
            this.state.opciones_cttomarco_aux.map((a: any) => {
                if (a.id_unidad === this.state.sim_info.ID_UNIDAD) {
                    opciones_ctto_marco2.push({
                        key: a.key,
                        text: a.text,
                    });
                }
            });
            this.setState({ opciones_cttomarco: opciones_ctto_marco2 });
        }

        /* if(this.state.sim_info.ID_UNIDAD != undefined && this.state.sim_info.ID_UNIDAD > 0 && this.state.opciones_roles_aux != undefined){
            let opciones_roles2: any[] = [];
            this.state.opciones_roles_aux.map( (a:any) => {
                if(a.id_unidad === this.state.sim_info.ID_UNIDAD){
                    opciones_roles2.push({
                        key: a.key,
                        text: a.text
                    })
                }
            })
            this.setState({ opciones_roles: opciones_roles2 });
        } */
        ////console.log(this.state.opciones_roles_aux)
        console.log(this.state.sim_info.ID_CONTRATO_MARCO);
        console.log("ctto marco");
        if (
            this.state.sim_info.ID_CONTRATO_MARCO != undefined &&
            this.state.sim_info.ID_CONTRATO_MARCO > 0 &&
            this.state.opciones_roles_aux != undefined
        ) {
            let opciones_roles2: any[] = [];
            console.log("if ctto marco");
            this.state.opciones_roles_aux.map((a: any) => {
                if (a.id_contrato_marco === this.state.sim_info.ID_CONTRATO_MARCO) {
                    opciones_roles2.push({
                        key: a.key,
                        text: a.text,
                    });
                }
            });
            // this.setState({ opciones_roles: opciones_roles2 });
        }
    }

    private cambiarestadoformulario() {
        this.setState({ estadoFormulario: true });
    }

    public textFieldOnChange(ev: any, index: number) {
        if (this.state.estadoFormulario === false) {
            this.setState({ estadoFormulario: true });
        }

        let key = ev.target.id;
        let value = ev.target.value;
        let sim = this.state.sim_info;
        let val = 0;

        switch (key) {
            // Cabecera
            case "horasDia":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 99) {
                    sim.HORAS_DIA = val;
                }
                break;
            case "UF":
                val = value === "" ? 0 : parseFloat(value);
                //if (val <= 99999) {
                sim.UF = val;
                //}
                break;
            case "CLP":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 9999) {
                    sim.PESOS = val;
                }
                break;
            case "USD":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 9999) {
                    sim.USD = val;
                }
                break;
            case "AUD":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 9999) {
                    sim.AUD = val;
                }
                break;
            case "soles":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 9999) {
                    sim.SOLES = val;
                }
                break;
            // Resumen
            case "ingresoVentaResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val > 0) {
                    sim.INGRESO_VENTA = val;
                }
                break;
            case "ingresoVentaConversionResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val > 0) {
                    sim.INGRESO_VENTA_CONVERSION = val;
                }
                break;
            case "costoVentaResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.COSTO_VENTA = val;
                }
                break;
            case "costoVentaConversionResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.COSTO_VENTA_CONVERSION = val;
                }
                break;
            case "resultadoResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.RESULTADO = val;
                }
                break;
            case "resultadoConversionResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.RESULTADO_CONVERSION = val;
                }
                break;
            case "rentabilidadResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.RENTABILIDAD = val;
                }
                break;
            case "descuentoVolumenResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.DESCUENTO_VOLUMEN = val;
                }
                break;
            case "finderFeeResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.FINDER_FEE = val;
                }
                break;
            case "valorCierreResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.TOTAL_HORAS = val;
                }
                break;
            case "totalHorasResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.TOTAL_HORAS = val;
                }
                break;
            case "tarifaPromedioResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.TARIFA_PROMEDIO = val;
                }
                break;
            case "tarifaPromedioConversionResumen":
                val = value === "" ? 0 : parseFloat(value);
                if (val <= 999999999) {
                    sim.TARIFA_PROMEDIO_CONVERSION = val;
                }
                break;
            case "nombreActividadDirecto":
                this.setState({ nombreActividad: value });
                break;
            case "nombreServicioTercero":
                this.setState({ nombreActividadTerceros: value });
                break;
            case "nombreGasto":
                this.setState({ nombreActividadGastos: value });
                break;
            case "nombrePresupuesto":
                sim.NOMBRE = value;
                break;
        }
        this.setState({ sim_info: sim });
    }

    public obtenerMeses() {
        let sim = this.state.sim_info;
        let yearinicio = new Date(sim.FECHA_DESDE === undefined ? "" : sim.FECHA_DESDE).getFullYear();
        let yearfin = new Date(sim.FECHA_HASTA === undefined ? "" : sim.FECHA_HASTA).getFullYear();
        //let yearinicio = new Date(2020,3,1).getFullYear();
        //let yearfin = new Date(2021,5,5).getFullYear();
        let iniciomes = new Date(sim.FECHA_DESDE === undefined ? "" : sim.FECHA_DESDE).getMonth() + 1;
        let finmes = new Date(sim.FECHA_HASTA === undefined ? "" : sim.FECHA_HASTA).getMonth() + 1;

        //let iniciomes = new Date(2020,3,1).getMonth();
        //let finmes = new Date(2021,5,5).getMonth();
        ////console.log(iniciomes, finmes)
        let totalyear = yearfin - yearinicio + 1;
        let years = yearinicio;
        let arrayyear = [];
        let calculomeses = 12 - iniciomes + finmes + (yearfin - yearinicio - 1) * 12 + 1;

        for (let i = 0; i < totalyear; i++) {
            arrayyear.push({
                years: years + i,
                inicio_year: yearinicio,
                fin_year: yearfin,
                inicio_mes: iniciomes,
                fin_mes: finmes,
                monto_directo: this.state.plan_ingresos_d / calculomeses === 0 ? "" : this.state.plan_ingresos_d / calculomeses,
                monto_tercero: this.state.plan_ingresos_t / calculomeses === 0 ? "" : this.state.plan_ingresos_t / calculomeses,
                monto_gasto_reembolsable: this.state.plan_gasto_gr / calculomeses === 0 ? "" : this.state.plan_gasto_gr / calculomeses,
                monto_gasto_proyecto: this.state.plan_gasto_gp / calculomeses === 0 ? "" : this.state.plan_gasto_gp / calculomeses,
            });
        }

        this.setState({
            yearprimero_directo: yearinicio,
            yearultimo_directo: yearfin,
            yeartotal_directo: arrayyear,
        });
    }

    public async obtenerOP() {
        this.obtenerMeses();
        let sim_info = this.state.sim_info;
        if (this.state.hasID) {
            fetchEngine
                .getAPI(`/api/simulacion/ObtenerOportunidad/${this.state.opoID}`, {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.state.tokenAPI}`,
                })
                .then((resultado: any) => {
                    if (resultado.ok) {
                        let oportunidad = resultado.data.oportunidad[0];
                        const car: any[] = [];
                        oportunidad.FECHA_INICIO = oportunidad.FECHA_INICIO != null ? new Date(oportunidad.FECHA_INICIO) : new Date();
                        oportunidad.FECHA_TERMINO = oportunidad.FECHA_TERMINO != null ? new Date(oportunidad.FECHA_TERMINO) : new Date();
                        oportunidad.FECHA_ENVIO_PROPUESTA =
                            oportunidad.FECHA_ENVIO_PROPUESTA != null ? new Date(oportunidad.FECHA_ENVIO_PROPUESTA) : new Date();
                        oportunidad.FECHA_ESTIMADA_INICIO =
                            oportunidad.FECHA_ESTIMADA_INICIO != null ? new Date(oportunidad.FECHA_ESTIMADA_INICIO) : new Date();
                        oportunidad.FECHA_ESTIMADA_FIN =
                            oportunidad.FECHA_ESTIMADA_FIN != null ? new Date(oportunidad.FECHA_ESTIMADA_FIN) : new Date();
                        sim_info.ID_MONEDA = oportunidad.ID_MONEDA;

                        sim_info.ID_CONTRATO_MARCO = oportunidad.ID_CONTRATO_MARCO;
                        sim_info.ID_UNIDAD = oportunidad.ID_UNIDAD;

                        sim_info.FECHA_DESDE = oportunidad.FECHA_INICIO != null ? new Date(oportunidad.FECHA_INICIO) : new Date();
                        sim_info.FECHA_HASTA = oportunidad.FECHA_TERMINO != null ? new Date(oportunidad.FECHA_TERMINO) : new Date();
                        sim_info.NOMBRE_PROYECTO = oportunidad.NOMBRE;
                        sim_info.ID_OPORTUNIDAD = oportunidad.ID;
                        sim_info.MONEDA = oportunidad.MONEDA;

                        sim_info.UF = oportunidad.UF;
                        sim_info.PESOS = oportunidad.PESOS;
                        sim_info.SOLES = oportunidad.SOLES;
                        sim_info.USD = oportunidad.USD;
                        sim_info.AUD = oportunidad.AUD;

                        {
                            resultado.data.roles.map((a: any) => {
                                car.push({
                                    key: a.ID,
                                    text: a.TITULO,
                                    id_contrato_marco: a.ID_CONTRATO_MARCO,
                                    id_unidad: 0,
                                    tarifa: a.TARIFA,
                                    costo: a.COSTO,
                                });
                            });
                        }

                        this.setState({
                            moneda_ctto: oportunidad.MONEDA_CTTO,
                            opciones_roles: car,
                        });

                        if (this.state.opciones_cttomarco_aux != undefined) {
                            this.state.opciones_cttomarco_aux.map((a: any) => {
                                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                                    this.setState({ horasxdia: a.horas });
                                }
                            });
                        }

                        if (this.state.opciones_cttomarco_aux != undefined) {
                            this.state.opciones_cttomarco_aux.map((a: any) => {
                                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                                    this.setState({ horasxdia: a.horas });
                                    this.state.opciones_monedas!.map((b: any) => {
                                        if (b.key === a.id_moneda) {
                                            this.setState({
                                                moneda_cto: b.text,
                                            });
                                        }
                                    });
                                }
                            });
                        }
                    } else {
                        console.log(resultado);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    async obtenerSIM() {
        if (this.state.hasID) {
            fetchEngine
                .getAPI(`/api/simulacion/${this.state.simID}`, {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.state.tokenAPI}`,
                })
                .then((resultado: any) => {
                    if (resultado.ok) {
                        let simulacion = resultado.data;
                        let ingresosD = simulacion.INGRESOS_DIRECTOS === undefined ? [] : simulacion.INGRESOS_DIRECTOS;
                        let ingresosT = simulacion.INGRESOS_TERCEROS === undefined ? [] : simulacion.INGRESOS_TERCEROS;
                        let gastos = simulacion.GASTOS === undefined ? [] : simulacion.GASTOS;
                        let idirecto: any = [];
                        let itercero: any = [];
                        let igastos: any = [];

                        const car: any[] = [];

                        resultado.data.ROLES.map((a: any) => {
                            car.push({
                                key: a.ID,
                                text: a.TITULO,
                                id_contrato_marco: a.ID_CONTRATO_MARCO,
                                id_unidad: 0,
                                tarifa: a.TARIFA,
                                costo: a.COSTO,
                            });
                        });

                        if (ingresosD.length > 0 || ingresosT.length > 0 || gastos.length > 0) {
                            this.setState({
                                ingresosDirectos: ingresosD,
                                ingresosTerceros: ingresosT,
                                ingresosGastos: gastos,
                            });
                        }

                        if (simulacion.ID_OPORTUNIDAD > 0) {
                            this.setState({ opoID: simulacion.ID_OPORTUNIDAD });
                            this.obtenerOP();
                        }

                        if (simulacion.ACTIVIDADES.length > 0) {
                            simulacion.ACTIVIDADES.map((a: any) => {
                                if (a.ID_MODULO === 1) {
                                    idirecto.push({
                                        INDEX: 0,
                                        ID_MODULO: 1,
                                        ID_SIMULACION: 0,
                                        ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                        NOMBRE: a.NOMBRE,
                                        TOTAL_HORAS: 0,
                                        TARIFA_HH: 0,
                                        COSTO_HH: 0,
                                        TOTAL_INGRESOS: 0,
                                        TOTAL_COSTO: 0,
                                    });
                                } else if (a.ID_MODULO === 2) {
                                    itercero.push({
                                        ID_MODULO: 2,
                                        ID_SIMULACION: 0,
                                        ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                        index: 0,
                                        NOMBRE: a.NOMBRE,
                                        TOTAL_COSTO: 0,
                                        TOTAL_INGRESO: 0,
                                    });
                                } else if (a.ID_MODULO === 3) {
                                    igastos.push({
                                        ID_SIMULACION: 0,
                                        ID_MODULO: 3,
                                        ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                        index: 0,
                                        NOMBRE: a.NOMBRE,
                                        TOTAL_GASTO: 0,
                                        VALOR_RECUPERAR: 0,
                                    });
                                }
                            });
                        }

                        simulacion.FECHA_DESDE =
                            simulacion.FECHA_DESDE != null && simulacion.FECHA_DESDE != "0001-01-01T00:00:00"
                                ? new Date(simulacion.FECHA_DESDE)
                                : null;
                        simulacion.FECHA_HASTA =
                            simulacion.FECHA_HASTA != null && simulacion.FECHA_HASTA != "0001-01-01T00:00:00"
                                ? new Date(simulacion.FECHA_HASTA)
                                : null;
                        simulacion.FECHA_APROBACION =
                            simulacion.FECHA_APROBACION != null && simulacion.FECHA_APROBACION != "0001-01-01T00:00:00"
                                ? new Date(simulacion.FECHA_APROBACION)
                                : null;

                        simulacion.INGRESO_VENTA_CONVERSION =
                            simulacion.INGRESO_VENTA_CONVERSION > 0
                                ? parseFloat(simulacion.INGRESO_VENTA_CONVERSION.toFixed(2))
                                : parseFloat(simulacion.INGRESO_VENTA_CONVERSION);
                        simulacion.COSTO_VENTA_CONVERSION =
                            simulacion.COSTO_VENTA_CONVERSION > 0
                                ? parseFloat(simulacion.COSTO_VENTA_CONVERSION.toFixed(2))
                                : parseFloat(simulacion.COSTO_VENTA_CONVERSION);
                        simulacion.RESULTADO_CONVERSION =
                            simulacion.RESULTADO_CONVERSION > 0
                                ? parseFloat(simulacion.RESULTADO_CONVERSION.toFixed(2))
                                : parseFloat(simulacion.RESULTADO_CONVERSION);
                        simulacion.RENTABILIDAD_CONVERSION =
                            simulacion.RENTABILIDAD_CONVERSION > 0
                                ? parseFloat(simulacion.RENTABILIDAD_CONVERSION.toFixed(2))
                                : parseFloat(simulacion.RENTABILIDAD_CONVERSION);

                        this.setState({
                            sim_info: simulacion,
                            actividadRoles: idirecto,
                            actividadTerceros: itercero,
                            actividadGastos: igastos,
                            opciones_roles: car,
                        });

                        if (this.state.opciones_tipoctto != undefined) {
                            this.state.opciones_tipoctto.map((a: any) => {
                                if (a.key === this.state.sim_info.ID_TIPO_CONTRATO) {
                                    this.setState({
                                        tipo_contrato_select: a.text,
                                    });
                                }
                            });
                        }

                        this.DatosPlan();
                    } else {
                        console.log(resultado);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    public async guardarSIM(key: any) {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        let sim = this.state.sim_info;
        let modulos: any = [];
        let resultado: number = sim.RESULTADO;
        let rentabilidad: number = sim.RENTABILIDAD;
        sim.FECHA_APROBACION = sim.FECHA_APROBACION != null ? sim.FECHA_APROBACION : new Date("1/1/2001");

        for (let index = 0; index < this.state.ingresosDirectos.length; index++) {
            const e = this.state.ingresosDirectos[index];
            if (e.ID_CARGO === 0) {
                this.state.ingresosDirectos.splice(index, 1);
            }
        }

        sim.INGRESOS_DIRECTOS = this.state.ingresosDirectos;
        sim.INGRESOS_TERCEROS = this.state.ingresosTerceros;
        sim.GASTOS = this.state.ingresosGastos;
        sim.TARIFA_PROMEDIO = sim.TARIFA_PROMEDIO > 0 ? sim.TARIFA_PROMEDIO : 0;

        this.state.actividadRoles.map((a: any) => {
            modulos.push({
                ID_SIMULACION: this.state.simID,
                ID_MODULO: 1,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
            });
        });
        sim.CREADO_POR = userName.account.userName;
        sim.MODIFICADO_POR = userName.account.userName;
        this.setState({ sim_info: sim });

        this.state.actividadTerceros.map((a: any) => {
            modulos.push({
                ID_SIMULACION: this.state.simID,
                ID_MODULO: 2,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
            });
        });

        this.state.actividadGastos.map((a: any) => {
            modulos.push({
                ID_SIMULACION: this.state.simID,
                ID_MODULO: 3,
                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                NOMBRE: a.NOMBRE,
            });
        });
        if (key === "aprobar") {
            sim.ID_ESTADO = 8;
            sim.FECHA_APROBACION = new Date();
        }

        sim.ACTIVIDADES = modulos;

        this.setState({ sim_info: sim });

        switch (key) {
            case "botonGuardar":
                fetchEngine
                    .postAPI(`/api/simulacion`, this.state.sim_info, {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.state.tokenAPI}`,
                    })
                    .then((resultado: any) => {
                        if (resultado.ok) {
                            this.setState({
                                dialogoErrorNombre: true,
                                dialogoGuardar2: true,
                                dialogoAprobar: true,
                            });

                            let simuladorID = this.state.simID != null ? this.state.simID : 0;
                            if (simuladorID > 0) {
                                this.setState({ dialogoGuardar2: false });
                            } else {
                                this.setState({ dialogoGuardar2: false });
                                window.location.href = `/simulador/${resultado.id}/${this.state.opoID}`;
                            }
                        } else {
                            this.setState({ dialogoGuardarError: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({ dialogoGuardarError: false });
                    });
                break;
            case "aprobar":
                fetchEngine
                    .postAPI(`/api/simulacion`, this.state.sim_info, {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.state.tokenAPI}`,
                    })
                    .then((resultado: any) => {
                        if (resultado.ok) {
                            this.setState({ dialogoAprobar: false });
                        } else {
                            this.setState({ dialogoGuardarError: false });
                        }
                    })
                    .catch((err) => {
                        this.setState({ dialogoGuardarError: false });
                    });
                break;
        }
    }

    private _onChangeProyecto = (event: any, item: any): void => {
        this.setState({ estadoFormulario: true });
        let opciones_roles2: any[] = [];
        let opciones_ctto_marco2: any[] = [];
        let sim = this.state.sim_info;
        sim.ID_OPORTUNIDAD = item.key;
        sim.ID_CONTRATO_MARCO = item.contrato_marco;
        sim.FECHA_DESDE = new Date(item.f_inicio);
        sim.FECHA_HASTA = new Date(item.f_fin);
        sim.ID_UNIDAD = item.unidad;
        sim.NOMBRE_PROYECTO = item.text;
        sim.MONEDA = item.moneda;
        sim.PESOS = item.clp;
        sim.UF = item.uf;
        sim.SOLES = item.soles;
        sim.USD = item.usd;
        sim.AUD = item.aud;
        console.log(sim.ID);

        this.setState({ moneda_ctto: item.moneda_ctto });

        if (this.state.opciones_roles_aux !== undefined) {
            this.state.opciones_roles_aux.map((e) => {
                if (e.id_contrato_marco === sim.ID_CONTRATO_MARCO) {
                    opciones_roles2.push({
                        key: e.key,
                        text: e.text,
                    });
                }
            });
        }

        if (this.state.opciones_cttomarco_aux !== undefined) {
            this.state.opciones_cttomarco_aux.map((e) => {
                if (e.id_unidad === sim.ID_UNIDAD) {
                    opciones_ctto_marco2.push({
                        key: e.key,
                        text: e.text,
                    });
                }
            });
        }

        if (this.state.opciones_cttomarco_aux != undefined) {
            this.state.opciones_cttomarco_aux.map((a: any) => {
                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                    this.setState({ horasxdia: a.horas });
                    this.state.opciones_monedas!.map((b: any) => {
                        if (b.key === a.id_moneda) {
                            this.setState({ moneda_cto: b.text });
                        }
                    });
                }
            });
        }

        this.setState({
            sim_info: sim,
            opciones_roles: opciones_roles2,
            opciones_cttomarco: opciones_ctto_marco2,
        });
    };

    private _onChangeTipoContrato = (event: any, item: any): void => {
        this.setState({ estadoFormulario: true });
        let sim = this.state.sim_info;
        sim.ID_TIPO_CONTRATO = item.key;

        if (this.state.opciones_tipoctto != undefined) {
            let texto: string = "";
            this.state.opciones_tipoctto.map((a: any) => {
                if (a.key === item.key) {
                    texto = a.text;
                    this.setState({ tipo_contrato_select: texto });
                }
            });
        }

        this.setState({ sim_info: sim });
    };

    private _onChangeUnidadNegocio = (event: any, item: any): void => {
        this.setState({ estadoFormulario: true });
        let sim = this.state.sim_info;
        let opciones_roles2: any[] = [];
        let opciones_ctto_marco2: any[] = [];

        sim.ID_UNIDAD = item.key;

        if (this.state.opciones_cttomarco_aux !== undefined) {
            this.state.opciones_cttomarco_aux.map((e) => {
                if (e.id_unidad === sim.ID_UNIDAD) {
                    opciones_ctto_marco2.push({
                        key: e.key,
                        text: e.text,
                    });
                }
            });
        }

        this.setState({
            sim_info: sim,
            opciones_roles: opciones_roles2,
            opciones_cttomarco: opciones_ctto_marco2,
        });
    };

    private _onChangeContratoMarco = (event: any, item: any): void => {
        this.setState({ estadoFormulario: true });
        let sim = this.state.sim_info;
        let opciones_roles2: any[] = [];
        sim.ID_CONTRATO_MARCO = item.key;

        if (this.state.opciones_cttomarco_aux != undefined) {
            this.state.opciones_cttomarco_aux.map((a: any) => {
                if (a.key === this.state.sim_info.ID_CONTRATO_MARCO) {
                    this.setState({ horasxdia: a.horas });

                    this.state.opciones_monedas!.map((b: any) => {
                        if (b.key === a.id_moneda) {
                            this.setState({ moneda_cto: b.text });
                            sim.ID_MONEDA = b.key;
                        }
                    });
                }
            });
        }

        if (this.state.opciones_roles_aux !== undefined) {
            this.state.opciones_roles_aux.map((e) => {
                if (e.id_contrato_marco === item.key) {
                    opciones_roles2.push({
                        key: e.key,
                        text: e.text,
                    });
                }
            });
        }

        this.setState({ sim_info: sim, opciones_roles: opciones_roles2 });
    };

    private _onChangeMoneda = (event: any, item: any): void => {
        this.setState({ estadoFormulario: true });
        let sim = this.state.sim_info;
        sim.ID_MONEDA = item.key;
        this.setState({ sim_info: sim });
    };

    private _onChangeEstado = (event: any, item: any): void => {
        this.setState({ estadoFormulario: true });
        let sim = this.state.sim_info;
        sim.ID_ESTADO = item.key;
        this.setState({ sim_info: sim });
    };

    private _onSelectDateFechaDesde = (date: Date | null | undefined): void => {
        let sim = this.state.sim_info;
        this.setState({ estadoFormulario: true });
        if (date != null) {
            sim.FECHA_DESDE = date;
        }
        this.setState({ sim_info: sim });
    };

    private _onSelectDateFechaHasta = (date: Date | null | undefined): void => {
        let sim = this.state.sim_info;
        this.setState({ estadoFormulario: true });
        if (date != null) {
            sim.FECHA_HASTA = date;
        }
        this.setState({ sim_info: sim });
    };

    public bloquePlandelProyecto() {
        return (
            <div>
                <div className="ms-Grid-row" id="bloqueIngresosDirectos"></div>
                <br />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 fondoTotales rounded" id="">
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Horas"
                                id={"ppDirectoHoras"}
                                value={this.state.plan_horas_d > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_horas_d) : ""}
                                title={this.state.plan_horas_d > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_horas_d) : ""}
                                disabled={true}
                                placeholder={"..."}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Ingresos"
                                id={"ppDirectoIngresos"}
                                value={this.state.plan_ingresos_d > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_ingresos_d) : ""}
                                title={this.state.plan_ingresos_d > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_ingresos_d) : ""}
                                disabled={true}
                                placeholder={"..."}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Costos"
                                id={"ppDirectoCostos"}
                                value={this.state.plan_costo_d > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_costo_d) : ""}
                                title={this.state.plan_costo_d > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_costo_d) : ""}
                                disabled={true}
                                placeholder={"..."}
                            />
                        </div>
                    </div>
                </div>

                <div className="fondoAcordeonPlan">
                    <Accordion
                        title="Total Ingresos Directos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        key={1}
                        classFondo="fondoSeccion"
                        className="fondoAcordeonPlan">
                        <div className="itemContent">
                            {this.state.yeartotal_directo.map((a: any) => {
                                return (
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <h6>
                                                <span className="badge badge-primary numAno">{a.years}</span>
                                            </h6>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ene"
                                                    disabled={a.years === a.inicio_year && a.inicio_mes > 1 ? true : false}
                                                    placeholder={a.years === a.inicio_year && a.inicio_mes > 1 ? "" : a.monto_directo}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Feb"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Mar"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Abr"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="May"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jun"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jul"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ago"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Sept"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Oct"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Nov"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Dic"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? ""
                                                            : new Intl.NumberFormat("de-DE").format(a.monto_directo)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <hr />
                        </div>
                    </Accordion>
                </div>

                <div className="ms-Grid-row" id="bloqueIngresosDirectos"></div>
                <br />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 fondoTotales rounded" id="">
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Costo"
                                id={"ppTerceroCosto"}
                                disabled={true}
                                placeholder={"..."}
                                value={this.state.plan_costo_t > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_costo_t) : ""}
                                title={this.state.plan_costo_t > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_costo_t) : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Ingresos"
                                id={"ppTerceroIngresos"}
                                disabled={true}
                                placeholder={"..."}
                                value={this.state.plan_ingresos_t > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_ingresos_t) : ""}
                                title={this.state.plan_ingresos_t > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_ingresos_t) : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Rentabilidad (%)"
                                id={"ppTerceroRentabilidad"}
                                disabled={true}
                                placeholder={"..."}
                                value={this.state.rentabilidad_plan_t != 0 ? this.state.rentabilidad_plan_t.toString().replace(".", ",") + "%" : ""}
                                title={this.state.rentabilidad_plan_t != 0 ? this.state.rentabilidad_plan_t.toString().replace(".", ",") + "%" : ""}
                            />
                        </div>
                    </div>
                </div>

                <div className="fondoAcordeonPlan">
                    <Accordion
                        title="Total Servicios Terceros"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        key={1}
                        classFondo="fondoSeccion"
                        className="fondoAcordeonPlan">
                        <div className="itemContent">
                            {this.state.yeartotal_directo.map((a: any) => {
                                return (
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <h6>
                                                <span className="badge badge-primary numAno">{a.years}</span>
                                            </h6>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ene"
                                                    disabled={a.years === a.inicio_year && a.inicio_mes > 1 ? true : false}
                                                    placeholder={a.years === a.inicio_year && a.inicio_mes > 1 ? "" : a.monto_tercero}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Feb"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Mar"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Abr"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="May"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jun"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jul"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ago"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Sept"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Oct"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Nov"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Dic"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? ""
                                                            : a.monto_tercero
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <hr />
                        </div>
                    </Accordion>
                </div>

                <div className="ms-Grid-row" id="bloqueIngresosDirectos"></div>
                <br />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 fondoTotales rounded" id="">
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Gasto Total"
                                id={"ppGastosReembolsableTotal"}
                                disabled={true}
                                placeholder={"..."}
                                value={this.state.plan_gasto_gr > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_gasto_gr) : ""}
                                title={this.state.plan_gasto_gr > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_gasto_gr) : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Recuperable"
                                id={"ppGastosReembolsableRecuperable"}
                                disabled={true}
                                placeholder={"..."}
                                value={
                                    this.state.plan_recuperable_gr > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_recuperable_gr) : ""
                                }
                                title={
                                    this.state.plan_recuperable_gr > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_recuperable_gr) : ""
                                }
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Diferencia"
                                id={"ppGastosReembolsableDiferencia"}
                                disabled={true}
                                placeholder={"..."}
                                value={this.state.plan_diferencia_gr != 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_diferencia_gr) : ""}
                                title={this.state.plan_diferencia_gr != 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_diferencia_gr) : ""}
                            />
                        </div>
                    </div>
                </div>

                <div className="fondoAcordeonPlan">
                    <Accordion
                        title="Total Gastos Cobrables al Cliente"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        key={1}
                        classFondo="fondoSeccion"
                        className="fondoAcordeonPlan">
                        <div className="itemContent">
                            {this.state.yeartotal_directo.map((a: any) => {
                                return (
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <h6>
                                                <span className="badge badge-primary numAno">{a.years}</span>
                                            </h6>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ene"
                                                    disabled={a.years === a.inicio_year && a.inicio_mes > 1 ? true : false}
                                                    placeholder={a.years === a.inicio_year && a.inicio_mes > 1 ? "" : a.monto_gasto_reembolsable}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Feb"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Mar"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Abr"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="May"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jun"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jul"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ago"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Sept"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Oct"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Nov"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Dic"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? ""
                                                            : a.monto_gasto_reembolsable
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <hr />
                        </div>
                    </Accordion>
                </div>

                <div className="ms-Grid-row" id="bloqueIngresosDirectos"></div>
                <br />
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg4 fondoTotales rounded" id="">
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4 txtTotal">
                            <TextField
                                label="Gasto Total"
                                id={"ppGastoProyectoTotal"}
                                disabled={true}
                                placeholder={"..."}
                                value={this.state.plan_gasto_gp > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_gasto_gp) : ""}
                                title={this.state.plan_gasto_gp > 0 ? new Intl.NumberFormat("de-DE").format(this.state.plan_gasto_gp) : ""}
                            />
                        </div>
                    </div>
                </div>

                <div className="fondoAcordeonPlan">
                    <Accordion
                        title="Total Gastos de Proyectos"
                        defaultCollapsed={false}
                        inicio={true}
                        expandAll={false}
                        key={1}
                        classFondo="fondoSeccion"
                        className="fondoAcordeonPlan">
                        <div className="itemContent">
                            {this.state.yeartotal_directo.map((a: any) => {
                                return (
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <h6>
                                                <span className="badge badge-primary numAno">{a.years}</span>
                                            </h6>
                                        </div>
                                        <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ene"
                                                    disabled={a.years === a.inicio_year && a.inicio_mes > 1 ? true : false}
                                                    placeholder={a.years === a.inicio_year && a.inicio_mes > 1 ? "" : a.monto_gasto_proyecto}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Feb"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 2
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 2
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Mar"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 3
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 3
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Abr"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 4
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 4
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="May"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 5
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 5
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jun"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 6
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 6
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Jul"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 7
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 7
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Ago"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 8
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 8
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Sept"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 9
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 9
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Oct"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 10
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 10
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Nov"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 11
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 11
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtMes">
                                                <TextField
                                                    label="Dic"
                                                    disabled={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? true
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? true
                                                            : false
                                                    }
                                                    placeholder={
                                                        a.years === a.inicio_year && a.inicio_mes > 12
                                                            ? ""
                                                            : a.years === a.fin_year && a.fin_mes < 12
                                                            ? ""
                                                            : a.monto_gasto_proyecto
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Accordion>
                </div>

                <br />
                {/* Fin Función Bloque Plan del Proyecto */}
            </div>
        );
    }

    public DatosPlan() {
        let ingresosDirecto = this.state.ingresosDirectos === undefined ? [] : this.state.ingresosDirectos;
        let ingresosTercero = this.state.ingresosTerceros === undefined ? [] : this.state.ingresosTerceros;
        let gasto = this.state.ingresosGastos === undefined ? [] : this.state.ingresosGastos;

        let totalHorasPlan_d = 0;
        let totalIngresosPlan_d = 0;
        let totalCostosPlan_d = 0;

        let rentabilidadPlan_t = 0;
        let totalIngresosPlan_t = 0;
        let totalCostosPlan_t = 0;

        let totalGastosPlan_gr = 0;
        let totalRecuperablePlan_gr = 0;
        let totalDiferenciaPlan_gr = 0;

        let totalGastosPlan_gp = 0;

        //Calculos Directos
        totalHorasPlan_d = parseFloat(ingresosDirecto.reduce((a, b) => a + b.HORAS, 0).toFixed(2));
        totalIngresosPlan_d = parseFloat(ingresosDirecto.reduce((a, b) => a + b.TOTAL_INGRESOS, 0).toFixed(2));
        totalCostosPlan_d = parseFloat(ingresosDirecto.reduce((a, b) => a + b.TOTAL_COSTO, 0).toFixed(2));

        //Calculos Terceros
        totalIngresosPlan_t = parseFloat(ingresosTercero.reduce((a, b) => a + b.INGRESO, 0).toFixed(2));
        totalCostosPlan_t = parseFloat(ingresosTercero.reduce((a, b) => a + b.COSTO_TOTAL, 0).toFixed(2));

        if (totalCostosPlan_t > 0 && totalIngresosPlan_t > 0) {
            rentabilidadPlan_t = parseFloat((100 - (totalCostosPlan_t * 100) / totalIngresosPlan_t).toFixed(2));
        }

        //Calculos Gastos Reembolsables y Proyecto
        totalGastosPlan_gr = parseFloat(gasto.reduce((a, b) => a + (b.REEMBOLSABLE === true ? b.GASTO_TOTAL : 0), 0).toFixed(2));
        totalGastosPlan_gp = parseFloat(gasto.reduce((a, b) => a + (b.REEMBOLSABLE === false ? b.GASTO_TOTAL : 0), 0).toFixed(2));
        totalDiferenciaPlan_gr = parseFloat(
            gasto.reduce((a, b) => a + (b.REEMBOLSABLE === true ? b.VALOR_RECUPERAR - b.GASTO_TOTAL : 0), 0).toFixed(2)
        );
        totalRecuperablePlan_gr = parseFloat(gasto.reduce((a, b) => a + b.VALOR_RECUPERAR, 0).toFixed(2));

        this.setState({
            plan_horas_d: totalHorasPlan_d,
            plan_ingresos_d: totalIngresosPlan_d,
            plan_costo_d: totalCostosPlan_d,
            rentabilidad_plan_t: rentabilidadPlan_t,
            plan_ingresos_t: totalIngresosPlan_t,
            plan_costo_t: totalCostosPlan_t,
            plan_gasto_gr: totalGastosPlan_gr,
            plan_gasto_gp: totalGastosPlan_gp,
            plan_diferencia_gr: totalDiferenciaPlan_gr,
            plan_recuperable_gr: totalRecuperablePlan_gr,
        });

        this.obtenerMeses();
    }

    public conversionResumen(valor: number, moneda: string) {
        let valorConvertido = 0;
        let sim = this.state.sim_info;
        ////console.log(valor, moneda)
        ////console.log(sim.AUD)
        ////console.log(sim.ID_MONEDA)
        ////console.log(this.state.moneda_ctto)
        ////console.log(sim.MONEDA)

        if (this.state.moneda_ctto != sim.MONEDA) {
            if (this.state.moneda_ctto.toUpperCase() == "CLP") {
                valorConvertido = valor * sim.PESOS;
            } else if (this.state.moneda_ctto.toUpperCase() == "UF") {
                valorConvertido = valor * sim.UF;
            } else if (this.state.moneda_ctto.toUpperCase() == "SOLES") {
                valorConvertido = valor * sim.SOLES;
            } else if (this.state.moneda_ctto.toUpperCase() == "USD") {
                valorConvertido = valor * sim.USD;
            } else if (this.state.moneda_ctto.toUpperCase() == "AUD") {
                valorConvertido = valor * sim.AUD;
            }
        } else {
            valorConvertido = valor;
        }

        return valorConvertido;
    }

    public IngresoVenta() {
        let ingresosDirecto = this.state.ingresosDirectos === undefined ? [] : this.state.ingresosDirectos;
        let ingresosTercero = this.state.ingresosTerceros === undefined ? [] : this.state.ingresosTerceros;
        let sim = this.state.sim_info;
        let totalIngresoVenta = 0;
        let valorConvertido = 0;
        totalIngresoVenta = ingresosDirecto.reduce((a, b) => a + b.TOTAL_INGRESOS, 0) + ingresosTercero.reduce((a, b) => a + b.INGRESO, 0);

        sim.INGRESO_VENTA = parseFloat(totalIngresoVenta.toFixed(2));
        sim.INGRESO_VENTA_DCTO = parseFloat(totalIngresoVenta.toFixed(2));

        valorConvertido = this.conversionResumen(totalIngresoVenta, sim.MONEDA);

        sim.INGRESO_VENTA_CONVERSION = parseFloat(valorConvertido.toFixed(2));
        sim.INGRESO_VENTA_DCTO_CONVERSION = parseFloat(valorConvertido.toFixed(2));

        this.setState({ sim_info: sim });
    }

    public CostoVenta() {
        let costoDirecto = this.state.ingresosDirectos === undefined ? [] : this.state.ingresosDirectos;
        let costoTercero = this.state.ingresosTerceros === undefined ? [] : this.state.ingresosTerceros;
        let sim = this.state.sim_info;
        let totalCostoVenta = 0;
        let valorConvertido = 0;

        totalCostoVenta = costoDirecto.reduce((a, b) => a + b.TOTAL_COSTO, 0) + costoTercero.reduce((a, b) => a + b.COSTO_TOTAL, 0);

        sim.COSTO_VENTA = parseFloat(totalCostoVenta.toFixed(2));

        valorConvertido = this.conversionResumen(totalCostoVenta, sim.MONEDA);

        sim.COSTO_VENTA_CONVERSION = parseFloat(valorConvertido.toFixed(2));

        this.setState({ sim_info: sim });
    }

    public Resultado() {
        //Gasto
        let gasto = this.state.ingresosGastos === undefined ? [] : this.state.ingresosGastos;
        let gasto_total = 0;
        let totalResultado = 0;
        let sim = this.state.sim_info;
        let valorConvertido = 0;
        let total_val_recuperar = 0;

        total_val_recuperar = gasto.reduce((a, b) => a + b.VALOR_RECUPERAR, 0);
        gasto_total = gasto.reduce((a, b) => a + b.GASTO_TOTAL, 0); // gasto total

        let diferencia = total_val_recuperar - gasto_total; /* (recuperar - gasto_total) */

        let totalIngresoVenta = parseFloat((sim.INGRESO_VENTA - (sim.INGRESO_VENTA * sim.DESCUENTO_VOLUMEN) / 100).toFixed(2));
        let totalCostoVenta = sim.COSTO_VENTA;
        // console.log(totalIngresoVenta, totalCostoVenta, diferencia);
        //Total resultado
        if (totalIngresoVenta !== undefined) {
            if (totalCostoVenta !== undefined) {
                totalResultado = parseFloat((totalIngresoVenta - totalCostoVenta + diferencia).toFixed(2));
            }
        }

        //console.log(totalResultado)

        sim.RESULTADO = totalResultado;

        valorConvertido = this.conversionResumen(totalResultado, sim.MONEDA);

        sim.RESULTADO_CONVERSION = parseFloat(valorConvertido.toFixed(2));

        this.setState({ sim_info: sim });
    }

    public Rentabilidad() {
        let sim = this.state.sim_info;
        let ingresoVenta = parseFloat((sim.INGRESO_VENTA - (sim.INGRESO_VENTA * sim.DESCUENTO_VOLUMEN) / 100).toFixed(2)); //sim.INGRESO_VENTA_DCTO;
        let resultado = sim.RESULTADO;

        let ingresoVentaConversion = parseFloat(
            (sim.INGRESO_VENTA_CONVERSION - (sim.INGRESO_VENTA_CONVERSION * sim.DESCUENTO_VOLUMEN) / 100).toFixed(2)
        ); //sim.INGRESO_VENTA_DCTO_CONVERSION;
        let resultadoConversion = sim.RESULTADO_CONVERSION;

        let totalRentabilidad = 0;
        let totalRentabilidadConversion = 0;

        resultado = parseInt(resultado.toFixed(2));
        ingresoVenta = parseInt(ingresoVenta.toFixed(2));
        // console.log(resultado, ingresoVenta);

        totalRentabilidad = ingresoVenta != 0 ? (resultado / ingresoVenta) * 100 : 0;
        // console.log(totalRentabilidad);

        totalRentabilidadConversion = ingresoVentaConversion != 0 ? (resultadoConversion / ingresoVentaConversion) * 100 : 0;

        //console.log(this.trunc(totalRentabilidadConversion, 2))

        sim.RENTABILIDAD = this.trunc(totalRentabilidad, 2); //parseInt(totalRentabilidad.toFixed(2));

        sim.RENTABILIDAD_CONVERSION = this.trunc(totalRentabilidadConversion, 2); //parseInt(totalRentabilidadConversion.toFixed(2));

        this.setState({ sim_info: sim });
    }

    public TotalHoras() {
        let ingresosDirecto = this.state.ingresosDirectos === undefined ? [] : this.state.ingresosDirectos;
        let sim = this.state.sim_info;
        let totalHoras = 0;

        totalHoras = ingresosDirecto.reduce((a, b) => a + b.HORAS, 0);

        sim.TOTAL_HORAS = parseFloat(totalHoras.toFixed(2));

        this.setState({ sim_info: sim });
    }

    public TarifaPromedio() {
        let sim = this.state.sim_info;
        let tarifaPromedio = 0;
        let valorConvertido = 0;
        tarifaPromedio = sim.TOTAL_HORAS != 0 ? sim.INGRESO_VENTA / sim.TOTAL_HORAS : 0;

        sim.TARIFA_PROMEDIO = parseFloat(tarifaPromedio.toFixed(2));

        valorConvertido = this.conversionResumen(tarifaPromedio, sim.MONEDA);

        sim.TARIFA_PROMEDIO_CONVERSION = parseFloat(valorConvertido.toFixed(5));

        this.setState({ sim_info: sim });
    }

    public DescuentoVolumen(ev: any) {
        let sim = this.state.sim_info;
        let state = this.state;
        let value = ev.target === undefined ? ev.floatValue : ev.target.value;

        value = value === "" ? 0 : parseFloat(value);
        if (value <= 100 && value >= 0) {
            value = value;
        } else if (value < 0) {
            value = 0;
        }

        let resultado_dcto = sim.RESULTADO;
        let rentabilidad_dcto = sim.RENTABILIDAD;
        let tarifa_promedio_dcto = sim.TARIFA_PROMEDIO;

        this.Resultado();
        this.Rentabilidad();
        this.TarifaPromedio();

        if (value === "" || value === undefined || isNaN(value)) {
            value = "0";
        }
        sim.DESCUENTO_VOLUMEN = parseFloat(value) >= 0 ? parseFloat(value) : 0;
        if (parseFloat(value) >= 0) {
            sim.TARIFA_PROMEDIO = parseFloat((sim.TARIFA_PROMEDIO - (sim.TARIFA_PROMEDIO * value) / 100).toFixed(2));
            sim.TARIFA_PROMEDIO_CONVERSION = parseFloat((sim.TARIFA_PROMEDIO_CONVERSION - (sim.TARIFA_PROMEDIO_CONVERSION * value) / 100).toFixed(5));

            sim.INGRESO_VENTA_DCTO = parseFloat((sim.INGRESO_VENTA - (sim.INGRESO_VENTA * value) / 100).toFixed(2));
            sim.INGRESO_VENTA_DCTO_CONVERSION = parseFloat((sim.INGRESO_VENTA_CONVERSION - (sim.INGRESO_VENTA_CONVERSION * value) / 100).toFixed(2));

            this.setState({
                sim_info: sim,
                resultado_dcto: resultado_dcto,
                rentabilidad_dcto: rentabilidad_dcto,
                tarifa_promedio_dcto: tarifa_promedio_dcto,
            });
        }
        //this.setState({sim_info: sim});
    }

    public trunc(x: number, posiciones: number) {
        var s = x.toString();
        var l = s.length;
        var decimalLength = s.indexOf(".") + 1;

        if (l - decimalLength <= posiciones) {
            return x;
        }
        // Parte decimal del número
        var isNeg = x < 0;
        var decimal = x % 1;
        var entera = isNeg ? Math.ceil(x) : Math.floor(x);
        // Parte decimal como número entero
        // Ejemplo: parte decimal = 0.77
        // decimalFormated = 0.77 * (10^posiciones)
        // si posiciones es 2 ==> 0.77 * 100
        // si posiciones es 3 ==> 0.77 * 1000
        var decimalFormated = Math.floor(Math.abs(decimal) * Math.pow(10, posiciones));
        // Sustraemos del número original la parte decimal
        // y le sumamos la parte decimal que hemos formateado
        var finalNum = entera + (decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1);

        return finalNum;
    }

    public bloqueResumen() {
        //this.Resultado();
        return (
            <div>
                {/* Función Bloque Gastos */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"></div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 d-flex justify-content-end mt-3 pr-5">
                        <label>
                            Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{this.state.sim_info.MONEDA}</span>
                        </label>
                    </div>
                </div>
                <div className="ms-Grid-row" id="bloqueIngresosDirectos"></div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Ingreso Venta"
                            id={"ingresoVentaConversionResumen"}
                            value={
                                this.state.sim_info.INGRESO_VENTA_CONVERSION === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.INGRESO_VENTA_CONVERSION)
                            }
                            //value={this.state.sim_info.INGRESO_VENTA === undefined ? "" : this.state.sim_info.INGRESO_VENTA.toString()}
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //onClick={() => //console.log(this.state.sim_info)}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Costo Venta"
                            id={"costoVentaConversionResumen"}
                            value={
                                this.state.sim_info.COSTO_VENTA_CONVERSION === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.COSTO_VENTA_CONVERSION)
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Resultado"
                            id={"resultadoConversionResumen"}
                            value={
                                this.state.sim_info.RESULTADO_CONVERSION === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.RESULTADO_CONVERSION)
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Rentabilidad (%)"
                            id={"rentabilidadResumen"}
                            value={
                                this.state.sim_info.RENTABILIDAD_CONVERSION === undefined
                                    ? ""
                                    : this.state.sim_info.RENTABILIDAD_CONVERSION.toString()
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg1 txtSmall">
                        <TextField
                            label="Total Horas"
                            id={"totalHorasResumen"}
                            value={
                                this.state.sim_info.TOTAL_HORAS === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.TOTAL_HORAS)
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                        <TextField
                            label="Tarifa Promedio"
                            id={"tarifaPromedioConversionResumen"}
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            value={
                                this.state.sim_info.TARIFA_PROMEDIO_CONVERSION === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.TARIFA_PROMEDIO_CONVERSION)
                            }
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                        <span className={"formateo"}>
                            <label>Descuento (%)</label>
                            <NumberFormat
                                id={"descuentoVolumenResumen"}
                                onValueChange={(e) => {
                                    this.DescuentoVolumen(e);
                                }}
                                defaultValue={
                                    this.state.sim_info.DESCUENTO_VOLUMEN <= 0
                                        ? ""
                                        : this.state.sim_info.DESCUENTO_VOLUMEN.toString().replace(".", ",")
                                }
                                value={
                                    this.state.sim_info.DESCUENTO_VOLUMEN === 0 || this.state.sim_info.DESCUENTO_VOLUMEN <= 0
                                        ? ""
                                        : this.state.sim_info.DESCUENTO_VOLUMEN.toString().replace(".", ",")
                                }
                                disabled={false}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                placeholder={"..."}
                                //format={"###%"}
                                maxLength={5}
                            />
                        </span>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                        <TextField
                            label="Valor Cierre"
                            id={"valorCierreConversionResumen"}
                            value={
                                this.state.sim_info.INGRESO_VENTA_DCTO_CONVERSION != undefined
                                    ? new Intl.NumberFormat("de-DE").format(this.state.sim_info.INGRESO_VENTA_DCTO_CONVERSION)
                                    : ""
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                </div>
                <br />
                {/* Fin Función Bloque Resumen */}
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"></div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6 d-flex justify-content-end mt-3 pr-5">
                        <label>
                            Los valores reflejados están en la moneda del Contrato Marco: <span>{this.state.moneda_cto}</span>
                        </label>
                    </div>
                </div>
                <div className="ms-Grid-row" id="bloqueIngresosDirectos"></div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Ingreso Venta"
                            id={"ingresoVentaResumen"}
                            value={
                                this.state.sim_info.INGRESO_VENTA === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.INGRESO_VENTA)
                            }
                            //value={this.state.sim_info.INGRESO_VENTA === undefined ? "" : this.state.sim_info.INGRESO_VENTA.toString()}
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Costo Venta"
                            id={"costoVentaResumen"}
                            value={
                                this.state.sim_info.COSTO_VENTA === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.COSTO_VENTA)
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Resultado"
                            id={"resultadoResumen"}
                            value={
                                this.state.sim_info.RESULTADO === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.RESULTADO)
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2 txtSmall">
                        <TextField
                            label="Rentabilidad (%)"
                            id={"rentabilidadResumen"}
                            value={this.state.sim_info.RENTABILIDAD === undefined ? "" : this.state.sim_info.RENTABILIDAD.toString()}
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg1 txtSmall">
                        <TextField
                            label="Total Horas"
                            id={"totalHorasResumen"}
                            value={
                                this.state.sim_info.TOTAL_HORAS === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.TOTAL_HORAS)
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                        <TextField
                            label="Tarifa Promedio"
                            id={"tarifaPromedioResumen"}
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            value={
                                this.state.sim_info.TARIFA_PROMEDIO === undefined
                                    ? ""
                                    : new Intl.NumberFormat("de-DE").format(this.state.sim_info.TARIFA_PROMEDIO)
                            }
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                        <span className={"formateo"}>
                            <label>Descuento (%)</label>
                            <NumberFormat
                                id={"descuentoVolumenResumen"}
                                onValueChange={(e) => {
                                    this.DescuentoVolumen(e);
                                }}
                                defaultValue={
                                    this.state.sim_info.DESCUENTO_VOLUMEN <= 0
                                        ? ""
                                        : this.state.sim_info.DESCUENTO_VOLUMEN > 100
                                        ? "100"
                                        : this.state.sim_info.DESCUENTO_VOLUMEN.toString().replace(".", ",")
                                }
                                value={
                                    this.state.sim_info.DESCUENTO_VOLUMEN <= 0
                                        ? ""
                                        : this.state.sim_info.DESCUENTO_VOLUMEN > 100
                                        ? "100"
                                        : this.state.sim_info.DESCUENTO_VOLUMEN.toString().replace(".", ",")
                                }
                                disabled={false}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                placeholder={"..."}
                                //format={"###%"}
                                maxLength={5}
                            />
                        </span>
                    </div>
                    <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                        <TextField
                            label="Valor Cierre"
                            id={"valorCierreResumen"}
                            value={
                                this.state.sim_info.INGRESO_VENTA_DCTO != undefined
                                    ? new Intl.NumberFormat("de-DE").format(this.state.sim_info.INGRESO_VENTA_DCTO)
                                    : ""
                            }
                            onChange={(e) => {
                                this.textFieldOnChange(e, 0);
                            }}
                            //type={"number"}
                            disabled={true}
                            placeholder={"..."}
                        />
                    </div>
                </div>
                <br />
            </div>
        );
    }

    public dialogoErrorNombre() {
        return (
            <Dialog
                hidden={this.state.dialogoErrorNombre}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Error Nombre Presupuesto",
                    subText: "Ingrese nombre del presupuesto",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoErrorNombre: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public callbackIngresos = (ingresos: any) => {
        let obj = { floatValue: this.state.sim_info.DESCUENTO_VOLUMEN };

        this.setState(
            {
                ingresosDirectos: [...ingresos],
                collapsed: true,
                largo_ingresos: ingresos.length,
            },
            () => {
                this.IngresoVenta();
                this.DatosPlan();
                this.CostoVenta();
                this.Resultado();
                this.Rentabilidad();
                this.TotalHoras();
                this.TarifaPromedio();
                this.DescuentoVolumen(obj);
            }
        );
    };

    public callbackActividades = (actividades: any) => {
        //console.log(ingresos);
        this.setState({ actividadRoles: [...actividades], collapsed: true });

        let obj = { floatValue: this.state.sim_info.DESCUENTO_VOLUMEN };

        this.IngresoVenta();
        this.DatosPlan();
        this.CostoVenta();
        this.Resultado();
        this.Rentabilidad();
        this.TotalHoras();
        this.TarifaPromedio();
        this.DescuentoVolumen(obj);
    };

    public callbackIngresosTerceros = (ingresos: any) => {
        //console.log(ingresos);
        this.setState({ ingresosTerceros: [...ingresos], collapsed: true });

        let obj = { floatValue: this.state.sim_info.DESCUENTO_VOLUMEN };

        this.IngresoVenta();
        this.DatosPlan();
        this.CostoVenta();
        this.Resultado();
        this.Rentabilidad();
        this.TotalHoras();
        this.TarifaPromedio();
        this.DescuentoVolumen(obj);
    };

    public callbackActividadesTerceros = (actividades: any) => {
        //console.log(ingresos);
        this.setState({ actividadTerceros: [...actividades], collapsed: true });

        let obj = { floatValue: this.state.sim_info.DESCUENTO_VOLUMEN };

        this.IngresoVenta();
        this.DatosPlan();
        this.CostoVenta();
        this.Resultado();
        this.Rentabilidad();
        this.TotalHoras();
        this.TarifaPromedio();
        this.DescuentoVolumen(obj);
    };

    public callbackIngresosGastos = (ingresos: any) => {
        let obj = { floatValue: this.state.sim_info.DESCUENTO_VOLUMEN };

        this.setState({ ingresosGastos: [...ingresos], collapsed: true }, () => {
            this.IngresoVenta();
            this.DatosPlan();
            this.CostoVenta();
            this.Resultado();
            this.Rentabilidad();
            this.TotalHoras();
            this.TarifaPromedio();
            this.DescuentoVolumen(obj);
        });
    };

    public callbackActividadesGastos = (actividades: any) => {
        //console.log(ingresos);
        this.setState({ actividadGastos: [...actividades], collapsed: true });

        let obj = { floatValue: this.state.sim_info.DESCUENTO_VOLUMEN };

        this.IngresoVenta();
        this.DatosPlan();
        this.CostoVenta();
        this.Resultado();
        this.Rentabilidad();
        this.TotalHoras();
        this.TarifaPromedio();
        this.DescuentoVolumen(obj);
    };

    render() {
        if (this.state.esAdmin || this.state.esGestion || this.state.esValidador) {
            return (
                <div>
                    <BotonAyuda token={this.state.tokenAPI} />
                    <div className="flotantes">
                        <div className="ms-Grid" dir="ltr">
                            {/* Título del módulo y botones flotantes */}
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg3">
                                    <div className="iconoRuta">
                                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                        &nbsp;
                                        <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Simulador de Presupuesto</span>
                                    </div>
                                    <div className="titulosimulador">
                                        <Glyphicon glyph="briefcase" /> Simulador de Presupuesto
                                        {/* <img className="izquierda" src={punteroTitulo} />Simulador de Presupuesto */}
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1 txtSmall">
                                    <TextField
                                        label="UF"
                                        disabled={true}
                                        placeholder={"..."}
                                        id={"UF"}
                                        /* defaultValue={"500"}
                                    value={"500"} */
                                        defaultValue={this.state.sim_info.UF.toString()}
                                        value={
                                            this.state.sim_info.UF === 0
                                                ? ""
                                                : (this.state.sim_info.UF === undefined
                                                      ? ""
                                                      : new Intl.NumberFormat("de-DE").format(this.state.sim_info.UF)
                                                  ).toString()
                                        }
                                        onChange={(e) => {
                                            this.textFieldOnChange(e, 0);
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1 txtSmall">
                                    <TextField
                                        label="Pesos (CLP)"
                                        disabled={true}
                                        placeholder={"..."}
                                        id={"CLP"}
                                        //defaultValue={"500"}
                                        //value={"500"}
                                        value={
                                            this.state.sim_info.PESOS === 0
                                                ? ""
                                                : (this.state.sim_info.PESOS === undefined
                                                      ? ""
                                                      : new Intl.NumberFormat("de-DE").format(this.state.sim_info.PESOS)
                                                  ).toString()
                                        }
                                        onChange={(e) => {
                                            this.textFieldOnChange(e, 0);
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1 txtSmall">
                                    <TextField
                                        label="USD"
                                        disabled={true}
                                        placeholder={"..."}
                                        id={"USD"}
                                        //defaultValue={"500"}
                                        //value={"500"}
                                        value={
                                            this.state.sim_info.USD === 0
                                                ? ""
                                                : (this.state.sim_info.USD === undefined
                                                      ? ""
                                                      : new Intl.NumberFormat("de-DE").format(this.state.sim_info.USD)
                                                  ).toString()
                                        }
                                        onChange={(e) => {
                                            this.textFieldOnChange(e, 0);
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1 txtSmall">
                                    <TextField
                                        label="AUD"
                                        disabled={true}
                                        placeholder={"..."}
                                        id={"AUD"}
                                        //defaultValue={"500"}
                                        //value={"500"}
                                        value={
                                            this.state.sim_info.AUD === 0
                                                ? ""
                                                : (this.state.sim_info.AUD === undefined
                                                      ? ""
                                                      : new Intl.NumberFormat("de-DE").format(this.state.sim_info.AUD)
                                                  ).toString()
                                        }
                                        onChange={(e) => {
                                            this.textFieldOnChange(e, 0);
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg1 txtSmall">
                                    <TextField
                                        label="Soles"
                                        disabled={true}
                                        placeholder={"..."}
                                        id={"soles"}
                                        //defaultValue={"500"}
                                        //value={"500"}
                                        value={
                                            this.state.sim_info.SOLES === 0
                                                ? ""
                                                : (this.state.sim_info.SOLES === undefined
                                                      ? ""
                                                      : new Intl.NumberFormat("de-DE").format(this.state.sim_info.SOLES)
                                                  ).toString()
                                        }
                                        onChange={(e) => {
                                            this.textFieldOnChange(e, 0);
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg4 d-flex justify-content-end">
                                    <div className="flotante">
                                        <DefaultButton
                                            id={"volverOportunidad"}
                                            text="Volver"
                                            onClick={() => (window.location.href = `/oportunidad/${this.state.sim_info.ID_OPORTUNIDAD}`)}
                                        />
                                        <PrimaryButton
                                            id={"guardarPresupuesto"}
                                            text="Guardar Presupuesto"
                                            onClick={(e) => {
                                                this.state.sim_info.NOMBRE == ""
                                                    ? this.setState({
                                                          dialogoErrorNombre: false,
                                                      })
                                                    : this.guardarSIM("botonGuardar");
                                            }}
                                        />
                                        {this.state.sim_info.ID_ESTADO != 8 ? (
                                            <PrimaryButton
                                                id={"aprobarPresupuesto"}
                                                text="Aprobar Presupuesto"
                                                onClick={(e) => {
                                                    this.state.simID === 0
                                                        ? this.setState({
                                                              dialogoAprobar: false,
                                                          })
                                                        : this.guardarSIM("aprobar");
                                                }}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <br />
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10"></div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg8" id="fieldNombrePresupuesto">
                                    <TextField
                                        label="Nombre Presupuesto"
                                        id={"nombrePresupuesto"}
                                        value={this.state.sim_info.NOMBRE}
                                        onChange={(e) => {
                                            this.textFieldOnChange(e, 0);
                                        }}
                                        //type={"text"}
                                        required={true}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" id="fieldEstado">
                                    <ComboBox
                                        label="Estado"
                                        disabled={true}
                                        id={"estadoPresupuesto"}
                                        options={this.state.opciones_estado}
                                        selectedKey={this.state.sim_info.ID_ESTADO}
                                        onChange={this._onChangeEstado}
                                    />
                                    {/*Estado: <span className="txtestado">Creando</span>*/}
                                </div>
                            </div>
                            {/* Fin Título del módulo y botones flotantes */}
                            {/* Entradas de la cabecera */}
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Oportunidad"
                                        placeholder="Nombre de oportunidad"
                                        disabled={this.state.sim_info.ID_OPORTUNIDAD > 0 ? true : false}
                                        id={"nombreProyecto"}
                                        onChange={this._onChangeProyecto}
                                        text={this.state.sim_info.NOMBRE_PROYECTO}
                                        options={this.state.opciones_proyecto}
                                        selectedKey={this.state.sim_info.ID_OPORTUNIDAD}
                                        allowFreeform={true}
                                        autoComplete="on"
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <DatePicker
                                        id={"fechaDesde"}
                                        className={controlClass.control}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        strings={DayPickerStrings}
                                        allowTextInput={true}
                                        placeholder="Desde"
                                        label="Fecha desde"
                                        //isRequired={true}
                                        ariaLabel="Seleccione una fecha"
                                        formatDate={this._onFormatDate}
                                        value={this.state.sim_info.FECHA_DESDE}
                                        onSelectDate={this._onSelectDateFechaDesde}
                                        disabled={this.state.sim_info.ID_OPORTUNIDAD > 0 ? true : false}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <DatePicker
                                        className={controlClass.control}
                                        firstDayOfWeek={DayOfWeek.Monday}
                                        strings={DayPickerStrings}
                                        placeholder="Hasta"
                                        label="Fecha hasta"
                                        id={"fechaHasta"}
                                        //isRequired={true}
                                        ariaLabel="Seleccione una fecha"
                                        formatDate={this._onFormatDate}
                                        value={this.state.sim_info.FECHA_HASTA}
                                        onSelectDate={this._onSelectDateFechaHasta}
                                        disabled={this.state.sim_info.ID_OPORTUNIDAD > 0 ? true : false}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                    <TextField
                                        label="Horas por día"
                                        id={"horasDia"}
                                        value={
                                            this.state.horasxdia === 0
                                                ? ""
                                                : (this.state.horasxdia === undefined ? 0 : this.state.horasxdia).toString()
                                        }
                                        onChange={(e) => {
                                            this.textFieldOnChange(e, 0);
                                        }}
                                        type={"number"}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Tipo contrato"
                                        placeholder="Seleccionar.."
                                        id={"tipoContrato"}
                                        text={this.state.tipo_contrato_select}
                                        selectedKey={this.state.sim_info.ID_TIPO_CONTRATO}
                                        onChange={this._onChangeTipoContrato}
                                        options={this.state.opciones_tipoctto}
                                        disabled={this.state.sim_info.ID_ESTADO != 8 ? false : true}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Unidad de negocio"
                                        placeholder="Seleccionar.."
                                        id={"unidadNegocio"}
                                        selectedKey={this.state.sim_info.ID_UNIDAD}
                                        onChange={this._onChangeUnidadNegocio}
                                        options={this.state.opciones_unidad_negocio}
                                        disabled={this.state.sim_info.ID_OPORTUNIDAD > 0 ? true : false}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <ComboBox
                                        label="Contrato marco"
                                        placeholder="Seleccionar.."
                                        id={"contratoMarco"}
                                        selectedKey={this.state.sim_info.ID_CONTRATO_MARCO}
                                        onChange={this._onChangeContratoMarco}
                                        options={/* this.state.opciones_cttomarco */ this.state.opciones_cttomarco_aux}
                                        disabled={this.state.sim_info.ID_OPORTUNIDAD > 0 ? true : false}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg2">
                                    <TextField
                                        label="Moneda Unidad de Negocio"
                                        id={"moneda"}
                                        value={this.state.sim_info.MONEDA}
                                        //onChange={(e) => { this.textFieldOnChange(e, 0) }}
                                        //type={"number"}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                            <br />
                            {/* Fin Entradas de la cabecera */}
                            {/* Función Bloque Ingresos y costos directos */}
                            <div className="acordeon">
                                <Accordion
                                    title="Ingresos y costos directos"
                                    defaultCollapsed={false}
                                    inicio={true}
                                    expandAll={false}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">
                                        {/* {this.bloqueIngresosDirectos()} */}

                                        <IngresosDirectos
                                            actividadesList={this.state.actividadRoles}
                                            listadoOpciones={this.state.opciones_roles}
                                            ingresoP={this.state.ingresosDirectos}
                                            moneda={this.state.moneda_cto}
                                            callbackIngresos={this.callbackIngresos}
                                            horasDia={this.state.horasxdia}
                                            roles={this.state.opciones_roles_aux}
                                            callbackActividades={this.callbackActividades}
                                        />
                                    </div>
                                </Accordion>
                            </div>
                            {/* Fin Función Bloque Ingresos y costos directos */}
                            {/* Función Bloque Ingreso de costos terceros */}
                            <div className="acordeon">
                                <Accordion
                                    title="Ingresos y costos terceros"
                                    defaultCollapsed={false}
                                    inicio={true}
                                    expandAll={false}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">
                                        <IngresosTerceros
                                            actividadesList={this.state.actividadTerceros}
                                            ingresoP={this.state.ingresosTerceros}
                                            moneda={this.state.moneda_cto}
                                            callback={this.callbackIngresosTerceros}
                                            callbackActividades={this.callbackActividadesTerceros}
                                        />
                                    </div>
                                </Accordion>
                            </div>
                            {/* Fin Función Bloque Ingreso de costos terceros */}
                            {/* Función Bloque Gastos */}
                            <div className="acordeon">
                                <Accordion
                                    title="Gastos"
                                    defaultCollapsed={false}
                                    inicio={true}
                                    expandAll={false}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">
                                        <IngresosGastos
                                            actividadesList={this.state.actividadGastos}
                                            listadoOpciones={this.state.opciones_item}
                                            ingresoP={this.state.ingresosGastos}
                                            moneda={this.state.moneda_cto}
                                            callback={this.callbackIngresosGastos}
                                            lista_categorias={this.state.opciones_categoria}
                                            callbackActividades={this.callbackActividadesGastos}
                                        />
                                    </div>
                                </Accordion>
                            </div>
                            {/* Fin Función Bloque Gastos */}
                            {/* Función Bloque Plan de propuesta*/}
                            <div className="acordeon">
                                <Accordion
                                    title="Plan de propuesta"
                                    defaultCollapsed={false}
                                    inicio={true}
                                    expandAll={false}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.bloquePlandelProyecto()}</div>
                                </Accordion>
                            </div>
                            {/* Fin Función Bloque Plan de propuesta */}
                            {/* Función Bloque Resumen */}
                            <div className="acordeon">
                                <Accordion
                                    title="Resumen Economic"
                                    defaultCollapsed={false}
                                    inicio={true}
                                    expandAll={false}
                                    className="fondoTitAcordion"
                                    key={1}
                                    classFondo="fondoSeccion">
                                    <div className="itemContent">{this.bloqueResumen()}</div>
                                </Accordion>
                            </div>
                            <div></div>
                            {/* Botón exportar */}
                            {/* <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg10"></div>
                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                    <div className="exportar">
                                        <DefaultButton 
                                            text="Exportar" 
                                            id="btnExportar"
                                            />
                                    </div>
                                </div>
                            </div> */}
                            {/* Fin Botón exportar */}
                        </div>
                        {this.dialogoErrorNombre()}
                        {this.dialogoGuardar2()}
                        {this.dialogoGuardarError()}
                        {this.dialogoErrorText()}
                        {this.dialogoAprobar()}
                    </div>
                </div>
            );
        } else if (this.state.esUsuario) {
            return (
                <React.Fragment>
                    <div className="mt-4 card sinAcceso text-primary">
                        <div className="card-header">Atención</div>
                        <div className="card-body">
                            <h6>
                                <Glyphicon glyph="info-sign" className="mr-1" /> Usted no posee los permisos para visualizar este formulario, si desea
                                obtener los permisos, contactese con el administrador del sistema.
                            </h6>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div></div>
                </React.Fragment>
            );
        }
    }
    public dialogoErrorText() {
        return (
            <Dialog
                hidden={this.state.dialogoIngresar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Nombre de actividad vacío",
                    subText: "Debe ingresar un nombre para la actividad",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoIngresar: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoGuardar2() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardar2}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Simulador Guardado",
                    subText: "Simulador guardado con éxito",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoGuardar2: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoGuardarError() {
        return (
            <Dialog
                hidden={this.state.dialogoGuardarError}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Error al guardar",
                    subText: "Error al guardar el simulador",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoGuardarError: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }

    public dialogoAprobar() {
        return (
            <Dialog
                hidden={this.state.dialogoAprobar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Simulación Aprobada",
                    subText: "Simulación aprobada con éxito",
                }}
                modalProps={{
                    //isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            this.setState({ dialogoAprobar: true });
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
}

export default Simulador;
