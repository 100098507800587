import React, { useState, useEffect } from 'react';
import 'react-autosuggest';
import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IconButton, PrimaryButton, TextField, Checkbox } from '@fluentui/react';
import { Accordion } from '../../controles/Accordion/Accordion';
import './Simulador.css';
import NumberFormat from 'react-number-format';

interface Props {
    actividadesList?: IngresoGastos[];
    ingresoP?: IngresoData[];
    callback: any;
    listadoOpciones?: any[];
    moneda?: string;
    lista_categorias?: any[];
    callbackActividades: any;
}

interface IngresoGastos {
    ID_SIMULACION: number;
    ID_MODULO: number;
    ID_ACTIVIDAD: number;
    index: number;
    NOMBRE: string;
    GASTO_TOTAL: number;
    VALOR_RECUPERAR: number;
}

interface IngresoData {
    INDEX: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    ITEM: string;
    UNIDAD: string;
    COSTO_UNITARIO: number;
    CANTIDAD:   number;
    GASTO_TOTAL:number;
    VALOR_RECUPERAR: number;
    REEMBOLSABLE: boolean;
}

//const addRol = (e: any, actividadID: number, index: number) => {

//}

const IngresosGastos = ({ actividadesList, ingresoP, callback, listadoOpciones, moneda, lista_categorias, callbackActividades }: Props) => {

    ////console.log(actividadesList);
    ////console.log(ingresoP);

    const [ingresos, setIngreso] = useState(ingresoP);
    const [actividades, setActividades] = useState(actividadesList);
    const [nombreAcividad, setNombreActividad] = useState("");

    const [totalGasto, setTotalGasto] = useState(0);
    const [valorRecuperar, setValorRecuperar] = useState(0);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogSubText, setDialogSubText] = useState("");

    const [indexActividad, setIndexActividad] = useState(-1);
    const [indexIngreso, setIndexIngreso] = useState(-1);

    const [activeKey, setActiveKey] = useState(0);
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [],
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
    };

    const addActividad = () => {
        let actividad = actividades;
        let listaActividades = actividades;
        let nuevoID = 0;
        let exists;

        if (listaActividades != undefined) {
            listaActividades.map((a:any)=>{
                nuevoID = a.ID_ACTIVIDAD;
            });
        }

        if(nombreAcividad != ""){
            ////console.log("nombre actividad no vacio")
            if(listaActividades !== undefined){
                ////console.log("no undefined")
                if(listaActividades.length > 0){
                    for (let i = 0; i < listaActividades.length; i++) {
                        const nombre = listaActividades[i].NOMBRE;
                        ////console.log(nombre)
                        if(nombre == nombreAcividad){
                            exists = true;
                            break;
                        }else{
                            exists = false
                        }
                    }
                }else{
                    if (actividad != undefined) {
                        actividad.push({
                            ID_SIMULACION: 0,
                            ID_MODULO: 3,
                            ID_ACTIVIDAD: nuevoID + 1,
                            index: actividad.length,
                            NOMBRE: nombreAcividad,
                            GASTO_TOTAL: 0,
                            VALOR_RECUPERAR: 0
                        });
                        setActividades([...actividad]);
                    }
                }
                if(exists === false){
                    if (actividad != undefined) {
                        actividad.push({
                            ID_SIMULACION: 0,
                            ID_MODULO: 3,
                            ID_ACTIVIDAD: nuevoID + 1,
                            index: actividad.length,
                            NOMBRE: nombreAcividad,
                            GASTO_TOTAL: 0,
                            VALOR_RECUPERAR: 0
                        });
                        setActividades([...actividad]);
                    }
                }
            }
            callbackActividades(actividades);
        }

        setNombreActividad("");
    }

    const _onChangeRol = (index: number, item: any, a: IngresoGastos): void => {
        let data = ingresos;
        let key = item.key;

        if(data !== undefined){
            data.map((i: IngresoData, ing: number) => {
                if(index === ing){
                    if(listadoOpciones !== undefined){
                        listadoOpciones.map((a: any) => {
                            if(a.key === key){
                                i.ITEM = key;
                            };
                        });
                    };
                }
            })
            setIngreso([...data]);
        }

        
    };

    const addRol = (actividadID: number) => {
        ////console.log(ingresos);
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.push({
                ID_ACTIVIDAD: actividadID,
                ID_SIMULACION: 0,
                ITEM: "",
                UNIDAD: "",
                COSTO_UNITARIO: 0,
                CANTIDAD: 0,
                GASTO_TOTAL: 0,
                VALOR_RECUPERAR: 0,
                REEMBOLSABLE: false,
                INDEX: ingreso.reduce((a: number, b: IngresoData) => 1 + b.INDEX, 0)
            });
            setIngreso([...ingreso]);
            callback(ingreso);
        }
    }

    const deleteRol = (indexI: number) => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            let row = ingreso.find((ing: IngresoData, i: number) => i === indexI);
            let sumaRows = 0;
            if (row != undefined) {
                sumaRows = row.COSTO_UNITARIO + row.CANTIDAD;
            }

            if (sumaRows === 0) {
                ingreso.splice(indexI, 1);
                setIngreso([...ingreso]);
                ////console.log(ingreso);
                callback(ingreso);
            } else {
                // alert("esta fila tiene datos!");
                setDialogTitle("Ingreso con datos!");
                setDialogSubText("Esta fila contiene datos ingresados ¿Está seguro que desea eliminar?");
                setIndexIngreso(indexI);
                setDialog(false);
            }
        }
    }

    const deleteRolIndex = () => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.splice(indexIngreso, 1);
            setIngreso([...ingreso]);
            ////console.log(ingreso);
            setIndexIngreso(-1);
            setIndexActividad(-1);
            setDialog(true);
            callback(ingreso);
            // ingreso.forEach((ing, i) => {
            //     if (i === indexIngreso) {
            //         if (ingreso != undefined) {
            //             ingreso.splice(indexIngreso);
            //             setIngreso([...ingreso]);
            //             setIndexIngreso(-1);
            //             setIndexActividad(-1);
            //             setDialog(true);
            //         }
            //     }
            // });
        }
    }

    const deleteActividad = (actividadID: number, indexA: number) => {
        let ingreso = ingresos;
        let actividad = actividades;
        let tieneIngresos = false;

        if (ingreso != undefined) {
            tieneIngresos = ingreso.some((i: IngresoData) => i.ID_ACTIVIDAD === actividadID);
        }

        if (tieneIngresos) {
            // alert("No puede eliminar porque tiene items adentro!");
            setIndexActividad(indexA);
            setDialogTitle("Actividad con roles!");
            setDialogSubText("¿Está seguro que desea eliminar la actividad?");
            setDialog(false);
        } else {
            if (actividad != undefined) {
                actividad.splice(indexA, 1);
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        }
    }

    const deleteActividadRoles = () => {
        let ingreso = ingresos;
        let ingreso2 : any = [];
        let actividad = actividades;
        let actividad2 : any = [];
        let actividadEliminar: IngresoGastos | undefined;

        console.log(ingreso);
        console.log(actividad);

        if (actividad != undefined) {

            actividadEliminar = actividad.find((a, i) => i === indexActividad);

            if (ingreso != undefined) {
                if (actividadEliminar != undefined) {
                    let idActividad = actividadEliminar.ID_ACTIVIDAD;

                    ingreso.map((ing: any)=>{
                        if(ing.ID_ACTIVIDAD != idActividad){
                            ingreso2.push({
                                CANTIDAD: ing.CANTIDAD,
                                COSTO_UNITARIO: ing.COSTO_UNITARIO,
                                GASTO_TOTAL: ing.GASTO_TOTAL,
                                ID: ing.ID,
                                ID_ACTIVIDAD: ing.ID_ACTIVIDAD,
                                ID_FILA_ITEM: ing.ID_FILA_ITEM,
                                ID_SIMULACION: ing.ID_SIMULACION,
                                ITEM: ing.ITEM,
                                REEMBOLSABLE: ing.REEMBOLSABLE,
                                UNIDAD: ing.UNIDAD,
                                VALOR_RECUPERAR: ing.VALOR_RECUPERAR
                            });
                        }
                    })

                    actividad.map((a: any) => {
                        if(a.ID_ACTIVIDAD != idActividad){
                            actividad2.push({
                                GASTO_TOTAL: a.GASTO_TOTAL,
                                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                ID_MODULO: a.ID_MODULO,
                                ID_SIMULACION: a.ID_SIMULACION,
                                NOMBRE: a.NOMBRE,
                                TOTAL_GASTO: a.TOTAL_GASTO,
                                VALOR_RECUPERAR: a.VALOR_RECUPERAR
                            });
                        }
                    });
                }

                /*actividad.splice(indexActividad, 1);
                setIndexActividad(-1);
                setActividades([...actividad]);
                setIngreso([...ingreso]);
                setDialog(true);
                callback(ingreso);*/

                setActividades([...actividad2]);
                setDialog(true);
                callback(ingreso2);
                callbackActividades(actividad2);
            }
        }
    }

    const onValueChanged = (index: number, ev: any, indexA: number, e?: string) => {
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.floatValue : ev.target.value;
        ////console.log(index, indexA, value, key);
        let val = 0;

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    switch (key) {
                        case "unidadGastos":
                            i.UNIDAD = value;
                            break;
                        case "costoUnitarioGastos":
                            val = value === "" ? 0 : parseFloat(value);
                            if(val >= 0){
                                i.COSTO_UNITARIO = val;
                            }else{
                                i.COSTO_UNITARIO = 0;
                            }
                            //Calcular
                            break;
                        case "cantidadGastos":
                            val = value === "" ? 0 : parseFloat(value);
                            if(val >= 0){
                                i.CANTIDAD = val;
                            }else{
                                i.CANTIDAD = 0;
                            }
                            //Calcular
                            break;
                        case "gastoTotalGastos":
                            val = value === "" ? 0 : parseFloat(value);
                            if(val >= 0){
                                i.GASTO_TOTAL = val;
                            }else{
                                i.GASTO_TOTAL = 0;
                            }
                            break;
                        case "valorRecuperarGastos":
                            val = value === "" ? 0 : parseFloat(value);
                            if(val >= 0){
                                i.VALOR_RECUPERAR = val;
                            }else{
                                i.VALOR_RECUPERAR = 0;
                            }
                            //Calcular
                            break;
                        case "reembolsableGastos":
                            i.REEMBOLSABLE = value;
                            break;
                        case "itemGastos":
                            i.ITEM = value;
                            break;
                    }
                    i.GASTO_TOTAL = parseFloat(((i.COSTO_UNITARIO) * (i.CANTIDAD)).toFixed(2));
                }
            });

            //Total Actividad
            calcularTotalActividad(data, indexA);



            setIngreso([...data]);
            callback(data);
        }
    }

    const onCheckChange = (index: number, ev: any, indexA: number) => {
        let key = ev.target.id;
        let value = ev.target.checked;
        ////console.log(index, indexA, value, key);

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    i.REEMBOLSABLE = value;
                    if(value === true && i.GASTO_TOTAL > 0){
                        i.VALOR_RECUPERAR = i.GASTO_TOTAL;
                    }else if(value === false){
                        i.VALOR_RECUPERAR = 0;
                    }
                }
            });

            setIngreso([...data]);
            callback(data);
        }
    }


    const calcularTotalActividad = (ingresos: IngresoData[], actividadID: number) => {
        if (ingresos != undefined) {
            let ingresosAct = ingresos.filter(i => { return i.ID_ACTIVIDAD === actividadID });
            let actividadesCopy = actividades;
            if (actividadesCopy != undefined) {
                //let act = actividadesCopy.find(a => a.actividadID === actividadID);
                actividadesCopy.forEach(a => {
                    if (a.ID_ACTIVIDAD === actividadID) {
                        a.GASTO_TOTAL = parseFloat(ingresosAct.reduce((a, b) => a + b.GASTO_TOTAL, 0).toFixed(2));
                        a.VALOR_RECUPERAR = parseFloat(ingresosAct.reduce((a, b) => a + b.VALOR_RECUPERAR, 0).toFixed(2));
                    }
                });

                //actualizar totales
                setTotalGasto(actividadesCopy.reduce((a, b) => a + b.GASTO_TOTAL, 0));
                setValorRecuperar(actividadesCopy.reduce((a, b) => (a + b.VALOR_RECUPERAR) - b.GASTO_TOTAL, 0));

                setActividades([...actividadesCopy]);
            }
        }
    }

    useEffect(() => {
        let data = ingresos;
            data!.map((a:any) => {
            ////console.log(a);
            calcularTotalActividad(data!, a.ID_ACTIVIDAD);
            })
    }, []);

    document.onclick = () => {
        /* let data = ingresos;
            data!.map((a:any) => {
            ////console.log(a);
            calcularTotalActividad(data!, a.ID_ACTIVIDAD);
            }) */
    }

    const _onChangeActividad = (event: any, item: any): void => {
        let text = item.text;

        setNombreActividad(text);
    };

    const FilasIngresos = (a: IngresoGastos) => {
        ////console.log(a)
        return (
            <div>
                {
                    ingresos != undefined ? ingresos.map((i: IngresoData, indexI: number) => {
                        return (
                            <div>
                                {
                                    i.ID_ACTIVIDAD === a.ID_ACTIVIDAD ?
                                        <div>
                                            <div key={indexI}>
                                            <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3 txtSmall">
                                                    <TextField
                                                        label="Ítem"
                                                        placeholder="Nombre ítem"
                                                        defaultValue={i.ITEM}
                                                        onChange={(e) => { onValueChanged(indexI, e, i.ID_ACTIVIDAD) }}
                                                        id={"itemGastos"}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 txtSmall">
                                                    <TextField
                                                        label="Unidad"
                                                        id={"unidadGastos"}
                                                        placeholder="..."
                                                        defaultValue={i.UNIDAD}
                                                        onChange={(e) => { onValueChanged(indexI, e, i.ID_ACTIVIDAD) }}
                                                    />
                                                </div>
                                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 txtSmall">
                                                    <span className={"formateo"}>
                                                        <label>Costo unitario</label>
                                                            <NumberFormat
                                                            id={"costoUnitarioGastos"}
                                                            onValueChange={(e) => { onValueChanged(indexI, e, i.ID_ACTIVIDAD, "costoUnitarioGastos") }}
                                                            defaultValue={i.COSTO_UNITARIO <= 0 ? "" : i.COSTO_UNITARIO.toString().replace(".", ",")}
                                                            value={i.COSTO_UNITARIO === 0 || i.COSTO_UNITARIO <= 0 ? "" : i.COSTO_UNITARIO.toString().replace(".", ",")}
                                                            disabled={false}
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','} 
                                                            placeholder={"..."}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                    <span className={"formateo"}>
                                                        <label>Cantidad</label>
                                                            <NumberFormat
                                                            id={"cantidadGastos"}
                                                            onValueChange={(e) => { onValueChanged(indexI, e, i.ID_ACTIVIDAD, "cantidadGastos") }}
                                                            defaultValue={i.CANTIDAD <= 0 ? "" : i.CANTIDAD.toString().replace(".", ",")}
                                                            value={i.CANTIDAD === 0 || i.CANTIDAD <= 0 ? "" : i.CANTIDAD.toString().replace(".", ",")}
                                                            disabled={false}
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','} 
                                                            placeholder={"..."}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                    <span className={"formateo"}>
                                                        <label>Gasto total</label>
                                                            <NumberFormat
                                                            id={"gastoTotalGastos"}
                                                            onValueChange={(e) => { onValueChanged(indexI, e, i.ID_ACTIVIDAD, "gastoTotalGastos") }}
                                                            defaultValue={i.GASTO_TOTAL <= 0 ? "" : i.GASTO_TOTAL.toString().replace(".", ",")}
                                                            value={i.GASTO_TOTAL === 0 || i.GASTO_TOTAL <= 0 ? "" : i.GASTO_TOTAL.toString().replace(".", ",")}
                                                            disabled={true}
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','} 
                                                            placeholder={"..."}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                    <span className={"formateo"}>
                                                        <label>Val. a recuperar</label>
                                                            <NumberFormat
                                                            id={"valorRecuperarGastos"}
                                                            onValueChange={(e) => { onValueChanged(indexI, e, i.ID_ACTIVIDAD, "valorRecuperarGastos") }}
                                                            defaultValue={i.VALOR_RECUPERAR <= 0 ? "" : i.VALOR_RECUPERAR.toString().replace(".", ",")}
                                                            value={i.VALOR_RECUPERAR === 0 || i.VALOR_RECUPERAR <= 0 ? "" : i.VALOR_RECUPERAR.toString().replace(".", ",")}
                                                            disabled={false}
                                                            thousandSeparator={'.'} 
                                                            decimalSeparator={','} 
                                                            placeholder={"..."}
                                                        />
                                                    </span>
                                                </div>
                                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                                                    <div className="checkbox" id="simGastosCobrables">
                                                        <Checkbox
                                                            label="Cobrable al cliente"
                                                            //id={"reembolsableGastos"}
                                                            //defaultValue={i.VALOR_RECUPERAR.toString()}
                                                            checked={i.REEMBOLSABLE}
                                                            onChange={(e) => { onCheckChange(indexI, e, i.ID_ACTIVIDAD) }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 mt-4" id="divEliminarRolGasto">
                                                    <button id="btnAgregarRol" onClick={(e) => { deleteRol(indexI) }}><i className="ms-Icon ms-Icon--Delete" aria-hidden="true" id="btnEliminarRol"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        : ""
                                }
                            </div>
                        );

                    })
                        : ""
                }

                <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" id="divAgregarActividad">
                        <button id="btnAgregarRol" onClick={(e) => { addRol(a.ID_ACTIVIDAD) }}><i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarRol"><span className="txtAgregar">&nbsp; Agregar ítem</span></i></button>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                    <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg7" id="divTotal">Total</div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 pb-1">
                        <TextField
                            id={"totalActividadGastosGastoTotal"}
                            disabled={true}
                            placeholder={"..."}
                            value={a.GASTO_TOTAL != undefined ? new Intl.NumberFormat('de-DE').format(a.GASTO_TOTAL) : ""}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadGastosValorRecuperar"}
                            disabled={true}
                            placeholder={"..."}
                            value={a.VALOR_RECUPERAR != undefined ? new Intl.NumberFormat('de-DE').format(a.VALOR_RECUPERAR) : ""}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const Resumen = () => {
        return (
            <div className="ms-Grid-row divTotalBloque" id="divTotalBloque">
                <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg7" id="divTotal">Total gastos</div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueGastoTotal"}
                        disabled={true}
                        placeholder={"..."}
                        value={totalGasto != undefined ? new Intl.NumberFormat('de-DE').format(totalGasto) : ""}
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueGastoValorRecuperar"}
                        disabled={true}
                        placeholder={"..."}
                        value={valorRecuperar != undefined ? new Intl.NumberFormat('de-DE').format(valorRecuperar) : ""}
                    />
                </div>
            </div>
        );
    }

    const Actividades = () => {
        return (
            actividades != undefined ? actividades.map((a: IngresoGastos, indexA: number) => {
                return (
                    <div>
                        <div>

                            <div className="acordeonActividad">
                                <Accordion title={a.NOMBRE}
                                    defaultCollapsed={false} inicio={true} expandAll={false} className="fondoTitAcordionActividad" key={1} classFondo="fondoSeccionActividad">
                                    <div className="itemContent">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="divBloque">
                                            <button id="btnAgregarRol" className="cierre" onClick={(e) => { deleteActividad(a.ID_ACTIVIDAD, indexA) }}><i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" id="btnEliminarActividad"></i></button>
                                        </div>
                                    </div>
                                    {FilasIngresos(a)}
                                </Accordion>
                            </div>

                        </div>
                    </div>);
            })
                : ""
        );
    }

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => { setDialog(true) }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => { indexIngreso > -1 ? deleteRolIndex() : deleteActividadRoles() }} text="Sí, Eliminar" />
                    <DefaultButton onClick={() => { setDialog(true) }} text="No" />
                </DialogFooter>
            </Dialog>

        );
    }

    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    {/* <Accordion title="Ingresos y costos directos"
                        defaultCollapsed={true} inicio={true} expandAll={false} className="fondoTitAcordion" key={1} classFondo="fondoSeccion"> */}
                    <div className="itemContent">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm8 ms-md4 ms-lg4 mt-2 mb-2">
                                <ComboBox
                                    placeholder="Nombre Concepto"
                                    id={"nombreActividadDirecto"}
                                    //value={this.state.nombreActividad}
                                    onChange={_onChangeActividad}
                                    options={lista_categorias}
                                    allowFreeform={true}
                                    autoComplete="on"
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm1 ms-md3 ms-lg3 mt-2 mb-2" id="divAgregarActividad">
                                <button id="btnAgregarActividad" className="btnAgregarActividad" onClick={(e) => { addActividad() }}>
                                    <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarActividad">&nbsp;
                                        <span className="txtAgregar">Agregar concepto</span>
                                    </i>
                                </button>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 d-flex justify-content-end mt-3">
                                <label>Los valores reflejados están en la moneda del Contrato Marco: <span>{moneda}</span></label>
                            </div>
                        </div>
                        {
                            Actividades()
                        }
                        {
                            dialog()
                        }
                        {
                            Resumen()
                        }
                    </div>
                    {/* </Accordion> */}
                </div>
            </div>
        </React.Fragment >
    );
}

export default IngresosGastos;
