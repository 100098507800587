import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AzureAD, AuthenticationState, IAzureADFunctionProps, IAccountInfo } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import Dropzone from "react-dropzone";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { basicReduxStore } from "../../reduxStore";
import { IconButton, IIconProps, TextField } from "office-ui-fabric-react";
import axios from "axios";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "./ordenCompra.css";
import { fetchEngine } from "../../fetchData";
import NumberFormat from "react-number-format";

const emojiIcon: IIconProps = { iconName: "RecycleBin" };
const add: IIconProps = { iconName: "CircleAdditionSolid" };
const download: IIconProps = { iconName: "CloudDownload" };
const izquierda: IIconProps = { iconName: "DecreaseIndentArrowMirrored" };
const derecha: IIconProps = { iconName: "DecreaseIndentArrow" };
interface Props {
    opoID: number;
    relativeUrl: string;
    tenantName: string;
    library: string;
    carpeta: string;
    disabled: boolean;
}

const OrdenCompra = ({ opoID, relativeUrl, tenantName, library, carpeta, disabled }: Props) => {
    const [archivo, setArchivo] = useState({ name: "" });
    const [montoOC, setMontoOC] = useState(0);
    const [nombreOC, setNombreOC] = useState("");
    const [ocs, setOcs] = useState([]);
    const [dialogo, setDialogo] = useState(true);
    const [dialogoTitle, setDialogoTitle] = useState("");
    const [dialogoSubTitle, setDialogoSubTitle] = useState("");
    const [dialogoError, setDialogoError] = useState(true);
    const [dialogoTitleOC, setDialogoTitleOC] = useState("");
    const [dialogoSubTitleOC, setDialogoSubTitleOC] = useState("");
    const [dialogoErrorOC, setDialogoErrorOC] = useState(true);
    const [isDialogHidden, setDialogHidden] = useState(true);
    const [documentoNombre, setDocumentoNombre] = useState("");
    const [ID_OC, setID_OC] = useState(0);
    const [ID_OPORTUNIDAD, setID_OPORTUNIDAD] = useState(0);
    const [tokenAPI, setTokenAPI] = useState<string | null>("");
    const [tokenAPIGraphDocuments, setTokenGraphDocuments] = useState<string | null>("");

    const graphEndPoint = fetchEngine.graphEndPoint;

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                setTokenAPI(d.tokenAPI);
                setTokenGraphDocuments(d.tokenAPIGraphDocuments);
            });
        }
        if (tokenAPI != "") {
            obtenerOC();
        }
    }, [relativeUrl, tenantName, opoID, tokenAPI, tokenAPIGraphDocuments]);

    const asignarAchivo = (archivo: any) => {
        setArchivo(archivo[0]);
    };

    const obtenerOC = async () => {
        fetchEngine
            .getAPI(`/api/ordencompra/${opoID}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                if (resultado.ok) {
                    if (resultado.data) {
                        setOcs(resultado.data);
                    }
                    setDialogo(true);
                    setArchivo({ name: "" });
                    setMontoOC(0);
                    setNombreOC("");
                } else {
                    setDialogoError(false);
                }
            })
            .catch((err) => {
                setDialogoError(false);
            });
    };

    const deleteOC = async (oc_id: number, id_oportunidad: number) => {
        fetchEngine
            .postAPI("/api/ordencompra/DeleteOC?ID=" + oc_id + "&id_oportunidad=" + id_oportunidad, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    eliminarArchivo();
                    obtenerOC();
                } else {
                    console.log(result);
                }
            })
            .catch((err) => {
                setDialogo(true);
                setDialogoTitle("Error");
                setDialogoSubTitle("Ocurrio un problema al eliminar OC.");
                setDialogoError(false);
            });
    };

    const eliminarArchivo = async () => {
        setDialogHidden(true);
        axios
            .get(`${graphEndPoint}/sites/${tenantName}:${relativeUrl}:/drives`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenAPIGraphDocuments}`,
                },
            })
            .then((r) => {
                let librerias = r.data.value;
                let documentos = librerias.filter((l: any) => {
                    return l.name === library;
                })[0];

                let idLibreria = documentos.id;
                axios
                    .delete(`${graphEndPoint}/drives/${idLibreria}/items/${ID_OC}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${tokenAPIGraphDocuments}`,
                        },
                    })
                    .then((d) => {})
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const insertarOC = async (url_oc: string) => {
        let obj = {
            ID_OPORTUNIDAD: opoID,
            MONTO: montoOC,
            ORDEN_COMPRA: nombreOC,
            URL_OC: url_oc,
        };

        fetchEngine
            .postAPI(`/api/ordencompra/`, obj, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                let resultado = result;
                if (resultado.ok) {
                    if (resultado.data) {
                    }
                    obtenerOC();
                } else {
                    setDialogo(true);
                    setDialogoTitle("Error");
                    setDialogoSubTitle("Ocurrio un problema al agregar OC.");
                    setDialogoError(false);
                }
            })
            .catch((err) => {
                setDialogo(true);
                setDialogoTitle("Error");
                setDialogoSubTitle("Ocurrio un problema al agregar OC.");
                setDialogoError(false);
            });
    };
    const dialogoMessage = () => {
        return (
            <Dialog
                hidden={dialogo}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: dialogoTitle,
                    subText: dialogoSubTitle,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {}
            </Dialog>
        );
    };

    const dialogosError = () => {
        return (
            <Dialog
                hidden={dialogoError}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: dialogoTitle,
                    subText: dialogoSubTitle,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                setDialogoError(true);
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    };
    const dialogosErrorOC = () => {
        return (
            <Dialog
                hidden={dialogoErrorOC}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: dialogoTitleOC,
                    subText: dialogoSubTitleOC,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                setDialogoErrorOC(true);
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    };
    const dialogosOC = () => {
        return (
            <Dialog
                hidden={dialogoError}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: dialogoTitle,
                    subText: dialogoSubTitle,
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                {
                    <DialogFooter>
                        <DefaultButton
                            text="Cerrar"
                            onClick={() => {
                                setDialogoError(true);
                            }}></DefaultButton>
                    </DialogFooter>
                }
            </Dialog>
        );
    };

    const dialogoEliminarDocumento = () => {
        return (
            <div>
                <Dialog
                    hidden={isDialogHidden}
                    onDismiss={() => setDialogHidden(true)}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: "Eliminar OC",
                        subText: `¿Está seguro que desea eliminar OC ?`,
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 450 } },
                    }}>
                    <DialogFooter>
                        <PrimaryButton
                            onClick={() => {
                                deleteOC(ID_OC, ID_OPORTUNIDAD);
                                setDialogo(false);
                                setDialogoTitle("Eliminando OC");
                                setDialogoSubTitle("Espere mientra se elimina OC..");
                                setDialogHidden(true);
                            }}>
                            Eliminar
                        </PrimaryButton>
                        <PrimaryButton onClick={() => setDialogHidden(true)}>Cerrar</PrimaryButton>
                    </DialogFooter>
                </Dialog>
            </div>
        );
    };

    const manejarDocumentos = async () => {
        // console.log(montoOC, nombreOC);

        if (montoOC && nombreOC) {
            setDialogoTitle("Agregando OC");
            setDialogoSubTitle("Espere mientra se agrega OC..");
            setDialogo(false);
            //Cargar documentos a través de la API de MSGraph
            axios
                .get(`${graphEndPoint}/sites/${tenantName}:${relativeUrl}:/drives`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${tokenAPIGraphDocuments}`,
                    },
                })
                .then((r) => {
                    let librerias = r.data.value;

                    let documentos = librerias.filter((l: any) => {
                        return l.name === library;
                    })[0];

                    let idLibreria = documentos.id;
                    let fileName = archivo.name;
                    if (fileName === "") {
                        setDialogoTitleOC("Error al agregar OC");
                        setDialogoSubTitleOC("Debe adjuntar un archivo");
                        setDialogoErrorOC(false);
                        setDialogo(true);
                    } else {
                        axios
                            .put(`${graphEndPoint}/drives/${idLibreria}/root:/${carpeta}/${fileName}:/content`, archivo, {
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${tokenAPIGraphDocuments}`,
                                },
                            })
                            .then((res) => {
                                let url = res.data.webUrl;
                                insertarOC(url);
                                setMontoOC(0);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setDialogoTitle("Error");
            setDialogoSubTitle("Falta ingresar un Numero o Monto de Contrato.");
            setDialogoError(false);
        }
    };

    return (
        <React.Fragment>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Número de contrato</th>
                            <th>Monto de contrato</th>
                            <th>Adjunto OC</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <TextField
                                    disabled={disabled}
                                    value={nombreOC}
                                    onChange={(e, v) => {
                                        setNombreOC(v != undefined ? v : "");
                                    }}
                                    title="Nombre de contrato"
                                />
                            </td>
                            <td>
                                <NumberFormat
                                    thousandSeparator={"."}
                                    value={montoOC === 0 ? "" : montoOC}
                                    className={"numberinput"}
                                    decimalSeparator={","}
                                    prefix={"$"}
                                    disabled={disabled}
                                    min={0}
                                    onValueChange={(v) =>
                                        setMontoOC(v != undefined ? (parseInt(v.value) < 0 ? 0 : parseInt(v != undefined ? v.value : "0")) : 0)
                                    }
                                />
                            </td>
                            <td>
                                {disabled ? (
                                    ""
                                ) : (
                                    <Dropzone
                                        onDrop={(acceptedFiles) => {
                                            asignarAchivo(acceptedFiles);
                                        }}
                                        multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {archivo.name === "" ? (
                                                        <p
                                                            style={{
                                                                cursor: "pointer",
                                                                textAlign: "center",
                                                                border: "1px solid #bcd4ff",
                                                                background: "whitesmoke",
                                                            }}>
                                                            <IconButton iconProps={izquierda} />
                                                            Arrastre o seleccione orden de compra
                                                            <IconButton iconProps={derecha} />
                                                        </p>
                                                    ) : archivo != undefined ? (
                                                        archivo.name
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                )}
                            </td>
                            <td>
                                <div>
                                    <IconButton
                                        disabled={disabled}
                                        className="valid"
                                        iconProps={add}
                                        title="Agregar OC a la oportunidad"
                                        onClick={() => {
                                            manejarDocumentos();
                                        }}
                                        ariaLabel=""
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className="table">
                    <thead>
                        <tr>
                            <th>N° de contrato</th>
                            <th>Monto de contrato</th>
                            <th>OC / Contrato</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ocs.map((a: any, i) => {
                            return (
                                <tr key={"tr_oc_" + i}>
                                    <td key={"tr_oc_1_" + i}>{a.ORDEN_COMPRA}</td>
                                    <td key={"tr_oc_2_" + i}>
                                        {parseInt(a.MONTO).toLocaleString() === "0" ? "" : parseInt(a.MONTO).toLocaleString()}
                                    </td>
                                    <td key={"tr_oc_3_" + i}>
                                        <a href={a.URL_OC} target="_blank">
                                            <IconButton iconProps={download} title="Descargar documento" ariaLabel="" />
                                        </a>
                                        {disabled ? (
                                            ""
                                        ) : (
                                            <IconButton
                                                iconProps={emojiIcon}
                                                title="Eliminar documento"
                                                onClick={() => {
                                                    setID_OC(a.ID);
                                                    setID_OPORTUNIDAD(a.ID_OPORTUNIDAD);
                                                    setDialogHidden(false);
                                                }}
                                                ariaLabel="Eliminar documento"
                                            />
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {dialogoMessage()}
                {dialogosError()}
                {dialogosErrorOC()}
                {dialogoEliminarDocumento()}
            </div>
        </React.Fragment>
    );
};

export default OrdenCompra;
