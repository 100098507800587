import React, { useState, useEffect } from "react";
import "react-autosuggest";
import {
    ComboBox,
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IComboBox,
    IComboBoxOption,
    IconButton,
    PrimaryButton,
    TextField,
} from "@fluentui/react";
import { Accordion } from "../../controles/Accordion/Accordion";
import "./Simulador.css";
import NumberFormat from "react-number-format";

interface Props {
    actividadesList?: Ingreso[];
    ingresoP?: IngresoData[];
    callbackIngresos: any;
    listadoOpciones?: any[];
    horasDia?: number;
    roles?: any[];
    moneda?: string;
    simID?: number;
    callbackActividades: any;
}

interface Ingreso {
    ID_MODULO: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    INDEX: number;
    NOMBRE: string;
    TOTAL_HORAS: number;
    TARIFA_HH: number;
    COSTO_HH: number;
    TOTAL_INGRESOS: number;
    TOTAL_COSTO: number;
    TOTAL_RENTABILIDAD: number;
}

interface IngresoData {
    INDEX: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    ID_CARGO: number; // rol
    TIEMPO_INVERTIR: number;
    SEMANAS: number;
    HORAS: number;
    TARIFA_HH: number;
    COSTO_HH: number;
    TOTAL_INGRESOS: number;
    TOTAL_COSTO: number;
    MARGEN: number;
}

const IngresosDirectos = ({
    actividadesList,
    ingresoP,
    callbackIngresos,
    listadoOpciones,
    horasDia,
    roles,
    moneda,
    simID,
    callbackActividades,
}: Props) => {
    const [horas_dia, setHorasDia] = useState(horasDia);
    const [ingresos, setIngreso] = useState(ingresoP);
    const [actividades, setActividades] = useState(actividadesList);
    const [nombreAcividad, setNombreActividad] = useState("");
    const [horas, setHoras] = useState(0);
    const [tarfiaHH, setTarifaHH] = useState(0);
    const [costoHH, setCostoHH] = useState(0);
    const [totalIngresos, setTotalIngresos] = useState(0);
    const [totalCostos, setTotalCostos] = useState(0);
    const [totalRentabilidad, setTotalRentabilidad] = useState(0);
    const [id_actividad, setId_actividad] = useState(0);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogSubText, setDialogSubText] = useState("");

    const [indexActividad, setIndexActividad] = useState(-1);
    const [indexIngreso, setIndexIngreso] = useState(-1);

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
    };

    // setIngreso(ingresoP);
    // console.log(ingresos);

    const addActividad = () => {
        let actividad = actividades;
        let nuevoID = 0;

        if (actividad != undefined) {
            actividad.map((a: any) => {
                nuevoID = a.ID_ACTIVIDAD;
            });
        }
        if (nombreAcividad != "") {
            if (actividad != undefined) {
                actividad.push({
                    ID_MODULO: 1,
                    ID_SIMULACION: 0,
                    ID_ACTIVIDAD: nuevoID + 1,
                    INDEX: actividad.length,
                    NOMBRE: nombreAcividad,
                    COSTO_HH: 0,
                    TOTAL_COSTO: 0,
                    TARIFA_HH: 0,
                    TOTAL_HORAS: 0,
                    TOTAL_INGRESOS: 0,
                    TOTAL_RENTABILIDAD: 0,
                });
                //console.log(actividad);
                setActividades([...actividad]);
                callbackActividades(actividad);
                //console.log(actividades)
            }
        } else {
            console.log("errorActividad");
        }

        setNombreActividad("");
    };

    const addRol = (actividadID: number) => {
        let ingreso = ingresos;
        //console.log(ingreso);
        if (ingreso != undefined) {
            ingreso.push({
                ID_ACTIVIDAD: actividadID,
                COSTO_HH: 0,
                HORAS: 0,
                ID_CARGO: 0,
                ID_SIMULACION: 0,
                MARGEN: 0,
                SEMANAS: 0,
                TARIFA_HH: 0,
                TIEMPO_INVERTIR: 0,
                TOTAL_COSTO: 0,
                TOTAL_INGRESOS: 0,
                INDEX: ingreso.reduce(
                    (a: number, b: IngresoData) => 1 + b.INDEX,
                    0
                ),
            });
            //console.log(ingreso);
            setIngreso([...ingreso]);
            callbackIngresos(ingreso);
        }
    };

    const deleteRol = (indexI: number) => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            let row = ingreso.find(
                (ing: IngresoData, i: number) => i === indexI
            );
            //console.log(row)
            let sumaRows = 0;
            if (row != undefined) {
                sumaRows =
                    row.TARIFA_HH +
                    row.COSTO_HH +
                    row.SEMANAS +
                    row.TIEMPO_INVERTIR;
            }

            if (sumaRows === 0) {
                ingreso.splice(indexI, 1);
                setIngreso([...ingreso]);
                //console.log(ingreso);
                callbackIngresos(ingreso);
            } else {
                setDialogTitle("Ingreso con datos!");
                setDialogSubText(
                    "Esta fila contiene datos ingresados ¿Está seguro que desea eliminar?"
                );
                setIndexIngreso(indexI);
                setDialog(false);
            }
        }
    };

    const deleteRolIndex = () => {
        let ingreso = ingresos;
        //console.log(indexIngreso)
        if (ingreso != undefined) {
            ingreso.splice(indexIngreso, 1);
            setIngreso([...ingreso]);
            Actividades();
            //   console.log(ingreso);
            setIndexIngreso(-1);
            setIndexActividad(-1);
            setDialog(true);
            callbackIngresos(ingreso);
            calcularTotalActividad(ingreso, id_actividad);
        }
    };

    const deleteActividad = (actividadID: number, indexA: number) => {
        let ingreso = ingresos;
        let actividad = actividades;
        let tieneIngresos = false;

        if (ingreso != undefined) {
            tieneIngresos = ingreso.some(
                (i: IngresoData) => i.ID_ACTIVIDAD === actividadID
            );
        }

        if (tieneIngresos) {
            setIndexActividad(indexA);
            setDialogTitle("Actividad con roles!");
            setDialogSubText("¿Está seguro que desea eliminar la actividad?");
            setDialog(false);
        } else {
            if (actividad != undefined) {
                actividad.splice(indexA, 1);
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        }
    };

    const deleteActividadRoles = () => {
        let ingreso = ingresos;
        let ingreso2: any = [];
        let actividad = actividades;
        let actividad2: any = [];
        let actividadEliminar: Ingreso | undefined;
        let total_ingresos = 0;
        let total_costo = 0;

        if (actividad != undefined) {
            actividadEliminar = actividad.find((a, i) => i === indexActividad);

            if (ingreso != undefined) {
                if (actividadEliminar != undefined) {
                    let idActividad = actividadEliminar.ID_ACTIVIDAD;

                    ingreso.map((ing: any) => {
                        if (ing.ID_ACTIVIDAD != idActividad) {
                            ingreso2.push({
                                COSTO_HH: ing.COSTO_HH,
                                HORAS: ing.HORAS,
                                ID: ing.ID,
                                ID_ACTIVIDAD: ing.ID_ACTIVIDAD,
                                ID_CARGO: ing.ID_CARGO,
                                ID_FILA_CARGO: ing.ID_FILA_CARGO,
                                ID_SIMULACION: ing.ID_SIMULACION,
                                MARGEN: ing.MARGEN,
                                RESERVA: ing.RESERVA,
                                SEMANAS: ing.SEMANAS,
                                TARIFA_HH: ing.TARIFA_HH,
                                TIEMPO_INVERTIR: ing.TIEMPO_INVERTIR,
                                TOTAL_COSTO: ing.TOTAL_COSTO,
                                TOTAL_INGRESOS: ing.TOTAL_INGRESOS,
                            });
                        }
                    });

                    actividad.map((a: any) => {
                        if (a.ID_ACTIVIDAD != idActividad) {
                            actividad2.push({
                                COSTO_HH: a.COSTO_HH,
                                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                ID_MODULO: a.ID_MODULO,
                                ID_SIMULACION: a.ID_SIMULACION,
                                INDEX: a.INDEX,
                                NOMBRE: a.NOMBRE,
                                TARIFA_HH: a.TARIFA_HH,
                                TOTAL_COSTO: a.TOTAL_COSTO,
                                TOTAL_HORAS: a.TOTAL_HORAS,
                                TOTAL_INGRESOS: a.TOTAL_INGRESOS,
                                TOTAL_RENTABILIDAD: a.TOTAL_RENTABILIDAD,
                            });
                        }
                    });
                }

                console.log(actividad2);

                setActividades([...actividad2]);
                setIngreso([...ingreso2]);
                setDialog(true);
                callbackIngresos(ingreso2);
                callbackActividades(actividad2);

                total_ingresos = actividad2.reduce(
                    (a: any, b: any) => a + b.TOTAL_INGRESOS,
                    0
                );
                total_costo = actividad2.reduce(
                    (a: any, b: any) => a + b.TOTAL_COSTO,
                    0
                );

                setHoras(
                    actividad2.reduce((a: any, b: any) => a + b.TOTAL_HORAS, 0)
                );
                setTarifaHH(
                    actividad2.reduce((a: any, b: any) => a + b.TARIFA_HH, 0)
                );
                setCostoHH(
                    actividad2.reduce((a: any, b: any) => a + b.COSTO_HH, 0)
                );
                setTotalIngresos(total_ingresos);
                setTotalCostos(total_costo);

                setTotalRentabilidad(
                    total_ingresos != 0
                        ? parseFloat(
                              (
                                  ((total_ingresos - total_costo) /
                                      total_ingresos) *
                                  100
                              ).toFixed(2)
                          )
                        : 0
                );
            }
        }
    };

    const onValueChanged = (
        index: number,
        ev: any,
        indexA: number,
        e?: string
    ) => {
        //console.log(ev)
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.floatValue : ev.target.value;
        //console.log(index, indexA, value, key);
        let val = 0;

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    switch (key) {
                        case "costoHHDirecto":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.COSTO_HH = val;
                            } else {
                                i.COSTO_HH = 0;
                            }

                            break;
                        case "tiempoInvertirDirecto":
                            val =
                                value === "" || value === undefined
                                    ? 0
                                    : parseFloat(value);
                            if (val <= 100 && val >= 0) {
                                i.TIEMPO_INVERTIR = val;
                            } else if (val < 0) {
                                i.TIEMPO_INVERTIR = 0;
                            }
                            //i.TIEMPO_INVERTIR = parseFloat(value);
                            //Calcular
                            break;
                        case "semanasDirecto":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.SEMANAS = val;
                            } else {
                                i.SEMANAS = 0;
                            }
                            //Calcular
                            break;
                        case "horasDirecto":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.HORAS = val;
                            } else {
                                i.HORAS = 0;
                            }
                            break;
                        case "tarifaHHDirecto":
                            val = value === "" ? 0 : parseFloat(value);
                            //console.log(val)
                            if (val >= 0) {
                                i.TARIFA_HH = val;
                            } else {
                                i.TARIFA_HH = 0;
                            }
                            //Calcular
                            break;
                        case "totalIngresosDirecto":
                            i.TOTAL_INGRESOS = parseFloat(value);
                            break;
                        case "totalCostoDirecto":
                            i.TOTAL_COSTO = parseFloat(value);
                            //Calcular
                            break;
                        case "margenDirecto":
                            i.MARGEN = parseFloat(value);
                            break;
                    }
                    let horasSemana = horasDia === undefined ? 0 : horasDia * 5;
                    //console.log(horasSemana)

                    i.HORAS = parseFloat(
                        (
                            i.SEMANAS *
                            horasSemana *
                            (i.TIEMPO_INVERTIR / 100)
                        ).toFixed(2)
                    );
                    i.TOTAL_INGRESOS = parseFloat(
                        (i.TARIFA_HH * i.HORAS).toFixed(2)
                    );
                    i.TOTAL_COSTO = parseFloat(
                        (i.COSTO_HH * i.HORAS).toFixed(2)
                    );
                    i.MARGEN =
                        i.TOTAL_INGRESOS != 0
                            ? parseFloat(
                                  (
                                      ((i.TOTAL_INGRESOS - i.TOTAL_COSTO) /
                                          i.TOTAL_INGRESOS) *
                                      100
                                  ).toFixed(2)
                              )
                            : 0;
                    if (i.COSTO_HH === 0) {
                        i.MARGEN = 0;
                    }
                }
            });

            //Total Actividad
            calcularTotalActividad(data, indexA);

            setIngreso([...data]);
            callbackIngresos(data);
        }
    };

    const calcularTotalActividad = (
        ingresos: IngresoData[],
        actividadID: number
    ) => {
        if (ingresos != undefined) {
            let ingresosAct = ingresos.filter((i) => {
                return i.ID_ACTIVIDAD === actividadID;
            });
            let actividadesCopy = actividades;
            let total_ingresos = 0;
            let total_costo = 0;
            if (actividadesCopy != undefined && actividadID != -2) {
                //let act = actividadesCopy.find(a => a.actividadID === actividadID);
                actividadesCopy.forEach((a) => {
                    if (a.ID_ACTIVIDAD === actividadID) {
                        a.COSTO_HH = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.COSTO_HH, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_INGRESOS = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.TOTAL_INGRESOS, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_COSTO = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.TOTAL_COSTO, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_HORAS = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.HORAS, 0)
                                .toFixed(2)
                        );
                        a.TARIFA_HH = parseFloat(
                            ingresosAct
                                .reduce((a, b) => a + b.TARIFA_HH, 0)
                                .toFixed(2)
                        );
                        a.TOTAL_RENTABILIDAD =
                            a.TOTAL_INGRESOS != 0
                                ? parseFloat(
                                      (
                                          ((a.TOTAL_INGRESOS - a.TOTAL_COSTO) /
                                              a.TOTAL_INGRESOS) *
                                          100
                                      ).toFixed(2)
                                  )
                                : 0;
                    }
                });

                // console.log(actividadesCopy);

                //actualizar totales
                total_ingresos = actividadesCopy.reduce(
                    (a, b) => a + b.TOTAL_INGRESOS,
                    0
                );
                total_costo = actividadesCopy.reduce(
                    (a, b) => a + b.TOTAL_COSTO,
                    0
                );

                setCostoHH(actividadesCopy.reduce((a, b) => a + b.COSTO_HH, 0));
                setTotalIngresos(total_ingresos);
                setTotalCostos(total_costo);
                setHoras(
                    actividadesCopy.reduce((a, b) => a + b.TOTAL_HORAS, 0)
                );
                setTarifaHH(
                    actividadesCopy.reduce((a, b) => a + b.TARIFA_HH, 0)
                );
                setTotalRentabilidad(
                    total_ingresos != 0
                        ? parseFloat(
                              (
                                  ((total_ingresos - total_costo) /
                                      total_ingresos) *
                                  100
                              ).toFixed(2)
                          )
                        : 0
                );

                setActividades([...actividadesCopy]);
            }
        }
    };

    useEffect(() => {
        let data = ingresos;
        data!.map((a: any) => {
            //console.log(a);
            calcularTotalActividad(data!, a.ID_ACTIVIDAD);
        });
    }, []);

    document.onclick = () => {
        let data = ingresos;
    };

    const _onChangeRol = (index: number, item: any, a: Ingreso): void => {
        let data = ingresos;
        let key = item.key;
        console.log(data);
        if (data !== undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    if (roles !== undefined) {
                        roles.map((a: any) => {
                            if (a.key === key) {
                                i.TARIFA_HH = a.tarifa;
                                i.COSTO_HH = a.costo;
                                i.ID_CARGO = key;
                            }
                        });
                    }
                }
            });
            setIngreso([...data]);
        }
    };

    //   console.log(listadoOpciones);
    const FilasIngresos = (a: Ingreso) => {
        return (
            <div>
                {ingresos != undefined
                    ? ingresos.map((i: IngresoData, indexI: number) => {
                          //   console.log("---INGRESOS--- " + i.ID_CARGO);
                          //   console.log(listadoOpciones);
                          return (
                              <div>
                                  {i.ID_ACTIVIDAD === a.ID_ACTIVIDAD ? (
                                      <div>
                                          <div key={"act" + indexI}>
                                              <div
                                                  className="ms-Grid-row bloqueActividad"
                                                  id="bloqueActividad"
                                              >
                                                  <div
                                                      className="ms-Grid-col ms-sm12 ms-md3 ms-lg3 txtSmall"
                                                      id="txtsim"
                                                  >
                                                      <ComboBox
                                                          label="Rol"
                                                          placeholder="Consultor"
                                                          id={
                                                              "consultorDirecto"
                                                          }
                                                          selectedKey={
                                                              i.ID_CARGO
                                                          }
                                                          onChange={(
                                                              e,
                                                              selectedOption
                                                          ) => {
                                                              _onChangeRol(
                                                                  indexI,
                                                                  selectedOption,
                                                                  a
                                                              );
                                                          }}
                                                          options={
                                                              listadoOpciones
                                                          }
                                                          allowFreeform={false}
                                                          autoComplete="on"
                                                      />
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>
                                                              Tiempo invertir(%)
                                                          </label>
                                                          <NumberFormat
                                                              id={
                                                                  "tiempoInvertirDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "tiempoInvertirDirecto"
                                                                  );
                                                              }}
                                                              defaultValue={
                                                                  i.TIEMPO_INVERTIR <=
                                                                  0
                                                                      ? ""
                                                                      : i.TIEMPO_INVERTIR >
                                                                        100
                                                                      ? "100"
                                                                      : i.TIEMPO_INVERTIR.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              value={
                                                                  i.TIEMPO_INVERTIR <=
                                                                  0
                                                                      ? ""
                                                                      : i.TIEMPO_INVERTIR >
                                                                        100
                                                                      ? "100"
                                                                      : i.TIEMPO_INVERTIR.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              disabled={false}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "%..."
                                                              }
                                                          />
                                                      </span>
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>Semanas</label>
                                                          <NumberFormat
                                                              id={
                                                                  "semanasDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "semanasDirecto"
                                                                  );
                                                              }}
                                                              defaultValue={
                                                                  i.SEMANAS <= 0
                                                                      ? ""
                                                                      : i.SEMANAS.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              value={
                                                                  i.SEMANAS ===
                                                                      0 ||
                                                                  i.SEMANAS <= 0
                                                                      ? ""
                                                                      : i.SEMANAS.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              disabled={false}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "..."
                                                              }
                                                          />
                                                      </span>
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>Horas</label>
                                                          <NumberFormat
                                                              id={
                                                                  "horasDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "horasDirecto"
                                                                  );
                                                              }}
                                                              value={
                                                                  i.HORAS === 0
                                                                      ? ""
                                                                      : i.HORAS.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              defaultValue={
                                                                  i.HORAS <= 0
                                                                      ? ""
                                                                      : i.HORAS.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "..."
                                                              }
                                                          />
                                                      </span>
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>
                                                              Tarifa x HH
                                                          </label>
                                                          <NumberFormat
                                                              id={
                                                                  "tarifaHHDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "tarifaHHDirecto"
                                                                  );
                                                              }}
                                                              defaultValue={
                                                                  i.TARIFA_HH <=
                                                                  0
                                                                      ? ""
                                                                      : i.TARIFA_HH.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              value={
                                                                  i.TARIFA_HH <=
                                                                  0
                                                                      ? ""
                                                                      : i.TARIFA_HH.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              disabled={false}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "..."
                                                              }
                                                          />
                                                      </span>
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>
                                                              Costo x HH
                                                          </label>
                                                          <NumberFormat
                                                              id={
                                                                  "costoHHDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "costoHHDirecto"
                                                                  );
                                                              }}
                                                              defaultValue={
                                                                  i.COSTO_HH <=
                                                                  0
                                                                      ? ""
                                                                      : i.COSTO_HH.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              value={
                                                                  i.COSTO_HH <=
                                                                  0
                                                                      ? ""
                                                                      : i.COSTO_HH.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        )
                                                              }
                                                              disabled={false}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "..."
                                                              }
                                                          />
                                                      </span>
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>
                                                              Total ingresos
                                                          </label>
                                                          <NumberFormat
                                                              id={
                                                                  "totalIngresosDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "totalIngresosDirecto"
                                                                  );
                                                              }}
                                                              value={
                                                                  i.TOTAL_INGRESOS ===
                                                                  0
                                                                      ? ""
                                                                      : new Intl.NumberFormat(
                                                                            "de-DE"
                                                                        ).format(
                                                                            i.TOTAL_INGRESOS
                                                                        )
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "..."
                                                              }
                                                          />
                                                      </span>
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>
                                                              Total costo
                                                          </label>
                                                          <NumberFormat
                                                              id={
                                                                  "totalCostoDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "totalCostoDirecto"
                                                                  );
                                                              }}
                                                              value={
                                                                  i.TOTAL_COSTO ===
                                                                  0
                                                                      ? ""
                                                                      : new Intl.NumberFormat(
                                                                            "de-DE"
                                                                        ).format(
                                                                            i.TOTAL_COSTO
                                                                        )
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "..."
                                                              }
                                                          />
                                                      </span>
                                                  </div>
                                                  <div className="ms-Grid-col ms-sm4 ms-md1 ms-lg1 txtSmall">
                                                      <span
                                                          className={"formateo"}
                                                      >
                                                          <label>
                                                              Rentabilidad (%)
                                                          </label>
                                                          <NumberFormat
                                                              id={
                                                                  "margenDirecto"
                                                              }
                                                              onValueChange={(
                                                                  e
                                                              ) => {
                                                                  onValueChanged(
                                                                      indexI,
                                                                      e,
                                                                      i.ID_ACTIVIDAD,
                                                                      "margenDirecto"
                                                                  );
                                                              }}
                                                              value={
                                                                  i.MARGEN === 0
                                                                      ? ""
                                                                      : i.MARGEN.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        ) + "%"
                                                              }
                                                              defaultValue={
                                                                  i.MARGEN === 0
                                                                      ? ""
                                                                      : i.MARGEN.toString().replace(
                                                                            ".",
                                                                            ","
                                                                        ) + "%"
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                  "."
                                                              }
                                                              decimalSeparator={
                                                                  ","
                                                              }
                                                              placeholder={
                                                                  "%..."
                                                              }
                                                              //format={"###%"}
                                                          />
                                                      </span>
                                                  </div>
                                                  <div
                                                      className="ms-Grid-col ms-sm2 ms-md1 ms-lg1 mt-4"
                                                      id="divEliminarRol"
                                                  >
                                                      <button
                                                          id="btnAgregarRol"
                                                          onClick={(e) => {
                                                              deleteRol(indexI);
                                                              setId_actividad(
                                                                  i.ID_ACTIVIDAD
                                                              );
                                                          }}
                                                      >
                                                          <i
                                                              className="ms-Icon ms-Icon--Delete"
                                                              aria-hidden="true"
                                                              id="btnEliminarRol"
                                                          ></i>
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  ) : (
                                      ""
                                  )}
                              </div>
                          );
                      })
                    : ""}

                <div
                    className="ms-Grid-row bloqueActividad"
                    id="bloqueActividad"
                >
                    <div
                        className="ms-Grid-col ms-sm2 ms-md2 ms-lg2"
                        id="divAgregarActividad"
                    >
                        <button
                            id="btnAgregarRol"
                            onClick={(e) => {
                                addRol(a.ID_ACTIVIDAD);
                            }}
                        >
                            <i
                                className="ms-Icon ms-Icon--AddTo"
                                aria-hidden="true"
                                id="btnAgregarRol"
                            >
                                <span className="txtAgregar">
                                    &nbsp; Agregar rol
                                </span>
                            </i>
                        </button>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"></div>
                    <div
                        className="ms-Grid-col ms-sm4 ms-md4 ms-lg5"
                        id="divTotal"
                    >
                        Total
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 pb-1">
                        <TextField
                            id={"totalActividadDirectoHoras"}
                            disabled={true}
                            placeholder={"..."}
                            value={
                                a.TOTAL_HORAS != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TOTAL_HORAS
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoTarifaHH"}
                            disabled={true}
                            placeholder={"..."}
                            value={
                                a.TARIFA_HH != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TARIFA_HH
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoCostoHH"}
                            disabled={true}
                            placeholder={"..."}
                            value={
                                a.COSTO_HH != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.COSTO_HH
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoTotalFees"}
                            disabled={true}
                            placeholder={"..."}
                            value={
                                a.TOTAL_INGRESOS != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TOTAL_INGRESOS
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoTotalCosto"}
                            disabled={true}
                            placeholder={"..."}
                            value={
                                a.TOTAL_COSTO != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TOTAL_COSTO
                                      )
                                    : ""
                            }
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalActividadDirectoRentabilidad"}
                            disabled={true}
                            placeholder={"..."}
                            value={
                                a.TOTAL_RENTABILIDAD != undefined
                                    ? new Intl.NumberFormat("de-DE").format(
                                          a.TOTAL_RENTABILIDAD
                                      ) + "%"
                                    : ""
                            }
                        />
                    </div>
                </div>
            </div>
        );
    };

    const Resumen = () => {
        return (
            <div className="ms-Grid-row divTotalBloque" id="divTotalBloque">
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg5" id="divTotal">
                    Total ingresos y costos directos
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoHoras"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            horas != undefined
                                ? new Intl.NumberFormat("de-DE").format(horas)
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoTarifaHH"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            tarfiaHH != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      tarfiaHH
                                  )
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoCostoHH"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            costoHH != undefined
                                ? new Intl.NumberFormat("de-DE").format(costoHH)
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoTotalFees"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            totalIngresos != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      totalIngresos
                                  )
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoTotalCosto"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            totalCostos != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      totalCostos
                                  )
                                : ""
                        }
                    />
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                    <TextField
                        id={"totalBloqueDirectoRentabilidad"}
                        disabled={true}
                        placeholder={"..."}
                        value={
                            totalRentabilidad != undefined
                                ? new Intl.NumberFormat("de-DE").format(
                                      totalRentabilidad
                                  ) + "%"
                                : ""
                        }
                    />
                </div>
            </div>
        );
    };

    const Actividades = () => {
        return actividades != undefined
            ? actividades.map((a: Ingreso, indexA: number) => {
                  //console.log(a);
                  return (
                      <div>
                          <div>
                              <div className="acordeonActividad">
                                  <Accordion
                                      title={a.NOMBRE}
                                      defaultCollapsed={false}
                                      inicio={true}
                                      expandAll={false}
                                      className="fondoTitAcordionActividad"
                                      key={1}
                                      classFondo="fondoSeccionActividad"
                                  >
                                      <div className="itemContent">
                                          <div
                                              className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                              id="divBloque"
                                          >
                                              <button
                                                  id="btnAgregarRol"
                                                  className="cierre"
                                                  onClick={(e) => {
                                                      deleteActividad(
                                                          a.ID_ACTIVIDAD,
                                                          indexA
                                                      );
                                                      setId_actividad(
                                                          a.ID_ACTIVIDAD
                                                      );
                                                  }}
                                              >
                                                  <i
                                                      className="ms-Icon ms-Icon--Cancel"
                                                      aria-hidden="true"
                                                      id="btnEliminarActividad"
                                                  ></i>
                                              </button>
                                          </div>
                                      </div>
                                      {FilasIngresos(a)}
                                  </Accordion>
                              </div>
                          </div>
                      </div>
                  );
              })
            : "";
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    1
                    <PrimaryButton
                        onClick={() => {
                            indexIngreso > -1
                                ? deleteRolIndex()
                                : deleteActividadRoles();
                        }}
                        text="Sí, Eliminar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    <div className="itemContent">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm8 ms-md4 ms-lg4 mt-2 mb-2">
                                <TextField
                                    placeholder="Nombre Actividad"
                                    id={"nombreActividadDirecto"}
                                    value={nombreAcividad}
                                    onChange={(e: any) => {
                                        setNombreActividad(e.target.value);
                                    }}
                                />
                            </div>
                            <div
                                className="ms-Grid-col ms-sm3 ms-md4 ms-lg3 mt-2 mb-2"
                                id="divAgregarActividad"
                            >
                                <button
                                    id="btnAgregarActividad"
                                    className="btnAgregarActividad"
                                    onClick={(e) => {
                                        addActividad();
                                    }}
                                >
                                    <i
                                        className="ms-Icon ms-Icon--AddTo"
                                        aria-hidden="true"
                                        id="btnAgregarActividad"
                                    >
                                        &nbsp;
                                        <span className="txtAgregar">
                                            Agregar Actividad
                                        </span>
                                    </i>
                                </button>
                            </div>
                            <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 d-flex justify-content-end mt-3">
                                <label>
                                    Los valores reflejados están en la moneda
                                    del Contrato Marco: <span>{moneda}</span>
                                </label>
                            </div>
                        </div>
                        {Actividades()}
                        {dialog()}
                        {Resumen()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default IngresosDirectos;
