import history from "../../history";
import React, { useState, useEffect } from "react";
import "./Rendicion.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { DefaultButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib-commonjs/DatePicker";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { TextField, ITextFieldProps } from "office-ui-fabric-react/lib/TextField";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import { ComboBox } from "office-ui-fabric-react";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { fetchEngine } from "../../fetchData";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { GastoRendicion, Rendicion } from "../../controles/entidades/Rendicion";

interface Props {
    dialogoEliminar: boolean;
    dialogoEliminarConfirmardo: boolean;
    dialogoEliminarError: boolean;
    dialogoEnviar: boolean;
    dialogoGuardar: boolean;
    dialogoValidar: boolean;
    dialogoConfirmaGuardar: boolean;
    rendicion: Rendicion;
    gasto: GastoRendicion;
    totalAdjuntos: number;
    esAdministrativo: boolean;
    callbackDialogos: Function;
    dialogoEliminarGasto: boolean;
    dialogoEliminarConfirmarGasto: boolean;
    dialogoEliminarErrorGasto: boolean;
    requiereMesAnioUso: boolean;
}

const DialogosRendiciones = ({
    dialogoEliminar,
    dialogoEliminarConfirmardo,
    dialogoEliminarError,
    dialogoEnviar,
    dialogoGuardar,
    dialogoValidar,
    dialogoConfirmaGuardar,
    rendicion,
    gasto,
    totalAdjuntos,
    esAdministrativo,
    callbackDialogos,
    dialogoEliminarGasto,
    dialogoEliminarConfirmarGasto,
    dialogoEliminarErrorGasto,
    requiereMesAnioUso,
}: Props) => {
    return (
        <React.Fragment>
            <Dialog
                hidden={dialogoEliminar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Eliminar Rendición",
                    subText: "¿Está seguro que desea eliminar la rendición?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={(e) => {
                            callbackDialogos("eliminar");
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoEliminarConfirmardo}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Rendición Eliminada",
                    subText: "Rendición eliminada con éxito",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            callbackDialogos("renEliminada");
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoEliminarError}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Error al eliminar Rendición",
                    subText: "Error al eliminar Rendición. Por favor contactarse con un administrador",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            callbackDialogos("errorEliminar");
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoEnviar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Guardado con éxito!",
                    subText: "La rendición ha sido enviada con éxito",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            callbackDialogos("enviar");
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoGuardar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Guardado con éxito!",
                    subText: "La rendición ha sido guardada con éxito",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            callbackDialogos("guardar");
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoValidar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Campos incompletos",
                    subText: "Por favor complete todos los campos requeridos antes de guardar",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <div className="ms-Grid" dir="ltr">
                    {(rendicion.ID_TIPO_RENDICION === undefined || rendicion.ID_TIPO_RENDICION === null || rendicion.ID_TIPO_RENDICION <= 0) && (
                        <div className="ms-Grid-row">
                            <span>Falta seleccionar tipo rendición.</span>
                        </div>
                    )}
                    {rendicion.ID_TIPO_RENDICION === 3 &&
                        (rendicion.MONTO_FONDO === undefined || rendicion.MONTO_FONDO === null || rendicion.MONTO_FONDO <= 0) && (
                            <div className="ms-Grid-row">
                                <span>Falta el monto del fondo.</span>
                            </div>
                        )}
                    {(rendicion.ID_MONEDA === undefined || rendicion.ID_MONEDA === null || rendicion.ID_MONEDA <= 0) && (
                        <div className="ms-Grid-row">
                            <span>Falta seleccionar moneda rendición.</span>
                        </div>
                    )}
                    {(rendicion.FECHA_RENDICION === undefined || rendicion.FECHA_RENDICION === null) && (
                        <div className="ms-Grid-row">
                            <span>Falta seleccionar la fecha de rendición.</span>
                        </div>
                    )}
                    {rendicion.ID > 0 &&
                        (gasto.ID_PROYECTO === undefined || gasto.ID_PROYECTO === null || gasto.ID_PROYECTO <= 0) &&
                        !esAdministrativo && (
                            <div className="ms-Grid-row">
                                <span>Falta seleccionar proyecto.</span>
                            </div>
                        )}
                    {rendicion.ID > 0 && (rendicion.ID_USUARIO === undefined || rendicion.ID_USUARIO === null || rendicion.ID_USUARIO <= 0) && (
                        <div className="ms-Grid-row">
                            <span>Falta seleccionar el usuario.</span>
                        </div>
                    )}
                    {rendicion.ID > 0 &&
                        (gasto.ID_CATEGORIA_RENDICION === undefined ||
                            gasto.ID_CATEGORIA_RENDICION === null ||
                            gasto.ID_CATEGORIA_RENDICION <= 0) && (
                            <div className="ms-Grid-row">
                                <span>Falta seleccionar la categoria de la rendición.</span>
                            </div>
                        )}
                    {rendicion.ID > 0 && (gasto.MONTO === undefined || gasto.MONTO === null || gasto.MONTO <= 0) && (
                        <div className="ms-Grid-row">
                            <span>Se debe ingresar un monto mayor a 0 </span>
                        </div>
                    )}
                    {rendicion.ID > 0 && (gasto.N_BOLETA_FACTURA === undefined || gasto.N_BOLETA_FACTURA === null || gasto.N_BOLETA_FACTURA === "") && (
                        <div className="ms-Grid-row">
                            <span>Se debe ingresar un N° de boleta.</span>
                        </div>
                    )}
                    {rendicion.ID > 0 && (gasto.FECHA_GASTO === undefined || gasto.FECHA_GASTO === null) && (
                        <div className="ms-Grid-row">
                            <span>Falta seleccionar la fecha de gasto </span>
                        </div>
                    )}
                    {rendicion.ID > 0 && (gasto.PROVEEDOR === undefined || gasto.PROVEEDOR === null || gasto.PROVEEDOR === "") && (
                        <div className="ms-Grid-row">
                            <span>Falta ingresar proveedor.</span>
                        </div>
                    )}
                    {rendicion.ID > 0 && (totalAdjuntos === undefined || totalAdjuntos === null || totalAdjuntos === 0) && (
                        <div className="ms-Grid-row">
                            <span>Falta adjuntar un archivo.</span>
                        </div>
                    )}
                    {rendicion.ID > 0 && requiereMesAnioUso && (gasto.MES_ANIO_USO === undefined || gasto.MES_ANIO_USO === null) && (
                        <div className="ms-Grid-row">
                            <span>Falta seleccionar la fecha de mes/año uso.</span>
                        </div>
                    )}
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoEliminarGasto}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Eliminar Gasto",
                    subText: "¿Está seguro que desea eliminar el gasto?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={(e) => {
                            callbackDialogos("eliminarGasto");
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoEliminarConfirmarGasto}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Gasto Eliminado",
                    subText: "Gasto eliminado con éxito",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            callbackDialogos("gastoEliminado");
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoEliminarErrorGasto}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Error al eliminar el Gasto",
                    subText: "Error al eliminar el Gasto. Por favor contactarse con un administrador",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Cerrar"
                        onClick={() => {
                            callbackDialogos("errorEliminarGasto");
                        }}></PrimaryButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoConfirmaGuardar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Tiene un gasto en curso.",
                    subText: "¿Desea descartar y continuar?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={(e) => {
                            callbackDialogos("confirmaGuardarSi");
                        }}
                    />
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            callbackDialogos("confirmaGuardarNo");
                        }}
                    />

                </DialogFooter>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogosRendiciones;
