import { useHistory } from "react-router-dom";
import "../../custom.css";
import GrillaDatosGestionProyecto from "./../GrillaDatos/GrillaDatosGestionProyecto";

import React, { useState, useEffect } from "react";
import "../Home/Home.css";
import { Glyphicon } from "react-bootstrap";
import { authProvider } from "../../authProvider";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import Spinner from "../../controles/Spinner/Spinner";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { fetchEngine } from "../../fetchData";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

const HomeGestionProyecto = () => {
    let userName: any;
    if (authProvider.getAccountInfo() != null) {
        userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
    }

    const [data, setData] = useState([]);
    const [comboData, setComboData] = useState([] as any);
    const [pages, setPages] = useState(0);
    const [elementID, setElementId] = useState(0);

    const [contrato, setContrato] = useState(0);

    const [emailUsuarioActual, setEmailUsuarioActual] = useState<string>(userName.account.userName);
    const [nombre, setNombre] = useState("0");
    const [fecha, setFecha] = useState("12-12-2012");
    const [id, setId] = useState(0);
    const [unidad, setUnidad] = useState(0);
    const [estado, setEstado] = useState(0);
    const [responsable, setResponsable] = useState("0");
    ////
    const [cliente, setCliente] = useState(0);
    ////
    const [dialogoCerrar, setDialogoCerrar] = useState(true);
    const [dialogoCerrarProyecto, setDialogoCerrarProyecto] = useState(true);
    const [dialogoCerrarConfirmacion, setDialogoCerrarConfirmacion] = useState(true);
    const [idProyecto, setIdProyecto] = useState(0);

    const history = useHistory();
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const [isAdmin, setEsAdmin] = useState(false);
    const [isGestion, setEsGestion] = useState(false);
    const [isUsuario, setEsUsuario] = useState(false);
    const [dialogoActualizandoProyectos, setDialogoActualizandoProyectos] = useState(true);
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: "Advertencia",
        subText: "¿Está seguro que quiere copiar este proyecto?",
    };
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [tokenAPI, setTokenAPI] = useState<string | null>("");
    const [tokenAPIGraph, setTokenAPIGraph] = useState<string | null>("");
    const [mostrarTextoCerrarProyecto, setMostrarTextoCerrarProyecto] = useState(false);
    const [desabilitarCerrarMes, setDesabilitarCerrarMes] = useState(false);
    const [infoInicialCargada, setInfoInicialCargada] = useState<boolean>(false);

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                setTokenAPI(d.tokenAPI);
                setTokenAPIGraph(d.tokenAPIGraph);
            });
        }
        if (tokenAPI !== "" && !infoInicialCargada) {
            setInfoInicialCargada(true);
            verificarAdmin();
            verificarGestion();
            verificarUsuario();
            verificarValidador();

            let fechaCerrarProyectosLS = localStorage.getItem("fechaCerrarProyectos");
            if (fechaCerrarProyectosLS !== null) {
                let fechaActual = new Date();
                let fechaCerrarProyectos = new Date(fechaCerrarProyectosLS);
                if (fechaActual < fechaCerrarProyectos) {
                    setDesabilitarCerrarMes(true);
                }
            }
        }
        if (tokenAPI != "" && infoInicialCargada) {
            obtenerProyectos(1);
        }
    }, [nombre, cliente, fecha, unidad, estado, id, responsable, isAdmin, isUsuario, isGestion, esValidador, tokenAPI, tokenAPIGraph]);

    const verificarAdmin = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsAdmin(result.ok);
            });
    };

    const verificarUsuario = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsUsuario(result.ok);
            });
    };

    const verificarGestion = async () => {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsGestion(result.ok);
            });
    };

    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }

    const cambioPage = (page: number) => {
        ////console.log(page);
    };

    const columnas = [
        {
            titulo: "ID",
            interno: "ID",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "id",
        },
        {
            titulo: "Nombre",
            interno: "NOMBRE",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text",
        },
        {
            titulo: "Cliente",
            interno: "CLIENTE_DISPLAY",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Unidad de negocio",
            interno: "UNIDAD_DISPLAY",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Fecha apertura",
            interno: "FECHA_CIERRE",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "date",
        },
        {
            titulo: "Estado",
            interno: "ESTADO_DISPLAY",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Responsable",
            interno: "RESPONSABLE_PROYECTO_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "autocomplete",
            placeholder: "Nombre de Responsable",
        },
    ];

    const acciones = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver proyecto",
            title: "Ver proyecto",
        },
        {
            id: "plan",
            icon: "PlannerLogo",
            tooltip: "Ver Plan",
            title: "Ver Plan",
        },
        {
            id: "cerrar",
            icon: "SkypeCircleCheck",
            tooltip: "Cerrar mes",
            title: "Cerrar mes",
        },
    ];

    const accion = (accion: string, ID: number) => {
        switch (accion) {
            case "reload":
                obtenerProyectos(1);
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/gestionproyecto/${ID}`);
                break;
            case "plan":
                window.location.href = `/planificacionproyecto/${ID}`;
                break;
            case "cerrar":
                setIdProyecto(ID);
                setDialogoCerrarProyecto(false);
                break;
            case "paging":
                obtenerProyectos(ID);
                break;
            default:
                break;
        }
    };

    const cerrarMesProyecto = async (id_proyecto: number) => {
        let fecha_actual = new Date();

        fetchEngine
            .putAPI(`/api/planificacion/CierreMesHome/${id_proyecto}`, fecha_actual, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result) {
                    setDialogoActualizandoProyectos(true);
                    setDialogoCerrarConfirmacion(false);
                }
            });
    };

    const cerrarMesProyectosTodos = async () => {
        let fecha_actual = new Date();

        fetchEngine
            .postAPI(`/api/planificacion/CerrarMesProyectos`, fecha_actual, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result) {
                    setDialogoActualizandoProyectos(true);
                    setDialogoCerrarConfirmacion(false);
                }
            });
    };
    const actualizarTotalForecastProyectos = async () => {
        fetchEngine
            .postAPI(`/api/planificacion/ActualizarTotalForecastProyectos`, "", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                console.log(result);
            });
    };

    const obtenerProyectos = async (page: number) => {
        fetchEngine
            .getAPI(
                `/api/gestionproyecto/GetByMeProyectosHome/${emailUsuarioActual}/${
                    nombre != "" ? nombre : "0"
                }/${cliente}/${fecha}/${unidad}/${estado}/${id}/${responsable != "" ? responsable : "0"}/${page}`,
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenAPI}`,
                }
            )
            .then((result: any) => {
                setData(result.data.listado);
                setComboData([
                    {
                        id: "ESTADO_DISPLAY",
                        value: result.data.estados,
                    },
                    {
                        id: "UNIDAD_DISPLAY",
                        value: result.data.unidades,
                    },
                    {
                        id: "CLIENTE_DISPLAY",
                        value: result.data.clientes,
                    },
                ]);

                setPages(result.contador);
            });
    };

    const callbackFilter = (id: string, value: string, value2: any) => {
        switch (id) {
            case "ID":
                setId(parseInt(value));
                break;
            case "NOMBRE":
                setNombre(value != "" ? value : "0");
                break;
            case "CLIENTE_DISPLAY":
                setCliente(parseInt(value));
                break;
            case "FECHA_CIERRE":
                if (value2 != undefined) {
                    value2 = value2.getFullYear() + "-" + (value2.getMonth() + 1) + "-" + value2.getDate();
                }
                setFecha(value2);
                break;
            case "UNIDAD_DISPLAY":
                setUnidad(parseInt(value));
                break;
            case "ESTADO_DISPLAY":
                setEstado(parseInt(value));
                break;
            case "RESPONSABLE_PROYECTO_DISPLAY":
                setResponsable(value2[0].mail);
                break;
        }
    };

    const dialogCerrarProyectos = () => {
        return (
            <Dialog
                hidden={dialogoCerrar}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "¿Está seguro de cerrar meses de todos los Proyectos?",
                    subText: "Se actualizarán todos los valores pronosticados por valores reales.",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Confirmar"
                        onClick={() => {
                            let fechaActual = new Date();
                            fechaActual.setMinutes(fechaActual.getMinutes() + 30);
                            let fechaFormateada = fechaActual.toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                            });
                            localStorage.setItem("fechaCerrarProyectos", fechaFormateada);
                            cerrarMesProyectosTodos();
                            actualizarTotalForecastProyectos();
                            setDialogoActualizandoProyectos(false);
                            setDesabilitarCerrarMes(true);
                            setDialogoCerrar(true);
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            setDialogoCerrar(true);
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    };

    const dialogCerrarMesProyecto = () => {
        return (
            <Dialog
                hidden={dialogoCerrarProyecto}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "¿Está seguro de cerrar mes?",
                    subText: "Se actualizarán los valores pronosticados por valores reales.",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Confirmar"
                        onClick={() => {
                            cerrarMesProyecto(idProyecto);
                            setDialogoActualizandoProyectos(false);
                            setMostrarTextoCerrarProyecto(true);
                            setDialogoCerrarProyecto(true);
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            setDialogoCerrarProyecto(true);
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    };

    const dialogActualizandoTotalesProyectos = () => {
        return (
            <Dialog
                hidden={dialogoActualizandoProyectos}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Actualizando Proyectos",
                    subText: mostrarTextoCerrarProyecto
                        ? "Favor espere..."
                        : "Los valores totales demoraran 30 minutos aprox. en visualizarse en la BD. Favor espere y no cierre la pagina...",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <div className="spinner">
                    <Spinner mnj="" />
                </div>
            </Dialog>
        );
    };
    const dialogCerrarProyectosConfirmacion = () => {
        return (
            <Dialog
                hidden={dialogoCerrarConfirmacion}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "La operación se ha realizado correctamente",
                    subText: "",
                }}
                modalProps={{
                    isBlocking: true,
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <DefaultButton
                        text="Cerrar"
                        onClick={() => {
                            setDialogoCerrarConfirmacion(true);
                            setMostrarTextoCerrarProyecto(false);
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
        );
    };

    if (isAdmin || isGestion || esValidador) {
        return (
            <React.Fragment>
                <BotonAyuda token={tokenAPI} />
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm6 ms-md5 ms-lg5">
                            <div className="iconoRuta">
                                <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                &nbsp;
                                <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Home Gestión de Proyectos</span>
                            </div>
                            <div className="titulosimulador">
                                <Glyphicon glyph="ok" /> Módulo Gestión de Proyectos
                            </div>
                        </div>
                        <div className="ms-Grid-col ms-sm6 ms-md7 ms-lg7 d-flex justify-content-end">
                            <div className="fijado">
                                <PrimaryButton
                                    text="Cerrar Mes Proyectos"
                                    onClick={() => {
                                        setDialogoCerrar(false);
                                    }}
                                    disabled={desabilitarCerrarMes}
                                />
                            </div>
                        </div>
                    </div>

                    {data ? (
                        <GrillaDatosGestionProyecto
                            columnas={columnas}
                            listado={data}
                            callbackPages={cambioPage}
                            callbackAcciones={accion}
                            pages={pages}
                            titulo={""}
                            acciones={acciones}
                            callbackFilter={callbackFilter}
                            comboData={comboData}
                            tokenAPIGraph={tokenAPIGraph}
                        />
                    ) : (
                        "No hay datos cargados"
                    )}

                    {dialogCerrarProyectos()}
                    {dialogCerrarProyectosConfirmacion()}
                    {dialogActualizandoTotalesProyectos()}
                    {dialogCerrarMesProyecto()}
                </div>
            </React.Fragment>
        );
    } else if (isUsuario && !isAdmin && !isGestion && !esValidador) {
        return (
            <React.Fragment>
                <div className="mt-4 card sinAcceso text-primary">
                    <div className="card-header">Atención</div>
                    <div className="card-body">
                        <h6>
                            <Glyphicon glyph="info-sign" className="mr-1" /> Usted no posee los permisos para visualizar este formulario, si desea
                            obtener los permisos, contactese con el administrador del sistema.
                        </h6>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div className="mt-4 card sinAcceso text-primary"></div>
            </React.Fragment>
        );
    }
};

export default HomeGestionProyecto;
