import React, { useState, useEffect } from "react";
import "react-autosuggest";
import { authProvider } from "../../authProvider";
import axios from "axios";
import "./grillaDatos.css";
import {
    classNamesFunction,
    ComboBox,
    DatePicker,
    DayOfWeek,
    IComboBoxOption,
    IconButton,
    IDatePickerStrings,
    mergeStyleSets,
    TextField,
} from "@fluentui/react";
import AutoComplete from "../../controles/AutoComplete/AutoComplete";
import { fetchEngine } from "../../fetchData";

interface Props {
    listado: any[];
    comboData: any[];
    columnas: any[];
    titulo: string;
    style?: string;
    pages: number;
    callbackPages: any;
    callbackAcciones: any;
    callbackFilter: any;
    acciones: any[];
    setDataCarga: any;
    tokenAPI: string | null;
}

interface Valores {
    id: string;
    value: any;
}

interface Filtros {
    estado: number;
    concepto: number;
    id: string;
    ot: string;
    hh: string;
    nombre: string;
    fecha: Date;
    usuario: string;
    responsable: string;
}

const GrillaCargaHoras = ({
    listado,
    columnas,
    titulo,
    style,
    pages,
    acciones,
    callbackAcciones,
    callbackFilter,
    comboData,
    setDataCarga,
    tokenAPI,
}: Props) => {
    const [activeKey, setActiveKey] = useState(0);
    const [selectedFilter, setFilter] = useState([] as Valores[]);
    const [filtros, setFiltros] = useState({} as Filtros);
    const [names, setNames] = useState([] as any);
    const [currentPage, setcurrentPage] = useState(1);
    const [upperPageBound, setupperPageBound] = useState(5);
    const [lowerPageBound, setlowerPageBound] = useState(0);
    const [isPrevBtnActive, setisPrevBtnActive] = useState("disabled");
    const [isNextBtnActive, setisNextBtnActive] = useState("");
    const [pageBound, setpageBound] = useState(5);
    const [value, setValue] = useState(0);

    const setPrevAndNextBtnClass = (listid: number) => {
        let totalPage: number = pages;
        setisNextBtnActive("disabled");
        setisPrevBtnActive("disabled");
        if (totalPage === listid && totalPage > 1) {
            setisNextBtnActive("disabled");
            setisPrevBtnActive("");
        } else if (listid === 1 && totalPage > 1) {
            setisNextBtnActive("");
        } else if (totalPage > 1) {
            setisNextBtnActive("");
            setisPrevBtnActive("");
        }
        if (listid === 1) {
            setisPrevBtnActive("disabled");
        }
    };

    const btnIncrementClick = () => {
        setupperPageBound(upperPageBound + pageBound);
        setlowerPageBound(lowerPageBound + pageBound);
        let listid: number = upperPageBound + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const btnDecrementClick = () => {
        setupperPageBound(upperPageBound - pageBound);
        setlowerPageBound(lowerPageBound - pageBound);
        let listid = upperPageBound - pageBound;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
    };

    const btnPrevClick = () => {
        if ((currentPage! - 1) % pageBound === 0) {
            setupperPageBound(upperPageBound - pageBound);
            setlowerPageBound(lowerPageBound - pageBound);
        }
        let listid = currentPage! - 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);

        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const btnNextClick = () => {
        if (currentPage! + 1 > upperPageBound) {
            setupperPageBound(upperPageBound + pageBound);
            setlowerPageBound(lowerPageBound + pageBound);
        }
        let listid = currentPage! + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const handleClick = (event: any) => {
        let listid = Number(event.target.id);
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const paginacion = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pages; i++) {
            pageNumbers.push(i);
        }

        const renderPageNumbers = pageNumbers.map((number: any) => {
            if (number === 1 && currentPage === 1) {
                return (
                    <li key={number} id={number} onClick={handleClick} className={"active" + " " + "page" + " " + number}>
                        <a id={number} onClick={handleClick}>
                            {number}
                        </a>
                    </li>
                );
            } else if (number < upperPageBound + 1 && number > lowerPageBound) {
                return (
                    <li
                        className={currentPage === number ? "active" : "" + " " + "page" + " " + number}
                        id={number}
                        onClick={handleClick}
                        key={number}>
                        <a id={number} onClick={handleClick}>
                            {number}
                        </a>
                    </li>
                );
            }
        });

        let pageIncrementBtn = null;
        if (pageNumbers.length > upperPageBound) {
            pageIncrementBtn = (
                <li onClick={btnIncrementClick} className={"pageitem"}>
                    <a onClick={btnIncrementClick}> &hellip; </a>
                </li>
            );
        }
        let pageDecrementBtn = null;
        if (lowerPageBound >= 1) {
            pageDecrementBtn = (
                <li onClick={btnDecrementClick} className={"pageitem"}>
                    <a onClick={btnDecrementClick}> &hellip; </a>
                </li>
            );
        }
        let renderPrevBtn = null;
        if (isPrevBtnActive === "disabled") {
            renderPrevBtn = (
                <li className={isPrevBtnActive + " " + "page"}>
                    <span id="btnPrev"></span>Ant.{" "}
                </li>
            );
        } else {
            renderPrevBtn = (
                <li onClick={btnPrevClick} className={isPrevBtnActive + " " + "page"}>
                    <a id="btnPrev" onClick={btnPrevClick}>
                        Ant.
                    </a>
                </li>
            );
        }
        let renderNextBtn = null;
        if (isNextBtnActive === "disabled") {
            renderNextBtn = (
                <li className={isNextBtnActive + " " + "page"}>
                    <span id="btnNext"> Sig.</span>
                </li>
            );
        } else {
            renderNextBtn = (
                <li onClick={btnNextClick} className={isNextBtnActive + " " + "page"}>
                    <a id="btnNext" onClick={btnNextClick}>
                        Sig.
                    </a>
                </li>
            );
        }

        return (
            <div style={{ display: "flex" }}>
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
                &nbsp;
            </div>
        );
    };

    const eventAcciones = (id: string, registro: number) => {
        if (id === "paging") {
            setActiveKey(registro);
        }
        callbackAcciones(id, registro);
    };

    const limpiarFiltros = () => {
        callbackFilter("ID", "0", []);
        callbackFilter("ESTADO", "0", []);
        callbackFilter("FECHA_CARGA", "", new Date("12/12/2012"));
        callbackFilter("NOMBRE_PROYECTO", "0");
        callbackFilter("OT", "0", []);
        callbackFilter("HH", "0", []);
        callbackFilter("NOMBRE_USUARIO", "", [
            {
                mail: "",
                name: "",
            },
        ]);
        callbackFilter("CREADO_POR_LOGIN", "", [
            {
                mail: "",
                name: "",
            },
        ]);

        filtros.id = "";
        filtros.nombre = "";
        filtros.ot = "";
        filtros.hh = "";
        filtros.responsable = "";
        filtros.usuario = "";
        setFilter([]);

        return true;
    };

    const callbackAutoComplete = (id: string, value: string) => {
        let sf = selectedFilter;

        let exists =
            sf.filter((f) => {
                return f.id === id;
            }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = value;
                }
            });
        } else {
            sf.push({
                id: id,
                value: value,
            });
        }

        setFilter([...sf]);

        callbackFilter(id, "", value);
    };

    const DayPickerStrings: IDatePickerStrings = {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        shortDays: ["D", "L", "M", "M", "J", "V", "S"],
        goToToday: "Ir a hoy",
        prevMonthAriaLabel: "Ir al mes previo",
        nextMonthAriaLabel: "Ir al mes siguiente",
        prevYearAriaLabel: "Ir al año anterior",
        nextYearAriaLabel: "Ir al año siguiente",
        closeButtonAriaLabel: "Cerrar el selector de fechas",
    };

    const _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    const _onSelectDate = (date: Date | null | undefined, id: string): void => {
        let sf = selectedFilter;

        let exists =
            sf.filter((f) => {
                return f.id === id;
            }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = date;
                }
            });
        } else {
            sf.push({
                id: id,
                value: date,
            });
        }

        setFilter([...sf]);

        callbackFilter(id, "", date);
    };

    const onValueChanged = (ev: any, id: string) => {
        let value = ev.target.value;
        filtros.nombre = value;
        callbackFilter(id, value, []);
    };

    const onValueChangedId = (ev: any, id: string) => {
        let value = ev.target.value;
        filtros.id = value;
        callbackFilter(id, value, []);
    };

    const onValueChangedOt = (ev: any, ot: string) => {
        let value = ev.target.value;
        filtros.ot = value;
        callbackFilter(ot, value, []);
    };
    const onValueChangedHH = (ev: any, hh: string) => {
        let value = ev.target.value;
        filtros.hh = value;
        callbackFilter(hh, value, []);
    };

    const onValueChangedNombreUsuario = (ev: any, id: string) => {
        let value = ev.target.value;
        filtros.usuario = value;
        callbackFilter(id, value, []);
    };

    const onChange = (e: any, item: any, id: string) => {
        let sf = selectedFilter;

        let exists =
            sf.filter((f) => {
                return f.id === id;
            }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = item.key;
                }
            });
        } else {
            sf.push({
                id: id,
                value: item.key,
            });
            filtros.estado = item.key;
        }

        setFilter([...sf]);

        callbackFilter(id, item.key, []);
    };

    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 15px 0",
            maxWidth: "300px",
        },
    });

    const tipoFiltro = (tipo: string, id: string) => {
        let control = undefined;

        switch (tipo) {
            case "id":
                control = (
                    <TextField
                        className="id"
                        type={"number"}
                        onChange={(e) => {
                            onValueChangedId(e, id);
                        }}
                        id="id"
                        value={filtros.id}
                    />
                );
                break;
            case "ot":
                control = (
                    <TextField
                        className="id"
                        type={"number"}
                        onChange={(e) => {
                            onValueChangedOt(e, id);
                        }}
                        id="ot"
                        value={filtros.ot}
                    />
                );
                break;
            case "hh":
                control = (
                    <TextField
                        className="id"
                        type={"number"}
                        onChange={(e) => {
                            onValueChangedHH(e, id);
                        }}
                        id="hh"
                        value={filtros.hh}
                    />
                );
                break;
            case "text":
                control = (
                    <TextField
                        onChange={(e) => {
                            onValueChanged(e, id);
                        }}
                        id="nombre"
                        value={filtros.nombre}
                    />
                );
                break;
            case "ComboBox":
                control = (
                    <ComboBox
                        autoComplete="on"
                        allowFreeform={true}
                        placeholder="Seleccionar..."
                        id={id}
                        options={names}
                        onChange={(ev) => onValueChangedNombreUsuario(ev, id)}
                    />
                );
                break;
            case "dateCarga":
                control = (
                    <DatePicker
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        placeholder="Fecha de carga"
                        value={
                            selectedFilter.filter((f) => {
                                return f.id === id;
                            }).length > 0
                                ? selectedFilter.filter((f) => {
                                      return f.id === id;
                                  })[0].value
                                : null
                        }
                        ariaLabel="Seleccione una fecha"
                        formatDate={_onFormatDate}
                        disabled={false}
                        onSelectDate={(e: Date | null | undefined) => {
                            _onSelectDate(e, id);
                        }}
                    />
                );
                id = "FECHA_CARGA";
                break;
            case "combo1":
                var items = [];
                if (comboData.length > 0) {
                    items = comboData
                        .find((f) => {
                            return f.id === id;
                        })
                        .value.filter((f: any) => {
                            return f.TITULO != null;
                        })
                        .map((m: any) => {
                            return { key: m.ID === null ? -1 : m.ID, text: m.TITULO === null ? "(vacío)" : m.TITULO };
                        });
                }
                items.unshift({ key: 0, text: "Seleccione..." });

                control = (
                    <ComboBox
                        key={"cmb_ESTADO"}
                        onChange={(e, item) => {
                            onChange(e, item, id);
                        }}
                        autoComplete="off"
                        selectedKey={
                            selectedFilter.filter((f) => {
                                return f.id === id;
                            }).length > 0
                                ? selectedFilter.filter((f) => {
                                      return f.id === id;
                                  })[0].value
                                : 0
                        }
                        options={items}
                        id={id}
                        defaultSelectedKey={filtros.estado}
                    />
                );
                break;
        }

        return <div>{control}</div>;
    };

    useEffect(() => {
        const usuario = async () => {
            fetchEngine
                .getAPI("/api/DatosFormulario", {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${tokenAPI}`,
                })
                .then((result: any) => {
                    const user: any[] = [];

                    result.data.consultores.map((a: any) => {
                        user.push({
                            id: a.ID,
                            text: a.CONSULTOR_DISPLAY /*  + ' - (' + a.CONSULTOR_LOGIN + ')' */,
                            correo: a.CONSULTOR_LOGIN,
                            nombre: a.CONSULTOR_DISPLAY,
                        });
                    });
                    console.log("Grilla: ", user);
                    setNames(user);
                })
                .catch((e) => {
                    console.log(e);
                });
        };
        usuario();
    }, []);

    return (
        <React.Fragment>
            <div>
                <div>
                    <h4 className="titGrilla">{titulo}</h4>
                    <div className="accionesGrilla">
                        <span className="botonRecargar">
                            <IconButton
                                iconProps={{
                                    iconName: "SyncStatusSolid",
                                }}
                                title="Recargar"
                                ariaLabel=""
                                onClick={() => {
                                    eventAcciones("reload", 0);
                                }}
                            />
                        </span>
                        <span className="botonLimpiar">
                            <IconButton
                                iconProps={{
                                    iconName: "ClearFilter",
                                }}
                                title="Limpiar filtros"
                                ariaLabel=""
                                onClick={() => {
                                    limpiarFiltros();
                                }}
                            />
                        </span>
                    </div>

                    <div className="table-responsive">
                        <table key="tabla_datos" className="grilla table table-hover table-sm table-striped">
                            <thead>
                                <tr key={"tr_datos"}>
                                    {columnas.map((c, key) => {
                                        return (
                                            <th key={c.interno}>
                                                {c.titulo}
                                                {c.filtrable ? <span>{tipoFiltro(c.filterType, c.interno)}</span> : ""}
                                            </th>
                                        );
                                    })}
                                    {acciones.length > 0 ? <th>Acciones</th> : ""}
                                </tr>
                            </thead>
                            <tbody>
                                {listado.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            {columnas.map((c, key) => {
                                                return (
                                                    <td key={"d_" + key}>
                                                        {c.filterType === "dateCarga" ? new Date(l[c.interno]).toLocaleDateString() : l[c.interno]}
                                                    </td>
                                                );
                                            })}
                                            {
                                                <td>
                                                    {acciones.map((a, key) => {
                                                        return (
                                                            <IconButton
                                                                iconProps={{ iconName: a.icon }}
                                                                title={a.title}
                                                                ariaLabel=""
                                                                onClick={() => {
                                                                    eventAcciones(a.id, l["ID"]);
                                                                }}
                                                            />
                                                        );
                                                    })}
                                                </td>
                                            }
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="paginar">{pages > 1 ? paginacion() : ""}</div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default GrillaCargaHoras;
