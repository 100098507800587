export interface IFilters {
    id: string;
    numGasto: string;
    monto: string;
    ot: string;
    nombreProyecto: string;
    conceptoRendicion: number;
    fechaRendicion: Date | undefined;
    fechaGasto: Date | undefined;
    creador: string;
    tipoRendicion: number;
    mesAnioUso: Date | undefined;
    proveedor: string;
    numBoleta: string;
    usuarioAproboRechazo: string;
    estadoRendicion: number;
}

export interface IFiltersString {
    id: string;
    numGasto: string;
    monto: string;
    ot: string;
    nombreProyecto: string;
    conceptoRendicion: string;
    fechaRendicion: string;
    fechaGasto: string;
    creador: string;
    tipoRendicion: string;
    mesAnioUso: string;
    proveedor: string;
    numBoleta: string;
    usuarioAproboRechazo: string;
    estadoRendicion: string;
    fechaDesde: string;
    fechaHasta: string;
    seccionRendicion: number;
    pagina: number;
    asignado: string;
}

export const defaultFilters: IFiltersString = {
    id: "0",
    numGasto: "0",
    monto: "0",
    ot: "0",
    nombreProyecto: "0",
    conceptoRendicion: "0",
    fechaRendicion: "0",
    fechaGasto: "0",
    creador: "0",
    tipoRendicion: "0",
    mesAnioUso: "0",
    proveedor: "0",
    numBoleta: "0",
    usuarioAproboRechazo: "0",
    estadoRendicion: "0",
    fechaDesde: "0",
    fechaHasta: "0",
    seccionRendicion: 1,
    pagina: 1,
    asignado: "0"
};
