import * as React from 'react';
import './Accordion.module.css';
import { IAccordionProps, IAccordionState } from './index';
import { css } from "@uifabric/utilities/lib/css";
import { DefaultButton, IIconProps } from 'office-ui-fabric-react';
//import { DefaultButton } from 'office-ui-fabric-react';

const collapsedIcon: IIconProps = { iconName: 'ChevronRight', className: "accordionChevron" };
const expandedIcon: IIconProps = { iconName: 'ChevronDown', className: "accordionChevron" };

export class Accordion extends React.Component<IAccordionProps, IAccordionState> {
  //private _drawerDiv: HTMLDivElement = undefined;
  constructor(props: IAccordionProps) {
    super(props);

    this.state = {
      expanded: props.defaultCollapsed,
      expandedAll: false,
      inicio: true
    };

    this.setExpand = this.setExpand.bind(this);
    this.reloco = this.reloco.bind(this);
  }
  componentWillReceiveProps(props:any) {

    if (this.state.individual === "ex") {
      if (this.state.expanded === props.expandAll) {
        this.setState({ individual: "" });
      }
    } else {
      this.setState({ expanded: props.defaultCollapsed });
    }
  }

  public reloco() {
    this.setState({
      individual:"ex"
    })
  }


  public setExpand(expand:any) {
    this.setState({ expanded: expand });
  }


  public render(): React.ReactElement<IAccordionProps> {
    return (
      <div className={css("accordion", this.props.className)}>
        <DefaultButton
          className={this.props.className}
          toggle
          checked={this.state.expanded}
          text={this.props.title}
          iconProps={this.state.expanded ? expandedIcon : collapsedIcon}
          onClick={() => {
            this.setState({
              expanded: !this.state.expanded,
              individual: !this.state.expanded ? "ex" : ""
            });
            this.reloco();
          }}
          aria-expanded={this.state.expanded}
          //aria-controls={this._drawerDiv && this._drawerDiv.id}
        />
       {this.state.expanded &&
          //<div className={this.props.classFondo} ref={(el) => { this._drawerDiv = el; }}>
          <div className={this.props.classFondo}>
            {this.props.children}
          </div>
        }   
      </div>
    );
  }
}
