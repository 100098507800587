import React, { useState } from "react";
import "./BotonAyuda.css"
import { PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { fetchEngine } from "../../fetchData";
import { Glyphicon } from "react-bootstrap";
interface Props {
    token: any
}



const BotonAyuda = ({ token }: Props) => {
    const [url, setUrl] = useState("0");
    const [isUrlLoad, setIsUrlLoad] = React.useState(false);
    const ClickBotonAyuda = () => {
        if (!isUrlLoad) {
            fetchEngine
            .getAPI(`api/enlaces/obtenerUrl/boton_ayuda`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            })
            .then((resultado: any) => {
                if (resultado.ok && resultado.data) {
                    setUrl(resultado.data.enlaces[0].ENLACE);
                }
                window.open(resultado.data.enlaces[0].ENLACE, '_blank')
                setIsUrlLoad(true);
            })
            .catch((err) => {
                console.log('err');
                console.log(err);
            });
        } else {
            window.open(url, '_blank')
        }
        
    }

    return <div className="botonayuda">
        <PrimaryButton onClick={ClickBotonAyuda}> <Glyphicon glyph="glyphicon glyphicon-exclamation-sign" style={{ marginRight: "3px" }} /> Ayuda</PrimaryButton>

    </div>
}

export default BotonAyuda;