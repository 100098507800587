import React from "react";
import "./Rendicion.css";
import { DefaultButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import Spinner from "reactstrap/lib/Spinner";

interface Props {
    dialogoAprobar: boolean;
    dialogoRechazar: boolean;
    dialogoMotivoRechazo: boolean;
    motivoRechazo: string;
    callbackDialogos: Function;
    textOnChange: Function;
    dialogoContabilizar: boolean;
    dialogoValidarGasto: boolean;
    isLoading?: boolean;
    estadoGasto: number;
}

const DialogosEstadoRendiciones = ({
    dialogoAprobar,
    dialogoRechazar,
    dialogoMotivoRechazo,
    motivoRechazo,
    callbackDialogos,
    textOnChange,
    dialogoContabilizar,
    dialogoValidarGasto,
    isLoading,
    estadoGasto,
}: Props) => {
    return (
        <React.Fragment>
            <Dialog
                hidden={dialogoAprobar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Aprobar Gasto",
                    subText: "¿Está seguro que desea aprobar el gasto?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            callbackDialogos("aprobar");
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoRechazar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Rechazar Gasto",
                    subText: "¿Está seguro que desea rechazar el gasto?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            callbackDialogos("siRechazar");
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoMotivoRechazo}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Motivo rechazo",
                    subText: "Ingrese el motivo del rechazo",
                }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <TextField
                            id="motivoRechazo"
                            label="Descripción"
                            value={motivoRechazo}
                            multiline
                            autoAdjustHeight
                            onChange={(e) => {
                                textOnChange(e);
                            }}
                        />
                    </div>
                </div>
                <DialogFooter>
                    <PrimaryButton
                        text="Rechazar"
                        onClick={() => {
                            if (estadoGasto === 17) {
                                callbackDialogos("rechazarGastoAprobador");
                            } else if (estadoGasto === 18) {
                                callbackDialogos("rechazarGastoValidador");
                            }
                        }}></PrimaryButton>
                    <DefaultButton
                        text="Salir"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoContabilizar}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Contabilizar Gasto",
                    subText: "¿Está seguro que desea contabilizar el gasto?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            callbackDialogos("contabilizar");
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={dialogoValidarGasto}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: "Validar Gasto",
                    subText: "¿Está seguro que desea validar el gasto?",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <DialogFooter>
                    <PrimaryButton
                        text="Si"
                        onClick={() => {
                            callbackDialogos("validar");
                        }}></PrimaryButton>
                    <DefaultButton
                        text="No"
                        onClick={() => {
                            callbackDialogos("cerrar");
                        }}></DefaultButton>
                </DialogFooter>
            </Dialog>
            <Dialog
                hidden={isLoading}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: "Generando PDF",
                    subText: "Favor espere...",
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450 } },
                }}>
                <div className="spinner">
                    <Spinner mnj="" />
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default DialogosEstadoRendiciones;
