import React, { useState, useEffect } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { AzureAD, AuthenticationState, IAzureADFunctionProps, MsalAuthProvider, LoginType } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import "../../custom.css";
import { basicReduxStore } from "../../reduxStore";
import Authenticated from "../Authenticated/Authenticated";
import logoVTZ2021 from "../../img/logotipoVantaz.svg";

interface Props {
    provider: MsalAuthProvider;
}

const NavMenu = () => {
    const [isOpen, setToggle] = useState(false);
    const [alto, setAlto] = useState(0);
    const [userOpen, setuserOpen] = useState(false);
    const [isUnauthenticated, setIsUnauthenticated] = useState("");

    const toggle = () => {
        setToggle(!isOpen);
    };

    const toggleUser = () => {
        setuserOpen(!userOpen);
    };

    useEffect(() => {
        if (authProvider.authenticationState === AuthenticationState.Unauthenticated) {
            authProvider.login();
        }
    }, []);

    return (
        <AzureAD provider={authProvider} reduxStore={basicReduxStore}>
            {({ accountInfo, authenticationState, error, login, logout }: IAzureADFunctionProps) => {
                ////console.log(authProvider);
                const isInProgress = authenticationState === AuthenticationState.InProgress;
                const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
                ////console.log(this.props.provider);
                const isUnauthenticated = authenticationState === AuthenticationState.Unauthenticated;

                return (
                    <React.Fragment>
                        <header>
                            <Navbar className="navbar-expand-sm navbar-toggleable-sm border-vantaz box-shadow mb-3" light>
                                <Container fluid>
                                    <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2">
                                        <div className="logoVantaz">
                                            <img src={logoVTZ2021} alt="" className="logoVTZ2021" />
                                        </div>
                                    </div>
                                    <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9">
                                        <NavbarBrand className="SGP">
                                            <span>Sistema Integrado Gestión de Proyectos</span>
                                        </NavbarBrand>
                                        <NavbarToggler id="nav-toggler" onClick={toggle} className="mr-2" />
                                    </div>
                                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 d-flex justify-content-end">
                                        <Collapse id="principal" className={"d-sm-inline-flex flex-sm-row-reverse "} isOpen={isOpen} navbar>
                                            <ul className="navbar-nav flex-grow">
                                                {isAuthenticated ? (
                                                    <Dropdown nav isOpen={userOpen} toggle={toggleUser}>
                                                        <DropdownToggle nav caret>
                                                            <Authenticated provider={authProvider} />
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem header>
                                                                Bienvenido {accountInfo != null ? accountInfo.account.name : ""}!
                                                            </DropdownItem>
                                                            <DropdownItem divider />
                                                            <DropdownItem onClick={logout}>
                                                                <h6>
                                                                    <small>Cerrar sesi&#243;n</small>
                                                                </h6>
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                ) : (
                                                    ""
                                                )}
                                                {!isAuthenticated ? (
                                                    <NavItem>
                                                        <NavLink tag={Link} className="text-dark" to="#" onClick={login}>
                                                            Iniciar sesi&#243;n
                                                        </NavLink>
                                                    </NavItem>
                                                ) : (
                                                    ""
                                                )}
                                            </ul>
                                        </Collapse>
                                    </div>
                                </Container>
                            </Navbar>
                        </header>
                    </React.Fragment>
                );
            }}
        </AzureAD>
    );
};

export default NavMenu;
