import React, { useState } from "react";

import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { Reserva, Proyectos, ProyectosH, SemanasH, TipoProyectosH, Consultores, Porcentajes, Resumen, Vacaciones, Licencias, ArraySemanas } from "../../controles/entidades/StaffingEntity";
import moment from "moment";
import { List } from "react-bootstrap/lib/Media";

interface Props {
    proyectosList?: Proyectos[]; // CONTIENE OPORTUNIDADES
    proyectosList2?: Proyectos[]; // CONTIENE PROYECTOS
    consultoresList?: Consultores[];
    porcentajesList?: Resumen[]; //Porcentajes[];
    porcentajesList2?: Resumen[]; //Porcentajes[];
    resumenList?: Resumen[];
    valorTest: number;
    collapseResumen: boolean;
    callbackEliminarReserva: any;
    proyectos?: ProyectosList;
    vacacionesList?: Vacaciones[];
    licenciasList?: Licencias[];
}

interface ProyectosList {
    ID: number;
    ID_CONSULTOR: number;
    ID_PROYECTO: number;
    ID_OPORTUNIDAD: number;
    ES_RESERVA: boolean;
    NOMBRE_OPORTUNIDAD: string;
    NOMBRE_PROYECTO: string;
    COLAPSABLE: boolean;
}

interface ConsultoresList {
    ID: number;
    ID_CARGO: number;
    ID_TIPO_CONTRATO: number;
    CONSULTOR_LOGIN: string;
    CONSULTOR_DISPLAY: string;
    UNIDAD_NEGOCIO: string;
    CARGO: string;
    TIPO_CONTRATO_CONSULTOR: string;
    FECHA_DESDE: string;
    FECHA_HASTA: string;
    ACTIVO: boolean;
    CANTIDAD_PROYECTOS: number;
    ES_INDEFINIDO: boolean;
    COLAPSABLE: boolean;
}

interface PorcentajesList {
    ID_PROYECTOS_CONSULTOR: number;
    ID_CONSULTOR: number;
    ID_PROYECTO: number;
    ID_OPORTUNIDAD: number;
    SEMANA: number;
    HORAS: number;
    PORCENTAJE: number;
    ID_MES: number;
    ANIO: number;
}

const StaffingProyectos = ({
    proyectosList,
    proyectosList2,
    consultoresList,
    porcentajesList,
    porcentajesList2,
    resumenList,
    callbackEliminarReserva,
    vacacionesList,
    licenciasList,
}: Props) => {
    const [colapsarResumen, setColapsarResumen] = useState(0);
    const [confirmDeleteReserva, setconfirmDeleteReserva] = useState(true);
    const [idEliminarReserva, setIdEliminarReserva] = useState(4);
    const [cambioVariable, setcambioVariable] = useState(false);
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );

    const contadorSemanas = (anio: number, mes: number) => {
        var fecha_actual;
        mes = mes - 1;
        fecha_actual = new Date(anio, 0, 1);

        let total_weeks = getISOWeeks(anio);

        while (fecha_actual.toLocaleDateString("es-ES", { weekday: "long" }) !== "lunes") {
            fecha_actual.setDate(fecha_actual.getDate() + 1);
        }

        let array_fechas = [];
        let contador = 0;

        array_fechas.push(fecha_actual);

        for (let index = 1; index < total_weeks; index++) {
            fecha_actual = moment(moment(fecha_actual).add(7, "d")).toDate();
            array_fechas.push(fecha_actual);
        }

        array_fechas.map((e: any) => {
            if (e.getMonth() === mes) {
                contador = contador + 1;
            }
        });

        return contador;
    };

    const getISOWeeks = (y: number) => {
        var d, isLeap;

        d = new Date(y, 0, 1);
        isLeap = new Date(y, 1, 29).getMonth() === 1;

        //check for a Jan 1 that's a Thursday or a leap year that has a
        //Wednesday jan 1. Otherwise it's 52
        return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52;
    };

    const meses = (mes: any, anio: any) => {
        let arrayMeses = [{}];
        if (mes == 1) {
            arrayMeses = [
                {
                    mes: "Enero",
                    total_semanas: contadorSemanas(anio, 1),
                    semanas: weeksArray(contadorSemanas(anio, 1), anio, 1),
                },
                {
                    mes: "Febrero",
                    total_semanas: contadorSemanas(anio, 2),
                    semanas: weeksArray(contadorSemanas(anio, 2), anio, 2),
                },
                {
                    mes: "Marzo",
                    total_semanas: contadorSemanas(anio, 3),
                    semanas: weeksArray(contadorSemanas(anio, 3), anio, 3),
                },
                {
                    mes: "Abril",
                    total_semanas: contadorSemanas(anio, 4),
                    semanas: weeksArray(contadorSemanas(anio, 4), anio, 4),
                },
            ];
        } else if (mes == 2) {
            arrayMeses = [
                {
                    mes: "Febrero",
                    total_semanas: contadorSemanas(anio, 2),
                    semanas: weeksArray(contadorSemanas(anio, 2), anio, 2),
                },
                {
                    mes: "Marzo",
                    total_semanas: contadorSemanas(anio, 3),
                    semanas: weeksArray(contadorSemanas(anio, 3), anio, 3),
                },
                {
                    mes: "Abril",
                    total_semanas: contadorSemanas(anio, 4),
                    semanas: weeksArray(contadorSemanas(anio, 4), anio, 4),
                },
                {
                    mes: "Mayo",
                    total_semanas: contadorSemanas(anio, 5),
                    semanas: weeksArray(contadorSemanas(anio, 5), anio, 5),
                },
            ];
        } else if (mes == 3) {
            arrayMeses = [
                {
                    mes: "Marzo",
                    total_semanas: contadorSemanas(anio, 3),
                    semanas: weeksArray(contadorSemanas(anio, 3), anio, 3),
                },
                {
                    mes: "Abril",
                    total_semanas: contadorSemanas(anio, 4),
                    semanas: weeksArray(contadorSemanas(anio, 4), anio, 4),
                },
                {
                    mes: "Mayo",
                    total_semanas: contadorSemanas(anio, 5),
                    semanas: weeksArray(contadorSemanas(anio, 5), anio, 5),
                },
                {
                    mes: "Junio",
                    total_semanas: contadorSemanas(anio, 6),
                    semanas: weeksArray(contadorSemanas(anio, 6), anio, 6),
                },
            ];
        } else if (mes == 4) {
            arrayMeses = [
                {
                    mes: "Abril",
                    total_semanas: contadorSemanas(anio, 4),
                    semanas: weeksArray(contadorSemanas(anio, 4), anio, 4),
                },
                {
                    mes: "Mayo",
                    total_semanas: contadorSemanas(anio, 5),
                    semanas: weeksArray(contadorSemanas(anio, 5), anio, 5),
                },
                {
                    mes: "Junio",
                    total_semanas: contadorSemanas(anio, 6),
                    semanas: weeksArray(contadorSemanas(anio, 6), anio, 6),
                },
                {
                    mes: "Julio",
                    total_semanas: contadorSemanas(anio, 7),
                    semanas: weeksArray(contadorSemanas(anio, 7), anio, 7),
                },
            ];
        } else if (mes == 5) {
            arrayMeses = [
                {
                    mes: "Mayo",
                    total_semanas: contadorSemanas(anio, 5),
                    semanas: weeksArray(contadorSemanas(anio, 5), anio, 5),
                },
                {
                    mes: "Junio",
                    total_semanas: contadorSemanas(anio, 6),
                    semanas: weeksArray(contadorSemanas(anio, 6), anio, 6),
                },
                {
                    mes: "Julio",
                    total_semanas: contadorSemanas(anio, 7),
                    semanas: weeksArray(contadorSemanas(anio, 7), anio, 7),
                },
                {
                    mes: "Agosto",
                    total_semanas: contadorSemanas(anio, 8),
                    semanas: weeksArray(contadorSemanas(anio, 8), anio, 8),
                },
            ];
        } else if (mes == 6) {
            arrayMeses = [
                {
                    mes: "Junio",
                    total_semanas: contadorSemanas(anio, 6),
                    semanas: weeksArray(contadorSemanas(anio, 6), anio, 6),
                },
                {
                    mes: "Julio",
                    total_semanas: contadorSemanas(anio, 7),
                    semanas: weeksArray(contadorSemanas(anio, 7), anio, 7),
                },
                {
                    mes: "Agosto",
                    total_semanas: contadorSemanas(anio, 8),
                    semanas: weeksArray(contadorSemanas(anio, 8), anio, 8),
                },
                {
                    mes: "Septiembre",
                    total_semanas: contadorSemanas(anio, 9),
                    semanas: weeksArray(contadorSemanas(anio, 9), anio, 9),
                },
            ];
        } else if (mes == 7) {
            arrayMeses = [
                {
                    mes: "Julio",
                    total_semanas: contadorSemanas(anio, 7),
                    semanas: weeksArray(contadorSemanas(anio, 7), anio, 7),
                },
                {
                    mes: "Agosto",
                    total_semanas: contadorSemanas(anio, 8),
                    semanas: weeksArray(contadorSemanas(anio, 8), anio, 8),
                },
                {
                    mes: "Septiembre",
                    total_semanas: contadorSemanas(anio, 9),
                    semanas: weeksArray(contadorSemanas(anio, 9), anio, 9),
                },
                {
                    mes: "Octubre",
                    total_semanas: contadorSemanas(anio, 10),
                    semanas: weeksArray(contadorSemanas(anio, 10), anio, 10),
                },
            ];
        } else if (mes == 8) {
            arrayMeses = [
                {
                    mes: "Agosto",
                    total_semanas: contadorSemanas(anio, 8),
                    semanas: weeksArray(contadorSemanas(anio, 8), anio, 8),
                },
                {
                    mes: "Septiembre",
                    total_semanas: contadorSemanas(anio, 9),
                    semanas: weeksArray(contadorSemanas(anio, 9), anio, 9),
                },
                {
                    mes: "Octubre",
                    total_semanas: contadorSemanas(anio, 10),
                    semanas: weeksArray(contadorSemanas(anio, 10), anio, 10),
                },
                {
                    mes: "Noviembre",
                    total_semanas: contadorSemanas(anio, 11),
                    semanas: weeksArray(contadorSemanas(anio, 11), anio, 11),
                },
            ];
        } else if (mes == 9) {
            arrayMeses = [
                {
                    mes: "Septiembre",
                    total_semanas: contadorSemanas(anio, 9),
                    semanas: weeksArray(contadorSemanas(anio, 9), anio, 9),
                },
                {
                    mes: "Octubre",
                    total_semanas: contadorSemanas(anio, 10),
                    semanas: weeksArray(contadorSemanas(anio, 10), anio, 10),
                },
                {
                    mes: "Noviembre",
                    total_semanas: contadorSemanas(anio, 11),
                    semanas: weeksArray(contadorSemanas(anio, 11), anio, 11),
                },
                {
                    mes: "Diciembre",
                    total_semanas: contadorSemanas(anio, 12),
                    semanas: weeksArray(contadorSemanas(anio, 12), anio, 12),
                },
            ];
        } else if (mes == 10) {
            arrayMeses = [
                {
                    mes: "Octubre",
                    total_semanas: contadorSemanas(anio, 10),
                    semanas: weeksArray(contadorSemanas(anio, 10), anio, 10),
                },
                {
                    mes: "Noviembre",
                    total_semanas: contadorSemanas(anio, 11),
                    semanas: weeksArray(contadorSemanas(anio, 11), anio, 11),
                },
                {
                    mes: "Diciembre",
                    total_semanas: contadorSemanas(anio, 12),
                    semanas: weeksArray(contadorSemanas(anio, 12), anio, 12),
                },
                {
                    mes: "Enero",
                    total_semanas: contadorSemanas(anio + 1, 1),
                    semanas: weeksArray(contadorSemanas(anio + 1, 1), anio + 1, 1),
                },
            ];
        } else if (mes == 11) {
            arrayMeses = [
                {
                    mes: "Noviembre",
                    total_semanas: contadorSemanas(anio, 11),
                    semanas: weeksArray(contadorSemanas(anio, 11), anio, 11),
                },
                {
                    mes: "Diciembre",
                    total_semanas: contadorSemanas(anio, 12),
                    semanas: weeksArray(contadorSemanas(anio, 12), anio, 12),
                },
                {
                    mes: "Enero",
                    total_semanas: contadorSemanas(anio + 1, 1),
                    semanas: weeksArray(contadorSemanas(anio + 1, 1), anio + 1, 1),
                },
                {
                    mes: "Febrero",
                    total_semanas: contadorSemanas(anio + 1, 2),
                    semanas: weeksArray(contadorSemanas(anio + 1, 2), anio + 1, 2),
                },
            ];
        } else if (mes == 12) {
            arrayMeses = [
                {
                    mes: "Diciembre",
                    total_semanas: contadorSemanas(anio, 12),
                    semanas: weeksArray(contadorSemanas(anio, 12), anio, 12),
                },
                {
                    mes: "Enero",
                    total_semanas: contadorSemanas(anio + 1, 1),
                    semanas: weeksArray(contadorSemanas(anio + 1, 1), anio + 1, 1),
                },
                {
                    mes: "Febrero",
                    total_semanas: contadorSemanas(anio + 1, 2),
                    semanas: weeksArray(contadorSemanas(anio + 1, 2), anio + 1, 2),
                },
                {
                    mes: "Marzo",
                    total_semanas: contadorSemanas(anio + 1, 3),
                    semanas: weeksArray(contadorSemanas(anio + 1, 3), anio + 1, 3),
                },
            ];
        }
        return arrayMeses;
    };

    /**
     * Returns count of weeks for year and month
     *
     * @param {Number} year - full year (YYYY)
     * @param {Number} month_number - month_number is in the range 1..12
     * @returns {number}
     */
    const weeksCount = (year: number, month_number: number) => {
        let firstOfMonth = new Date(year, month_number - 1, 1);
        let day = firstOfMonth.getDay() || 6;
        day = day === 1 ? 0 : day;
        if (day) {
            day--;
        }
        let diff = 7 - day;
        let lastOfMonth = new Date(year, month_number, 0);
        let lastDate = lastOfMonth.getDate();
        if (lastOfMonth.getDay() === 1) {
            diff--;
        }
        let result = Math.ceil((lastDate - diff) / 7);
        return result;
    };

    const weeksArray = (semanas: number, anio: number, mes: number) => {
        let fechaActual = new Date(anio, mes - 1, 1);
        while (fechaActual.toLocaleDateString("es-ES", { weekday: "long" }) !== "lunes") {
            fechaActual.setDate(fechaActual.getDate() + 1);
        }
        let conteo2 = 1;
        let conteo = moment(fechaActual).isoWeek();
        let semanasArray = [];
        while (conteo2 <= semanas) {
            semanasArray.push(conteo);
            conteo = conteo + 1;
            conteo2 = conteo2 + 1;
        }
        return semanasArray;
    };

    const colapsarResumenClick = (e: any) => {
        let key = e.currentTarget.getAttribute("data-index");
        if (proyectosList !== undefined) {
            if (proyectosList2 !== undefined) {
                if (vacacionesList !== undefined) {
                    if (licenciasList !== undefined) {
                        proyectosList.forEach((e: ProyectosList) => {
                            if (e.ID_CONSULTOR === parseInt(key)) {
                                let colapsable = !e.COLAPSABLE;
                                e.COLAPSABLE = colapsable;
                            }
                        });
                        proyectosList2.forEach((e: ProyectosList) => {
                            if (e.ID_CONSULTOR === parseInt(key)) {
                                let colapsable = !e.COLAPSABLE;
                                e.COLAPSABLE = colapsable;
                            }
                        });
                        vacacionesList.forEach((e: Vacaciones) => {
                            if (e.ID_USUARIO === parseInt(key)) {
                                let colapsable = !e.COLAPSABLE;
                                e.COLAPSABLE = colapsable;
                            }
                        });
                        licenciasList.forEach((e: Licencias) => {
                            if (e.ID_USUARIO === parseInt(key)) {
                                let colapsable = !e.COLAPSABLE;
                                e.COLAPSABLE = colapsable;
                            }
                        });
                    }
                }
            }
        }

        proyectosConsultor();
        setcambioVariable(!cambioVariable);
    };

    const verificarSiColocarCerosAntes = (numSemana: number, dias_semanas_copia: any) => {
        let columnasCero: any = [];
        let diasEliminar: any = [];
        dias_semanas_copia.some(function (dia: number) {
            if (numSemana !== dia) {
                diasEliminar.push(dia);
                columnasCero.push("0%");
            } else {
                diasEliminar.map((diaEliminar: number) => {
                    let indexDia = dias_semanas_copia.indexOf(diaEliminar);
                    dias_semanas_copia.splice(indexDia, 1);
                });
                let indexDia = dias_semanas_copia.indexOf(dia);
                dias_semanas_copia.splice(indexDia, 1);
                return columnasCero;
            }
        });

        return columnasCero;
    };

    const proyectosConsultor = () => {
        let newDate = new Date();
        let mes = newDate.getMonth() + 1;
        let anio = newDate.getFullYear();
        let displayMes: any = {};
        let dias_semanas: any = [];
        let total_semanas = 0;
        displayMes = meses(mes, anio);
        displayMes.forEach((ele: any) => {
            total_semanas += ele.total_semanas;
            dias_semanas.push(...ele.semanas);
        });

        const dialogoEliminarReserva = (dialogoBool: boolean, e: any) => {
            setconfirmDeleteReserva(dialogoBool);
            let id = e.currentTarget.getAttribute("data-index");
            setIdEliminarReserva(id);
        };

        let arrayGlobal: ProyectosH[] = [];
        let espectroSemanas = dias_semanas.slice();
        let primeraSemana: number = espectroSemanas[0];
        let ultimaSemana: number = espectroSemanas[espectroSemanas.length - 1];
        let dosanios: boolean = primeraSemana > ultimaSemana;
        let xAnio: number = new Date().getFullYear();
        let inicioIndice: number = 0;
        proyectosList2?.map((xProyecto: ProyectosList, index: number) => {
            let xResumen: Resumen[] | undefined = porcentajesList2?.filter((xPorcentaje: Resumen) => {
                let xSemana: number = xPorcentaje.SEMANA;
                return (
                    xPorcentaje.ID_PROYECTO == xProyecto.ID_PROYECTO 
                    && xPorcentaje.ID_CONSULTOR == xProyecto.ID_CONSULTOR
                    && ( dosanios ? ((xSemana >= primeraSemana && xPorcentaje.ANIO == xAnio) || (xSemana <= ultimaSemana && xPorcentaje.ANIO == (xAnio + 1))) : (xSemana >= primeraSemana && xSemana <= ultimaSemana && xPorcentaje.ANIO == xAnio) )
                )
            });
            let xProyectoH: ProyectosH = {
                ID: inicioIndice + index,
                ID_CONSULTOR: xProyecto.ID_CONSULTOR,
                ID_REGISTRO: xProyecto.ID_PROYECTO,
                TIPO_REGISTRO: TipoProyectosH.OrdenTrabajo,
                NOMBRE: xProyecto.NOMBRE_PROYECTO,
                COLAPSABLE: xProyecto.COLAPSABLE,
                SEMANAS: [],
            };
            xResumen?.forEach((auxR: Resumen) => {
                let xSemanaH: SemanasH = {
                    ID: auxR.ID_PROYECTOS_CONSULTOR,
                    ANIO: auxR.ANIO,
                    MES: auxR.ID_MES,
                    SEMANA: auxR.SEMANA,
                    HORAS: auxR.HORAS,
                    PORCENTAJE: auxR.PORCENTAJE,
                };
                xProyectoH.SEMANAS.push(xSemanaH);
            });
            arrayGlobal.push(xProyectoH);
            return xProyectoH;
        });
        inicioIndice = arrayGlobal.length;
        proyectosList?.map((xOportunidad: ProyectosList, index: number) => {
            let xResumen: Resumen[] | undefined = porcentajesList?.filter((xPorcentaje: Resumen) => {
                let xSemana: number = xPorcentaje.SEMANA;
                return (
                    xPorcentaje.ID_OPORTUNIDAD == xOportunidad.ID_OPORTUNIDAD 
                    && xPorcentaje.ID_CONSULTOR == xOportunidad.ID_CONSULTOR
                    && ( dosanios ? ((xSemana >= primeraSemana && xPorcentaje.ANIO == xAnio) || (xSemana <= ultimaSemana && xPorcentaje.ANIO == (xAnio + 1))) : (xSemana >= primeraSemana && xSemana <= ultimaSemana && xPorcentaje.ANIO == xAnio) )
                )
            });
            let xProyectoH: ProyectosH = {
                ID: inicioIndice + index,
                ID_CONSULTOR: xOportunidad.ID_CONSULTOR,
                ID_REGISTRO: xOportunidad.ID_OPORTUNIDAD,
                TIPO_REGISTRO: TipoProyectosH.Oportunidad,
                NOMBRE: xOportunidad.NOMBRE_OPORTUNIDAD,
                COLAPSABLE: xOportunidad.COLAPSABLE,
                SEMANAS: [],
            };
            xResumen?.forEach((auxR: Resumen) => {
                let xSemanaH: SemanasH = {
                    ID: auxR.ID_PROYECTOS_CONSULTOR,
                    ANIO: auxR.ANIO,
                    MES: auxR.ID_MES,
                    SEMANA: auxR.SEMANA,
                    HORAS: auxR.HORAS,
                    PORCENTAJE: auxR.PORCENTAJE,
                };
                xProyectoH.SEMANAS.push(xSemanaH);
            });
            arrayGlobal.push(xProyectoH);
            return xProyectoH;
        });
        inicioIndice = arrayGlobal.length;
        vacacionesList?.map((xVacacion: Vacaciones, index: number) => {
            let xResumen: ArraySemanas[] | undefined = xVacacion.ARRAY_SEMANAS?.filter((xPorcentaje: ArraySemanas) => {
                let xSemana: number = xPorcentaje.NUMERO_SEMANA;
                return (
                    xPorcentaje.PORCENTAJE > 0
                    && ( dosanios ? xSemana >= primeraSemana || xSemana <= ultimaSemana : xSemana >= primeraSemana && xSemana <= ultimaSemana )
                )
            });
            let xProyectoH: ProyectosH = {
                ID: inicioIndice + index,
                ID_CONSULTOR: xVacacion.ID_USUARIO,
                ID_REGISTRO: xVacacion.ID,
                TIPO_REGISTRO: TipoProyectosH.Vacacion,
                NOMBRE: "Vacaciones",
                COLAPSABLE: xVacacion.COLAPSABLE,
                SEMANAS: [],
            };
            xResumen?.forEach((auxR: ArraySemanas, index: number) => {
                let xSemanaH: SemanasH = {
                    ID: index,
                    ANIO: auxR.NUMERO_SEMANA < primeraSemana ? new Date(xVacacion.FECHA_HASTA).getFullYear() : new Date(xVacacion.FECHA_DESDE).getFullYear(),
                    MES: auxR.MES_STAFFING,
                    SEMANA: auxR.NUMERO_SEMANA,
                    HORAS: 0,
                    PORCENTAJE: auxR.PORCENTAJE,
                };
                xProyectoH.SEMANAS.push(xSemanaH);
            });
            arrayGlobal.push(xProyectoH);
            return xProyectoH;
        });
        inicioIndice = arrayGlobal.length;
        licenciasList?.map((xLicencia: Licencias, index: number) => {
            let xResumen: ArraySemanas[] | undefined = xLicencia.ARRAY_SEMANAS?.filter((xPorcentaje: ArraySemanas) => {
                let xSemana: number = xPorcentaje.NUMERO_SEMANA;
                return (
                    xPorcentaje.PORCENTAJE > 0
                    && ( dosanios ? xSemana >= primeraSemana || xSemana <= ultimaSemana : xSemana >= primeraSemana && xSemana <= ultimaSemana )
                )
            });
            let xProyectoH: ProyectosH = {
                ID: inicioIndice + index,
                ID_CONSULTOR: xLicencia.ID_USUARIO,
                ID_REGISTRO: xLicencia.ID,
                TIPO_REGISTRO: TipoProyectosH.Licencia,
                NOMBRE: "Licencia",
                COLAPSABLE: xLicencia.COLAPSABLE,
                SEMANAS: [],
            };
            xResumen?.forEach((auxR: ArraySemanas, index: number) => {
                let xSemanaH: SemanasH = {
                    ID: index,
                    ANIO: auxR.NUMERO_SEMANA < primeraSemana ? new Date(xLicencia.FECHA_HASTA).getFullYear() : new Date(xLicencia.FECHA_DESDE).getFullYear(),
                    MES: auxR.MES_STAFFING,
                    SEMANA: auxR.NUMERO_SEMANA,
                    HORAS: 0,
                    PORCENTAJE: auxR.PORCENTAJE,
                };
                xProyectoH.SEMANAS.push(xSemanaH);
            });
            arrayGlobal.push(xProyectoH);
            return xProyectoH;
        });

        return (
            <div>
                {consultoresList != undefined
                    ? consultoresList.map((a: ConsultoresList, indexA: number) => {
                            let dias_semanas_resumen = dias_semanas.slice();
                            let claseNombreConsultor = "";
                            a.CANTIDAD_PROYECTOS = arrayGlobal.filter((proyecto: ProyectosH) => 
                                (proyecto.ID_CONSULTOR == a.ID 
                                    && (
                                        ((proyecto.TIPO_REGISTRO == TipoProyectosH.Vacacion || proyecto.TIPO_REGISTRO == TipoProyectosH.Licencia) && proyecto.SEMANAS.length > 0)
                                        || (proyecto.TIPO_REGISTRO !== TipoProyectosH.Vacacion && proyecto.TIPO_REGISTRO !== TipoProyectosH.Licencia)
                                    )
                                )
                            ).length;
                            a.CANTIDAD_PROYECTOS <= 0 ? (claseNombreConsultor = "w-26") : (claseNombreConsultor = "w-25");
                            return (
                                <div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <div className="table-responsive table-hover">
                                                <table className="table table-sm tablaStaffing table-striped">
                                                    <thead>
                                                        <tr className="fondoMes">
                                                            <td colSpan={4}></td>
                                                            {displayMes.map((a: any) => {
                                                                return (
                                                                    <div className="contentMeses">
                                                                        <td colSpan={a.total_semanas} className="text-dark">
                                                                            {a.mes}
                                                                        </td>
                                                                    </div>
                                                                );
                                                            })}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4}></td>
                                                                {displayMes.map((e: any) => {
                                                                    return (
                                                                        <div className="contentSemanas">
                                                                            {e.semanas.map((ele: any) => {
                                                                                return (
                                                                                    <div className="contentSemanas">
                                                                                        <td className="text-primary">{ele}</td>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    );
                                                                })}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan={4} className={claseNombreConsultor}>
                                                                {a.ES_INDEFINIDO === true ? (
                                                                    <label className="nombreConsultor">
                                                                        {a.CONSULTOR_DISPLAY + " - " + a.CARGO + " - " + a.UNIDAD_NEGOCIO}
                                                                        {" (" + a.TIPO_CONTRATO_CONSULTOR + ")"}
                                                                    </label>
                                                                ) : (
                                                                    <label className="nombreConsultor">
                                                                        {a.CONSULTOR_DISPLAY + " - " + a.CARGO + " - " + a.UNIDAD_NEGOCIO}
                                                                        <br />
                                                                        {" (" +
                                                                            a.TIPO_CONTRATO_CONSULTOR +
                                                                            " hasta " +
                                                                            a.FECHA_HASTA.split("T")[0] +
                                                                            ")"}
                                                                    </label>
                                                                )}
                                                            </td>
                                                            <td colSpan={4 + total_semanas}></td>
                                                        </tr>
                                                        {
                                                            a.CANTIDAD_PROYECTOS <= 0 ? (
                                                                <tr
                                                                    className="collapseProyectos"
                                                                    onClick={(e) => colapsarResumenClick(e)}
                                                                    key={a.ID}
                                                                    data-index={a.ID}>
                                                                </tr>
                                                            ) : (
                                                                <tr
                                                                    className="collapseProyectos"
                                                                    onClick={(e) => colapsarResumenClick(e)}
                                                                    key={a.ID}
                                                                    data-index={a.ID}>
                                                                    <th scope="col" className="colOT">
                                                                        OP / OT
                                                                    </th>
                                                                    <th scope="col" className="colProyecto">
                                                                        Oportunidades / Proyectos
                                                                    </th>
                                                                    <th scope="col" className="borrar"></th>
                                                                    <th scope="col" className="colNumOT tdCenter">
                                                                        <span className="badge badge-info badgeIndicador">{a.CANTIDAD_PROYECTOS}</span>
                                                                    </th>
                                                                    {
                                                                        dias_semanas_resumen.map((xSemana: number) => {
                                                                            let xAgendado: number = arrayGlobal
                                                                                .filter((proyecto: ProyectosH) => proyecto.ID_CONSULTOR == a.ID)
                                                                                .map((proyecto: ProyectosH) => {
                                                                                    let xPorcentaje: number = 0;
                                                                                    xPorcentaje = proyecto.SEMANAS
                                                                                        .filter((semana: SemanasH) => semana.SEMANA == xSemana)
                                                                                        .map((semana: SemanasH) => semana.PORCENTAJE)
                                                                                        .reduce((acumulador, valor) => (acumulador + valor), 0);
                                                                                    return xPorcentaje;
                                                                                })
                                                                                .reduce((acumulado, valor) => (acumulado + valor), 0);
                                                                            let xClass: string = 
                                                                                xAgendado > 100 ? "sobreAgendado"
                                                                                : xAgendado === 100 ? "agendado"
                                                                                : xAgendado >= 90 ? "minimoAgendado"
                                                                                : "menorAgendado";
                                                                            return (<th scope="col" className={xClass}>{ xAgendado + "%" }</th>);
                                                                        })
                                                                    }
                                                                </tr>
                                                            )
                                                        }
                                                    </thead>

                                                    <tbody key={a.ID} data-index={a.ID}>
                                                        {
                                                            a.CANTIDAD_PROYECTOS <= 0 ? 
                                                                (
                                                                    <tr>
                                                                        <td className="sinProyecto" scope="row" colSpan={4 + 4 + total_semanas}>
                                                                            <label>SIN PROYECTOS U OPORTUNIDADES ASIGNADAS</label>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            : <>
                                                                {
                                                                    arrayGlobal
                                                                        .filter((proyecto: ProyectosH) => 
                                                                            (proyecto.ID_CONSULTOR == a.ID 
                                                                                && (
                                                                                    ((proyecto.TIPO_REGISTRO == TipoProyectosH.Vacacion || proyecto.TIPO_REGISTRO == TipoProyectosH.Licencia) && proyecto.SEMANAS.length > 0)
                                                                                    || (proyecto.TIPO_REGISTRO !== TipoProyectosH.Vacacion && proyecto.TIPO_REGISTRO !== TipoProyectosH.Licencia)
                                                                                )
                                                                            )
                                                                        )
                                                                        .map((proyecto: ProyectosH) => {
                                                                            return (
                                                                                <tr className="IDENTIFICADOR">
                                                                                    <td scope="row">{
                                                                                        proyecto.TIPO_REGISTRO == TipoProyectosH.OrdenTrabajo ? "OT - "
                                                                                        : proyecto.TIPO_REGISTRO == TipoProyectosH.Oportunidad ? "OP - "
                                                                                        : ""
                                                                                    }{ proyecto.ID_REGISTRO }</td>
                                                                                    <td>{ proyecto.NOMBRE }</td>
                                                                                    <td></td>
                                                                                    <td className="estado tdCenter">
                                                                                        <label>
                                                                                            <div 
                                                                                                id={
                                                                                                    proyecto.TIPO_REGISTRO == TipoProyectosH.OrdenTrabajo ? "squareCeleste"
                                                                                                    : proyecto.TIPO_REGISTRO == TipoProyectosH.Oportunidad ? "squareMorado"
                                                                                                    : "squareBlanco"
                                                                                                }
                                                                                                className={
                                                                                                    proyecto.TIPO_REGISTRO == TipoProyectosH.OrdenTrabajo ? "rounded-circle"
                                                                                                    : proyecto.TIPO_REGISTRO == TipoProyectosH.Oportunidad ? "rounded-circle"
                                                                                                    : "rounded-circle border border-info"
                                                                                                }
                                                                                                ></div>
                                                                                            &nbsp;
                                                                                        </label>
                                                                                    </td>
                                                                                    {
                                                                                        dias_semanas_resumen.map((xSemana: number) => {
                                                                                            let xPorcentaje: number = 0;
                                                                                            xPorcentaje = proyecto.SEMANAS
                                                                                                .filter((semana: SemanasH) => semana.SEMANA == xSemana)
                                                                                                .map((semana: SemanasH) => semana.PORCENTAJE)
                                                                                                .reduce((acumulador, valor) => (acumulador + valor), 0);
                                                                                            return (
                                                                                                <td className="colDias">
                                                                                                    { xPorcentaje.toString() + "%" }
                                                                                                </td>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </tr>
                                                                            )
                                                                        })
                                                                }
                                                            </>
                                                        }
                                                  </tbody>
                                              </table>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : ""}
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    <div className="itemContent">
                        {proyectosConsultor()}
                        {confirmDeleteReserva === false && (
                            <Dialog
                                hidden={confirmDeleteReserva}
                                dialogContentProps={{
                                    type: DialogType.largeHeader,
                                    title: "Eliminar reserva",
                                    subText: "¿Estás seguro que deseas eliminarla?",
                                }}
                                modalProps={{
                                    styles: { main: { textAlign: "center" } },
                                }}>
                                <DialogFooter>
                                    <PrimaryButton
                                        text="Confirmar"
                                        onClick={() => {
                                            callbackEliminarReserva(idEliminarReserva);
                                        }}></PrimaryButton>
                                    <DefaultButton
                                        text="Cancelar"
                                        onClick={() => {
                                            setconfirmDeleteReserva(true);
                                        }}></DefaultButton>
                                </DialogFooter>
                            </Dialog>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default StaffingProyectos;
