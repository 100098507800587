import { faSmileBeam } from "@fortawesome/free-solid-svg-icons";

export class Proyectos {
    ID: number = 0;
    ID_CONSULTOR: number = 0;
    ID_PROYECTO: number = 0;
    ID_OPORTUNIDAD: number = 0;
    ES_RESERVA: boolean = false;
    NOMBRE_OPORTUNIDAD: string = "";
    NOMBRE_PROYECTO: string = "";
    COLAPSABLE: boolean = false;
}

export enum TipoProyectosH { NoDefinido, OrdenTrabajo, Oportunidad, Vacacion, Licencia }

export class ProyectosH {
    ID: number = 0;
    ID_CONSULTOR: number = 0;
    ID_REGISTRO: number = 0;
    TIPO_REGISTRO: TipoProyectosH = TipoProyectosH.NoDefinido;
    NOMBRE: string = "";
    COLAPSABLE: boolean = false;
    SEMANAS: SemanasH[] = [];
}

export class SemanasH {
    ID: number = 0;
    ANIO: number = 0;
    MES: number = 0;
    SEMANA: number = 0;
    HORAS: number= 0;
    PORCENTAJE: number = 0;
}

export class Consultores {
    ID: number = 0;
    ID_CARGO: number = 0;
    ID_TIPO_CONTRATO: number = 0;
    CONSULTOR_LOGIN: string = "";
    CONSULTOR_DISPLAY: string = "";
    UNIDAD_NEGOCIO: string = "";
    CARGO: string = "";
    TIPO_CONTRATO_CONSULTOR: string = "";
    FECHA_DESDE: string = "";
    FECHA_HASTA: string = "";
    ACTIVO: boolean = false;
    CANTIDAD_PROYECTOS: number = 0;
    ES_INDEFINIDO: boolean = false;
    COLAPSABLE: boolean = false;
    HORA_MAX: number = 0;
}

export class Porcentajes {
    ID_CONSULTOR: number = 0;
    SUMA_HORAS: number = 0;
    SUMA_PORCENTAJES: number = 0;
    ID_MES: number = 0; 
    ANIO: number = 0;
    MES_STAFFING: number = 0;
}

export class Resumen {
    ID_PROYECTOS_CONSULTOR: number = 0;
    ID_CONSULTOR: number = 0;
    ID_PROYECTO: number = 0;
    ID_OPORTUNIDAD: number = 0;
    SEMANA: number = 0;
    HORAS: number = 0;
    PORCENTAJE: number = 0;
    ID_MES: number = 0;
    ANIO: number = 0;
    MES_STAFFING: number = 0;
}

/*export class Reserva {
    ID: number = 0;
    ID_CONSULTOR: number = 0;
    ID_PROYECTO: number = 0;
    ID_OPORTUNIDAD: number = 0;
    CONSULTOR_LOGIN: string = "";
    CONSULTOR_DISPLAY: string = "";
    FECHA_DESDE?: Date;
    FECHA_HASTA?: Date;
    //PORCENTAJE_RESERVA: number = 0;
    SEMANA: number = 0;
    MES: number = 0;
    ANIO: number = 0;
    HORA: number= 0;
}*/

export class Reserva {
    ID: number = 0;
    ID_CONSULTOR: number = 0;
    ID_PROYECTO: number = 0;
    ID_OPORTUNIDAD: number = 0;
    CONSULTOR_LOGIN: string = "";
    CONSULTOR_DISPLAY: string = "";
    FECHA_DESDE?: Date;
    FECHA_HASTA?: Date;
    ARRAY_RESERVA?: ArrayReserva[];
}

export class ArrayReserva {
    ID: number = 0;
    ID_MES: number = 0;
    //ID_PROYECTOS_CONSULTOR
    SEMANA: number = 0;
    HORAS: number= 0;
    PORCENTAJE: number = 0;
    ANIO: number = 0;
}

export class Vacaciones {
    ID: number = 0;
    FECHA_DESDE: string = "";
    FECHA_HASTA: string = "";
    ID_USUARIO: number = 0;
    ID_VACACION_BUK: number = 0;
    ID_MES: number = 0;
    MES_STAFFING: number = 0;
    NUMERO_SEMANA: number = 0;
    PORCENTAJE: number = 0;
    ARRAY_SEMANAS?: ArraySemanas[];
    COLAPSABLE: boolean = false;
}

export class ArraySemanas{
    NUMERO_SEMANA: number = 0;
    MES_STAFFING: number = 0;
    PORCENTAJE: number = 0;
}

export class Licencias {
    ID: number = 0;
    FECHA_DESDE: string = "";
    FECHA_HASTA: string = "";
    ID_USUARIO: number = 0;
    ID_LICENCIA_BUK: number = 0;
    ID_MES: number = 0;
    MES_STAFFING: number = 0;
    NUMERO_SEMANA: number = 0;
    PORCENTAJE: number = 0;
    ARRAY_SEMANAS?: ArraySemanas[];
    COLAPSABLE: boolean = false;
}