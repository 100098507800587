import React, { useState, useEffect } from 'react';
import 'react-autosuggest';
import axios from 'axios';
import './grillaDatos.css';
import { classNamesFunction, ComboBox, DatePicker, DayOfWeek, IComboBoxOption, IconButton, IDatePickerStrings, mergeStyleSets, TextField } from '@fluentui/react';
import AutoComplete from '../../controles/AutoComplete/AutoComplete';

interface Props {
    listado: any[];
    comboData2: any[];
    columnas: any[];
    titulo: string;
    style?: string;
    pages2: number;
    callbackPages: any;
    callbackAcciones: any;
    callbackFilterPresupuesto: any;
    acciones: any[];
}

interface Valores {
    id: string;
    value: any;
}

interface Filtros {
    nombresim: string;
    nombreopo: string;
    contrato: number;
}

const GrillaPresupuesto = ({ listado, columnas, titulo, style, pages2, acciones, callbackAcciones, callbackFilterPresupuesto, comboData2 }: Props) => {

    const theme = {
        foo: {
            color: 'red',
            ':hover': {
                color: 'green'
            }
        },
        bar: {
            color: 'blue'
        }
    };

    const [activeKey, setActiveKey] = useState(0);
    const [selectedFilter, setFilter] = useState([] as Valores[]);
    const [filtros, setFiltros] = useState({} as Filtros);

    const [currentPage, setcurrentPage] = useState(1);
    const [upperPageBound, setupperPageBound] = useState(5);
    const [lowerPageBound, setlowerPageBound] = useState(0);
    const [isPrevBtnActive, setisPrevBtnActive] = useState('disabled');
    const [isNextBtnActive, setisNextBtnActive] = useState('');
    const [pageBound, setpageBound] = useState(5);

    const setPrevAndNextBtnClass = (listid: number) => {
        let totalPage: number = pages2;
        setisNextBtnActive('disabled');
        setisPrevBtnActive('disabled');
        if (totalPage === listid && totalPage > 1) {
            setisNextBtnActive('disabled');
            setisPrevBtnActive('');
        }
        else if (listid === 1 && totalPage > 1) {
            setisNextBtnActive('');
        }
        else if (totalPage > 1) {
            setisNextBtnActive('');
            setisPrevBtnActive('');
        }
        if (listid === 1) {
            setisPrevBtnActive('disabled');
        }
    }

    const btnIncrementClick = () => {
        setupperPageBound(upperPageBound + pageBound);
        setlowerPageBound(lowerPageBound + pageBound);
        let listid: number = upperPageBound + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages2 > 1) {
            eventAcciones("paging", listid);
        }
    }

    const btnDecrementClick = () => {
        setupperPageBound(upperPageBound - pageBound);
        setlowerPageBound(lowerPageBound - pageBound);
        let listid = upperPageBound - pageBound;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
    }

    const btnPrevClick = () => {
        if ((currentPage! - 1) % pageBound === 0) {
            setupperPageBound(upperPageBound - pageBound);
            setlowerPageBound(lowerPageBound - pageBound);
        }
        let listid = currentPage! - 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);

        if (pages2 > 1) {
            eventAcciones("paging", listid);
        }
    }

    const btnNextClick = () => {
        if ((currentPage! + 1) > upperPageBound) {
            setupperPageBound(upperPageBound + pageBound);
            setlowerPageBound(lowerPageBound + pageBound);
        }
        let listid = currentPage! + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages2 > 1) {
            eventAcciones("paging", listid);
        }
    }

    const handleClick = (event: any) => {
        let listid = Number(event.target.id);
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages2 > 1) {
            eventAcciones("paging", listid);
        }
    }


    const paginacion = () => {
        const pageNumbers = [];
        //const { currentPage, todosPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;
        //for (let i = 1; i <= Math.ceil(this.state.paginas / todosPerPage); i++) {
        for (let i = 1; i <= pages2; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number: any) => {
            if (number === 1 && currentPage === 1) {
                return (
                    <li key={number} id={number} onClick={handleClick} className={"active" + ' ' + "page" + " " + number} ><a id={number} onClick={handleClick}>{number}</a></li>
                )
            }
            else if ((number < upperPageBound + 1) && number > lowerPageBound) {
                return (
                    <li className={currentPage === number ? "active" : '' + ' ' + "page" + " " + number} id={number} onClick={handleClick} key={number} ><a id={number} onClick={handleClick}>{number}</a></li>
                )
            }
        });

        let pageIncrementBtn = null;
        if (pageNumbers.length > upperPageBound) {
            pageIncrementBtn = <li onClick={btnIncrementClick} className={"pageitem"}><a onClick={btnIncrementClick}> &hellip; </a></li>
        }
        let pageDecrementBtn = null;
        if (lowerPageBound >= 1) {
            pageDecrementBtn = <li onClick={btnDecrementClick} className={"pageitem"}><a onClick={btnDecrementClick}> &hellip; </a></li>
        }
        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className={isPrevBtnActive + ' ' + "page"}><span id="btnPrev"></span>Ant. </li>
        }
        else {
            renderPrevBtn = <li onClick={btnPrevClick} className={isPrevBtnActive + ' ' + "page"}><a id="btnPrev" onClick={btnPrevClick}>Ant.</a></li>
        }
        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled') {
            renderNextBtn = <li className={isNextBtnActive + ' ' + "page"}><span id="btnNext"> Sig.</span></li>
        }
        else {
            renderNextBtn = <li onClick={btnNextClick} className={isNextBtnActive + ' ' + "page"}><a id="btnNext" onClick={btnNextClick}>Sig.</a></li>
        }


        return (
            <div style={{ display: "flex" }}>
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
                &nbsp;
            </div>
        );
    }

    const eventAcciones = (id: string, registro: number) => {
        if (id === "paging") {
            setActiveKey(registro);
        }
        callbackAcciones(id, registro);
    }

    const limpiarFiltros = () => {
        callbackFilterPresupuesto("NOMBRE_PROYECTO", "0");
        callbackFilterPresupuesto("NOMBRE", "0");
        callbackFilterPresupuesto("CONTRATO_MARCO", "0");
        callbackFilterPresupuesto("FECHA_APROBACION", "", new Date("12/12/2012"));

        filtros.nombresim = "";
        filtros.contrato = 0;
        filtros.nombreopo = "";
        setFilter([]);

        return true;
    }

    const DayPickerStrings: IDatePickerStrings = {
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],

        shortMonths: ['En', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],

        days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],

        shortDays: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],

        goToToday: 'Ir a hoy',
        prevMonthAriaLabel: 'Ir al mes previo',
        nextMonthAriaLabel: 'Ir al mes siguiente',
        prevYearAriaLabel: 'Ir al año anterior',
        nextYearAriaLabel: 'Ir al año siguiente',
        closeButtonAriaLabel: 'Cerrar el selector de fechas'
    };

    const _onFormatDate = (date: Date | undefined): string => {
        ////console.log(date);
        if (date != undefined) {
            //date = new Date(date);
            return date.getDate() + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear());
        } else {
            return '01-01-0001';
        }
    };

    const _onSelectDate = (date: Date | null | undefined, id: string): void => {

        let sf = selectedFilter;

        let exists = sf.filter(f => { return f.id === id }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = date;
                }
            });
        } else {
            sf.push({
                id: id,
                value: date
            });
        }

        setFilter([...sf]);

        callbackFilterPresupuesto(id, "", date);
    };

    const onValueChanged = (ev: any, id: string) => {
        let value = ev.target.value;
        ////console.log(id, value);
        filtros.nombresim = value;
        callbackFilterPresupuesto(id, value, []);
    }

    const onValueChanged2 = (ev: any, id: string) => {
        let value = ev.target.value;
        ////console.log(id, value);
        filtros.nombreopo = value;
        callbackFilterPresupuesto(id, value, []);
    }


    const onChange = (e: any, item: any, id: string) => {
        let sf = selectedFilter;

        let exists = sf.filter(f => { return f.id === id }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = item.key;
                }
            });

        } else {
            sf.push({
                id: id,
                value: item.key
            });
            filtros.contrato = item.key;
        }

        setFilter([...sf]);

        callbackFilterPresupuesto(id, item.key);
    }

    const controlClass = mergeStyleSets({
        control: {
            margin: '0 0 15px 0',
            maxWidth: '300px'
        }
    });

    const tipoFiltro = (tipo: string, id: string) => {
        let control = undefined;
        ////console.log("Filtrando");
        ////console.log(tipo);

        switch (tipo) {
            case "text":
                control = <TextField onChange={(e) => { onValueChanged(e, id) }} id="nombre" value={filtros.nombresim} />;
                break;
            case "text2":
                control = <TextField onChange={(e) => { onValueChanged2(e, id) }} id="nombre2" value={filtros.nombreopo} />;
                break;
            case "combo":
                ////console.log(id);
                ////console.log(comboData2);
                var items = [];
                if (comboData2.length > 0) {
                    items = comboData2.find(f => { return f.id === id }).value.filter((f: any) => { return f.TITULO != null }).map((m: any) => {
                        return { key: m.ID === null ? -1 : m.ID, text: m.TITULO === null ? "(vacío)" : m.TITULO };
                    });
                }
                items.unshift({ key: 0, text: "Seleccione..." });
                ////console.log(items);
                control = <ComboBox key={"cmb_CONTRATO_MARCO"} onChange={(e, item) => { onChange(e, item, id) }} autoComplete="off"
                    selectedKey={selectedFilter.filter(f => { return f.id === id }).length > 0 ? selectedFilter.filter(f => { return f.id === id })[0].value : 0}
                    options={items} id={id} defaultSelectedKey={filtros.contrato} />;
                break;
            case "fechaAprobacion":
                control = <DatePicker
                    className={controlClass.control}
                    firstDayOfWeek={DayOfWeek.Monday}
                    strings={DayPickerStrings}
                    allowTextInput={true}
                    placeholder="Fecha de aprobación"
                    value={selectedFilter.filter(f => { return f.id === id }).length > 0 ? selectedFilter.filter(f => { return f.id === id })[0].value : null}
                    ariaLabel="Seleccione una fecha"
                    formatDate={_onFormatDate}
                    disabled={false}
                    onSelectDate={(e: Date | null | undefined) => { _onSelectDate(e, id) }} />
                id = "FECHA_APROBACION";
                break;
        }

        return (
            <div>
                {control}
            </div>
        );
    }

    return (
        <React.Fragment>
            {
                <div>
                    {/* <input type='text' onChange={getUsers} /> */}

                    <div>
                        <h4 className="titGrilla">{titulo}</h4>
                        <div className="accionesGrilla">
                            <span className="botonRecargar"><IconButton iconProps={{
                                iconName: "SyncStatusSolid"
                            }} title="Recargar" ariaLabel=""
                                onClick={() => { eventAcciones("reload", 0) }}
                            /></span>
                            <span className="botonLimpiar"><IconButton iconProps={{
                                iconName: "ClearFilter"
                            }} title="Limpiar filtros" ariaLabel=""
                                onClick={() => { limpiarFiltros() }}
                            /></span>
                        </div>

                        <div className="table-responsive">
                            <table key="tabla_datos" className="grilla table table-hover table-sm table-striped">
                                <thead>
                                    <tr key={"tr_datos"}>
                                        {
                                            columnas.map((c, key) => {
                                                return <th key={c.interno}>
                                                    {c.titulo}
                                                    {c.filtrable ?
                                                        <span>
                                                            {tipoFiltro(c.filterType, c.interno)}
                                                        </span>
                                                        : ""}
                                                </th>
                                            })
                                        }
                                        {
                                            acciones.length > 0 ? <th>Acciones</th> : ""
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        listado.map((l, key) => {
                                            return <tr key={key}>
                                                {
                                                    columnas.map((c, key) => {
                                                        return <td key={"d_" + key}>
                                                            {c.filterType === "fechaAprobacion" ? new Date(l[c.interno]).toLocaleDateString() != "1/1/1" && new Date(l[c.interno]).toLocaleDateString() != "1/1/2001" ? new Date(l[c.interno]).toLocaleDateString() : "" : l[c.interno]}
                                                        </td>
                                                    })
                                                }
                                                {
                                                    <td>
                                                        {
                                                            acciones.map((a, key) => {
                                                                //////console.log(a);
                                                                return (
                                                                    <IconButton
                                                                        iconProps={{ iconName: a.icon }}
                                                                        title={a.title}
                                                                        ariaLabel=""
                                                                        onClick={() => { eventAcciones(a.id, l["ID"]) }}
                                                                    />)
                                                            })
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="paginar">
                            {pages2 > 1 ? paginacion() : ""}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
}

export default GrillaPresupuesto;
