import React, { useState, useEffect } from "react";
import {
    Collapse,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AzureAD, AuthenticationState, IAzureADFunctionProps, MsalAuthProvider, LoginType } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import { basicReduxStore } from "../../reduxStore";
import "react-autosuggest";
import axios from "axios";
import "./grillaDatos.css";
import {
    classNamesFunction,
    ComboBox,
    DatePicker,
    DayOfWeek,
    IComboBoxOption,
    IconButton,
    IDatePickerStrings,
    mergeStyleSets,
    TextField,
} from "@fluentui/react";
import AutoComplete from "../../controles/AutoComplete/AutoComplete";

interface Props {
    listado: any[];
    comboData: any[];
    columnas: any[];
    titulo: string;
    style?: string;
    pages: number;
    callbackPages: any;
    callbackAcciones: any;
    callbackFilter: any;
    acciones: any[];
    tokenAPIGraph: string | null;
}

interface Valores {
    id: string;
    value: any;
}

interface Filtros {
    id: string;
    nombre: string;
    unidad: number;
    fecha: Date;
    estado: number;
    responsable: string;
}

const GrillaDatosGestionProyecto = ({
    listado,
    columnas,
    titulo,
    style,
    pages,
    acciones,
    callbackAcciones,
    callbackFilter,
    comboData,
    tokenAPIGraph,
}: Props) => {
    const theme = {
        foo: {
            color: "red",
            ":hover": {
                color: "green",
            },
        },
        bar: {
            color: "blue",
        },
    };

    const [activeKey, setActiveKey] = useState(0);
    const [selectedFilter, setFilter] = useState([] as Valores[]);
    const [filtros, setFiltros] = useState({} as Filtros);

    const [currentPage, setcurrentPage] = useState(1);
    const [upperPageBound, setupperPageBound] = useState(5);
    const [lowerPageBound, setlowerPageBound] = useState(0);
    const [isPrevBtnActive, setisPrevBtnActive] = useState("disabled");
    const [isNextBtnActive, setisNextBtnActive] = useState("");
    const [pageBound, setpageBound] = useState(5);

    const setPrevAndNextBtnClass = (listid: number) => {
        let totalPage: number = pages;
        setisNextBtnActive("disabled");
        setisPrevBtnActive("disabled");
        if (totalPage === listid && totalPage > 1) {
            setisNextBtnActive("disabled");
            setisPrevBtnActive("");
        } else if (listid === 1 && totalPage > 1) {
            setisNextBtnActive("");
        } else if (totalPage > 1) {
            setisNextBtnActive("");
            setisPrevBtnActive("");
        }
        if (listid === 1) {
            setisPrevBtnActive("disabled");
        }
    };

    const btnIncrementClick = () => {
        setupperPageBound(upperPageBound + pageBound);
        setlowerPageBound(lowerPageBound + pageBound);
        let listid: number = upperPageBound + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const btnDecrementClick = () => {
        setupperPageBound(upperPageBound - pageBound);
        setlowerPageBound(lowerPageBound - pageBound);
        let listid = upperPageBound - pageBound;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
    };

    const btnPrevClick = () => {
        if ((currentPage! - 1) % pageBound === 0) {
            setupperPageBound(upperPageBound - pageBound);
            setlowerPageBound(lowerPageBound - pageBound);
        }
        let listid = currentPage! - 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);

        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const btnNextClick = () => {
        if (currentPage! + 1 > upperPageBound) {
            setupperPageBound(upperPageBound + pageBound);
            setlowerPageBound(lowerPageBound + pageBound);
        }
        let listid = currentPage! + 1;
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const handleClick = (event: any) => {
        let listid = Number(event.target.id);
        setcurrentPage(listid);
        setPrevAndNextBtnClass(listid);
        if (pages > 1) {
            eventAcciones("paging", listid);
        }
    };

    const paginacion = () => {
        const pageNumbers = [];
        for (let i = 1; i <= pages; i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map((number: any) => {
            if (number === 1 && currentPage === 1) {
                return (
                    <li key={number} id={number} onClick={handleClick} className={"active" + " " + "page" + " " + number}>
                        <a id={number} onClick={handleClick}>
                            {number}
                        </a>
                    </li>
                );
            } else if (number < upperPageBound + 1 && number > lowerPageBound) {
                return (
                    <li
                        className={currentPage === number ? "active" : "" + " " + "page" + " " + number}
                        id={number}
                        onClick={handleClick}
                        key={number}>
                        <a id={number} onClick={handleClick}>
                            {number}
                        </a>
                    </li>
                );
            }
        });

        let pageIncrementBtn = null;
        if (pageNumbers.length > upperPageBound) {
            pageIncrementBtn = (
                <li onClick={btnIncrementClick} className={"pageitem"}>
                    <a onClick={btnIncrementClick}> &hellip; </a>
                </li>
            );
        }
        let pageDecrementBtn = null;
        if (lowerPageBound >= 1) {
            pageDecrementBtn = (
                <li onClick={btnDecrementClick} className={"pageitem"}>
                    <a onClick={btnDecrementClick}> &hellip; </a>
                </li>
            );
        }
        let renderPrevBtn = null;
        if (isPrevBtnActive === "disabled") {
            renderPrevBtn = (
                <li className={isPrevBtnActive + " " + "page"}>
                    <span id="btnPrev"></span>Ant.{" "}
                </li>
            );
        } else {
            renderPrevBtn = (
                <li onClick={btnPrevClick} className={isPrevBtnActive + " " + "page"}>
                    <a id="btnPrev" onClick={btnPrevClick}>
                        Ant.
                    </a>
                </li>
            );
        }
        let renderNextBtn = null;
        if (isNextBtnActive === "disabled") {
            renderNextBtn = (
                <li className={isNextBtnActive + " " + "page"}>
                    <span id="btnNext"> Sig.</span>
                </li>
            );
        } else {
            renderNextBtn = (
                <li onClick={btnNextClick} className={isNextBtnActive + " " + "page"}>
                    <a id="btnNext" onClick={btnNextClick}>
                        Sig.
                    </a>
                </li>
            );
        }

        return (
            <div style={{ display: "flex" }}>
                {renderPrevBtn}
                {pageDecrementBtn}
                {renderPageNumbers}
                {pageIncrementBtn}
                {renderNextBtn}
                &nbsp;
            </div>
        );
    };

    const eventAcciones = (id: string, registro: number) => {
        if (id === "paging") {
            setActiveKey(registro);
        }
        callbackAcciones(id, registro);
    };

    const limpiarFiltros = () => {
        callbackFilter("ID", "0", []);
        callbackFilter("FECHA_CIERRE", "", new Date("12/12/2012"));
        callbackFilter("NOMBRE", "0", []);
        callbackFilter("UNIDAD_DISPLAY", "0", []);
        callbackFilter("ESTADO_DISPLAY", "0", []);
        callbackFilter("CLIENTE_DISPLAY", "0", []);
        callbackFilter("RESPONSABLE_PROYECTO_DISPLAY", "", [
            {
                mail: "",
                name: "",
            },
        ]);

        filtros.nombre = "";
        filtros.id = "";
        filtros.unidad = 0;
        setFilter([]);

        return true;
    };

    const onValueChanged = (ev: any, id: string) => {
        let value = ev.target.value;
        filtros.nombre = value;
        callbackFilter(id, value, []);
    };

    const onValueChangedId = (ev: any, id: string) => {
        let value = ev.target.value;
        filtros.id = value;
        callbackFilter(id, value, []);
    };

    const onChange = (e: any, item: any, id: string) => {
        let sf = selectedFilter;

        let exists =
            sf.filter((f) => {
                return f.id === id;
            }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = item.key;
                }
            });
        } else {
            sf.push({
                id: id,
                value: item.key,
            });
            filtros.unidad = item.key;
        }

        setFilter([...sf]);

        callbackFilter(id, item.key, []);
    };

    const _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    const _onSelectDate = (date: Date | null | undefined, id: string): void => {
        let sf = selectedFilter;

        let exists =
            sf.filter((f) => {
                return f.id === id;
            }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = date;
                }
            });
        } else {
            sf.push({
                id: id,
                value: date,
            });
        }

        setFilter([...sf]);

        callbackFilter(id, "", date);
    };

    const DayPickerStrings: IDatePickerStrings = {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
        shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        shortDays: ["D", "L", "M", "M", "J", "V", "S"],
        goToToday: "Ir a hoy",
        prevMonthAriaLabel: "Ir al mes previo",
        nextMonthAriaLabel: "Ir al mes siguiente",
        prevYearAriaLabel: "Ir al año anterior",
        nextYearAriaLabel: "Ir al año siguiente",
        closeButtonAriaLabel: "Cerrar el selector de fechas",
    };

    const callbackAutoComplete = (id: string, value: string) => {
        let sf = selectedFilter;

        let exists =
            sf.filter((f) => {
                return f.id === id;
            }).length > 0;

        if (exists) {
            sf.forEach((s: any) => {
                if (s.id === id) {
                    s.value = value;
                }
            });
        } else {
            sf.push({
                id: id,
                value: value,
            });
        }

        setFilter([...sf]);

        callbackFilter(id, "", value);
    };

    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 15px 0",
            maxWidth: "300px",
        },
    });

    const tipoFiltro = (tipo: string, id: string) => {
        let control = undefined;
        switch (tipo) {
            case "id":
                control = (
                    <TextField
                        className="id"
                        type={"number"}
                        onChange={(e) => {
                            onValueChangedId(e, id);
                        }}
                        id="id"
                        value={filtros.id}
                    />
                );
                break;
            case "text":
                control = (
                    <TextField
                        onChange={(e) => {
                            onValueChanged(e, id);
                        }}
                        id="nombre"
                        value={filtros.nombre}
                    />
                );
                break;
            case "autocomplete":
                control = (
                    <AutoComplete
                        disabled={false}
                        id={id}
                        multi={false}
                        callback={callbackAutoComplete}
                        defaultValue={""}
                        tokenAPIGraph={tokenAPIGraph}
                    />
                );
                break;
            case "date":
                control = (
                    <DatePicker
                        className={controlClass.control}
                        firstDayOfWeek={DayOfWeek.Monday}
                        strings={DayPickerStrings}
                        allowTextInput={true}
                        placeholder="Fecha de apertura"
                        value={
                            selectedFilter.filter((f) => {
                                return f.id === id;
                            }).length > 0
                                ? selectedFilter.filter((f) => {
                                      return f.id === id;
                                  })[0].value
                                : null
                        }
                        ariaLabel="Seleccione una fecha"
                        formatDate={_onFormatDate}
                        disabled={false}
                        onSelectDate={(e: Date | null | undefined) => {
                            _onSelectDate(e, id);
                        }}
                    />
                );
                id = "FECHA_CIERRE";
                break;
            case "combo":
                var items = [];
                if (comboData.length > 0) {
                    items = comboData
                        .find((f) => {
                            return f.id === id;
                        })
                        .value.filter((f: any) => {
                            return f.TITULO != null;
                        })
                        .map((m: any) => {
                            return { key: m.ID === null ? -1 : m.ID, text: m.TITULO === null ? "(vacío)" : m.TITULO };
                        });
                }
                items.unshift({ key: 0, text: "Seleccione..." });
                control = (
                    <ComboBox
                        key={"cmb_UNIDAD_DISPLAY"}
                        onChange={(e, item) => {
                            onChange(e, item, id);
                        }}
                        autoComplete="off"
                        selectedKey={
                            selectedFilter.filter((f) => {
                                return f.id === id;
                            }).length > 0
                                ? selectedFilter.filter((f) => {
                                      return f.id === id;
                                  })[0].value
                                : 0
                        }
                        options={items}
                        id={id}
                        defaultSelectedKey={filtros.unidad}
                    />
                );

                control = (
                    <ComboBox
                        key={"cmb_ESTADO_DISPLAY"}
                        onChange={(e, item) => {
                            onChange(e, item, id);
                        }}
                        autoComplete="off"
                        selectedKey={
                            selectedFilter.filter((f) => {
                                return f.id === id;
                            }).length > 0
                                ? selectedFilter.filter((f) => {
                                      return f.id === id;
                                  })[0].value
                                : 0
                        }
                        options={items}
                        id={id}
                    />
                );
                break;
        }

        return <div>{control}</div>;
    };

    return (
        <React.Fragment>
            {
                <div>
                    <div>
                        <h4 className="titGrilla">{titulo}</h4>
                        <div className="accionesGrilla">
                            <span className="botonRecargar">
                                <IconButton
                                    iconProps={{
                                        iconName: "SyncStatusSolid",
                                    }}
                                    title="Recargar"
                                    ariaLabel=""
                                    onClick={() => {
                                        eventAcciones("reload", 0);
                                    }}
                                />
                            </span>
                            <span className="botonLimpiar">
                                <IconButton
                                    iconProps={{
                                        iconName: "ClearFilter",
                                    }}
                                    title="Limpiar filtros"
                                    ariaLabel=""
                                    onClick={() => {
                                        limpiarFiltros();
                                    }}
                                />
                            </span>
                        </div>

                        <div className="table-responsive">
                            <table key="tabla_datos" className="grilla table table-hover table-sm table-striped">
                                <thead>
                                    <tr key={"tr_datos"}>
                                        {columnas.map((c, key) => {
                                            return (
                                                <th key={c.interno}>
                                                    {c.titulo}
                                                    {c.filtrable ? <span>{tipoFiltro(c.filterType, c.interno)}</span> : ""}
                                                </th>
                                            );
                                        })}
                                        {acciones.length > 0 ? <th>Acciones</th> : ""}
                                    </tr>
                                </thead>
                                <tbody>
                                    {listado.map((l, key) => {
                                        return (
                                            <tr key={key}>
                                                {columnas.map((c, key) => {
                                                    return (
                                                        <td key={"d_" + key}>
                                                            {c.filterType === "date" ? new Date(l[c.interno]).toLocaleDateString() : l[c.interno]}
                                                        </td>
                                                    );
                                                })}
                                                {
                                                    <td>
                                                        {acciones.map((a, key) => {
                                                            return l["ESTADO_DISPLAY"] == "Planificación" && a.id == "cerrar" ? (
                                                                ""
                                                            ) : (
                                                                <IconButton
                                                                    iconProps={{ iconName: a.icon }}
                                                                    title={a.title}
                                                                    ariaLabel=""
                                                                    onClick={() => {
                                                                        eventAcciones(a.id, l["ID"]);
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </td>
                                                }
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="paginar">{pages > 1 ? paginacion() : ""}</div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

export default GrillaDatosGestionProyecto;
