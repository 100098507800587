import React, { useState, useEffect } from "react";
import { authProvider } from "../../authProvider";
import { AzureAD, AuthenticationState, IAzureADFunctionProps, MsalAuthProvider, LoginType } from "react-aad-msal";
import { fetchEngine } from "../../fetchData";

interface Props {
    provider: MsalAuthProvider;
}

const Authenticated = ({ provider }: Props) => {
    const [imagen, setImagen] = useState(new Blob());
    const [nFirst, setNombre] = useState("");
    const [lFirst, setApellido] = useState("");
    const [tokenAPIGraph, setTokenAPIGraph] = useState("");

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPIGraph);
            }).then((d) => {
                setTokenAPIGraph(d);
            });
        }
        // Actualiza el título del documento usando la API del navegador
        if (provider.authenticationState === AuthenticationState.Authenticated) {
            let graphAPI = fetchEngine.graphEndPoint;

            if (tokenAPIGraph != null && tokenAPIGraph != "") {
                fetch(`${graphAPI}/me/photo/$value`, {
                    method: "GET",
                    mode: "cors",
                    cache: "no-cache",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + tokenAPIGraph,
                    },
                })
                    .then((response) => {
                        // handle the response
                        if (response.ok) {
                            response.blob().then((r) => {
                                setImagen(r);
                            });
                        } else {
                            let acc = provider.getAccount();
                            let name = acc.name;
                            let splName = name.split(" ");
                            setNombre(Array.from(splName[0])[0].toString());
                            setApellido(Array.from(splName[1])[0].toString());
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            }
        }
    }, [provider, tokenAPIGraph]);

    const [isOpen, setToggle] = useState(false);
    const [alto, setAlto] = useState(0);
    const [userOpen, setuserOpen] = useState(false);

    const toggle = () => {
        setToggle(!isOpen);
    };

    const toggleUser = () => {
        setuserOpen(!userOpen);
    };

    if (imagen.size > 0) {
        return <img src={URL.createObjectURL(imagen)} className="rounded-circle" width={32} height={32} />;
    } else {
        return <span className="rounded-circle p-2 bg-white border-1">{nFirst + "" + lFirst}</span>;
    }
};

export default Authenticated;
