import React, { useState, useEffect } from "react";
import "react-autosuggest";
import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField, Checkbox } from "@fluentui/react";
import { Accordion } from "../../../controles/Accordion/Accordion";
import "../PlanificacionProyecto.css";
import { MessageBar, MessageBarType } from "@fluentui/react";
import NumberFormat from "react-number-format";

interface Props {
    actividadesList?: IngresoGastos[];
    ingresoP?: IngresoData[];
    callback: any;
    callbackActividades: any;
    listadoOpciones?: any[];
    moneda?: string;
    estado_modulo: number;
    lista_categorias?: any[];
    total_year?: any[];
    arrayGastos?: any[];
    callbackArrayGastos: any;
    id_planificacion: number;
    cantidad_meses: number | undefined;
}

interface IngresoGastos {
    ID: number;
    ID_SIMULACION: number;
    ID_MODULO: number;
    ID_ACTIVIDAD: number;
    index: number;
    NOMBRE: string;
    //GASTO_TOTAL: number;
    VALOR_RECUPERAR: number;
    ID_TEMPORAL: number;
    GASTO_TOTAL: number;
    GASTO_DISTRIBUIDO: number;
}

interface IngresoData {
    ID: number;
    INDEX: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    ITEM: string;
    UNIDAD: string;
    COSTO_UNITARIO: number;
    CANTIDAD: number;
    GASTO_TOTAL: number;
    VALOR_RECUPERAR: number;
    REEMBOLSABLE: boolean;
    ID_TEMPORAL: number;
    TOTAL_GASTOS_DIST: number;
}

interface ArrayGastos {
    ID: number;
    ID_MES: number;
    ID_GASTOS: number;
    VALOR: number;
    ANIO: number;
    ID_TEMPORAL: number;
}

const IngresosGastosPlanificacion = ({
    actividadesList,
    ingresoP,
    callback,
    listadoOpciones,
    moneda,
    estado_modulo,
    lista_categorias,
    callbackActividades,
    total_year,
    arrayGastos,
    callbackArrayGastos,
    id_planificacion,
    cantidad_meses,
}: Props) => {
    const [arrGastos, setArrGastos] = useState(arrayGastos);

    const [ingresos, setIngreso] = useState(ingresoP);
    const [actividades, setActividades] = useState(actividadesList);
    const [nombreAcividad, setNombreActividad] = useState("");

    const [totalGasto, setTotalGasto] = useState(0);
    const [valorRecuperar, setValorRecuperar] = useState(0);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogSubText, setDialogSubText] = useState("");

    const [indexActividad, setIndexActividad] = useState(-1);
    const [indexIngreso, setIndexIngreso] = useState(-1);

    const [activeKey, setActiveKey] = useState(0);
    const modalPropsStyles = {
        main: { maxWidth: 450 },
    };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
    };

    const addActividad = () => {
        let actividad = actividades;
        let listaActividades = actividades;
        let exists;
        let nuevoID = 0;

        if (actividad != undefined) {
            actividad.map((a: any) => {
                nuevoID = a.ID_ACTIVIDAD;
            });
        }

        if (nombreAcividad != "") {
            //console.log("nombre actividad no vacio")
            if (listaActividades !== undefined) {
                //console.log("no undefined")
                if (listaActividades.length > 0) {
                    for (let i = 0; i < listaActividades.length; i++) {
                        const nombre = listaActividades[i].NOMBRE;
                        //console.log(nombre)
                        if (nombre == nombreAcividad) {
                            exists = true;
                            break;
                        } else {
                            exists = false;
                        }
                    }
                } else {
                    if (actividad != undefined) {
                        actividad.push({
                            ID_SIMULACION: 0,
                            ID_MODULO: 3,
                            ID_ACTIVIDAD: nuevoID + 1,
                            index: actividad.length,
                            NOMBRE: nombreAcividad,
                            GASTO_TOTAL: 0,
                            VALOR_RECUPERAR: 0,
                            ID_TEMPORAL: 0,
                            ID: 0,
                            GASTO_DISTRIBUIDO: 0,
                        });
                        setActividades([...actividad]);
                    }
                }
                if (exists === false) {
                    if (actividad != undefined) {
                        actividad.push({
                            ID_SIMULACION: 0,
                            ID_MODULO: 3,
                            ID_ACTIVIDAD: nuevoID + 1,
                            index: actividad.length,
                            NOMBRE: nombreAcividad,
                            GASTO_TOTAL: 0,
                            VALOR_RECUPERAR: 0,
                            ID_TEMPORAL: 0,
                            ID: 0,
                            GASTO_DISTRIBUIDO: 0,
                        });
                        setActividades([...actividad]);
                    }
                }
            }
            callbackActividades(actividades);
        }

        setNombreActividad("");
    };

    const _onChangeRol = (index: number, item: any, a: IngresoGastos): void => {
        let data = ingresos;
        let key = item.key;

        if (data !== undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    if (listadoOpciones !== undefined) {
                        listadoOpciones.map((a: any) => {
                            if (a.key === key) {
                                i.ITEM = key;
                            }
                        });
                    }
                }
            });
            setIngreso([...data]);
        }
    };

    const addRol = (actividadID: number) => {
        //console.log(ingresos);
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.push({
                ID_ACTIVIDAD: actividadID,
                ID: 0,
                ID_SIMULACION: 0,
                ITEM: "",
                UNIDAD: "",
                COSTO_UNITARIO: 0,
                CANTIDAD: 0,
                GASTO_TOTAL: 0,
                VALOR_RECUPERAR: 0,
                REEMBOLSABLE: false,
                ID_TEMPORAL: 0,
                TOTAL_GASTOS_DIST: 0,
                INDEX: ingreso.reduce((a: number, b: IngresoData) => 1 + b.INDEX, 0),
            });
            setIngreso([...ingreso]);
            callback(ingreso);
        }
    };

    const deleteRol = (indexI: number) => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            let row = ingreso.find((ing: IngresoData, i: number) => i === indexI);
            let sumaRows = 0;
            if (row != undefined) {
                sumaRows = row.COSTO_UNITARIO + row.CANTIDAD;
            }

            if (sumaRows === 0) {
                ingreso.splice(indexI, 1);
                setIngreso([...ingreso]);
                //console.log(ingreso);
                callback(ingreso);
            } else {
                setDialogTitle("Ingreso con datos!");
                setDialogSubText("Esta fila contiene datos ingresados ¿Está seguro que desea eliminar?");
                setIndexIngreso(indexI);
                setDialog(false);
            }
        }
    };

    const deleteRolIndex = () => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.splice(indexIngreso, 1);
            setIngreso([...ingreso]);
            //console.log(ingreso);
            setIndexIngreso(-1);
            setIndexActividad(-1);
            setDialog(true);
            callback(ingreso);
            calcularTotalActividad(ingreso, indexActividad);
            setIndexActividad(-1);
        }
    };

    const deleteActividad = (actividadID: number, indexA: number) => {
        let ingreso = ingresos;
        let actividad = actividades;
        let tieneIngresos = false;

        if (ingreso != undefined) {
            tieneIngresos = ingreso.some((i: IngresoData) => i.ID_ACTIVIDAD === actividadID);
        }

        if (tieneIngresos) {
            setIndexActividad(indexA);
            setDialogTitle("Actividad con roles!");
            setDialogSubText("¿Está seguro que desea eliminar la actividad?");
            setDialog(false);
        } else {
            if (actividad != undefined) {
                actividad.splice(indexA, 1);
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        }
    };

    const cambio = (e: any) => {
        //console.log(e);
        e.value = 0;
        //console.log(e);
    };

    const deleteActividadRoles = () => {
        let ingreso = ingresos;
        let ingreso2: any = [];
        let actividad = actividades;
        let actividad2: any = [];
        let actividadEliminar: IngresoGastos | undefined;

        if (actividad != undefined) {
            actividadEliminar = actividad.find((a, i) => i === indexActividad);

            if (ingreso != undefined) {
                if (actividadEliminar != undefined) {
                    let idActividad = actividadEliminar.ID_ACTIVIDAD;

                    ingreso.map((ing: any) => {
                        if (ing.ID_ACTIVIDAD != idActividad) {
                            ingreso2.push({
                                CANTIDAD: ing.CANTIDAD,
                                COSTO_UNITARIO: ing.COSTO_UNITARIO,
                                GASTO_TOTAL: ing.GASTO_TOTAL,
                                ID: ing.ID,
                                ID_ACTIVIDAD: ing.ID_ACTIVIDAD,
                                ID_FILA_ITEM: ing.ID_FILA_ITEM,
                                ID_SIMULACION: ing.ID_SIMULACION,
                                ID_TEMPORAL: ing.ID_TEMPORAL,
                                ITEM: ing.ITEM,
                                REEMBOLSABLE: ing.REEMBOLSABLE,
                                TOTAL_GASTOS_DIST: ing.TOTAL_GASTOS_DIST,
                                UNIDAD: ing.UNIDAD,
                                VALOR_RECUPERAR: ing.VALOR_RECUPERAR,
                            });
                        }
                    });

                    actividad.map((a: any) => {
                        if (a.ID_ACTIVIDAD != idActividad) {
                            actividad2.push({
                                GASTO_DISTRIBUIDO: a.GASTO_DISTRIBUIDO,
                                GASTO_TOTAL: a.GASTO_TOTAL,
                                ID: a.ID,
                                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                ID_MODULO: a.ID_MODULO,
                                ID_SIMULACION: a.ID_SIMULACION,
                                ID_TEMPORAL: a.ID_TEMPORAL,
                                NOMBRE: a.NOMBRE,
                                VALOR_RECUPERAR: a.VALOR_RECUPERAR,
                            });
                        }
                    });
                }

                setActividades([...actividad2]);
                setDialog(true);
                callback(ingreso2);
                callbackActividades(actividad2);
                setTotalGasto(actividad2.reduce((a: any, b: any) => a + b.GASTO_TOTAL, 0));
                setValorRecuperar(actividad2.reduce((a: any, b: any) => a + b.VALOR_RECUPERAR, 0));
            }
        }
    };

    const onValueChanged = (index: number, ev: any, indexA: number, e?: string) => {
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.floatValue : ev.target.value;
        let val = 0;
        //console.log(index, indexA, value, key);

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    switch (key) {
                        case "unidadGastos_Plan":
                            i.UNIDAD = value;
                            break;
                        case "costoUnitarioGastos_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.COSTO_UNITARIO = val;
                            } else {
                                i.COSTO_UNITARIO = 0;
                            }
                            //Calcular
                            break;
                        case "cantidadGastos_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.CANTIDAD = val;
                            } else {
                                i.CANTIDAD = 0;
                            }
                            //Calcular
                            break;
                        case "gastoTotalGastos_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.GASTO_TOTAL = val;
                            } else {
                                i.GASTO_TOTAL = 0;
                            }
                            break;
                        case "valorRecuperarGastos_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.VALOR_RECUPERAR = val;
                            } else {
                                i.VALOR_RECUPERAR = 0;
                            }
                            //Calcular
                            break;
                        case "reembolsableGastos_Plan":
                            i.REEMBOLSABLE = value;
                            break;
                        case "itemGastos_Plan":
                            i.ITEM = value;
                            break;
                    }
                    i.GASTO_TOTAL = parseFloat((i.COSTO_UNITARIO * i.CANTIDAD).toFixed(2));
                }
            });

            //Total Actividad
            calcularTotalActividad(data, indexA);

            setIngreso([...data]);
            callback(data);
        }
    };

    const onCheckChange = (index: number, ev: any, indexA: number) => {
        let key = ev.target.id;
        let value = ev.target.checked;
        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    i.REEMBOLSABLE = value;
                    if (value === true && i.GASTO_TOTAL > 0) {
                        i.VALOR_RECUPERAR = i.GASTO_TOTAL;
                    } else if (value === false) {
                        i.VALOR_RECUPERAR = 0;
                    }
                }
            });

            setIngreso([...data]);
            callback(data);
        }
    };

    const calcularTotalActividad = (ingresos: IngresoData[], actividadID: number) => {
        if (ingresos !== undefined && actividades !== undefined) {
            let total_gasto_act: number = 0;
            let total_recuperable_act: number = 0;
            actividades
                .filter((actividad: IngresoGastos) => actividad.ID_ACTIVIDAD == actividadID && actividad.ID_MODULO == 3)
                .forEach((actividad: IngresoGastos) => {
                    actividad.GASTO_TOTAL = 0;
                    actividad.VALOR_RECUPERAR = 0;
                    ingresos
                        .filter((ingreso: IngresoData) => ingreso.ID_ACTIVIDAD === actividadID)
                        .forEach((ingreso: IngresoData) => {
                            actividad.GASTO_TOTAL += ingreso.GASTO_TOTAL;
                            actividad.VALOR_RECUPERAR += ingreso.VALOR_RECUPERAR;
                        });
                });
            actividades
                .filter((actividad: IngresoGastos) => actividad.ID_MODULO == 3)
                .forEach((actividad: IngresoGastos) => {
                    total_gasto_act += actividad.GASTO_TOTAL;
                    total_recuperable_act += actividad.VALOR_RECUPERAR;
                });
            setTotalGasto(total_gasto_act);
            setValorRecuperar(total_recuperable_act);
        }
    };

    useEffect(() => {
        let data = ingresos;
        if (data != undefined) {
            data!.map((a: any) => {
                //console.log(a);
                calcularTotalActividad(data!, a.ID_ACTIVIDAD);
            });
        }
    }, []);

    const _onChangeActividad = (event: any, item: any): void => {
        let text = item == undefined ? "" : item.text;

        setNombreActividad(text);
    };

    const agregarReservaArray = (anio: number, mes: number, valor: any, ID: number, id_temporal: number) => {
        let data = actividades;
        let flag = false;

        if (arrayGastos !== undefined) {
            if (arrayGastos.length <= 0) {
                arrayGastos.push({
                    ID: 0,
                    ID_GASTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    VALOR: valor,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                });
            } else {
                //modifica
                arrayGastos.forEach((e: any) => {
                    if (e.ANIO === anio && e.ID_MES === mes && e.ID_GASTOS === ID) {
                        e.VALOR = valor;
                    }
                });

                //agrega
                for (let index = 0; index < arrayGastos.length; index++) {
                    const e = arrayGastos[index];
                    if (e.ANIO === anio && e.ID_MES === mes && e.ID_GASTOS === ID) {
                        flag = false;
                        break;
                    } else {
                        flag = true;
                    }
                }
            }
            if (flag === true) {
                arrayGastos.push({
                    ID: 0,
                    ID_GASTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    VALOR: valor,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                });
            }

            if (data != undefined) {
                data.map((i: IngresoGastos, ing: number) => {
                    if (ID === i.ID) {
                        i.GASTO_DISTRIBUIDO = arrayGastos.filter((e: any) => e.ID_GASTOS === ID).reduce((a, b) => a + b.VALOR, 0);
                    }
                });
            }
        }

        setActividades(data);
        callbackArrayGastos(arrayGastos);
    };

    const onReservaChange = (anio: number, mes: number, element: any, id_temporal: number, index_I: number) => {
        let indexI = 0;
        let id = index_I;
        indexI = id;
        let valor = parseFloat(element.value); // valor del input

        if (isNaN(valor)) {
            valor = 0;
        } else if (valor >= 0) {
            valor = valor;
        } else {
            valor = 0;
        }

        switch (mes) {
            case 1:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 2:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 3:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 4:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 5:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 6:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 7:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 8:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 9:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 10:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 11:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
            case 12:
                agregarReservaArray(anio, mes, valor, indexI, id_temporal);
                break;
        }
    };

    /**
     * Returns count of weeks for year and month
     *
     * @param {Number} year - full year (YYYY)
     * @param {Number} month_number - month_number is in the range 1..12
     * @returns {number}
     */
    const weeksCount = (year: number, month_number: number) => {
        let firstOfMonth = new Date(year, month_number - 1, 1);
        let day = firstOfMonth.getDay() || 6;
        day = day === 1 ? 0 : day;
        if (day) {
            day--;
        }
        let diff = 7 - day;
        let lastOfMonth = new Date(year, month_number, 0);
        let lastDate = lastOfMonth.getDate();
        if (lastOfMonth.getDay() === 1) {
            diff--;
        }
        let result = Math.ceil((lastDate - diff) / 7);

        return result + 1;
    };

    const valueMonth = (year: number, month_number: number, id_gasto: number) => {
        let value = -1;

        if (arrayGastos !== undefined) {
            arrayGastos.forEach((e: ArrayGastos) => {
                if (e.ANIO === year && e.ID_MES === month_number && e.ID_GASTOS === id_gasto) {
                    value = e.VALOR;
                }
            });
        }

        return value.toString();
    };

    const calcularDistribucion = (gastos: number, id: number) => {
        let gasto_distribuido = 0;

        if (cantidad_meses !== undefined) {
            gasto_distribuido = gastos / cantidad_meses;
        }

        if (arrayGastos !== undefined) {
            arrayGastos.map((e: ArrayGastos) => {
                if (e.ID_GASTOS === id) {
                    e.VALOR = parseFloat(gasto_distribuido.toFixed(2));
                }
            });
        }

        setArrGastos(arrayGastos);
    };

    const FilasIngresos = (a: IngresoGastos) => {
        return (
            <div>
                {ingresos != undefined
                    ? ingresos.map((i: IngresoData, indexI: number) => {
                          return (
                              <div>
                                  {i.ID_ACTIVIDAD === a.ID_ACTIVIDAD ? (
                                      <div>
                                          <div key={indexI}>
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 txtSmall separadorBloques">
                                                  <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg3 txtSmall">
                                                          <TextField
                                                              label="Ítem"
                                                              id={"itemGastos_Plan"}
                                                              defaultValue={i.ITEM}
                                                              placeholder="Nombre ítem"
                                                              onChange={(e) => {
                                                                  onValueChanged(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 txtSmall">
                                                          <TextField
                                                              label="Unidad"
                                                              id={"unidadGastos_Plan"}
                                                              defaultValue={i.UNIDAD}
                                                              placeholder="..."
                                                              onChange={(e) => {
                                                                  onValueChanged(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Costo unitario</label>
                                                              <NumberFormat
                                                                  id={"costoUnitarioGastos_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "costoUnitarioGastos_Plan");
                                                                  }}
                                                                  defaultValue={
                                                                      i.COSTO_UNITARIO <= 0 ? "" : i.COSTO_UNITARIO.toString().replace(".", ",")
                                                                  }
                                                                  value={
                                                                      i.COSTO_UNITARIO === 0 || i.COSTO_UNITARIO <= 0
                                                                          ? ""
                                                                          : i.COSTO_UNITARIO.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Cantidad</label>
                                                              <NumberFormat
                                                                  id={"cantidadGastos_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "cantidadGastos_Plan");
                                                                  }}
                                                                  defaultValue={i.CANTIDAD <= 0 ? "" : i.CANTIDAD.toString().replace(".", ",")}
                                                                  value={
                                                                      i.CANTIDAD === 0 || i.CANTIDAD <= 0
                                                                          ? ""
                                                                          : i.CANTIDAD.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Gasto total</label>
                                                              <NumberFormat
                                                                  id={"gastoTotalGastos_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "gastoTotalGastos_Plan");
                                                                  }}
                                                                  defaultValue={i.GASTO_TOTAL <= 0 ? "" : i.GASTO_TOTAL.toString().replace(".", ",")}
                                                                  value={
                                                                      i.GASTO_TOTAL === 0 || i.GASTO_TOTAL <= 0
                                                                          ? ""
                                                                          : i.GASTO_TOTAL.toString().replace(".", ",")
                                                                  }
                                                                  disabled={true}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Val. a recuperar</label>
                                                              <NumberFormat
                                                                  id={"valorRecuperarGastos_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "valorRecuperarGastos_Plan");
                                                                  }}
                                                                  defaultValue={
                                                                      i.VALOR_RECUPERAR <= 0 ? "" : i.VALOR_RECUPERAR.toString().replace(".", ",")
                                                                  }
                                                                  value={
                                                                      i.VALOR_RECUPERAR === 0 || i.VALOR_RECUPERAR <= 0
                                                                          ? ""
                                                                          : i.VALOR_RECUPERAR.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div
                                                          className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall mt-4 reembolsable"
                                                          id="planGastoCobrable">
                                                          <Checkbox
                                                              //id="licitacion"
                                                              label="Cobrable al cliente"
                                                              checked={i.REEMBOLSABLE}
                                                              onChange={(e) => {
                                                                  onCheckChange(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }></Checkbox>
                                                      </div>
                                                      {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                                                          ""
                                                      ) : (
                                                          <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 mt-4" id="divEliminarRol">
                                                              <button
                                                                  id="btnAgregarRol"
                                                                  onClick={(e) => {
                                                                      deleteRol(indexI);
                                                                      setIndexActividad(a.ID_ACTIVIDAD);
                                                                  }}>
                                                                  <i className="ms-Icon ms-Icon--Delete" aria-hidden="true" id="btnEliminarRol2"></i>
                                                              </button>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  ) : (
                                      ""
                                  )}
                              </div>
                          );
                      })
                    : ""}

                <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                    {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                        ""
                    ) : (
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" id="divAgregarActividad">
                            <button
                                id="btnAgregarRol"
                                onClick={(e) => {
                                    addRol(a.ID_ACTIVIDAD);
                                }}>
                                <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarRol">
                                    <span className="txtAgregar">&nbsp; Agregar ítem</span>
                                </i>
                            </button>
                        </div>
                    )}

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg7 txtSmall" id="divTotal">
                            Total
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                            <TextField
                                id={"totalActividadDirectoGasto_Plan"}
                                disabled={true}
                                placeholder={"..."}
                                //type={"number"}
                                value={a.GASTO_TOTAL != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.GASTO_TOTAL) : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                            <TextField
                                id={"totalActividadDirectoValor_Plan"}
                                disabled={true}
                                placeholder={"..."}
                                //type={"number"}
                                value={a.VALOR_RECUPERAR != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.VALOR_RECUPERAR) : ""}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Resumen = () => {
        return (
            <div className="ms-Grid-row divTotalBloque" id="divTotalBloque">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className="ms-Grid-col ms-sm7 ms-m7 ms-lg6 txtSmall"></div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" id="divTotal">
                        Total gastos
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoGasto_Plan"}
                            disabled={true}
                            placeholder={"..."}
                            value={totalGasto != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(totalGasto) : ""}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoValor_Plan"}
                            disabled={true}
                            placeholder={"..."}
                            value={valorRecuperar != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(valorRecuperar) : ""}
                        />
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall"></div>
            </div>
        );
    };

    const Actividades = () => {
        return actividades != undefined
            ? actividades.map((act: IngresoGastos, indexAct: number) => {
                  return (
                      <div>
                          <div>
                              <div className="acordeonActividad">
                                  <Accordion
                                      title={act.NOMBRE}
                                      defaultCollapsed={false}
                                      inicio={true}
                                      expandAll={false}
                                      className="fondoTitAcordionActividad"
                                      key={1}
                                      classFondo="fondoSeccionActividad">
                                      <div className="itemContent">
                                          {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                                              ""
                                          ) : (
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="divBloque">
                                                  <button
                                                      id="btnAgregarRol"
                                                      className="cierre"
                                                      onClick={(e) => {
                                                          deleteActividad(act.ID_ACTIVIDAD, indexAct);
                                                      }}>
                                                      <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" id="btnEliminarActividad"></i>
                                                  </button>
                                              </div>
                                          )}
                                      </div>

                                      <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                          <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg2 txtSmall">
                                              <TextField
                                                  label="Total Gastos Distribuidos"
                                                  id={"totalGasto_Plan"}
                                                  value={
                                                      act.GASTO_DISTRIBUIDO === 0
                                                          ? ""
                                                          : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.GASTO_DISTRIBUIDO).toString()
                                                  }
                                                  defaultValue={
                                                      act.GASTO_DISTRIBUIDO <= 0
                                                          ? ""
                                                          : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.GASTO_DISTRIBUIDO).toString()
                                                  }
                                                  disabled={true}
                                                  placeholder={"..."}
                                              />
                                          </div>
                                          <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                              <MessageBar
                                                  messageBarType={
                                                      act.GASTO_DISTRIBUIDO > act.GASTO_TOTAL
                                                          ? MessageBarType.warning
                                                          : act.GASTO_DISTRIBUIDO < act.GASTO_TOTAL
                                                          ? MessageBarType.warning
                                                          : MessageBarType.success
                                                  }
                                                  isMultiline={false}
                                                  dismissButtonAriaLabel="Close">
                                                  {act.GASTO_DISTRIBUIDO > act.GASTO_TOTAL
                                                      ? `Sobran ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat((act.GASTO_DISTRIBUIDO - act.GASTO_TOTAL).toFixed(2))
                                                        )} gastos distribuidos.`
                                                      : act.GASTO_DISTRIBUIDO < act.GASTO_TOTAL
                                                      ? `Faltan ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat((act.GASTO_TOTAL - act.GASTO_DISTRIBUIDO).toFixed(2))
                                                        )} gastos distribuidos.`
                                                      : "Los gastos están correctamente distribuidos."}
                                              </MessageBar>
                                          </div>
                                          <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg2 btnDistribucion">
                                              <PrimaryButton
                                                  text="Distribución automática"
                                                  onClick={() => calcularDistribucion(act.GASTO_TOTAL, act.ID)}
                                                  disabled={estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false}
                                              />
                                          </div>
                                      </div>

                                      {/* calendario semanas */}
                                      {total_year !== undefined
                                          ? total_year.map((a: any) => {
                                                let arr_months2 = [];
                                                let arr_months: any[] = [];
                                                for (let indexA = 1; indexA <= 12; indexA++) {
                                                    arr_months.push({
                                                        mes: indexA,
                                                        value: valueMonth(a.years, indexA, act.ID),
                                                    });
                                                    arr_months2 = [];
                                                }
                                                return (
                                                    <div>
                                                        <div className="ms-Grid-row bloqueActividad mt-3" id="bloqueActividad">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2">
                                                                <div className="table-responsive fondoTabla">
                                                                    <table className="table table-sm tablaGastos">
                                                                        <thead>
                                                                            <tr>
                                                                                {a.years === a.inicio_year && a.inicio_mes > 1 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Enero {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Febrero {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Marzo {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Abril {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Mayo {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Junio {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Julio {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Agosto {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Septiembre {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Octubre {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Noviembre {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td colSpan={1}>
                                                                                            <span className="badge badge-primary">
                                                                                                Diciembre {a.years}
                                                                                            </span>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                {(a.years === a.inicio_year && a.inicio_mes > 1) === false ? (
                                                                                    <React.Fragment>
                                                                                        <td className="textMonth">
                                                                                            {arr_months[0].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      1,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[0].value < 0 ? cambio(arr_months[0]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"ENE-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            1,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[0].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[0].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[0].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[0].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                ) : arr_months[0].value > 0 ? (
                                                                                    agregarReservaArray(a.years, 1, 0, act.ID, act.ID_TEMPORAL)
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 1 ? "" : <td></td>}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[1].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      2,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[1].value < 0 ? cambio(arr_months[1]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"FEB-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            2,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[1].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[1].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[1].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[1].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[2].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      3,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[2].value < 0 ? cambio(arr_months[2]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"MAR-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            3,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[2].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[2].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[2].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[2].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[3].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      4,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[3].value < 0 ? cambio(arr_months[3]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"ABR-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            4,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[3].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[3].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[3].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[3].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[4].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      5,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[4].value < 0 ? cambio(arr_months[4]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"MAY-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            5,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[4].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[4].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[4].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[4].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[5].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      6,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[5].value < 0 ? cambio(arr_months[5]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"JUN-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            6,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[5].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[5].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[5].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[5].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[6].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      7,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[6].value < 0 ? cambio(arr_months[6]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"JUL-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            7,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[6].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[6].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[6].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[6].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[7].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      8,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[7].value < 0 ? cambio(arr_months[7]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"AGO-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            8,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[7].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[7].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[7].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[7].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[8].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      9,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[8].value < 0 ? cambio(arr_months[8]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"SEP-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            9,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[8].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[8].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[8].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[8].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[9].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      10,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[9].value < 0 ? cambio(arr_months[9]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"OCT-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            10,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[9].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[9].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[9].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[9].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[10].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      11,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[10].value < 0 ? cambio(arr_months[10]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"NOV-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            11,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[10].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[10].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[10].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[10].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}

                                                                                {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <td>
                                                                                            {arr_months[11].value < 0
                                                                                                ? agregarReservaArray(
                                                                                                      a.years,
                                                                                                      12,
                                                                                                      0,
                                                                                                      act.ID,
                                                                                                      act.ID_TEMPORAL
                                                                                                  )
                                                                                                : ""}
                                                                                            {arr_months[11].value < 0 ? cambio(arr_months[11]) : ""}
                                                                                            <span className={"formateoDistribucion"}>
                                                                                                <NumberFormat
                                                                                                    id={"DIC-" + a.years}
                                                                                                    onValueChange={(element) => {
                                                                                                        onReservaChange(
                                                                                                            a.years,
                                                                                                            12,
                                                                                                            element,
                                                                                                            act.ID_TEMPORAL,
                                                                                                            act.ID
                                                                                                        );
                                                                                                    }}
                                                                                                    defaultValue={
                                                                                                        arr_months[11].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[11].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    value={
                                                                                                        arr_months[11].value <= 0
                                                                                                            ? ""
                                                                                                            : arr_months[11].value
                                                                                                                  .toString()
                                                                                                                  .replace(".", ",")
                                                                                                    }
                                                                                                    thousandSeparator={"."}
                                                                                                    decimalSeparator={","}
                                                                                                    placeholder={"..."}
                                                                                                    disabled={
                                                                                                        estado_modulo === 10 ||
                                                                                                        estado_modulo === 24 ||
                                                                                                        estado_modulo === 25
                                                                                                            ? true
                                                                                                            : false
                                                                                                    }
                                                                                                />
                                                                                            </span>
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )}
                                                                                {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                    ""
                                                                                ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                    ""
                                                                                ) : (
                                                                                    <td></td>
                                                                                )}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })
                                          : ""}
                                      {/* fin calendario semanas */}
                                      {FilasIngresos(act)}
                                  </Accordion>
                              </div>
                          </div>
                      </div>
                  );
              })
            : "";
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            indexIngreso > -1 ? deleteRolIndex() : deleteActividadRoles();
                        }}
                        text="Sí, Eliminar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    <div className="itemContent">
                        {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm1 ms-md8 ms-lg7"></div>
                                <div className="ms-Grid-col ms-sm11 ms-md4 ms-lg5 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm8 ms-md4 ms-lg4 mt-2 mb-2">
                                    <ComboBox
                                        placeholder="Nombre Concepto"
                                        id={"nombreActividadDirecto"}
                                        //value={nombreAcividad}
                                        onChange={_onChangeActividad}
                                        options={lista_categorias}
                                        allowFreeform={true}
                                        autoComplete="on"
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm1 ms-md3 ms-lg3 mt-2 mb-2" id="divAgregarActividad">
                                    <button
                                        id="btnAgregarActividad"
                                        className="btnAgregarActividad"
                                        onClick={(e) => {
                                            addActividad();
                                        }}>
                                        <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarActividad">
                                            &nbsp;
                                            <span className="txtAgregar">Agregar concepto</span>
                                        </i>
                                    </button>
                                </div>
                                <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        {Actividades()}
                        {dialog()}
                        {Resumen()}
                    </div>
                    {/* </Accordion> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default IngresosGastosPlanificacion;
