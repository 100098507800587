import React, { useState, useEffect } from "react";
import "react-autosuggest";
import "./autocomplete.css";
import { fetchEngine } from "../../fetchData";

interface Props {
    callback: any;
    id: string;
    defaultValue: any;
    multi: boolean;
    disabled: boolean;
    tokenAPIGraph: string | null;
}

const AutoComplete = ({ callback, id, defaultValue, multi, disabled, tokenAPIGraph }: Props) => {
    const theme = {
        foo: {
            color: "red",
            ":hover": {
                color: "green",
            },
        },
        bar: {
            color: "blue",
        },
    };

    const [suggestions, setsuggestions] = useState([] as any[]);
    const [value, setValue] = useState("");
    const [values, setValues] = useState([] as any[]);
    const [textValue, setTextValue] = useState("");

    const getSuggestions = async (value: any) => {
        let val = value.target.value;
        setTextValue(val);
        const inputValue = val.trim().toLowerCase();
        const inputLength = inputValue.length;
        let usuarios: any = [];
        await new Promise<any>((resolve, reject) => {
            let grapiAPI = fetchEngine.graphEndPoint;

            if (tokenAPIGraph != null && tokenAPIGraph != "") {
                fetch(
                    `${grapiAPI}/users?$filter=startsWith(displayName,'${val}') 
                    or startsWith(mail,'${val}')&$select=id,displayName,mail`,
                    {
                        method: "GET",
                        mode: "cors",
                        cache: "no-cache",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + tokenAPIGraph,
                        },
                    }
                )
                    .then((result) => {
                        result.json().then((r) => {
                            if (r.value) {
                                r.value.forEach((user: any) => {
                                    usuarios.push({
                                        name: user.displayName,
                                        mail: user.mail,
                                    });
                                });
                            }
                            resolve(true);
                        });
                    })
                    .catch((e) => {
                        reject();
                    });
            }
        });

        setsuggestions([...usuarios]);
        return usuarios;
    };

    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    const getSuggestionValue = (suggestion: any) => `${suggestion.name} (${suggestion.mail})`;

    // Use your imagination to render suggestions.
    const renderSuggestion = (suggestion: any) => <div>{`${suggestion.name} (${suggestion.mail})`}</div>;

    const onChange = (event: any, { newValue }: any) => {
        try {
            ////console.log(newValue);
            let splited = newValue.replace("(", "").replace(")", "").split(" ");
            ////console.log(splited);
            callback(id, splited[2], splited[0] + " " + splited[1]);
            setValue(newValue);
        } catch (e) {}
    };

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    const onSuggestionsFetchRequested = ({ value }: any) => {
        getSuggestions(value).then((r) => {
            setsuggestions(r);
        });
    };

    // Autosuggest will call this function every time you need to clear suggestions.
    const onSuggestionsClearRequested = () => {
        setsuggestions([]);
    };
    const renderSectionTitle = ({ section }: any) => {
        return <strong>{section.title}</strong>;
    };

    const inputProps = {
        value: value,
        onChange: onChange,
        type: "search",
        placeholder: "ingrese usuario",
    };

    useEffect(() => {
        setValues([]);
        if (defaultValue.length === undefined && defaultValue.name != undefined) {
            if (defaultValue.name != "") {
                setValues([{ name: defaultValue.name, mail: defaultValue.mail }]);
            }
        }

        if (defaultValue.length > 0) {
            let maps: any[] = defaultValue.map((r: any) => {
                return { name: r.name, mail: r.mail };
            });
            setValues([...maps]);
            if (defaultValue[0].name === "") {
                setValues([]);
            }
        }
    }, [defaultValue]);

    const eliminarElemento = (index: number) => {
        let valuesE = values;

        console.log("borra");
        values.splice(index, 1);

        setValues([...values]);

        callback(id, values); // solución al eliminar usuario
    };

    const seleccionadoElemento = (item: any) => {
        values.push({
            name: item.name,
            mail: item.mail,
        });

        setsuggestions([]);
        setTextValue("");
        setValues([...values]);

        callback(id, values);
    };

    return (
        <React.Fragment>
            <div>
                <div className="sc-AxirZ bJCmFu">
                    {values.map((v, i) => {
                        return (
                            <div key={"div_" + i} data-tag-index="0" className="sc-AxiKw eSbheu">
                                <span key={"span_" + i} title={v.mail}>
                                    {v.name}
                                </span>
                                <svg
                                    key={"svg_" + i}
                                    className="MuiSvgIcon-root"
                                    id="borrarResponsable"
                                    focusable="false"
                                    onClick={() => {
                                        disabled ? console.log("salkd") : eliminarElemento(i);
                                    }}
                                    viewBox="0 0 24 24"
                                    aria-hidden="true">
                                    <path
                                        key={"key_" + i}
                                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                </svg>
                            </div>
                        );
                    })}
                    <input
                        type="text"
                        disabled={disabled ? true : !multi && values.length > 0}
                        aria-autocomplete="list"
                        autoComplete="off"
                        spellCheck={false}
                        onChange={(e) => {
                            getSuggestions(e);
                        }}
                        value={textValue}
                    />
                </div>
                {suggestions.length > 0 ? (
                    <ul role="listbox" className="listbox">
                        {suggestions.map((s, index) => {
                            return (
                                <li
                                    key={"li_" + index}
                                    onClick={() => {
                                        seleccionadoElemento(s);
                                    }}>
                                    {s.name}
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    ""
                )}
            </div>
        </React.Fragment>
    );
};

export default AutoComplete;
