import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Label, ComboBox } from "office-ui-fabric-react";
import moment from "moment";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import { Reserva, Proyectos, Consultores, Porcentajes, Resumen, ArrayReserva, Vacaciones, Licencias } from "../../controles/entidades/StaffingEntity";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { IDatePickerStrings, DatePicker, DayOfWeek } from "office-ui-fabric-react/lib/DatePicker";
import { mergeStyleSets } from "office-ui-fabric-react/lib/Styling";
import { Glyphicon } from "react-bootstrap";
import "./Staffing.css";
import "../Simulador/Simulador.css";
import { fetchEngine } from "../../fetchData";
import { authProvider } from "../../authProvider";
import "../../custom.css";
import AutoCompleteMantenedor from "../../controles/AutoComplete/AutoCompleteMantenedor";
import AcordeonProyectos from "./StaffingProyectos";
import Spinner from "../../controles/Spinner/Spinner";
import "../layout.css";

import { initializeIcons } from "@fluentui/react/lib/Icons";
import BotonAyuda from "../BotonAyuda/BotonAyuda";
initializeIcons();

const Staffing = () => {
    let st_info = {
        ID: 0,
        ID_CONSULTOR: 0,
        ID_PROYECTO: 0,
        ID_OPORTUNIDAD: 0,
        CONSULTOR_LOGIN: "",
        CONSULTOR_DISPLAY: "",
        FECHA_DESDE: new Date(Date.now()),
        FECHA_HASTA: new Date(Date.now()),
        ARRAY_RESERVA: [],
    };
    const history = useHistory();
    const [stInfo, setStInfo] = useState<Reserva>(st_info);
    const [pathName, setPathName] = useState<string>(window.location.pathname);
    const [params, setParams] = useState<any>(pathName.split("/"));
    const [estadoFormulario, setEstadoFormulario] = useState<boolean>(false);
    const [collapsed, setCollapsed] = useState<boolean>(true);
    const [dialogoCrearReserva, setDialogoCrearReserva] = useState<boolean>(true);
    const [validacionCrearReserva, setValidacionCrearReserva] = useState<boolean>(true);
    const [validacionFechas, setValidacionFechas] = useState<boolean>(true);
    const [collapseResumen, setCollapseResumen] = useState<boolean>(false);
    const [cargarReserva, setCargarReserva] = useState<boolean>(true);
    const [errorReserva, setErrorReserva] = useState<boolean>(true);
    const [okReserva, setOkReserva] = useState<boolean>(true);
    const [loaderTabla, setLoaderTabla] = useState<boolean>(false);
    const [arrayReserva2, setArrayReserva2] = useState<boolean>(false);

    const [opcionesProyecto, setOpcionesProyecto] = useState<any>([]);
    const [opcionesOportunidad, setOpcionesOportunidad] = useState<any>([]);
    const [opcionesUnidad, setOpcionesUnidad] = useState<any>([]);

    const [valorTest, setValorTest] = useState<number>(89);
    const [consultoresList, setConsultoresList] = useState<Consultores[]>([]);
    const [proyectosList, setProyectosList] = useState<Proyectos[]>([]);
    const [proyectosList2, setProyectosList2] = useState<Proyectos[]>([]);
    const [porcentajesList, setPorcentajesList] = useState<Resumen[]>([]);
    const [porcentajesList2, setPorcentajesList2] = useState<Resumen[]>([]);
    const [resumenList, setResumenList] = useState<Resumen[]>();
    const [consultor, setConsultor] = useState<string>("");
    const [nivel, setNivel] = useState<string>("");
    const [idUnidad, setIdUnidad] = useState<number>(0);
    const [consultorDisabled, setConsultorDisabled] = useState<boolean>(false);
    const [idEliminarReserva, setIdEliminarReserva] = useState<number>(0);
    const [firstYear, setFirstYear] = useState<number>(0);
    const [lastYear, setLastYear] = useState<number>(0);
    const [totalYear, setTotalYear] = useState<any>([]);
    const [arrayReserva, setArrayReserva] = useState<any>([]);
    const [clientID, setClientID] = useState<string>(process.env.REACT_APP_SCOPE_API ? process.env.REACT_APP_SCOPE_API : "");
    const [esAdmin, setEsAdmin] = useState<boolean>(false);
    const [esUsuario, setEsUsuario] = useState<boolean>(false);
    const [esGestion, setEsGestion] = useState<boolean>(false);
    const [esValidador, setEsValidador] = useState<boolean>(false);
    const [tokenAPI, setTokenAPI] = useState<string | null>("");
    const [tokenAPIGraphSites, setTokenAPIGraphSites] = useState<string | null>("");
    const [vacacionesList, setVacacionesList] = useState<any>([]);
    const [licenciasList, setLicenciasList] = useState<any>([]);
    const [infoInicialCargada, setInfoInicialCargada] = useState<boolean>(false);

    useEffect(() => {
        if (authProvider != undefined) {
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens);
            }).then((d) => {
                setTokenAPI(d.tokenAPI);
                setTokenAPIGraphSites(d.tokenAPIGraphSites);
            });
        }

        if (tokenAPI !== "" && !infoInicialCargada) {
            setInfoInicialCargada(true);
            verificarAdmin();
            verificarUsuario();
            verificarGestion();
            verificarValidador();
            obtenerValoresCbx();
        }
        if (tokenAPI != "" && infoInicialCargada) {
            obtenerValores();
            calendarioReserva();
        }
    }, [stInfo, consultor, idUnidad, nivel, tokenAPI, tokenAPIGraphSites]);

    //CONSULTAS PERFILAMIENTO
    async function verificarAdmin() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetAdmin`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsAdmin(result.ok);
            });
    }

    async function verificarUsuario() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetUsuario`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsUsuario(result.ok);
            });
    }

    async function verificarGestion() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetGestion`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsGestion(result.ok);
            });
    }

    async function verificarValidador() {
        fetchEngine
            .getAPI(`/api/Perfiles/GetValidador`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                setEsValidador(result.ok);
            });
    }

    const _onFormatDate = (date: Date | undefined): string => {
        if (date != undefined) {
            return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
        } else {
            return "01-01-0001";
        }
    };

    async function obtenerValores() {
        let userName: any;
        let newDate = new Date();
        let mesActual = newDate.getMonth() + 1;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .getAPI(`/api/Staffing/ObtenerConsultores/${consultor != "" ? consultor : "0"}/${nivel != "" ? nivel : "0"}/${idUnidad}/${mesActual}`, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                setLoaderTabla(true);
                if (resultado.ok) {
                    setLoaderTabla(false);
                    const opo: any[] = [];
                    const proyect: any[] = [];
                    const proyect2: any[] = [];
                    const vacaciones: any[] = [];
                    const licencias: any[] = [];
                    if (resultado.data) {
                        resultado.data.oportunidades.map((a: any) => {
                            opo.push({
                                key: a.ID,
                                text: a.NOMBRE,
                                estado: a.ID_ESTADO,
                            });
                        });
                        resultado.data.proyectos.map((a: any) => {
                            proyect.push({
                                COLAPSABLE: a.COLAPSABLE != undefined && a.COLAPSABLE != null ? !a.COLAPSABLE : true,
                                ES_RESERVA: a.ES_RESERVA,
                                ID: a.ID > 0 ? a.ID : 0,
                                ID_CONSULTOR: a.ID_CONSULTOR > 0 ? a.ID_CONSULTOR : 0,
                                ID_OPORTUNIDAD: a.ID_OPORTUNIDAD > 0 ? a.ID_OPORTUNIDAD : 0,
                                ID_PROYECTO: a.ID_PROYECTO > 0 ? a.ID_PROYECTO : 0,
                                NOMBRE_OPORTUNIDAD: a.NOMBRE_OPORTUNIDAD != "" ? a.NOMBRE_OPORTUNIDAD : "",
                                NOMBRE_PROYECTO: a.NOMBRE_PROYECTO != "" ? a.NOMBRE_PROYECTO : "",
                            });
                        });
                        resultado.data.proyectos2.map((a: any) => {
                            proyect2.push({
                                COLAPSABLE: a.COLAPSABLE != undefined && a.COLAPSABLE != null ? !a.COLAPSABLE : true,
                                ES_RESERVA: a.ES_RESERVA,
                                ID: a.ID > 0 ? a.ID : 0,
                                ID_CONSULTOR: a.ID_CONSULTOR > 0 ? a.ID_CONSULTOR : 0,
                                ID_OPORTUNIDAD: a.ID_OPORTUNIDAD > 0 ? a.ID_OPORTUNIDAD : 0,
                                ID_PROYECTO: a.ID_PROYECTO > 0 ? a.ID_PROYECTO : 0,
                                NOMBRE_OPORTUNIDAD: a.NOMBRE_OPORTUNIDAD != "" ? a.NOMBRE_OPORTUNIDAD : "",
                                NOMBRE_PROYECTO: a.NOMBRE_PROYECTO != "" ? a.NOMBRE_PROYECTO : "",
                            });
                        });
                        resultado.data.vacaciones.map((a: any) => {
                            vacaciones.push({
                                FECHA_DESDE: a.FECHA_DESDE,
                                FECHA_HASTA: a.FECHA_HASTA,
                                ID: a.ID > 0 ? a.ID : 0,
                                ID_USUARIO: a.ID_USUARIO > 0 ? a.ID_USUARIO : 0,
                                ID_VACACION_BUK: a.ID_VACACION_BUK > 0 ? a.ID_VACACION_BUK : 0,
                                ID_MES: a.ID_MES > 0 ? a.ID_MES : 0,
                                MES_STAFFING: a.MES_STAFFING > 0 ? a.MES_STAFFING : 0,
                                ARRAY_SEMANAS: a.ARRAY_SEMANAS !== undefined ? a.ARRAY_SEMANAS : undefined,
                                COLAPSABLE: true,
                            });
                        });
                        resultado.data.licencias.map((a: any) => {
                            licencias.push({
                                FECHA_DESDE: a.FECHA_DESDE != undefined && null ? a.FECHA_DESDE : undefined,
                                FECHA_HASTA: a.FECHA_HASTA != undefined && null ? a.FECHA_HASTA : undefined,
                                ID: a.ID > 0 ? a.ID : 0,
                                ID_USUARIO: a.ID_USUARIO > 0 ? a.ID_USUARIO : 0,
                                ID_LICENCIA_BUK: a.ID_LICENCIA_BUK > 0 ? a.ID_LICENCIA_BUK : 0,
                                ID_MES: a.ID_MES > 0 ? a.ID_MES : 0,
                                MES_STAFFING: a.MES_STAFFING > 0 ? a.MES_STAFFING : 0,
                                ARRAY_SEMANAS: a.ARRAY_SEMANAS !== undefined ? a.ARRAY_SEMANAS : undefined,
                                COLAPSABLE: true,
                            });
                        });

                        setConsultoresList(resultado.data.consultores);
                        setProyectosList(proyect);
                        setProyectosList2(proyect2);
                        setOpcionesOportunidad(opo);
                        setPorcentajesList(resultado.data.porcentajes);
                        setPorcentajesList2(resultado.data.porcentajes2);
                        setResumenList(resultado.data.resumen);
                        setVacacionesList(vacaciones);
                        setLicenciasList(licencias);
                    }
                } else {
                    console.log(resultado);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    async function obtenerValoresCbx() {
        fetchEngine
            .getAPI("/api/DatosFormulario", {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                const unn: any[] = [];
                const ctto: any[] = [];
                if (result.ok) {
                    result.data.unidades_negocios.map((a: any) => {
                        unn.push({
                            key: a.ID,
                            text: a.TITULO,
                        });
                    });
                    setOpcionesUnidad(unn);
                } else {
                    console.log(result);
                }
            });
    }

    async function guardarReserva() {
        let st = stInfo;
        fetchEngine
            .postAPI("/api/staffing", st, {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((resultado: any) => {
                setCargarReserva(false);
                if (resultado.ok) {
                    setCargarReserva(true);
                    setOkReserva(false);
                    setDialogoCrearReserva(true);

                    obtenerValores();

                    st.ID = 0;
                    st.ID_CONSULTOR = 0;
                    st.ID_PROYECTO = 0;
                    st.ID_OPORTUNIDAD = 0;
                    st.CONSULTOR_LOGIN = "";
                    st.CONSULTOR_DISPLAY = "";
                    st.FECHA_DESDE = new Date(Date.now());
                    st.FECHA_HASTA = new Date(Date.now());
                    st.ARRAY_RESERVA = [];
                    setStInfo(st);
                    setArrayReserva2(true);
                } else {
                    setErrorReserva(false);
                    setCargarReserva(true);
                    setOkReserva(true);
                    setArrayReserva2(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function getISOWeeks(y: number) {
        var d, isLeap;

        d = new Date(y, 0, 1);
        isLeap = new Date(y, 1, 29).getMonth() === 1;

        //check for a Jan 1 that's a Thursday or a leap year that has a
        //Wednesday jan 1. Otherwise it's 52
        return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52;
    }

    function contadorSemanas(anio: number, mes: number) {
        var fecha_actual;
        fecha_actual = new Date(anio, 0, 1);

        let total_weeks = getISOWeeks(anio);

        while (fecha_actual.toLocaleDateString("es-ES", { weekday: "long" }) !== "lunes") {
            fecha_actual.setDate(fecha_actual.getDate() + 1);
        }

        let array_fechas = [];
        let contador = 0;

        array_fechas.push(fecha_actual);

        for (let index = 1; index < total_weeks; index++) {
            fecha_actual = moment(moment(fecha_actual).add(7, "d")).toDate();
            array_fechas.push(fecha_actual);
        }

        array_fechas.map((e: any) => {
            if (e.getMonth() === mes) {
                contador = contador + 1;
            }
        });

        return contador;
    }

    async function eliminarReserva(idEliminarReserva: any) {
        fetchEngine
            .postAPI(`/api/staffing/EliminarReserva`, parseInt(idEliminarReserva), {
                "Content-Type": "application/json",
                Authorization: `Bearer ${tokenAPI}`,
            })
            .then((result: any) => {
                if (result.ok) {
                    obtenerValores();
                } else {
                    console.log(result);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    function callbackEliminarReserva(idEliminarReservaCall: number) {
        eliminarReserva(idEliminarReservaCall);
    }

    const DayPickerStrings: IDatePickerStrings = {
        months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

        shortMonths: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],

        days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],

        shortDays: ["D", "L", "M", "M", "J", "V", "S"],

        goToToday: "Ir a hoy",
        prevMonthAriaLabel: "Ir al mes previo",
        nextMonthAriaLabel: "Ir al mes siguiente",
        prevYearAriaLabel: "Ir al año anterior",
        nextYearAriaLabel: "Ir al año siguiente",
        closeButtonAriaLabel: "Cerrar el selector de fechas",
    };

    const controlClass = mergeStyleSets({
        control: {
            margin: "0 0 15px 0",
            maxWidth: "300px",
        },
    });

    const textFieldOnChange = (ev: any) => {
        let key = ev.target.id;
        let value = ev.target.value;
        let st = stInfo;
        let consultorValue = "";
        let nivelValue = "";
        switch (key) {
            case "txtConsultor":
                consultorValue = value;
                setConsultor(consultorValue);
                break;
            case "txtNivel":
                nivelValue = value;
                setNivel(nivelValue);
                break;
        }
        setStInfo(st);
    };

    const _onChangeOportunidad = (event: any, item: any): void => {
        setEstadoFormulario(true);
        let st = stInfo;
        st.ID_OPORTUNIDAD = item.key;
        setStInfo(st);
    };

    const _onChangeUnidad = (event: any, item: any): void => {
        setEstadoFormulario(true);
        let unidad;
        unidad = item.key;

        setIdUnidad(unidad);
    };

    const _onSelectDateFechaDesde = (date: Date | null | undefined): void => {
        let st = stInfo;
        setEstadoFormulario(true);
        if (date != null) {
            if (st.FECHA_HASTA !== undefined) {
                if (date > st.FECHA_HASTA) {
                    setValidacionFechas(false);
                } else {
                    setValidacionFechas(true);
                }
            }
            st.FECHA_DESDE = date;
        }
        setStInfo(st);
        calendarioReserva();
    };

    const _onSelectDateFechaHasta = (date: Date | null | undefined): void => {
        let st = stInfo;
        setEstadoFormulario(true);
        if (date != null) {
            if (st.FECHA_DESDE !== undefined) {
                if (date < st.FECHA_DESDE) {
                    setValidacionFechas(false);
                } else {
                    setValidacionFechas(true);
                }
            }
            st.FECHA_HASTA = date;
        }
        setStInfo(st);
        calendarioReserva();
    };

    const userHandler = (tipo: string, value: any, display: string) => {
        let st = stInfo;
        let consultores = consultoresList;

        if (tipo === "consultor") {
            st.CONSULTOR_DISPLAY = value[0].name;
            st.CONSULTOR_LOGIN = value[0].mail;

            setStInfo(st);
        }

        consultores.map((e) => {
            if (e.CONSULTOR_LOGIN.toLowerCase() === st.CONSULTOR_LOGIN.toLowerCase()) {
                st.ID_CONSULTOR = e.ID;
            }
        });

        setStInfo(st);
    };

    function validarAgregarProyecto() {
        let st = stInfo;
        let array_reserva_l = arrayReserva !== undefined ? arrayReserva.length : [];
        if (
            st.ID_OPORTUNIDAD === undefined ||
            st.ID_OPORTUNIDAD === null ||
            st.ID_OPORTUNIDAD <= 0 ||
            st.CONSULTOR_DISPLAY === undefined ||
            st.CONSULTOR_DISPLAY === "" ||
            st.CONSULTOR_DISPLAY === null ||
            st.CONSULTOR_LOGIN === undefined ||
            st.CONSULTOR_LOGIN === "" ||
            st.CONSULTOR_LOGIN === null ||
            st.FECHA_DESDE === undefined ||
            st.FECHA_HASTA === undefined ||
            array_reserva_l <= 0
        ) {
            setValidacionCrearReserva(false);
        } else {
            st.ARRAY_RESERVA = arrayReserva;
            setStInfo(st);
            setValidacionCrearReserva(true);
            guardarReserva();
        }
    }

    function borrarRegistroReserva() {
        let st = stInfo;

        st.ID = 0;
        st.ID_CONSULTOR = 0;
        st.ID_PROYECTO = 0;
        st.ID_OPORTUNIDAD = 0;
        st.CONSULTOR_LOGIN = "";
        st.CONSULTOR_DISPLAY = "";
        st.FECHA_DESDE = new Date(Date.now());
        st.FECHA_HASTA = new Date(Date.now());

        setStInfo(st);
        setDialogoCrearReserva(true);
    }

    function limpiarFiltros() {
        setConsultor("");
        setNivel("");
        setIdUnidad(0);
    }

    function calendarioReserva() {
        let st = stInfo;
        let yearinicio = new Date(st.FECHA_DESDE !== undefined ? st.FECHA_DESDE : "").getFullYear();
        let yearfin = new Date(st.FECHA_HASTA !== undefined ? st.FECHA_HASTA : "").getFullYear();

        let iniciomes = new Date(st.FECHA_DESDE !== undefined ? st.FECHA_DESDE : "").getMonth() + 1;
        let finmes = new Date(st.FECHA_HASTA !== undefined ? st.FECHA_HASTA : "").getMonth() + 1;

        let totalyear = yearfin - yearinicio + 1;
        let years = yearinicio;
        let arrayyear = [];

        for (let i = 0; i < totalyear; i++) {
            arrayyear.push({
                years: years + i,
                inicio_year: yearinicio,
                fin_year: yearfin,
                inicio_mes: iniciomes,
                fin_mes: finmes,
            });
        }

        setFirstYear(yearinicio);
        setLastYear(yearfin);
        setTotalYear(arrayyear);
    }

    function agregarReservaArray(semana: number, anio: number, mes: number, hora: any) {
        let flag = false;
        let consultor = stInfo.CONSULTOR_LOGIN;
        let horaMax = 0;
        let porcentaje = 0;

        consultoresList.forEach((c: any) => {
            if (c.CONSULTOR_LOGIN.toLowerCase() === consultor.toLowerCase()) {
                horaMax = c.HORA_MAX;
            }
        });

        if (hora === 0) {
            porcentaje = 0;
        } else {
            porcentaje = parseInt(((hora * 100) / horaMax).toFixed(0));
        }

        if (arrayReserva.length <= 0) {
            arrayReserva.push({
                ID: 0,
                ID_MES: mes,
                SEMANA: semana,
                HORAS: hora,
                PORCENTAJE: porcentaje,
                ANIO: anio,
            });
        } else {
            //modifica
            arrayReserva.forEach((e: any) => {
                if (e.SEMANA === semana && e.ANIO === anio && e.ID_MES === mes) {
                    e.HORAS = hora;
                    e.PORCENTAJE = porcentaje;
                }
            });

            //agrega
            for (let index = 0; index < arrayReserva.length; index++) {
                const e = arrayReserva[index];
                if (e.SEMANA === semana && e.ANIO === anio && e.ID_MES === mes) {
                    flag = false;
                    break;
                } else {
                    flag = true;
                }
            }
        }
        if (flag === true) {
            arrayReserva.push({
                ID: 0,
                ID_MES: mes,
                SEMANA: semana,
                HORAS: hora,
                PORCENTAJE: porcentaje,
                ANIO: anio,
            });
        }
    }

    function cambio(e: any) {
        e.value = 0;
    }

    /**
     * Almacena en un array la cantidad de horas a reservar en un proyecto de un determinado usuario
     *
     * @param {Number} anio - full year (YYYY)
     * @param {Number} mes - month_number is in the range 1..12
     * @param {any} element - detalles del input que accionó el evento
     * @param {Number} semana - semana en el rango de 1 .. X
     * @returns {Array} Contiene semana, mes, año, proyecto y consultor
     */
    function onReservaChange(anio: number, mes: number, element: any, semana: number) {
        let hora = 0;
        if (element.target === undefined) {
            hora = 0;
        } else {
            hora = parseInt(element.target.value); // valor del input
        }

        if (isNaN(hora)) {
            hora = 0;
        }
        switch (mes) {
            case 1:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 2:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 3:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 4:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 5:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 6:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 7:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 8:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 9:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 10:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 11:
                agregarReservaArray(semana, anio, mes, hora);
                break;
            case 12:
                agregarReservaArray(semana, anio, mes, hora);
                break;
        }
    }

    function cambioProyecto() {
        setArrayReserva2(false);
        validarAgregarProyecto();
    }

    if (esAdmin || esGestion || esValidador) {
        return (
            <React.Fragment>
                <BotonAyuda token={tokenAPI} />
                <div className="fuente">
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg6">
                                <div className="iconoRuta">
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Inicio</span>
                                    &nbsp;
                                    <Glyphicon glyph="glyphicon glyphicon-play" /> <span id="txtRuta">Staffing</span>
                                </div>
                            </div>
                            <br />
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg9">
                                    <div className="titulosimulador">
                                        <Glyphicon glyph="user" /> Staffing
                                    </div>
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-sm12 ms-lg12 d-flex justify-content-end">
                                    <div className="flotante">
                                        <PrimaryButton
                                            text="Crear Reserva"
                                            className="btnCrearReserva"
                                            onClick={(e) => {
                                                setDialogoCrearReserva(false);
                                                calendarioReserva();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                    <TextField
                                        label="Buscar Consultor"
                                        id="txtConsultor"
                                        onChange={(e) => {
                                            textFieldOnChange(e);
                                        }}
                                        placeholder="Nombre Consultor"
                                        value={consultor}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                    <ComboBox
                                        label="Buscar Unidad"
                                        id="cbxUnidad"
                                        placeholder="Seleccione..."
                                        selectedKey={idUnidad}
                                        onChange={_onChangeUnidad}
                                        options={opcionesUnidad}
                                        autoComplete="on"
                                        allowFreeform={true}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2">
                                    <div className="enLinea">
                                        <TextField
                                            label="Buscar Nivel"
                                            id="txtNivel"
                                            onChange={(e) => {
                                                textFieldOnChange(e);
                                            }}
                                            placeholder="Nombre Nivel"
                                            value={nivel}
                                        />
                                    </div>
                                    <div className="filtro">
                                        <button className="fondoFiltro" onClick={() => limpiarFiltros()}>
                                            <i className="ms-Icon ms-Icon--ClearFilter" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 d-flex justify-content-end mt-4">
                                    <div id="squares" className="estadosStaffing bg-light  shadow-sm">
                                        <label title="El registro es de Vacaciones">
                                            <div
                                                id="squareBlanco"
                                                className="rounded-circle border border-info"
                                                title="El registro es de Vacaciones"></div>
                                            <small className="block">Vacaciones/Licencia</small>
                                        </label>
                                        <label title="El registro es una Oportunidad">
                                            <div id="squareMorado" className="rounded-circle " title="El registro es una Oportunidad"></div>
                                            <small className="block">&nbsp;Oportunidad</small>
                                        </label>
                                        <label title="El registro es un Proyecto">
                                            <div id="squareCeleste" className="rounded-circle" title="El registro es un Proyecto"></div>
                                            &nbsp;<small className="block">Proyecto</small>
                                        </label>
                                        <label title="El % de Resumen es menor al mínimo agendado">
                                            <div
                                                id="squareRojo"
                                                className="rounded-circle"
                                                title="El % del Resumen es menor al mínimo agendado"></div>
                                            &nbsp;
                                            <small className="block">&lt;90% agendado</small>
                                        </label>
                                        <label title="El % de Resumen está entre el mínimo y menos que el 100% agendado">
                                            <div
                                                id="squareAmarillo"
                                                className="rounded-circle"
                                                title="El % de Resumen está entre el mínimo y menos que el 100% agendado"></div>
                                            &nbsp;
                                            <small className="block">&gt;=90% agendado</small>
                                        </label>
                                        <label title="El % de Resumen está 100% agendado">
                                            <div id="squareVerde" className="rounded-circle" title="El % del Resumen está 100% agendado"></div>
                                            &nbsp;<small className="block">Agendado</small>
                                        </label>
                                        <label title="El % de Resumen es mayor al 100% agendado">
                                            <div
                                                id="squareAmarilloDos"
                                                className="rounded-circle"
                                                title="El % del Resumen es mayor al 100% agendado"></div>
                                            &nbsp;
                                            <small className="block">&#62;100% agendado</small>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="ms-Grid-row">
                                <div className="acordeon">
                                    {loaderTabla === true ? (
                                        <div>
                                            <div className="spinner">
                                                <Spinner mnj="" />
                                                <br />
                                            </div>
                                            <div className="spinner">
                                                <p>Cargando personal...</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <AcordeonProyectos
                                            proyectosList={proyectosList}
                                            proyectosList2={proyectosList2}
                                            consultoresList={consultoresList}
                                            porcentajesList={porcentajesList}
                                            porcentajesList2={porcentajesList2}
                                            resumenList={resumenList}
                                            valorTest={valorTest}
                                            collapseResumen={collapseResumen}
                                            callbackEliminarReserva={callbackEliminarReserva}
                                            vacacionesList={vacacionesList}
                                            licenciasList={licenciasList}
                                        />
                                    )}
                                </div>
                            </div>

                            <br />
                            <br />
                            <div className="ms-Grid-row" id="rowSquares">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 d-flex justify-content-end">
                                    <div id="squares" className="estadosStaffing bg-light  shadow-sm">
                                        <label title="El registro es de Vacaciones">
                                            <div
                                                id="squareBlanco"
                                                className="rounded-circle border border-info"
                                                title="El registro es de Vacaciones"></div>
                                            <small className="block">Vacaciones/Licencia</small>
                                        </label>
                                        <label title="El registro es una Oportunidad">
                                            <div id="squareMorado" className="rounded-circle " title="El registro es una Oportunidad"></div>
                                            <small className="block">&nbsp;Oportunidad</small>
                                        </label>
                                        <label title="El registro es un Proyecto">
                                            <div id="squareCeleste" className="rounded-circle" title="El registro es un Proyecto"></div>
                                            &nbsp;<small className="block">Proyecto</small>
                                        </label>
                                        <label title="El % de Resumen es menor al mínimo agendado">
                                            <div
                                                id="squareRojo"
                                                className="rounded-circle"
                                                title="El % del Resumen es menor al mínimo agendado"></div>
                                            &nbsp;
                                            <small className="block">&lt;90% agendado</small>
                                        </label>
                                        <label title="El % de Resumen está entre el mínimo y menos que el 100% agendado">
                                            <div
                                                id="squareAmarillo"
                                                className="rounded-circle"
                                                title="El % de Resumen está entre el mínimo y menos que el 100% agendado"></div>
                                            &nbsp;
                                            <small className="block">&gt;=90% agendado</small>
                                        </label>
                                        <label title="El % de Resumen está 100% agendado">
                                            <div id="squareVerde" className="rounded-circle" title="El % del Resumen está 100% agendado"></div>
                                            &nbsp;<small className="block">Agendado</small>
                                        </label>
                                        <label title="El % de Resumen es mayor al 100% agendado">
                                            <div
                                                id="squareAmarilloDos"
                                                className="rounded-circle"
                                                title="El % del Resumen es mayor al 100% agendado"></div>
                                            &nbsp;
                                            <small className="block">&#62;100% agendado</small>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {arrayReserva2 === false && (
                        <Dialog
                            hidden={dialogoCrearReserva}
                            dialogContentProps={{
                                type: DialogType.largeHeader,
                                title: "Crear Reserva",
                                subText: "Complete los datos para reservar una oportunidad a un Consultor.",
                            }}
                            modalProps={{
                                styles: {
                                    main: [
                                        {
                                            selectors: {
                                                ["@media (min-width: 480px)"]: {
                                                    maxWidth: "710px",
                                                    minWidth: "0px",
                                                },
                                            },
                                        },
                                    ],
                                },
                            }}>
                            <Label>
                                Consultor <span className="obligatorio">*</span>
                            </Label>
                            <AutoCompleteMantenedor
                                id="consultor"
                                callback={userHandler}
                                multi={false}
                                disabled={consultorDisabled != undefined ? consultorDisabled : false}
                                defaultValue={{
                                    name: stInfo.CONSULTOR_DISPLAY,
                                    mail: stInfo.CONSULTOR_LOGIN,
                                }}
                                tokenAPI={tokenAPI}
                                tokenAPIGraphSites={tokenAPIGraphSites}
                            />
                            <ComboBox
                                label="Oportunidad"
                                placeholder=" Seleccione..."
                                id={"cbxProyecto"}
                                onChange={_onChangeOportunidad}
                                options={opcionesOportunidad}
                                autoComplete="on"
                                dropdownMaxWidth={200}
                                required={true}
                                allowFreeform={true}
                            />
                            <DatePicker
                                id={"fechaDesde"}
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                allowTextInput={true}
                                placeholder="Desde"
                                label="Fecha desde (mes)"
                                ariaLabel="Seleccione una fecha"
                                formatDate={_onFormatDate}
                                value={stInfo.FECHA_DESDE}
                                onSelectDate={_onSelectDateFechaDesde}
                                isRequired={true}
                            />
                            <DatePicker
                                className={controlClass.control}
                                firstDayOfWeek={DayOfWeek.Monday}
                                strings={DayPickerStrings}
                                placeholder="Hasta"
                                label="Fecha hasta (mes)"
                                id={"fechaHasta"}
                                ariaLabel="Seleccione una fecha"
                                formatDate={_onFormatDate}
                                value={stInfo.FECHA_HASTA}
                                onSelectDate={_onSelectDateFechaHasta}
                                isRequired={true}
                            />

                            {validacionFechas === true ? (
                                ""
                            ) : (
                                <label style={{ color: "red" }}>La fecha de inicio no puede ser superior a su término.</label>
                            )}

                            {totalYear !== undefined
                                ? totalYear.map((a: any) => {
                                      let arr_months2 = [];
                                      let arr_months: any[] = [];
                                      let indiceSemana = 1;

                                      for (let indexA = 0; indexA <= 11; indexA++) {
                                          for (let indexB = 1; indexB <= contadorSemanas(a.years, indexA); indexB++) {
                                              arr_months2.push({
                                                  id: indiceSemana,
                                                  value: -1,
                                              });

                                              indiceSemana = indiceSemana + 1;
                                          }
                                          arr_months.push(arr_months2);
                                          arr_months2 = [];
                                      }

                                      return (
                                          <div>
                                              <h6>
                                                  <span className="badge badge-primary numAno">{a.years}</span>
                                              </h6>
                                              <br />
                                              <div className="ms-Grid-row bloqueActividad mt-3" id="bloqueActividad">
                                                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2">
                                                      <div className="table-responsive">
                                                          <table className="table table-sm tablaServicioDirecto">
                                                              <thead>
                                                                  <tr>
                                                                      {a.years === a.inicio_year && a.inicio_mes > 1 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[0].length}>
                                                                                  <span className="badge badge-primary">Enero</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[1].length}>
                                                                                  <span className="badge badge-primary">Febrero</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[2].length}>
                                                                                  <span className="badge badge-primary">Marzo</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[3].length}>
                                                                                  <span className="badge badge-primary">Abril</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[4].length}>
                                                                                  <span className="badge badge-primary">Mayo</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[5].length}>
                                                                                  <span className="badge badge-primary">Junio</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[6].length}>
                                                                                  <span className="badge badge-primary">Julio</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[7].length}>
                                                                                  <span className="badge badge-primary">Agosto</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[8].length}>
                                                                                  <span className="badge badge-primary">Septiembre</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[9].length}>
                                                                                  <span className="badge badge-primary">Octubre</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[10].length}>
                                                                                  <span className="badge badge-primary">Noviembre</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}

                                                                      {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                          ""
                                                                      ) : (
                                                                          <React.Fragment>
                                                                              <td colSpan={arr_months[11].length}>
                                                                                  <span className="badge badge-primary">Diciembre</span>
                                                                              </td>
                                                                              <td></td>
                                                                          </React.Fragment>
                                                                      )}
                                                                  </tr>
                                                              </thead>
                                                              <tbody>
                                                                  <tr className="numeroSemanas">
                                                                      {arr_months[0].map((e: any) => {
                                                                          if ((a.years === a.inicio_year && a.inicio_mes > 1) === false)
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 1 ? "" : <td></td>}

                                                                      {arr_months[1].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 2) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 2) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[2].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 3) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 3) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[3].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 4) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 4) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[4].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 5) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 5) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[5].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 6) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 6) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[6].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 7) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 7) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[7].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 8) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 8) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[8].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 9) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 9) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[9].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 10) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 10) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[10].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 11) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 11) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[11].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 12) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 12) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>{e.id}</td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}
                                                                  </tr>
                                                                  <tr>
                                                                      {arr_months[0].map((e: any) => {
                                                                          if ((a.years === a.inicio_year && a.inicio_mes > 1) === false)
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 1, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              id={"ENE-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 1, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 1 ? "" : <td></td>}

                                                                      {arr_months[1].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 2) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 2) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 2, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"FEB-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 2, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[2].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 3) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 3) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 3, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"MAR-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 3, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[3].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 4) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 4) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 4, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"ABR-SEM" + e + "-" + a.years}
                                                                                              defaultValue={"0"}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 4, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[4].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 5) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 5) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 5, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"MAY-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 5, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[5].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 6) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 6) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 6, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"JUN-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 6, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[6].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 7) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 7) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 7, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"JUL-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 7, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[7].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 8) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 8) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 8, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"AGO-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 8, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[8].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 9) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 9) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 9, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"SEP-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 9, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[9].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 10) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 10) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 10, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"OCT-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 10, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[10].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 11) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 11) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 11, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"NOV-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 11, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}

                                                                      {arr_months[11].map((e: any) => {
                                                                          if (a.years === a.inicio_year && a.inicio_mes > 12) {
                                                                              return "";
                                                                          } else if (a.years === a.fin_year && a.fin_mes < 12) {
                                                                              return "";
                                                                          } else {
                                                                              return (
                                                                                  <React.Fragment>
                                                                                      <td>
                                                                                          {e.value < 0 ? onReservaChange(a.years, 12, 0, e.id) : ""}
                                                                                          {e.value < 0 ? cambio(e) : ""}
                                                                                          <TextField
                                                                                              label=""
                                                                                              id={"DIC-SEM" + e + "-" + a.years}
                                                                                              onChange={(element) => {
                                                                                                  onReservaChange(a.years, 12, element, e.id);
                                                                                              }}
                                                                                              placeholder={"..."}
                                                                                              type={"number"}
                                                                                          />
                                                                                      </td>
                                                                                  </React.Fragment>
                                                                              );
                                                                          }
                                                                      })}
                                                                      {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                          ""
                                                                      ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                          ""
                                                                      ) : (
                                                                          <td></td>
                                                                      )}
                                                                  </tr>
                                                              </tbody>
                                                          </table>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      );
                                  })
                                : ""}

                            <DialogFooter>
                                <PrimaryButton
                                    text="Crear"
                                    disabled={validacionFechas === false ? true : false}
                                    onClick={() => {
                                        cambioProyecto();
                                    }}></PrimaryButton>
                                <DefaultButton text="Cerrar" onClick={() => borrarRegistroReserva()}></DefaultButton>
                            </DialogFooter>
                        </Dialog>
                    )}
                    <Dialog
                        hidden={validacionCrearReserva}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: "Error al crear una reserva",
                            subText: `Debe completar todos los datos para crear una reserva.`,
                        }}
                        modalProps={{
                            styles: { main: { maxWidth: 450 } },
                        }}>
                        <DialogFooter>
                            <PrimaryButton
                                text="Cerrar"
                                onClick={() => {
                                    setValidacionCrearReserva(true);
                                }}></PrimaryButton>
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        hidden={cargarReserva}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: "Creando reserva",
                            subText: "Espere...",
                        }}
                        modalProps={{
                            styles: { main: { maxWidth: 450 } },
                        }}>
                        <div className="spinner">
                            <Spinner mnj="" />
                        </div>
                    </Dialog>
                    <Dialog
                        hidden={errorReserva}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: "Hubo un error al crear la Reserva",
                            subText: "Favor informar a un administrador e intentar nuevamente.",
                        }}
                        modalProps={{
                            styles: { main: { maxWidth: 350 } },
                        }}>
                        <DialogFooter>
                            <PrimaryButton
                                text="Cerrar"
                                onClick={() => {
                                    setErrorReserva(true);
                                }}></PrimaryButton>
                        </DialogFooter>
                    </Dialog>
                    <Dialog
                        hidden={okReserva}
                        dialogContentProps={{
                            type: DialogType.largeHeader,
                            title: "Se ha reservado correctamente",
                        }}
                        modalProps={{
                            styles: { main: { textAlign: "center" } },
                        }}>
                        <DialogFooter>
                            <PrimaryButton
                                text="Cerrar"
                                onClick={() => {
                                    setOkReserva(true);
                                }}></PrimaryButton>
                        </DialogFooter>
                    </Dialog>
                </div>
            </React.Fragment>
        );
    } else if (esUsuario) {
        return (
            <React.Fragment>
                <div className="mt-4 card sinAcceso text-primary">
                    <div className="card-header">Atención</div>
                    <div className="card-body">
                        <h6>
                            <Glyphicon glyph="info-sign" className="mr-1" />
                            Usted no posee los permisos para visualizar esta información, si desea obtener los permisos, contactese con el
                            administrador del sistema.
                        </h6>
                    </div>
                </div>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <div></div>
            </React.Fragment>
        );
    }
};

export default Staffing;
