import React, { useState, useEffect } from "react";
import "react-autosuggest";
import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField } from "@fluentui/react";
import { Accordion } from "../../../controles/Accordion/Accordion";
import "../PlanificacionProyecto.css";
import { MessageBar, MessageBarType } from "@fluentui/react";
import NumberFormat from "react-number-format";
import moment from "moment";

interface Props {
    actividadesList?: Ingreso[];
    listadoOpciones?: any[];
    listadoRoles_Plan: any[]; // mejorable
    listadoOpcionesUnidad?: any[]; // mejorable
    moneda?: string; // se puede juntar en objeto
    ingresoP: IngresoDirectoData[]; // mejorable
    callback: any; // mejorable
    callbackActividades: any;
    total_year?: any[]; // se puede juntar en objeto
    arrayReserva?: any[]; // mejorable
    consultoresList?: any; // mejorable
    callbackReserva: any;
    estado_modulo: number; // se puede juntar en objeto
    listadoCargos: any[]; // mejorable
    id_unidad: number; // mejorable
    id_planificacion: number; // se puede juntar en objeto
    listadoConsultores_itfc?: Consultores[]; // mejorable
    cantidad_semanas: number | undefined;
    callback_update_calendario: boolean;
}

interface Consultores {
    key: number;
    text: string;
}

interface Ingreso {
    ID_MODULO: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    INDEX: number;
    NOMBRE: string;
    TOTAL_HORAS: number;
    TARIFA_HH: number;
    COSTO_HH: number;
    TOTAL_INGRESOS: number;
    TOTAL_COSTO: number;
    TOTAL_RENTABILIDAD: number;
}

interface IngresoDirectoData {
    ID: number;
    ID_PLANIFICACION: number;
    ID_ACTIVIDAD: number;
    ID_UNIDAD: number;
    ID_CARGO: number;
    ID_CARGO_PLAN: number;
    ID_PERSONA: number;
    TIEMPO_INVERTIR: number;
    SEMANAS: number;
    HORAS: number;
    COSTO_HH: number;
    TOTAL_COSTO: number;
    ID_TEMPORAL: number;
    TOTAL_HORAS: number;
}

interface ArrayReserva {
    ID: number;
    ID_MES: number;
    ID_INGRESOS_DIRECTOS: number;
    SEMANA: number;
    HORAS: number;
    PORCENTAJE: number;
    ANIO: number;
    ID_TEMPORAL: number;
    FECHA_RESERVA?: Date;
}

const IngresosDirectosPlanificacion = ({
    actividadesList,
    listadoOpciones,
    listadoRoles_Plan,
    listadoOpcionesUnidad,
    moneda,
    ingresoP,
    callback,
    callbackActividades,
    total_year,
    arrayReserva,
    consultoresList,
    callbackReserva,
    estado_modulo,
    listadoCargos,
    id_unidad,
    id_planificacion,
    listadoConsultores_itfc,
    cantidad_semanas,
    callback_update_calendario,
}: Props) => {
    if (arrayReserva != undefined) {
        ingresoP.map((i: IngresoDirectoData, ing: number) => {
            i.TOTAL_HORAS = arrayReserva.filter((e: any) => e.ID_INGRESOS_DIRECTOS === i.ID).reduce((a, b) => a + b.HORAS, 0);
        });
    }

    const [actividades, setActividades] = useState(actividadesList);
    const [ingresos, setIngreso] = useState(ingresoP);
    const [arrReserva, setArrReserva] = useState(arrayReserva);

    const [nombreActividad, setNombreActividad] = useState("");
    const [horas, setHoras] = useState(0);
    const [costoHH, setCostoHH] = useState(0);
    const [totalCostos, setTotalCostos] = useState(0);

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogSubText, setDialogSubText] = useState("");

    const [indexActividad, setIndexActividad] = useState(-1);
    const [indexIngreso, setIndexIngreso] = useState(-1);
    const [idIngreso, setIdIngreso] = useState(-1);
    const [listadoConsultores, setListadoConsultores] = useState(listadoConsultores_itfc);

    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
    };

    const addActividad = () => {
        let actividad = actividades;
        let nuevoID = 0;

        if (actividad != undefined) {
            actividad.map((a: any) => {
                nuevoID = a.ID_ACTIVIDAD;
            });
        }

        if (nombreActividad != "") {
            if (actividad != undefined) {
                actividad.push({
                    ID_MODULO: 1,
                    ID_SIMULACION: 0,
                    ID_ACTIVIDAD: nuevoID + 1,
                    INDEX: actividad.length,
                    NOMBRE: nombreActividad,
                    COSTO_HH: 0,
                    TOTAL_COSTO: 0,
                    TARIFA_HH: 0,
                    TOTAL_HORAS: 0,
                    TOTAL_INGRESOS: 0,
                    TOTAL_RENTABILIDAD: 0,
                });
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        } else {
            //errorActividad
        }
        setNombreActividad("");
    };

    const addRol = (actividadID: number, cant_ingresos: number) => {
        let ingreso = ingresos;
        if (ingreso != undefined) {
            ingreso.push({
                ID: cant_ingresos + 1,
                ID_PERSONA: 0,
                ID_ACTIVIDAD: actividadID,
                COSTO_HH: 0,
                HORAS: 0,
                ID_CARGO: 0,
                ID_CARGO_PLAN: 0,
                ID_UNIDAD: 0,
                ID_PLANIFICACION: 0,
                SEMANAS: 0,
                TIEMPO_INVERTIR: 0,
                TOTAL_COSTO: 0,
                ID_TEMPORAL: cant_ingresos + 1,
                TOTAL_HORAS: 0,
            });
            setIngreso([...ingreso]);
            callback(ingreso);
        }
    };

    const deleteRol = (indexI: number, id_ingreso: number) => {
        let ingreso = ingresos;
        let arrayReservaCleaned = [];
        setIdIngreso(id_ingreso);

        if (ingreso != undefined) {
            let row = ingreso.find((ing: IngresoDirectoData, i: number) => i === indexI);
            let sumaRows = 0;
            if (row != undefined) {
                sumaRows = row.COSTO_HH + row.SEMANAS + row.TIEMPO_INVERTIR;
            }

            if (sumaRows === 0) {
                ingreso.splice(indexI, 1);
                setIngreso([...ingreso]);
                callback(ingreso);

                if (arrayReserva != undefined) {
                    arrayReservaCleaned = arrayReserva.filter((x) => {
                        return x.ID_INGRESOS_DIRECTOS != id_ingreso;
                    });
                }

                setArrReserva(arrayReservaCleaned);
                callbackReserva(arrayReservaCleaned);
                setIdIngreso(-1);
            } else {
                // alert("esta fila tiene datos!");
                setDialogTitle("Ingreso con datos!");
                setDialogSubText("Esta fila contiene datos ingresados ¿Está seguro que desea eliminar?");
                setIndexIngreso(indexI);
                setDialog(false);
            }
        }
    };

    const deleteRolIndex = () => {
        let ingreso = ingresos;
        let arrayReservaCleaned = [];

        if (ingreso != undefined) {
            ingreso.splice(indexIngreso, 1);
            setIngreso([...ingreso]);
            Actividades();
            setIndexIngreso(-1);
            setIndexActividad(-1);
            setDialog(true);
            callback(ingreso);

            ingreso!.map((a: any) => {
                calcularTotalActividad(ingreso!, a.ID_ACTIVIDAD);
            });

            if (arrayReserva != undefined) {
                arrayReservaCleaned = arrayReserva.filter((x) => {
                    return x.ID_INGRESOS_DIRECTOS != idIngreso;
                });
            }

            setArrReserva(arrayReservaCleaned);
            callbackReserva(arrayReservaCleaned);
            setIdIngreso(-1);
            calcularTotalActividad(ingreso, indexActividad);
            setIndexActividad(-1);
        }
    };

    const deleteActividad = (actividadID: number, indexA: number) => {
        let ingreso = ingresos;
        let actividad = actividades;
        let tieneIngresos = false;

        if (ingreso != undefined) {
            tieneIngresos = ingreso.some((i: IngresoDirectoData) => i.ID_ACTIVIDAD === actividadID);
        }

        if (tieneIngresos) {
            setIndexActividad(indexA);
            setDialogTitle("Actividad con roles!");
            setDialogSubText("¿Está seguro que desea eliminar la actividad?");
            setDialog(false);
        } else {
            if (actividad != undefined) {
                actividad.splice(indexA, 1);
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        }
    };

    const deleteActividadRoles = () => {
        let ingreso = ingresos;
        let ingreso2: any = [];
        let actividad = actividades;
        let actividad2: any = [];
        let actividadEliminar: Ingreso | undefined;
        let ingresosEliminados: any = [];
        let arrayReservaCleaned = [];

        if (actividad != undefined) {
            actividadEliminar = actividad.find((a, i) => i === indexActividad);

            if (ingreso != undefined) {
                if (actividadEliminar != undefined) {
                    let idActividad = actividadEliminar.ID_ACTIVIDAD;

                    ingreso.map((ing: any) => {
                        if (ing.ID_ACTIVIDAD != idActividad) {
                            ingreso2.push({
                                COSTO_HH: ing.COSTO_HH,
                                HORAS: ing.HORAS,
                                ID: ing.ID,
                                ID_ACTIVIDAD: ing.ID_ACTIVIDAD,
                                ID_CARGO: ing.ID_CARGO,
                                ID_CARGO_PLAN: ing.ID_CARGO_PLAN,
                                ID_PERSONA: ing.ID_PERSONA,
                                ID_PLANIFICACION: ing.ID_PLANIFICACION,
                                ID_TEMPORAL: ing.ID_TEMPORAL,
                                ID_UNIDAD: ing.ID_UNIDAD,
                                SEMANAS: ing.SEMANAS,
                                TIEMPO_INVERTIR: ing.TIEMPO_INVERTIR,
                                TOTAL_COSTO: ing.TOTAL_COSTO,
                                TOTAL_HORAS: ing.TOTAL_HORAS,
                            });
                        } else {
                            ingresosEliminados.push(ing.ID);
                        }
                    });

                    actividad.map((a: any) => {
                        if (a.ID_ACTIVIDAD != idActividad) {
                            actividad2.push({
                                COSTO_HH: a.COSTO_HH,
                                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                ID_MODULO: a.ID_MODULO,
                                ID_PLANIFICACION: a.ID_PLANIFICACION,
                                INDEX: a.INDEX,
                                NOMBRE: a.NOMBRE,
                                TARIFA_HH: a.TARIFA_HH,
                                TOTAL_COSTO: a.TOTAL_COSTO,
                                TOTAL_HORAS: a.TOTAL_HORAS,
                                TOTAL_INGRESO: a.TOTAL_INGRESO,
                                TOTAL_RENTABILIDAD: a.TOTAL_RENTABILIDAD,
                            });
                        }
                    });
                }

                if (arrayReserva != undefined) {
                    arrayReservaCleaned = arrayReserva.filter((item) => !ingresosEliminados.includes(item.ID_INGRESOS_DIRECTOS));
                }

                setArrReserva(arrayReservaCleaned);
                callbackReserva(arrayReservaCleaned);

                setActividades([...actividad2]);
                setIngreso([...ingreso2]);
                callback(ingreso2);
                callbackActividades(actividad2);
                setDialog(true);
                setTotalCostos(actividad2.reduce((a: any, b: any) => a + b.TOTAL_COSTO, 0));
                setCostoHH(actividad2.reduce((a: any, b: any) => a + b.COSTO_HH, 0));
                setHoras(actividad2.reduce((a: any, b: any) => a + b.TOTAL_HORAS, 0));
            }
        }
    };

    const onValueChanged = (index: number, ev: any, indexA: number, e?: string) => {
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.floatValue : ev.target.value;
        let val = 0;

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoDirectoData, ing: number) => {
                if (index === ing) {
                    switch (key) {
                        case "costoHHDirecto_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.COSTO_HH = val;
                            } else {
                                i.COSTO_HH = 0;
                            }
                            break;
                        case "tiempoInvertirDirecto_Plan":
                            val = value === "" || value === undefined ? 0 : parseFloat(value);
                            if (val <= 100 && val >= 0) {
                                i.TIEMPO_INVERTIR = val;
                            } else if (val < 0) {
                                i.TIEMPO_INVERTIR = 0;
                            }
                            //Calcular
                            break;
                        case "semanasDirecto_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.SEMANAS = val;
                            } else {
                                i.SEMANAS = 0;
                            }
                            //Calcular
                            break;
                        case "horasDirecto_Plan":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.HORAS = val;
                            } else {
                                i.HORAS = 0;
                            }
                            break;
                        case "totalCostoDirecto":
                            i.TOTAL_COSTO = parseFloat(value);
                            //Calcular
                            break;
                    }
                    let horasSemana = 0;

                    if (listadoOpcionesUnidad !== undefined) {
                        listadoOpcionesUnidad.map((e: any) => {
                            if (e.key === i.ID_UNIDAD) {
                                horasSemana = parseInt(e.hora_dia) * 5;
                            }
                        });
                    }

                    i.HORAS = parseFloat((i.SEMANAS * horasSemana * (i.TIEMPO_INVERTIR / 100)).toFixed(2));
                    i.TOTAL_COSTO = parseFloat((i.COSTO_HH * i.HORAS).toFixed(2));
                }
            });

            //Total Actividad
            calcularTotalActividad(data, indexA);

            setIngreso([...data]);
            callback(data);
        }
    };

    const calcularTotalActividad = (ingresos: IngresoDirectoData[], actividadID: number) => {
        if (ingresos !== undefined && actividades !== undefined) {
            let total_valor_act: number = 0;
            let total_horas_act: number = 0;
            let total_costo_act: number = 0;
            actividades
                .filter((actividad: Ingreso) => actividad.ID_ACTIVIDAD == actividadID && actividad.ID_MODULO == 1)
                .forEach((actividad: Ingreso) => {
                    actividad.COSTO_HH = 0;
                    actividad.TOTAL_HORAS = 0;
                    actividad.TOTAL_COSTO = 0;
                    ingresos
                        .filter((ingreso: IngresoDirectoData) => ingreso.ID_ACTIVIDAD === actividadID)
                        .forEach((ingreso: IngresoDirectoData) => {
                            ingreso.TOTAL_COSTO = ingreso.COSTO_HH * ingreso.TOTAL_HORAS;
                            actividad.COSTO_HH += ingreso.COSTO_HH;
                            actividad.TOTAL_HORAS += ingreso.TOTAL_HORAS;
                            actividad.TOTAL_COSTO += (ingreso.COSTO_HH * ingreso.TOTAL_HORAS);
                        });
                });
            actividades
                .filter((actividad: Ingreso) => actividad.ID_MODULO == 1)
                .forEach((actividad: Ingreso) => {
                    total_valor_act += actividad.COSTO_HH;
                    total_horas_act += actividad.TOTAL_HORAS;
                    total_costo_act += actividad.TOTAL_COSTO;
                });
            setHoras(total_horas_act);
            setCostoHH(total_valor_act);
            setTotalCostos(total_costo_act);
        }
    };

    useEffect(() => {
        let data2 = ingresos;
        data2!.map((a: any) => {
            calcularTotalActividad(data2!, a.ID_ACTIVIDAD);
        });
        Actividades();
    }, []);

    const _onChangeUnidad = (index: number, item: any /* , a: Ingreso, actividad: any */): void => {
        let data2 = ingresos;
        let key = item.key;

        if (data2 != undefined) {
            data2
                .filter((data: IngresoDirectoData, idx) => idx == index)
                .forEach((data: IngresoDirectoData) => {
                    data.ID_UNIDAD = key;
                    data.ID_CARGO_PLAN = 0;
                    data.ID_PERSONA = 0;
                });

            setIngreso([...data2]);
        }
    };

    const _onChangeRol = (index: number, item: any): void => {
        let data = listadoCargos;
        let key = item.key;
        let costo = item.costo;
        let ingreso = ingresos;

        if (data !== undefined) {
            setListadoConsultores([...data.filter((e: any) => e.id_cargo == key)]);
        }

        if (ingreso != undefined) {
            ingreso
                .filter((data: IngresoDirectoData, idx) => idx == index)
                .forEach((data: IngresoDirectoData) => {
                    data.ID_CARGO_PLAN = key;
                    data.ID_PERSONA = 0;
                    data.COSTO_HH = costo;
                });
        }
    };

    const _onChangePersona = (index: number, item: any): void => {
        let data = ingresos;
        let key = item.key;
        if (data !== undefined) {
            data.map((i: IngresoDirectoData, ing: number) => {
                if (index === ing) {
                    if (listadoConsultores !== undefined) {
                        listadoConsultores.map((a: any) => {
                            if (a.key === key) {
                                i.ID_PERSONA = key;
                            }
                        });
                    }
                }
            });
            setIngreso([...data]);
        }
    };

    const valueWeek = (year: number, month_number: number, semana: number, id_ingreso_directo: number) => {
        let value = -1;
        let flag = false;
        if (arrayReserva !== undefined) {
            arrayReserva.forEach((e: ArrayReserva) => {
                if (e.ANIO === year && e.ID_MES === month_number + 1 && e.SEMANA === semana && e.ID_INGRESOS_DIRECTOS === id_ingreso_directo) {
                    value = e.HORAS;
                } else {
                    flag = true;
                }
            });
        }

        return value.toString();
    };

    const agregarReservaArray = (
        semana: number,
        anio: number,
        mes: number,
        hora: any,
        ID: number | undefined,
        id_persona: number,
        id_temporal: number
    ) => {
        let consultor = "";
        let data = ingresos;
        let dataArrayReserva = arrayReserva;
        let fechaArrayReserva = new Date(anio, mes - 1);

        consultoresList.map((e: any) => {
            if (e.ID === id_persona) {
                consultor = e.CONSULTOR_LOGIN;
            }
        });

        let flag = false;
        let horaMax = 0;
        let porcentaje = 0;

        consultoresList.forEach((c: any) => {
            if (c.CONSULTOR_LOGIN.toLowerCase() === consultor.toLowerCase()) {
                horaMax = c.HORA_MAX;
            }
        });

        porcentaje = parseInt(((hora * 100) / horaMax).toFixed(0));

        if (dataArrayReserva !== undefined) {
            if (dataArrayReserva.length <= 0) {
                dataArrayReserva.push({
                    ID: 0,
                    ID_INGRESOS_DIRECTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    SEMANA: semana,
                    HORAS: hora,
                    PORCENTAJE: 0,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                    FECHA_RESERVA: fechaArrayReserva,
                });
            } else {
                //modifica
                dataArrayReserva.forEach((e: any) => {
                    if (e.SEMANA === semana && e.ANIO === anio && e.ID_MES === mes && e.ID_TEMPORAL === id_temporal) {
                        e.HORAS = hora;
                        e.PORCENTAJE = 0;
                    }
                });

                //agrega
                for (let index = 0; index < dataArrayReserva.length; index++) {
                    const e = dataArrayReserva[index];
                    if (e.SEMANA === semana && e.ANIO === anio && e.ID_MES === mes && e.ID_TEMPORAL === id_temporal) {
                        flag = false;
                        break;
                    } else {
                        flag = true;
                    }
                }
            }
            if (flag === true) {
                dataArrayReserva.push({
                    ID: 0,
                    ID_INGRESOS_DIRECTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    SEMANA: semana,
                    HORAS: hora,
                    PORCENTAJE: 0,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                    FECHA_RESERVA: fechaArrayReserva,
                });
            }

            if (arrayReserva != undefined) {
                if (data != undefined) {
                    data.map((i: IngresoDirectoData, ing: number) => {
                        if (ID === i.ID) {
                            i.TOTAL_HORAS = arrayReserva.filter((e: any) => e.ID_INGRESOS_DIRECTOS === ID).reduce((a, b) => a + b.HORAS, 0);
                        }
                    });
                }
            }
        }

        setIngreso(data);

        callbackReserva(dataArrayReserva);
    };

    /**
     * Almacena en un array la cantidad de horas a reservar en un proyecto de un determinado usuario
     *
     * @param {Number} anio - full year (YYYY)
     * @param {Number} mes - month_number is in the range 1..12
     * @param {any} element - detalles del input que accionó el evento
     * @param {Number} semana - semana en el rango de 1 .. X
     * @returns {Array} Contiene semana, mes, año, proyecto y consultor
     */
    const onReservaChange = (
        anio: number,
        mes: number,
        element: any,
        semana: number,
        id_persona: number,
        id_temporal: number,
        dataIndex?: number
    ) => {
        let indexI: number | undefined;
        let id: number | undefined;

        if (element.target === undefined) {
            id = dataIndex;
        } else {
            id = parseInt(element.target.getAttribute("data-index"));
        }
        indexI = id;
        let hora = 0;

        if (element.target === undefined) {
            hora = 0;
        } else {
            hora = parseFloat(element.target.value); // valor del input
        }

        if (isNaN(hora)) {
            hora = 0;
        } else if (hora >= 0) {
            hora = hora;
        } else {
            hora = 0;
        }

        agregarReservaArray(semana, anio, mes, hora, indexI, id_persona, id_temporal);
        calcularTotalActividad(ingresos, 1);
    };

    const calcularDistribucion = (horas: number, id: number) => {
        let hora_distribuida = 0;

        if (cantidad_semanas !== undefined) {
            hora_distribuida = horas / cantidad_semanas;
        }

        if (arrayReserva != undefined) {
            arrayReserva.map((e: ArrayReserva) => {
                if (e.ID_INGRESOS_DIRECTOS === id) {
                    e.HORAS = hora_distribuida;
                }
            });
        }

        setArrReserva(arrayReserva);
        callback(ingresos);
        let actividadesCopy = ingresoP;
        if (actividadesCopy != undefined) {
            actividadesCopy.forEach((a) => {
                if (a.ID === id) {
                    a.TOTAL_HORAS = horas;
                }
            });
        }
    };

    const _optionCargo = (unidad: number) => {
        return listadoRoles_Plan.filter((e: any) => e.id_unidad == unidad);
    };

    const _textCargo = (cargo: number) => {
        let consultor: string = "Rol";
        let filter: any;
        filter = listadoRoles_Plan.find((e: any) => e.key == cargo);
        if (filter !== undefined) {
            consultor = filter.text;
        }

        return consultor;
    };

    const _optionPersona = (index: number, a: Ingreso, cargo: number) => {
        return listadoCargos.filter((e: any) => e.id_cargo == cargo);
    };

    const _textPersona = (cargo: number) => {
        let consultor: string = "Persona";
        let filter: any;
        filter = listadoCargos.find((e: any) => e.key == cargo);
        if (filter !== undefined) {
            consultor = filter.text;
        }

        return consultor;
    };

    const contadorSemanas = (anio: number, mes: number) => {
        var fecha_actual;
        fecha_actual = new Date(anio, 0, 1);

        let total_weeks = getISOWeeks(anio);

        while (fecha_actual.toLocaleDateString("es-ES", { weekday: "long" }) !== "lunes") {
            fecha_actual.setDate(fecha_actual.getDate() + 1);
        }

        let array_fechas = [];
        let contador = 0;

        array_fechas.push(fecha_actual);

        for (let index = 1; index < total_weeks; index++) {
            fecha_actual = moment(moment(fecha_actual).add(7, "d")).toDate();
            array_fechas.push(fecha_actual);
        }

        array_fechas.map((e: any) => {
            if (e.getMonth() === mes) {
                contador = contador + 1;
            }
        });

        return contador;
    };

    const getISOWeeks = (y: number) => {
        var d, isLeap;

        d = new Date(y, 0, 1);
        isLeap = new Date(y, 1, 29).getMonth() === 1;

        //check for a Jan 1 that's a Thursday or a leap year that has a
        //Wednesday jan 1. Otherwise it's 52
        return d.getDay() === 4 || (isLeap && d.getDay() === 3) ? 53 : 52;
    };

    const FilasIngresos = (a: Ingreso) => {
        return (
            <div>
                {ingresos != undefined
                    ? ingresos.map((i: IngresoDirectoData, indexI: number) => {
                          return (
                              <div>
                                  {i.ID_ACTIVIDAD === a.ID_ACTIVIDAD ? (
                                      <div>
                                          <div key={indexI}>
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 txtSmall separadorBloques">
                                                  <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                                      <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1 txtSmall" id="txt">
                                                          <ComboBox
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                              label="Unidad"
                                                              placeholder="Unidad"
                                                              defaultSelectedKey={i.ID_UNIDAD}
                                                              id={"unidadDirecto_Plan"}
                                                              onChange={(e, selectedOption) => {
                                                                  _onChangeUnidad(indexI, selectedOption);
                                                              }}
                                                              options={listadoOpcionesUnidad}
                                                              allowFreeform={true}
                                                              autoComplete="on"
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm12 ms-md2 ms-lg2 txtSmall" id="txt">
                                                          <ComboBox
                                                              disabled={true}
                                                              label="Rol Simulado"
                                                              placeholder="Sin Rol Simulado"
                                                              defaultSelectedKey={i.ID_CARGO}
                                                              id={"rolSimulado_Plan"}
                                                              options={listadoOpciones}
                                                              allowFreeform={true}
                                                              autoComplete="on"
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm12 ms-md1 ms-lg1 txtSmall" id="txt">
                                                          <ComboBox
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                              label="Rol"
                                                              placeholder={i.ID_UNIDAD > 0 ? _textCargo(i.ID_CARGO_PLAN) : "Rol"}
                                                              defaultSelectedKey={i.ID_CARGO_PLAN}
                                                              id={"consultorDirecto_Plan"}
                                                              onChange={(e, selectedOption) => {
                                                                  _onChangeRol(indexI, selectedOption);
                                                              }}
                                                              options={i.ID_UNIDAD > 0 ? _optionCargo(i.ID_UNIDAD) : []}
                                                              allowFreeform={true}
                                                              autoComplete="on"
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg2 txtSmall" id="txt">
                                                          <ComboBox
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                              label="Persona"
                                                              placeholder={i.ID_CARGO_PLAN > 0 ? _textPersona(i.ID_PERSONA) : "Persona"}
                                                              defaultSelectedKey={i.ID_PERSONA}
                                                              id={"personaDirecto_Plan"}
                                                              onChange={(e, selectedOption) => {
                                                                  _onChangePersona(indexI, selectedOption);
                                                              }}
                                                              options={i.ID_CARGO_PLAN > 0 ? _optionPersona(indexI, a, i.ID_CARGO_PLAN) : []}
                                                              allowFreeform={true}
                                                              autoComplete="on"
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm3 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Tiempo invertir(%)</label>
                                                              <NumberFormat
                                                                  id={"tiempoInvertirDirecto_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "tiempoInvertirDirecto_Plan");
                                                                  }}
                                                                  defaultValue={
                                                                      i.TIEMPO_INVERTIR <= 0
                                                                          ? ""
                                                                          : i.TIEMPO_INVERTIR > 100
                                                                          ? "100"
                                                                          : i.TIEMPO_INVERTIR.toString().replace(".", ",")
                                                                  }
                                                                  value={
                                                                      i.TIEMPO_INVERTIR <= 0
                                                                          ? ""
                                                                          : i.TIEMPO_INVERTIR > 100
                                                                          ? "100"
                                                                          : i.TIEMPO_INVERTIR.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"%..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Semanas</label>
                                                              <NumberFormat
                                                                  id={"semanasDirecto_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "semanasDirecto_Plan");
                                                                  }}
                                                                  defaultValue={i.SEMANAS <= 0 ? "" : i.SEMANAS.toString().replace(".", ",")}
                                                                  value={
                                                                      i.SEMANAS === 0 || i.SEMANAS <= 0 ? "" : i.SEMANAS.toString().replace(".", ",")
                                                                  }
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Horas</label>
                                                              <NumberFormat
                                                                  id={"horasDirecto_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "horasDirecto_Plan");
                                                                  }}
                                                                  value={i.HORAS === 0 ? "" : i.HORAS.toString().replace(".", ",")}
                                                                  defaultValue={i.HORAS <= 0 ? "" : i.HORAS.toString().replace(".", ",")}
                                                                  disabled={true}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Costo x HH</label>
                                                              <NumberFormat
                                                                  id={"costoHHDirecto_Plan"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "costoHHDirecto_Plan");
                                                                  }}
                                                                  defaultValue={i.COSTO_HH <= 0 ? "" : i.COSTO_HH.toString().replace(".", ",")}
                                                                  value={i.COSTO_HH <= 0 ? "" : i.COSTO_HH.toString().replace(".", ",")}
                                                                  disabled={
                                                                      estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25
                                                                          ? true
                                                                          : false
                                                                  }
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Total costo</label>
                                                              <NumberFormat
                                                                  id={"totalCostoDirecto"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "totalCostoDirecto");
                                                                  }}
                                                                  value={
                                                                      i.TOTAL_COSTO === 0 ? "" : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(i.TOTAL_COSTO)
                                                                  }
                                                                  disabled={true}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                                                          ""
                                                      ) : (
                                                          <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 mt-4" id="divEliminarRol">
                                                              <button
                                                                  id="btnAgregarRol"
                                                                  onClick={(e) => {
                                                                      deleteRol(indexI, i.ID);
                                                                      setIndexActividad(a.ID_ACTIVIDAD);
                                                                  }}>
                                                                  <i className="ms-Icon ms-Icon--Delete" aria-hidden="true" id="btnEliminarRol"></i>
                                                              </button>
                                                          </div>
                                                      )}
                                                  </div>
                                                  <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                                      <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg1 txtSmall">
                                                          <TextField
                                                              label="Total HH Distribuido"
                                                              id={"totalHH_Plan"}
                                                              value={
                                                                  i.TOTAL_HORAS === 0
                                                                      ? ""
                                                                      : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(i.TOTAL_HORAS).toString()
                                                              }
                                                              defaultValue={
                                                                  i.TOTAL_HORAS <= 0
                                                                      ? ""
                                                                      : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(i.TOTAL_HORAS).toString()
                                                              }
                                                              disabled={true}
                                                              placeholder={"..."}
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                                          <MessageBar
                                                              messageBarType={
                                                                  i.TOTAL_HORAS > i.HORAS
                                                                      ? MessageBarType.warning
                                                                      : i.TOTAL_HORAS < i.HORAS
                                                                      ? MessageBarType.warning
                                                                      : MessageBarType.success
                                                              }
                                                              isMultiline={false}
                                                              dismissButtonAriaLabel="Close">
                                                              {i.TOTAL_HORAS > i.HORAS
                                                                  ? `Sobran ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                                        parseFloat((i.TOTAL_HORAS - i.HORAS).toFixed(2))
                                                                    )} horas distribuidas.`
                                                                  : i.TOTAL_HORAS < i.HORAS
                                                                  ? `Faltan ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                                        parseFloat((i.HORAS - i.TOTAL_HORAS).toFixed(2))
                                                                    )} horas distribuidas.`
                                                                  : "Las horas están correctamente distribuidas."}
                                                          </MessageBar>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg2 btnDistribucion">
                                                          <PrimaryButton
                                                              text="Distribución automática"
                                                              onClick={() => calcularDistribucion(i.HORAS, i.ID)}
                                                              disabled={
                                                                  estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? true : false
                                                              }
                                                          />
                                                      </div>
                                                      <label id="cantSems">La cantidad de semanas es de: {cantidad_semanas}</label>
                                                  </div>
                                                  {/* calendario semanas */}
                                                  {total_year !== undefined
                                                      ? total_year.map((a: any) => {
                                                            let arr_months2 = [];
                                                            let arr_months: any[] = [];
                                                            let indiceSemana = 1;
                                                            let conteoSemanas = 0;

                                                            for (let indexA = 0; indexA <= 11; indexA++) {
                                                                // Meses
                                                                conteoSemanas = contadorSemanas(a.years, indexA);
                                                                for (let indexB = 1; indexB <= conteoSemanas; indexB++) {
                                                                    arr_months2.push({
                                                                        sem: indiceSemana,
                                                                        value:
                                                                            valueWeek(a.years, indexA, indiceSemana, i.ID).split(".").length == 2
                                                                                ? parseInt(
                                                                                      valueWeek(a.years, indexA, indiceSemana, i.ID).split(".")[1]
                                                                                  ) > 9
                                                                                    ? parseFloat(
                                                                                          valueWeek(a.years, indexA, indiceSemana, i.ID)
                                                                                      ).toFixed(2)
                                                                                    : parseFloat(
                                                                                          valueWeek(a.years, indexA, indiceSemana, i.ID)
                                                                                      ).toFixed(1)
                                                                                : valueWeek(a.years, indexA, indiceSemana, i.ID),
                                                                    });

                                                                    indiceSemana = indiceSemana + 1;
                                                                }
                                                                arr_months.push(arr_months2);
                                                                arr_months2 = [];
                                                            }

                                                            return i.ID_CARGO_PLAN <= 0 || i.ID_CARGO_PLAN === undefined ? (
                                                                <div></div>
                                                            ) : (
                                                                <div>
                                                                    <div className="ms-Grid-row bloqueActividad mt-3" id="bloqueActividad">
                                                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2">
                                                                            <div className="table-responsive">
                                                                                <table className="table table-sm tablaServicioDirecto">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 1 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[0].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Enero {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[1].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Febrero {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[2].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Marzo {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[3].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Abril {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[4].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Mayo {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[5].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Junio {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[6].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Julio {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[7].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Agosto {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[8].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Septiembre {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[9].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Octubre {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[10].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Noviembre {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}

                                                                                            {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <td colSpan={arr_months[11].length}>
                                                                                                        <span className="badge badge-primary">
                                                                                                            Diciembre {a.years}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td></td>
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr className="numeroSemanas">
                                                                                            {arr_months[0].map((e: any) => {
                                                                                                if (
                                                                                                    (a.years === a.inicio_year &&
                                                                                                        a.inicio_mes > 1) === false
                                                                                                )
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 1 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[1].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 2) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 2) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[2].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 3) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 3) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[3].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 4) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 4) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[4].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 5) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 5) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[5].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 6) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 6) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[6].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 7) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 7) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[7].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 8) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 8) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[8].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 9) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 9) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[9].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 10) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 10) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[10].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 11) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 11) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[11].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 12) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 12) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td>{e.sem}</td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}
                                                                                        </tr>
                                                                                        <tr>
                                                                                            {arr_months[0].map((e: any) => {
                                                                                                if (
                                                                                                    (a.years === a.inicio_year &&
                                                                                                        a.inicio_mes > 1) === false
                                                                                                )
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          1,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    id={
                                                                                                                        "ENE-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            1,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 1 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[1].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 2) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 2) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          2,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "FEB-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            2,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 2 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 2 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[2].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 3) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 3) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          3,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "MAR-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            3,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 3 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 3 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[3].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 4) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 4) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          4,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "ABR-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            4,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 4 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 4 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[4].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 5) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 5) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          5,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "MAY-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            5,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 5 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 5 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[5].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 6) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 6) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          6,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "JUN-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            6,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 6 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 6 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[6].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 7) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 7) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          7,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "JUL-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            7,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 7 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 7 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[7].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 8) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 8) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          8,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "AGO-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            8,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 8 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 8 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[8].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 9) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 9) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          9,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "SEP-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            9,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 9 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 9 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[9].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 10) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 10) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          10,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "OCT-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            10,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 10 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 10 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[10].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 11) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 11) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          11,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "NOV-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            11,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 11 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 11 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}

                                                                                            {arr_months[11].map((e: any) => {
                                                                                                if (a.years === a.inicio_year && a.inicio_mes > 12) {
                                                                                                    return "";
                                                                                                } else if (a.years === a.fin_year && a.fin_mes < 12) {
                                                                                                    return "";
                                                                                                } else {
                                                                                                    return (
                                                                                                        <React.Fragment>
                                                                                                            <td className="mesesIngresos">
                                                                                                                {parseInt(e.value) < 0
                                                                                                                    ? onReservaChange(
                                                                                                                          a.years,
                                                                                                                          12,
                                                                                                                          0,
                                                                                                                          e.sem,
                                                                                                                          i.ID_PERSONA,
                                                                                                                          i.ID_TEMPORAL,
                                                                                                                          i.ID
                                                                                                                      )
                                                                                                                    : ""}
                                                                                                                <TextField
                                                                                                                    label=""
                                                                                                                    id={
                                                                                                                        "DIC-SEM" +
                                                                                                                        e.sem +
                                                                                                                        "-" +
                                                                                                                        a.years
                                                                                                                    }
                                                                                                                    defaultValue={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        e.value <= 0
                                                                                                                            ? ""
                                                                                                                            : e.value.toString()
                                                                                                                    }
                                                                                                                    onChange={(element) => {
                                                                                                                        onReservaChange(
                                                                                                                            a.years,
                                                                                                                            12,
                                                                                                                            element,
                                                                                                                            e.sem,
                                                                                                                            i.ID_PERSONA,
                                                                                                                            i.ID_TEMPORAL
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    placeholder={"..."}
                                                                                                                    type={"number"}
                                                                                                                    disabled={
                                                                                                                        estado_modulo === 10 ||
                                                                                                                        estado_modulo === 24 ||
                                                                                                                        estado_modulo === 25
                                                                                                                            ? true
                                                                                                                            : false
                                                                                                                    }
                                                                                                                    key={i.ID}
                                                                                                                    data-index={i.ID}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </React.Fragment>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                            {a.years === a.inicio_year && a.inicio_mes > 12 ? (
                                                                                                ""
                                                                                            ) : a.years === a.fin_year && a.fin_mes < 12 ? (
                                                                                                ""
                                                                                            ) : (
                                                                                                <td></td>
                                                                                            )}
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                      : ""}
                                                  {/* fin calendario semanas */}
                                              </div>
                                          </div>
                                      </div>
                                  ) : (
                                      ""
                                  )}
                              </div>
                          );
                      })
                    : ""}

                <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                    {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                        ""
                    ) : (
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" id="divAgregarActividad">
                            <button
                                id="btnAgregarRol"
                                onClick={(e) => {
                                    addRol(a.ID_ACTIVIDAD, ingresos.length === 0 ? 1 : ingresos[ingresos.length - 1].ID);
                                }}>
                                <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarRol">
                                    <span className="txtAgregar">&nbsp; Agregar Persona</span>
                                </i>
                            </button>
                        </div>
                    )}
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg8 txtSmall" id="divTotal">
                            Total
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                            <TextField
                                id={"totalActividadDirectoTarifaHH_Plan"}
                                disabled={true}
                                placeholder={"..."}
                                value={a.TOTAL_HORAS != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.TOTAL_HORAS) : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                            <TextField
                                id={"totalActividadDirectoCostoHH_Plan"}
                                disabled={true}
                                placeholder={"..."}
                                value={a.COSTO_HH != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.COSTO_HH) : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                            <TextField
                                id={"totalActividadDirectoTotalCosto"}
                                disabled={true}
                                placeholder={"..."}
                                value={a.TOTAL_COSTO != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.TOTAL_COSTO) : ""}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Resumen = () => {
        return (
            <div className="ms-Grid-row divTotalBloque" id="divTotalBloque">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className="ms-Grid-col ms-sm7 ms-md7 ms-lg7 txtSmall"></div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1" id="divTotal">
                        Total ingresos y costos directos
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoHoras_Plan"}
                            disabled={true}
                            placeholder={"..."}
                            value={horas != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(horas) : ""}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoCosto_Plan"}
                            disabled={true}
                            placeholder={"..."}
                            value={costoHH != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(costoHH) : ""}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoTotalCosto"}
                            disabled={true}
                            placeholder={"..."}
                            value={totalCostos != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(totalCostos) : ""}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1"></div>
                </div>
            </div>
        );
    };

    const Actividades = () => {
        return actividades != undefined
            ? actividades.map((a: Ingreso, indexA: number) => {
                  return (
                      <div>
                          <div>
                              <div className="acordeonActividad">
                                  <Accordion
                                      title={a.NOMBRE}
                                      defaultCollapsed={false}
                                      inicio={true}
                                      expandAll={false}
                                      className="fondoTitAcordionActividad"
                                      key={1}
                                      classFondo="fondoSeccionActividad">
                                      <div className="itemContent">
                                          {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                                              ""
                                          ) : (
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="divBloque">
                                                  <button
                                                      id="btnAgregarRol"
                                                      className="cierre"
                                                      onClick={(e) => {
                                                          deleteActividad(a.ID_ACTIVIDAD, indexA);
                                                      }}>
                                                      <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" id="btnEliminarActividad"></i>
                                                  </button>
                                              </div>
                                          )}
                                      </div>
                                      {FilasIngresos(a)}
                                  </Accordion>
                              </div>
                          </div>
                      </div>
                  );
              })
            : "";
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            indexIngreso > -1 ? deleteRolIndex() : deleteActividadRoles();
                        }}
                        text="Sí, Eliminar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };

    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    <div className="itemContent">
                        {estado_modulo === 10 || estado_modulo === 24 || estado_modulo === 25 ? (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm1 ms-md8 ms-lg7"></div>
                                <div className="ms-Grid-col ms-sm11 ms-md4 ms-lg5 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm8 ms-md4 ms-lg4 mt-2 mb-2">
                                    <TextField
                                        placeholder="Nombre Actividad"
                                        id={"nombreActividadDirecto"}
                                        value={nombreActividad}
                                        onChange={(e: any) => {
                                            setNombreActividad(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm3 ms-md4 ms-lg3 mt-2 mb-2" id="divAgregarActividad">
                                    <button
                                        id="btnAgregarActividad"
                                        className="btnAgregarActividad"
                                        onClick={(e) => {
                                            addActividad();
                                        }}>
                                        <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarActividad">
                                            &nbsp;
                                            <span className="txtAgregar">Agregar Actividad</span>
                                        </i>
                                    </button>
                                </div>
                                <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        {Actividades()}
                        {dialog()}
                        {Resumen()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default IngresosDirectosPlanificacion;
