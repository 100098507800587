import React, { useState, useEffect } from "react";
import "react-autosuggest";
import { ComboBox, DefaultButton, Dialog, DialogFooter, DialogType, IconButton, PrimaryButton, TextField, Checkbox } from "@fluentui/react";
import { Accordion } from "../../../controles/Accordion/Accordion";
import "../PlanificacionProyecto.css";
import NumberFormat from "react-number-format";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { ArrayRecuperables } from "../../../controles/entidades/Planificacion";

interface Props {
    actividadesList?: IngresoGastos[];
    ingresoP?: IngresoData[];
    callback: any;
    callbackActividades: any;
    listadoOpciones?: any[];
    moneda?: string;
    estado_modulo: number;
    lista_categorias?: any[];
    total_year?: any[];
    arrayGastos?: ArrayGastos[];
    arrayRecuperables?: any[];
    callbackArrayGastos: any;
    callbackArrayRecuperables: any;
    id_planificacion: number;

    fecha_cierre_mes?: Date;
    cantidad_meses?: number;
}

interface IngresoGastos {
    ID: number;
    ID_SIMULACION: number;
    ID_MODULO: number;
    ID_ACTIVIDAD: number;
    index: number;
    NOMBRE: string;
    //GASTO_TOTAL: number;
    VALOR_RECUPERAR: number;
    ID_TEMPORAL: number;
    GASTO_TOTAL: number;
    GASTO_DISTRIBUIDO: number;
    RECUPERABLE_DISTRIBUIDO: number;
    TOTAL_NETO: number;
}

interface IngresoData {
    ID: number;
    INDEX: number;
    ID_SIMULACION: number;
    ID_ACTIVIDAD: number;
    ITEM: string;
    UNIDAD: string;
    COSTO_UNITARIO: number;
    CANTIDAD: number;
    GASTO_TOTAL: number;
    VALOR_RECUPERAR: number;
    REEMBOLSABLE: boolean;
    ID_TEMPORAL: number;
    TOTAL_GASTOS_DIST: number;
}

interface ArrayGastos {
    ID: number;
    ID_MES: number;
    ID_GASTOS: number;
    VALOR: number;
    ANIO: number;
    ID_TEMPORAL: number;
    ID_PLANIFICACION: number;
}

const IngresosGastosForecast = ({
    actividadesList,
    ingresoP,
    callback,
    listadoOpciones,
    moneda,
    estado_modulo,
    lista_categorias,
    callbackActividades,
    total_year,
    arrayGastos,
    arrayRecuperables,
    callbackArrayGastos,
    callbackArrayRecuperables,
    id_planificacion,
    fecha_cierre_mes,
    cantidad_meses,
}: Props) => {
    const [ingresos, setIngreso] = useState(ingresoP);
    const [actividades, setActividades] = useState(actividadesList);
    const [nombreAcividad, setNombreActividad] = useState("");

    const [dialogTitle, setDialogTitle] = useState("");
    const [dialogSubText, setDialogSubText] = useState("");

    const [indexActividad, setIndexActividad] = useState(-1);
    const [indexIngreso, setIndexIngreso] = useState(-1);

    const [activeKey, setActiveKey] = useState(0);
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogSubText,
    };

    const addActividad = () => {
        let actividad = actividades;
        let listaActividades = actividades;
        let exists;
        let nuevoID = 0;
        let nuevoTemporal = 0;

        if (actividad != undefined) {
            actividad.map((a: any) => {
                nuevoID = a.ID_ACTIVIDAD;
                if (a.id_temporal >= nuevoTemporal){
                    nuevoTemporal = a.id_temporal++;
                }
            });
        }

        if (nombreAcividad != "") {
            if (listaActividades !== undefined) {
                if (listaActividades.length > 0) {
                    for (let i = 0; i < listaActividades.length; i++) {
                        const nombre = listaActividades[i].NOMBRE;
                        if (nombre == nombreAcividad) {
                            exists = true;
                            break;
                        } else {
                            exists = false;
                        }
                    }
                } else {
                    if (actividad != undefined) {
                        actividad.push({
                            ID_SIMULACION: 0,
                            ID_MODULO: 3,
                            ID_ACTIVIDAD: nuevoID + 1,
                            index: actividad.length,
                            NOMBRE: nombreAcividad,
                            GASTO_TOTAL: 0,
                            VALOR_RECUPERAR: 0,
                            ID_TEMPORAL: nuevoTemporal,
                            ID: 0,
                            GASTO_DISTRIBUIDO: 0,
                            RECUPERABLE_DISTRIBUIDO: 0,
                            TOTAL_NETO: 0,
                        });
                        setActividades([...actividad]);
                    }
                }
                if (exists === false) {
                    if (actividad != undefined) {
                        actividad.push({
                            ID_SIMULACION: 0,
                            ID_MODULO: 3,
                            ID_ACTIVIDAD: nuevoID + 1,
                            index: actividad.length,
                            NOMBRE: nombreAcividad,
                            GASTO_TOTAL: 0,
                            VALOR_RECUPERAR: 0,
                            ID_TEMPORAL: nuevoTemporal,
                            ID: 0,
                            GASTO_DISTRIBUIDO: 0,
                            RECUPERABLE_DISTRIBUIDO: 0,
                            TOTAL_NETO: 0,
                        });
                        setActividades([...actividad]);
                    }
                }
            }
            callbackActividades(actividades);
        }

        setNombreActividad("");
    };

    const _onChangeRol = (index: number, item: any, a: IngresoGastos): void => {
        let data = ingresos;
        let key = item.key;

        if (data !== undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    if (listadoOpciones !== undefined) {
                        listadoOpciones.map((a: any) => {
                            if (a.key === key) {
                                i.ITEM = key;
                            }
                        });
                    }
                }
            });
            setIngreso([...data]);
        }
    };

    const addRol = (actividadID: number) => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.push({
                ID_ACTIVIDAD: actividadID,
                ID: 0,
                ID_SIMULACION: 0,
                ITEM: "",
                UNIDAD: "",
                COSTO_UNITARIO: 0,
                CANTIDAD: 0,
                GASTO_TOTAL: 0,
                VALOR_RECUPERAR: 0,
                REEMBOLSABLE: false,
                ID_TEMPORAL: 0,
                TOTAL_GASTOS_DIST: 0,
                INDEX: ingreso.reduce((a: number, b: IngresoData) => 1 + b.INDEX, 0),
            });
            setIngreso([...ingreso]);
            callback(ingreso);
        }
    };

    const deleteRol = (indexI: number) => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            let row = ingreso.find((ing: IngresoData, i: number) => i === indexI);
            let sumaRows = 0;
            if (row != undefined) {
                sumaRows = row.COSTO_UNITARIO + row.CANTIDAD;
            }

            if (sumaRows === 0) {
                ingreso.splice(indexI, 1);
                setIngreso([...ingreso]);
                callback(ingreso);
            } else {
                setDialogTitle("Ingreso con datos!");
                setDialogSubText("Esta fila contiene datos ingresados ¿Está seguro que desea eliminar?");
                setIndexIngreso(indexI);
                setDialog(false);
            }
        }
    };

    const deleteRolIndex = () => {
        let ingreso = ingresos;

        if (ingreso != undefined) {
            ingreso.splice(indexIngreso, 1);
            setIngreso([...ingreso]);
            setIndexIngreso(-1);
            setIndexActividad(-1);
            setDialog(true);
            callback(ingreso);
            calcularTotalActividad(ingreso, indexActividad);
            setIndexActividad(-1);
        }
    };

    const deleteActividad = (actividadID: number, indexA: number) => {
        let ingreso = ingresos;
        let actividad = actividades;
        let tieneIngresos = false;

        if (ingreso != undefined) {
            tieneIngresos = ingreso.some((i: IngresoData) => i.ID_ACTIVIDAD === actividadID);
        }

        if (tieneIngresos) {
            setIndexActividad(indexA);
            setDialogTitle("Actividad con roles!");
            setDialogSubText("¿Está seguro que desea eliminar la actividad?");
            setDialog(false);
        } else {
            if (actividad != undefined) {
                actividad.splice(indexA, 1);
                setActividades([...actividad]);
                callbackActividades(actividades);
            }
        }
    };

    const deleteActividadRoles = () => {
        let ingreso = ingresos;
        let ingreso2: any = [];
        let actividad = actividades;
        let actividad2: any = [];
        let actividadEliminar: IngresoGastos | undefined;

        if (actividad != undefined) {
            actividadEliminar = actividad.find((a, i) => i === indexActividad);

            if (ingreso != undefined) {
                let ingEliminar: number[];
                if (actividadEliminar != undefined) {
                    let idActividad = actividadEliminar.ID_ACTIVIDAD;

                    ingreso.map((ing: any) => {
                        if (ing.ID_ACTIVIDAD != idActividad) {
                            ingreso2.push({
                                CANTIDAD: ing.CANTIDAD,
                                COSTO_UNITARIO: ing.COSTO_UNITARIO,
                                GASTO_TOTAL: ing.GASTO_TOTAL,
                                ID: ing.ID,
                                ID_ACTIVIDAD: ing.ID_ACTIVIDAD,
                                ID_FILA_ITEM: ing.ID_FILA_ITEM,
                                ID_SIMULACION: ing.ID_SIMULACION,
                                ID_TEMPORAL: ing.ID_TEMPORAL,
                                ITEM: ing.ITEM,
                                REEMBOLSABLE: ing.REEMBOLSABLE,
                                TOTAL_GASTOS_DIST: ing.TOTAL_GASTOS_DIST,
                                UNIDAD: ing.UNIDAD,
                                VALOR_RECUPERAR: ing.VALOR_RECUPERAR,
                            });
                        }
                    });

                    actividad.map((a: any) => {
                        if (a.ID_ACTIVIDAD != idActividad) {
                            actividad2.push({
                                GASTO_DISTRIBUIDO: a.GASTO_DISTRIBUIDO,
                                GASTO_TOTAL: a.GASTO_TOTAL,
                                ID: a.ID,
                                ID_ACTIVIDAD: a.ID_ACTIVIDAD,
                                ID_MODULO: a.ID_MODULO,
                                ID_SIMULACION: a.ID_SIMULACION,
                                ID_TEMPORAL: a.ID_TEMPORAL,
                                NOMBRE: a.NOMBRE,
                                VALOR_RECUPERAR: a.VALOR_RECUPERAR,
                            });
                        }
                    });
                }

                setActividades([...actividad2]);
                setDialog(true);
                callback(ingreso2);
                callbackActividades(actividad2);
            }
        }
    };

    const onValueChanged = (index: number, ev: any, indexA: number, e?: string) => {
        let key = ev.target === undefined ? e : ev.target.id;
        let value = ev.target === undefined ? ev.floatValue : ev.target.value;
        let val = 0;

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    switch (key) {
                        case "unidadGastos_Fore":
                            i.UNIDAD = value;
                            break;
                        case "costoUnitarioGastos_Fore":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.COSTO_UNITARIO = val;
                            } else {
                                i.COSTO_UNITARIO = 0;
                            }
                            //Calcular
                            break;
                        case "cantidadGastos_Fore":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.CANTIDAD = val;
                            } else {
                                i.CANTIDAD = 0;
                            }
                            //Calcular
                            break;
                        case "gastoTotalGastos_Fore":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.GASTO_TOTAL = val;
                            } else {
                                i.GASTO_TOTAL = 0;
                            }
                            break;
                        case "valorRecuperarGastos_Fore":
                            val = value === "" ? 0 : parseFloat(value);
                            if (val >= 0) {
                                i.VALOR_RECUPERAR = val;
                            } else {
                                i.VALOR_RECUPERAR = 0;
                            }
                            //Calcular
                            break;
                        case "reembolsableGastos_Plan":
                            i.REEMBOLSABLE = value;
                            break;
                        case "itemGastos_Fore":
                            i.ITEM = value;
                            break;
                    }
                    i.GASTO_TOTAL = parseFloat((i.COSTO_UNITARIO * i.CANTIDAD).toFixed(2));
                }
            });

            //Total Actividad
            calcularTotalActividad(data, indexA);
            setIngreso([...data]);
            callback(data);
        }
    };

    const onCheckChange = (index: number, ev: any, indexA: number) => {
        let key = ev.target.id;
        let value = ev.target.checked;

        let data = ingresos;
        if (data != undefined) {
            data.map((i: IngresoData, ing: number) => {
                if (index === ing) {
                    i.REEMBOLSABLE = value;
                    if (value === true && i.GASTO_TOTAL > 0) {
                        i.VALOR_RECUPERAR = i.GASTO_TOTAL;
                    } else if (value === false) {
                        i.VALOR_RECUPERAR = 0;
                    }
                }
            });

            setIngreso([...data]);
            callback(data);
        }
    };
    const _onCheckChange = (ev?: any, isChecked?: boolean) => {};

    const agregarReservaArray = (anio: number, mes: number, valor: any, ID: number, id_temporal: number) => {
        let data = actividades;
        let gastos = arrayGastos;
        let flag = false;

        if (gastos !== undefined) {
            if (gastos.length <= 0) {
                gastos.push({
                    ID: 0,
                    ID_GASTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    VALOR: valor,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                });
            } else {
                //modifica
                gastos.forEach((e: any) => {
                    if (e.ANIO === anio && e.ID_MES === mes && e.ID_GASTOS === ID) {
                        e.VALOR = valor;
                    }
                });

                //agrega
                for (let index = 0; index < gastos.length; index++) {
                    const e = gastos[index];
                    if (e.ANIO === anio && e.ID_MES === mes && e.ID_GASTOS === ID) {
                        flag = false;
                        break;
                    } else {
                        flag = true;
                    }
                }
            }
            if (flag === true) {
                gastos.push({
                    ID: 0,
                    ID_GASTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    VALOR: valor,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                });
            }
            callbackArrayGastos(gastos);
            calcularTotalActividad([]);
        }
    };

    const agregarReservaArrayRecuperables = (anio: number, mes: number, valor: any, ID: number, id_temporal: number) => {
        let data = actividades;

        let flag = false;

        if (arrayRecuperables !== undefined) {
            if (arrayRecuperables.length <= 0) {
                arrayRecuperables.push({
                    ID: 0,
                    ID_GASTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    VALOR: valor,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                });
            } else {
                //modifica
                arrayRecuperables.forEach((e: any) => {
                    if (e.ANIO === anio && e.ID_MES === mes && e.ID_GASTOS === ID) {
                        e.VALOR = valor;
                    }
                });

                //agrega
                for (let index = 0; index < arrayRecuperables.length; index++) {
                    const e = arrayRecuperables[index];
                    if (e.ANIO === anio && e.ID_MES === mes && e.ID_GASTOS === ID) {
                        flag = false;
                        break;
                    } else {
                        flag = true;
                    }
                }
            }
            if (flag === true) {
                arrayRecuperables.push({
                    ID: 0,
                    ID_GASTOS: ID,
                    ID_PLANIFICACION: id_planificacion,
                    ID_MES: mes,
                    VALOR: valor,
                    ANIO: anio,
                    ID_TEMPORAL: id_temporal,
                });
            }
        }
        callbackArrayRecuperables(arrayRecuperables);
        calcularTotalActividad([]);
    };

    const onReservaChange = (anio: number, mes: number, element: any, id_temporal: number, index_I: number) => {
        let indexI = 0;
        let id = index_I;
        indexI = id;
        let valor = parseInt(element.value); // valor del input

        if (isNaN(valor)) {
            valor = 0;
        } else if (valor >= 0) {
            valor = valor;
        } else {
            valor = 0;
        }

        agregarReservaArray(anio, mes, valor, indexI, id_temporal);
    };

    const onReservaChangeRecuperable = (anio: number, mes: number, element: any, id_temporal: number, index_I: number) => {
        let indexI = 0;
        let id = index_I;
        indexI = id;
        let valor = parseInt(element.value); // valor del input

        if (isNaN(valor)) {
            valor = 0;
        } else if (valor >= 0) {
            valor = valor;
        } else {
            valor = 0;
        }

        agregarReservaArrayRecuperables(anio, mes, valor, indexI, id_temporal);
    };

    const calcularTotalActividad = (xingresos: IngresoData[], actividadID?: number) => {
        let auxArrayActividades = actividades;
        let auxArrayIngresos = ingresos;
        let auxArrayGastos = arrayGastos;
        let auxArrayRecuperables = arrayRecuperables;

        if (auxArrayActividades !== undefined) {
            auxArrayActividades
            .filter((actividad: IngresoGastos) => ((actividad.ID_ACTIVIDAD == actividadID || actividadID == undefined) && actividad.ID_MODULO == 3))
            .forEach((actividad: IngresoGastos) => {
                actividad.GASTO_TOTAL = 0;
                actividad.VALOR_RECUPERAR = 0;
                actividad.GASTO_DISTRIBUIDO = 0;
                actividad.RECUPERABLE_DISTRIBUIDO = 0;
                
                if(auxArrayIngresos !== undefined){
                    auxArrayIngresos
                    .filter((item: IngresoData) => item.ID_ACTIVIDAD == actividad.ID_ACTIVIDAD)
                    .forEach((item: IngresoData) => {
                        item.GASTO_TOTAL = item.CANTIDAD * item.COSTO_UNITARIO;
                        actividad.GASTO_TOTAL += item.GASTO_TOTAL;
                        actividad.VALOR_RECUPERAR += item.VALOR_RECUPERAR;
                    });
                }
                if(auxArrayGastos !== undefined){
                    auxArrayGastos
                        .filter((item: ArrayGastos) => item.ID_TEMPORAL == actividad.ID_TEMPORAL)
                        .forEach((item: ArrayGastos, i) => {
                            actividad.GASTO_DISTRIBUIDO += item.VALOR;
                        });
                }
                if(auxArrayRecuperables !== undefined){
                    auxArrayRecuperables
                        .filter((item: ArrayGastos) => item.ID_TEMPORAL == actividad.ID_TEMPORAL)
                        .forEach((item: ArrayGastos) => {
                            actividad.RECUPERABLE_DISTRIBUIDO += item.VALOR;
                        });
                }
                actividad.TOTAL_NETO = actividad.VALOR_RECUPERAR - actividad.GASTO_DISTRIBUIDO;
            });

            setIngreso(auxArrayIngresos);
            setActividades(auxArrayActividades);
            
            callback(auxArrayIngresos);
            callbackActividades(auxArrayActividades);
        }
    };

    useEffect(() => {
        calcularTotalActividad([]);
    }, []);

    const _onChangeActividad = (event: any, item: any): void => {
        let text = item == undefined ? "" : item.text;

        setNombreActividad(text);
    };

    const calcularDistribucion = (gasto_total_bloque: number, id_actividad: number) => {
        let ingreso_distribuido = 0;
        let gasto: any;
        let arrayGastos2 = arrayGastos;
        let cantidad_meses_fr = cantidad_meses;
        let nuevoTotal = gasto_total_bloque;

        //PLAN
        if (arrayGastos2 !== undefined) {
            arrayGastos2.map((e: ArrayGastos) => {
                if (id_actividad === e.ID_GASTOS) {
                    if (isClosedMonth(e.ID_MES - 1, e.ANIO) === true) {
                        nuevoTotal = nuevoTotal - e.VALOR;
                        cantidad_meses_fr = cantidad_meses_fr != undefined ? cantidad_meses_fr - 1 : 0;
                    }
                }
            });
        }

        if (cantidad_meses_fr !== undefined) {
            ingreso_distribuido = nuevoTotal / cantidad_meses_fr;
        }

        if (arrayGastos2 !== undefined) {
            arrayGastos2.map((e: ArrayGastos) => {
                if (id_actividad === e.ID_GASTOS) {
                    if (isClosedMonth(e.ID_MES - 1, e.ANIO) === false) {
                        e.VALOR = ingreso_distribuido;
                    }
                }
            });
        }
    };

    const cambio = (e: any) => {
        e.value = 0;
    };

    const valueMonth = (year: number, month_number: number, id_gasto: number) => {
        let value = -1;
        if (arrayGastos !== undefined) {
            arrayGastos.forEach((e: ArrayGastos) => {
                if (e.ANIO === year && e.ID_MES === month_number && e.ID_GASTOS === id_gasto) {
                    value = e.VALOR;
                }
            });
        }

        return value.toString();
    };

    const valueMonthRecuperable = (year: number, month_number: number, id_gasto: number) => {
        let value = -1;
        if (arrayRecuperables !== undefined) {
            arrayRecuperables.forEach((e: ArrayRecuperables) => {
                if (e.ANIO === year && e.ID_MES === month_number && e.ID_GASTOS === id_gasto) {
                    value = e.VALOR;
                }
            });
        }

        return value.toString();
    };

    /**
     * Se encarga de revisar los meses cerrados, comparando la ultima fecha vez de cierre con la fecha de cada campo
     *
     * @param {Number} anio - full year (YYYY)
     * @param {Number} mes - month_number is in the range 0..11
     * @returns {Boolean} En caso de ser true, deshabilita el campo, si no, lo deja habilitado
     */
    const isClosedMonth = (mes: number, anio: number) => {
        let fecha_campo = new Date(anio, mes, 28);
        let fecha_cierre_mes_ing = fecha_cierre_mes !== undefined ? fecha_cierre_mes : new Date();
        let parse_fecha_string = "";
        parse_fecha_string = fecha_cierre_mes_ing.toString().split("T")[0];

        fecha_cierre_mes_ing = new Date(
            parseInt(parse_fecha_string.split("-")[0]),
            parseInt(parse_fecha_string.split("-")[1]),
            parseInt(parse_fecha_string.split("-")[2])
        );

        if (fecha_campo.getMonth() === fecha_cierre_mes_ing.getMonth() && fecha_campo.getFullYear() === fecha_cierre_mes_ing.getFullYear()) {
            return false;
        } else if (fecha_campo < fecha_cierre_mes_ing) {
            return true;
        } else {
            return false;
        }
    };

    const FilasIngresos = (a: IngresoGastos) => {
        return (
            <div>
                {ingresos != undefined
                    ? ingresos.map((i: IngresoData, indexI: number) => {
                          return (
                              <div>
                                  {i.ID_ACTIVIDAD === a.ID_ACTIVIDAD ? (
                                      <div>
                                          <div key={indexI}>
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 txtSmall separadorBloques">
                                                  <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg3 txtSmall">
                                                          <TextField
                                                              label="Ítem"
                                                              id={"itemGastos_Fore"}
                                                              defaultValue={i.ITEM}
                                                              placeholder="Nombre ítem"
                                                              onChange={(e) => {
                                                                  onValueChanged(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={estado_modulo === 24 || estado_modulo === 25 ? true : false}
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2 txtSmall">
                                                          <TextField
                                                              label="Unidad"
                                                              id={"unidadGastos_Fore"}
                                                              defaultValue={i.UNIDAD}
                                                              placeholder="..."
                                                              onChange={(e) => {
                                                                  onValueChanged(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={estado_modulo === 24 || estado_modulo === 25 ? true : false}
                                                          />
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Costo unitario</label>
                                                              <NumberFormat
                                                                  id={"costoUnitarioGastos_Fore"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "costoUnitarioGastos_Fore");
                                                                  }}
                                                                  defaultValue={
                                                                      i.COSTO_UNITARIO <= 0 ? "" : i.COSTO_UNITARIO.toString().replace(".", ",")
                                                                  }
                                                                  value={
                                                                      i.COSTO_UNITARIO === 0 || i.COSTO_UNITARIO <= 0
                                                                          ? ""
                                                                          : i.COSTO_UNITARIO.toString().replace(".", ",")
                                                                  }
                                                                  disabled={estado_modulo === 24 || estado_modulo === 25 ? true : false}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Cantidad</label>
                                                              <NumberFormat
                                                                  id={"cantidadGastos_Fore"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "cantidadGastos_Fore");
                                                                  }}
                                                                  defaultValue={i.CANTIDAD <= 0 ? "" : i.CANTIDAD.toString().replace(".", ",")}
                                                                  value={
                                                                      i.CANTIDAD === 0 || i.CANTIDAD <= 0
                                                                          ? ""
                                                                          : i.CANTIDAD.toString().replace(".", ",")
                                                                  }
                                                                  disabled={estado_modulo === 24 || estado_modulo === 25 ? true : false}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Gasto total</label>
                                                              <NumberFormat
                                                                  id={"gastoTotalGastos_Fore"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "gastoTotalGastos_Fore");
                                                                  }}
                                                                  defaultValue={i.GASTO_TOTAL <= 0 ? "" : i.GASTO_TOTAL.toString().replace(".", ",")}
                                                                  value={
                                                                      i.GASTO_TOTAL === 0 || i.GASTO_TOTAL <= 0
                                                                          ? ""
                                                                          : i.GASTO_TOTAL.toString().replace(".", ",")
                                                                  }
                                                                  disabled={true}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall">
                                                          <span className={"formateo"}>
                                                              <label>Val. a recuperar</label>
                                                              <NumberFormat
                                                                  id={"valorRecuperarGastos_Fore"}
                                                                  onValueChange={(e) => {
                                                                      onValueChanged(indexI, e, i.ID_ACTIVIDAD, "valorRecuperarGastos_Fore");
                                                                  }}
                                                                  defaultValue={
                                                                      i.VALOR_RECUPERAR <= 0 ? "" : i.VALOR_RECUPERAR.toString().replace(".", ",")
                                                                  }
                                                                  value={
                                                                      i.VALOR_RECUPERAR === 0 || i.VALOR_RECUPERAR <= 0
                                                                          ? ""
                                                                          : i.VALOR_RECUPERAR.toString().replace(".", ",")
                                                                  }
                                                                  disabled={estado_modulo === 24 || estado_modulo === 25 ? true : false}
                                                                  thousandSeparator={"."}
                                                                  decimalSeparator={","}
                                                                  placeholder={"..."}
                                                              />
                                                          </span>
                                                      </div>
                                                      <div
                                                          className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall mt-4 reembolsable"
                                                          id="ejeGastosCobrable">
                                                          <Checkbox
                                                              label="Cobrable al cliente"
                                                              checked={i.REEMBOLSABLE}
                                                              onChange={(e) => {
                                                                  onCheckChange(indexI, e, i.ID_ACTIVIDAD);
                                                              }}
                                                              disabled={estado_modulo === 24 || estado_modulo === 25 ? true : false}></Checkbox>
                                                      </div>
                                                      {estado_modulo === 24 || estado_modulo === 25 ? (
                                                          ""
                                                      ) : (
                                                          <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 mt-4" id="divEliminarRol">
                                                              <button
                                                                  id="btnAgregarRol"
                                                                  onClick={(e) => {
                                                                      deleteRol(indexI);
                                                                      setIndexActividad(a.ID_ACTIVIDAD);
                                                                  }}>
                                                                  <i className="ms-Icon ms-Icon--Delete" aria-hidden="true" id="btnEliminarRol"></i>
                                                              </button>
                                                          </div>
                                                      )}
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  ) : (
                                      ""
                                  )}
                              </div>
                          );
                      })
                    : ""}

                <div className="ms-Grid-row bloqueActividad" id="bloqueActividad">
                    {estado_modulo === 24 || estado_modulo === 25 ? (
                        ""
                    ) : (
                        <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg2" id="divAgregarActividad">
                            <button
                                id="btnAgregarRol"
                                onClick={(e) => {
                                    addRol(a.ID_ACTIVIDAD);
                                }}>
                                <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarRol">
                                    <span className="txtAgregar">&nbsp; Agregar ítem</span>
                                </i>
                            </button>
                        </div>
                    )}
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className="ms-Grid-col ms-sm1 ms-md6 ms-lg7 txtSmall mt-4" id="divTotal">
                            Total items
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 pb-1 mt-4">
                            <TextField
                                id={"totalActividadDirectoGasto_Fore"}
                                disabled={true}
                                placeholder={"..."}
                                value={a.GASTO_TOTAL != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.GASTO_TOTAL) : ""}
                            />
                        </div>
                        <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 mt-4">
                            <TextField
                                id={"totalActividadDirectoValor_Fore"}
                                disabled={true}
                                placeholder={"..."}
                                value={a.VALOR_RECUPERAR != undefined ? new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(a.VALOR_RECUPERAR) : ""}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const Resumen = () => {
        let itemsGasto: number = 0;
        let itemsRecuperable: number = 0;
        let totalGasto: number = 0;
        let totalRecuperable: number = 0;

        if(actividades !== undefined) {
            actividades.forEach((item: IngresoGastos) => {
                itemsGasto += item.GASTO_TOTAL;
                itemsRecuperable += item.VALOR_RECUPERAR;
                totalGasto += item.GASTO_DISTRIBUIDO;
                totalRecuperable += item.RECUPERABLE_DISTRIBUIDO;
            });
        }
        return (
            <div className="ms-Grid-row divTotalBloque" id="divTotalBloque">
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall" id="divTotal">
                        Total gastos distribuidos
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoGasto_Fore"}
                            disabled={true}
                            placeholder={"..."}
                            value={new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(totalGasto)}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoValor_Fore"}
                            disabled={true}
                            placeholder={"..."}
                            value={new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(totalRecuperable)}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm. ms-m3 ms-lg3 txtSmall"></div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall" id="divTotal">
                        Total gastos items
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoGasto_Fore_items"}
                            disabled={true}
                            placeholder={"..."}
                            value={new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(itemsGasto)}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1">
                        <TextField
                            id={"totalBloqueDirectoValor_Fore_items"}
                            disabled={true}
                            placeholder={"..."}
                            value={new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(itemsRecuperable)}
                        />
                    </div>
                </div>
                <div className="ms-Grid-col ms-sm1 ms-md1 ms-lg1 txtSmall"></div>
            </div>
        );
    };

    const Actividades = () => {
        let arrayAnios = arrayGastos?.map((item: ArrayGastos) => item.ANIO);
        let aniosDistintos = Array.from(new Set(arrayAnios));
        return actividades != undefined
            ? actividades.map((act: IngresoGastos, indexAct: number) => {
                  return (
                      <div>
                          <div>
                              <div className="acordeonActividad">
                                  <Accordion
                                      title={act.NOMBRE}
                                      defaultCollapsed={false}
                                      inicio={true}
                                      expandAll={false}
                                      className="fondoTitAcordionActividad"
                                      key={1}
                                      classFondo="fondoSeccionActividad">
                                      <div className="itemContent">
                                          {estado_modulo === 24 || estado_modulo === 25 ? (
                                              ""
                                          ) : (
                                              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" id="divBloque">
                                                  <button
                                                      id="btnAgregarRol"
                                                      className="cierre"
                                                      onClick={(e) => {
                                                          deleteActividad(act.ID_ACTIVIDAD, indexAct);
                                                      }}>
                                                      <i className="ms-Icon ms-Icon--Cancel" aria-hidden="true" id="btnEliminarActividad"></i>
                                                  </button>
                                              </div>
                                          )}
                                      </div>
                                      <div className="ms-Grid-row bloqueActividad resumenGastos" id="bloqueActividad">
                                          <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg2 txtSmall">
                                              <TextField
                                                  label="Total Gastos Distribuidos"
                                                  id={"totalGasto_Plan"}
                                                  value={
                                                      act.GASTO_DISTRIBUIDO === 0
                                                          ? ""
                                                          : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.GASTO_DISTRIBUIDO).toString()
                                                  }
                                                  defaultValue={
                                                      act.GASTO_DISTRIBUIDO <= 0
                                                          ? ""
                                                          : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.GASTO_DISTRIBUIDO).toString()
                                                  }
                                                  disabled={true}
                                                  placeholder={"..."}
                                              />
                                          </div>
                                          <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                              <MessageBar
                                                  messageBarType={
                                                      act.GASTO_DISTRIBUIDO > act.GASTO_TOTAL
                                                          ? MessageBarType.warning
                                                          : act.GASTO_DISTRIBUIDO < act.GASTO_TOTAL
                                                          ? MessageBarType.warning
                                                          : MessageBarType.success
                                                  }
                                                  isMultiline={false}
                                                  dismissButtonAriaLabel="Close">
                                                  {act.GASTO_DISTRIBUIDO > act.GASTO_TOTAL
                                                      ? `Sobran ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat((act.GASTO_DISTRIBUIDO - act.GASTO_TOTAL).toFixed(2))
                                                        )} gastos distribuidos planificados.`
                                                      : act.GASTO_DISTRIBUIDO < act.GASTO_TOTAL
                                                      ? `Faltan ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat((act.GASTO_TOTAL - act.GASTO_DISTRIBUIDO).toFixed(2))
                                                        )} gastos distribuidos planificados.`
                                                      : "Los gastos planificados están correctamente distribuidos."}
                                              </MessageBar>
                                          </div>
                                      </div>

                                      <div className="ms-Grid-row bloqueActividad resumenGastos" id="bloqueActividad">
                                          <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg2 txtSmall">
                                              <TextField
                                                  label="Total Recuperable Distribuidos"
                                                  id={"totalGasto_Plan"}
                                                  value={
                                                      act.RECUPERABLE_DISTRIBUIDO === 0
                                                          ? ""
                                                          : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.RECUPERABLE_DISTRIBUIDO).toString()
                                                  }
                                                  defaultValue={
                                                      act.RECUPERABLE_DISTRIBUIDO <= 0
                                                          ? ""
                                                          : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.RECUPERABLE_DISTRIBUIDO).toString()
                                                  }
                                                  disabled={true}
                                                  placeholder={"..."}
                                              />
                                          </div>
                                          <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 alertaDistribucion">
                                              <MessageBar
                                                  messageBarType={
                                                      act.RECUPERABLE_DISTRIBUIDO > act.VALOR_RECUPERAR
                                                          ? MessageBarType.warning
                                                          : act.RECUPERABLE_DISTRIBUIDO < act.VALOR_RECUPERAR
                                                          ? MessageBarType.warning
                                                          : MessageBarType.success
                                                  }
                                                  isMultiline={false}
                                                  dismissButtonAriaLabel="Close">
                                                  {act.RECUPERABLE_DISTRIBUIDO > act.VALOR_RECUPERAR
                                                      ? `Sobran ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat((act.RECUPERABLE_DISTRIBUIDO - act.VALOR_RECUPERAR).toFixed(2))
                                                        )} recuperables distribuidos planificados.`
                                                      : act.RECUPERABLE_DISTRIBUIDO < act.VALOR_RECUPERAR
                                                      ? `Faltan ${new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(
                                                            parseFloat((act.VALOR_RECUPERAR - act.RECUPERABLE_DISTRIBUIDO).toFixed(2))
                                                        )} recuperables distribuidos planificados.`
                                                      : "Los recuperables planificados están correctamente distribuidos."}
                                              </MessageBar>
                                          </div>
                                      </div>

                                        <div className="ms-Grid-row bloqueActividad resumenGastos" id="bloqueActividad">
                                            <div className="ms-Grid-col ms-sm6 ms-md1 ms-lg2 txtSmall">
                                                <TextField
                                                    label="Total Neto"
                                                    id={"totalGasto_Plan"}
                                                    value={act.TOTAL_NETO === 0 ? "" : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.TOTAL_NETO).toString()}
                                                    defaultValue={
                                                        act.TOTAL_NETO <= 0 ? "" : new Intl.NumberFormat("de-DE", { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 2 }).format(act.TOTAL_NETO).toString()
                                                    }
                                                    disabled={true}
                                                    placeholder={"..."}
                                                />
                                            </div>
                                        </div>
                                        {
                                            aniosDistintos.sort((a: number, b: number) => a - b).map((numeroAnio: number) => {
                                                let collectMeses = [
                                                    { numero: 1, nombre: "Enero" },
                                                    { numero: 2, nombre: "Febrero" },
                                                    { numero: 3, nombre: "Marzo" },
                                                    { numero: 4, nombre: "Abril" },
                                                    { numero: 5, nombre: "Mayo" },
                                                    { numero: 6, nombre: "Junio" },
                                                    { numero: 7, nombre: "Julio" },
                                                    { numero: 8, nombre: "Agosto" },
                                                    { numero: 9, nombre: "Septiembre" },
                                                    { numero: 10, nombre: "Octubre" },
                                                    { numero: 11, nombre: "Noviembre" },
                                                    { numero: 12, nombre: "Diciembre" },
                                                ]
                                                let arrayAnioGastos: ArrayGastos[] = arrayGastos?.filter((gasto: ArrayGastos) => (gasto.ID_TEMPORAL == act.ID_TEMPORAL && gasto.ANIO == numeroAnio)) ?? [];
                                                let arrayAnioRecuperables: ArrayRecuperables[] = arrayRecuperables?.filter((gasto: ArrayRecuperables) => (gasto.ID_TEMPORAL == act.ID_TEMPORAL && gasto.ANIO == numeroAnio)) ?? [];
                                                let arrayAnios = [...arrayAnioGastos.map((item: ArrayGastos) => item.ID_MES), ...arrayAnioRecuperables.map((item: ArrayRecuperables) => item.ID_MES)];
                                                let arrayMeses: number[] = [];
                                                for(let i = Math.min(...arrayAnios); i <= Math.max(...arrayAnios); i++){
                                                    arrayMeses.push(i);
                                                }
                                                return (
                                                    <div>
                                                        <div className="ms-Grid-row bloqueActividad mt-3" id="bloqueActividad">
                                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-2">
                                                                <div className="table-responsive fondoTabla">
                                                                    <table className="table table-sm tablaGastos">
                                                                        <thead>
                                                                            <tr>
                                                                                {
                                                                                    arrayMeses.map((numeroMes: number) => {
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <td>
                                                                                                    <span className="badge badge-primary">
                                                                                                        { collectMeses.find((mes) => mes.numero == numeroMes)?.nombre } { numeroAnio }
                                                                                                    </span>
                                                                                                </td>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="tituloCalendario">Gastos</td>
                                                                            </tr>
                                                                            <tr>
                                                                                {
                                                                                    arrayMeses.map((numeroMes: number) => {
                                                                                        let mesActual: ArrayGastos | undefined = arrayAnioGastos.find((item: ArrayGastos) => item.ID_MES == numeroMes);
                                                                                        let mesNombre = collectMeses.find((mes) => mes.numero == numeroMes)?.nombre ?? "";
                                                                                        if(mesActual == undefined){
                                                                                            agregarReservaArray( numeroAnio, numeroMes, 0, act.ID, act.ID_TEMPORAL );
                                                                                            mesActual = arrayGastos?.find((item: ArrayGastos) => (item.ID_TEMPORAL == act.ID_TEMPORAL && item.ANIO == numeroAnio && item.ID_MES == numeroMes));
                                                                                        }
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <td className="textMonth">
                                                                                                    <span className={"formateoDistribucion"}>
                                                                                                        <NumberFormat
                                                                                                            id = { "GAS-" + act.ID_ACTIVIDAD + "-" + mesNombre.toUpperCase().slice(0, 3) + "-" + numeroAnio}
                                                                                                            onValueChange = { (element) => { onReservaChange( numeroAnio, numeroMes, element, act.ID_TEMPORAL, act.ID ); } }
                                                                                                            defaultValue = { mesActual?.VALOR ?? "" }
                                                                                                            value = { mesActual?.VALOR ?? "" }
                                                                                                            thousandSeparator = { "." }
                                                                                                            decimalSeparator = { "," }
                                                                                                            placeholder = { "..." }
                                                                                                            disabled = { isClosedMonth(numeroMes, numeroAnio) }
                                                                                                        />
                                                                                                    </span>
                                                                                                </td>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="tituloCalendario">Recuperables</td>
                                                                            </tr>
                                                                            <tr>
                                                                                {
                                                                                    arrayMeses.map((numeroMes: number) => {
                                                                                        let mesActual: ArrayRecuperables | undefined = arrayAnioRecuperables.find((item: ArrayRecuperables) => item.ID_MES == numeroMes);
                                                                                        let mesNombre = collectMeses.find((mes) => mes.numero == numeroMes)?.nombre ?? "";
                                                                                        if(mesActual == undefined){
                                                                                            agregarReservaArrayRecuperables( numeroAnio, numeroMes, 0, act.ID, act.ID_TEMPORAL );
                                                                                            mesActual = arrayAnioRecuperables?.find((item: ArrayRecuperables) => (item.ID_TEMPORAL == act.ID_TEMPORAL && item.ANIO == numeroAnio && item.ID_MES == numeroMes));
                                                                                        }
                                                                                        return (
                                                                                            <React.Fragment>
                                                                                                <td className="textMonth">
                                                                                                    <span className={"formateoDistribucion"}>
                                                                                                        <NumberFormat
                                                                                                            id = { "REC-" + act.ID_ACTIVIDAD + "-" + mesNombre.toUpperCase().slice(0, 3) + "-" + numeroAnio}
                                                                                                            onValueChange = { (element) => { onReservaChangeRecuperable( numeroAnio, numeroMes, element, act.ID_TEMPORAL, act.ID ); } }
                                                                                                            defaultValue = { mesActual?.VALOR ?? "" }
                                                                                                            value = { mesActual?.VALOR ?? "" }
                                                                                                            thousandSeparator = { "." }
                                                                                                            decimalSeparator = { "," }
                                                                                                            placeholder = { "..." }
                                                                                                            disabled = { isClosedMonth(numeroMes, numeroAnio) }
                                                                                                        />
                                                                                                    </span>
                                                                                                </td>
                                                                                            </React.Fragment>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {FilasIngresos(act)}
                                  </Accordion>
                              </div>
                          </div>
                      </div>
                  );
              })
            : "";
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            indexIngreso > -1 ? deleteRolIndex() : deleteActividadRoles();
                        }}
                        text="Sí, Eliminar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };
    
    return (
        <React.Fragment>
            <div className="ms-Grid" dir="ltr">
                <div className="acordeon">
                    <div className="itemContent marginbot">
                        {estado_modulo === 24 || estado_modulo === 25 ? (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm1 ms-md8 ms-lg7"></div>
                                <div className="ms-Grid-col ms-sm11 ms-md4 ms-lg5 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        ) : (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm8 ms-md4 ms-lg4 mt-2 mb-2">
                                    <ComboBox
                                        placeholder="Nombre Concepto"
                                        id={"nombreActividadDirecto"}
                                        onChange={_onChangeActividad}
                                        options={lista_categorias}
                                        allowFreeform={true}
                                        autoComplete="on"
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm1 ms-md3 ms-lg3 mt-2 mb-2" id="divAgregarActividad">
                                    <button
                                        id="btnAgregarActividad"
                                        className="btnAgregarActividad"
                                        onClick={(e) => {
                                            addActividad();
                                        }}>
                                        <i className="ms-Icon ms-Icon--AddTo" aria-hidden="true" id="btnAgregarActividad">
                                            &nbsp;
                                            <span className="txtAgregar">Agregar concepto</span>
                                        </i>
                                    </button>
                                </div>
                                <div className="ms-Grid-col ms-sm11 ms-md3 ms-lg4 d-flex justify-content-end mt-3">
                                    <label>
                                        Los valores reflejados están en la moneda de la Unidad de Negocio: <span>{moneda}</span>
                                    </label>
                                </div>
                            </div>
                        )}
                        {Actividades()}
                        {dialog()}
                        {Resumen()}
                    </div>
                    {/* </Accordion> */}
                </div>
            </div>
        </React.Fragment>
    );
};

export default IngresosGastosForecast;
