import { useHistory } from "react-router-dom";
import "../../custom.css";
import logoVantaz2021 from "../../img/logoVTZ2021.svg";
import React, { useState, useEffect } from "react";
import "./Home.css";
import { authProvider } from "../../authProvider";
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react/lib-commonjs/Button";
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react/lib/Dialog";
import "office-ui-fabric-react/dist/css/fabric.css"; // para los grid
import { fetchEngine } from "../../fetchData";
import BotonAyuda from "../BotonAyuda/BotonAyuda";

const Home = () => {
    const [data, setData] = useState([]);
    const [dataPresupuesto, setDataPresupuesto] = useState([]);
    const [dataRendicion, setDataRendicion] = useState([]);
    const [comboData, setComboData] = useState([] as any);
    const [comboData2, setComboData2] = useState([] as any);
    const [comboData3, setComboData3] = useState([] as any);
    const [pages, setPages] = useState(0);
    const [pages2, setPages2] = useState(0);
    const [pagesRendicion, setPagesRendicion] = useState(0);
    const [elementID, setElementId] = useState(0);

    const [contrato, setContrato] = useState(0);
    const [nombresim, setNombreSim] = useState("0");
    const [nombreopo, setNombreOpo] = useState("0");
    const [tipoTab, settipoTab] = useState("Mis Registros");

    const [nombre, setNombre] = useState("0");
    const [unidad, setUnidad] = useState(0);
    const [estado, setEstado] = useState(0);
    const [estadoRendicion, setEstadoRendicion] = useState(0);
    const [responsable, setResponsable] = useState("0");

    const history = useHistory();
    const modalPropsStyles = { main: { maxWidth: 450 } };
    const modalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        []
    );
    const [hideDialog, setDialog] = useState(true);
    const dialogContentProps = {
        type: DialogType.normal,
        title: "Advertencia",
        subText: "¿Está seguro que quiere copiar esta oportunidad?",
    };
    const [tokenAPI, setTokenAPI] = useState<string>("");
    const cambioPage = (page: number) => {
        ////console.log(page);
    };

    const columnas = [
        {
            titulo: "ID",
            interno: "ID",
            posicion: 1,
            ordenable: true,
            filtrable: false,
        },
        {
            titulo: "Nombre",
            interno: "NOMBRE",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text",
        },
        {
            titulo: "Unidad de negocio",
            interno: "UNIDAD_NEGOCIO_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Fecha cierre",
            interno: "FECHA_CIERRE",
            posicion: 1,
            ordenable: false,
            filtrable: false,
            filterType: "date",
        },
        {
            titulo: "Estado",
            interno: "ESTADO_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
        {
            titulo: "Responsable",
            interno: "RESPONSABLE_PROYECTO_DISPLAY",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "autocomplete",
            placeholder: "Nombre de Responsable",
        },
    ];

    const acciones = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver oportunidad",
            title: "Ver oportunidad",
        },
        {
            id: "copiar",
            icon: "Copy",
            tooltip: "Copiar oportunidad",
            title: "Copiar oportunidad",
        },
    ];

    const accion = (accion: string, ID: number) => {
        ////console.log(accion, ID);

        switch (accion) {
            case "reload":
                obtenerOportunidades(1);
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/oportunidad/${ID}`);
                break;
            case "paging":
                obtenerOportunidades(ID + 1);
                break;
            case "copiar":
                setElementId(ID);
                setDialog(false);
                break;
            default:
                break;
        }
    };

    const obtenerOportunidades = (page: number) => {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine
            .getAPI(
                `/api/oportunidad/getbyme/${userName.account.userName}/${nombre != "" ? nombre : "0"}/${unidad}/${estado}/${
                    responsable != "" ? responsable : "0"
                }/${page}`
            )
            .then((result: any) => {
                setData(result.data.listado);
                setComboData([
                    {
                        id: "ESTADO_DISPLAY",
                        value: result.data.estados,
                    },
                    {
                        id: "UNIDAD_NEGOCIO_DISPLAY",
                        value: result.data.unidades,
                    },
                ]);

                setPages(result.contador);
            });
    };

    const copiarOportunidad = (ID: number) => {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }

        fetchEngine.putAPI(`/api/oportunidad/${ID}`).then((result: any) => {
            let copyID = result.id;
            history.push(`/oportunidad/${copyID}`);
        });
    };

    const dialog = () => {
        return (
            <Dialog
                hidden={hideDialog}
                onDismiss={() => {
                    setDialog(true);
                }}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton
                        onClick={() => {
                            copiarOportunidad(elementID);
                            setDialog(true);
                        }}
                        text="Sí, copiar"
                    />
                    <DefaultButton
                        onClick={() => {
                            setDialog(true);
                        }}
                        text="No"
                    />
                </DialogFooter>
            </Dialog>
        );
    };

    const callbackFilter = (id: string, value: string) => {
        switch (id) {
            case "NOMBRE":
                setNombre(value != "" ? value : "0");
                break;
            case "UNIDAD_NEGOCIO_DISPLAY":
                setUnidad(parseInt(value));
                break;
            case "ESTADO_DISPLAY":
                setEstado(parseInt(value));
                break;
            case "RESPONSABLE_PROYECTO_DISPLAY":
                setResponsable(value);
                break;
            case "CONTRATO_MARCO":
                setContrato(parseInt(value));
                break;
        }
    };

    useEffect(() => {
        if (authProvider != undefined) {
            obtenerOportunidades(1);
        }
    }, [nombre, unidad, estado, responsable]);

    // Listado presupuesto
    const columnasPresupuesto = [
        {
            titulo: "ID",
            interno: "ID",
            posicion: 1,
            ordenable: true,
            filtrable: false,
        },
        {
            titulo: "Nombre simulador",
            interno: "NOMBRE",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text",
        },
        {
            titulo: "Nombre oportunidad",
            interno: "NOMBRE_PROYECTO",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text2",
        },
        {
            titulo: "Contrato Marco",
            interno: "CONTRATO_MARCO",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
    ];

    const accionesPresupuesto = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver presupuesto",
            title: "Ver presupuesto",
        },
    ];

    const accionPresupuesto = (accion: string, ID: number) => {
        switch (accion) {
            case "reload":
                obtenerPresupuestos(1);
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/simulador/${ID}`);
                break;
            case "paging":
                obtenerPresupuestos(ID + 1);
                break;
            default:
                break;
        }
    };

    const callbackFilterPresupuesto = (id: string, value: string) => {
        switch (id) {
            case "NOMBRE":
                setNombreSim(value != "" ? value : "0");
                break;
            case "NOMBRE_PROYECTO":
                setNombreOpo(value != "" ? value : "0");
                break;
            case "CONTRATO_MARCO":
                setContrato(parseInt(value));
                break;
        }
    };

    const obtenerPresupuestos = (page: number) => {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine
            .getAPI(
                `/api/simulacion/getbyme/${userName.account.userName}/${nombresim != "" ? nombresim : "0"}/${
                    nombreopo != "" ? nombreopo : "0"
                }/${contrato}/${page}`
            )
            .then((result: any) => {
                setDataPresupuesto(result.data.listado);

                setComboData2([
                    {
                        id: "CONTRATO_MARCO",
                        value: result.data.contratos,
                    },
                ]);

                setPages2(result.contador / 10 + 1);
            });
    };

    useEffect(() => {
        if (authProvider != undefined) {
            obtenerPresupuestos(1);
        }
    }, [nombresim, nombreopo, contrato]);

    // Listado rendicion
    const columnasRendicion = [
        {
            titulo: "ID",
            interno: "ID",
            posicion: 1,
            ordenable: true,
            filtrable: false,
        },
        {
            titulo: "Monto",
            interno: "MONTO",
            posicion: 1,
            ordenable: false,
            filtrable: true,
            filterType: "text",
        },
        {
            titulo: "Fecha rendición",
            interno: "FECHA_RENDICION",
            posicion: 1,
            ordenable: false,
            filtrable: false,
            filterType: "date",
        },
        {
            titulo: "Fecha gasto",
            interno: "FECHA_GASTO",
            posicion: 1,
            ordenable: false,
            filtrable: false,
            filterType: "date",
        },
        {
            titulo: "Estado",
            interno: "ESTADO",
            posicion: 1,
            ordenable: true,
            filtrable: true,
            filterType: "combo",
        },
    ];

    const accionesRendicion = [
        {
            id: "ver",
            icon: "RedEye",
            tooltip: "Ver rendición",
            title: "Ver rendición",
        },
    ];

    const accionRendicion = (accion: string, ID: number) => {
        ////console.log(accion, ID);

        switch (accion) {
            case "reload":
                obtenerRendicion(1);
                break;
            case "eliminar":
                break;
            case "ver":
                history.push(`/rendicion/${ID}`);
                break;
            case "paging":
                obtenerRendicion(ID + 1);
                break;
            default:
                break;
        }
    };

    const callbackFilterRendicion = (id: string, value: string) => {
        switch (id) {
            case "ESTADO":
                setEstadoRendicion(parseInt(value));
                break;
        }
    };

    const obtenerRendicion = (page: number) => {
        let userName: any;
        if (authProvider.getAccountInfo() != null) {
            userName = authProvider.getAccountInfo() ? authProvider.getAccountInfo() : {};
        }
        fetchEngine.getAPI(`/api/rendicion/getbyme/${userName.account.userName}/${estadoRendicion}/${page}`).then((result: any) => {
            setDataRendicion(result.data.listado);

            setComboData3([
                {
                    id: "ESTADO",
                    value: result.data.estados,
                },
            ]);

            setPagesRendicion(result.contador / 10 + 1);
        });
    };

    useEffect(() => {
        console.log("load, useeffect")
        if (authProvider != undefined) {
            console.log("token progress")
            obtenerRendicion(1);
            new Promise<any>(async (resolve, reject) => {
                var tokens = await fetchEngine.getStorageValue(authProvider);
                resolve(tokens.tokenAPI);
            }).then((d) => {
                setTokenAPI(d);
            });
        }
    }, [estadoRendicion]);

    return (
        <React.Fragment>
            <BotonAyuda token={tokenAPI} />
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row mt-5">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 mt-4"></div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 mt-4">
                        <h5 className="titSistemaVTZ">Bienvenido(a) a tu nuevo espacio de trabajo</h5>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-1 text-center">
                        <img src={logoVantaz2021} alt="" className="logoVTZ2021" />
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 mt-4 d-flex justify-content-end">
                        <h5 className="titSistemaVTZ">descubre una nueva experiencia</h5>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg2 mt-4"></div>
                </div>

                <div className="ms-Grid-row mt-3">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 mt-4">
                        <h1 className="titHome">Sistema Integrado Gestión de Proyectos</h1>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Home;
