import * as React from 'react';
import './Spinner.module.css';
import {  IIconProps } from 'office-ui-fabric-react';
import { useState } from "react";
import { css } from "@emotion/core";
import BarLoader from "react-spinners/BarLoader";
import BeatLoader from "react-spinners/BeatLoader";
import BounceLoader from "react-spinners/BounceLoader";
import CircleLoader from "react-spinners/CircleLoader";
import ClipLoader from "react-spinners/CircleLoader";
import ClockLoader from "react-spinners/ClockLoader";
import DotLoader from "react-spinners/DotLoader";
import PacmanLoader from "react-spinners/PacmanLoader";
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib-commonjs/Button';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
interface Props {
  mnj: string;
}

const Spinner= ({ mnj }: Props) => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#4472c4");

  return (
    <div className="sweet-loading">
      {/*<button onClick={() => setLoading(!loading)}>{mnj}</button>*/}
      {/*<input
        value={color}
        onChange={(input) => setColor(input.target.value)}
        placeholder="Color of the loader"
      />*/}

      <BeatLoader color={color} loading={loading} css={override} size={20} />

      {/*<DefaultButton text={"Cerrar"}/>*/}
    </div> 
  );
}

export default Spinner;